import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-sales-order',
  templateUrl: './sales-order.component.html',
  styleUrls: ['./sales-order.component.scss']
})
export class SalesOrderComponent implements OnInit {
  @Input() title: string;
  @Input() order: any;
  @Input() orderItems: any;
  @Input() totalCases: number;
  @Input() totalPallets: number;
  @Input() totalGrossLbs: number;
  @Input() totalCaseCost: number;
  @Input() totalGrosskg: number;
  constructor() { }
  grayRow = [];

  ngOnInit() {

    let stripe = 'S';
    this.orderItems.forEach(element => {
      stripe = stripe == 'S' ? '' : 'S';
      this.grayRow.push(stripe);
    });
  }
  displayDate(dt, t) {
    
    if (dt) {
      if (t === undefined) {
        t = '';
      }
      let x = new Date(dt);
      if (t == 'T') {
        return x.toLocaleDateString() + ' @ ' + x.toLocaleTimeString();
      }
      return x.toLocaleDateString();
    } 
    return dt
  }
}
