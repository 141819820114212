import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
const url = environment.kalcorApiUrl;

@Injectable({
  providedIn: 'root'
})
export class SalesManagerService {

  constructor(private http: HttpClient) { }
  getAll(): Observable<any> {
    return this.http.get(url + 'SalesManagers', this.jwt());
  }
  getOne(id: number): Observable<any> {
    return this.http.get(url + 'SalesManagers/' + id, this.jwt());
  }
  update(SalesMgr) {
    return this.http.put(url + 'SalesManagers/' + SalesMgr.salesMgrId, SalesMgr, this.jwt());
  }
  create(SalesMgr): Observable<any> {
    return this.http.post(url + 'SalesManagers', SalesMgr, this.jwt());
  }
  remove(id) {
    return this.http.delete(url + 'SalesManagers/' + id, this.jwt());
  }
  private jwt() {
    // create authorization header with jwt token
    const currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
    if (currentUser && currentUser.token) {
      const headersInfo = new HttpHeaders({ Authorization: 'Bearer ' + currentUser.token });
      return { headers: headersInfo };
    }
  }
}
