<h5>Clearing Company List </h5>
<table class="table table-striped" style="overflow-x:scroll;">
    <thead>
    <tr>
      <th scope="col">Active</th>
      <th scope="col">Company</th>
      <th scope="col">Address</th>
      <th scope="col">City</th>
      <th scope="col">State/Province</th>
      <th scope="col">Country/Region</th>
      <th scope="col">Zip Code</th>
      <th scope="col">Full Name</th>
      <th scope="col">Job Title</th>
      </tr>
    </thead>
    <tbody>
    <tr *ngFor="let c of clearing; index as i">
      <td>{{ c.activeItem }}</td>
      <td><a class="pointer" (click)="onRowClicked(c)">
        <u class="text-primary">{{ c.company }}</u>
        </a>
        </td>
      <td>{{ c.address }}</td>
      <td>{{ c.city }}</td>
      <td>{{ c.stateProvince }}</td>
      <td>{{ c.countryRegion }}</td>
      <td>{{ c.zipPostalCode }}</td>
      <td>{{ c.fullName }}</td>
      <td>{{ c.jobTitle }}</td>
    </tr>
    </tbody>
  </table>
  <ngb-alert *ngIf="successMessage" [type]="alertType" (close)="successMessage = null">{{ successMessage }}</ngb-alert>   
