import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
const url = environment.kalcorApiUrl;
@Injectable({
  providedIn: 'root'
})
export class ProductService {

  constructor(private http: HttpClient) { }

  getAll(): Observable<any> {
    return this.http.get(url + 'Products', this.jwt());
  }
  getOne(id: number): Observable<any> {
    return this.http.get(url + 'Products/' + id, this.jwt());
  }
  getActiveListings(): Observable<any> {
    return this.http.get(url + 'Products/ActiveListings', this.jwt());
  }
  getActiveListingOrder(brand: number, category: number, costing: string, costType: string, groupId: number, page: number, pageSize: number): Observable<any> {
    page = page ? page : 1;
    pageSize = pageSize ? pageSize : 30;
    return this.http.get(url + 'Products/ActiveListingOrder' + '?brandId=' + brand
       + '&categoryId=' + category + '&costing=' + costing + '&costType=' + costType 
    + '&productGroupId=' + groupId + '&page=' + page + '&pageSize=' + pageSize, this.jwt());
  }
  getListingCustomerOrder(): Observable<any> {
    return this.http.get(url + 'Products/ListingCustomerOrder', this.jwt());
  }

  getActiveListingByCustomer(customerId: number, vendorId: number): Observable<any> {
    return this.http.get(url + 'Products/ListingByCustomer/'
    + customerId  + '&vendorId=' + vendorId, this.jwt());
  }
    
  getActiveListingByVendor(vendorId: number): Observable<any> {
    return this.http.get(url + 'Products/ListingByVendor/'
    + vendorId, this.jwt());
  }

  getProjectionOrder(customerId: number, brandId: number, headId: number, banner: string, year: number, promotion: string): Observable<any> {
    return this.http.get(url + 'Products/ProjectionOrder' + '?customerId=' + customerId + '&brandId=' + brandId +
      '&headId=' + headId + '&banner=' + banner + '&year=' + year + '&promo=' + promotion, this.jwt());
  }

  getFilteredListing(typeofCosting: string, costType: string, customerId: number, shipToId: number, brokerId: number, brandId: number, categoryId: number, vendorId: number, page: number, pageSize: number): Observable<any> {
    page = page ? page : 1;
    pageSize = pageSize ? pageSize : 30;
    return this.http.get(url + 'Products/ListingFilter?typeofCosting=' + typeofCosting + '&costType=' + 
      costType.trim() + '&customerId=' + customerId + '&shipToId=' + shipToId + '&brokerId=' + brokerId + 
      '&brandId=' + brandId + '&categoryId=' + categoryId + '&vendorId=' + vendorId + '&page=' + page + 
      '&pageSize=' + pageSize, this.jwt());
  }

  getItemOrder(): Observable<any> {
    return this.http.get(url + 'Products/ItemOrder', this.jwt());
  }
  getPriceList(vendorId: number, typeofCosting: string, brandId: number, productOrderCodeId: number, productGroupId: number, page: number, pageSize: number): Observable<any> {
    return this.http.get(url + 'Products/PriceList?vendorId2=' + vendorId + '&typeofCosting=' + typeofCosting +
    '&brandId=' + brandId + '&productOrderCodeId=' + productOrderCodeId + '&productGroupId=' + productGroupId +  '&page=' + page + '&pageSize=' + pageSize, this.jwt());
  }
  getExhibitA(vendorId: number, brokerId: number, brokerId2: number, customerId: number, shipToId: number, page: number, pageSize: number): Observable<any> {
    return this.http.get(url + 'Products/ExhibitA?vendorId2=' + vendorId +
    '&brokerId=' + brokerId + '&broker2Id=' + brokerId2 + '&customerId=' + customerId + '&shipToId=' + shipToId + '&page=' + page + '&pageSize=' + pageSize, this.jwt());
  }
  getProductGroup(productGroupId: number): Observable<any> {
    return this.http.get(url + 'Products/ProductGroup' + '?productGroupId=' + productGroupId, this.jwt());
  }
  getProductGroupListing(grouplistingId: number): Observable<any> {
    return this.http.get(url + 'Products/ProductGroupListing' + '?groupListingId=' + grouplistingId, this.jwt());
  }
  update(pickup) {
    return this.http.put(url + 'Products/' + pickup.id, pickup, this.jwt());
  }
  create(pickup): Observable<any> {
    return this.http.post(url + 'Products', pickup, this.jwt());
  }
  createUnique(pickup): Observable<any> {
    return this.http.post(url + 'Products/Unique', pickup, this.jwt());
  }
  remove(id) {
    return this.http.delete(url + 'Products/' + id, this.jwt());
  }
  private jwt() {
    // create authorization header with jwt token
    const currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
    if (currentUser && currentUser.token) {
        const headersInfo = new HttpHeaders({ Authorization: 'Bearer ' + currentUser.token });
        return { headers: headersInfo };
    }
  }

}
