<h5>{{ title }} </h5>
<form [formGroup]="locationForm" (ngSubmit)="onSubmit()" novalidate>
  <div class="form-group">
    <div class="col-lg-44">
      <label>
        Location:
        <input type="text" formControlName="location" class="form-control"  size="60">
      </label>
    </div>
    <div class="col-lg-44">
        <label>
          City:
          <input type="text" formControlName="city" class="form-control"  size="60">
        </label>
    </div>
  
    <button type="submit" class="btn btn-primary mt-3 w-25">Save</button>
    <button type="button" class="btn btn-secondary ml-3 mt-3 w-25" (click)="onDelete()">Delete</button>
  </div>
    <ngb-alert *ngIf="successMessage" type="success" (close)="successMessage = null">{{ successMessage }}</ngb-alert>
</form>