import { Component, OnInit } from '@angular/core';
import { CustomerRetailService } from '../../../../services/customer-retail.service';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { CustomerEnd } from 'src/app/models/customer-end-model';
import { CustomerRetail } from 'src/app/models/customer-retail.model';
import { CustomerEndService } from 'src/app/services/customer-end.service';

@Component({
  selector: 'app-customer-retail-list',
  templateUrl: './customer-retail-list.component.html',
  styleUrls: ['./customer-retail-list.component.scss']
})
export class CustomerRetailListComponent implements OnInit {
  customerRetail: any;
  customerHead: CustomerEnd[];
  activeItemSelected: string;
  customerSelected: number;
  customerOptions:[];
  companySelected: number;
  companyOptions:[];
  customerRetailOriginal: any;
  page: number;
  pageSize: number;
  count: number;
  collectionSize = 0;
  successMessage: string;
  private success = new Subject<string>();
  alertType = "success";
  staticAlertClosed = false;
  constructor(private router: Router,
              private customerHeadSvc: CustomerEndService,
              private customerRetailSvc: CustomerRetailService) { }

  ngOnInit() {
    this.reset();
    setTimeout(() => this.staticAlertClosed = true, 20000);
    this.success.subscribe((message) => this.successMessage = message);
    this.success.pipe(
      debounceTime(5000)
    ).subscribe(() => this.successMessage = null);    
  }

  loadPage() {
    this.customerRetailSvc.getFilteredListing(this.activeItemSelected, this.companySelected, this.customerSelected, this.page, this.pageSize).subscribe(data => {
      this.customerRetailOriginal = data.item;
      this.customerRetail = [];
      this.customerRetailOriginal.forEach(item => {
        const index = this.customerRetail.findIndex(x => x.id == item.id);
        if (index == -1) {
          this.customerRetail.push(item);
        } else {
          if (item.retailRegion && this.customerRetail[index].retailRegion) {
            if (this.customerRetail[index].retailRegion !== null && this.customerRetail[index].retailRegion.indexOf(item.retailRegion) == -1) {
              this.customerRetail[index].retailRegion += " " + item.retailRegion;
              this.customerRetail[index].retailRegion.trim();
            }
          }
          if (item.retailAccountType) {
            if (this.customerRetail[index].retailAccountType.indexOf(item.retailAccountType) == -1) {
              this.customerRetail[index].retailAccountType += " " + item.retailAccountType;
              this.customerRetail[index].retailAccountType.trim();
            }
          }
        }
      });
      this.customerOptions = data.item2;
      this.companyOptions = data.item3;
      this.count = data.count;
    },error => {
      this.successMessage = 'Unable to process request';
      this.alertType = "danger";
    });
  }

  reset() {
    this.page = 1;
    this.collectionSize = 1;
    this.pageSize = 30;
    this.activeItemSelected = 'Active';
    this.companySelected = 0;
    this.customerSelected = 0;
    this.loadPage();    
  }



  onRowClicked(retail) {
    this.router.navigate(['/customers/retail-edit', retail.id]);
  }
}
