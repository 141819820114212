<div>
    <h4>Funds Transfer List</h4>
    <div class="col-lg-44">
        Filter
      <label class="ms-3">
        Transfer Status:
        <select [(ngModel)]="transferStatusSelected" (change)="resetFilters()">
          <option [value]=""></option>
          <option *ngFor="let t of transferStatusOptions" [value]="t">{{ t }}</option>
        </select>
      </label>
  
      <label class="ms-3">
        Transfer No:
        <select [(ngModel)]="transferNumberSelected" (change)="resetFilters()">
          <option [value]=""></option>
          <option *ngFor="let t of transferNumberOptions" [value]="t">{{ t }}</option>
        </select>
      </label>
  
      <label class="ms-3">
        Bill to:
        <select [(ngModel)]="billToSelected" (change)="resetFilters()">
          <option [value]=""></option>
          <option *ngFor="let b of billToOptions" [value]="b.billToId">{{ b.company }}</option>
        </select>
      </label>

      <label class="ms-3">
        Vendor:
        <select [(ngModel)]="vendorSelected" (change)="resetFilters()">
          <option [value]=""></option>
          <option *ngFor="let v of vendorOptions" [value]="v.vendorId">{{ v.vendorName }}</option>
        </select>
      </label>
      <button type="button" class="btn btn-primary mt-0 ms-3" (click)="getDefaultDataScreen()">Reset</button>
    </div>
    <table class="table table-striped">
      <thead>
        <tr>
          <th scope="col">Transfer Status</th>
          <th scope="col">Transfer No.</th>
          <th scope="col">Transfer Date</th>
          <th scope="col">Bill To</th>
          <th scope="col">Vendor</th>
          <th scope="col">Notes</th>
          <th scope="col">Transfer Amount</th>
          <th scope="col">From Bucket</th>
          <th scope="col">To Bucket</th>
          <th scope="col">Transfer</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let t of transfer; index as i">
          <td>{{ t?.transferStatus }}</td>
          <td>{{ t?.transferNo }}</td>
          <td>{{ displayDate(t.transferDate, '') }}</td>
          <td>{{ t?.company }}</td>
          <td>{{ t?.vendorName }}</td>
          <td>{{ t?.notes }}</td>
          <td>{{ t?.transferAmount | currency }}</td>
          <td>{{ t?.fromBucket }}</td>
          <td>{{ t?.toBucket }}</td>
          <td>
            <a (click)="goToEdit(i)" class="pointer">
              <u class="text-primary">Transfer</u>
            </a>
          </td>
        </tr>
      </tbody>
    </table>

</div>