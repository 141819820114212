export class Product {
  id: number;
  activeListings: string;
  billingAgentId: number;
  billing: number;
  billing1: number;
  blended: number;
  blendedFreightCost: number;
  brandId: number;
  brokerId: number;
  broker2Id: number;
  categoryId: number;
  categorySubId: number;
  clearingCs: number;
  clearingCost: number;
  cndFrtCostCase: number;
  cndFrtCostPallet: number;
  commission: number;
  commission1: number;
  commission2: number;
  confirmDate: Date;
  confirmedBy: string;
  costType: string;
  csCubeImperial: number;
  csCubeMetric: number;
  csGrossKg: number;
  csGrossLbs: number;
  csHCm: number;
  csHInch: number;
  csLCm: number;
  csLInch: number;
  csNetKg: number;
  csNetLbs: number;
  csPerPallet: number;
  csPerTruck: number;
  csWCm: number;
  csWInch: number;
  customerId: number;
  duty: number;
  exchangeBudget: number;
  fobConfirmed: string;
  fobDifference: number;
  fobNetReturn: number;
  fobNetVendorCost: number;
  fobNetVendorUnit: number;
  freightCs: number;
  gm: number;
  gtnid: number;
  headOfficeId: number;
  headOfficeIds: string;
  hi: number;
  itemDescription: string;
  kalcor: number;
  kalcor1: number;
  marketing: number;
  marketing1: number;
  netCnd: number;
  netCsUsd: number;
  orderType: string;
  oz: number;
  pack: number;
  palletPerTruck: number;
  productOrderCode: string;
  productOrderCodeId: number;
  productType: string;
  retailId: number;
  retailIds: string;
  salesMgrId: number;
  shipToId: number;
  shipToIds: string;
  size: string;
  storageCostCase: number;
  storageCostPallet: number;
  terms: number;
  terms1: number;
  ti: number;
  truckCost1: number;
  truckCost2: number;
  truckCost3: number;
  truckCost4: number;
  truckCost5: number;
  truckCost6: number;
  truckCost7: number;
  truckCost8: number;
  unitCubeImp: number;
  unitCubeM: number;
  unitGross: number;
  unitHCm: number;
  unitHInch: number;
  unitLCm: number;
  unitLInch: number;
  unitNet: number;
  unitWCm: number;
  unitWInch: number;
  upcid: number;
  whsSelling: number;
  whsSrp: number;
  whsUnit: number;
  location1: number;
  location2: number;
  location3: number;
  location4: number;
  location5: number;
  location6: number;
  location7: number;
  location8: number;
  location1Id: number;
  location2Id: number;
  location3Id: number;
  location4Id: number;
  location5Id: number;
  location6Id: number;
  location7Id: number;
  location8Id: number;
  transportCo1: number;
  transportCo2: number;
  transportCo3: number;
  transportCo4: number;
  transportCo5: number;
  transportCo6: number;
  transportCo7: number;
  transportCo8: number;
  transNotesCo1: string;
  transNotesCo2: string;
  transNotesCo3: string;
  transNotesCo4: string;
  transNotesCo5: string;
  transNotesCo6: string;
  transNotesCo7: string;
  transNotesCo8: string;
  vendorPurchaseCaseCost: number;
  vendorPurchaseCaseCostUnit: number;
  royalty: number;
  royalty1: number;
  currencyCode: string;
  palletTypeId: number;
  shippingLaneEnd1: number;
  shippingLaneEnd2: number;
  shippingLaneEnd3: number;
  shippingLaneEnd4: number;
  shippingLaneEnd5: number;
  shippingLaneEnd6: number;
  shippingLaneEnd7: number;
  shippingLaneEnd8: number;
  freightType1: string;
  freightType2: string;
  freightType3: string;
  freightType4: string;
  freightType5: string;
  freightType6: string;
  freightType7: string;
  freightType8: string;
  freightCost1: number;
  freightCost2: number;
  freightCost3: number;
  freightCost4: number;
  freightCost5: number;
  freightCost6: number;
  freightCost7: number;
  freightCost8: number;
  freightCostSales1: number;
  freightCostSales2: number;
  freightCostSales3: number;
  freightCostSales4: number;
  freightCostSales5: number;
  freightCostSales6: number;
  freightCostSales7: number;
  freightCostSales8: number;
  cndFrtCostLb: number;
  productGroupId: number;
  groupListingId: number;
  gstHstId: number;
 }

 export class Items {
  brokerId: number;
  broker2Id: number;
  commission1: number;
  commission2: number;
  shipToId: number;
  brokerName: string;
  };

  export class ProductListing {
    products: Product;
    items: Items[];
  }

export const ActiveListings = [
  'Active - PRICE LIST Grocery',
  'Active - PRICE LIST Distributor',
  'Active - PRICE LIST Food Service',
  'Active - PRICE LIST Stock',
  'Active - LISTING Grocery',
  'Active - LISTING Distributor',
  'Active - LISTING Food Service',
  'Active - LISTING Stock',
  //9.11.24 removed and added to inactiveListings const 
  'Archived - PRICE LIST Grocery',
  'Archived - PRICE LIST Distributor',
  'Archived - PRICE LIST Food Service',
  'Archived - PRICE LIST Stock',
  'Archived - LISTING Grocery',
  'Archived - LISTING Distributor',
  'Archived - LISTING Food Service',
  'Archived - LISTING Stock'
];

// export const InactiveListings = [
//   'Archived - PRICE LIST Grocery',
//   'Archived - PRICE LIST Distributor',
//   'Archived - PRICE LIST Food Service',
//   'Archived - PRICE LIST Stock',
//   'Archived - LISTING Grocery',
//   'Archived - LISTING Distributor',
//   'Archived - LISTING Food Service',
//   'Archived - LISTING Stock'
// ];

export const ProductInit = {
  id: 0,
  activeListings: null,
  billingAgentId: null,
  billing: null,
  billing1: null,
  blended: null,
  blendedFreightCost: null,
  brandId: null,
  brokerId: null,
  broker2Id: null,
  categoryId: null,
  categorySubId: null,
  clearingCs: null,
  clearingCost: null,
  cndFrtCostCase: null,
  cndFrtCostPallet: null,
  commission: null,
  commission1: null,
  commission2: null,
  confirmDate: null,
  confirmedBy: null,
  costType: null,
  csCubeImperial: null,
  csCubeMetric: null,
  csGrossKg: null,
  csGrossLbs: null,
  csHCm: null,
  csHInch: null,
  csLCm: null,
  csLInch: null,
  csNetKg: null,
  csNetLbs: null,
  csPerPallet: null,
  csPerTruck: null,
  csWCm: null,
  csWInch: null,
  customerId: null,
  duty: null,
  exchangeBudget: null,
  fobConfirmed: null,
  fobDifference: null,
  fobNetReturn: null,
  fobNetVendorCost: null,
  fobNetVendorUnit: null,
  freightCs: null,
  gm: null,
  gtnid: null,
  headOfficeId: null,
  headOfficeIds: null,
  hi: null,
  itemDescription: null,
  kalcor: null,
  kalcor1: null,
  marketing: null,
  marketing1: null,
  netCnd: null,
  netCsUsd: null,
  orderType: null,
  oz: null,
  pack: null,
  palletPerTruck: null,
  productOrderCode: null,
  productOrderCodeId: null,
  productType: null,
  retailId: null,
  retailIds: null,
  salesMgrId: null,
  shipToId: null,
  shipToIds: null,
  size: null,
  storageCostCase: null,
  storageCostPallet: null,
  terms: null,
  terms1: null,
  ti: null,
  truckCost1: null,
  truckCost2: null,
  truckCost3: null,
  truckCost4: null,
  truckCost5: null,
  truckCost6: null,
  truckCost7: null,
  truckCost8: null,
  unitCubeImp: null,
  unitCubeM: null,
  unitGross: null,
  unitHCm: null,
  unitHInch: null,
  unitLCm: null,
  unitLInch: null,
  unitNet: null,
  unitWCm: null,
  unitWInch: null,
  upcid: null,
  whsSelling: null,
  whsSrp: null,
  whsUnit: null,
  location1: null,
  location2: null,
  location3: null,
  location4: null,
  location5: null,
  location6: null,
  location7: null,
  location8: null,
  location1Id: null,
  location2Id: null,
  location3Id: null,
  location4Id: null,
  location5Id: null,
  location6Id: null,
  location7Id: null,
  location8Id: null,
  transportCo1: null,
  transportCo2: null,
  transportCo3: null,
  transportCo4: null,
  transportCo5: null,
  transportCo6: null,
  transportCo7: null,
  transportCo8: null,
  transNotesCo1: null,
  transNotesCo2: null,
  transNotesCo3: null,
  transNotesCo4: null,
  transNotesCo5: null,
  transNotesCo6: null,
  transNotesCo7: null,
  transNotesCo8: null,
  vendorPurchaseCaseCost: null,
  vendorPurchaseCaseCostUnit: null,
  royalty: null,
  royalty1: null,
  currencyCode: null,
  palletTypeId: null,
  shippingLaneEnd1: null,
  shippingLaneEnd2: null,
  shippingLaneEnd3: null,
  shippingLaneEnd4: null,
  shippingLaneEnd5: null,
  shippingLaneEnd6: null,
  shippingLaneEnd7: null,
  shippingLaneEnd8: null,
  freightType1: null,
  freightType2: null,
  freightType3: null,
  freightType4: null,
  freightType5: null,
  freightType6: null,
  freightType7: null,
  freightType8: null,
  freightCost1: null,
  freightCost2: null,
  freightCost3: null,
  freightCost4: null,
  freightCost5: null,
  freightCost6: null,
  freightCost7: null,
  freightCost8: null,
  freightCostSales1: null,
  freightCostSales2: null,
  freightCostSales3: null,
  freightCostSales4: null,
  freightCostSales5: null,
  freightCostSales6: null,
  freightCostSales7: null,
  freightCostSales8: null,
  cndFrtCostLb: null,
  productGroupId: null,
  groupListingId: null,
  gstHstId: null
};
export const productCalc = {
  pack: ['csNetLbs']
};
