<h5>Review Customer Projection Week</h5>
<form [formGroup]="projectionForm" novalidate>
    <div class="form-group">
        <div class="row">
            <!-- <div class="col-lg-6 col-xl-3">
                <label for="vendorSelect" class="pt-2">
                  *Vendor:
                </label>
            </div> -->
            <div class="col-lg-6 col-xl-3">
                <label for="vendorSelect" class="pt-2">
                  *Vendor:
                </label>
            </div>
            <div class="col-lg-6 col-xl-3">
                <label for="regionSelect" class="pt-2">
                    Region
                </label>
            </div>
            <div class="col-lg-6 col-xl-2 p-0" >
                <label for="view-ship-to" class="pt-2 ps-4 pb-0 text-end">
                    View Ship To
                </label>
            </div>
            <div class="col-lg-3 col-xl-2">
                <select id="view-ship-to" formControlName="viewShipTo" class="form-control">
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                </select>
            </div>
            <div class="col-lg-6 col-xl-1 p-0" >
                <label for="select-year" class="pt-2 px-0 pb-0">
                    Year
                </label>
            </div>
            <div class="col-lg-3 col-xl-2">
                <select id="select-year" formControlName="year" class="form-control">
                    <option *ngFor="let y of years" [value]="y">{{ y }}</option>
                    </select>
            </div>
            <div class="col-lg-6 col-xl-2 p-0" >
                <label for="select-start-week" class="pt-2 ps-4 pb-0">
                    Start Week
                </label>
            </div>
            <div class="col-lg-3 col-xl-2 px-0">
                <select id="select-start-week" formControlName="weekStart" class="form-control">
                    <option *ngFor="let w of weeks" [value]="w">{{ w }}</option>
                    </select>
            </div>
            <div class="col-lg-6 col-xl-2 p-0" >
                <label for="select-start-month" class="pt-2 ps-4 pb-0">
                    Start Period
                </label>
            </div>
            <div class="col-lg-3 col-xl-2 px-0">
                <select id="select-start-month" formControlName="monthStart" class="form-control">
                    <option *ngFor="let p of startPeriodOptions" [value]="p.week">{{ p.period }} {{ p.weekStartDate}}</option>
                    </select>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-6 col-xl-3">
                <select id="vendorSelect" formControlName="vendorId" class="form-control">
                <option [value]=""></option>
                <option *ngFor="let v of vendorOptions" [value]="v.id">{{v.company}}</option>
                </select>
            </div>
            <div class="col-lg-6 col-xl-3">
                <div id="regionSelect">
                    {{ allRegions }}   
                </div>
            </div>
            <div class="col-lg-6 col-xl-2 p-0 " >
                <label for="view-banners" class="pt-2 ps-4 pb-0">
                    View Banners
                </label>
            </div>
            <div class="col-lg-3 col-xl-2">
                <select id="view-banners" formControlName="viewBanners" class="form-control">
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                </select>
            </div>
            <div class="col-lg-6 col-xl-3">
            </div>
            <div class="col-lg-6 col-xl-2 p-0" >
                <label for="select-end-week" class="pt-2 ps-4 pb-0">
                    End Week
                </label>
            </div>
            <div class="col-lg-3 col-xl-2 px-0">
                <select id="select-end-week" formControlName="weekEnd" class="form-control">
                    <option *ngFor="let w of weeks" [value]="w">{{ w }}</option>
                    </select>
            </div>
            <div class="col-lg-6 col-xl-2 p-0" >
                <label for="select-end-month" class="pt-2 ps-4 pb-0">
                    End Period
                </label>
            </div>
            <div class="col-lg-3 col-xl-2 px-0">
                <select id="select-end-month" formControlName="monthEnd" class="form-control">
                    <option *ngFor="let p of endPeriodOptions" [value]="p.week">{{ p.period }} {{ p.weekEndDate}}</option>
                </select>
            </div>

        </div>

        <div class="row">
            <div class="col-lg-6 col-xl-3">
                <label for="customerSelect" class="pt-2">
                  *Customer:
                </label>
            </div>
            <!-- <div class="col-lg-6 col-xl-3">
                <label for="Dot-select" class="pt-2">
                  Dot Location:
                </label>
            </div> -->
        </div>
        <div class="row">
            <div class="col-lg-6 col-xl-3">
                <select id="customerSelect" formControlName="customerId" class="form-control">
                <option [value]=""></option>
                <option *ngFor="let c of customerOptions" [value]="c.id">{{c.company}}</option>
                </select>
            </div>
            <!-- <div class="col-lg-6 col-xl-3">
                <select id="Dot-select" formControlName="dotId" class="form-control">
                <option [value]=""></option>
                <option *ngFor="let d of dotOptions" [value]="d.id">{{d.location}}</option>
                </select>
            </div> -->
            <div class="col-lg-6 col-xl-3">
                <button *ngIf="alertType=='clear'" type="submit" class="btn btn-primary w-75" (click)="validate()" >Submit</button>
                <button *ngIf="alertType=='info'" type="button" class="btn btn-primary w-75" disabled>
                    <span class="spinner-border spinner-border-sm"></span>...Loading</button>
            </div>

        </div> 

        <!-- <div class="row">
            <div class="col-lg-6 col-xl-3">
                <label for="shipto-select" class="pt-2">
                  Ship To:
                </label>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-6 col-xl-3">
                <select id="shipto-select" formControlName="shipToId" class="form-control">
                <option [value]=""></option>
                <option *ngFor="let s of shipToOptions" [value]="s.id">{{s.company}}</option>
                </select>
            </div>
        </div>  -->
    </div>
</form>

<div style="overflow-x:scroll;width:100%;">
    <table class="table table-borderless table-sm"  style="width:100%;">
        <tbody *ngFor="let p of projectionsPrint">
            <tr *ngIf="p.tag == 'Header1'">
                <td></td>
                <td></td>
            </tr>
            <tr *ngIf="p.tag == 'Header7'">
                <td colspan="2">SHIP TO</td>
            </tr>
            <tr *ngIf="p.tag == 'Header8'">
                <td colspan="3" class="border border-1">{{ p.shipTo}}</td>
            </tr>
            <tr *ngIf="p.tag == 'Header1'" class="head-office-color">
                <td colspan="2">HEAD OFFICE</td>
                <td colspan="100%">REGION</td>
            </tr>
            <tr *ngIf="p.tag == 'Header2'" class="head-office-color">
                <td colspan="2" class="border border-1">{{ p.headOffice}}</td>
                <td colspan="100%">{{ p.bannerRegion }}</td>
            </tr>

            <tr *ngIf="p.tag == 'Header3'" >
                <td></td>
                <td></td>
            </tr>
            <tr *ngIf="p.tag == 'Header3'" class="banner-color">
                <td colspan="2">BANNERS</td>
                <td colspan="4">REGION</td>
                <td colspan="100%">No. Stores</td>
            </tr>
            <tr *ngIf="p.tag == 'Header4'" class="banner-color">
                <td colspan="2" class="border border-1">{{ p.headOffice}}</td>
                <td>{{ p.bannerRegion }}</td>
            </tr>

            <tr *ngIf="p.tag == 'Header4b'" class="banner-color">
                <td colspan="2">{{ p.bannerCompany}}</td>
                <td colspan="4">{{ p.bannerRegion }}</td>
                <td colspan="100%">{{ p.noStores }}</td>
            </tr>

            <tr *ngIf="p.tag == 'Header5'" [ngClass]="{'banner-color': p.tagColor=='Banner', 'head-office-color': p.tagColor=='HeadOffice' }">
                <td></td>
                <td></td>
                <td></td>
                <td *ngFor="let w of weekHeader" class="border border-1">{{w}}</td>                
            </tr>
            <tr *ngIf="p.tag == 'Header6'" [ngClass]="{'banner-color': p.tagColor=='Banner', 'head-office-color': p.tagColor=='HeadOffice' }">
                <td>ORDER</td>
                <td>ITEM DESCRIPTION</td>
                <td>CATEGORY</td>
                <td *ngFor="let w of weekNumberHeader" class="border border-1">{{ w }}</td> 
            </tr>
            <tr *ngIf="p.tag == ''"  [ngClass]="{'banner-color': p.tagColor=='Banner', 'head-office-color': p.tagColor=='HeadOffice' }">
                <td class="border border-1">
                    {{ p.productOrderCode }}
                </td>
                <td style="white-space:nowrap;" class="border border-1">
                    {{ p.itemDescription }}
                </td>
                <td style="white-space:nowrap;" class="border border-1">
                    {{ p.category }}
                </td>
                <!-- <td *ngFor="let w of p.weeks; index as i" class="border border-1" [ngClass]="{'promo-color': p.promotion[i]=='Y' && p.tagColor=='Banner'}">
                    {{ w ? w : '' }}
                </td> -->

                <td *ngFor="let w of p.weeks; index as i" class="border border-1" [ngClass]="{'promo-color': p.promotion[i]=='Y' && p.tagColor=='Banner'}" [ngbPopover]="popContent" [popoverTitle]="popTitle" placement="bottom">
                    <ng-template #popTitle>Promotional Week</ng-template> 
                    <ng-template #popContent> {{ p.promotionalNumber[i] }}</ng-template>
                    {{ w ? w : '' }}

                </td> 

    
            </tr>
            <ngb-alert *ngIf="successMessage" [type]="alertType" (close)="successMessage = null">{{ successMessage }}</ngb-alert>
        </tbody>
    </table>
</div>

<ngb-alert *ngIf="successMessage" [type]="alertType" (close)="successMessage = null">{{ successMessage }}</ngb-alert>
