import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { CustomerEnd } from '../../../../models/customer-end-model';
import { CustomerEndService } from '../../../../services/customer-end.service';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { Region } from 'src/app/models/region-model';
import { RegionService } from 'src/app/services/region.service';
import { TypeOfAccount } from 'src/app/models/type-of-account-model';
import { TypeOfAccountService } from 'src/app/services/type-of-account.service';
import { Customer } from 'src/app/models/customer.model';
import { CustomerService } from 'src/app/services/customer.service';
import { CustomerRetail } from 'src/app/models/customer-retail.model';
import { ShipToAddress } from 'src/app/models/shipto.model';
import { ShipToAddressService } from 'src/app/services/ship-to-address.service';
import { isTemplateExpression } from 'typescript';
import { CustomerHeadsService } from 'src/app/services/customer-heads.service';
import { CustomerHeads } from 'src/app/models/customer-heads.model';

@Component({
  selector: 'app-customer-end-edit',
  templateUrl: './customer-end-edit.component.html',
  styleUrls: ['./customer-end-edit.component.scss']
})
export class CustomerEndEditComponent implements OnInit {
  title = 'Customer Head Office New';
  newOne = true;
  customerEnd: CustomerEnd;
  customerEndForm: UntypedFormGroup;
  typeOfAcct: TypeOfAccount[];
  region: Region[];
  customer: Customer[];
  customerId: any;
  customerRetail: CustomerRetail[];
  shipTo: ShipToAddress[];
  shipToOriginal: ShipToAddress[];
  successMessage: string;
  retailRegion = " ";
  retailAccountType = " ";
  banners: any;
  grayRow = [];
  counter: number;
  customerHeads: CustomerHeads[];
  customers: any;
  customerSelected: [];
  customerIds: string;
  private success = new Subject<string>();
  staticAlertClosed = false;
  constructor(private route: ActivatedRoute,
    private router: Router,
    private fb: UntypedFormBuilder,
    private typeOfAcctSvc: TypeOfAccountService,
    private regionSvc: RegionService,
    private shipToSvc: ShipToAddressService,
    private customerSvc: CustomerService,
    private customerEndSvc: CustomerEndService,
    private customerHeadsSvc: CustomerHeadsService) {
    this.customerEnd = new CustomerEnd();
  }

  ngOnInit() {
    this.customerEndForm = this.fb.group({
      activeItem: [''],
      company: [''],
      address: [''],
      city: [''],
      comments: [''],
      countryRegion: [''],
      currencyCode: [''],
      eMail: [''],
      faxNumber: [''],
      fullAddress: [''],
      fullName: [''],
      jobTitle: [''],
      mapLinkOffice: [''],
      mapLinkWarehouse: [''],
      mobileNumber: [''],
      stateProvince: [''],
      title: [''],
      webPage: [''],
      zipPostalCode: [''],
      latitude: [''],
      longitude: [''],
      customerIds: ['']
      // shipToIds: ['']

    });
    this.banners = [];
    this.customerHeads = [];
    this.customers = [];
    this.customerEndSvc.getAll().subscribe(customerEnd => {
      this.customerEnd = customerEnd;
      this.typeOfAcctSvc.getAll().subscribe(typeOfAcct => {
        this.typeOfAcct = typeOfAcct;
        this.regionSvc.getAll().subscribe(region => {
          this.region = region;
          this.region.sort((a, b) => a.regionName < b.regionName ? -1 : a.regionName > b.regionName ? 1 : 0);
          this.customerSvc.getAll().subscribe(customer => {
            this.customer = customer;
            this.customerEndSvc.getFilter().subscribe(data => {
              this.customerRetail = data.item;
              // this.shipToSvc.getAll().subscribe(shipTo => {
              //   this.shipTo = shipTo;
              //   this.shipToOriginal = shipTo;
                this.route.data.subscribe(data => {
                  if (data.dataCustomerEnd !== undefined) {
                    this.title = 'Customer Head Office Edit';
                    this.newOne = false;
                    this.customerEnd = data.dataCustomerEnd;
                    this.customerSvc.getCustomer(this.customerEnd.id).subscribe(data => {
                      this.customerId = data.length > 0 ? data[0] : 0;
                    this.customerHeadsSvc.getHeads(this.customerEnd.id).subscribe(data => {
                      this.customerHeads = data;
                      this.customerIds = '';
                      this.customerHeads.forEach(data => {
                        this.customerIds += ' ' + data.customerId;
                        
                      })
                      this.customerIds = this.customerIds.trim();
                      // this.shipToSvc.getAll().subscribe(ship => {
                      //   this.shipToOriginal = ship;
                        this.fillFormData();
                      //   this.onChanges();
                      //   this.setupShipTo(this.customerEnd.customerId);
                        this.customerEndSvc.getFilteredListing("Active", this.customerEnd.id, 1, 999).subscribe(data => {
                          this.retailRegion = ' ';
                          this.retailAccountType = ' ';
                          data.item.forEach(e => {
                            // const customerHead = {
                            //   customer: e.customerId,
                            //   customerHead: e.customerHeadId,
                            //   customerHeadId: e.customerHeadId
                            // };
                            // this.customers.push(customerHead);
                            // if (e.customerHeadId > 0) {
                            //   const index = this.customer.findIndex(data => data.id == e.customerId);
                            // }
                            if (e.retailAccountType) {
                              if (this.retailAccountType.indexOf(e.retailAccountType) == -1) {
                                this.retailAccountType += " " + e.retailAccountType;
                                this.retailAccountType.trim();
                              }
                            }
                            if (e.retailRegion) {
                              if (this.retailRegion.indexOf(e.retailRegion) == -1) {
                                this.retailRegion += " " + e.retailRegion;
                                this.retailRegion.trim();
                              }
                            }
                          })
                        })
                      })
                    })
                    // this.fillFormData();
                      
                    // })  
                  } else {
                    this.title = 'Customer Head Office New';
                    this.newOne = true;
                    this.customerEnd = new CustomerEnd();
                    this.customerEndForm.reset();
                    this.customerHeads = [];
                    // this.shipToSvc.getAll().subscribe(ship => {
                    //   this.shipToOriginal = ship;
                    //   this.onChanges();
                    //   this.setupShipTo(this.customerEnd.customerId);
                    // })
                  }
                })
              // })
            })
          })
        })
      })
    });
    setTimeout(() => this.staticAlertClosed = true, 20000);
    this.success.subscribe((message) => this.successMessage = message);
    this.success.pipe(
      debounceTime(5000)
    ).subscribe(() => this.successMessage = null);
  }
  fillFormData() {
    this.customerEndForm.setValue({
      activeItem: this.customerEnd.activeItem,
      company: this.customerEnd.company,
      address: this.customerEnd.address,
      city: this.customerEnd.city,
      comments: this.customerEnd.comments,
      countryRegion: this.customerEnd.countryRegion,
      currencyCode: this.customerEnd.currencyCode,
      eMail: this.customerEnd.eMail,
      faxNumber: this.customerEnd.faxNumber,
      fullAddress: this.customerEnd.fullAddress,
      fullName: this.customerEnd.fullName,
      jobTitle: this.customerEnd.jobTitle,
      mapLinkOffice: this.customerEnd.mapLinkOffice,
      mapLinkWarehouse: this.customerEnd.mapLinkWarehouse,
      mobileNumber: this.customerEnd.mobileNumber,
      stateProvince: this.customerEnd.stateProvince,
      title: this.customerEnd.title,
      webPage: this.customerEnd.webPage,
      zipPostalCode: this.customerEnd.zipPostalCode,
      latitude: this.customerEnd.latitude,
      longitude: this.customerEnd.longitude,
      customerIds: this.customerIds
      // shipToIds: this.splitNumbers(this.customerEnd.shipToIds)

    });
  }
  // onChanges(): void {
  //   this.customerEndForm.get('customerId').valueChanges.subscribe(val => {
  //     this.setupShipTo(val);
  //   });
  // }
  // splitNumbers(ids) {
  //   let ret = [];
  //   if (ids) {
  //     let retCount = 0;
  //     const tempSplit = ids.split(',');
  //     if (tempSplit.length > 0) {
  //       tempSplit.forEach(id => {
  //         if (id) {
  //           ret.push(id * 1);
  //         }
  //       })
  //     }
  //   }
  //   return ret;
  // }
  // setupShipTo(custid) {
  //   this.shipTo = [];
  //   if (custid) {
  //     this.shipToOriginal.forEach(x => {
  //       if (x.customerId == custid) {
  //         this.shipTo.push(x);
  //       }
  //     })
  //   }
  // }
  getCustomerEndWeeks() {
    this.router.navigate(['./other/week-customer-end-list'], {
      state:
      {
        customerHeadId: this.customerEnd.id,
      }
    });
  }
  // updateCustomerHeads() {
  //   this.counter++;
  //   if (this.counter <= this.customerSelected.length) {
  //     const index = this.counter -1;
  //     let x = new CustomerHeads();
  //     x.customerHeadId = 0;
  //     x.customerId = this.customerSelected[index];
  //     x.headId = this.customerEnd.id;
  //     this.customerHeadsSvc.create(x).subscribe(data => {
  //       this.updateCustomerHeads();
  //     });
  //   } else {
  //     this.customerHeadsSvc.getHeads(this.customerEnd.id).subscribe(data => {
  //       this.customerHeads = data;
  //     })
  //   }
  // }
  // deleteCustomerHeads() {
  //   this.counter++;
  //   if (this.counter > this.customerHeads.length) {
  //     this.counter = 0;
  //     this.updateCustomerHeads();
  //   } else {
  //     const index = this.counter -1;
  //     this.customerHeadsSvc.remove(this.customerHeads[index].customerHeadId).subscribe(data => {
  //       this.deleteCustomerHeads();
  //     }, error => {
  //       this.deleteCustomerHeads();
  //     })
  //   }

  // }
  onSubmit() {
    this.customerEnd.activeItem = this.customerEndForm.controls.activeItem.value;
    this.customerEnd.company = this.customerEndForm.controls.company.value;
    this.customerEnd.address = this.customerEndForm.controls.address.value;
    this.customerEnd.city = this.customerEndForm.controls.city.value;
    this.customerEnd.comments = this.customerEndForm.controls.comments.value;
    this.customerEnd.countryRegion = this.customerEndForm.controls.countryRegion.value;
    this.customerEnd.currencyCode = this.customerEndForm.controls.currencyCode.value;
    this.customerEnd.eMail = this.customerEndForm.controls.eMail.value;
    this.customerEnd.faxNumber = this.customerEndForm.controls.faxNumber.value;
    this.customerEnd.fullAddress = this.customerEndForm.controls.fullAddress.value;
    this.customerEnd.fullName = this.customerEndForm.controls.fullName.value;
    this.customerEnd.jobTitle = this.customerEndForm.controls.jobTitle.value;
    this.customerEnd.mapLinkOffice = this.customerEndForm.controls.mapLinkOffice.value;
    this.customerEnd.mapLinkWarehouse = this.customerEndForm.controls.mapLinkWarehouse.value;
    this.customerEnd.mobileNumber = this.customerEndForm.controls.mobileNumber.value;
    this.customerEnd.stateProvince = this.customerEndForm.controls.stateProvince.value;
    this.customerEnd.webPage = this.customerEndForm.controls.webPage.value;
    this.customerEnd.zipPostalCode = this.customerEndForm.controls.zipPostalCode.value;
    this.customerEnd.longitude = this.customerEndForm.controls.longitude.value;
    this.customerEnd.latitude = this.customerEndForm.controls.latitude.value;
    // this.customerSelected = this.customerEndForm.controls.customerIds.value;
    // this.counter = 0;

    if (this.newOne) {
      this.customerEndSvc.create(this.customerEnd).subscribe(data => {
        this.customerEnd.id = data.id;
        // this.deleteCustomerHeads();
        this.router.navigate(['/customers/customer-end-edit', this.customerEnd.id]);
      }, error => {
      });
    } else {
      this.customerEndSvc.update(this.customerEnd).subscribe(data => {
        // this.deleteCustomerHeads();
        this.success.next(`Successfully changed.`);
      }, error => {
      });
    }
  }
  onDelete() {
    if (this.customerEnd.id) {
      this.customerEndSvc.remove(this.customerEnd.id).subscribe(data => {
        this.router.navigate(['/customers/customer-end-list']);
      });
    }
  }
}
