import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-profit-loss',
  templateUrl: './profit-loss.component.html',
  styleUrls: ['./profit-loss.component.scss']
})
export class ProfitLossComponent implements OnInit {
  @Input() title: string;
  @Input() order: any;
  @Input() items: any;
  @Input() itemsUSD: any;
  @Input() totalCases: any;
  @Input() totalShipped: any;
  @Input() totalInvoiced: any;
  @Input() totalExtCost: any;
  @Input() totalMarketing: any;
  @Input() totalBilling: any;
  @Input() totalTerms: any;
  @Input() totalCommission: any;
  @Input() totalKalcor: any;
  @Input() cndFrtCostCase: any;
  @Input() storageCostCase: any;
  @Input() totalCndFrtCostCase: any;
  @Input() totalStorageCostCase: any;
  @Input() totalPurchaseCaseCostExt: any;
  @Input() totalFreightCostExt: any;
  @Input() totalClearing: any;
  @Input() totalDuty: any;
  @Input() totalRoyalty: any;
  @Input() totalVendor: any;
  @Input() budgetActual: any;
  @Input() usdVendorCost: any;
  @Input() usdFrtCost: any;
  @Input() usdClearing: any;
  @Input() usdDuty: any;
  @Input() usdRoyalty: any;
  @Input() indexMarketing: any;
  @Input() indexBilling: any;
  @Input() indexTerms: any;
  @Input() indexCommission: any;
  @Input() indexKalcor: any;
  @Input() indexFreightCnd: any;
  @Input() indexStorage: any;
  @Input() indexVendor: any;
  @Input() indexClearing: any;
  @Input() indexRoyalty: any;
  @Input() indexDuty: any;
  @Input() indexFreightUSD: any;
  @Input() indexFobDiff: any;
  constructor() { }

  ngOnInit() {
    this.order.exchangeActual = this.budgetActual[12].actualAmount;
  }
  displayDate(dt, t) {
    
    if (dt) {
      if (t === undefined) {
        t = '';
      }
      let x = new Date(dt);
      if (t == 'T') {
        return x.toLocaleDateString() + ' @ ' + x.toLocaleTimeString();
      }
      return x.toLocaleDateString();
    } 
    return dt

  }

}
