import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { UpcGtn } from '../../../models/upc-gtn.model';
import { UpcGtnService } from '../../../services/upc-gtn.service';
import { Vendor } from '../../../models/vendor-model';
import { VendorService } from '../../../services/vendor.service';
import { Brand } from '../../../models/brand.model';
import { BrandService } from '../../../services/brand.service';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { SorterService } from '../../../services/sorter.service';

@Component({
  selector: 'app-upc-gtn-edit',
  templateUrl: './upc-gtn-edit.component.html',
  styleUrls: ['./upc-gtn-edit.component.scss']
})
export class UpcGtnEditComponent implements OnInit {
  title = 'UPC/GTN New';
  newOne = true;
  upcGtn: UpcGtn;
  upcGtnForm: UntypedFormGroup;
  successMessage: string;
  private success = new Subject<string>();
  staticAlertClosed = false;
  vendor: Vendor[] = [];
  brand: Brand[] = [];
  currentVendor = '';

  constructor(private route: ActivatedRoute,
              private router: Router,
              private fb: UntypedFormBuilder,
              private upcGtnService: UpcGtnService,
              private brandService: BrandService,
              private sortSvc: SorterService,
              private vendorService: VendorService) { }

  ngOnInit() {
    this.upcGtnForm = this.fb.group({
      activeStatus: [''],
      vendorOrderCode: [''],
      upc: [''],
      gtn14: [''],
      upcArea: [''],
      brandId: [''],
      vendor: [{ value: '', disabled: true }]
    });
    this.brandService.getAll().subscribe(brand => {
      this.brand = brand;
    // this.brandService.getFilteredListing('Active', 0, 0, 30, 1).subscribe(brand => {
    //   this.brand = brand.item3;
      this.brand = this.sortSvc.sorter(this.brand, 'brand');
      this.vendorService.getAll().subscribe(data => {
        this.vendor = data;
        this.route.data.subscribe(data2 => {
          if (data2.dataUpcGtn !== undefined) {
            this.title = 'UPC/GTN Edit';
            this.newOne = false;
            this.upcGtn = data2.dataUpcGtn;
            this.upcGtn.activeStatus = this.upcGtn.activeStatus.trim();
            this.fillFormData();
            this.onChanges();
          } else {
            this.title = 'UPC/GTN New';
            this.newOne = true;
            this.upcGtn = new UpcGtn();
            this.upcGtnForm.reset();
            this.onChanges();
          }
        });
        
      });
    });
    setTimeout(() => this.staticAlertClosed = true, 20000);
    this.success.subscribe((message) => this.successMessage = message);
    this.success.pipe(
      debounceTime(5000)
    ).subscribe(() => this.successMessage = null);
  }
  onChanges(): void {

    this.upcGtnForm.get('brandId').valueChanges.subscribe(val => {
      this.getVendor(val * 1);
    });

  }
  getVendor(id) {
    let ret = '';
    if (id) {
      const brandIndex = this.brand.findIndex(item => item.id === id);
      if (brandIndex >  -1) {
        const vendorId = this.brand[brandIndex].vendorId;
        const index = this.vendor.findIndex(item => item.id === vendorId);
        if (index >  -1) {
          ret = this.vendor[index].company;
        }
      }
    }
    this.currentVendor = ret;
    this.upcGtnForm.controls.vendor.setValue(this.currentVendor);
    return ret;
  }
  fillFormData() {
    this.upcGtnForm.setValue({
      activeStatus: this.upcGtn.activeStatus,
      vendorOrderCode: this.upcGtn.vendorOrderCode,
      upc: this.upcGtn.upc,
      gtn14: this.upcGtn.gtn14,
      brandId: this.upcGtn.brandId,
      upcArea: this.upcGtn.upcArea,
      vendor: this.getVendor(this.upcGtn.brandId)
    });
  }
  onSubmit() {
    this.upcGtn.activeStatus = this.upcGtnForm.controls.activeStatus.value;
    this.upcGtn.vendorOrderCode = this.upcGtnForm.controls.vendorOrderCode.value;
    this.upcGtn.upc = this.upcGtnForm.controls.upc.value;
    this.upcGtn.gtn14 = this.upcGtnForm.controls.gtn14.value;
    this.upcGtn.upcArea = this.upcGtnForm.controls.upcArea.value;
    this.upcGtn.brandId = this.upcGtnForm.controls.brandId.value;
    if (this.newOne) {
      this.upcGtnService.create(this.upcGtn).subscribe(data => {
        this.upcGtn.id = data.id;
        this.newOne = false;
        this.title = 'UPC/GTN Edit';
        this.success.next(`Successfully added.`);
      }, error => {
      });
    } else {
      this.upcGtnService.update(this.upcGtn).subscribe(data => {
        this.success.next(`Successfully changed.`);
      }, error => {
      });
    }
  }
  onDelete() {
    if (this.upcGtn.id) {
      this.upcGtnService.remove(this.upcGtn.id).subscribe(data => {
        this.router.navigate(['/items/upc-gtn-list']);
      });
    }
  }
}
