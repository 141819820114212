<h4>Shipped List</h4>

<table class="table table-striped">
  <thead>
  <tr>
    <th scope="col">Order Status</th>
    <th scope="col">Order Type</th>
    <th scope="col">Sales Order No</th>
    <th scope="col">Purchase Order</th>
    <th scope="col">Sales Order Date</th>
    <th scope="col">Shipment Date</th>
    <th scope="col">Delivered Date/Appt</th>
    <th scope="col">Bill To</th>
    <th scope="col">Ship To</th>
    <th scope="col">Warehouse</th>
    <th scope="col">Broker</th>
    </tr>
  </thead>
  <tbody>
  <tr *ngFor="let o of orders | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize" (click)="onRowClicked(o)">
    <td>{{ o.orderStatus }}</td>
    <td>{{ o.orderType }}</td>
    <td>{{ o.salesOrderNo }}</td>
    <td>{{ o.purchaseOrder }}</td>
    <td>{{ displayDate(o.salesOrderDate, '') }}</td>
    <td>{{ displayDate(o.shipmentDate, '') }}</td>
    <td>{{ displayDate(o.deliveredDateAppt, 'T') }}</td>
    <td>{{ o.billTo }}</td>
    <td>{{ o.shipTo }}</td>
    <td>{{ o.warehouse }}</td>
    <td>{{ o.broker }}</td>
  </tr>
  <div *ngIf="orders!==undefined">
  <ngb-pagination
  [(page)]="page"
  [pageSize]="pageSize"
  [collectionSize]="orders?.length"></ngb-pagination>
  </div>
  </tbody>
</table>
