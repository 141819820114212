import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { ShipToAddress } from '../../../models/shipto.model';
import { ShipToAddressService } from '../../../services/ship-to-address.service';

@Injectable()
export class ShipToAddressResolver implements Resolve<ShipToAddress> {

  constructor(private shipToAddressService: ShipToAddressService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ShipToAddress> {
    const idField = 'id';
    const shipToId = parseInt(route.params[idField], 10);

    if (shipToId === 0) {
      return;
    }
    return this.shipToAddressService.getOne(shipToId );
  }
}
