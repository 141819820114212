import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
const url = environment.kalcorApiUrl;

@Injectable({
  providedIn: 'root'
})
export class CreditBatchService {

  constructor(private http: HttpClient) { }

  getAll(): Observable<any> {
    return this.http.get(url + 'CreditBatches', this.jwt());
  }
  getOpen(): Observable<any> {
    return this.http.get(url + 'CreditBatches/Open', this.jwt());
  }

  getOne(id: number): Observable<any> {
    return this.http.get(url + 'CreditBatches/' + id, this.jwt());
  }

  getLast(): Observable<any> {
    return this.http.get(url + 'CreditBatches/Last', this.jwt());
  }

  getOneBatch(batchNo: number): Observable<any> {
    return this.http.get(url + 'CreditBatches/' + batchNo + '/Batch', this.jwt());
  }

  update(credit) {
    return this.http.put(url + 'CreditBatches/' + credit.creditBatchId, credit, this.jwt());
  }
  create(credit): Observable<any> {
    return this.http.post(url + 'CreditBatches', credit, this.jwt());
  }
  remove(id) {
    return this.http.delete(url + 'CreditBatches/' + id, this.jwt());
  }
  private jwt() {
    // create authorization header with jwt token
    const currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
    if (currentUser && currentUser.token) {
        const headersInfo = new HttpHeaders({ Authorization: 'Bearer ' + currentUser.token });
        return { headers: headersInfo };
    }
  }  
}
