import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { CreditReasons } from 'src/app/models/credit-reasons.model';
import { CreditReasonsService } from 'src/app/services/credit-reasons.service';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { error } from 'console';

@Component({
  selector: 'app-credit-reasons-edit',
  templateUrl: './credit-reasons-edit.component.html',
  styleUrls: ['./credit-reasons-edit.component.scss']
})
export class CreditReasonsEditComponent implements OnInit{
  title = 'Credit Reason New';
  newOne = true;
  creditReason: CreditReasons;
  creditReasonForm: UntypedFormGroup;
  successMessage: string;
  private success = new Subject<string>();
  staticAlertClosed = false;
  alertType = "success";

  constructor(private route: ActivatedRoute,
              private router: Router,
              private fb: UntypedFormBuilder,
              private creditReasonsService: CreditReasonsService) {
                this.creditReason = new CreditReasons();
              }

  ngOnInit() {
    this.creditReasonForm = this.fb.group({
      creditReason1: ['', Validators.required]
    });
    this.route.data.subscribe(data => {
      if (data.dataCreditReasons !== undefined) {
        this.title = 'Credit Reasons Edit';
        this.newOne = false;
        this.creditReason = data.dataCreditReasons;
        this.fillFormData();
      } else {
        this.title = 'Credit Reasons New';
        this.newOne = true;
        this.creditReason = new CreditReasons();
        this.creditReasonForm.reset();
      }
    });
    setTimeout(() => this.staticAlertClosed = true, 20000);
    this.success.subscribe((message) => this.successMessage = message);
    this.success.pipe(
      debounceTime(5000)
    ).subscribe(() => this.successMessage = null);
  }
  fillFormData() {
    this.creditReasonForm.setValue({
      creditReason1: this.creditReason.creditReason1
    });
  }
  onSubmit() {
    if (this.creditReasonForm.invalid) {
      this.success.next('Please enter required field.');
      this.alertType = "danger";
      return;
    }
    this.creditReason.creditReason1 = this.creditReasonForm.controls.creditReason1.value;
    if (this.newOne) {
      this.creditReasonsService.create(this.creditReason).subscribe(data => {
        this.creditReason.id = data.id;
        this.newOne = false;
        this.title = 'Credit Reasons Edit';
        this.success.next('Successfully added.');
      }, error => {
      });
    } else {
      this.creditReasonsService.update(this.creditReason).subscribe(data => {
        this.success.next('Successfully changed.');
      }, error => {
      });
    }
  }
  onDelete() {
    if (this.creditReason.id) {
      this.creditReasonsService.remove(this.creditReason.id).subscribe(data => {
        this.router.navigate(['/other/credit-reasons-list']);
      });
    }
  }
}
