<h5>{{ title }} </h5>
<form [formGroup]="regionForm" (ngSubmit)="onSubmit()" novalidate>
    <div class="form-group">
        <div class="col-lg-44">
            <label>
                Active Item:
                <select formControlName="active" class="form-control">
                    <option value="Active">Active</option>
                    <option value="Not Active">Not Active</option>
                </select>
            </label>
        </div>


        <div class="col-lg-44">
            <label>
                Region:
                <input type="text" formControlName="regionName" class="form-control" size="60">
            </label>
        </div>

        <div class="col-lg-44">
            <label>
                Province:
                <input type="text" formControlName="province" class="form-control" size="60">
            </label>
        </div>

        <button type="submit" class="btn btn-primary mt-3 w-25">Save</button>
        <button type="button" class="btn btn-secondary ml-3 mt-3 w-25" (click)="onDelete()">Delete</button>
    </div>
    <ngb-alert *ngIf="successMessage" type="success" (close)="successMessage = null">{{ successMessage }}
    </ngb-alert>
</form>