import { Component, OnInit } from '@angular/core';
import { BrandService } from '../../../services/brand.service';
import { ActivatedRoute, Router } from '@angular/router';
@Component({
  selector: 'app-brand-list',
  templateUrl: './brand-list.component.html',
  styleUrls: ['./brand-list.component.scss']
})
export class BrandListComponent implements OnInit {
  brand: any;
  activeItemSelected: string;
  vendorSelected: number;
  vendorOptions:[];
  brandSelected: number;
  brandOptions:[];
  page: number;
  pageSize: number;
  count: number;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private brandService: BrandService) { }

  ngOnInit() {
    this.page = 1;
    this.pageSize = 30;
    this.activeItemSelected = 'Active';
    this.brandSelected = 0;
    this.vendorSelected = 0;
    this.route.data.subscribe(data => {
      this.brand = data.dataBrand.item;
      this.vendorOptions = data.dataBrand.item2;
      this.brandOptions = data.dataBrand.item3;
      this.count = data.dataBrand.count;
    });
  }
  
  loadPage() {
    this.brandService.getFilteredListing(this.activeItemSelected, this.brandSelected, this.vendorSelected, this.page, this.pageSize).subscribe(data => {
      this.brand = data.item;
      this.vendorOptions  = data.item2;
      this.brandOptions = data.item3;
      this.count = data.count;
    });
  }
  
  reset() {
    this.page = 1;
    this.pageSize = 30;
    this.activeItemSelected = 'Active';
    this.brandSelected = 0;
    this.vendorSelected = 0;
    this.loadPage();    
  }
  onRowClicked(clear) {
    this.router.navigate(['/items/brand-details', clear.id]);
  }

}
