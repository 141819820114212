import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { CreditShipToBroker } from '../models/credit-model';
const url = environment.kalcorApiUrl;
@Injectable({
  providedIn: 'root'
})
export class CreditShiptoService {

  constructor(private http: HttpClient) { }

  getAll(): Observable<any> {
    return this.http.get(url + 'CreditShipToBrokers', this.jwt());
  }

  getOne(id: number): Observable<any> {
    return this.http.get(url + 'CreditShipToBrokers/' + id, this.jwt());
  }
  
  getBroker(id: number): Observable<any> {
    return this.http.get(url + 'CreditShipToBrokers/' + id + '/Listing', this.jwt());
  }

  update(creditShipToBroker: CreditShipToBroker) {
    return this.http.put(url + 'CreditShipToBrokers/' + creditShipToBroker.creditShipToBrokerId, creditShipToBroker, this.jwt());
  }

  create(creditShipToBroker: CreditShipToBroker): Observable<any> {
    return this.http.post(url + 'CreditShipToBrokers', creditShipToBroker, this.jwt());
  }
  remove(id: number) {
    return this.http.delete(url + 'CreditShipToBrokers/' + id, this.jwt());
  }

  private jwt() {
    // create authorization header with jwt token
    const currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
    if (currentUser && currentUser.token) {
        const headersInfo = new HttpHeaders({ Authorization: 'Bearer ' + currentUser.token });
        return { headers: headersInfo };
    }

  }
}
