import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
const url = environment.kalcorApiUrl;

@Injectable({
  providedIn: 'root'
})
export class FundsTransferService {

  constructor(private http: HttpClient) { }
  getAll(): Observable<any> {
    return this.http.get(url + 'FundsTransfers', this.jwt());
  }
  getOne(id: number): Observable<any> {
    return this.http.get(url + 'FundsTransfers/' + id, this.jwt());
  }
  getFilter(status: string, transferNo: string, billTo: number, vendorId: number, page: number, pageSize: number): Observable<any> {
    return this.http.get(url + 'FundsTransfers/Filter?status=' + status.trim() + 
          '&transferNo=' + transferNo + 
          '&billTo=' + billTo + 
          '&vendorId=' + vendorId + 
          '&page=' + page + '&pageSize=' + pageSize, this.jwt());
  }
  getBucketList(year: number, month:[], vendorId: number, customerId: number, brokerId: number, bucket: string,): Observable<any> {
    return this.http.get(url + 'FundsTransfers/Vendor?year=' + year + '&month=' + 
            month + '&vendorId=' + vendorId + '&customerId=' + customerId + '&brokerId=' + brokerId + '&bucket=' + bucket, this.jwt());
  }
  update(transfer) {
    return this.http.put(url + 'FundsTransfers/' + transfer.id, transfer, this.jwt());
  }
  create(transfer): Observable<any> {
    return this.http.post(url + 'FundsTransfers', transfer, this.jwt());
  }
  remove(id) {
    return this.http.delete(url + 'FundsTransfers/' + id, this.jwt());
  }
  private jwt() {
    // create authorization header with jwt token
    const currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
    if (currentUser && currentUser.token) {
        const headersInfo = new HttpHeaders({ Authorization: 'Bearer ' + currentUser.token });
        return { headers: headersInfo };
    }
  }

}
