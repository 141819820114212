import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { 
  OrderListComponent,
  OrderEditComponent,
  OrderResolver,
  OrderItemsResolver,
  OrderItemsEditComponent
 } from './index';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SalesRoutingModule } from '../sales-routing.module';
import { ReactiveFormsModule } from '@angular/forms';
import { SalesOrderComponent } from './pdf/sales-order/sales-order.component';
import { VendorSalesOrderComponent } from './pdf/vendor-sales-order/vendor-sales-order.component';
import { PackingSlipComponent } from './pdf/packing-slip/packing-slip.component';
import { ShippingEditComponent } from './shipping-edit/shipping-edit.component';
import { FormsModule } from '@angular/forms';
import { ShippingComponent } from './pdf/shipping/shipping.component';
import { ShippedListComponent } from './shipped-list/shipped-list.component';
import { InvoicingEditComponent } from './invoicing-edit/invoicing-edit.component';
import { InvoicingComponent } from './pdf/invoicing/invoicing.component';
import { ConfirmDialogModule } from '../components/confirm-dialog/confirm-dialog.module';
import { InvoicingCustomerComponent } from './pdf/invoicing-customer/invoicing-customer.component';


@NgModule({
  declarations: [OrderListComponent, OrderEditComponent, OrderItemsEditComponent, SalesOrderComponent, VendorSalesOrderComponent, PackingSlipComponent, ShippingEditComponent, ShippingComponent, ShippedListComponent, InvoicingEditComponent, InvoicingComponent, InvoicingCustomerComponent],
  imports: [
    CommonModule,
    NgbModule,
    FormsModule,
    SalesRoutingModule,
    ReactiveFormsModule,
    ConfirmDialogModule
  ],
  providers: [
    OrderResolver,
    OrderItemsResolver
  ]
})
export class OrderModule { }
