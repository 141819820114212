import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from "@angular/router";
import { Observable } from "rxjs";
import { GstHst } from "src/app/models/gst-hst.model";
import { GstHstService } from "src/app/services/gst-hst.service";

@Injectable()
export class GstHstResolver implements Resolve<GstHst> {
    constructor(private gstHstService: GstHstService) {
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<GstHst> {
        const idField = 'id';
        const id = parseInt(route.params[idField], 10);

        if (id === 0) {
            return;
        }
        return this.gstHstService.getOne(id);
    }
}