import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { PickupLocation } from '../../../../models/pickup-location.model';
import { Vendor } from '../../../../models/vendor-model';
import { PickupLocationService } from '../../../../services/pickup-location.service';
import { VendorService } from '../../../../services/vendor.service';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
@Component({
  selector: 'app-pickup-location-detail',
  templateUrl: './pickup-location-detail.component.html',
  styleUrls: ['./pickup-location-detail.component.scss']
})
export class PickupLocationDetailComponent implements OnInit {
  title = 'Pickup Address New';
  newOne = true;
  vendor: Vendor[] = [];
  pickupLocation: PickupLocation;
  pickupLocationForm: UntypedFormGroup;
  successMessage: string;
  private success = new Subject<string>();
  staticAlertClosed = false;
  constructor(private route: ActivatedRoute,
              private router: Router,
              private fb: UntypedFormBuilder,
              private pickupLocationSvc: PickupLocationService,
              private vendorSvc: VendorService) { }

  ngOnInit() {
    this.pickupLocationForm = this.fb.group({
      activeItem: [''],
      company: [''],
      address: [''],
      city: [''],
      stateProvince: [''],
      countryRegion: [''],
      zipPostalCode: [''],
      fullName: [''],
      jobTitle: [''],
      businessPhone: [''],
      faxNumber: [''],
      mobileNumber: [''],
      eMail: [''],
      webPage: [''],
      comments: [''],
      loadingTime: [''],
      puAppointments: [''],
      notes: [''],
      vendorId: [''],
      latitude: [''],
      longitude: ['']      
    });
    this.vendorSvc.getAll().subscribe(data3 => {
      this.vendor = data3;
      this.route.data.subscribe(data4 => {
        if (data4.dataPickupLocation !== undefined) {
          this.title = 'Pickup Address Edit';
          this.newOne = false;
          this.pickupLocation = data4.dataPickupLocation;
          this.fillFormData();
        } else {
          this.title = 'Pickup Address New';
          this.newOne = true;
          this.pickupLocation = new PickupLocation();
          this.pickupLocationForm.reset();
        }
      });
    });
    setTimeout(() => this.staticAlertClosed = true, 20000);
    this.success.subscribe((message) => this.successMessage = message);
    this.success.pipe(
      debounceTime(5000)
    ).subscribe(() => this.successMessage = null);
  }
  fillFormData() {
    this.pickupLocationForm.setValue({
      activeItem: this.pickupLocation.activeItem,
      company: this.pickupLocation.company,
      address: this.pickupLocation.address,
      city: this.pickupLocation.city,
      stateProvince: this.pickupLocation.stateProvince,
      countryRegion: this.pickupLocation.countryRegion,
      zipPostalCode: this.pickupLocation.zipPostalCode,
      fullName: this.pickupLocation.fullName,
      jobTitle: this.pickupLocation.jobTitle,
      businessPhone: this.pickupLocation.businessPhone,
      faxNumber: this.pickupLocation.faxNumber,
      mobileNumber: this.pickupLocation.mobileNumber,
      eMail: this.pickupLocation.eMail,
      webPage: this.pickupLocation.webPage,
      comments: this.pickupLocation.comments,
      loadingTime: this.pickupLocation.loadingTime,
      puAppointments: this.pickupLocation.puAppointments,
      notes: this.pickupLocation.notes,
      vendorId: this.pickupLocation.vendorId,
      latitude: this.pickupLocation.latitude,
      longitude: this.pickupLocation.longitude
    });
  }
  onSubmit() {
    this.pickupLocation.activeItem = this.pickupLocationForm.controls.activeItem.value;
    this.pickupLocation.company = this.pickupLocationForm.controls.company.value;
    this.pickupLocation.address = this.pickupLocationForm.controls.address.value;
    this.pickupLocation.city = this.pickupLocationForm.controls.city.value;
    this.pickupLocation.stateProvince = this.pickupLocationForm.controls.stateProvince.value;
    this.pickupLocation.countryRegion = this.pickupLocationForm.controls.countryRegion.value;
    this.pickupLocation.zipPostalCode = this.pickupLocationForm.controls.zipPostalCode.value;
    this.pickupLocation.fullName = this.pickupLocationForm.controls.fullName.value;
    this.pickupLocation.jobTitle = this.pickupLocationForm.controls.jobTitle.value;
    this.pickupLocation.businessPhone = this.pickupLocationForm.controls.businessPhone.value;
    this.pickupLocation.faxNumber = this.pickupLocationForm.controls.faxNumber.value;
    this.pickupLocation.mobileNumber = this.pickupLocationForm.controls.mobileNumber.value;
    this.pickupLocation.eMail = this.pickupLocationForm.controls.eMail.value;
    this.pickupLocation.webPage = this.pickupLocationForm.controls.webPage.value;
    this.pickupLocation.comments = this.pickupLocationForm.controls.comments.value;
    this.pickupLocation.loadingTime = this.pickupLocationForm.controls.loadingTime.value;
    this.pickupLocation.puAppointments = this.pickupLocationForm.controls.puAppointments.value;
    this.pickupLocation.notes = this.pickupLocationForm.controls.notes.value;
    this.pickupLocation.vendorId = this.pickupLocationForm.controls.vendorId.value;
    this.pickupLocation.latitude = this.pickupLocationForm.controls.latitude.value;
    this.pickupLocation.longitude = this.pickupLocationForm.controls.longitude.value;
    
    if (this.newOne) {
      this.pickupLocationSvc.create(this.pickupLocation).subscribe(data => {
        this.pickupLocation.id = data.id;
        this.router.navigate(['/vendors/pickup-location-edit', this.pickupLocation.id]);
        }, error => {
      });
    } else {
      this.pickupLocationSvc.update(this.pickupLocation).subscribe(data => {
        this.success.next(`Successfully changed.`);
      }, error => {
      });
    }
  }
  onDelete() {
    if (this.pickupLocation.id) {
      this.pickupLocationSvc.remove(this.pickupLocation.id).subscribe(data => {
        this.router.navigate(['/vendors/pickup-location-list']);
      });
    }
  }
}
