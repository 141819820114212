import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from "@angular/router";
import { Observable } from "rxjs";
import { BillingAgent } from "src/app/models/billing-agent.model";
import { BillingAgentService } from "src/app/services/billing-agent.service";

@Injectable()
export class BillingAgentResolver implements Resolve<BillingAgent> {
    constructor(private billingAgentService: BillingAgentService) {
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<BillingAgent> {
        const idField = 'id';
        const id = parseInt(route.params[idField], 10);

        if (id === 0) {
            return;
        }
        return this.billingAgentService.getOne(id);
    }
}