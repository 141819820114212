import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BorderCrossingService } from '../../../services/border-crossing.service';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
@Component({
  selector: 'app-border-crossing-list',
  templateUrl: './border-crossing-list.component.html',
  styleUrls: ['./border-crossing-list.component.scss']
})
export class BorderCrossingListComponent implements OnInit {
  successMessage: string;
  private success = new Subject<string>();
  alertType = "success";
  staticAlertClosed = false;
  borderCrossing: any;
  page = 1;
  pageSize = 30;
  constructor(
    private router: Router,
    private borderCrossingService: BorderCrossingService
  ) { }

  ngOnInit() {
    this.borderCrossingService.getAll().subscribe(data => {
      this.borderCrossing = data
    },error => {
      this.successMessage = 'Unable to process request';
      this.alertType = "danger";
    });
    setTimeout(() => this.staticAlertClosed = true, 20000);
    this.success.subscribe((message) => this.successMessage = message);
    this.success.pipe(
      debounceTime(5000)
    ).subscribe(() => this.successMessage = null); 
  }
  onRowClicked(border) {
    this.router.navigate(['/other/border-crossing-edit', border.id]);
  }

}
