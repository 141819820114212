import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable, observable } from 'rxjs';
import { CustomerHeads } from '../models/customer-heads.model';
const url = environment.kalcorApiUrl;

@Injectable({
  providedIn: 'root'
})
export class CustomerHeadsService {

  constructor(private http: HttpClient) { }
  getAll(): Observable<any> {
    return this.http.get(url + 'CustomerHeads', this.jwt());
  }
  getOne(id: number): Observable<any> {
    return this.http.get(url + 'CustomerHeads/' + id, this.jwt());
    //id = customerHeadId
  }
  getCustomers(id: number): Observable<any> {
    return this.http.get(url + 'CustomerHeads/' + id + '/Customer', this.jwt());
    //customerId = id. returns both customerIds and headIds
  }
  getHeads(id: number): Observable<any> {
    return this.http.get(url + 'CustomerHeads/' + id + '/Head', this.jwt());
    //headId = id. returns both customerIds and headIds
  }
  update(customerHeads) {
    return this.http.put(url + 'CustomerHeads/' + customerHeads.id, customerHeads, this.jwt());
  }
  create(customerHeads): Observable<any> {
    return this.http.post(url + 'CustomerHeads', customerHeads, this.jwt());
  }
  remove(id) {
    return this.http.delete(url + 'CustomerHeads/' + id, this.jwt());
  }


  private jwt() {
    // create authorization header with jwt token
    const currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
    if (currentUser && currentUser.token) {
        const headersInfo = new HttpHeaders({ Authorization: 'Bearer ' + currentUser.token });
        return { headers: headersInfo };
    }
  }
}
