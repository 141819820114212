import { Component, OnInit, Input} from '@angular/core';
@Component({
  selector: 'app-vendor-sales-order',
  templateUrl: './vendor-sales-order.component.html',
  styleUrls: ['./vendor-sales-order.component.scss']
})
export class VendorSalesOrderComponent implements OnInit {
  @Input() title: string;
  @Input() order: any;
  @Input() orderItems: any;
  @Input() totalCases: number;
  @Input() totalPallets: number;
  @Input() totalGrossLbs: number;
  @Input() totalCaseCost: number;
  @Input() totalGrosskg: number;
  @Input() vendorPurchaseCaseCost: boolean;
  @Input() fOBNetVendorCost: boolean;
  costType: string;
  grayRow = [];
  
  constructor() { }

  ngOnInit() {
    this.costType = this.vendorPurchaseCaseCost ? "Vendor Purchase Case Cost" : "FOB Net Vendor Cost";
    let stripe = 'S';
    this.orderItems.forEach(element => {
      stripe = stripe == 'S' ? '' : 'S';
      this.grayRow.push(stripe);
    });
    
  }
  
  displayDate(dt, t) {
    
    if (dt) {
      if (t === undefined) {
        t = '';
      }
      let x = new Date(dt);
      if (t == 'T') {
        return x.toLocaleDateString() + ' @ ' + x.toLocaleTimeString();
      }
      return x.toLocaleDateString();
    } 
    return dt
  }
}
