import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { LkupProductGroups } from '../models/lkup-product-group.model';
const url = environment.kalcorApiUrl;

@Injectable({
  providedIn: 'root'
})
export class LkupProductGroupService {

  constructor(private http: HttpClient) { }

  getAll(): Observable<any> {
    return this.http.get(url + 'LkupProductsGroups', this.jwt());
  }
  getOne(id: number): Observable<any> {
    return this.http.get(url + 'LkupProductsGroups/' +id, this.jwt());
  }
  getGroupFilter(vendorId: number, page: number, pageSize: number): Observable<any> {
    page = page ? page : 1;
    pageSize = pageSize ? pageSize : 30;
    return this.http.get(url + 'LkupProductsGroups/GroupFilter?vendorId=' + vendorId + '&page=' + page + '&pageSize=' + pageSize, this.jwt());
  }
  update(lkupProductGroup) {
    return this.http.put(url + 'LkupProductsGroups/' + lkupProductGroup.id, lkupProductGroup, this.jwt());
  }
  create(lkupProductGroup): Observable<any> {
    return this.http.post(url + 'LkupProductsGroups', lkupProductGroup, this.jwt());
  }
  remove(id: number) {
    return this.http.delete(url + 'LkupProductsGroups/' + id, this.jwt());
  }

  private jwt() {
    // create authorization header with jwt token
    const currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
    if (currentUser && currentUser.token) {
        const headersInfo = new HttpHeaders({ Authorization: 'Bearer ' + currentUser.token });
        return { headers: headersInfo };
    }
  }
}
