import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { DataLinkList } from '../../../models/data-link.model';
import { DataLinkService } from '../../../services/data-link.service';
@Injectable()
export class DataLinkListResolver implements Resolve<DataLinkList> {
  dataLinkList: DataLinkList;
  constructor(private dataLinkService: DataLinkService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    const yearField = 'year';
    const year =  parseInt(route.params[yearField], 10);

    if (year === 0) {
      return;
    }
    return this.dataLinkService.getList(year, 1, 35);
  }
}