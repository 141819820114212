<div>
    <h4>Credit List</h4>
    <h6>Filter</h6>
    <div class="col-lg-44">
      <label>
        Credit Status:
        <select [(ngModel)]="creditStatusSelected" (change)="resetFilters()">
          <option [value]=""></option>
          <option *ngFor="let o of creditStatusOptions" [value]="o">{{ o }}</option>
        </select>
      </label>

      <label class="ms-3">
        Bill to:
        <select [(ngModel)]="billToSelected" (change)="resetFilters()">
          <option [value]=""></option>
          <option *ngFor="let b of billToOptions" [value]="b.billToId">{{ b.billToName }}</option>
        </select>
      </label>

      <label class="ms-3">
        Vendor:
        <select [(ngModel)]="vendorSelected" (change)="resetFilters()">
          <option [value]=""></option>
          <option *ngFor="let v of vendorOptions" [value]="v.vendorId">{{ v.vendorName }}</option>
        </select>
      </label>

      <label class="ms-3">
        Batch No:
        <select [(ngModel)]="batchNoSelected" (change)="resetFilters()">
          <option *ngFor="let b of batchNoOptions" [value]="b.batchNo">{{ b.batchNo }}</option>
        </select>
      </label>

      <label class="ms-3">
        Type of Batch:
        <select [(ngModel)]="batchTypeSelected" (change)="resetFilters()">
          <option *ngFor="let b of batchTypeOptions" [value]="b.batchType">{{ b.batchType }}</option>
        </select>
      </label>
<!--       
      <label class="ms-3">
        Credit No:
        <select [(ngModel)]="creditNumberSelected" (change)="resetFilters()">
          <option [value]=""></option>
          <option *ngFor="let o of creditNumberOptions" [value]="o.creditNo">{{ o.creditNo }}</option>
        </select>
      </label> -->
  
      <!-- <label class="ms-3">
        Purchase Order:
        <select [(ngModel)]="purchaseOrderSelected" (change)="resetFilters()">
          <option [value]=""></option>
          <option *ngFor="let p of purchaseOrderOptions" [value]="p.purchaseOrder">{{ p.purchaseOrder }}</option>
        </select>
      </label> -->
      
<!--       <fieldset>
        <div class="mb-3 row">
          <label for="typeahead-http" class="col-xs-3 col-sm-auto col-form-label">Search for a wiki page:</label>
          <div class="col">
              <input id="typeahead-http" type="text" class="form-control" [class.is-invalid]="searchFailed" [(ngModel)]="model" [ngbTypeahead]="search" placeholder="Wikipedia search" />
            <small *ngIf="searching" class="form-text text-muted">searching...</small>
            <div class="invalid-feedback" *ngIf="searchFailed">Sorry, suggestions could not be loaded.</div>
          </div>
        </div>
      </fieldset> -->

    <fieldset>
      <div class="mb-3 pt-2 row">
      <label for="customerCredit" class="col-xs-3 col-sm-auto col-form-label">
        Customer Credit No: </label>
        <div class="col-lg-8">
        <input id="customerCredit" type="text" class="form-control" [class.is-invalid]="searchFailed" 
        [(ngModel)]="customerCreditNo" [ngbTypeahead]="search" placeholder="Credit Number Search" (selectItem)="itemSelected($event)"/>
        </div>
        <small *ngIf="searching" class="form-text text-muted">searching...</small>
        <div class="invalid-feedback" *ngIf="searchFailed">Sorry, credit number does not exist.</div>
      </div>
    </fieldset>




      <button type="button" class="btn btn-primary mt-0 ms-3" (click)="getDefaultDataScreen()">Reset</button>
  
    </div>
    <table class="table table-striped">
        <thead>
          <tr>
            <th scope="col">Credit Status</th>
            <th scope="col">Credit Type</th>
            <th scope="col">Credit No.</th>
            <th scope="col">Purchase Order</th>
            <th scope="col">Credit Date</th>
            <th scope="col">Customer Credit No.</th>
            <th scope="col">Bill To</th>
            <th scope="col">Vendor</th>
            <th scope="col">Credit/Promotion Explanation</th>
            <th scope="col">Promo Start Date</th>
            <th scope="col">Promo End Date</th>
            <th scope="col">Broker</th>
            <th scope="col">Broker 2</th>
            <th scope="col">Brand</th>
            <th scope="col">Category</th>
            <th scope="col">Cases</th>
            <th scope="col">Bill Back per Case</th>
            <th scope="col">Bucket</th>
            <th scope="col">Credit Amount</th>
            <th scope="col">Credit</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let c of credit; index as i">
            <td>{{ c.creditStatus }}</td>
            <td>{{ c.creditType }}</td>
            <td>{{ c.creditNo }}</td>
            <td>{{ c.purchaseOrder }}</td>
            <td>{{ displayDate(c.creditDate, '') }}</td>
            <td>{{ c.customerCreditNo }}</td>
            <td>{{ c.billToName }}</td>
            <td>{{ c.vendorName }}</td>
            <td>{{ c.creditPromotionExplaination }}</td>
            <td>{{ displayDate(c.promoStartDate, '') }}</td>
            <td>{{ displayDate(c.promoEndDate, '') }}</td>
            <td>{{ c.broker }}</td>
            <td>{{ c.broker2 }}</td>
            <td>{{ c.brand }}</td>
            <td>{{ c.category }}</td>
            <td>{{ c.cases }}</td>
            <td>{{ c.billBackPerCase | currency  }}</td>
            <td>{{ c.bucket }}</td>
            <td>{{ c.creditAmount | currency  }}</td>
            <td>
              <a (click)="goToEdit(i)" class="pointer">
                <u class="text-primary">Credit</u>
              </a>
            </td>
          </tr>
        </tbody>
      </table>
      <div *ngIf="credit!==undefined">
        <ngb-pagination [(page)]="page" [pageSize]="pageSize" [collectionSize]="collectionSize" [maxSize]="5"
          (pageChange)="resetFilters()"></ngb-pagination>
      </div>
</div>
