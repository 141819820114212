import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CommissionData } from 'src/app/models-azure/commission-data.model';
import { DataLink } from 'src/app/models/data-link.model';
import { DataLinkService } from 'src/app/services/data-link.service';
import { Vendor } from 'src/app/models/vendor-model';
import { VendorService } from 'src/app/services/vendor.service';
import { WeekKalcorService } from 'src/app/services/week-kalcor.service';
import { CommissionService } from 'src/app/services-azure/commission.service';
import { OrderService } from 'src/app/services/order.service';
import { Transfer } from 'src/app/models/bucket.model';
import { ShipToAddress } from 'src/app/models/shipto.model';
import { Broker } from 'src/app/models/broker.model';
import { BrokerPayment } from 'src/app/models/broker.model';
import { ShipToAddressService } from 'src/app/services/ship-to-address.service';
import { BrokerService } from 'src/app/services/broker.service';
import { Big } from 'big.js';

@Component({
  selector: 'app-commission-link',
  templateUrl: './commission-link.component.html',
  styleUrls: ['./commission-link.component.scss']
})
export class CommissionLinkComponent {
  year = [];
  buttonLabel = 'Add more Vendors';
  vendorYear = [];
  items = [];
  buttonClicker = [];
  buttonRemover = [];
  selectedVendor: number;
  processIndexItem: number;
  // processTotalCount: number;
  processIndex: number;
  processIndexCases: number;
  processIndexIncoming: number;
  processIndexIncomingItems: number;
  processIndexOutgoing: number;
  processIndexOutgoingItems: number;
  processIndexTransfering: number;
  processIndexTransferingItems: number;
  processIndexPaying: number;
  processIndexPayingItems: number;
  firstPost = true;
  vendorId: number;
  totalCount: number;
  // itemCounter: number;
  itemCount: number;
  // processIndexMarketing: number;
  dataLink: DataLink;
  vendor: Vendor[];
  dataLinks: DataLink[];
  yearRequested: any;
  processVendIndex: number;
  page: number;
  pageSize = 35;
  count: number;
  periods = [];
  kstLineCount = 0;
  kstTotalExtension = 0;
  order: any;
  orderType = 'Both Retail/Food Service';
  bucket = 'Commission';
  report = 'Actual';
  monthSelected: any;
  brokerSelected: number;
  new: any;
  balfwd: number;
  balanceForwardCredit: number;
  balanceForwardActual: number;
  balanceForwardBudget: number;
  balanceForwardTransfer: number;
  balanceForwardPayment: number;
  creditData: any;
  balFwdBeginningMonth = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  balFwdAfterIncomingMonth = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  balFwdOutgoingMonth = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  balFwdTransferingMonth = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  balEndCurrentMonth = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  incoming = [[],[],[],[],[],[],[],[],[],[],[],[],[],[]];
  outgoing = [[],[],[],[],[],[],[],[],[],[],[],[],[],[]];
  transfering = [[],[],[],[],[],[],[],[],[],[],[],[],[],[]];
  paying = [[],[],[],[],[],[],[],[],[],[],[],[],[],[]];
  transfer: Transfer[];
  periodName = '';
  shipTo: ShipToAddress[];
  brokers: Broker[];
  payment: BrokerPayment[];
  monthName = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];
  spinner = [];

constructor(
  private router: Router,
  private activatedRoute: ActivatedRoute,
  private dataLinkService: DataLinkService,
  private vendorService: VendorService,
  private weekKalcorService: WeekKalcorService,
  private commissionService: CommissionService,
  private orderService: OrderService,
  private shipToService: ShipToAddressService,
  private brokerService: BrokerService

) {}

ngOnInit() {
  this.periods = [];
  this.monthSelected = [];
  this.monthSelected.push("1");
  this.monthSelected.push("12");
  this.shipToService.getAll().subscribe(ship => {
    this.shipTo = ship;
    this.brokerService.getAll().subscribe(b => {
      this.brokers = b;
    })
  })
  this.displayItems();
}
displayItems() {
  const theDate = new Date();
  const middle = theDate.getFullYear();
  this.year.push(2025);
  this.year.push(2024);
  this.year.push(2023);
  this.year.push(2022);
  this.year.push(2021);
  this.year.push(2020);
  this.spinner.push('');
  this.spinner.push('');
  this.spinner.push('');
  this.spinner.push('');
  this.spinner.push('');
  this.spinner.push('');
}
clickedItem(i: number) {
  this.router.navigate(['/portal/commission-data', this.year[i]]);
}
run(i: number) {
  this.yearRequested = this.year[i];
  this.spinner[i] = '...Updating';
  this.weekKalcorService.getYear(this.yearRequested).subscribe(data => {
    this.periods = data;
    this.loadVendorYear();
  })
}
loadVendorYear() {
  this.dataLinkService.getList(this.yearRequested, this.page, this.pageSize).subscribe(data => {
    this.vendorYear = data.item;
    this.page = data.page;
    this.count = data.count;
    this.processIndex = -1;
    this.initializeVendors();
  })
}
initializeVendors() {
  //process one vendor at a time
  this.processIndex++;
  if (this.processIndex >= this.vendorYear.length) {
    //all vendors have been processed update status
    this.updateStatus();
  } else {
    this.firstPost = true;
    this.getItems();
  }
}
getItems() {  
  this.vendorId = this.vendorYear[this.processIndex].vendorId;
  this.commissionService.getFilter(this.vendorYear[this.processIndex].salesYear, this.vendorYear[this.processIndex].vendorId, 0, 0).subscribe(data => {
    this.items = data.item;
    if (this.items.length > 0) {
      this.processIndexItem = -1;
      this.deleteItems();
    } else {
      this.processIndexItem = -1;
      this.getCommissionData();
    }
  }, error => {
    alert('Error from azzure:' + error.message)
  }) 
}
getCommissionData() {
  this.vendorYear[this.processIndex].status = "Updating";
  this.totalCount = 0;
  this.balfwd = 0;
  this.brokerSelected = 0;
  this.incoming = [[],[],[],[],[],[],[],[],[],[],[],[],[],[]];
  this.outgoing = [[],[],[],[],[],[],[],[],[],[],[],[],[],[]];
  this.transfering = [[],[],[],[],[],[],[],[],[],[],[],[],[],[]];
  this.paying = [[],[],[],[],[],[],[],[],[],[],[],[],[],[]];
  this.orderService.getBucketList(this.vendorYear[this.processIndex].salesYear, this.monthSelected, this.vendorYear[this.processIndex].vendorId, 0, 0, this.bucket, this.report, this.orderType).subscribe(data => {
    this.order = data.order; 
    this.creditData = data.creditData;
    this.transfer = data.transferData;
    this.payment = data.paymentData;
    this.balanceForwardCredit = data.balanceForwardCredit;
    this.balanceForwardActual = data.balanceForwardActual;
    this.balanceForwardBudget = data.balanceForwardBudget;
    this.balanceForwardTransfer = data.balanceForwardTransfer;
    this.balanceForwardPayment = data.balanceForwardPayment;
    const balance = this.computeBalFwd();
    this.balfwd = balance;
    // this.balfwd = this.balanceForwardBudget - this.balanceForwardCredit + this.balanceForwardTransfer - this.balanceForwardPayment;
    this.loadArrays();
    this.totalCount = 0;
    this.processIndexIncoming = -1;
    this.processIndexIncomingItems = -1;
    this.processIndexOutgoing = -1;
    this.processIndexOutgoingItems = -1;
    this.processIndexTransfering = -1;
    this.processIndexTransferingItems = -1;
    this.processIndexPaying = -1;
    this.processIndexPayingItems = -1;
    this.kstLineCount = 0;
    this.kstTotalExtension = 0;
    if (this.firstPost == true) {
      this.insertBalFwd();
      this.firstPost = false;
    } 
    this.initializeIncoming();
  })
}
computeBalFwd() {
  let bigNum: any;
  bigNum = Big(this.balanceForwardBudget)
  .minus(this.balanceForwardCredit)
  .plus(this.balanceForwardTransfer)
  .minus(this.balanceForwardPayment)
  .round(2)
  return bigNum * 1;
}
insertBalFwd() {
  const year = this.vendorYear[this.processIndex].salesYear;
  let firstDate = new Date(Date.UTC(year, 0, 2, 0, 0, 0));
  let dateFormat = firstDate.toISOString();
  let x = new CommissionData();
  x.id = 0;
  x.category = 'Balance Forward';
  x.period = this.getPeriodName(dateFormat);
  x.year = year;
  x.date = firstDate;
  x.vendor = this.vendorYear[this.processIndex].vendorName;
  x.vendorId = this.vendorYear[this.processIndex].vendorId;
  x.balance = this.balFwdBeginningMonth[0];
  this.create(x);
}
loadArrays() {
  this.order.forEach(element => {
    // Splits items into incoming and outgoing arrays by period
    // 7.10 Commission 2 commented out throughout to be used at a later date. 
    const period = this.getPeriod(element.shipmentDate);
    element.budgetAmount = this.getCommission(element.brokerId, element.commission1);
    element.commPct = this.getCommissionPct(element.brokerId, element.commPct1);
    // element.budgetAmount = this.getCommission(element.brokerId, element.commission1, element.broker2Id, element.commission2);
    // element.commPct = this.getCommissionPct(element.brokerId, element.commPct1, element.broker2Id, element.commPct2);
    element.broker = this.getBroker(element.brokerId, element.broker, element.broker2Id, element.broker2);
    this.incoming[period].push(element);
  });
  this.creditData.forEach(element => {
    const period = this.getPeriod(element.creditDate);
    const itemCount = element.items.length;
    if (itemCount == 0) {
      element.brokerCompany = this.getBroker(element.brokerId, element.brokerCompany, element.brokerId2, element.brokerCompany2);
      element.commissionPct = this.getCommissionPct(element.brokerId, element.commission1),
      // element.commissionPct = this.getCommissionPct(element.brokerId, element.commission1, element.brokerId2, element.commission2),
      element.shipTo = '';
      this.outgoing[period].push(element);
    } else {
      element.items.forEach(item => {
        if (this.brokerSelected == 0 || this.brokerSelected == item.brokerId || this.brokerSelected == item.brokerId2) {
          const newItem = {
            balance: element.balance,
            brokerCompany: this.getBroker(item.brokerId, "", item.brokerId2, ""),
            brokerCompany2: element.brokerCompany2,
            brokerId: item.brokerId,
            brokerId2: item.brokerId2,
            bucket: element.bucket,
            commissionPct: this.getCommissionPct(item.brokerId, item.commission1),
            // commissionPct: this.getCommissionPct(item.brokerId, item.commission1, item.brokerId2, item.commission2),
            commission1: item.commission1,
            commission2: item.commission2,
            creditAmount: item.commissionDollars1 + item.commissionDollars2,
            creditDate: element.creditDate,
            creditNo: element.creditNo,
            creditPromotionExplaination: element.creditPromotionExplaination,
            creditReason: element.creditReason1,
            creditStatus: element.creditStatus,
            creditType: element.creditType,
            customerCompany: element.customerCompany,
            customerCreditNo: element.customerCreditNo,
            customerId: element.customerId,
            debit: element.debit,
            purchaseOrder: element.purchaseOrder,
            vendorCompany: element.vendorCompany,
            vendorId: element.vendorId,
            shipTo: this.getShipTo(item.shipToId)
          }
          this.outgoing[period].push(newItem);
        }
      });
    }
  });
  this.transfer.forEach(element => {
     const period = this.getPeriod(element.transferDate);
     this.transfering[period].push(element);
  });
  this.payment.forEach(e => {
    const index = this.monthName.findIndex( mo => mo == e.month);
    if (index > -1) {
      this.paying[index].push(e);
    }
  })

  this.balFwdBeginningMonth.forEach((element, index) => {
    this.balFwdBeginningMonth[index] = this.balfwd;
    element = this.balfwd;
    this.balEndCurrentMonth[index] = this.balfwd;
    this.incoming[index].forEach(element2 => {
      this.balEndCurrentMonth[index] += element2.budgetAmount; 
      element2.balance = this.balEndCurrentMonth[index];
    })
    this.outgoing[index].forEach(element3 => {
      element3.debit = 0;
      const commDollar = this.computeCommission(element3.creditAmount, element3.commission1)
      const commDollar2 = this.computeCommission(element3.creditAmount, element3.commission2)

      if (this.brokerSelected == 0 || this.brokerSelected === element3.brokerId) {
        element3.debit += commDollar;
      }
      // if (this.brokerSelected == 0 || this.brokerSelected === element3.brokerId2) {
      //   element3.debit += commDollar2;
      // }
      this.balEndCurrentMonth[index] -= element3.debit;
      element3.balance = this.balEndCurrentMonth[index];
    })

    this.transfering[index].forEach(element4 => {
      if (element4.fromBucket == this.bucket) {
        element4.debit = element4.transferAmount;
      } else {
        element4.credit = element4.transferAmount;
      }
      
      this.balEndCurrentMonth[index] += element4.credit - element4.debit;
      element4.balance = this.balEndCurrentMonth[index];
    })

    this.paying[index].forEach(e => {
      this.balEndCurrentMonth[index] -= e.paymentAmount;
      e.balance = this.balEndCurrentMonth[index];
    })

    this.balfwd = this.balEndCurrentMonth[index];
  })
}
getShipTo(shipToId) {
  let ship = '';
  const index = this.shipTo.findIndex(ship => ship.id == shipToId);
  if (index > -1) {
    ship = this.shipTo[index].company;
  }
  return ship;
}
getBroker(id, broker1, id2, broker2) {
  let broker = '';
  broker1 = typeof broker1 == 'undefined' || !broker1 ? '' : broker1;
  broker2 = typeof broker2 == 'undefined' || !broker2 ? '' : broker2;
  id = typeof id == 'undefined' || id == null ? 0 : id * 1;
  id2 = typeof id2 == 'undefined' || id2 == null ? 0 : id2 * 1;

  if (broker1 == '') {
    const index = this.brokers.findIndex(brok => brok.id == id);
    if (index > -1) {
      broker1 = this.brokers[index].company;
    }
  }

  if (broker2 == '') {
    const index = this.brokers.findIndex(brok => brok.id == id2);
    if (index > -1) {
      broker2 = '';
      // broker2 = this.brokers[index].company;
    }
  }

  if (this.brokerSelected > 0) {
     if (this.brokerSelected != id) {
      broker1 = '';
     }
     if (this.brokerSelected != id2) {
       broker2 = '';
     }
  }

  if (broker1 != '' && broker2 != '') {
    broker = broker1
    // broker = broker1 + '/' + broker2
  } else {
    broker += broker1;
    // broker += broker2;
  }
 
  return broker;
}
// getCommissionorg(id, comm1, id2, comm2) {
//   let commissionAmount = 0;
//   if (this.brokerSelected == 0 || this.brokerSelected === id) {
//     commissionAmount += comm1;
//   }
//   if (this.brokerSelected == 0 || this.brokerSelected === id2) {
//     commissionAmount += comm2;
//   }
//   return commissionAmount;
// }
getCommission(id, comm1) {
  let commissionAmount = 0;
  if (this.brokerSelected == 0 || this.brokerSelected === id) {
    commissionAmount += comm1;
  }
  return commissionAmount;
}
// getCommissionPctorg(id, comm1, id2, comm2) {
//   let commissionPct = 0;
//   if (this.brokerSelected == 0 || this.brokerSelected === id) {
//     commissionPct += comm1;
//   }
//   if (this.brokerSelected == 0 || this.brokerSelected === id2) {
//     commissionPct += comm2;
//   }
//   return commissionPct *.01;
// }
getCommissionPct(id, comm1) {
  let commissionPct = 0;
  if (this.brokerSelected == 0 || this.brokerSelected === id) {
    commissionPct += comm1;
  }
  return commissionPct *.01;
}
computeCommission(credit, commPct) {
  credit = credit ? credit : 0;
  commPct = commPct ? commPct : 0;

  let bigNum: any;
  bigNum = Big(credit)
  .times(commPct)
  .times(.01)
  .round(2)
  return bigNum * 1;
}
getPeriod(date) {
  let retValue;
  const baseDate = date.substring(0, 10);
  for (let i = 0; i < this.periods.length; i++) {
    if (baseDate >= this.periods[i].weekStartDate && baseDate <= this.periods[i].weekEndDate) {
      let period = this.periods[i].period;
      retValue = period;
    }
  }
  return retValue;
}
getPeriodName(date) {
  let retValue;
  const baseDate = date.substring(0, 10);
  for (let i = 0; i < this.periods.length; i++) {
    if (baseDate >= this.periods[i].weekStartDate && baseDate <= this.periods[i].weekEndDate) {
      let periodYear = "0" + this.periods[i].period + "-PERIOD";             
      if (this.periods[i].period > 9) {
        periodYear = this.periods[i].period + "-PERIOD";
      } 
      retValue = periodYear;
    }
  }
  return retValue;
}
initializeIncoming() {
  //begin inserting data into azure starting with incoming array
  this.processIndexIncoming++;
  if (this.processIndexIncoming >= this.incoming.length) {
    this.processIndexOutgoing = -1;
    this.initializeOutgoing();
  } else {
    if (this.incoming[this.processIndexIncoming].length == 0) {
      this.initializeIncoming();
    }
    this.processIndexIncomingItems = -1;
    this.insertIncoming();
  }
}
insertIncoming() {
  //insert into azure incoming array one subarray at a time
  this.processIndexIncomingItems++;
  if (this.processIndexIncomingItems >= this.incoming[this.processIndexIncoming].length) {
    this.initializeIncoming();
  } else {
    this.totalCount++;
    this.vendorYear[this.processIndex].counter = this.totalCount;
    const shipDate = this.incoming[this.processIndexIncoming][this.processIndexIncomingItems].shipmentDate;
    let dateFormat = new Date(this.incoming[this.processIndexIncoming][this.processIndexIncomingItems].shipmentDate);
    let x = new CommissionData();
    x.id = 0;
    x.category = 'Incoming';
    x.period = this.getPeriodName(shipDate);
    x.year = dateFormat.getFullYear();
    x.status = this.incoming[this.processIndexIncoming][this.processIndexIncomingItems].orderStatus;
    x.type = this.incoming[this.processIndexIncoming][this.processIndexIncomingItems].orderType;
    x.no = this.incoming[this.processIndexIncoming][this.processIndexIncomingItems].salesOrderNo;
    x.purchaseOrder = this.incoming[this.processIndexIncoming][this.processIndexIncomingItems].purchaseOrder;
    x.date = shipDate;
    x.customer = this.incoming[this.processIndexIncoming][this.processIndexIncomingItems].billTo;
    x.vendor = this.incoming[this.processIndexIncoming][this.processIndexIncomingItems].vendor;
    x.vendorId = this.incoming[this.processIndexIncoming][this.processIndexIncomingItems].vendorId;
    x.shipTo = this.incoming[this.processIndexIncoming][this.processIndexIncomingItems].shipTo;
    x.broker = this.incoming[this.processIndexIncoming][this.processIndexIncomingItems].broker;
    x.broker2 = this.incoming[this.processIndexIncoming][this.processIndexIncomingItems].broker2;
    x.salesAmount = this.incoming[this.processIndexIncoming][this.processIndexIncomingItems].salesAmount;
    x.commissionIncPercent = this.incoming[this.processIndexIncoming][this.processIndexIncomingItems].commPct;
    x.commissionInc = this.incoming[this.processIndexIncoming][this.processIndexIncomingItems].commission1;
    // x.commissionInc = this.incoming[this.processIndexIncoming][this.processIndexIncomingItems].budgetAmount;
    x.balance = 0;

    const ext = x.balance === null ? 0 : x.balance;
    this.kstTotalExtension += ext;
    this.kstLineCount += ext > 0 ? 1 : 0;
    
    this.create(x);
    this.insertIncoming(); 
  }
}
create(row) {
  this.commissionService.create(row).subscribe(data => {
  })
}
initializeOutgoing () {
  //continue inserting data into azure now dealing with outgoing array
  this.processIndexOutgoing++;
  if (this.processIndexOutgoing >= this.outgoing.length) {
    this.processIndexTransfering = -1;
    this.initializeTransfering();
  } else {
    if (this.outgoing[this.processIndexOutgoing].length == 0) {
      this.initializeOutgoing();
    }
    this.processIndexOutgoingItems = -1;
    this.insertOutgoing();
  }
}
insertOutgoing() {
  //insert into azure outgoing array one subarray at a time
  this.processIndexOutgoingItems++;
  if (this.processIndexOutgoingItems >= this.outgoing[this.processIndexOutgoing].length) {
    this.initializeOutgoing();
  } else {
    this.totalCount++;
    this.vendorYear[this.processIndex].counter = this.totalCount;
    const creditDate = this.outgoing[this.processIndexOutgoing][this.processIndexOutgoingItems].creditDate;
    let dateFormat = new Date(this.outgoing[this.processIndexOutgoing][this.processIndexOutgoingItems].creditDate);
    let x = new CommissionData();
    x.id = 0;
    x.category = 'Outgoing';
    x.period = this.getPeriodName(creditDate);
    x.year = dateFormat.getFullYear();
    x.status = this.outgoing[this.processIndexOutgoing][this.processIndexOutgoingItems].creditStatus;
    x.type = this.outgoing[this.processIndexOutgoing][this.processIndexOutgoingItems].creditType;
    x.no = this.outgoing[this.processIndexOutgoing][this.processIndexOutgoingItems].creditNo;
    x.purchaseOrder = this.outgoing[this.processIndexOutgoing][this.processIndexOutgoingItems].purchaseOrder;
    x.date = creditDate;
    x.customer = this.outgoing[this.processIndexOutgoing][this.processIndexOutgoingItems].customerCompany;
    x.vendor = this.outgoing[this.processIndexOutgoing][this.processIndexOutgoingItems].vendorCompany;
    x.vendorId = this.outgoing[this.processIndexOutgoing][this.processIndexOutgoingItems].vendorId;
    x.shipTo = this.outgoing[this.processIndexOutgoing][this.processIndexOutgoingItems].shipTo;
    x.broker = this.outgoing[this.processIndexOutgoing][this.processIndexOutgoingItems].brokerCompany;
    // x.broker2 = this.outgoing[this.processIndexOutgoing][this.processIndexOutgoingItems].broker2;
    x.customerCreditNo = this.outgoing[this.processIndexOutgoing][this.processIndexOutgoingItems].customerCreditNo;
    x.creditAmount = this.outgoing[this.processIndexOutgoing][this.processIndexOutgoingItems].creditAmount;
    x.notes = this.outgoing[this.processIndexOutgoing][this.processIndexOutgoingItems].creditPromotionExplaination;
    x.creditReason = this.outgoing[this.processIndexOutgoing][this.processIndexOutgoingItems].creditReason;
    x.commissionOut = this.outgoing[this.processIndexOutgoing][this.processIndexOutgoingItems].debit;
    x.commissionOutPercent = this.outgoing[this.processIndexOutgoing][this.processIndexOutgoingItems].commissionPct;
    x.balance = 0;


    const ext = x.balance === null ? 0 : x.balance;
    this.kstTotalExtension += ext;
    this.kstLineCount += ext > 0 ? 1 : 0;

    this.create(x);
    this.insertOutgoing(); 
  }
}
initializeTransfering() {
  //continue inserting data into azure now dealing with transferring array
  this.processIndexTransfering++;
  if (this.processIndexTransfering >= this.transfering.length) {
    //continue inserting data into azure now dealing with paying array
    this.processIndexPaying = -1;
    this.initializePaying();
  } else {
    if (this.transfering[this.processIndexTransfering].length == 0) {
      this.initializeTransfering();
    }
    this.processIndexTransferingItems = -1;
    this.insertTransfering();
  }
}
insertTransfering() {
  //insert into azure transferring array one subarray at a time
  this.processIndexTransferingItems++;
  if (this.processIndexTransferingItems >= this.transfering[this.processIndexTransfering].length) {
    this.initializeTransfering();
  } else {
    this.totalCount++;
    this.vendorYear[this.processIndex].counter = this.totalCount;
    const transferDate = this.transfering[this.processIndexTransfering][this.processIndexTransferingItems].transferDate;
    const bucketFrom = this.transfering[this.processIndexTransfering][this.processIndexTransferingItems].fromBucket;
    const bucketTo = this.transfering[this.processIndexTransfering][this.processIndexTransferingItems].toBucket;
    let dateFormat = new Date(this.transfering[this.processIndexTransfering][this.processIndexTransferingItems].transferDate);
    let x = new CommissionData();
    x.id = 0;
    x.category = 'Transfering';
    x.period = this.getPeriodName(transferDate);
    x.year = dateFormat.getFullYear();
    x.status = this.transfering[this.processIndexTransfering][this.processIndexTransferingItems].transferStatus;
    x.type = this.transfering[this.processIndexTransfering][this.processIndexTransferingItems].orderType1;
    x.no = this.transfering[this.processIndexTransfering][this.processIndexTransferingItems].transferNo;
    x.date = transferDate;
    x.customer = this.transfering[this.processIndexTransfering][this.processIndexTransferingItems].billTo;
    x.vendor = this.transfering[this.processIndexTransfering][this.processIndexTransferingItems].vendor;
    x.vendorId = this.transfering[this.processIndexTransfering][this.processIndexTransferingItems].vendorId;
    x.credit = this.transfering[this.processIndexTransfering][this.processIndexTransferingItems].credit;
    x.debit = this.transfering[this.processIndexTransfering][this.processIndexTransferingItems].debit;
    x.balance = 0;
    x.notes = this.transfering[this.processIndexTransfering][this.processIndexTransferingItems].notes;
    x.bucketTransfer = bucketFrom + " " + "to" + " " + bucketTo;

    const ext = x.balance === null ? 0 : x.balance;
    this.kstTotalExtension += ext;
    this.kstLineCount += ext > 0 ? 1 : 0;

    this.create(x);
    this.insertTransfering(); 
  }
}
initializePaying() {
  //continue inserting data into azure now dealing with transferring array
  this.processIndexPaying++;
  if (this.processIndexPaying >= this.paying.length) {
    this.processIndexPaying = -1;
    //all arrays have been posted to azure update kst datalink table
    this.updateDataLink();
  } else {
    if (this.paying[this.processIndexPaying].length == 0) {
      this.initializePaying();
    }
    this.processIndexPayingItems = -1;
    this.insertPaying();
  }
}
insertPaying() {
  //insert into azure paying array one subarray at a time
  this.processIndexPayingItems++;
  if (this.processIndexPayingItems >= this.paying[this.processIndexPaying].length) {
    this.initializePaying();
  } else {
    this.totalCount++;
    this.vendorYear[this.processIndex].counter = this.totalCount;
    const paymentDate = this.paying[this.processIndexPaying][this.processIndexPayingItems].paymentDate;
    let dateFormat = new Date(this.paying[this.processIndexPaying][this.processIndexPayingItems].paymentDate);
    let x = new CommissionData();
    x.id = 0;
    x.category = 'Payment';
    x.period = this.getPeriodName(paymentDate);
    x.year = this.paying[this.processIndexPaying][this.processIndexPayingItems].year;
    x.month = this.paying[this.processIndexPaying][this.processIndexPayingItems].month;
    x.no = this.paying[this.processIndexPaying][this.processIndexPayingItems].brokerPayNumber;
    x.purchaseOrder = this.paying[this.processIndexPaying][this.processIndexPayingItems].reference;
    x.date = paymentDate;
    x.vendor = this.paying[this.processIndexPaying][this.processIndexPayingItems].vendor;
    x.vendorId = this.vendorId;
    x.notes = this.paying[this.processIndexPaying][this.processIndexPayingItems].notes;
    x.payment = this.paying[this.processIndexPaying][this.processIndexPayingItems].paymentAmount;
    x.balance = 0;
    
    const ext = x.balance === null ? 0 : x.balance;
    this.kstTotalExtension += ext;
    this.kstLineCount += ext > 0 ? 1 : 0;

    this.create(x);
    this.insertPaying(); 
  }
}
deleteItems() {
  this.vendorYear[this.processIndex].status = "Deleting";
  this.totalCount++;
  this.vendorYear[this.processIndex].counter = this.totalCount;
  this.processIndexItem++;
  if (this.processIndexItem < this.items.length) {
    this.commissionService.remove(this.items[this.processIndexItem].id).subscribe(data => {
      this.deleteItems();
    }, error => {
      alert('Error received from Azure: ' + error.message);
    })
  } else {
    this.getItems();
  }
}
updateDataLink() {
  this.dataLink = new DataLink();
  this.vendorYear[this.processIndex].status = "Completed";
  this.buttonClicker[this.processIndex] = false;
  const todaysDate = new Date();
  this.dataLink.dataLinkId = this.vendorYear[this.processIndex].dataLinkId;
  this.dataLink.lastUpdate = todaysDate;
  this.vendorYear[this.processIndex].lastUpdate = todaysDate;
  this.dataLink.userId = this.getUserId();
  this.vendorYear[this.processIndex].name = this.getUserName();
  this.dataLinkService.update(this.dataLink).subscribe(data => {
    // alert("All done.");
  })
  this.initializeVendors();
}
getUserId() {
  let userId = 0;
  const currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
  if (currentUser) {
    userId = currentUser.id;  
  }
  return userId;
}
getUserName() {
  let userName = '';
  const currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
  if (currentUser) {
    userName = currentUser.firstName;  
  }
  return userName;
}
updateStatus() {
  this.spinner = [];
  alert('Year ' + this.yearRequested + ' successfully updated.');
}
}
