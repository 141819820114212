import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable, ObservedValueOf } from 'rxjs';
const url = environment.kalcorApiUrl;

@Injectable({
  providedIn: 'root'
})
export class WeekCustomerService {

  constructor(private http: HttpClient) { }
  getAll(): Observable<any> {
    return this.http.get(url + 'WeekCustomers', this.jwt());
  }
  getOne(id: number): Observable<any> {
    return this.http.get(url + 'WeekCustomers/' + id, this.jwt());
  }
  getYear(customerId: number, year: number): Observable<any> {
    return this.http.get(url + 'WeekCustomers/' + customerId + '/Year/' + year, this.jwt());
  }
  getYears(customerId: number): Observable<any> {
    return this.http.get(url + 'WeekCustomers/' + customerId + '/Years', this.jwt());
  }
  update(wkCustomer): Observable<any> {
    return this.http.put(url + 'WeekCustomers/' + wkCustomer.weekId, wkCustomer, this.jwt());
  }
  create(wkCustomer): Observable<any> {
    return this.http.post(url + 'WeekCustomers', wkCustomer, this.jwt());
  }
  remove(id: number) {
    return this.http.delete(url + 'WeekCustomers/' + id, this.jwt());
  }
  private jwt() {
    //create authorization header with jwt token
  const currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
  if (currentUser && currentUser.token) {
    const headersInfo = new HttpHeaders({ Authorization: 'Bearer ' + currentUser.token });
    return { headers: headersInfo };
  }
  }
}
