import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { ExchangeRate } from '../../models/exchange-rate.model';
import { ExchangeRateService } from '../../services/exchange-rate.service';

@Injectable()
export class ExchangeRateResolver implements Resolve<ExchangeRate> {
    constructor(private exchangeRateService: ExchangeRateService) {
    }
  
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ExchangeRate> {
      const idField = 'id';
      const id =  parseInt(route.params[idField], 10);
  
      if (id === 0) {
        return;
      }
      return this.exchangeRateService.getOne(id);
    }
  }