<h5>KST All Reports Link To Azure</h5>
<div class="col-lg-14 text-danger">
    <i>* Running a year update will take several minutes. A pop up will appear indicating update complete.</i>
  </div>
    <div class="row">
      <div class="pageFormat">
      <table class="table table-striped">
      <thead>
      <tr>
        <th scope="col">Year</th>
        <th scope="col">Run Update</th>
        <th scope="col">Status</th>
        <th scope="col">Edit Vendors</th>
        </tr>
      </thead>
      <tbody>
      <tr *ngFor="let y of year; index as i">
  
        <td>{{ y }}</td>
        <td>
            <button type="button" [disabled]="buttonClicker[i]" class="btn btn-link" (click)="run(i)">Run Update</button>
        </td>
          <td><button *ngIf="this.spinner[i]=='...Updating'" class="btn btn-primary" type="button[i]" disabled>
            <span class="spinner-border spinner-border-sm"></span>{{ spinner[i] }} {{ this.bucket }}</button></td>
        <td>
          <button type="button" class="btn btn-secondary" (click)="clickedItem(i)">Edit Vendors</button>            
      </td>
      </tr>
      </tbody>
    </table>
  </div>
  <div *ngIf="this.bucket!=''" class="pageFormat">
    <h5>Update Status: {{this.bucket}}</h5>
    <table class="table">
      <thead>
      <tr>
        <th scope="col">Vendors</th>
        <th scope="col">Modified By</th>
        <th scope="col">Last Update</th>
        <th scope="col">Status</th>
        <th scope="col">Counter</th>
        </tr>
      </thead>
      <tbody>
      <tr *ngFor="let v of vendorYear; index as i">
        <td>{{ v.vendorName }}</td>
        <td>{{ v.name }}</td>
        <td>{{ v.lastUpdate | date:'short' }}</td>
        <td>{{ v.status }}</td>
        <td>{{ v.counter }}</td>
      </tr>
      </tbody>
    </table>
  </div>
  </div>

