<h5>Projection Edit</h5>
<div class="row g-3 align-items-center">
  <div class="col-lg-14 text-danger">
    <i>* Indicates a required field. Completely greyed out fields may not be changed.</i>
  </div>
  <div class="col">
    <div class="row g-3">
      <div class="mx-auto mb-3" style="width: 300px;">
        <mat-icon font-size="50px;">help_outline</mat-icon>
        <span class="ms-2 mb-3 h6">
          Click on the tooltips below for help
        </span>
      </div>
    </div>
  </div>
</div>
<form [formGroup]="projectionForm" novalidate>
  <div class="form-group">
    <div class="row g-3 align-items-center">
        <label for="vendorSelect" class="col-lg-4 pt-2">
         Vendor:
        </label>
        <div class="col-lg-10 pt-2">
          <select id="vendorSelect" formControlName="vendorId" class="form-control">
            <option [value]=""></option>
            <option *ngFor="let v of vendorOriginal" [value]="v.id">{{v.company}}</option>
          </select>
        </div>
    </div>
    <div class="row g-3 align-items-center">
        <label for="customerSelect" class="col-lg-4 pt-2">
         Customer:
        </label>
        <div class="col-lg-10 pt-2">
          <select id="customerSelect" formControlName="customerId" class="form-control">
            <option *ngFor="let c of customerOriginal" [value]="c.id">{{c.company}}</option>
            <!-- <option [value]=""></option>
            <option *ngFor="let c of customerOptions" [value]="c.customerId">{{c.customer}}</option> -->
          </select>
        </div>
    </div>
  <div class="row g-3 align-items-center">
      <label class="col"></label>
  </div>
    
    <div class="row g-3 align-items-center">
        <label for="customerHeadSelect" class="col-lg-4 pt-2">
         Customer Head Office:
        </label>
        <div class="col-lg-10 pt-2">
          <select id="customerHeadSelect" formControlName="customerHeadId" class="form-control">
            <option [value]=""></option>
            <option *ngFor="let h of customerHeadOptions" [value]="h.id">{{h.company}}</option>
          </select>
        </div>
    </div>
    <div class="row g-3 align-items-center">
        <label for="bannerSelect" class="col-lg-4 pt-2">
         *Banners:
        </label>
        <div class="col-lg-10 pt-2">
          <select multiple id="bannerSelect" formControlName="bannerId" class="form-control" size="15">
            <option *ngFor="let b of bannerOptions" [value]="b.id">{{b.company + b.status}}</option>
          </select>
        </div>
        <div class="col-lg-2"></div>
        <div class="col-lg-6">
          <div class="d-grid gap-2">
            <button type="button" class="btn btn-outline-secondary" placement="top" 
            ngbPopover="When selecting more than one banner and more than one product, ensure the values in ALL Cases Per Store/Wk fields are correct.">
            Multiple Banners
            </button>
            <button type="button" class="btn btn-outline-secondary " placement="top" 
            ngbPopover="A banner's budget is confirmed when -'CONFIRMED' is displayed next to the selected banner. If you have just confirmed the banner, refresh the page to see the change.">
            Confirmed Banners
            </button>
          </div>
        </div>
        <div class="col-lg-2"></div>
    </div>
   
    <div class="row g-3 align-items-center">
        <label for="storeSelect" class="col-lg-4 pt-2">
         Confirm Stores:
        </label>
        <div class="col-lg-2 pt-2">
        <input id="storeSelect" formControlName="noStores" class="form-control" type="number">
        </div>
        <div class="col-lg-10"></div>
        <div class="col-lg-6">
        <div class="d-grid gap-2">
          <button type="button" class="btn btn-outline-secondary " placement="top" 
          ngbPopover="This number is used to calculate the cases per store/wk. You may override this number, however when dealing with multiple banners store rounding will no longer calculate.">
	        Number of Stores
          </button>
        </div>
      </div>
      <div class="col-lg-2"></div>
    </div>
    <div class="row g-3 align-items-center">
        <label for="yearSelect" class="col-lg-4 pt-2">
         Year:
        </label>
        <div class="col-lg-2 pt-2">
        <select id="yearSelect" formControlName="year" class="form-control">
          <option *ngFor="let w of weeks; index as i" [value]="w.year">{{ w.year }}</option>
            <!-- <option *ngFor="let y of years; index as i" [value]="y">{{ y }}</option> -->
        </select>
        </div>
    </div>
    <div class="row g-3 align-items-center">
        <label class="col-lg-4 pt-2">
         *Start:
        </label>
        <label for="weekStart" class="col-lg-2 pt-2">
            WEEK
        </label>
        <div class="col-lg-2 pt-2">
        <select id="weekStart" formControlName="weekStartId" class="form-control" type="text">
            <option [value]=""></option>
            <option *ngFor="let w of weeks" [value]="w.weekId">{{w.week}}</option>
        </select>
        </div>
        <div class="col-lg-2"> {{ weekStartOne }}</div>
        <div class="col-lg-2"> {{ weekEndOne }}</div>

        <div class="col-lg-4"></div>
        
        <div class="col-lg-6">
          <div class="d-grid gap-2">
            <button type="button" class="btn btn-outline-secondary " placement="top" 
            ngbPopover="Week start may never be less than week end. Week start does not need to start at 1.">
            Choosing the Weeks
            </button>
          </div>
        </div>
        <div class="col-lg-2"></div>
    </div>
    <div class="row g-3 align-items-center">
        <label class="col-lg-4 pt-2">
         *End:
        </label>
        <label for="weekEnd" class="col-lg-2 pt-2">
            WEEK
        </label>
        <div class="col-lg-2 pt-2">
        <select id="weekEnd" formControlName="weekEndId" class="form-control" type="text">
            <option [value]=""></option>
            <option *ngFor="let w of weeks" [value]="w.weekId">{{w.week}}</option>
        </select>
        </div>
        <div class="col-lg-2"> {{ weekStartTwo }}</div>
        <div class="col-lg-2"> {{ weekEndTwo }}</div>
        <div class="col-lg-4"></div>

        <div class="col-lg-6">
          <div class="d-grid gap-2">
            <button type="button" class="btn btn-outline-secondary " placement="top" 
            ngbPopover="First set the value for cases per store/wk to zero (or set to the original budget amount), save. Now select the new weeks for the promotion, save. Review the original budget to confirm you have correctly adjusted the amount.">
            Changing a Budget Promotions Weeks
            </button>
          </div>
        </div>
        <div class="col-lg-2"></div>
    </div>
    <div class="row g-3 align-items-center">
        <label for="promoSelect" class="col-lg-4 pt-2">
         *Promotion Type:
        </label>
        <div class="col-lg-4 pt-2">
        <select id="promoSelect" formControlName="typeOfPromotion" class="form-control">
          <option [value]=""></option>
          <option value="Budget">Budget</option>
          <option value="Budget Contingency">Budget Contingency</option>
          <option value="Budget Promotion">Budget Promotion</option>  
        </select>
        </div>

        <div class="col-lg-8"></div>

        <div class="col-lg-6 pt-2">
          <div class="d-grid gap-2">
            <button type="button" class="btn btn-outline-secondary " placement="top" 
            ngbPopover="A budget promotion will override the cases in a budget. If a budget does not exist, one will be automatically created for the exact specifications in the promotion.">
            Budget Promotions
            </button>
            <button type="button" class="btn btn-outline-secondary " placement="top" 
            ngbPopover="To eliminate a budget, set the value for cases per store/wk to zero and save.">
            Remove a Budget
            </button>
          </div>
        </div>
        <div class="col-lg-2"></div>
    </div>
    <div *ngIf="promotionOrder" class="row">
      <label for="promotionalNumber" class="col-lg-4 pt-2">
          Promotional Number:
      </label> 
      <div class="col-lg-4 pt-2">
      <input id="promotionalNumber" formControlName="promotionNo" type="text" class="form-control" >
      </div>
  </div>


  <div class="spacer"></div>
  <div class="col-mx text-danger"><i>You may view and edit other product(s) associated with this projection by selecting the corresponding checkbox(es).</i></div>
  <div class="row g-0 col-lg-3">
    <label for="selectBoxAll" class="h6">Select product(s)</label>
      <div class="col-lg-1 pt-2">
      <input id="selectBoxAll" type="checkbox" class="form-check-input" [(ngModel)]="selectBox" [ngModelOptions]="{standalone: true}" (click)="onClickSelect()">
      </div>
    
    </div>
    <div *ngFor="let item of selectedListing; let i = index;">
      <div class="row g-0" [ngClass]="{'gray-out': grayRow[i]==='G', 'grid-stripe': grayRow[i]==='S'}">
        <div class="col-lg-1 pt-2">
          <input type="checkbox" class="form-check-input" [(ngModel)]="checkBox[i]" [ngModelOptions]="{standalone: true}">
        </div>
        <div class="col-lg-2 pt-2 px-2">
          {{ item?.orderCode}}
        </div>
        <div *ngIf="item.brandCategory !== undefined" class="col-lg-8 pt-2 px-2">
          {{ item?.brandCategory }}
        </div>
        <div *ngIf="item.brandCategory === undefined" class="col-lg-8 pt-2 px-2">
          {{ item?.brand }} / {{ item?.category}}
        </div>
        <div class="col-lg-8 pt-2 px-2">
          {{ item?.itemDescription}}
        </div>
        <div class="col-lg-2 pt-2 px-2">
          {{ item?.size }}
        </div>
      </div>  
    </div>
    <div class="row g-3 align-items-center"> 
      <div class="col-lg-16"></div>
      <div class="col-lg-6 pt-2">
          <div class="d-grid gap-2">
            <button type="button" class="btn btn-outline-secondary " placement="top" 
            ngbPopover="If the value of this field is zero, all weeks will be saved as zero. Enter an amount here to be calculated by the number of stores.">
            Cases Per Store/WK
            </button>
            <button type="button" class="btn btn-outline-secondary " placement="top" 
            ngbPopover="You may override a weekbox. If you are working with multiple banners, this will be the exact number of cases specified for every banner.">
            WK Boxes
            </button>
          </div>
        </div>
      </div>
      <div class="col-lg-2"></div>
    <div class="row g-0 bg-primary text-white mt-3">
      <div class="col-lg-2 pt-2 px-2">
        <label class="bg-primary text-white">
          ORDER CODE
        </label>
      </div>
      <div class="col-lg-6 pt-2 px-2">
        <label class="bg-primary text-white">
          BRAND/CATEGORY
        </label>
      </div>
      
      <div class="col-lg-5 pt-2 px-2">
        <label class="bg-primary text-white">
          ITEM DESCRIPTION
        </label>
      </div>
      
      <div class="col-lg-2 pt-2 px-2">
        <label class="bg-primary text-white">
          SIZE
        </label>
      </div>
      <div class="col-lg-2 pt-2 px-2">
        <label class="text-white text-end w-100">
          CASES PER STORE/WK
        </label>
      </div>
      <div class="col-lg-3 pt-2 px-2">
        <label class="text-white text-end w-100">
          PROJECTED SALES/CASES
        </label>
      </div>
      <div class="col-lg-3 pt-2 px-2">
        <label class="text-white text-end w-100">
          PROJECTED SALES/CASES WITH STORE ROUNDING
        </label>
      </div>
    </div>

    <div *ngFor="let item of selectedListing; let i = index;">
        <div *ngIf="checkBox[i]" class="row g-0 border border-start-0 border-end-0 border-dark" [ngClass]="{'gray-out': grayRow[i]==='G', 'grid-stripe': grayRow[i]==='S'}">
          <div class="col-lg-2 pt-4 px-2 h6">
            {{ item?.orderCode}}
          </div>
          <div *ngIf="item.brandCategory !== undefined" class="col-lg-6 pt-4 px-2 h6">
            {{ item?.brandCategory }}
          </div>
          <div *ngIf="item.brandCategory === undefined" class="col-lg-6 pt-4 px-2 h6">
            {{ item?.brand }} / {{ item?.category}}
          </div>
          <div class="col-lg-5 pt-4 px-2 h6">
            {{ item?.itemDescription}}
          </div>
          <div class="col-lg-2 pt-4 px-2 h6">
            {{ item?.size }}
          </div>
          <div class="col-lg-2 pt-4 px-2 h6">
            <input class="w-75 float-end text-end" [(ngModel)]="itemsEntered[i]"  [disabled]="disableSelectedWeek[i]"
              [ngModelOptions]="{standalone: true}" (keyup)="caseChange(i)">
          </div>
          <div class="col-lg-3 pt-4 px-2 text-end h6">
            {{ salesNotRounded[i] | currency }} / {{ casesNotRounded[i] | number }}
          </div>
          <div class="col-lg-3 pt-4 pl-2 text-end h6">
            {{ sales[i] | currency }} / {{ caseTotal[i] | number }}
          </div>
          <div class="smallSpacer"></div>
          <div class="row border-top border-secondary">
            <div class="col grid-stripe">
              <h6 class="py-2 text-center">Period 1</h6>
              <div *ngFor="let in of incoming[i]; let j = index;" class="row">
                <div *ngIf="weeksSelected[j].period == '1'" class="col">WK {{weeksSelected[j].week}}
                <input class="w-100 float-center text-end" [disabled]="in.confirmWeek == 'Y'" [(ngModel)]="in.week"
                [ngModelOptions]="{standalone: true}"(keyup)="caseWeekChange(i)" >
                </div>
              </div>
            </div>
            <div class="col">
              <h6 class="py-2 text-center">Period 2</h6>
              <div *ngFor="let in of incoming[i]; let j = index;" class="row">
                <div *ngIf="weeksSelected[j].period == '2'" class="col">WK {{weeksSelected[j].week}}
                <input class="w-100 float-center text-end" [disabled]="in.confirmWeek == 'Y'" [(ngModel)]="in.week"
                [ngModelOptions]="{standalone: true}"(keyup)="caseWeekChange(i)" >
                </div>
              </div>
            </div>
            <div class="col grid-stripe">
              <h6 class="py-2 text-center">Period 3</h6>
              <div *ngFor="let in of incoming[i]; let j = index;" class="row">
                <div *ngIf="weeksSelected[j].period == '3'" class="col">WK {{weeksSelected[j].week}}
                <input class="w-100 float-center text-end" [disabled]="in.confirmWeek == 'Y'" [(ngModel)]="in.week"
                [ngModelOptions]="{standalone: true}"(keyup)="caseWeekChange(i)" >
                </div>
              </div>
            </div>
            <div class="col">
              <h6 class="py-2 text-center">Period 4</h6>
              <div *ngFor="let in of incoming[i]; let j = index;" class="row">
                <div *ngIf="weeksSelected[j].period == '4'" class="col">WK {{weeksSelected[j].week}}
                <input class="w-100 float-center text-end" [disabled]="in.confirmWeek == 'Y'" [(ngModel)]="in.week"
                [ngModelOptions]="{standalone: true}"(keyup)="caseWeekChange(i)" >
                </div>
              </div>
            </div>
            <div class="col grid-stripe">
              <h6 class="py-2 text-center">Period 5</h6>
              <div *ngFor="let in of incoming[i]; let j = index;" class="row">
                <div *ngIf="weeksSelected[j].period == '5'" class="col">WK {{weeksSelected[j].week}}
                <input class="w-100 float-center text-end" [disabled]="in.confirmWeek == 'Y'" [(ngModel)]="in.week"
                [ngModelOptions]="{standalone: true}"(keyup)="caseWeekChange(i)" >
                </div>
              </div>
            </div>
            <div class="col">
              <h6 class="py-2 text-center">Period 6</h6>
              <div *ngFor="let in of incoming[i]; let j = index;" class="row">
                <div *ngIf="weeksSelected[j].period == '6'" class="col">WK {{weeksSelected[j].week}}
                <input class="w-100 float-center text-end" [disabled]="in.confirmWeek == 'Y'" [(ngModel)]="in.week"
                [ngModelOptions]="{standalone: true}"(keyup)="caseWeekChange(i)" >
                </div>
              </div>
            </div>
            <div class="col grid-stripe">
              <h6 class="py-2 text-center">Period 7</h6>
              <div *ngFor="let in of incoming[i]; let j = index;" class="row">
                <div *ngIf="weeksSelected[j].period == '7'" class="col">WK {{weeksSelected[j].week}}
                <input class="w-100 float-center text-end" [disabled]="in.confirmWeek == 'Y'" [(ngModel)]="in.week"
                [ngModelOptions]="{standalone: true}"(keyup)="caseWeekChange(i)" >
                </div>
              </div>
            </div>
            <div class="col">
              <h6 class="py-2 text-center">Period 8</h6>
              <div *ngFor="let in of incoming[i]; let j = index;" class="row">
                <div *ngIf="weeksSelected[j].period == '8'" class="col">WK {{weeksSelected[j].week}}
                <input class="w-100 float-center text-end" [disabled]="in.confirmWeek == 'Y'" [(ngModel)]="in.week"
                [ngModelOptions]="{standalone: true}"(keyup)="caseWeekChange(i)" >
                </div>
              </div>
            </div>
            <div class="col grid-stripe">
              <h6 class="py-2 text-center">Period 9</h6>
              <div *ngFor="let in of incoming[i]; let j = index;" class="row">
                <div *ngIf="weeksSelected[j].period == '9'" class="col">WK {{weeksSelected[j].week}}
                <input class="w-100 float-center text-end" [disabled]="in.confirmWeek == 'Y'" [(ngModel)]="in.week"
                [ngModelOptions]="{standalone: true}"(keyup)="caseWeekChange(i)" >
                </div>
              </div>
            </div>
            <div class="col">
              <h6 class="py-2 text-center">Period 10</h6>
              <div *ngFor="let in of incoming[i]; let j = index;" class="row">
                <div *ngIf="weeksSelected[j].period == '10'" class="col">WK {{weeksSelected[j].week}}
                <input class="w-100 float-center text-end" [disabled]="in.confirmWeek == 'Y'" [(ngModel)]="in.week"
                [ngModelOptions]="{standalone: true}"(keyup)="caseWeekChange(i)" >
                </div>
              </div>
            </div>
            <div class="col grid-stripe">
              <h6 class="py-2 text-center">Period 11</h6>
              <div *ngFor="let in of incoming[i]; let j = index;" class="row">
                <div *ngIf="weeksSelected[j].period == '11'" class="col">WK {{weeksSelected[j].week}}
                <input class="w-100 float-center text-end" [disabled]="in.confirmWeek == 'Y'" [(ngModel)]="in.week"
                [ngModelOptions]="{standalone: true}"(keyup)="caseWeekChange(i)" >
                </div>
              </div>
            </div>
            <div class="col">
              <h6 class="py-2 text-center">Period 12</h6>
              <div *ngFor="let in of incoming[i]; let j = index;" class="row">
                <div *ngIf="weeksSelected[j].period == '12'" class="col">WK {{weeksSelected[j].week}}
                <input class="w-100 float-center text-end" [disabled]="in.confirmWeek == 'Y'" [(ngModel)]="in.week"
                [ngModelOptions]="{standalone: true}"(keyup)="caseWeekChange(i)" >
                </div>
              </div>
            </div>
            <div class="col grid-stripe">
              <h6 class="py-2 text-center">Period 13</h6>
              <div *ngFor="let in of incoming[i]; let j = index;" class="row">
                <div *ngIf="weeksSelected[j].period == '13'" class="col">WK {{weeksSelected[j].week}}
                <input class="w-100 float-center text-end" [disabled]="in.confirmWeek == 'Y'" [(ngModel)]="in.week"
                [ngModelOptions]="{standalone: true}"(keyup)="caseWeekChange(i)" >
                </div>
              </div>
            </div>
            </div>
          <!-- <div *ngFor="let in of incoming[i]; let j = index;" class="col-lg-2 pt-2 px-2 ms-3">
            WK {{weeksSelected[j].week}}
            <input class="w-100 float-center text-end" [disabled]="in.confirmWeek == 'Y'" [(ngModel)]="in.week"
              [ngModelOptions]="{standalone: true}"(keyup)="caseWeekChange(i)" >
          </div> -->
        </div>  
      </div>


  </div>
<div>
  <button type="submit" class="btn btn-primary mt-3 w-25" (click)="validate(0)" ngbTooltip="Ensure that cases per store/wk is not zero, unless you are intending to remove a budget." placement="top">Save</button>
  <button *ngIf="!promotionOrder" type="button" class="btn btn-secondary ms-3 mt-3 w-25" (click)="initializeDelete()">Delete Budget</button> 
  <button *ngIf="promotionOrder" type="button" class="btn btn-secondary ms-3 mt-3 w-25" (click)="initializeDelete()">Delete Promotion</button> 
  <button *ngIf="alertType=='info'" type="button" class="btn btn-primary mt-3 w-5" disabled>
    <span class="spinner-border spinner-border-sm"></span>...Deleting. You will be navigated to projections list when finished.</button>
</div>
<div>
  <button *ngIf="administrationRole" type="button" class="btn btn-warning mt-3 w-25"  (click)="validate(1)" ngbTooltip="Ensure that cases per store/wk is not zero." placement="top">Confirm</button>
  <button *ngIf="administrationRole" type="button" class="btn btn-secondary ms-3 mt-3 w-25"  (click)="validate(2)" ngbTooltip="After confirming do the WK boxes show zero? As long as there was a value in cases per store/wk the budget was confirmed. Refresh the edit screen to verify the WK boxes have the correct value." placement="top">Unconfirm</button>
</div>
<div>
  
</div>
    <ngb-alert *ngIf="successMessage" [type]="alertType" (close)="successMessage = null">{{ successMessage }}</ngb-alert>
</form>
