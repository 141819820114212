import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Form, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { Customer } from 'src/app/models/customer.model';
import { CustomerEnd } from 'src/app/models/customer-end-model';
import { CustomerHeads } from 'src/app/models/customer-heads.model';
import { CustomerEndService } from 'src/app/services/customer-end.service';
import { WeekKalcor } from 'src/app/models/week-kalcor.model';
import { WeekKalcorService } from 'src/app/services/week-kalcor.service';
import { CustomerRetailService } from 'src/app/services/customer-retail.service';
import { CustomerService } from 'src/app/services/customer.service';
import { VendorService } from 'src/app/services/vendor.service';
import { Vendor } from 'src/app/models/vendor-model';
import { validateLocaleAndSetLanguage } from 'typescript';
import { ShipToAddress } from 'src/app/models/shipto.model';
import { CustomerHeadsService } from 'src/app/services/customer-heads.service';
import { ProductService } from 'src/app/services/product.service';
import { Product } from 'src/app/models/product.model';
import { Sorter2Service } from 'src/app/services/sorter2.service';
import { SorterService } from 'src/app/services/sorter.service';
import { Projection } from 'src/app/models/projection-model';
import { ProjectionsService } from 'src/app/services/projections.service';
import { Brand } from 'src/app/models/brand.model';
import { BrandService } from 'src/app/services/brand.service';
import { error } from 'console';
import { Big } from 'big.js';
import { PromotionsService } from 'src/app/services/promotions.service';
import { Promotion } from 'src/app/models/promotion.model';

@Component({
  selector: 'app-projections-new',
  templateUrl: './projections-new.component.html',
  styleUrls: ['./projections-new.component.scss']
})
export class ProjectionsNewComponent implements OnInit {
  projection: Projection;
  projectionItems: Projection[];
  projection2: Projection;
  customerHead: CustomerHeads[];
  weekItems = [];
  projectionForm: UntypedFormGroup;
  bannerOptions = [];
  bannerConfirmed = [];
  bannerOriginal = [];
  bannerSelected = [];
  projectIds = [];
  brand = [];
  brandId: number;
  brandIds = [];
  customerOptions = [];
  customerOriginal = [];
  customerHeadOptions = [];
  customerHeadOriginal = [];
  customerHeadSelected = [];
  listingOriginal = [];
  listing = [];
  listingId: number;
  noOfStores = [];
  totalStores = 0;
  selectedListing = [];
  vendorOptions = [];
  vendorOriginal = [];
  weeks: WeekKalcor[];
  weekStartOne: any;
  weekEndOne: any;
  weekStartTwo: any;
  weekEndTwo: any;
  years = [];
  grayRow = [];
  itemsEntered = [];
  sales = [];
  caseTotal = [];
  salesNotRounded = [];
  casesNotRounded = [];
  alertType = "success";
  successMessage: string;
  private success = new Subject <string>();
  staticAlertClosed = false;
  weekOne: number;
  incoming: any;
  newOne = true;
  projectionIds = [];
  weeksSelected = [];
  itemsTotal: number;
  itemProcessingIndex: number;
  confirm = false;
  confirmWeek = [];
  disableSelectedWeek = [];
  administrationRole = false;
  ignore = false;
  bannerCounter: number;
  banners = [];
  currentBanners = [];
  response: any;
  checkBox = [];
  selectBox = false;
  bannerProcessingIndex: number;
  totalItemsToAddorUpdate: number;
  updatedAddCount: number;
  projectUpdated = [];
  projectUpdatedIndex: number;
  promotions = [];
  promotionId: number;
  originalBudgetId: number;
  promotionOrder = false;
  itemsPromo = [];
  constructor(
    private fb: UntypedFormBuilder,
    private bannerService: CustomerRetailService,
    private customerService: CustomerService,
    private customerEndService: CustomerEndService,
    private customerHeadService: CustomerHeadsService,
    private promotionService: PromotionsService,
    private vendorService: VendorService,
    private weekKalcorService: WeekKalcorService,
    private productService: ProductService,
    private sort2Svc: Sorter2Service,
    private sortSvc: SorterService,
    private router: Router,
    private projectionsService: ProjectionsService,
    private brandService: BrandService,
    
  ) {}

  ngOnInit(): void {    
    this.getUserInfo();
    this.projectionForm = this.fb.group({
      customerId: ['', Validators.required],
      vendorId: ['', Validators.required],
      customerHeadId: [''],
      bannerId: ['', Validators.required],
      noStores: [''],
      weekStartId: ['', Validators.required],
      weekEndId: ['', Validators.required],
      year: ['', Validators.required],
      typeOfPromotion: ['', Validators.required],
      promotionNo: ['']

    })
    this.projection = new Projection();
    this.bannerService.getAll().subscribe(ban => {
      this.bannerOriginal = ban.filter(b => b.activeItem === 'Active');
      this.bannerOriginal = this.sortSvc.sorter(ban, 'company');
      //this.promotionService.getAll().subscribe(p => {
        //this.promotions = p;
        this.brandService.getAll().subscribe(brand => {
          this.brand = brand;
          this.noOfStores = [];
          this.vendorService.getCompany().subscribe(ven => {
            this.vendorOriginal = ven;
            this.customerService.getCompany().subscribe(cust => {
              this.customerOriginal = cust;
              this.customerEndService.getAll().subscribe(head => {
                this.customerHeadOriginal = head;
                this.productService.getListingCustomerOrder().subscribe(listing => {
                  this.listingOriginal = listing;
                  this.weekKalcorService.getYears().subscribe(data => {
                    data.forEach(x => {
                      this.years.push(x);
                    })
                    this.onChanges();
                  })
                })
              })
            })
          })
        })
      //})    
    })
    setTimeout(() => this.staticAlertClosed = true, 20000);
    this.success.subscribe((message) => this.successMessage = message);
    this.success.pipe(
      debounceTime(5000)
    ).subscribe(() => this.successMessage = null);
  }

  onChanges(): void {
    this.projectionForm.get('vendorId').valueChanges.subscribe(val => {
      if (!this.ignore) {
        this.ignore = true;
        this.setupCustomer(val);
        this.ignore = false;
      }
    })
    this.projectionForm.get('customerId').valueChanges.subscribe(val => {
      if (!this.ignore) {
        this.ignore = true;
        this.getInitialListing();
        this.ignore = false;
      }
    })
    this.projectionForm.get('customerHeadId').valueChanges.subscribe(val => {
      if (!this.ignore) {
        this.ignore = true;
        this.setUpBannerOptions(val);
        this.ignore = false;
      }
    })
    this.projectionForm.get('bannerId').valueChanges.subscribe(val => {
      if (!this.ignore) {
        this.ignore = true;
        this.setupStores(val);
        this.bannerCounter = -1;
        this.getListing();
        this.ignore = false;
      }
    })
    this.projectionForm.get('year').valueChanges.subscribe(val => {
      if (!this.ignore) {
        this.ignore = true;
        this.setupWeeks(val);
        this.ignore = false;
      }
    })
    this.projectionForm.get('weekStartId').valueChanges.subscribe(val => {
      if (!this.ignore) {
        this.ignore = true;
        this.setupWeekOne(val);
        this.bannerCounter = -1;
        this.getListing();
        this.ignore = false;
      }
    })
    this.projectionForm.get('weekEndId').valueChanges.subscribe(val => {
      if (!this.ignore) {
        this.ignore = true;
        this.setupWeekTwo(val);
        this.bannerCounter = -1;
        this.getListing();
        this.ignore = false;
      }
    })
    this.projectionForm.get('typeOfPromotion').valueChanges.subscribe(val => {
      if (!this.ignore) {
        this.ignore = true;
        this.bannerCounter = -1;
        this.getListing();
        this.ignore = false;
      }
      if (val == 'Budget Promotion') {
        this.promotionOrder = true;

      }
    })
    this.projectionForm.get('noStores').valueChanges.subscribe(val => {
      if (!this.ignore) {
        this.ignore = true;
        this.selectedListing.forEach((e, index) => {
          this.calculateSales(index);
        })
        this.ignore = false;
      }
    })
  }

  setupCustomer(venId) {
    this.customerOptions = [];
    this.listing = [];
    this.selectedListing = [];
    this.bannerOptions = [];
    this.bannerConfirmed = [];
    this.projectionForm.controls.customerId.setValue('');
    if (venId) {
      this.listingOriginal.forEach(e => {
        if (e.vendorId == venId) {
          const index = this.customerOptions.findIndex(c => c.customerId == e.customerId);
          if (index == -1) {
            const cust = {
              customerId: e.customerId,
              customer: e.customer
            }
            this.customerOptions.push(cust)
          }
        }
      })
    }
  }

  getInitialListing() {
    const vendorId = this.projectionForm.controls.vendorId.value * 1;
    const customerId = this.projectionForm.controls.customerId.value * 1;
    this.brandIds = this.brand.filter(b => b.vendorId === vendorId);
    this.itemsEntered = [];
    this.sales = [];
    this.caseTotal = [];
    this.salesNotRounded = [];
    this.casesNotRounded = [];
    this.incoming = [];
    this.selectedListing = [];
    this.checkBox = [];
    if (customerId && this.brandIds.length > 0) {
      const selected = this.listingOriginal.filter(e => e.customerId == customerId);
      selected.forEach(e => {

        if (this.brandIds.some(b => b.vendorId == e.vendorId)) {
          const projectionItems = {
            id: null,
            vendorId: e.vendorId,
            customerId: e.customerId,
            shipToId: null,
            customerEndId: null,
            bannerIds: null,
            bannerRegionIds: null,
            noStores: null,
            year: null,
            itemDescription: e.itemDescription,
            orderCode: e.orderCode,
            size: e.size,
            week1: 0,
            week2: 0, 
            week3: 0,
            week4: 0, 
            week5: 0, 
            week6: 0, 
            week7: 0, 
            week8: 0, 
            week9: 0, 
            week10: 0, 
            week11: 0, 
            week12: 0, 
            week13: 0, 
            week14: 0, 
            week15: 0, 
            week16: 0, 
            week17: 0, 
            week18: 0, 
            week19: 0, 
            week20: 0, 
            week21: 0, 
            week22: 0, 
            week23: 0, 
            week24: 0, 
            week25: 0, 
            week26: 0, 
            week27: 0, 
            week28: 0, 
            week29: 0, 
            week30: 0, 
            week31: 0, 
            week32: 0, 
            week33: 0, 
            week34: 0, 
            week35: 0, 
            week36: 0, 
            week37: 0, 
            week38: 0, 
            week39: 0, 
            week40: 0, 
            week41: 0, 
            week42: 0, 
            week43: 0, 
            week44: 0, 
            week45: 0, 
            week46: 0, 
            week47: 0, 
            week48: 0, 
            week49: 0, 
            week50: 0, 
            week51: 0, 
            week52: 0, 
            week53 : 0,
            typeOfPromotion: null,  
            promotionNo: null,
            promotionId: null,
            productId: null,
            whsSelling: e.whsSelling,
            brandId: e.brandId,
            brand: e.brand,
            category: e.category,
            confirmWeeks: null,
            headOfficeIds: e.headOfficeIds,
            retailIds: e.retailIds
          }
          this.selectedListing.push(projectionItems);
          this.checkBox.push(false);
        }  
      })

    }

    // set up customerhead options
    this.projectionForm.controls.customerHeadId.setValue('');
    this.customerHeadOptions = []
    this.bannerOptions = [];
    this.selectedListing.forEach(e => {
      if (e.headOfficeIds) {
        const heads = e.headOfficeIds.split(',');
        if (heads.length > 0) {
          heads.forEach(f => {
            const headId = f * 1;
            const index = this.customerHeadOptions.findIndex(e => e.id == headId);
            if (index == -1) {
              const index2 = this.customerHeadOriginal.findIndex(e => e.id == headId);
              if (index2 > -1) {
                this.customerHeadOptions.push(
                  {
                    id: this.customerHeadOriginal[index2].id,
                    company: this.customerHeadOriginal[index2].company
                  }
                );
              }
            }
          })
        }
      }
    })
  }

  setUpBannerOptions(val) {
    // setup banner options
    this.bannerOptions = []
    this.projectionForm.controls.bannerId.setValue('');
    const headId = val * 1;
    this.bannerOriginal.forEach(b =>  {
      if (b.customerHeadId == headId) {
        const index = this.bannerOptions.findIndex(e => e.id == b.id);
        if (index == -1) {
          this.bannerOptions.push(
            {
              id: b.id,
              company: b.company,
              noStores: b.noStores,
              status: " "
            }
          );
        }

      }

    })
  }

  getListing(){

    const vendorId = this.projectionForm.controls.vendorId.value * 1;
    if (!vendorId) {
      return;
    }

    const customerId = this.projectionForm.controls.customerId.value * 1;
    if (!customerId) {
      return;
    }
    const headId = this.projectionForm.controls.customerHeadId.value * 1;
    if (!headId) {
      return;
    }
    this.banners = this.projectionForm.controls.bannerId.value;
    if (!this.banners) {
      return;
    }

    const year = this.projectionForm.controls.year.value * 1;
    if (!year) {
      return;
    }

    const promo = this.projectionForm.controls.typeOfPromotion.value;
    if (!promo) {
      return;
    }

    if (this.bannerCounter == -1) {
      this.projectIds = [];
      this.incoming = [];
      this.itemsEntered = [];
      this.sales = [];
      this.caseTotal = [];
      this.salesNotRounded = [];
      this.casesNotRounded = [];
      this.clearWeeks();
    } 
    this.bannerCounter++;
    if (this.bannerCounter >= this.banners.length) {
      this.getWeeks();
      this.EnteredAndSales();
      return;
    }
    
    const oneBanner = this.banners[this.bannerCounter];
    // const bannerOptionIndex = this.bannerOptions.find(b => b.id == oneBanner);
    // if (bannerOptionIndex > -1 ) {
    //   const proj = {
    //     id: 0,
    //     noStores: this.bannerOptions[bannerOptionIndex].noStores,
    //     bannerId: this.bannerOptions[bannerOptionIndex].id
    //   }
    //   this.projectIds.push(proj);
    // }

    //get promotion service original
    const weekId = this.projectionForm.controls.weekStartId.value * 1;
    const weekIndex = this.weeks.findIndex(w => w.weekId == weekId);
    const firstWeek = this.weeks[weekIndex].week;

    this.projectionForm.controls.promotionNo.setValue("");
    this.promotionId = null;

    this.projectionsService.getPromotion(customerId, vendorId, headId, oneBanner, year, promo).subscribe(listing => {
      listing.forEach(list => {
          const index = this.selectedListing.findIndex(e => e.orderCode == list.productOrderCode)       
          if (index > -1) {
            const proj = {
              id: list.id,
              productOrderCode: list.productOrderCode,
              noStores: list.noStores,
              bannerId: list.bannerId,
              
                 
            }

            this.projectIds.push(proj);
            this.selectedListing[index].id = list.id;
            this.selectedListing[index].noStores += list.noStores;
            this.selectedListing[index].confirmWeeks = list.confirmWeeks;
            if (list.confirmWeeks.indexOf("Y") > -1) {
              const bannerIndex = this.bannerOptions.findIndex(e => e.id == oneBanner);
              if (bannerIndex > -1) {
                this.bannerOptions[bannerIndex].status = "  -  CONFIRMED";
              } 
            }
            
            this.selectedListing[index].bannerId = list.bannerId;
            this.selectedListing[index].week1 += list.week1;
            this.selectedListing[index].week2 += list.week2;
            this.selectedListing[index].week3 += list.week3;
            this.selectedListing[index].week4 += list.week4;
            this.selectedListing[index].week5 += list.week5; 
            this.selectedListing[index].week6 += list.week6;
            this.selectedListing[index].week7 += list.week7;
            this.selectedListing[index].week8 += list.week8;
            this.selectedListing[index].week9 += list.week9;
            this.selectedListing[index].week10 += list.week10; 
            this.selectedListing[index].week11 += list.week11; 
            this.selectedListing[index].week12 += list.week12;
            this.selectedListing[index].week13 += list.week13;
            this.selectedListing[index].week14 += list.week14;
            this.selectedListing[index].week15 += list.week15;
            this.selectedListing[index].week16 += list.week16;
            this.selectedListing[index].week17 += list.week17;
            this.selectedListing[index].week18 += list.week18;
            this.selectedListing[index].week19 += list.week19;
            this.selectedListing[index].week20 += list.week20;
            this.selectedListing[index].week21 += list.week21;
            this.selectedListing[index].week22 += list.week22;
            this.selectedListing[index].week23 += list.week23;
            this.selectedListing[index].week24 += list.week24;
            this.selectedListing[index].week25 += list.week25;
            this.selectedListing[index].week26 += list.week26;
            this.selectedListing[index].week27 += list.week27;
            this.selectedListing[index].week28 += list.week28;
            this.selectedListing[index].week29 += list.week29;
            this.selectedListing[index].week30 += list.week30;
            this.selectedListing[index].week31 += list.week31;
            this.selectedListing[index].week32 += list.week32;
            this.selectedListing[index].week33 += list.week33; 
            this.selectedListing[index].week34 += list.week34; 
            this.selectedListing[index].week35 += list.week35; 
            this.selectedListing[index].week36 += list.week36; 
            this.selectedListing[index].week37 += list.week37; 
            this.selectedListing[index].week38 += list.week38; 
            this.selectedListing[index].week39 += list.week39; 
            this.selectedListing[index].week40 += list.week40; 
            this.selectedListing[index].week41 += list.week41; 
            this.selectedListing[index].week42 += list.week42; 
            this.selectedListing[index].week43 += list.week43; 
            this.selectedListing[index].week44 += list.week44; 
            this.selectedListing[index].week45 += list.week45; 
            this.selectedListing[index].week46 += list.week46; 
            this.selectedListing[index].week47 += list.week47; 
            this.selectedListing[index].week48 += list.week48; 
            this.selectedListing[index].week49 += list.week49; 
            this.selectedListing[index].week50 += list.week50; 
            this.selectedListing[index].week51 += list.week51; 
            this.selectedListing[index].week52 += list.week52; 
            this.selectedListing[index].week53 += list.week53;
            
            if (list.itemsPromo.length > 0) {
              list.itemsPromo.forEach(e => {
                this.itemsPromo.push(e);     
                const promoWeek = e.promotionWeeks.split(',');
                const promoIndex = firstWeek -1;
                if (promoWeek[promoIndex] == "Y") {
                  this.projectionForm.controls.promotionNo.setValue(e.promotionalNumber);
                  this.promotionId = e.id;
                  this.originalBudgetId = e.budgetId;                 
                } else {
                  this.itemsPromo = [];
                }
              })
            }

          }
        })
        

        

      // this.getWeeks();
      // this.EnteredAndSales();
      this.getListing();
      },error => {
          this.successMessage = 'Unable to process request';
          this.alertType = "danger";
          return;
    })
  }

  clearWeeks() {
    this.selectedListing.forEach(e => {
      e.noStores = 0,
      e.id = null;
      e.week1 = 0;
      e.week2 = 0; 
      e.week3 = 0;
      e.week4 = 0; 
      e.week5 = 0; 
      e.week6 = 0; 
      e.week7 = 0; 
      e.week8 = 0; 
      e.week9 = 0; 
      e.week10 = 0; 
      e.week11 = 0; 
      e.week12 = 0; 
      e.week13 = 0; 
      e.week14 = 0; 
      e.week15 = 0; 
      e.week16 = 0; 
      e.week17 = 0; 
      e.week18 = 0; 
      e.week19 = 0; 
      e.week20 = 0; 
      e.week21 = 0; 
      e.week22 = 0; 
      e.week23 = 0; 
      e.week24 = 0; 
      e.week25 = 0; 
      e.week26 = 0; 
      e.week27 = 0; 
      e.week28 = 0; 
      e.week29 = 0; 
      e.week30 = 0; 
      e.week31 = 0; 
      e.week32 = 0; 
      e.week33 = 0; 
      e.week34 = 0; 
      e.week35 = 0; 
      e.week36 = 0; 
      e.week37 = 0; 
      e.week38 = 0; 
      e.week39 = 0; 
      e.week40 = 0; 
      e.week41 = 0; 
      e.week42 = 0; 
      e.week43 = 0; 
      e.week44 = 0; 
      e.week45 = 0; 
      e.week46 = 0; 
      e.week47 = 0; 
      e.week48 = 0; 
      e.week49 = 0; 
      e.week50 = 0; 
      e.week51 = 0; 
      e.week52 = 0; 
      e.week53  = 0;
      e.confirmWeeks = null;
    })

  }

  getWeeks(){
    this.incoming = [];
    this.confirmWeek = [];
    this.disableSelectedWeek = [];
    this.selectedListing.forEach(e => {
      if (e.confirmWeeks === null) {
        e.confirmWeeks = 'N,'.repeat(53);
      }
      // if (this.banners.length > 1) {
      //   e.confirmWeeks = 'Y,'.repeat(53);
      // }
      let confirmWeeks = e.confirmWeeks.split(',');

      this.confirmWeek.push(confirmWeeks);
      let week = [];
      this.weeksSelected.forEach(f => {
        switch (f.week) {
          case 1:
            week.push({ week: e.week1, weekNumber: f.week, confirmWeek: confirmWeeks[0] });
            break;
          case 2:
            week.push({ week: e.week2, weekNumber: f.week, confirmWeek: confirmWeeks[1] });
            break;
          case 3:
            week.push({ week: e.week3, weekNumber: f.week, confirmWeek: confirmWeeks[2] });
            break;
          case 4:
            week.push({ week: e.week4, weekNumber: f.week, confirmWeek: confirmWeeks[3] });
            break;
          case 5:
            week.push({ week: e.week5, weekNumber: f.week, confirmWeek: confirmWeeks[4] });
            break;
          case 6:
            week.push({ week: e.week6, weekNumber: f.week, confirmWeek: confirmWeeks[5] });
            break;
          case 7:
            week.push({ week: e.week7, weekNumber: f.week, confirmWeek: confirmWeeks[6] });
            break;
          case 8:
            week.push({ week: e.week8, weekNumber: f.week, confirmWeek: confirmWeeks[7] });
            break;
          case 9:
            week.push({ week: e.week9, weekNumber: f.week, confirmWeek: confirmWeeks[8] });
            break;
          case 10:
            week.push({ week: e.week10, weekNumber: f.week, confirmWeek: confirmWeeks[9] });
            break;
          case 11:
            week.push({ week: e.week11, weekNumber: f.week, confirmWeek: confirmWeeks[10] });
            break;
          case 12:
            week.push({ week: e.week12, weekNumber: f.week, confirmWeek: confirmWeeks[11] });
            break;
          case 13:
            week.push({ week: e.week13, weekNumber: f.week, confirmWeek: confirmWeeks[12] });
            break;
          case 14:
            week.push({ week: e.week14, weekNumber: f.week, confirmWeek: confirmWeeks[13] });
            break;
          case 15:
            week.push({ week: e.week15, weekNumber: f.week, confirmWeek: confirmWeeks[14] });
            break;
          case 16:
            week.push({ week: e.week16, weekNumber: f.week, confirmWeek: confirmWeeks[15] });
            break;
          case 17:
            week.push({ week: e.week17, weekNumber: f.week, confirmWeek: confirmWeeks[16] });
            break;
          case 18:
            week.push({ week: e.week18, weekNumber: f.week, confirmWeek: confirmWeeks[17] });
            break;
          case 19:
            week.push({ week: e.week19, weekNumber: f.week, confirmWeek: confirmWeeks[18] });
            break;
          case 20:
            week.push({ week: e.week20, weekNumber: f.week, confirmWeek: confirmWeeks[19] });
            break;
          case 21:
            week.push({ week: e.week21, weekNumber: f.week, confirmWeek: confirmWeeks[20] });
            break;
          case 22:
            week.push({ week: e.week22, weekNumber: f.week, confirmWeek: confirmWeeks[21] });
            break;
          case 23:
            week.push({ week: e.week23, weekNumber: f.week, confirmWeek: confirmWeeks[22] });
            break;
          case 24:
            week.push({ week: e.week24, weekNumber: f.week, confirmWeek: confirmWeeks[23] });
            break;
          case 25:
            week.push({ week: e.week25, weekNumber: f.week, confirmWeek: confirmWeeks[24] });
            break;
          case 26:
            week.push({ week: e.week26, weekNumber: f.week, confirmWeek: confirmWeeks[25] });
            break;
          case 27:
            week.push({ week: e.week27, weekNumber: f.week, confirmWeek: confirmWeeks[26] });
            break;
          case 28:
            week.push({ week: e.week28, weekNumber: f.week, confirmWeek: confirmWeeks[27] });
            break;
          case 29:
            week.push({ week: e.week29, weekNumber: f.week, confirmWeek: confirmWeeks[28] });
            break;
          case 30:
            week.push({ week: e.week30, weekNumber: f.week, confirmWeek: confirmWeeks[29] });
            break;
          case 31:
            week.push({ week: e.week31, weekNumber: f.week, confirmWeek: confirmWeeks[30] });
            break;
          case 32:
            week.push({ week: e.week32, weekNumber: f.week, confirmWeek: confirmWeeks[31] });
            break;
          case 33:
            week.push({ week: e.week33, weekNumber: f.week, confirmWeek: confirmWeeks[32] });
            break;
          case 34:
            week.push({ week: e.week34, weekNumber: f.week, confirmWeek: confirmWeeks[33] });
            break;
          case 35:
            week.push({ week: e.week35, weekNumber: f.week, confirmWeek: confirmWeeks[34] });
            break;
          case 36:
            week.push({ week: e.week36, weekNumber: f.week, confirmWeek: confirmWeeks[35] });
            break;
          case 37:
            week.push({ week: e.week37, weekNumber: f.week, confirmWeek: confirmWeeks[36] });
            break;
          case 38:
            week.push({ week: e.week38, weekNumber: f.week, confirmWeek: confirmWeeks[37] });
            break;
          case 39:
            week.push({ week: e.week39, weekNumber: f.week, confirmWeek: confirmWeeks[38] });
            break;
          case 40:
            week.push({ week: e.week40, weekNumber: f.week, confirmWeek: confirmWeeks[39] });
            break;
          case 41:
            week.push({ week: e.week41, weekNumber: f.week, confirmWeek: confirmWeeks[40] });
            break;
          case 42:
            week.push({ week: e.week42, weekNumber: f.week, confirmWeek: confirmWeeks[41] });
            break;
          case 43:
            week.push({ week: e.week43, weekNumber: f.week, confirmWeek: confirmWeeks[42] });
            break;
          case 44:
            week.push({ week: e.week44, weekNumber: f.week, confirmWeek: confirmWeeks[43] });
            break;
          case 45:
            week.push({ week: e.week45, weekNumber: f.week, confirmWeek: confirmWeeks[44] });
            break;
          case 46:
            week.push({ week: e.week46, weekNumber: f.week, confirmWeek: confirmWeeks[45] });
            break;
          case 47:
            week.push({ week: e.week47, weekNumber: f.week, confirmWeek: confirmWeeks[46] });
            break;
          case 48:
            week.push({ week: e.week48, weekNumber: f.week, confirmWeek: confirmWeeks[47] });
            break;
          case 49:
            week.push({ week: e.week49, weekNumber: f.week, confirmWeek: confirmWeeks[48] });
            break;
          case 50:
            week.push({ week: e.week50, weekNumber: f.week, confirmWeek: confirmWeeks[49] });
            break;
          case 51:
            week.push({ week: e.week51, weekNumber: f.week, confirmWeek: confirmWeeks[50] });
            break;
          case 52:
            week.push({ week: e.week52, weekNumber: f.week, confirmWeek: confirmWeeks[51] });
            break;
          case 53:
              week.push({ week: e.week53, weekNumber: f.week, confirmWeek: confirmWeeks[52] });
              break;

        }
      })

      this.incoming.push(week);
      const disableIt = week.some(e => e.confirmWeek == 'Y');
      this.disableSelectedWeek.push(disableIt);
    })
  }
  setupStores(ban) {
    if (!ban) {
      return;
    }

    this.totalStores = 0;
    ban.forEach(x => {
        this.bannerOptions.forEach(e => {
          if (x == e.id) {
            const index = this.bannerOptions.findIndex(b => b == e.id);
            if (index == -1) {
              this.totalStores += e.noStores ? e.noStores : 0;
              //this.totalStores += e.noOfStores ? e.noOfStores : 0;
            }
          }
          
        })
      }
    )
    this.projectionForm.controls.noStores.setValue(this.totalStores);
  }
  setupWeeks(year){
    this.weekKalcorService.getYear(year).subscribe(data => {
      this.weeks = data;
     // this.setUpWeeksSelected();
    })
  }
  setupWeekOne(week){   
    this.weeks.forEach(e => {
      if (e.weekId == week) {
        this.weekStartOne = e.weekStartDate;
        this.weekEndOne = e.weekEndDate;
      }
    });
    this.setUpWeeksSelected();
  }
  setupWeekTwo(week){   
    const weekOne = this.projectionForm.controls.weekStartId.value * 1;
    if (weekOne > week) {
      this.success.next('End date may not be greater than Start date');
      this.alertType = "danger";
      return;
    } else {
      this.weeks.forEach(e => {
        if (e.weekId == week) {
          this.weekStartTwo = e.weekStartDate;
          this.weekEndTwo = e.weekEndDate;
        }
      });
    }
    this.setUpWeeksSelected();
  }
  setUpWeeksSelected() {
    this.weeksSelected = [];
    let startId = this.projectionForm.controls.weekStartId.value * 1;
    let endId = this.projectionForm.controls.weekEndId.value * 1;
    let pushIt = false;
    this.weeks.forEach( e => {
      if (startId) {
        if (e.weekId == startId) {
          pushIt = true;
        }
      }
      if (pushIt) {
        this.weeksSelected.push(e);
      }
      if (endId) {
        if (e.weekId == endId) {
          pushIt = false;
        }
      }
    })

    // this.incoming will be created below
    this.getWeeks();
    this.EnteredAndSales();
  }

  EnteredAndSales() {
    this.incoming.forEach((inWeek, inWeekIndex) => {
      const cases = inWeek.length > 0 ? inWeek[0].week : 0;
      let sameCase = true;
      let totalCases = 0;
      inWeek.forEach((e, index) => {
        sameCase = sameCase ? e.week == cases : sameCase;
        totalCases += e.week ? e.week : 0;
      })
      this.itemsEntered[inWeekIndex] = 0;
      // if (sameCase) {
      //   const totalStores = this.projectionForm.controls.noStores.value * 1;
      //   const bigNum: any = Big(cases)
      //   .div(totalStores)
      //   .round(2);
      //   this.itemsEntered[inWeekIndex] = bigNum * 1;
      // }
      this.caseTotal[inWeekIndex] = totalCases  * 1; 
      this.casesNotRounded[inWeekIndex] = totalCases * 1;
      const bigNum: any = Big(totalCases)
      .times(this.selectedListing[inWeekIndex].whsSelling)
      .round(2);
      this.sales[inWeekIndex] = bigNum * 1;
      this.salesNotRounded[inWeekIndex] = bigNum * 1;
      
    })
  }

  caseChange(index: number) {

    this.calculateSales(index);
  }

  calculateSales(index: number) {
    const itemEntered = this.itemsEntered[index] * 1;
    const totalStores = this.projectionForm.controls.noStores.value * 1;
    const bigNum2: any = Big(totalStores)
    .times(itemEntered)
    .round(0);
    const caseWeek = bigNum2 * 1;

    let totalCases = 0;
    if (!this.incoming) {
      return;
    }
    if (!this.selectedListing) {
      return;
    }
    this.incoming[index].forEach(e => {
      e.week = caseWeek;
      totalCases += caseWeek;
    });
    // this.caseTotal[index] = totalCases  * 1;
    this.casesNotRounded[index] = totalCases  * 1;
    const bigNum: any = Big(totalCases)
    .times(this.selectedListing[index].whsSelling)
    .round(2);
    // this.sales[index] = bigNum * 1;
    this.salesNotRounded[index] = bigNum * 1;

    this.calculateRoundedSales(index);
  }

  calculateRoundedSales(index: number) {
    // 7.24.24 calculate every banners stores and round up or down to acheive an overall rounded cases and sales total
    const itemEntered = this.itemsEntered[index] * 1;
    const totalStores = this.projectionForm.controls.noStores.value * 1;
    this.noOfStores = []
    this.banners.forEach(x => {
      this.bannerOptions.forEach(e => {
        if (x == e.id) {
          const bannerIndex = this.bannerOptions.findIndex(b => b == e.id);
          if (bannerIndex == -1) {
            this.noOfStores.push(e.noStores);
          }
        }
      })
    }
  )
  // determine if confirmed stores have been overriden, if so return, otherwise proceed to rounding stores by banner
  let compareStores = 0;
  this.noOfStores.forEach(e => {
    compareStores += e;
  })
  if (totalStores != compareStores) {
    return;
  }

  let roundedStores = 0;
  this.noOfStores.forEach(e => {
    const store = e * 1;
    const bigNum2: any = Big(store)
    .times(itemEntered)
    .round(0);
    const caseWeekRounded = bigNum2 * 1;
    roundedStores += caseWeekRounded;
  })
    
    const caseWeek = roundedStores;

    let totalCases = 0;
    if (!this.incoming) {
      return;
    }
    if (!this.selectedListing) {
      return;
    }
    this.incoming[index].forEach(e => {
      e.week = caseWeek;
      totalCases += caseWeek;
    });
    this.caseTotal[index] = totalCases  * 1;
    const bigNum: any = Big(totalCases)
    .times(this.selectedListing[index].whsSelling)
    .round(2);
    this.sales[index] = bigNum * 1;
  }

  caseWeekChange(index: number) {
    let totalCases = 0;
    this.incoming[index].forEach(e => {
      totalCases += e.week * 1;
    });
    this.caseTotal[index] = totalCases  * 1;
    this.casesNotRounded[index] = totalCases  * 1;
    const bigNum: any = Big(totalCases)
    .times(this.selectedListing[index].whsSelling)
    .round(2);
    this.sales[index] = bigNum * 1;
    this.salesNotRounded[index] = bigNum * 1;

  }

  fillFormData() {
    this.projectionForm.setValue({
      vendorId: this.projection.vendorId,
     // shipToId: this.projection.shipToId,
      customerHeadId: this.projection.customerEndId,
      bannerId: this.splitNumbers(this.projection.bannerIds),
      noStores: this.projection.noStores,
      year: this.projection.year,
      typeOfPromotion: this.projection.typeOfPromotion,
      //projectionId: this.splitNumbers(this.projection.id)
    })
  }
  splitNumbers(ids) {
    let ret = [];
    if (ids) {
      let retCount = 0;
      const tempSplit = ids.split(',');
      if (tempSplit.length > 0) {
        tempSplit.forEach(id => {
          if (id) {
            ret.push(id * 1);
          }
        })
      }
    }
    return ret;
  }
  validate(type) {
    if (this.weekStartOne > this.weekStartTwo) {
      this.success.next('End date may not be greater than Start date');
      this.alertType = "danger";
      return;
    }
    if (this.projectionForm.invalid) {
      this.success.next('Please enter required fields.');
      this.alertType = "danger";
      return;
    }
    if (this.promotionOrder == true) {
      if (this.projectionForm.controls.promotionNo.value == "") {
        this.success.next('Please enter a promotion number.');
        this.alertType = "danger";
        return;
      }
    } 
    switch (type) {
      case 1:
        this.onConfirm();
        break;
      case 2:
        this.unConfirm();
      default:
        this.onSubmit();
        break;

    }
  }
  
  getCaseWeek(index, weekNumber, cases) {
    // const weeksSelectedIndex = this.weeksSelected.findIndex(e => e.week == weekNumber);
    cases = null;
    const weeksSelectedIndex = this.incoming[index].findIndex(e => e.weekNumber == weekNumber);
    if (weeksSelectedIndex > -1) {
      cases = this.incoming[index][weeksSelectedIndex].week;
      if (this.banners.length > 1) {
        const casesEntered = this.itemsEntered[this.itemProcessingIndex];
        // 9/14/23 calculate cases only if cases per store/wk entered is greater than 0
        if (casesEntered > 0) {
          const noOfStores = this.projection.noStores;
          cases = casesEntered * noOfStores;
        }
      }
      // 7/23/24 added rounding to be save to database, per rick we will never go below a .25 decimal point
      const bigNum: any = Big(cases)
      .round(0);
      cases = bigNum * 1;
      const index2 = weekNumber - 1;
      this.confirmWeek[index][index2] = this.confirm ? 'Y' : this.confirmWeek[index][index2];
    }

    return cases;
  }

  onSubmit() {
   // this.listingId = this.projection.id;
    this.projection.customerId = this.projectionForm.controls.customerId.value;
    this.projection.vendorId = this.projectionForm.controls.vendorId.value;
    this.projection.customerEndId = this.projectionForm.controls.customerHeadId.value;
    this.projection.bannerIds = this.projectionForm.controls.bannerId.value.join();
    //this.projection.noStores = this.projectionForm.controls.noStores.value;
    this.projection.year = this.projectionForm.controls.year.value;
    this.projection.typeOfPromotion = this.projectionForm.controls.typeOfPromotion.value;
    this.itemsTotal = this.selectedListing.length;
    this.bannerProcessingIndex = -1;
    this.totalItemsToAddorUpdate = this.checkBox.filter(b => b === true).length;
    this.projectUpdated = [];
    this.updateIt();
  }
  updateIt() {
    this.bannerProcessingIndex++;
    if (this.bannerProcessingIndex >= this.banners.length) {
      this.projectUpdatedIndex = -1;
      if (this.projection.typeOfPromotion == 'Budget Promotion') {
        this.promotion();
      }
      return;
    }
// one banner at a time
   
    // default
    const indexBannerOption = this.bannerOptions.findIndex(b => b.id == this.banners[this.bannerProcessingIndex]);
    if (indexBannerOption > -1) {
      this.projection.noStores = this.bannerOptions[indexBannerOption].noStores;
    }
    

    // number of stores from projection table
    const indexBanner = this.projectIds.findIndex(b => b.bannerId == this.banners[this.bannerProcessingIndex]);
    if (indexBanner > -1) {
      this.projection.noStores = this.projectIds[indexBanner].noStores;
    }

    this.projection.bannerId = this.banners[this.bannerProcessingIndex]; 

    this.itemProcessingIndex = -1;
    this.updatedAddCount = 0;
    this.updateEachItem();
  }

  updateEachItem() {
    this.itemProcessingIndex++;
    if (this.itemProcessingIndex >= this.itemsTotal) {
      this.confirm = false;
      this.updateIt();
      return;
    }
    
    if (!this.checkBox[this.itemProcessingIndex]) {
      this.updateEachItem();
      return;
    }
    this.updatedAddCount++;

    this.projection.week1 = this.getCaseWeek(this.itemProcessingIndex, 1, this.selectedListing[this.itemProcessingIndex].week1);
      this.projection.week2 = this.getCaseWeek(this.itemProcessingIndex, 2, this.selectedListing[this.itemProcessingIndex].week2);
      this.projection.week3 = this.getCaseWeek(this.itemProcessingIndex, 3, this.selectedListing[this.itemProcessingIndex].week3);
      this.projection.week4 = this.getCaseWeek(this.itemProcessingIndex, 4, this.selectedListing[this.itemProcessingIndex].week4);
      this.projection.week5 = this.getCaseWeek(this.itemProcessingIndex, 5, this.selectedListing[this.itemProcessingIndex].week5);
      this.projection.week6 = this.getCaseWeek(this.itemProcessingIndex, 6, this.selectedListing[this.itemProcessingIndex].week6);
      this.projection.week7 = this.getCaseWeek(this.itemProcessingIndex, 7, this.selectedListing[this.itemProcessingIndex].week7);
      this.projection.week8 = this.getCaseWeek(this.itemProcessingIndex, 8, this.selectedListing[this.itemProcessingIndex].week8);
      this.projection.week9 = this.getCaseWeek(this.itemProcessingIndex, 9, this.selectedListing[this.itemProcessingIndex].week9);
      this.projection.week10 = this.getCaseWeek(this.itemProcessingIndex, 10, this.selectedListing[this.itemProcessingIndex].week10);
      this.projection.week11 = this.getCaseWeek(this.itemProcessingIndex, 11, this.selectedListing[this.itemProcessingIndex].week11);
      this.projection.week12 = this.getCaseWeek(this.itemProcessingIndex, 12, this.selectedListing[this.itemProcessingIndex].week12);
      this.projection.week13 = this.getCaseWeek(this.itemProcessingIndex, 13, this.selectedListing[this.itemProcessingIndex].week13);
      this.projection.week14 = this.getCaseWeek(this.itemProcessingIndex, 14, this.selectedListing[this.itemProcessingIndex].week14);
      this.projection.week15 = this.getCaseWeek(this.itemProcessingIndex, 15, this.selectedListing[this.itemProcessingIndex].week15);
      this.projection.week16 = this.getCaseWeek(this.itemProcessingIndex, 16, this.selectedListing[this.itemProcessingIndex].week16);
      this.projection.week17 = this.getCaseWeek(this.itemProcessingIndex, 17, this.selectedListing[this.itemProcessingIndex].week17);
      this.projection.week18 = this.getCaseWeek(this.itemProcessingIndex, 18, this.selectedListing[this.itemProcessingIndex].week18);
      this.projection.week19 = this.getCaseWeek(this.itemProcessingIndex, 19, this.selectedListing[this.itemProcessingIndex].week19);
      this.projection.week20 = this.getCaseWeek(this.itemProcessingIndex, 20, this.selectedListing[this.itemProcessingIndex].week20);
      this.projection.week21 = this.getCaseWeek(this.itemProcessingIndex, 21, this.selectedListing[this.itemProcessingIndex].week21);
      this.projection.week22 = this.getCaseWeek(this.itemProcessingIndex, 22, this.selectedListing[this.itemProcessingIndex].week22);
      this.projection.week23 = this.getCaseWeek(this.itemProcessingIndex, 23, this.selectedListing[this.itemProcessingIndex].week23);
      this.projection.week24 = this.getCaseWeek(this.itemProcessingIndex, 24, this.selectedListing[this.itemProcessingIndex].week24);
      this.projection.week25 = this.getCaseWeek(this.itemProcessingIndex, 25, this.selectedListing[this.itemProcessingIndex].week25);
      this.projection.week26 = this.getCaseWeek(this.itemProcessingIndex, 26, this.selectedListing[this.itemProcessingIndex].week26);
      this.projection.week27 = this.getCaseWeek(this.itemProcessingIndex, 27, this.selectedListing[this.itemProcessingIndex].week27);
      this.projection.week28 = this.getCaseWeek(this.itemProcessingIndex, 28, this.selectedListing[this.itemProcessingIndex].week28);
      this.projection.week29 = this.getCaseWeek(this.itemProcessingIndex, 29, this.selectedListing[this.itemProcessingIndex].week29);
      this.projection.week30 = this.getCaseWeek(this.itemProcessingIndex, 30, this.selectedListing[this.itemProcessingIndex].week30);
      this.projection.week31 = this.getCaseWeek(this.itemProcessingIndex, 31, this.selectedListing[this.itemProcessingIndex].week31);
      this.projection.week32 = this.getCaseWeek(this.itemProcessingIndex, 32, this.selectedListing[this.itemProcessingIndex].week32);
      this.projection.week33 = this.getCaseWeek(this.itemProcessingIndex, 33, this.selectedListing[this.itemProcessingIndex].week33);
      this.projection.week34 = this.getCaseWeek(this.itemProcessingIndex, 34, this.selectedListing[this.itemProcessingIndex].week34);
      this.projection.week35 = this.getCaseWeek(this.itemProcessingIndex, 35, this.selectedListing[this.itemProcessingIndex].week35);
      this.projection.week36 = this.getCaseWeek(this.itemProcessingIndex, 36, this.selectedListing[this.itemProcessingIndex].week36);
      this.projection.week37 = this.getCaseWeek(this.itemProcessingIndex, 37, this.selectedListing[this.itemProcessingIndex].week37);
      this.projection.week38 = this.getCaseWeek(this.itemProcessingIndex, 38, this.selectedListing[this.itemProcessingIndex].week38);
      this.projection.week39 = this.getCaseWeek(this.itemProcessingIndex, 39, this.selectedListing[this.itemProcessingIndex].week39);
      this.projection.week40 = this.getCaseWeek(this.itemProcessingIndex, 40, this.selectedListing[this.itemProcessingIndex].week40);
      this.projection.week41 = this.getCaseWeek(this.itemProcessingIndex, 41, this.selectedListing[this.itemProcessingIndex].week41);
      this.projection.week42 = this.getCaseWeek(this.itemProcessingIndex, 42, this.selectedListing[this.itemProcessingIndex].week42);
      this.projection.week43 = this.getCaseWeek(this.itemProcessingIndex, 43, this.selectedListing[this.itemProcessingIndex].week43);
      this.projection.week44 = this.getCaseWeek(this.itemProcessingIndex, 44, this.selectedListing[this.itemProcessingIndex].week44);
      this.projection.week45 = this.getCaseWeek(this.itemProcessingIndex, 45, this.selectedListing[this.itemProcessingIndex].week45);
      this.projection.week46 = this.getCaseWeek(this.itemProcessingIndex, 46, this.selectedListing[this.itemProcessingIndex].week46);
      this.projection.week47 = this.getCaseWeek(this.itemProcessingIndex, 47, this.selectedListing[this.itemProcessingIndex].week47);
      this.projection.week48 = this.getCaseWeek(this.itemProcessingIndex, 48, this.selectedListing[this.itemProcessingIndex].week48);
      this.projection.week49 = this.getCaseWeek(this.itemProcessingIndex, 49, this.selectedListing[this.itemProcessingIndex].week49);
      this.projection.week50 = this.getCaseWeek(this.itemProcessingIndex, 50, this.selectedListing[this.itemProcessingIndex].week50);
      this.projection.week51 = this.getCaseWeek(this.itemProcessingIndex, 51, this.selectedListing[this.itemProcessingIndex].week51);
      this.projection.week52 = this.getCaseWeek(this.itemProcessingIndex, 52, this.selectedListing[this.itemProcessingIndex].week52);
      this.projection.week53 = this.getCaseWeek(this.itemProcessingIndex, 53, this.selectedListing[this.itemProcessingIndex].week53);
      
      this.projection.brandId = this.selectedListing[this.itemProcessingIndex].brandId;
      this.projection.productOrderCode = this.selectedListing[this.itemProcessingIndex].orderCode;
      this.projection.confirmWeeks = this.confirmWeek[this.itemProcessingIndex].join();

      //creates a new budget in projections table
      const projectIndex =  this.projectIds.findIndex(p => p.productOrderCode == this.projection.productOrderCode && p.bannerId == this.projection.bannerId);
    if (projectIndex == -1) {
      this.projection.id = 0;
    } else {
      this.projection.id = this.projectIds[projectIndex].id;
    }
    if (this.projection.id == 0) {
      //deal with new column promotionWeeks
      if (this.projection.typeOfPromotion == 'Budget Promotion') {
        let promoWeeks = [];
          let tempWeeks = [this.projection.week1, this.projection.week2, this.projection.week3, this.projection.week4, this.projection.week5, this.projection.week6, this.projection.week7, this.projection.week8, this.projection.week9, this.projection.week10,
          this.projection.week11, this.projection.week12, this.projection.week13, this.projection.week14, this.projection.week15, this.projection.week16, this.projection.week17, this.projection.week18, this.projection.week19, this.projection.week20,
          this.projection.week21, this.projection.week22, this.projection.week23, this.projection.week24, this.projection.week25, this.projection.week26, this.projection.week27, this.projection.week28, this.projection.week29, this.projection.week30,
          this.projection.week31, this.projection.week32, this.projection.week33, this.projection.week34, this.projection.week35, this.projection.week36, this.projection.week37, this.projection.week38, this.projection.week39, this.projection.week40,
          this.projection.week41, this.projection.week42, this.projection.week43, this.projection.week44, this.projection.week45, this.projection.week46, this.projection.week47, this.projection.week48, this.projection.week49, this.projection.week50,
          this.projection.week51, this.projection.week52];
          tempWeeks.forEach(w => {
            (w == 0 || w === null ? 'N' : 'Y');
            promoWeeks.push(w == 0 || w === null ? 'N' : 'Y');
          })
          this.projection.promotionWeeks = promoWeeks.join();
      }
      this.projectionsService.create(this.projection).subscribe(data => {
        this.response = data;
        this.selectedListing[this.itemProcessingIndex].id = this.response.id;
        this.projection.id = this.response.id;
        const proj = {
          id: this.response.id,
          productOrderCode: this.projection.productOrderCode,
          noStores: this.projection.noStores,
          bannerId: this.projection.bannerId,
        }
        this.projectIds.push(proj);
        this.projectUpdated.push(this.projection.id);
        if (this.updatedAddCount >= this.totalItemsToAddorUpdate) {
          this.success.next('Successfully added.');
          this.alertType = "success";
        }
        //deal with promotion table
        if (this.projection.typeOfPromotion == 'Budget Promotion') {
          let x = new Promotion();
          x.id = 0;
          x.budgetPromotionId = this.projection.id;
          x.promotionalNumber = this.projectionForm.controls.promotionNo.value;
          x.promotionWeeks = this.projection.promotionWeeks;
          x.budgetId = this.originalBudgetId;
          this.promotionService.create(x).subscribe(s => {
            x.id = s.id;
            this.itemsPromo.push(x);
            this.success.next(`Successfully added.`);
            this.alertType = "success";
            this.updateEachItem();
          }, error => {
            this.success.next(`Unable to add.`);
            this.alertType = "danger";
            //}, error => {
          });
        } else {
          this.updateEachItem();
        }
      }, error => {
        this.success.next(`Unable to add.`);
        this.alertType = "danger";
      });
    } else {
      //deal with new column promotionWeeks
      if (this.projection.typeOfPromotion == 'Budget Promotion') {
        let promoWeeks = [];
        let tempWeeks = [this.projection.week1, this.projection.week2, this.projection.week3, this.projection.week4, this.projection.week5, this.projection.week6, this.projection.week7, this.projection.week8, this.projection.week9, this.projection.week10,
        this.projection.week11, this.projection.week12, this.projection.week13, this.projection.week14, this.projection.week15, this.projection.week16, this.projection.week17, this.projection.week18, this.projection.week19, this.projection.week20,
        this.projection.week21, this.projection.week22, this.projection.week23, this.projection.week24, this.projection.week25, this.projection.week26, this.projection.week27, this.projection.week28, this.projection.week29, this.projection.week30,
        this.projection.week31, this.projection.week32, this.projection.week33, this.projection.week34, this.projection.week35, this.projection.week36, this.projection.week37, this.projection.week38, this.projection.week39, this.projection.week40,
        this.projection.week41, this.projection.week42, this.projection.week43, this.projection.week44, this.projection.week45, this.projection.week46, this.projection.week47, this.projection.week48, this.projection.week49, this.projection.week50,
        this.projection.week51, this.projection.week52];
        tempWeeks.forEach(w => {
          (w == 0 || w === null ? 'N' : 'Y');
          promoWeeks.push(w == 0 || w === null ? 'N' : 'Y');
        })
        this.projection.promotionWeeks = promoWeeks.join();
      }
      this.projectionsService.update(this.projection).subscribe(data => {
        this.projectUpdated.push(this.projection.id);
        if (this.updatedAddCount >= this.totalItemsToAddorUpdate) {
          this.success.next('Successfully updated.');
          this.alertType = "success";
        }
        //deal with promotion table 
        if (this.projection.typeOfPromotion == 'Budget Promotion') {

          const index = this.itemsPromo.findIndex(p => p.budgetPromotionId == this.projection.id);

          //creates a new promotion in promotion table
          if (index == -1) {
            let x = new Promotion();
            x.id = 0;
            x.budgetPromotionId = this.projection.id;
            x.promotionalNumber = this.projectionForm.controls.promotionNo.value;
            x.promotionWeeks = this.projection.promotionWeeks;
            x.budgetId = this.originalBudgetId;
            this.promotionService.create(x).subscribe(s => {
              x.id = s.id;
              this.itemsPromo.push(x);
              this.success.next(`Successfully added promotion.`);
              this.alertType = "success";
              this.updateEachItem();
            }, error => {
              this.success.next(`Unable to add promotion.`);
              this.alertType = "danger";
              //}, error => {
            });

          } else {
            //updates a promotion in promotion table
            let x = new Promotion();
            x.id = this.itemsPromo[index].id;
            x.budgetPromotionId = this.projection.id;
            x.promotionalNumber = this.projectionForm.controls.promotionNo.value;
            x.promotionWeeks = this.projection.promotionWeeks;
            x.budgetId = this.originalBudgetId;
            this.promotionService.update(x).subscribe(data => {
              this.itemsPromo.push(x);
              this.success.next(`Successfully updated promotion.`);
              this.alertType = "success";
              this.updateEachItem();
            }, error => {
              this.success.next(`Unable to update promotion.`);
              this.alertType = "danger";
            });
          }
        } else {
          this.updateEachItem();
        }
      }, error => {
        this.success.next(`Unable to update.`);
        this.alertType = "danger";
      });
    }
  }
  onDelete() {
    if (this.projection.id) {
      this.projectionsService.remove(this.projection.id).subscribe(data => {
        this.router.navigate(['/projections/projections-new']);
      });
    }
  }
  getUserInfo() {
    this.administrationRole = false;
    const user = JSON.parse(sessionStorage.getItem('currentUser'));
    if (user === null) { return};
    this.administrationRole = user.administrationRole === 'Administration' ? true : false;
  
  }
  
  onConfirm() {
    this.confirm = true;
    this.incoming.forEach((e, index) => {
      e.forEach(f => {
        f.confirmWeek = 'Y';
        const indexWeekNumber = f.weekNumber - 1;
        this.confirmWeek[index][indexWeekNumber] = 'Y';
      })

   })
   this.selectedListing.forEach((e, index) => {
     e.confirmWeeks = this.confirmWeek[index].join();
   })
   this.onSubmit();
   this.getWeeks();
  }

  unConfirm() {
    this.incoming.forEach((e, index) => {
       e.forEach(f => {
         f.confirmWeek = 'N';
         const indexWeekNumber = f.weekNumber - 1;
         this.confirmWeek[index][indexWeekNumber] = 'N';
       })

    })
    this.selectedListing.forEach((e, index) => {
      e.confirmWeeks = this.confirmWeek[index].join();
    })
    this.onSubmit();
    this.getWeeks();
  }
  onClickSelect() {
    this.selectBox = !this.selectBox;
    this.checkBox.fill(this.selectBox);
  }
  promotion() {
    //overlays budget
    this.projectUpdatedIndex++;
    if (this.projectUpdatedIndex >= this.projectUpdated.length) {

      return;
    }
    let promoWeeks = [];
    //get budget promotion data
    this.projectionsService.getOne(this.projectUpdated[this.projectUpdatedIndex]).subscribe(promoData => {
      let tempWeeks = [promoData.week1, promoData.week2, promoData.week3, promoData.week4, promoData.week5, promoData.week6, promoData.week7, promoData.week8, promoData.week9, promoData.week10,
      promoData.week11, promoData.week12, promoData.week13, promoData.week14, promoData.week15, promoData.week16, promoData.week17, promoData.week18, promoData.week19, promoData.week20,
      promoData.week21, promoData.week22, promoData.week23, promoData.week24, promoData.week25, promoData.week26, promoData.week27, promoData.week28, promoData.week29, promoData.week30,
      promoData.week31, promoData.week32, promoData.week33, promoData.week34, promoData.week35, promoData.week36, promoData.week37, promoData.week38, promoData.week39, promoData.week40,
      promoData.week41, promoData.week42, promoData.week43, promoData.week44, promoData.week45, promoData.week46, promoData.week47, promoData.week48, promoData.week49, promoData.week50,
      promoData.week51, promoData.week52];
      tempWeeks.forEach(w => {
        promoWeeks.push(w == 0 || w === null ? 'N' : 'Y');
      })

      //creates a budget in projections if one does not exist    
      this.projectionsService.getOrderCode(promoData.customerId, promoData.vendorId, promoData.customerEndId, promoData.bannerId, promoData.year, 'Budget', promoData.productOrderCode).subscribe(budgetData => {
        if (budgetData.length == 0) {
          promoData.id = 0;
          promoData.promotionWeeks = promoWeeks.join();
          promoData.typeOfPromotion = 'Budget';
          promoData.budgetPromotionId = this.projectUpdated[this.projectUpdatedIndex];

          //create budget and get original budgetId
          this.projectionsService.create(promoData).subscribe(e => {
            this.originalBudgetId = e.id;
            const index = this.itemsPromo.findIndex(p => p.budgetPromotionId == this.projectUpdated[this.projectUpdatedIndex]);

            //promotion exists - created in updateEachItem()
            // if (index == -1) {
            // } else {
              //updates promotion table
              let x = new Promotion();
              x.id = this.itemsPromo[index].id;
              x.budgetPromotionId = this.itemsPromo[index].budgetPromotionId;
              x.promotionalNumber = this.projectionForm.controls.promotionNo.value;
              x.promotionWeeks = promoData.promotionWeeks;
              x.budgetId = this.originalBudgetId;
              this.promotionService.update(x).subscribe(data => {
                this.success.next(`Successfully updated promotion.`);
                this.alertType = "success";
                this.promotion();
              }, error => {
                this.success.next(`Unable to update promotion.`);
                this.alertType = "danger";
              });
            // }
          })
        } else {
          //if budget exists creates a budget promotion in projections table
          this.projection2 = budgetData[0];
          const index = this.itemsPromo.findIndex(p => p.budgetPromotionId == this.projectUpdated[this.projectUpdatedIndex]);
          // if (index == -1) {
          // } else {
            //updates promotion table
            let x = new Promotion();
            x.id = this.itemsPromo[index].id;
            x.budgetPromotionId = this.itemsPromo[index].budgetPromotionId;
            x.promotionalNumber = this.projectionForm.controls.promotionNo.value;
            x.promotionWeeks = promoData.promotionWeeks;
            x.budgetId = this.projection2.id;
            this.promotionService.update(x).subscribe(data => {
              this.duplicate(promoData, promoWeeks);
              this.projectionsService.update(this.projection2).subscribe(data => {
                this.success.next(`Successfully updated promotion.`);
                this.promotion();
              })
              this.alertType = "success";
            }, error => {
              this.success.next(`Unable to update promotion.`);
              this.alertType = "danger";
            });
          // }
          // this.duplicate(promoData, promoWeeks);
          // this.projectionsService.update(this.projection2).subscribe(data => {
          //   this.promotion();
          // })
        }
      })
    })
  }
  duplicate(p, promo) {
    this.projection2.week1 = p.week1 > 0 ? p.week1 : null; 
    this.projection2.week2 = p.week2 > 0 ? p.week2 : null;
    this.projection2.week3 = p.week3 > 0 ? p.week3 : null; 
    this.projection2.week4 = p.week4 > 0 ? p.week4 : null;  
    this.projection2.week5 = p.week5 > 0 ? p.week5 : null;  
    this.projection2.week6 = p.week6 > 0 ? p.week6 : null;  
    this.projection2.week7 = p.week7 > 0 ? p.week7 : null;  
    this.projection2.week8 = p.week8 > 0 ? p.week8 : null;  
    this.projection2.week9 = p.week9 > 0 ? p.week9 : null;  
    this.projection2.week10 = p.week10 > 0 ? p.week10 : null;  
    this.projection2.week11 = p.week11 > 0 ? p.week11 : null;  
    this.projection2.week12 = p.week12 > 0 ? p.week12 : null;  
    this.projection2.week13 = p.week13 > 0 ? p.week13 : null;  
    this.projection2.week14 = p.week14 > 0 ? p.week14 : null;  
    this.projection2.week15 = p.week15 > 0 ? p.week15 : null;  
    this.projection2.week16 = p.week16 > 0 ? p.week16 : null;  
    this.projection2.week17 = p.week17 > 0 ? p.week17 : null;  
    this.projection2.week18 = p.week18 > 0 ? p.week18 : null;  
    this.projection2.week19 = p.week19 > 0 ? p.week19 : null;  
    this.projection2.week20 = p.week20 > 0 ? p.week20 : null;  
    this.projection2.week21 = p.week21 > 0 ? p.week21 : null;  
    this.projection2.week22 = p.week22 > 0 ? p.week22 : null;  
    this.projection2.week23 = p.week23 > 0 ? p.week23 : null;  
    this.projection2.week24 = p.week24 > 0 ? p.week24 : null;  
    this.projection2.week25 = p.week25 > 0 ? p.week25 : null;  
    this.projection2.week26 = p.week26 > 0 ? p.week25 : null;  
    this.projection2.week27 = p.week27 > 0 ? p.week27 : null;  
    this.projection2.week28 = p.week28 > 0 ? p.week28 : null;  
    this.projection2.week29 = p.week29 > 0 ? p.week29 : null;  
    this.projection2.week30 = p.week30 > 0 ? p.week30 : null;  
    this.projection2.week31 = p.week31 > 0 ? p.week31 : null;  
    this.projection2.week32 = p.week32 > 0 ? p.week32 : null;  
    this.projection2.week33 = p.week33 > 0 ? p.week33 : null;  
    this.projection2.week34 = p.week34 > 0 ? p.week34 : null;  
    this.projection2.week35 = p.week35 > 0 ? p.week35 : null;  
    this.projection2.week36 = p.week36 > 0 ? p.week36 : null;  
    this.projection2.week37 = p.week37 > 0 ? p.week37 : null;  
    this.projection2.week38 = p.week38 > 0 ? p.week38 : null;  
    this.projection2.week39 = p.week39 > 0 ? p.week39 : null;  
    this.projection2.week40 = p.week40 > 0 ? p.week40 : null;  
    this.projection2.week41 = p.week41 > 0 ? p.week41 : null;  
    this.projection2.week42 = p.week42 > 0 ? p.week42 : null;  
    this.projection2.week43 = p.week43 > 0 ? p.week43 : null;  
    this.projection2.week44 = p.week44 > 0 ? p.week44 : null;  
    this.projection2.week45 = p.week45 > 0 ? p.week45 : null;  
    this.projection2.week46 = p.week46 > 0 ? p.week46 : null;  
    this.projection2.week47 = p.week47 > 0 ? p.week47 : null;  
    this.projection2.week48 = p.week48 > 0 ? p.week48 : null;  
    this.projection2.week49 = p.week49 > 0 ? p.week49 : null;  
    this.projection2.week50 = p.week50 > 0 ? p.week50 : null;  
    this.projection2.week51 = p.week51 > 0 ? p.week51 : null;  
    this.projection2.week52 = p.week52 > 0 ? p.week52 : null;  
    this.projection2.week53  = p.week53 > 0 ? p.week53 : null; 
    this.projection2.promotionWeeks = promo.join();
    this.projection2.budgetPromotionId = this.projectUpdated[this.projectUpdatedIndex];
  
  }
}


