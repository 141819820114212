import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { PalletType } from '../../../models/pallet-type.model';
import { PalletTypeService } from '../../../services/pallet-type.service';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
@Component({
  selector: 'app-pallet-type-edit',
  templateUrl: './pallet-type-edit.component.html',
  styleUrls: ['./pallet-type-edit.component.css']
})
export class PalletTypeEditComponent implements OnInit {
  title = 'Type of Pallet';
  newOne = true;
  palletType: PalletType;
  palletForm: UntypedFormGroup;
  successMessage: string;
  private success = new Subject<string>();
  staticAlertClosed = false;
  constructor(private route: ActivatedRoute,
    private router: Router,
    private fb: UntypedFormBuilder,
    private palletSvc: PalletTypeService) { }

  ngOnInit(): void {
    this.palletForm = this.fb.group({
      palletType: ['']
    })
    
    this.route.data.subscribe(data => {
      if (data.dataPalletType !== undefined) {
        this.title = 'Type of Pallet Edit';
        this.newOne = false;
        this.palletType = data.dataPalletType;
        this.fillFormData();
      } else {
        this.title = 'Type of Pallet New';
        this.newOne = true;
        this.palletType = new PalletType();
        this.palletForm.reset();
      }
    });
    setTimeout(() => this.staticAlertClosed = true, 20000);
    this.success.subscribe((message) => this.successMessage = message);
    this.success.pipe(
      debounceTime(5000)
    ).subscribe(() => this.successMessage = null);     
  }
  fillFormData() {
    this.palletForm.setValue({
      palletType: this.palletType.palletType1
    });
  }

  onSubmit() {
    this.palletType.palletType1 = this.palletForm.controls.palletType.value;
    if (this.newOne) {
      this.palletSvc.create(this.palletType).subscribe(data => {
        this.palletType.palletTypeId =  data.palletTypeId;
        this.newOne = false;
        this.title = 'Type of Pallet Edit';
        this.success.next(`Successfully added.`);
      }, error => {
      });
    } else {
      this.palletSvc.update(this.palletType).subscribe(data => {
        this.success.next(`Successfully changed.`);
      }, error => {
      });
    }
  }

  onDelete() {
    if (this.palletType.palletTypeId) {
      this.palletSvc.remove(this.palletType.palletTypeId).subscribe(data => {
        this.router.navigate(['/other/pallet-type-list']);
      });
    }
  }      

}
