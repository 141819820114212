import { Component } from '@angular/core';
import { LkupProductGroupService } from 'src/app/services/lkup-product-group.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-product-group-list',
  templateUrl: './product-group-list.component.html',
  styleUrls: ['./product-group-list.component.scss']
})
export class ProductGroupListComponent {
  productGroup: any;
  page = 1;
  pageSize = 25;
  vendorFilter = [];
  vendor = 0;
  collectionSize = 0;
  constructor(private router: Router,
              private productGroupService: LkupProductGroupService) {}
  
  ngOnInit() {
    this.getDefaultDataScreen();
  }
  getDefaultDataScreen() {
    this.vendor = 0;
    this.resetFilters();
  }
  loadPage($event) {
    this.resetFilters();
  }
  resetFilters() {
    this.productGroupService.getGroupFilter(this.vendor, this.page, this.pageSize).subscribe(group => {
      this.productGroup = group.item;
      this.vendorFilter = group.item2;
    });
  }
  onRowClicked(group) {
    this.router.navigate(['/products/group-edit', group.id]);
  }
}
