export class DataLink {
    dataLinkId: number;
    userId: number;
    vendorId: number;
    salesYear: number;
    lastUpdate: Date;
}

export class DataLinkList {
    dataLinkId: number;
    userId: number;
    vendorId: number;
    salesYear: number;
    lastUpdate: Date;
    name: string;
    vendorName: string;
}