import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Form, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { Customer } from 'src/app/models/customer.model';
import { CustomerService } from 'src/app/services/customer.service';
import { ShipToAddress } from 'src/app/models/shipto.model';
import { ShipToAddressService } from 'src/app/services/ship-to-address.service';
import { SorterService } from 'src/app/services/sorter.service';
import { Sorter2Service } from 'src/app/services/sorter2.service';
import { Sorter3Service } from 'src/app/services/sorter3.service';
import { ProductService } from 'src/app/services/product.service';
import { CustomerRetailService } from 'src/app/services/customer-retail.service';
import { ProjectionsService } from 'src/app/services/projections.service';
import { LocationService } from 'src/app/services/location.service';
import { Big } from 'big.js';
import { WeekKalcor } from 'src/app/models/week-kalcor.model';
import { WeekKalcorService } from 'src/app/services/week-kalcor.service';
import { VendorService } from 'src/app/services/vendor.service';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-review-week',
  templateUrl: './review-week.component.html',
  styleUrls: ['./review-week.component.scss']
})
export class ReviewWeekComponent implements OnInit {

  projectionForm: UntypedFormGroup;
  vendorOptions = [];
  vendorId: number;
  customerOptions = [];
  customerOriginal = [];
  customerId: number;
  regions: string;
  allRegions: string;
  years = [];
  todaysDate: Date;
  weeks = [];
  months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  shipToOriginal: ShipToAddress[];
  shipToByCustomer = [];
  shipToOptions = [];
  shipToRegions = [];
  shipToId: number;
  regionShipto = [];
  regionShipToOriginal = [];
  regionHeadOffice = [];
  projectionQuery = [];
  listingOriginal = [];
  bannerRegion = [];
  banners = [];
  bannersTotal: number;
  bannerIndex: number;
  projections = [];
  projectionsPrint = [];
  bannerProcessed = [];
  headOffice = [];
  dotOptions = [];
  dotId: number;
  viewShipTo: string;
  viewBanners: string;
  weekHeader = ['WK','WK','WK','WK','WK','WK','WK','WK','WK','WK',
                'WK','WK','WK','WK','WK','WK','WK','WK','WK','WK',
                'WK','WK','WK','WK','WK','WK','WK','WK','WK','WK',
                'WK','WK','WK','WK','WK','WK','WK','WK','WK','WK',
                'WK','WK','WK','WK','WK','WK','WK','WK','WK','WK','WK','WK'];
  weekNumberHeader = ['1','2','3','4','5','6','7','8','9','10',
                      '11','12','13','14','15','16','17','18','19','20',
                      '21','22','23','24','25','26','27','28','29','30',
                      '31','32','33','34','35','36','37','38','39','40',
                      '41','42','43','44','45','46','47','48','49','50','51','52'];
  begWeek: number;
  endWeek: number;
  weeksKalcor: WeekKalcor[];
  continueChanges = true;
  dateCompare: string;
  shipToIndex: number;
  startPeriodOptions = [];
  endPeriodOptions = [];
  private success = new Subject<string>();
  alertType = "success";
  successMessage: string;

  constructor(
    private fb: UntypedFormBuilder,
    private customerService: CustomerService,
    private sortSvc: SorterService,
    private shipToSvc: ShipToAddressService,
    private productService: ProductService,
    private bannerService: CustomerRetailService,
    private projectionSvc: ProjectionsService,
    private sort2Svc: Sorter2Service,
    private sort3Svc: Sorter3Service,
    private locationSvc: LocationService,
    private weekKalcorService: WeekKalcorService,
    private vendorSvc: VendorService
  ) { }

  ngOnInit(): void {
    this.alertType = "clear";
    this.todaysDate = new Date();
    const firstYear = this.todaysDate.getFullYear() - 2;
    this.viewShipTo = 'Yes';
    this.viewBanners = 'Yes';
    this.begWeek = 1;
    this.endWeek = 52;
    this.years = [];
    const firstWeek = 1;
    for (let i = 0; i < 53; i++) {
      this.weeks.push(firstWeek+i);
    }
    this.allRegions = '';
    this.projectionForm = this.fb.group({
      vendorId: ['', Validators.required],
      customerId: ['', Validators.required],
      dotId: [''],
      shipToId: [''],
      viewShipTo: [''],
      viewBanners: [''],
      noStores: [''],
      weekStart: [''],
      weekEnd: [''],
      year: [''],
      monthStart: [''],
      monthEnd: ['']
    })
    this.vendorSvc.getCompany().subscribe(vend => {
      this.vendorOptions = vend;
      this.weekKalcorService.getYears().subscribe(x => {
        this.years = x;
        this.customerService.getCompany().subscribe(cust => {
          this.customerOriginal = cust;
          this.locationSvc.getDot().subscribe(dot => {
            this.dotOptions = dot;
            this.fillFormData();
            const year = this.projectionForm.get('year').value * 1;
            this.setupWeekYears(year);
            this.onChanges();
          })
        })
      })
    })
  }
  fillFormData() {
    this.projectionForm.setValue({
      vendorId: '',
      customerId: '',
      dotId: '',
      shipToId: '',
      viewShipTo: this.viewShipTo,
      viewBanners: this.viewBanners,
      noStores: '',
      weekStart: this.begWeek,
      weekEnd: this.endWeek,
      year: this.todaysDate.getFullYear(),
      monthStart: '',
      monthEnd: ''
    })
  }
  onChanges() {
    this.projectionForm.get('vendorId').valueChanges.subscribe(val => {
      this.vendorId = val * 1;
      this.buildCustomerOptions(this.vendorId);
    })
    this.projectionForm.get('customerId').valueChanges.subscribe(val => {
      this.customerId = val * 1;
      this.dotId = 0;
      this.shipToOptions = [];
      this.getShipTo(val);
    })

    this.projectionForm.get('dotId').valueChanges.subscribe(val => {
      this.buildShipToOptions();
    })
    this.projectionForm.get('viewShipTo').valueChanges.subscribe(val => {
      this.viewShipTo = val;
    })
    this.projectionForm.get('viewBanners').valueChanges.subscribe(val => {
      this.viewBanners = val;
    })
    this.projectionForm.get('weekStart').valueChanges.subscribe(val => {
        this.begWeek = val * 1;
        this.setUpWeeks();
        if (this.continueChanges) {
          this.projectionForm.controls.monthStart.setValue('');
        }
        this.continueChanges = false;
      })
    this.projectionForm.get('weekEnd').valueChanges.subscribe(val => {
      this.endWeek = val * 1;  
      this.setUpWeeks();
        if (this.continueChanges) {
          this.projectionForm.controls.monthEnd.setValue('');
        }
        this.continueChanges = false;
      })

    this.projectionForm.get('year').valueChanges.subscribe(val => {
      this.setupWeekYears(val);
    })

    this.projectionForm.get('monthStart').valueChanges.subscribe(val => {
        this.findBegWeek(val);
    })

    this.projectionForm.get('monthEnd').valueChanges.subscribe(val => {
        this.findEndWeek(val);
    })

  }
  
  findBegWeek(weekNumber) {
    const weekIndex = this.weeks.findIndex(w => w == weekNumber * 1);
    if (weekIndex > -1) {
       this.begWeek = this.weeks[weekIndex];
       this.projectionForm.controls.weekStart.setValue(this.begWeek);
    }

    // const year = this.projectionForm.controls.year.value;
    // let index = this.months.findIndex(m => m == monthName);
    // if (index == -1) {
    //   return;
    // }
    // index++;
    // let month = index + '';
    // if (index < 10 ) {
    //   month = '0' + month;
    // } 
    // this.dateCompare = year + '-' + month + '-01';
    // this.weeksKalcor.forEach(w => {
    //   const weekStart = w.weekStartDate.toString();
    //   const weekEnd = w.weekEndDate.toString();
    //   if (this.dateCompare >= weekStart && this.dateCompare <= weekEnd) {
    //     this.projectionForm.controls['weekStart'].setValue(w.week); 
    //     this.begWeek = w.week * 1;
    //   } 
    // })
  }
  findEndWeek(weekNumber) {
    const weekIndex = this.weeks.findIndex(w => w == weekNumber * 1);
    if (weekIndex > -1) {
      this.endWeek = this.weeks[weekIndex];
      this.projectionForm.controls.weekEnd.setValue(this.endWeek);
    }
    // const year = this.projectionForm.controls.year.value;
    // let index = this.months.findIndex(m => m == monthName);
    // if (index == -1) {
    //   return;
    // }
    // index++;
    // let month = index + '';
    // if (index < 10 ) {
    //   month = '0' + month;
    // } 
    // this.dateCompare = year + '-' + month + '-28';
    // this.weeksKalcor.forEach(w => {
    //   const weekStart = w.weekStartDate.toString();
    //   const weekEnd = w.weekEndDate.toString();
    //   if (this.dateCompare >= weekStart && this.dateCompare <= weekEnd) {
    //     this.projectionForm.controls['weekEnd'].setValue(w.week); 
    //     this.endWeek = w.week * 1;
    //   } 
    // })
  }
  changeBegMonth() {
    const index = this.weeksKalcor.findIndex(w => w.week == this.begWeek);
    if (index > -1) {
      const tempMonth = this.weeksKalcor[index].weekStartDate.toString().substring(0,10);
      const tempDate = tempMonth.split('-');
      if (tempDate.length == 3) {
        const month = Number(tempDate[1]) - 1;
        this.projectionForm.controls['monthStart'].setValue(this.months[month])
      }
    }
  }

  changeEndMonth() {
    const index = this.weeksKalcor.findIndex(w => w.week == this.endWeek);
    if (index > -1) {
      const tempMonth = this.weeksKalcor[index].weekEndDate.toString().substring(0,10);
      const tempDate = tempMonth.split('-');
      if (tempDate.length == 3) {
        const month = Number(tempDate[1]) - 1;
        this.projectionForm.controls['monthEnd'].setValue(this.months[month])
      }
    }
  }
  setupWeekYears(year){
    this.weekKalcorService.getYear(year).subscribe(data => {
      this.weeksKalcor = data;
      this.startPeriodOptions=[];
      this.endPeriodOptions=[];
      let prevPeriod = 0;
      this.weeksKalcor.forEach(w => {
        if (w.period == prevPeriod) {
          const currentIndex = this.endPeriodOptions.length - 1;
          this.endPeriodOptions[currentIndex].weekId = w.weekId;
          this.endPeriodOptions[currentIndex].week = w.week;
          this.endPeriodOptions[currentIndex].weekStartDate = w.weekStartDate;
          this.endPeriodOptions[currentIndex].weekEndDate = w.weekEndDate;
          this.endPeriodOptions[currentIndex].period = w.period;
          this.endPeriodOptions[currentIndex].year = w.year;
        } else {
          this.startPeriodOptions.push({
            weekId: w.weekId,
            week: w.week,
            weekStartDate: w.weekStartDate,
            weekEndDate: w.weekEndDate,
            period: w.period,
            year: w.year      
          });
          this.endPeriodOptions.push({
            weekId: w.weekId,
            week: w.week,
            weekStartDate: w.weekStartDate,
            weekEndDate: w.weekEndDate,
            period: w.period,
            year: w.year              
          });
          prevPeriod = w.period;
        }
      })
    })
  }
  setUpWeeks() {
    this.weekHeader = [];
    this.weekNumberHeader = [];
    for(let x=this.begWeek; x <= this.endWeek; x++) {
      this.weekHeader.push('WK');
      this.weekNumberHeader.push(x + '');
    }
  }
  buildCustomerOptions(vendor) {
    this.customerOptions = [];
    this.projectionsPrint = [];
    this.shipToOptions = [];
    this.productService.getActiveListingByVendor(vendor).subscribe(listing => {
      this.listingOriginal = listing;
      this.listingOriginal.forEach(list => {
        const index = this.customerOptions.findIndex(c => c.id == list.customerId);
        if (index == -1) {
          this.customerOptions.push(
            {id: list.customerId,
              company: list.customer
            }
          )
        }
      })
     this.customerOptions = this.sortSvc.sorter(this.customerOptions,'company');
    })

  }
  getShipTo(custId: number) {
    this.shipToSvc.getFilteredListing('Active', 0, custId, 1, 9999).subscribe(data => {
      this.shipToByCustomer = data.item;
      this.buildShipToOptions();
    })
  }
  buildShipToOptions() {
    // Build ship to options 
    // only those selected in listings

    this.shipToOptions = [];
    // const dotId = this.projectionForm.controls.dotId.value * 1;
    // let dotLocation = '';
    // if (dotId > -1) {
    //   const dotIndex = this.dotOptions.findIndex(d => d.id == dotId);
    //   if (dotIndex > -1) {
    //     dotLocation = this.dotOptions[dotIndex].location;
    //   }
    // }
    
    if (this.customerId && this.vendorId) {
      this.listingOriginal.forEach(l => {
        if (l.customerId == this.customerId ) {
          const ship = l.shipToIds.split(',');
          ship.forEach(s => {
            const shipIndex = this.shipToByCustomer.findIndex(s2 => s2.id == s * 1);
            if (shipIndex > -1) {
                const index = this.shipToOptions.findIndex(o => o.id == s * 1);
                if (index == -1) {
                  const shipTo = {
                    id: this.shipToByCustomer[shipIndex].id,
                    company: this.shipToByCustomer[shipIndex].company + ' (' + this.shipToByCustomer[shipIndex].location + ')',
                    location: this.shipToByCustomer[shipIndex].location
                  }
                  this.shipToOptions.push(shipTo); 
                }
            }
          });
        }
      })
      // this.shipToIndex = -1;
      // this.processEachShipTo();
    }
  }
  validate() {
    if (this.projectionForm.invalid) {
      //this.success.next(`Please enter required fields.`);
      this.successMessage = 'Please enter required fields.'
      this.alertType = "danger";
      return;
    }
    this.shipToOptions = this.sort2Svc.sorter(this.shipToOptions, 'location', 'company');
    this.shipToIndex = -1;
    this.projectionsPrint = [];
    this.alertType = "info";
    this.processEachShipTo();
    
  }
  processEachShipTo() {
    this.shipToIndex++;
    if (this.shipToIndex >= this.shipToOptions.length) {
      this.alertType = "clear";
      return;
    }
    this.shipToId = this.shipToOptions[this.shipToIndex].id;
    this.getRegions();
  }
  getRegions() {
    this.regionShipto = [];
    this.shipToSvc.getFilteredListing('Active', this.shipToId, 0, 1, 999).subscribe(data => {
      this.shipToRegions = data.item;
      const regionsAll = [];
      this.shipToRegions.forEach(ship => {
        if (regionsAll.indexOf(ship.shipRegion) == -1) {
          regionsAll.push(ship.shipRegion);
        }
      })
      this.regions = regionsAll.join();
      if (this.allRegions) {
        this.allRegions += ',';
      }
      this.allRegions += this.regions;
      // next step is to determine what banners to ship to
    // based on region of banner
      this.getBanners();
    })
  }

  getBanners() {
    if (!this.customerId) {
      return;
    }
    if (!this.shipToId) {
      return;
    }

    // start with banners allowed based on listing
    this.banners = [];
    this.listingOriginal.forEach(l => {
      if (l.customerId == this.customerId) {
        if (l.shipToIds.indexOf(this.shipToId) > -1) {
          const temp = l.retailIds.split(',');
          temp.forEach(singleBanner => {
            if (singleBanner != '') {
              if (this.banners.indexOf(singleBanner) == -1) {
                this.banners.push(singleBanner);
              }
            }
          })
        }
      }
    })
    
    this.bannerIndex = -1;
    this.bannerRegion = [];
    this.getBannerRegion();
  }
  getBannerRegion() {
    // get the region for each banner
    // and make sure it is a region that the shipto is handling
    this.bannerIndex++;
    if (this.bannerIndex >= this.banners.length) {
      // next step is to query projections
      // by customerid and banner 
      this.bannersTotal = this.bannerRegion.length;
      this.bannerIndex = -1;
      this.bannerProcessed = [];
      this.projections = [];
      //this.projectionsPrint = [];
      this.getProjections();
      return;
    }

    this.bannerService.getRegion(this.banners[this.bannerIndex]).subscribe(data => {
    //  const regions = this.regions.split(',');
      data.forEach(e => {
        if (this.regions.indexOf(e.bannerRegion) > -1) {
          const index = this.bannerRegion.findIndex(f => f.id == e.id && f.bannerRegion == e.bannerRegion);
          if (index == -1) {
            this.bannerRegion.push(e);
          }
        }
      })
      this.getBannerRegion();
    });

  }

  getProjections() {
    // ready to query projections
    this.bannerIndex++;
    //this.alertType = "info";
    if (this.bannerIndex >= this.bannersTotal) {
      this.prepareProjections();
      return;
    }

    // const bannerIndex = this.bannerProcessed.findIndex(b => b.id == this.bannerRegion[this.bannerIndex].id);
    // if (bannerIndex > -1) {
    //   this.getProjections();
    //   return;
    // }

    let proj = this.projections.filter(p => p.bannerId == this.bannerRegion[this.bannerIndex].id && p.id > 0);
    // if (proj.length > 0) {
    //   let cloned = JSON.parse(JSON.stringify(proj));
    //   cloned.forEach((p, index) => {
    //     cloned[index].id = 0;
    //     cloned[index].noStores = this.bannerRegion[this.bannerIndex].noOfStores;
    //     cloned[index].bannerRegion = this.bannerRegion[this.bannerIndex].bannerRegion;
    //     const cloned2 = JSON.parse(JSON.stringify(cloned[index]));
    //     this.projections.push(cloned2); 
    //   }) 
    //   this.getProjections();
    //   return;      
    // }
    if (proj.length > 0) {
      proj.forEach((p, index) => {
        proj[index].noStores += this.bannerRegion[this.bannerIndex].noOfStores;
        proj[index].bannerRegion += ' ' + this.bannerRegion[this.bannerIndex].bannerRegion;
      }) 
      this.getProjections();
      return;      
    }

    const year = this.projectionForm.controls.year.value * 1;
    const promo = "Budget";
    //this.projectionSvc.getOrderCustomer(this.vendorId, this.customerId, this.bannerRegion[this.bannerIndex].id, year, promo).subscribe(data => {
      //this.projectionSvc.getPromotion(this.customerId, this.vendorId, 0, this.bannerRegion[this.bannerIndex].id, year, null).subscribe(data => {
        this.projectionSvc.getPromotion(this.customerId, this.vendorId, 0, this.bannerRegion[this.bannerIndex].id, year, promo).subscribe(data => {
      if (data.length > 0) {
        data.forEach(p => {
          let tempWeeks = [p.week1, p.week2, p.week3, p.week4, p.week5, p.week6, p.week7, p.week8, p.week9, p.week10, 
            p.week11, p.week12, p.week13, p.week14, p.week15, p.week16, p.week17, p.week18, p.week19, p.week20,
            p.week21, p.week22, p.week23, p.week24, p.week25, p.week26, p.week27, p.week28, p.week29, p.week30,
            p.week31, p.week32, p.week33, p.week34, p.week35, p.week36, p.week37, p.week38, p.week39, p.week40,
            p.week41, p.week42, p.week43, p.week44, p.week45, p.week46, p.week47, p.week48, p.week49, p.week50,
            p.week51, p.week52];
          
          let weeks = [];
          tempWeeks.forEach((w, index) => {
            if (index >= this.begWeek - 1 && index <= this.endWeek - 1) {
              weeks.push(w);
            } 
          })

          weeks.forEach((w, index) => {
            if (w) {
              // w = w / p.noStores;
              const bigNum: any = Big(w)
             .div(p.noStores)
             .round(2);
             weeks[index] = bigNum * 1;
            } else {
              weeks[index] = 0;
            }
          })  
          const index = this.listingOriginal.findIndex(l => l.orderCode == p.productOrderCode);
          let itemDescription = '';
          let category = '';
          if (index > -1) {
            itemDescription = this.listingOriginal[index].itemDescription;
            category = this.listingOriginal[index].category;
          }
          
          let promo = [];
          if (p.promotionWeeks !== null) {
            promo = p.promotionWeeks.split(',');
          }
          let promotionalNumbers = Array(51).fill("");
            p.itemsBudget.forEach(e => {
              
              const ret = e.promotionWeeks.split(",");
              ret.forEach((f, index) => {
                promotionalNumbers[index] = f == "Y" ? e.promotionalNumber : promotionalNumbers[index];
              })
            })

          //promotionalNumber: p.itemsBudget[0].promotionalNumber


          const proj = {
            id: p.id,
            bannerId: p.bannerId,
            bannerCompany: this.bannerRegion[this.bannerIndex].company,
            bannerRegion:  this.bannerRegion[this.bannerIndex].bannerRegion,
            totalStores: this.bannerRegion[this.bannerIndex].totalStores,
            noStores: this.bannerRegion[this.bannerIndex].noOfStores,
            //noStores: p.noStores,
            productOrderCode: p.productOrderCode,
            itemDescription: itemDescription,
            category: category,
            headOffice: this.bannerRegion[this.bannerIndex].headOfficeCompany,
            tag: '',
            tagColor: '',
            weeks: weeks,
            promotion: promo,
            promotionalNumber: promotionalNumbers
          }
          this.projections.push(proj);         
          if (this.headOffice.indexOf(this.bannerRegion[this.bannerIndex].headOfficeCompany) == -1) {
            this.headOffice.push(this.bannerRegion[this.bannerIndex].headOfficeCompany);
          }
        });

      }
      this.getProjections();
    })

  }
  prepareProjections() {
    // remove banners with p.id > 0
    // const tempProjections = this.projections.filter(p => p.id == 0);
    // this.projections = [];
    // tempProjections.forEach(p => {
    //   const cloned = JSON.parse(JSON.stringify(p));
    //   this.projections.push(cloned);
    // })
    // create subtotals for each head office
    // create totals for ship to
    let tempHeadOfficeTotals = [];
    let tempShipToTotals = [];
    let headOfficeRegion = [];
    if (this.projections.length > 0) {
      // ship to
      let currentIndex = 0;
      const cloned3 = JSON.parse(JSON.stringify(this.projections[0]));
      tempShipToTotals.push(cloned3);
      currentIndex = tempShipToTotals.length - 1;
      tempShipToTotals[currentIndex].tag = "Header7";  
      tempShipToTotals[currentIndex].productOrderCode = "";

      const cloned4 = JSON.parse(JSON.stringify(this.projections[0]));
      tempShipToTotals.push(cloned4);
      currentIndex = tempShipToTotals.length - 1;
      tempShipToTotals[currentIndex].tag = "Header8";  
      tempShipToTotals[currentIndex].productOrderCode = "";
      tempShipToTotals[currentIndex].shipTo = this.shipToOptions[this.shipToIndex].company;

      const cloned = JSON.parse(JSON.stringify(this.projections[0]));
      tempShipToTotals.push(cloned);
      currentIndex = tempShipToTotals.length - 1;
      // WK
      tempShipToTotals[currentIndex].tag = "Header5";  
      tempShipToTotals[currentIndex].productOrderCode = "";
      // ORDER, ITEM DESCRIPTION, CATEGORY
      const cloned2 = JSON.parse(JSON.stringify(this.projections[0]));
      tempShipToTotals.push(cloned2);
      currentIndex = tempShipToTotals.length - 1;
      tempShipToTotals[currentIndex].productOrderCode = "";
      tempShipToTotals[currentIndex].tag = "Header6";
    }
    this.projections = this.sort3Svc.sorter(this.projections, 'headOffice', 'bannerCompany', 'productOrderCode');
    //headOfficeRegion
    this.projections.forEach((p, index) => {
      //p.bannerRegion
      if (headOfficeRegion.length > 0) {
        const headOfficeIndex = headOfficeRegion.findIndex(h => h.headOffice == p.headOffice);
        if (headOfficeIndex == -1) {
          headOfficeRegion.push({headOffice : p.headOffice, bannerRegion : p.bannerRegion});
        } else {
          const ret = p.bannerRegion.split(" ");
          ret.forEach(r => {      
            if (headOfficeRegion[headOfficeIndex].bannerRegion.indexOf(r) == -1) {
              headOfficeRegion[headOfficeIndex].bannerRegion += " " + r;
            }
          })
          
        }
      } else {
        headOfficeRegion.push({headOffice : p.headOffice, bannerRegion : p.bannerRegion});
      }  
    })
    // calculate cases     
    this.projections.forEach((p, index) => {
    
      p.weeks.forEach((week, index2) => {
        const bigNum: any = Big(week)
          .times(p.noStores)
          .round(0);
        this.projections[index].weeks[index2] = bigNum * 1;
      });

      // create head office subtotals
      const indexHeadOffice = tempHeadOfficeTotals.findIndex(h => h.headOffice == p.headOffice && h.productOrderCode == p.productOrderCode)
      if (indexHeadOffice == -1) {
        const cloned = JSON.parse(JSON.stringify(p)); 
        tempHeadOfficeTotals.push(cloned);
      } else {
        p.weeks.forEach((week, index2) => {
          tempHeadOfficeTotals[indexHeadOffice].weeks[index2] += week;
        });      
      }

      // create ship to subtotals
      const indexShipTo = tempShipToTotals.findIndex(h => h.productOrderCode == p.productOrderCode);
      if (indexShipTo == -1) {
        const cloned = JSON.parse(JSON.stringify(p)); 
        tempShipToTotals.push(cloned);
      } else {
        p.weeks.forEach((week, index2) => {
          tempShipToTotals[indexShipTo].weeks[index2] += week;
        });      
      }
    })

    if (this.viewShipTo != 'Yes') {
      tempShipToTotals = [];
    }
    let previousBanner = '';
    let previousRegion = '';
    let previousHeadOffice = '';
    let lastIndex = -1;
    this.projections.forEach((h, indexProjection) => {
      if (h.headOffice != previousHeadOffice) {
        // HEAD OFFICE
        const cloned = JSON.parse(JSON.stringify(h));
        tempShipToTotals.push(cloned);
        lastIndex = tempShipToTotals.length - 1;
        tempShipToTotals[lastIndex].tag = "Header1";  
        tempShipToTotals[lastIndex].tagColor = "HeadOffice";
        // Head Office Company
        const cloned2 = JSON.parse(JSON.stringify(h));
        tempShipToTotals.push(cloned2);
        lastIndex = tempShipToTotals.length - 1;
        tempShipToTotals[lastIndex].tag = "Header2";  
        tempShipToTotals[lastIndex].tagColor = "HeadOffice";
        const headOfficeIndex = headOfficeRegion.findIndex(i => i.headOffice == h.headOffice);
        if (headOfficeIndex > -1) {
          tempShipToTotals[lastIndex].bannerRegion = headOfficeRegion[headOfficeIndex].bannerRegion;
        } 
        // WK
        const cloned3 = JSON.parse(JSON.stringify(h));
        tempShipToTotals.push(cloned3);
        lastIndex = tempShipToTotals.length - 1;
        tempShipToTotals[lastIndex].tag = "Header5";  
        tempShipToTotals[lastIndex].tagColor = "HeadOffice";

        // ORDER, ITEM DESCRIPTION, CATEGORY
        const cloned4 = JSON.parse(JSON.stringify(h));
        tempShipToTotals.push(cloned4);
        lastIndex = tempShipToTotals.length - 1;
        tempShipToTotals[lastIndex].tag = "Header6";  
        tempShipToTotals[lastIndex].tagColor = "HeadOffice";

        const headOfficeItems = tempHeadOfficeTotals.filter(t => t.headOffice == h.headOffice)
        headOfficeItems.forEach(items => {
          const cloned = JSON.parse(JSON.stringify(items));
          tempShipToTotals.push(cloned);
          lastIndex = tempShipToTotals.length - 1;
          tempShipToTotals[lastIndex].tagColor = "HeadOffice";
        })

        previousHeadOffice = h.headOffice; 
      }

// banners
    if (this.viewBanners == 'Yes') {
      if (h.bannerCompany != previousBanner) {
        // Banners
        const cloned = JSON.parse(JSON.stringify(h));
        tempShipToTotals.push(cloned);
        lastIndex = tempShipToTotals.length - 1;
        tempShipToTotals[lastIndex].tag = "Header3";  
        tempShipToTotals[lastIndex].tagColor = "Banner";
        // Banner Company
        const cloned2 = JSON.parse(JSON.stringify(h));
        tempShipToTotals.push(cloned2);
        lastIndex = tempShipToTotals.length - 1;
        tempShipToTotals[lastIndex].tag = "Header4b";  
        tempShipToTotals[lastIndex].tagColor = "Banner";
        // WK
        const cloned3 = JSON.parse(JSON.stringify(h));
        tempShipToTotals.push(cloned3);
        lastIndex = tempShipToTotals.length - 1;
        tempShipToTotals[lastIndex].tag = "Header5";  
        tempShipToTotals[lastIndex].tagColor = "Banner";

        // ORDER, ITEM DESCRIPTION, CATEGORY
        const cloned4 = JSON.parse(JSON.stringify(h));
        tempShipToTotals.push(cloned4);
        lastIndex = tempShipToTotals.length - 1;
        tempShipToTotals[lastIndex].tag = "Header6";  
        tempShipToTotals[lastIndex].tagColor = "Banner";

        previousBanner = h.bannerCompany;
        previousRegion = h.bannerRegion;
      }
    }

    if (this.viewBanners == 'Yes') {
      // details
      const cloned3 = JSON.parse(JSON.stringify(h));
      tempShipToTotals.push(cloned3);
      lastIndex = tempShipToTotals.length - 1;
      tempShipToTotals[lastIndex].tagColor = "Banner";
   }
    

    }) 

    tempShipToTotals.forEach(t => {
      const clone = JSON.parse(JSON. stringify(t))
      this.projectionsPrint.push(clone);
    })
    // create head office subtotals
    // let lastIndex = -1;
    // this.headOffice.forEach(headOffice => {
    //   let createIt = true;
    //   this.projections.forEach(p => {
    //     if (p.headOffice == headOffice) {
    //         if (createIt) {
    //           createIt = false;
    //           const cloned = JSON.parse(JSON.stringify(p)); 
    //           this.projections.push(cloned);
    //           lastIndex = this.projections.length - 1;
    //           this.projections[lastIndex].bannerCompany = "";
    //           this.projections[lastIndex].weeks.fill(0);
    //         }
    //         if (p.bannerCompany) {
    //           p.weeks.forEach((week, weekIndex) => {
    //             this.projections[lastIndex].weeks[weekIndex] += week;
    //           });
    //         }
    //     }
    //   })
    // })

    // create total projections
    // if (this.projections.length > 0) {
    //   const cloned = JSON.parse(JSON.stringify(this.projections[0])); 
    //   this.projections.push(cloned);
    //   lastIndex = this.projections.length - 1;
    //   this.projections[lastIndex].weeks.fill(0);
    //   this.projections[lastIndex].bannerCompany = "";
    //   this.projections[lastIndex].headOffice = "";
    //   this.projections.forEach((p, index) => {
    //     if (index != lastIndex && p.bannerCompany == "") {
    //       p.weeks.forEach((week,weekIndex) => {
    //         this.projections[lastIndex].weeks[weekIndex] += week;
    //       });
    //     }
    //   })
    // }

//    this.projections = this.sort2Svc.sorter(this.projections, 'headOffice', 'bannerCompany');
    this.processEachShipTo();
  }
}
