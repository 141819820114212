import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from "@angular/router";
import { Observable } from "rxjs";
import { WeekKalcor } from "src/app/models/week-kalcor.model";
import { WeekKalcorService } from "src/app/services/week-kalcor.service";

@Injectable()
export class WeekKalcorResolver implements Resolve<WeekKalcor> {
    constructor(private WeekKalcorService : WeekKalcorService) { 
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<WeekKalcor> {
        const idField = 'id';
        const id = parseInt(route.params[idField], 10);

        if (id === 0) {
            return;
        }
        return this.WeekKalcorService.getYear(id);
    }
}