export class VendorReportData {
    id: number;
    category: string;
    period: string;
    month: string;
    year: number;
    status: string;
    type: string;
    no: string;
    customerCreditNo: string;
    date: Date;
    customer: string;
    vendor: string;
    vendorId: number;
    notes: string;
    creditAmount: number;  
    balance: number;
    bucketTransfer: string;
    credit: number;
    debit: number;
}