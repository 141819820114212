<h5>{{ title }}</h5>
<table class="table">
    <thead class="mt-3">
        <th scope="col">Week</th>
        <th scope="col">Week Start</th>
        <th scope="col">Week End</th>
        <th scope="col">Period</th>
    </thead>
    <tbody>
        <tr *ngFor="let w of weeks; index as i" [ngSwitch]="w.week">
            <td class="border border-top-0 border-bottom-0 border-start-0 border-end-0">{{ w.week }}</td>
            <td *ngSwitchCase="'1'" class="border border-top-0 border-bottom-0 border-start-0 border-end-0">
                <input type="date" [(ngModel)]="w.weekStartDate" [ngModelOptions]="{standalone: true}">
            </td>
            <td *ngSwitchCase=" '1' " class="border border-top-0 border-bottom-0 border-start-0 border-end-0">
                <input type="date" [(ngModel)]="w.weekEndDate" [ngModelOptions]="{standalone: true}"
                    (change)="create()">
            </td>
            <td *ngSwitchCase="'52'" class="border border-top-0 border-bottom-0 border-start-0 border-end-0">
                <input type="date" [(ngModel)]="w.weekStartDate" [ngModelOptions]="{standalone: true}">
            </td>
            <td *ngSwitchCase="'52'" class="border border-top-0 border-bottom-0 border-start-0 border-end-0">
                <input type="date" [(ngModel)]="w.weekEndDate" [ngModelOptions]="{standalone: true}">
            </td>
            <td *ngSwitchCase="'53'" class="border border-top-0 border-bottom-0 border-start-0 border-end-0">
                <input type="date" [(ngModel)]="w.weekStartDate" [ngModelOptions]="{standalone: true}">
            </td>
            <td *ngSwitchCase="'53'" class="border border-top-0 border-bottom-0 border-start-0 border-end-0">
                <input type="date" [(ngModel)]="w.weekEndDate" [ngModelOptions]="{standalone: true}">
            </td>
            <td *ngSwitchDefault class="border border-top-0 border-bottom-0 border-start-0 border-end-0">
                {{ w.weekStartDate | date:'shortDate' }}
            </td>
            <td *ngSwitchDefault class="border border-top-0 border-bottom-0 border-start-0 border-end-0">
                {{ w.weekEndDate | date:'shortDate' }}
            </td>
            <td class="border border-top-0 border-bottom-0 border-start-0 border-end-0">
                <input type="text" [(ngModel)]="w.period" [ngModelOptions]="{standalone: true}">
            </td>
        </tr>
    </tbody>
</table>
<div class ="row">
    <div class="pe-0 ms-2"> *To eliminate week 53, leave 0 as the Period value. To use week 53, enter a number value for Period.</div> 
</div>
<div class="row">
    <div class="col-2 pe-0 ms-2">
        <button type="button" class="btn btn-primary mt-3 w-100" (click)="validate()">Save</button>
    </div>
    <div class="col-2">
        <button type="button" (click)="onDelete()" class="btn btn-primary mt-3 w-100">Delete</button>
    </div>
    <ngb-alert *ngIf="successMessage" [type]="alertType" (close)="successMessage = null">{{ successMessage }}
    </ngb-alert>
</div>

    

