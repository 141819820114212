export class Broker {
activeItem: string;
address: string;
businessPhone: string;
city: string;
comments: string;
company: string;
countryRegion: string;
eMail: string;
faxNumber: string;
fullAddress: string;
fullName: string;
id: number;
jobTitle: string;
mobileNumber: string;
stateProvince: string;
webPage: string;
zipPostalCode: string;
}

export class BrokerPayment {
    id: number;
    paymentDate: Date;
    brokerPayNumber: string;
    month: string;
    year: number;
    vendorId: number;
    brokerId: number;
    reference: string;
    notes: string;
    paymentAmount: number;
    bucket: string;
    status: string;
}