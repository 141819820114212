import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { Observable, observable } from "rxjs";
const url = environment.kalcorApiUrl;

@Injectable({
    providedIn: 'root'
})
export class WeekKalcorService {

    constructor(private http: HttpClient) {}
    getAll(): Observable<any> {
        return this.http.get(url + 'WeekKalcors', this.jwt());
    }
    getOne(id: number): Observable<any>  {
        return this.http.get(url + 'WeekKalcors/' + id, this.jwt());
    }
    getYears(): Observable<any> {
        return this.http.get(url + 'WeekKalcors/Years', this.jwt());
    }
    getYear(year: number): Observable<any> {
        return this.http.get(url + 'WeekKalcors/' + year + '/Years', this.jwt());
    }        
    update(wkKalcor): Observable<any> {
        return this.http.put(url + 'WeekKalcors/' + wkKalcor.weekId, wkKalcor, this.jwt());
    }
    create(wkKalcor): Observable<any> {
        return this.http.post(url + 'WeekKalcors', wkKalcor, this.jwt());
    }
    remove(id: number) {
        return this.http.delete(url + 'WeekKalcors/' + id, this.jwt());
    }
    private jwt() {
         // create authorization header with jwt token
    const currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
    if (currentUser && currentUser.token) {
        const headersInfo = new HttpHeaders({ Authorization: 'Bearer ' + currentUser.token });
        return { headers: headersInfo };
    }
    }

}