<h5>Listing Case Review </h5>
<div class="smallSpacer"><h6>Filter</h6></div>
<div class="col-lg-44">
  <label class="formControlName ms-3">
    Type of Costing:
    <select class="form-control" [(ngModel)]="activeListingsSelected" (change)="resetFilters()">
      <option [value]=""></option>
      <option *ngFor="let o of activeListingsOptions" [value]="o">{{ o }}</option>
    </select>
  </label>
    <label class="formControlName ms-3">
    Cost Type:
    <select class="form-control" [(ngModel)]="costTypeSelected" (change)="resetFilters()">
      <option [value]=""></option>
      <option *ngFor="let o of costTypeOptions" [value]="o">{{ o }}</option>
    </select>
  </label>
    <label class="formControlName ms-3">
    Customer:
    <select class="form-control" [(ngModel)]="customerSelected" (change)="resetFilters()">
      <option [value]=""></option>
      <option *ngFor="let o of customerOptions" [value]="o.customerId">{{ o.customer }}</option>
    </select>
  </label>

  <label class="formControlName ms-3">
    Ship To:
    <select class="form-control" [(ngModel)]="shipToSelected" (change)="resetFilters()">
      <option [value]=""></option>
      <option *ngFor="let o of shipToOptions" [value]="o.id">{{ o.company }}</option>
    </select>
  </label>
    <label class="formControlName ms-3">
    Broker:
    <select class="form-control" [(ngModel)]="brokerSelected" (change)="resetFilters()">
      <option [value]=""></option>
      <option *ngFor="let o of brokerOptions" [value]="o.id">{{ o.company }}</option>
    </select>
  </label>
  <label class="formControlName ms-3">
    Vendor
    <select class="form-control" [(ngModel)]="vendorSelected" (change)="resetFilters()">
      <option [value]="0"></option>
      <option *ngFor="let o of vendorOptions" [value]="o.vendorId">{{ o.vendor }}</option>
    </select> 
  </label>
    <label class="formControlName ms-3">
    Brand:
    <select class="form-control" [(ngModel)]="brandSelected" (change)="resetFilters()">
      <option [value]=""></option>
      <option *ngFor="let o of brandOptions" [value]="o.brandId">{{ o.brand }}</option>
    </select>
  </label>
    <label class="formControlName ms-3">
    Category:
    <select class="form-control" [(ngModel)]="categorySelected" (change)="resetFilters()">
      <option [value]=""></option>
      <option *ngFor="let o of categoryOptions" [value]="o.categoryId">{{ o.category }}</option>
    </select>
  </label>
  <div class="smallSpacer"></div>
  <button type="button" class="btn btn-primary mt-0 ms-3" (click)="getDefaultDataScreen()">Reset</button>
</div>
<div class="spacer"></div>
<table class="table table-striped">
  <thead>
  <tr>
    <th scope="col">Customer</th>
    <th scope="col">Ship To</th>
    <th scope="col">Broker</th>
    <th scope="col">Product Order Code</th> 
    <th scope="col">Order Type</th> 
    <th scope="col">Item Description</th>
    <th scope="col">Size</th>
    <th scope="col">CS Net Lbs</th>
    <th scope="col">CS Gross Lbs</th>
    <th scope="col">CS Net Kg</th>
    <th scope="col">CS Gross Kg</th>
    <th scope="col">Cs L Inch</th>
    <th scope="col">Cs W Inch</th>
    <th scope="col">Cs H Inch</th>
    <th scope="col">Cs Cube Imp</th>
    <th scope="col">Cs L Cm</th>
    <th scope="col">Cs W Cm</th>
    <th scope="col">Cs H Cm</th>
    <th scope="col">Cs Cube M</th>
    <th scope="col">Unit Net</th>
    <th scope="col">Unit Gross</th>
    <th scope="col">Unit L Inch</th>
    <th scope="col">Unit W Inch</th>
    <th scope="col">Unit H Inch</th>
    <th scope="col">Unit Cube Imp</th>
    <th scope="col">Unit L Cm</th>
    <th scope="col">Unit W Cm</th>
    <th scope="col">Unit H Cm</th>
    <th scope="col">Unit Cube M</th>
    
    </tr>
  </thead>
  <tbody>
  <tr *ngFor="let p of products; index as i">
    <!-- <td>{{ p.activeListings }}</td>
    <td>{{ p.costType }}</td> -->
    <td>{{ p.customer }}</td>
    <td>{{ getShipTo(p.shipToIds) }}</td>
    <td>{{ p.broker }}</td>
    <td>
      <a class="pointer" (click)="onRowClicked(p)">
      <u class="text-primary">{{ p.orderCode }}</u>
      </a>
    </td>
    <td>{{ p.orderType }}</td>
    <td>{{ p.itemDescription }}</td>
    <td>{{ p.size }}</td>
    <td>{{ formatValue(p.csNetLbs) }}</td>
    <td>{{ formatValue(p.csGrossLbs) }}</td>
    <td>{{ formatValue(p.csNetKg) }}</td>
    <td>{{ formatValue(p.csGrossKg) }}</td>
    <td>{{ formatValue(p.csLInch) }}</td>
    <td>{{ formatValue(p.csWInch) }}</td>
    <td>{{ formatValue(p.csHInch) }}</td>
    <td>{{ formatValue(p.csCubeImperial) }}</td>
    <td>{{ formatValue(p.csLCm) }}</td>
    <td>{{ formatValue(p.csWCm) }}</td>
    <td>{{ formatValue(p.csHCm) }}</td>
    <td>{{ formatValue(p.csCubeMetric) }}</td>
    <td>{{ formatValue(p.unitNet) }}</td>
    <td>{{ formatValue(p.unitGross) }}</td>
    <td>{{ formatValue(p.unitLInch) }}</td>
    <td>{{ formatValue(p.unitWInch) }}</td>
    <td>{{ formatValue(p.unitHInch) }}</td>
    <td>{{ formatValue(p.unitCubeImperial) }}</td>
    <td>{{ formatValue(p.unitLCm) }}</td>
    <td>{{ formatValue(p.unitWCm) }}</td>
    <td>{{ formatValue(p.unitHCm) }}</td>
    <td>{{ formatValue(p.unitCubeMetric) }}</td>
  </tr>
  </tbody>
</table>

<div *ngIf="products!==undefined">
  <ngb-pagination
  [(page)]="page"
  [pageSize]="pageSize"
  [collectionSize]="collectionSize"
  [maxSize]="5"
  (pageChange)="loadPage($event)">
 </ngb-pagination>
</div>

<div class="btn-group" role="group" aria-label="Basic radio toggle button group">
      
  <input class="btn-check" type="radio" name="radio1" id="choice1" [(ngModel)]="pageSize"  (change)="loadPage($event)" autocomplete="off" checked [value]="25">
  <label class="btn btn-outline-primary" for="choice1">
    25
  </label>

  <input class="btn-check" type="radio" name="radio1" id="choice2" [(ngModel)]="pageSize"  (change)="loadPage($event)" [value]="50" autocomplete="off">
  <label class="btn btn-outline-primary" for="choice2">
    50
  </label>

  <input class="btn-check" type="radio" name="radio1" id="choice3" [(ngModel)]="pageSize"  (change)="loadPage($event)" [value]="100" autocomplete="off">
  <label class="btn btn-outline-primary" for="choice3">
    100
  </label>

</div>

<!-- <div class="btn-group btn-group-toggle" data-toggle="buttons" ngbRadioGroup name="radioBasic" [(ngModel)]="pageSize"  (change)="loadPage($event)">
  <label class="pt-2 pr-2" >Page Size</label>
  <label class="btn-outline-primary" ngbButtonLabel>
    <input type="radio" ngbButton [value]="25"> 25
  </label>
  <label class="btn-outline-primary" ngbButtonLabel>
    <input type="radio" ngbButton [value]="50"> 50
  </label>
  <label class="btn-outline-primary" ngbButtonLabel>
    <input type="radio" ngbButton [value]="100"> 100
  </label>
</div> -->