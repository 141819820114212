import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { BrokerPaymentService } from '../../../services/broker-payment.service';
import { BrokerPayment } from '../../../models/broker.model';
import { UntypedFormBuilder, UntypedFormGroup, Validators, FormsModule } from '@angular/forms';
import { Subject, fromEventPattern, Subscription, throwError } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { BrokerService } from '../../../services/broker.service';
import { VendorService } from '../../../services/vendor.service';
@Component({
  selector: 'app-broker-pay-edit',
  templateUrl: './broker-pay-edit.component.html',
  styleUrls: ['./broker-pay-edit.component.scss']
})
export class BrokerPayEditComponent implements OnInit {
  brokerPay: BrokerPayment;
  successMessage: string;
  private success = new Subject<string>();
  alertType = "success";
  staticAlertClosed = false;
  newOne = true;
  brokerPayForm: UntypedFormGroup;
  brokers: any;
  vendors: any;
  monthName = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];
  buckets = ['Commission', 'Royalty'];
  minYear: number;
  maxYear: number;
  currentYear: number;
  constructor(
    private router: Router,
    private brokerPayService: BrokerPaymentService,
    private route: ActivatedRoute,
    private fb: UntypedFormBuilder,
    private brokerService: BrokerService,
    private vendService: VendorService
  ) { }

  ngOnInit() {
    this.minYear = 2019;
    const today = new Date();
    this.currentYear = today.getFullYear();
    this.maxYear = this.currentYear + 5;
    this.brokerPayForm = this.fb.group({
      paymentDate: ['', Validators.required],
      brokerPayNumber: [{ value: '', disabled: true }],
      brokerId: [''],
      vendorId: ['', Validators.required],
      reference: [''],
      notes: [''],
      paymentAmount: ['', Validators.required],
      bucket: ['',  Validators.required],
      status: [{ value: '', disabled: true }],
      month: ['', Validators.required],
      year: ['', [Validators.min(this.minYear), Validators.max(this.maxYear)]]
    });

    this.brokerService.getCompany().subscribe(data => {
      this.brokers = data;
      this.vendService.getCompany().subscribe(data => {
        this.vendors = data;
        this.route.data.subscribe( brokeryPay => {
          if (brokeryPay.dataBrokerPay !== undefined) {
            this.brokerPay = brokeryPay.dataBrokerPay;
            this.newOne = false;
          } else {
            this.newOne = true;
            this.initTransfer();
          }
          this.fillFormData();
        })
      })
    })
    setTimeout(() => this.staticAlertClosed = true, 20000);
    this.success.subscribe((message) => this.successMessage = message);
    this.success.pipe(
      debounceTime(5000)
      ).subscribe(() => this.successMessage = null);    
    
  }
  fillFormData() {
    this.brokerPayForm.setValue({
      paymentDate: this.brokerPay.paymentDate == null ? this.brokerPay.paymentDate : this.brokerPay.paymentDate.toString().substr(0,10),
      brokerPayNumber: this.brokerPay.brokerPayNumber,
      brokerId: this.brokerPay.brokerId,
      vendorId: this.brokerPay.vendorId,
      reference: this.brokerPay.reference,
      notes:  this.brokerPay.notes,
      paymentAmount: this.brokerPay.paymentAmount,
      bucket: this.brokerPay.bucket.trim(),
      status: this.brokerPay.status,
      month: this.brokerPay.month,
      year: this.brokerPay.year
    });
  }
  initTransfer(): void {
    this.brokerPay = new BrokerPayment();
    this.brokerPay.paymentDate = null;
    this.brokerPay.brokerPayNumber = null;
    this.brokerPay.brokerId = null;
    this.brokerPay.vendorId = null;
    this.brokerPay.reference = null;
    this.brokerPay.notes = null;
    this.brokerPay.paymentAmount = null;
    this.brokerPay.bucket = null;
    this.brokerPay.status = null;
    this.brokerPay.month = null;
    this.brokerPay.year = this.currentYear;
  }  
  displayDate(dt, t) {
    if (dt) {
      if (t === undefined) {
        t = '';
      }
      let x = new Date(dt);
      if (t == 'T') {
        return x.toLocaleDateString() + ' @ ' + x.toLocaleTimeString();
      }
      return x.toLocaleDateString();
    } 
    return dt
  }
  onSubmit(status) {
    if (this.brokerPayForm.invalid) {
      this.success.next(`Please enter required fields.`);
      this.alertType = "danger";
      return;
    }
    this.alertType = "success";
    this.brokerPayForm.controls.status.setValue(status);
    this.updateOrder();
    if (this.newOne) {
      this.brokerPay.id = 0;
      this.brokerPayService.create(this.brokerPay).subscribe(data => {
        this.success.next(`Successfully created.`);
        this.router.navigate(['/accounts/broker-pay-edit',  data.id]);
      })
    } else {
      this.brokerPayService.update(this.brokerPay).subscribe(data => {
        this.success.next(`Successfully updated.`);
      })
    }
  }
  updateOrder() {
    for (const item in this.brokerPayForm.controls) {
      if (this.brokerPayForm.controls.hasOwnProperty(item)) {
          this.brokerPay[item] = this.brokerPayForm.controls[item].value;
      }
    }
  }
  onDelete() {
    if (this.brokerPay.id) {
      if (confirm('Delete this item?'))
      this.brokerPayService.remove(this.brokerPay.id).subscribe(data => {
        this.router.navigate(['/accounts/broker-pay-list']);
      });
    }
  }  
}
