import { Component, OnInit } from '@angular/core';
import { ShipToAddressService } from '../../../../services/ship-to-address.service';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
@Component({
  selector: 'app-ship-to-address-list',
  templateUrl: './ship-to-address-list.component.html',
  styleUrls: ['./ship-to-address-list.component.scss']
})
export class ShipToAddressListComponent implements OnInit {

  constructor(private router: Router,
              private shipSvc: ShipToAddressService) { }
  shipToAddress: any;
  activeItemOptions = ['Active', 'Not Active'];
  activeItemSelected: string;
  customerSelected: number;
  customerOptions:[];
  companySelected: number;
  companyOptions:[];
  page: number;
  pageSize: number;
  count: number;
  shipToOriginal: any;
  successMessage: string;
  private success = new Subject<string>();
  alertType = "success";
  staticAlertClosed = false;
  ngOnInit() {
    this.reset();
    setTimeout(() => this.staticAlertClosed = true, 20000);
    this.success.subscribe((message) => this.successMessage = message);
    this.success.pipe(
      debounceTime(5000)
    ).subscribe(() => this.successMessage = null);    
  }
  
  loadPage() {
    this.shipSvc.getFilteredListing(this.activeItemSelected, this.companySelected, this.customerSelected, this.page, this.pageSize).subscribe(data => {
      this.shipToOriginal = data.item;
      this.shipToAddress = [];
      this.shipToOriginal.forEach(item => {
        const index = this.shipToAddress.findIndex(x => x.id == item.id);
        if (index == -1) {
          this.shipToAddress.push(item);
        } else {
          if (item.shipRegion && this.shipToAddress[index].shipRegion) {
            if (this.shipToAddress[index].shipRegion !== null && this.shipToAddress[index].shipRegion.indexOf(item.shipRegion) == -1) {
              this.shipToAddress[index].shipRegion += " " + item.shipRegion;
              this.shipToAddress[index].shipRegion.trim();
            }
          }
          // if (item.shipToAccountType) {
          //   if (this.shipToAddress[index].shipToAccountType.indexOf(item.shipToAccountType) == -1) {
          //     this.shipToAddress[index].shipToAccountType += " " + item.shipToAccountType;
          //     this.shipToAddress[index].shipToAccountType.trim();
          //   }
          // }
        }
      });
      this.customerOptions = data.item2;
      this.companyOptions = data.item3;
      this.count = data.count;
    },error => {
      this.successMessage = 'Unable to process request';
      this.alertType = "danger";
    });
  }
  reset() {
    this.page = 1;
    this.pageSize = 30;
    this.activeItemSelected = 'Active';
    this.companySelected = 0;
    this.customerSelected = 0;
    this.loadPage();    
  }
  onRowClicked(shipto) {
    this.router.navigate(['/customers/ship-to-address-edit', shipto.id]);
  }
}
