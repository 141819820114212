import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { UserFull, User } from '../../../models/user.model';
import { UserService } from '../../../services/user.service';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { VendorService } from '../../../services/vendor.service';
import { RolesService } from '../../../services/roles.service';

@Component({
  selector: 'app-user-edit',
  templateUrl: './user-edit.component.html',
  styleUrls: ['./user-edit.component.scss']
})
export class UserEditComponent implements OnInit {
  title = 'User Edit';
  newOne = true;
  user: UserFull;
  userForm: UntypedFormGroup;
  successMessage: string;
  successMessage2: string;
  private success = new Subject<string>();
  private success2 = new Subject<string>();
  staticAlertClosed = false;
  display='none';
  modalTitle = 'Enter Password';
  vendors: any;
  roles: any;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private fb: UntypedFormBuilder,
    private userService: UserService,
    private vendService: VendorService,
    private roleService: RolesService
  ) { }

  ngOnInit() {
    this.userForm = this.fb.group({
      userinitials: [''],
      userpassword: [''],
      firstName: [''],
      lastName: [''],
      department: [''],
      address: [''],
      city: [''],
      stateProvince: [''],
      postalCode: [''],
      countryRegion: [''],
      email: [''],
      mobile: [''],
      phone: [''],
      photoLocation: [''],
      vendorId: [''],
      roleIds: ['']
    })
    this.roleService.getAll().subscribe(roleData => {
      this.roles = roleData;
      this.roles.sort((a, b) => a.roleName < b.roleName ? -1 : a.roleName > b.roleName ? 1 : 0);

      this.vendService.getCompany().subscribe(data => {
        this.vendors = data;
        this.route.data.subscribe(data => {
          if (data.dataUser !== undefined) {
            this.title = 'User Edit';
            this.newOne = false;
            this.user = data.dataUser;
            this.user.passwordHash = null;
            this.user.passwordSalt = null;
            this.fillFormData();
          } else {
            this.title = 'User New';
            this.newOne = true;
            this.user = new UserFull();
            this.userForm.reset();
          }
        });
      })
    })
    setTimeout(() => this.staticAlertClosed = true, 20000);
    this.success.subscribe((message) => this.successMessage = message);
    this.success.pipe(
      debounceTime(5000)
    ).subscribe(() => this.successMessage = null);     

    this.success2.subscribe((message) => this.successMessage2 = message);
    this.success2.pipe(
      debounceTime(5000)
    ).subscribe(() => this.successMessage2 = null);     

  }
  fillFormData() {
    this.userForm.setValue({
      userinitials: this.user.userinitials,
      userpassword: this.user.userpassword,
      firstName: this.user.firstName,
      lastName: this.user.lastName,
      department: this.user.department,
      address: this.user.address,
      city: this.user.city,
      stateProvince: this.user.stateProvince,
      postalCode: this.user.postalCode,
      countryRegion: this.user.countryRegion,
      email: this.user.email,
      mobile: this.user.mobile,
      phone: this.user.phone,
      photoLocation: this.user.photoLocation,
      vendorId: this.user.vendorId,
      roleIds: this.splitNumbers(this.user.roleIds)
    });
  }
  splitNumbers(ids) {
    let ret = [];
    if (ids) {
      let retCount = 0;
      const tempSplit = ids.split(',');
      if (tempSplit.length > 0) {
        tempSplit.forEach( id => {
          if (id) {
            ret.push(id * 1);
          }
        })
      }
    }

    return ret;
  }

  onSubmit() {
    
    this.user.userinitials = this.userForm.controls.userinitials.value;
    this.user.firstName = this.userForm.controls.firstName.value;
    this.user.lastName = this.userForm.controls.lastName.value;
    this.user.department = this.userForm.controls.department.value;
    this.user.address = this.userForm.controls.address.value;
    this.user.city = this.userForm.controls.city.value;
    this.user.stateProvince = this.userForm.controls.stateProvince.value;
    this.user.postalCode = this.userForm.controls.postalCode.value;
    this.user.countryRegion = this.userForm.controls.countryRegion.value;
    this.user.email = this.userForm.controls.email.value;
    this.user.mobile = this.userForm.controls.mobile.value;
    this.user.phone = this.userForm.controls.phone.value;
    this.user.roleIds = this.userForm.controls.roleIds.value.join();
    this.user.vendorId = this.userForm.controls.vendorId.value;

    if (this.newOne) {
        this.userService.createFull(this.user).subscribe(data => {
         this.user.userid =  data.userid;
          this.newOne = false;
          this.title = 'User Edit';
          this.success.next(`Successfully added.`);
        }, error => {
        });
      } else {
        this.userService.update(this.user).subscribe(data => {
          this.success.next(`Successfully changed.`);
        }, error => {
        });
      }
    }
  onDelete() {
    if (this.user.userid) {
      this.userService.delete(this.user.userid).subscribe(data => {
        this.router.navigate(['/admin/user-list']);
      });
    }
  }      
  openModal(){
    if (!this.user.userid) {
      alert('Save this user first before setting password.');
      return;
    }
    this.display='block';
  }
  onCloseHandled(){
    this.display='none';
  }
  onUpdatePassword() {

    const user = new User();
    user.userid = this.user.userid;
    user.userinitials = this.userForm.controls.userinitials.value;
    user.userpassword = this.userForm.controls.userpassword.value;
    this.userService.updatePassword(user).subscribe(data => {
      this.success2.next(`Successfully changed.`);
    })
  }
}
