import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
const url = environment.kalcorApiUrl;

@Injectable({
  providedIn: 'root'
})
export class BrokerPaymentService {

  constructor(private http: HttpClient) { }

  getAll(): Observable<any> {
    return this.http.get(url + 'BrokerPayments', this.jwt());
  }
  getOne(id: number): Observable<any> {
    return this.http.get(url + 'BrokerPayments/' + id, this.jwt());
  }
  getFilter(status: string, brokerPayNumber: string, brokerId: number, vendorId: number, page: number, pageSize: number): Observable<any> {
    return this.http.get(url + 'BrokerPayments/Filter?status=' + status.trim() + 
          '&brokerPayNumber=' + brokerPayNumber + 
          '&brokerId=' + brokerId + 
          '&vendorId=' + vendorId + 
          '&page=' + page + '&pageSize=' + pageSize, this.jwt());
  }
      
  update(brokerPay) {
    return this.http.put(url + 'BrokerPayments/' + brokerPay.id, brokerPay, this.jwt());
  }
  create(brokerPay): Observable<any> {
    return this.http.post(url + 'BrokerPayments', brokerPay, this.jwt());
  }
  remove(id) {
    return this.http.delete(url + 'BrokerPayments/' + id, this.jwt());
  }
  private jwt() {
    // create authorization header with jwt token
    const currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
    if (currentUser && currentUser.token) {
        const headersInfo = new HttpHeaders({ Authorization: 'Bearer ' + currentUser.token });
        return { headers: headersInfo };
    }
  }
  
}
