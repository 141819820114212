<h4>Roles/Permissions List </h4>

<table class="table table-striped" style="overflow-x:scroll;">
    <thead>
    <tr>
      <th scope="col">Role</th>
      <th scope="col">Dashboard</th>
      <th scope="col">Sales</th>
      <th scope="col">Custom Clearing</th>
      <th scope="col">Accounting</th>
      <th scope="col">Customers</th>
      <th scope="col">Vendors</th>
      <th scope="col">Other</th>
      <th scope="col">items</th>
      <th scope="col">Products</th>
      <th scope="col">Product listing</th>
      <th scope="col">Projections</th>
      <th scope="col">Reports</th>
      </tr>
    </thead>
    <tbody>
    <tr *ngFor="let r of roles; index as i" (click)="onRowClicked(r)">
      <td>{{ r.roleName }}</td>
      <td class="text-center">{{ r.dashboard ? 'Y' : 'N' }}</td>
      <td class="text-center">{{ r.sales ? 'Y' : 'N' }}</td>
      <td class="text-center">{{ r.customClearing ? 'Y' : 'N' }}</td>
      <td class="text-center">{{ r.accounting ? 'Y' : 'N' }}</td>
      <td class="text-center">{{ r.customer ? 'Y' : 'N' }}</td>
      <td class="text-center">{{ r.vendor ? 'Y' : 'N' }}</td>
      <td class="text-center">{{ r.other ? 'Y' : 'N' }}</td>
      <td class="text-center">{{ r.items ? 'Y' : 'N' }}</td>
      <td class="text-center">{{ r.products ? 'Y' : 'N' }}</td>
      <td class="text-center">{{ r.productListing ? 'Y' : 'N' }}</td>
      <td class="text-center">{{ r.projections ? 'Y' : 'N' }}</td>
      <td class="text-center">{{ r.reports ? 'Y' : 'N' }}</td>
    </tr>
    </tbody>
  </table>
