import { Component, OnInit } from '@angular/core';
import { WeekKalcorService } from 'src/app/services/week-kalcor.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';


@Component({
  selector: 'app-week-kalcor-list',
  templateUrl: './week-kalcor-list.component.html',
  styleUrls: ['./week-kalcor-list.component.scss']
})
export class WeekKalcorListComponent implements OnInit {
  year = [];

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private weekKalcorSvc: WeekKalcorService
  ) { }

  ngOnInit(): void {
    this.displayItems();
  }

  displayItems() {
    this.year=[];
    this.weekKalcorSvc.getYears().subscribe(years => {
      years.forEach(year => {
        this.year.push(year);
      });
    });
  }
  clickedItem(i: number) {
    this.router.navigate(['./other/week-kalcor-edit', this.year[i]]);
  }


}
