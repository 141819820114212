import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-difference',
  templateUrl: './difference.component.html',
  styleUrls: ['./difference.component.scss']
})
export class DifferenceComponent implements OnInit {
  @Input() title: string;
  @Input() bucketSelected: string;
  @Input() balFwdBeginningMonth: any;
  @Input() balFwdAfterIncomingMonth: any;
  @Input() balFwdOutgoingMonth: any;
  @Input() balEndCurrentMonth: any;
  @Input() incoming: any;
  @Input() outgoing: any;
  @Input() transfering: any;
  @Input() buckets: any;
  @Input() numberMonthsRequested: any;
  @Input() monthName: any;
  @Input() customerName: any;
  @Input() vendorName: any;
  @Input() yearSelected: any;
  @Input() firstSelectMonthIndex: number;
  @Input() reportType: string;
  @Input() orderType: string;
constructor() { }

  ngOnInit() {
  }
  displayDate(dt, t) {
    if (dt === undefined) {
      return null;
    }
    if (dt) {
      if (t === undefined) {
        t = '';
      }
      if (dt.indexOf('T') == -1) {
        dt += 'T00:00';
      }
      let x = new Date(dt);
      if (t == 'T') {
        return x.toLocaleDateString() + ' @ ' + x.toLocaleTimeString();
      }
      return x.toLocaleDateString();
    } 
    return dt
  }

}
