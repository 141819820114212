import { Component, OnInit } from '@angular/core';
import { FundsTransferService } from '../../../services/funds-transfer.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-transfer-list',
  templateUrl: './transfer-list.component.html',
  styleUrls: ['./transfer-list.component.scss']
})
export class TransferListComponent implements OnInit {
  transfer: any;
  page = 1;
  pageSize = 30;
  collectionSize = 0;
  transferStatusSelected: string;
  transferNumberSelected: string;
  billToSelected: number;
  vendorSelected: number;
  transferStatusOptions = ['Open', 'Closed'];
  transferNumberOptions = [];
  billToOptions = [];
  vendorOptions = [];
  constructor(
    private router: Router,
    private transferService: FundsTransferService,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.transferStatusSelected = 'Open';
    this.transferNumberSelected = '';
    this.billToSelected = 0;
    this.vendorSelected = 0;
    this.route.data.subscribe( transfer => {
      if (transfer.dataTransfer !== undefined) {
        this.transfer = transfer.dataTransfer.item;
        this.collectionSize = transfer.dataTransfer.count;
        this.transferNumberOptions = transfer.dataTransfer.item2;
        this.billToOptions = transfer.dataTransfer.item3;
        this.vendorOptions = transfer.dataTransfer.item4;
      }                
    })
  }
  displayDate(dt, t) {
    
    if (dt) {
      if (t === undefined) {
        t = '';
      }
      let x = new Date(dt);
      if (t == 'T') {
        return x.toLocaleDateString() + ' @ ' + x.toLocaleTimeString();
      }
      return x.toLocaleDateString();
    } 
    return dt

  }
  getDefaultDataScreen() {
    this.transferStatusSelected = 'Open';
    this.transferNumberSelected = '';
    this.billToSelected = 0;
    this.vendorSelected = 0;
    this.page = 1;
    this.resetFilters();
  } 
  resetFilters() {
    this.transferService.getFilter(this.transferStatusSelected, this.transferNumberSelected, 
      this.billToSelected, this.vendorSelected, this.page, this.pageSize).subscribe(transfer => {
        this.transfer = transfer.item;
        this.collectionSize = transfer.count;
        this.transferNumberOptions = transfer.item2;
        this.billToOptions = transfer.item3;
        this.vendorOptions = transfer.item4;
      });
  }

  goToEdit(index) {
    this.router.navigate(['/accounts/transfer-edit',  this.transfer[index].id]);
  }
  
}
