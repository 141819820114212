<h5>Type of Account</h5>
<table class="table table-striped" style="overflow-x:scroll;">
    <thead>
    <tr>
      <th scope="col">Type of Account</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let t of typeOfAccount | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize" (click)="onRowClicked(t)">
      <td>{{ t.typeOfAccount1 }}</td>
    </tr>
    </tbody>
  </table>
  <div *ngIf="typeOfAccount!==undefined">
    <ngb-pagination [(page)]="page" [pageSize]="pageSize" [collectionSize]="typeOfAccount.length">
    </ngb-pagination>
  </div>
  <ngb-alert *ngIf="successMessage" [type]="alertType" (close)="successMessage = null">{{ successMessage }}</ngb-alert>  
  
