import { Component, OnInit } from '@angular/core';
import { ProductService } from '../../../../services/product.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-product-list',
  templateUrl: './product-list.component.html',
  styleUrls: ['./product-list.component.scss']
})
export class ProductListComponent implements OnInit {
  products: any;
  productsOriginal: any;
  page = 1;
  pageSize = 25;
  brandFilter = [];
  categoryFilter = [];
  costingFilter = [];
  costTypeFilter = [];
  brand = 0;
  category = 0;
  brandName = '';
  categoryName = '';
  costing = '';
  costType = '';
  collectionSize = 0;
  groupSelected: number;
  groups = [];
  constructor(private router: Router,
              private productService: ProductService) { }

  ngOnInit() {
    this.getDefaultDataScreen();
  }
  getDefaultDataScreen() {
    this.brand = 0;
    this.category = 0;
    this.costing = '';
    this.costType = '';
    this.groupSelected = 0;
    this.resetFilters();
  }
  loadPage($event) {
    this.resetFilters();
  }
  resetFilters() {
    this.products = [];
    this.brandFilter = [];
    this.costingFilter = [];
    this.categoryFilter = [];
    this.costTypeFilter = [];
    this.groups = [];
    this.productService.getActiveListingOrder(
      this.brand, this.category, this.costing, this.costType, this.groupSelected, this.page, this.pageSize).subscribe(prod => {
      this.products = prod.item;
      this.productsOriginal = prod.item;
      this.brandFilter = prod.item4.filter(b => b.brand !== null);
      this.categoryFilter = prod.item5;
      this.collectionSize = prod.count;
      this.costingFilter = prod.item2;
      this.costTypeFilter = prod.item3;
      this.groups = prod.item6;
    });
  }
  onRowClicked(product) {
    this.router.navigate(['/products/setup-edit',  product.id]);
  }
  getCaseReview() {
    this.router.navigate(['/products/cases-setup-list'], {
      state:
      {
        brandId: this.brand,
        categoryId: this.category,
        costing: this.costing,
        costType: this.costType
      }
    });
  }
}
