import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Warehouse } from '../../../models/warehouse.model';
import { WarehouseService } from '../../../services/warehouse.service';
import { TermsService } from '../../../services/terms.service';
import { Term } from '../../../models/term-model';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
@Component({
  selector: 'app-warehouse-detail',
  templateUrl: './warehouse-detail.component.html',
  styleUrls: ['./warehouse-detail.component.scss']
})
export class WarehouseDetailComponent implements OnInit {
  title = 'Warehouse New';
  newOne = true;
  warehouse: Warehouse;
  warehouseForm: UntypedFormGroup;
  successMessage: string;
  private success = new Subject<string>();
  staticAlertClosed = false;
  term: Term[] = [];
  constructor(private route: ActivatedRoute,
              private router: Router,
              private fb: UntypedFormBuilder,
              private termsSvc: TermsService,
              private warehouseService: WarehouseService) { }

  ngOnInit() {
    this.warehouseForm = this.fb.group({
      activeItem:  [''],
      company:  [''],
      address:  [''],
      city:  [''],
      stateProvince:  [''],
      countryRegion:  [''],
      zipPostalCode:  [''],
      termsId:  [''],
      currencyCode:  [''],
      fullName:  [''],
      jobTitle:  [''],
      businessPhone:  [''],
      faxNumber:  [''],
      mobileNumber:  [''],
      eMail:  [''],
      webPage:  [''],
      comments:  [''],
      whsNo:  [''],
      federalEstablishment:  [''],
    });
    this.termsSvc.getAll().subscribe(data2 => {
      this.term = data2;
      this.route.data.subscribe(data3 => {
        if (data3.dataWarehouse !== undefined) {
          this.title = 'Warehouse Edit';
          this.newOne = false;
          this.warehouse = data3.dataWarehouse;
          this.fillFormData();
        } else {
          this.title = 'Warehouse New';
          this.newOne = true;
          this.warehouse = new Warehouse();
          this.warehouseForm.reset();
        }
      });
    });
    setTimeout(() => this.staticAlertClosed = true, 20000);
    this.success.subscribe((message) => this.successMessage = message);
    this.success.pipe(
      debounceTime(5000)
    ).subscribe(() => this.successMessage = null);
  }
  fillFormData() {
    this.warehouseForm.setValue({
      activeItem:  this.warehouse.activeItem,
      company:  this.warehouse.company,
      address:  this.warehouse.address,
      city:  this.warehouse.city,
      stateProvince:  this.warehouse.stateProvince,
      countryRegion:  this.warehouse.countryRegion,
      zipPostalCode:  this.warehouse.zipPostalCode,
      termsId:  this.warehouse.termsId,
      currencyCode:  this.warehouse.currencyCode,
      fullName:  this.warehouse.fullName,
      jobTitle:  this.warehouse.jobTitle,
      businessPhone:  this.warehouse.businessPhone,
      faxNumber:  this.warehouse.faxNumber,
      mobileNumber:  this.warehouse.mobileNumber,
      eMail:  this.warehouse.eMail,
      webPage:  this.warehouse.webPage,
      comments:  this.warehouse.comments,
      whsNo:  this.warehouse.whsNo,
      federalEstablishment:  this.warehouse.federalEstablishment
    });
  }
  onSubmit() {
    this.warehouse.activeItem = this.warehouseForm.controls.activeItem.value;
    this.warehouse.company =  this.warehouseForm.controls.company.value;
    this.warehouse.address =  this.warehouseForm.controls.address.value;
    this.warehouse.city =  this.warehouseForm.controls.city.value;
    this.warehouse.stateProvince =  this.warehouseForm.controls.stateProvince.value;
    this.warehouse.countryRegion =  this.warehouseForm.controls.countryRegion.value;
    this.warehouse.zipPostalCode =  this.warehouseForm.controls.zipPostalCode.value;
    this.warehouse.termsId =  this.warehouseForm.controls.termsId.value;
    this.warehouse.currencyCode =  this.warehouseForm.controls.currencyCode.value;
    this.warehouse.fullName =  this.warehouseForm.controls.fullName.value;
    this.warehouse.jobTitle =  this.warehouseForm.controls.jobTitle.value;
    this.warehouse.businessPhone =  this.warehouseForm.controls.businessPhone.value;
    this.warehouse.faxNumber =  this.warehouseForm.controls.faxNumber.value;
    this.warehouse.mobileNumber =  this.warehouseForm.controls.mobileNumber.value;
    this.warehouse.eMail =  this.warehouseForm.controls.eMail.value;
    this.warehouse.webPage =  this.warehouseForm.controls.webPage.value;
    this.warehouse.comments =  this.warehouseForm.controls.comments.value;
    this.warehouse.whsNo =  this.warehouseForm.controls.whsNo.value;
    this.warehouse.federalEstablishment = this.warehouseForm.controls.federalEstablishment.value;
    if (this.newOne) {
      this.warehouseService.create(this.warehouse).subscribe(data => {
        this.warehouse.id = data.id;
        this.router.navigate(['/other/warehouse-edit', this.warehouse.id]);
        }, error => {
      });
    } else {
      this.warehouseService.update(this.warehouse).subscribe(data => {
        this.success.next(`Successfully changed.`);
      }, error => {
      });
    }
  }
  onDelete() {
    if (this.warehouse.id) {
      this.warehouseService.remove(this.warehouse.id).subscribe(data => {
        this.router.navigate(['/other/warehouse-list']);
      });
    }
  }
}
