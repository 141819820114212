import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { HttpClient, HttpHeaderResponse, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { VendorReportData } from '../models-azure/vendor-report-data.model';

const url = environment.kalcorApiUrl;

@Injectable({
  providedIn: 'root'
})
export class VendorReportService {

  constructor(private http: HttpClient) { }

  getAll(): Observable<any> {
    return this.http.get(url + 'VendorReportDatas', this.jwt());
  }
  getOne(id: number): Observable<any> {
    return this.http.get(url + 'VendorReportDatas/' + id, this.jwt());
  }
  getFilter(year: number, vendorId: number, page: number, pageSize: number): Observable<any> {
    page = page ? page : 1;
    pageSize = pageSize ? pageSize : 200;
    return this.http.get(url + 'VendorReportDatas/Filter?year=' + year + '&vendorId=' + vendorId + '&page='
    + page + '&pageSize=' + pageSize, this.jwt());
  }
  update(vendorReportData) {
    return this.http.put(url + 'VendorReportDatas/' + vendorReportData.Id, vendorReportData, this.jwt());
  }
  create(vendorReportData) {
    return this.http.post(url + 'VendorReportDatas', vendorReportData, this.jwt());
  }
  remove(id) {
    return this.http.delete(url + 'VendorReportDatas/' + id, this.jwt());
  }


  private jwt() {
    // create authorization header with jwt token
    const currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
    if (currentUser && currentUser.token) {
        const headersInfo = new HttpHeaders({ Authorization: 'Bearer ' + currentUser.token });
        return { headers: headersInfo };
    }
  }

}
