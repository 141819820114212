<div id="my-bucket-sales-overview" *ngIf="reportName=='Sales Overview'">
    <app-sales-overview-pdf
      [title] = "title"
      [bucketSelected] = "bucketSelected"
      [balFwdBeginningMonth] = "balFwdBeginningMonth"
      [balFwdAfterIncomingMonth] = "balFwdAfterIncomingMonth"
      [balEndCurrentMonth] = 'balEndCurrentMonth'
      [incoming] = "incoming"
      [outgoing] = "outgoing"
      [transfering] = "transfering"
      [buckets] = "buckets"
      [yearSelected] = "yearSelected"
      [numberMonthsRequested] = "numberMonthsRequested"
      [monthName] = "monthName"
      [customerName] = "customerName"
      [firstSelectMonthIndex] = "firstSelectMonthIndex"
      [vendorName] = "vendorName"
      [balOngoing] = "balOngoing"
      [customerVendorTitle] = "customerVendorTitle"
      [orderType] = "orderType">
    </app-sales-overview-pdf>
</div> 
<div *ngIf="reportName==''">
    <div *ngFor="let b of buckets; index as i">
      <div *ngIf="b">
        <div class="row g-0 mt-2">
            <div class="col-lg-20 pt-2 px-0 fw-bold">
                Customer: {{ customerName }}
            </div>    
            <div class="col-lg-4 pt-2 px-0 fw-bold">
                Month {{ monthName[i]}}
            </div>
        </div>
  
        <div class="row g-0">
            <div class="col-lg-10 px-0 fw-bold">
                Vendor: {{ vendorName }}
            </div>    
            <div class="col-lg-10 pt-2 px-0 fw-bold">
              {{ bucketSelected }}
            </div>
            <div class="col-lg-4 px-0 fw-bold">
                Year {{ yearSelected }}
            </div>
        </div>
        <div class="row g-0">
          <div class="col-lg-20 px-0 fw-bold">
              Order Type: {{ orderType }}
          </div>    
        </div>

  
        <div class="row g-0 mt-2">
          <table class="w-100">
            <thead class="mt-3 pe-2 border-top border-bottom">
                <th>Month</th>
                <th>{{ customerVendorTitle }}</th>
                <th class="text-end">Sales Amount</th>
                <th class="text-end">Balance</th>
                <th class="text-end">Balance Ongoing</th>
            </thead>
            <tbody>
                <tr *ngFor="let o of incoming[i]">
                    <td>{{ monthName[i]}} {{ yearSelected }}</td>
                    <td>{{ o.vendorCustomer }}</td>
                    <td class="text-end" [ngClass]="{'text-danger': o.extCaseCost < 0}">
                      {{ o.extCaseCost | currency }}
                    </td>
                    <td class="text-end" [ngClass]="{'text-danger': o.balance < 0}">
                      {{ o.balance | currency }}
                    </td>
                    <td class="text-end" [ngClass]="{'text-danger': o.balanceOngoing < 0}">
                      {{ o.balanceOngoing | currency }}
                    </td>
                </tr>
                <tr>
                  <td class="text-white background">TOTAL</td>
                  <td class="text-white background"></td>
                  <td class="text-white background"></td>
                  <td class="text-end text-white background" [ngClass]="{'text-danger': balEndCurrentMonth[i] < 0}">
                    {{ balEndCurrentMonth[i] | currency }}
                  </td>
                  <td class="text-end text-white background" [ngClass]="{'text-danger': balOngoing[i] < 0}">
                    {{ balOngoing[i] | currency }}
                  </td>
              </tr>
          </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="col-4 pe-0">
      <button type="button" class="btn btn-primary mt-3 w-100"
      (click)="printProcess()">Print</button>
    </div>
  </div>
  