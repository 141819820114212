<h5>Billing Agents List</h5>
<table class="table table-striped" style="overflow-x:scroll;">
    <thead>
        <tr>
            <th scope="col">Billing Agent</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let b of billingAgent; index as i">
            <td>
                <a class="pointer" (click)="onRowClicked(b)">
                    <u class="text-primary">{{ b.billingAgentName }}</u>
                </a>
            </td>
        </tr>
    </tbody>
</table>
<ngb-alert *ngIf="successMessage" [type]="alertType" (close)="successMessage = null">{{ successMessage }}</ngb-alert>
