import { Injectable,} from "@angular/core";
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from "@angular/router";
import { Observable } from "rxjs";
import { WeekCustomerEnd, } from "src/app/models/week-customer-end.model";
import { WeekCustomerEndService } from "src/app/services/week-customer-end.service";
import { CustomerEndService } from "src/app/services/customer-end.service";

@Injectable()
export class WeekCustomerHeadsResolver implements Resolve<WeekCustomerEnd> {
    constructor(private WeekCustomerEndService : WeekCustomerEndService,
                private CustomerEndService : CustomerEndService) {
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<WeekCustomerEnd> {
        
        if (route.url[0].path === "week-customer-end-new") {
            return this.CustomerEndService.getOne(0);
        }
        
        const idField = 'id';
        const id = parseInt(route.params[idField], 10);

        if (id === 0) {
            return;
        }
        return this.WeekCustomerEndService.getYears(id);
    }
}



