<h5>{{ title }} </h5>
<form [formGroup]="SalesManagerForm" (ngSubmit)="onSubmit()" novalidate>
  <div class="form-group">

    <div class="col-lg-24">
      <label>
        Full Name:
        <input type="text" formControlName="fullName" class="form-control" size="60">
      </label>
    </div>

    <div class="col-lg-24">
      <label>
        Job Title:
        <input type="text" formControlName="jobTitle" class="form-control" size="60">
      </label>
    </div>
   
    <div class="col-lg-24">
      <label>
        e-Mail:
        <input type="text" formControlName="eMail" class="form-control" size="60">
      </label>
    </div>

    <button type="submit" class="btn btn-primary mt-3 w-25">Save</button>
    <button type="button" class="btn btn-secondary ms-3 mt-3 w-25" (click)="onDelete()">Delete</button>
    </div>
     <ngb-alert *ngIf="successMessage" type="success" (close)="successMessage = null">{{ successMessage }}</ngb-alert>
</form>

