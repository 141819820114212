import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
const url = environment.kalcorApiUrl;
const PARAMS = new HttpParams({
  fromObject: {
    action: 'opensearch',
    format: 'json',
    origin: '*'
  }
});

@Injectable({
  providedIn: 'root'
})
export class CreditService {

  constructor(private http: HttpClient) { }
  getAll(): Observable<any> {
    return this.http.get(url + 'CreditInformations', this.jwt());
  }
  getOne(id: number): Observable<any> {
    return this.http.get(url + 'CreditInformations/' + id, this.jwt());
  }

  getOneBatch(id: number): Observable<any> {
    return this.http.get(url + 'CreditInformations/' + id + '/Batch', this.jwt());
  }

  search(term: string) {
    if (term === '') {
      return of([]);
    }

    return this.http.get<[any, string[]]>(url + 'CreditInformations/' + term + '/Search', this.jwt()).pipe(
        map(response => response)
      );
  }

  getFilter(status: string, creditNo: string, purchaseOrderNo: string, customerCreditNo: string, billTo: number, vendorId: number, batchNo: number, batchType: string, page: number, pageSize: number): Observable<any> {
    return this.http.get(url + 'CreditInformations/Filter?status=' + status.trim() + 
          '&creditNo=' + creditNo + 
          '&purchaseOrderNo=' + purchaseOrderNo + 
          '&customerCreditNo=' + customerCreditNo + 
          '&billTo=' + billTo + 
          '&vendorId=' + vendorId + 
          '&batchNo=' + batchNo +
          '&batchType=' + batchType +
          '&page=' + page + '&pageSize=' + pageSize, this.jwt());
  }
  update(credit) {
    return this.http.put(url + 'CreditInformations/' + credit.id, credit, this.jwt());
  }
  create(credit): Observable<any> {
    return this.http.post(url + 'CreditInformations', credit, this.jwt());
  }
  remove(id) {
    return this.http.delete(url + 'CreditInformations/' + id, this.jwt());
  }
  private jwt() {
    // create authorization header with jwt token
    const currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
    if (currentUser && currentUser.token) {
        const headersInfo = new HttpHeaders({ Authorization: 'Bearer ' + currentUser.token });
        return { headers: headersInfo };
    }
  }
}
