export class FreightCndData {
    id: number;
    category: string;
    period: string;
    month: string;
    year: number;
    status: string;
    type: string;
    no: string;
    purchaseOrder: string;
    date: Date;
    customer: string;
    vendor: string;
    vendorId: number;
    shipTo: string;
    broker: string;
    salesAmount: number;
    credit: number;
    balance: number;
    creditAmount: number;
    notes: string;
    bucketTransfer: string;
    debit: number;
}