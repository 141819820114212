import { Component, OnInit } from '@angular/core';
import { SalesManagerService } from '../../../services/sales-manager.service';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
@Component({
  selector: 'app-sales-manager-list',
  templateUrl: './sales-manager-list.component.html',
  styleUrls: ['./sales-manager-list.component.scss']
})
export class SalesManagerListComponent implements OnInit {
  SalesManager: any;
  successMessage: string;
  private success = new Subject<string>();
  alertType = "success";
  staticAlertClosed = false;
  constructor(private router: Router,
              private SalesManagerSvc: SalesManagerService) { }

  ngOnInit() {
    this.SalesManagerSvc.getAll().subscribe(data => {
      this.SalesManager = data
    },error => {
      this.successMessage = 'Unable to process request';
      this.alertType = "danger";
    });
    setTimeout(() => this.staticAlertClosed = true, 20000);
    this.success.subscribe((message) => this.successMessage = message);
    this.success.pipe(
      debounceTime(5000)
    ).subscribe(() => this.successMessage = null);       
  }
  onRowClicked(rowSelected) {
    this.router.navigate(['/other/sales-manager-edit', rowSelected.salesMgrId]);
  }
}
