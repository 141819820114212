<h5>Customer Head Office - Weeks</h5>
<div class="row"></div>
<table class="table table-striped">
    <thead>
        <tr>
            <th scope="col">Customer</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let c of customerHead; index as i">
            <td><a class="pointer" (click)="onRowClicked(c)">
                <u class="text-primary">{{ c.company }}</u>
                </a>
                </td>
        </tr>
    </tbody>
</table>