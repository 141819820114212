export class CustomerRetail {
  id: number;
  activeItem: string;
  customer: string;
  company: string;
  address: string;
  city: string;
  stateProvince: string;
  countryRegion: string;
  zipPostalCode: string;
  comments: string;
  noStores: number;
  typeOfAccount: string;
  webPage: string;
  customerId: number;
  locationId: number;
  mapLinkOffice: string;
  mapLinkWarehouse: string;
  customerNavigation: string;
  accountTypeId: number;
  regionId: number;
  customerHeadId: number;
  shipToIds: string;
}
