import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { BrokerPaymentService } from '../../../services/broker-payment.service';

@Component({
  selector: 'app-broker-pay-list',
  templateUrl: './broker-pay-list.component.html',
  styleUrls: ['./broker-pay-list.component.scss']
})
export class BrokerPayListComponent implements OnInit {
  brokerPay: any;
  page = 1;
  pageSize = 30;
  collectionSize = 0;
  brokerPayStatusSelected: string;
  brokerPayNumberSelected: string;
  brokerSelected: number;
  vendorSelected: number;
  brokerPayStatusOptions = ['Open', 'Closed'];
  brokerPayNumberOptions = [];
  brokerOptions = [];
  vendorOptions = [];

  constructor(
    private router: Router,
    private brokerPayService: BrokerPaymentService,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.brokerPayStatusSelected = 'Open';
    this.brokerPayNumberSelected = '';
    this.brokerSelected = 0;
    this.vendorSelected = 0;
    this.route.data.subscribe( brokerPay => {
      if (brokerPay.dataBrokerPay !== undefined) {
        this.brokerPay = brokerPay.dataBrokerPay.item;
        this.collectionSize = brokerPay.dataBrokerPay.count;
        this.brokerPayNumberOptions = brokerPay.dataBrokerPay.item2;
        this.brokerOptions = brokerPay.dataBrokerPay.item3;
        this.vendorOptions = brokerPay.dataBrokerPay.item4;
      }                
    })
  }

  displayDate(dt, t) {
    if (dt) {
      if (t === undefined) {
        t = '';
      }
      let x = new Date(dt);
      if (t == 'T') {
        return x.toLocaleDateString() + ' @ ' + x.toLocaleTimeString();
      }
      return x.toLocaleDateString();
    } 
    return dt
  }
  getDefaultDataScreen() {
    this.brokerPayStatusSelected = 'Open';
    this.brokerPayNumberSelected = '';
    this.brokerSelected = 0;
    this.vendorSelected = 0;
    this.page = 1;
    this.resetFilters();
  } 

  resetFilters() {
    this.brokerPayService.getFilter(this.brokerPayStatusSelected, this.brokerPayNumberSelected, 
      this.brokerSelected, this.vendorSelected, this.page, this.pageSize).subscribe(brokerPay => {
        this.brokerPay = brokerPay.item;
        this.collectionSize = brokerPay.count;
        this.brokerPayNumberOptions = brokerPay.item2;
        this.brokerOptions = brokerPay.item3;
        this.vendorOptions = brokerPay.item4;
    });
  } 
  goToEdit(index) {
    this.router.navigate(['/accounts/broker-pay-edit',  this.brokerPay[index].id]);
  }   
}
