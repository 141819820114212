import { Component, OnInit } from '@angular/core';
import { ProductService } from '../../../../services/product.service';
import { Router } from '@angular/router';
import { Product, ActiveListings } from 'src/app/models/product.model';
import { ExcelService } from 'src/app/services/excel.service';

@Component({
  selector: 'app-case-setup-list',
  templateUrl: './case-setup-list.component.html',
  styleUrls: ['./case-setup-list.component.scss']
})
export class CaseSetupListComponent implements OnInit {
  products: any;
  page = 1;
  pageSize = 100;
  activeList = [];
  productsOriginal: any;
  brandFilter = [];
  categoryFilter = [];
  costingFilter = [];
  costTypeFilter = [];
  groupFilter = [];
  brand = '';
  category = '';
  costing = '';
  costType = '';
  group = '';
  constructor(private router: Router,
              private excelService: ExcelService,
              private productService: ProductService) { }

  ngOnInit() {
    this.activeList = ActiveListings;
    this.productService.getItemOrder().subscribe(prod => {
      this.products = prod;
      this.productsOriginal = prod;
      this.resetFilters();
    });
  }
  getDefaultDataScreen() {
    this.brand = '';
    this.category = '';
    this.costing = '';
    this.costType = '';
    this.group = '';
    this.resetFilters();
  }
  resetFilters() {
    let pointsNeeded = 0;
    
    pointsNeeded += this.brand ? 1 : 0;
    pointsNeeded += this.category ? 1 : 0;
    pointsNeeded += this.costing ? 1 : 0;
    pointsNeeded += this.costType ? 1 : 0;
    pointsNeeded += this.group? 1 : 0;

    this.products = [];
    let firstTime = true;
    this.productsOriginal.forEach( prod => {
      let pointsCounted = 0;
      if (this.brand === prod.brand) {
        pointsCounted++;
      }
      if (this.category === prod.category) {
        pointsCounted++;
      }

      if (this.costing === prod.activeListings) {
        pointsCounted++;
      }

      if (this.costType === prod.costType) {
        pointsCounted++;
      }

      if (this.group === prod.productGroupNo) {
        pointsCounted++;
      }

      if (pointsCounted >= pointsNeeded) {
        this.products.push(prod);
        if (this.brand == '') {
          this.brandFilter = firstTime ? [] : this.brandFilter;
          if (prod.brand && this.brandFilter.indexOf(prod.brand) == -1) {
            this.brandFilter.push(prod.brand);
          }
        }
  
        if (this.category == '') {
          this.categoryFilter = firstTime ? [] : this.categoryFilter;
          if (prod.category && this.categoryFilter.indexOf(prod.category) == -1) {
            this.categoryFilter.push(prod.category);
          }
        }
  
        if (this.costing == '') {
          this.costingFilter = firstTime ? [] : this.costingFilter;
          if (prod.activeListings && this.costingFilter.indexOf(prod.activeListings) == -1) {
            this.costingFilter.push(prod.activeListings);
          }
        }

        if (this.costType == '') {
          this.costTypeFilter = firstTime ? [] : this.costTypeFilter;
          if (prod.costType && this.costTypeFilter.indexOf(prod.costType) == -1) {
            this.costTypeFilter.push(prod.costType);
          }
        }

        if (this.group == '') {
          this.groupFilter = firstTime ? [] : this.groupFilter;
          if (prod.productGroupNo && this.groupFilter.indexOf(prod.productGroupNo) == -1) {
            this.groupFilter.push(prod.productGroupNo);
          }
        }
        firstTime = false;      
      }
    })
  }
  formatValue(x) {
    if (x == null || 0) {
      let x = 0;
      return x.toFixed(2);
    } else {
      return x.toFixed(2);
    }
  }  
  onRowClicked(product) {
    this.router.navigate(['/products/cases-setup-edit',  product.id]);
  }
  //12.4.24 for below service to work brand and category need to be reconfigured to get id values
  // initializeExcel() {
  //   this.products = [];
  //   this.productService.getActiveListingOrder(this.brand, this.category, this.costing, this.costType, 0, 1, 999).subscribe(prod => {

  //   })
  // }
  // exportToExcel() {
  //   const excelData = [];
  //   let indexProd = 0;
  //     this.products.forEach(e => {
  //         const temp = {
  //           vendor: e.vendor,
  //           brand: e.brand,
  //           customer: e.customer
  //         }
  //         excelData.push(temp);
  //         indexProd++;
  //     })
  //   this.excelService.generateExcel(excelData, 'Product Case Review');    
  // }
}
