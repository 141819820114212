<h4>KST Freight CND Data Link To Azure</h4>

<!-- <ngb-alert *ngIf="successMessage" [type]="alertType" (close)="successMessage = null">{{ successMessage }}</ngb-alert> -->
<table class="table table-striped">
  <thead>
  <tr>
    <th scope="col">Vendor</th>
    <th scope="col">Year</th>
    <th scope="col">Run Update</th>
    <th scope="col">Modified By</th>
    <th scope="col">Last Update</th>
    <th scope="col">Status</th>
    <th scope="col">Counter</th>
    <th scope="col">Remove Vendor</th>
    </tr>
  </thead>
  <tbody>
  <tr *ngFor="let v of vendorYear; index as i">
    <td>{{ v.vendorName }}</td>
    <td>{{ v.salesYear }}</td>
    <td>
        <button type="button" [disabled]="buttonClicker[i]" class="btn btn-link" (click)="run(i)">Run Update</button>            
    </td>
    <td>{{ v.name }}</td>
    <td>{{ v.lastUpdate | date:'short' }}</td>
    <td>{{ v.status }}</td>
    <td>{{ v.counter }}</td>
    <td>
      <button type="button" [disabled]="buttonRemover[i]" class="btn btn-secondary" (click)="removeVendor(i)">Remove Vendor</button>            
  </td>
  </tr>
  </tbody>
</table>
<div class="row">
  <div class="col-md-4 mt-2" >
      <button type="button" class="btn btn-primary mt-3 mb-3 w-75" (click)="addVendors()">{{ buttonLabel }}</button>
  </div>
</div>
<div *ngIf="vendorYear!==undefined">
  <ngb-pagination [(page)]="page" [pageSize]="pageSize" [collectionSize]="count" (pageChange)="loadPage()">
  </ngb-pagination>
</div>
