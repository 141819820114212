<h5>Lot-BB Report </h5>
<div class="smallSpacer"><h6>Select Period</h6></div>
<div class="col-lg-44">
<label class="formControlName ms-3">
        Year
        <!-- <input class="form-control" [(ngModel)]="year" (ngModelChange)="yearChange()" size="5"> -->
        <select class="form-control" [(ngModel)]="year" (ngModelChange)="yearChange()">
          <option [value]=""></option>
          <option *ngFor="let y of years" [value]="y">{{ y }}</option>
        </select>
</label>
<label class="formControlName ms-3">
    Period
    <select class="form-control" [(ngModel)]="periodSelected" (ngModelChange)="periodChange($event)">
        <option [value]=""></option>
        <option *ngFor="let p of periodOptions">{{ p.period }}</option>
      </select>
</label>
<label class="formControlName ms-3">Start Date
   <input class="form-control" disabled placeholder="{{ periodStartDate }}">
</label>
<label class="formControlName ms-3">End Date
  <input class="form-control" disabled placeholder="{{ periodEndDate }}">
</label>
</div>
<div class="smallSpacer"><h6>Filter</h6></div>
<div class="col-lg-44">
<label class="formControlName  ms-3">
        Order Status
        <select class="form-control" [(ngModel)]="orderStatusSelected" (change)="resetFilters()">
          <option [value]=""></option>
          <option *ngFor="let o of orderStatusOptions" [value]="o">{{ o }}</option>
        </select>
</label>
<label class="formControlName  ms-3">
  Order Type
  <select class="form-control" [(ngModel)]="orderTypeSelected" (change)="resetFilters()">
    <option [value]=""></option>
    <option *ngFor="let o of orderTypeOptions" [value]="o">{{ o }}</option>
  </select>
</label>
<label class="formControlName ms-3">
  Vendor
  <select class="form-control" [(ngModel)]="vendorSelected" (change)="resetFilters()">
    <option [value]="0"></option>
    <option *ngFor="let o of venOptions" [value]="o.vendorId">{{ o.vendor }}</option>
  </select>
</label>
<label class="formControlName ms-3">
  Customer
  <select class="form-control" [(ngModel)]="custSelected" (change)="resetFilters()">
    <option [value]="0"></option>
    <option *ngFor="let o of custOptions" [value]="o.billToId">{{ o.billTo }}</option>
  </select>
</label>
<label class="formControlName ms-3">
  Ship To
  <select class="form-control" [(ngModel)]="shipSelected" (change)="resetFilters()">
    <option [value]="0"></option>
    <option *ngFor="let o of shipOptions" [value]="o.shipToId">{{ o.shipTo }}</option>
  </select>
</label>
<label class="formControlName ms-3">
    Sales Order No
    <select class="form-control" [(ngModel)]="salesNoSelected" (change)="resetFilters()">
      <option [value]=""></option>
      <option *ngFor="let o of salesNoOptions" [value]="o.salesOrderNo">{{ o.salesOrderNo }}</option>
    </select>
</label>
<label class="formControlName ms-3">
    Purchase Order
    <select class="form-control" [(ngModel)]="poNoSelected" (change)="resetFilters()">
      <option [value]=""></option>
      <option *ngFor="let o of poNoOptions" [value]="o.purchaseOrder">{{ o.purchaseOrder }}</option>
    </select>
</label>


<div class="smallSpacer"></div>
<ngb-alert *ngIf="successMessage" [type]="alertType" (close)="successMessage = null">{{ successMessage }}
</ngb-alert>
    <button type="button" class="btn btn-primary" (click)="refreshOrders()">Export to Excel</button>
    <button type="button" class="btn btn-secondary ms-3" (click)="getDefaultDataScreen()">Reset</button>
</div>
<div class="spacer"></div>
<table class="table table-striped">
  <thead>
  <tr>
    <th scope="col">Order Status</th>
    <th scope="col">Order Type</th>
    <th scope="col">Vendor</th>
    <th scope="col">Customer</th>
    <th scope="col">Ship To</th>
    <th scope="col">Order Code</th>
    <th scope="col">Brand/Category</th>
    <th scope="col">Item Description</th>
    <th scope="col">Size</th>
    <th scope="col">Cases</th>
    <th scope="col">Sales Order No</th>
    <th scope="col">Purchase Order</th>
    <th scope="col">Sales Order Date</th>
    <th scope="col">Shipment Date</th>
    <th scope="col">Delivered Date/Appt</th>
    <th scope="col">Lot #</th>
    <th scope="col">BB Date</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let o of orders; index as i">
        <td>{{ o.orderStatus }}</td>
        <td>{{ o.orderType }}</td>
        <td>{{ o.vendor }}</td>
        <td>{{ o.billTo }}</td>
        <td>{{ o.shipTo }}</td>
        <td>{{ o.orderCode }}</td>
        <td>{{ o.brandCategory }}</td>
        <td>{{ o.itemDescription }}</td>
        <td>{{ o.size }}</td>
        <td>{{ o.cases }}</td>
        <td>{{ o.salesOrderNo }}</td>
        <td>{{ o.purchaseOrder }}</td>
        <td>{{ displayDate(o.salesOrderDate, '') }}</td>
        <td>{{ displayDate(o.shipmentDate, '') }}</td>
        <td>{{ displayDate(o.deliveredDateAppt, 'T') }}</td>
        <td>{{ o.lotNo1 }}</td>
        <td>{{ displayDate(o.bbDate1, '') }}</td>
  </tr>
  </tbody>
</table>
<div *ngIf="orders!==undefined">
  <ngb-pagination
  [(page)]="page"
  [pageSize]="pageSize"
  [collectionSize]="collectionSize"
  [maxSize]="5"
  (pageChange)="loadPage($event)">
 </ngb-pagination>
</div>

<div class="btn-group" role="group" aria-label="Basic radio toggle button group">
      
  <input class="btn-check" type="radio" name="radio1" id="choice1" [(ngModel)]="pageSize"  (change)="loadPage($event)" autocomplete="off" checked [value]="25">
  <label class="btn btn-outline-primary" for="choice1">
    25
  </label>

  <input class="btn-check" type="radio" name="radio1" id="choice2" [(ngModel)]="pageSize"  (change)="loadPage($event)" [value]="50" autocomplete="off">
  <label class="btn btn-outline-primary" for="choice2">
    50
  </label>

  <input class="btn-check" type="radio" name="radio1" id="choice3" [(ngModel)]="pageSize"  (change)="loadPage($event)" [value]="100" autocomplete="off">
  <label class="btn btn-outline-primary" for="choice3">
    100
  </label>

</div>
  