<h5>Customer New</h5>
<form [formGroup]="customerForm" (ngSubmit)="onSubmit()" novalidate>
  <div class="form-group">
    <div class="col">
      <label>
        Active Item:
        <select formControlName="activeItem" class="form-control">
          <option value="Active">Active</option>
          <option value="Not Active">Not Active</option>
        </select>
      </label>
      <label class="ms-5">
          Account Number:
          <input type="text" formControlName="accountNo" class="form-control" size="60">
        </label>
    </div>

    <div class="col-lg-24">
      <label>
        company:
        <input type="text" formControlName="company" class="form-control"  size="60">
      </label>
    </div>

    <div class="col-lg-24">
      <label>
        Address:
        <input type="text" formControlName="address" class="form-control" size="60">
      </label>
    </div>

    <div class="col-lg-24">
      <label>
        City:
        <input type="text" formControlName="city" class="form-control" size="60">
      </label>
    </div>

    <div class="col">
      <label>
        State/Province:
        <input type="text" formControlName="stateProvince" class="form-control">
      </label>

      <label class="ms-3">
        Country/Region:
        <input type="text" formControlName="countryRegion" class="form-control">
      </label>

      <label class="ms-3">
        Zip/Postal Code:
        <input type="text" formControlName="zipPostalCode" class="form-control">
      </label>
    </div>

    <div class="col-lg-24">
      <label>
        Full Name:
        <input type="text" formControlName="fullName" class="form-control" size="60">
      </label>
    </div>

    <div class="col-lg-24">
      <label>
        Job Title:
        <input type="text" formControlName="jobTitle" class="form-control" size="60">
      </label>
    </div>

    <div class="col">
      <label>
        Business:
        <input type="text" formControlName="businessPhone" class="form-control">
      </label>

      <label class="ms-3">
        Fax:
        <input type="text" formControlName="faxNumber" class="form-control">
      </label>

      <label class="ms-3">
        Mobile:
        <input type="text" formControlName="mobileNumber" class="form-control">
      </label>
    </div>

    <div class="col-lg-24">
      <label>
        E-Mail:
        <input type="text" formControlName="eMail" class="form-control" size="60">
      </label>
    </div>

    <div class="col-lg-24">
      <label>
        Web Page:
        <input type="text" formControlName="webPage" class="form-control" size="60">
      </label>
    </div>

    <div class="col-lg-24">
      <label>
        Comments:
        <input type="text" formControlName="comments" class="form-control" size="60">
      </label>
    </div>
    
    <div class="col-24">
      <label>
        Currency Code:
        <select formControlName="currencyCode" class="form-control">
          <option value="CAN">CAN</option>
          <option value="USD">USD</option>
        </select>
      </label>
    </div>
    
    <div class="col">
        <label>
         Terms:
          <select formControlName="termsId" class="form-control">
            <option *ngFor="let t of term" [value]="t.id">{{t.paymentTerms}}</option>
          </select>
        </label>
    </div>

    <div class="col">
      <label>
        Latitude:
        <input type="text" formControlName="latitude" class="form-control">
      </label>
    </div>

    <div class="col">
      <label>
        Longitude:
        <input type="text" formControlName="longitude" class="form-control">
      </label>
    </div>

    <!-- <div class="col-lg-10">
      <label>
        Customer Head Office:
      <select formControlName="customerEndId" class="form-control">
        <option *ngFor="let c of customerEnd" [value]="c.id">{{c.company}}</option>
      </select>
    </label>
    </div> -->


      <button type="submit" class="btn btn-primary mt-3 w-25">Create</button>
    </div>
</form>
