import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators, FormsModule } from '@angular/forms';
import { Subject, fromEventPattern, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { Transportation } from '../../../../models/transportation.model';
import { TransportationService } from '../../../../services/transportation.service';
import { OrderItems } from '../../../../models/orderItems.model';
import { OrderItemsService } from '../../../../services/order-items.service';
import { Order } from '../../../../models/order-model';
import { OrderService } from '../../../../services/order.service';
import { FreightType } from '../../../../models/freight-type-model';
import { FreightTypeService } from '../../../../services/freight-type.service';
import { Customer } from '../../../../models/customer.model';
import { CustomerService } from '../../../../services/customer.service';
import { ShipToAddress } from '../../../../models/shipto.model';
import { ShipToAddressService } from '../../../../services/ship-to-address.service';
import { Vendor } from '../../../../models/vendor-model';
import { VendorService } from '../../../../services/vendor.service';
import { PickupLocation } from '../../../../models/pickup-location.model';
import { PickupLocationService } from '../../../../services/pickup-location.service';
import { Warehouse } from '../../../../models/warehouse.model';
import { WarehouseService } from '../../../../services/warehouse.service';
import { Location} from '../../../../models/location-model';
import { LocationService } from '../../../../services/location.service';
import { Broker } from '../../../../models/broker.model';
import { BrokerService } from '../../../../services/broker.service';
import { Term } from '../../../../models/term-model';
import { TermsService } from '../../../../services/terms.service';
import { BudgetActuals } from '../../../../models/budget-actuals.model';
import { BudgetActualsService } from '../../../../services/budget-actuals.service';
import { Big } from 'big.js';
import * as jsPDF from 'jspdf';
import * as html2pdf from 'html2pdf.js';
import { WeekKalcorService } from 'src/app/services/week-kalcor.service';
import { WeekKalcor } from 'src/app/models/week-kalcor.model';

@Component({
  selector: 'app-profit-edit',
  templateUrl: './profit-edit.component.html',
  styleUrls: ['./profit-edit.component.scss']
})
export class ProfitEditComponent implements OnInit {
  successMessage: string;
  private success = new Subject<string>();
  staticAlertClosed = false;
  alertType = "success";
  order: Order;
  orderPDF: any;
  itemsUSD: OrderItems[];
  items: OrderItems[];
  salesOrderNo: string;
  reportName = '';
  freightType: FreightType;
  title: string;
  reportNames = [
    {
    Name: 'Sales Order Profit & Loss', 
    Id: 'my-profit-loss-edit',
    filename: 'Profit Loss',
    Orientation: 'landscape',
    TopMargin: .5,
    LRMargin: .2
    }
  ];
  pageBreak: any;
  loaded = false;
  customer: Customer;
  shipTo: ShipToAddress;
  vendor: Vendor;
  vendors: Vendor[];
  pickup: PickupLocation;
  warehouse: Warehouse;
  broker: Broker;
  term: Term;
  transport1: string;
  transport2: string;
  transport3: string;
  transport4: string;
  transport5: string;
  location1: Location;
  location2: Location;
  location3: Location;
  location4: Location;
  location5: Location;
  freightTypeCompany: string;
  vendorPaymentTerms: string;
  orderForm: UntypedFormGroup;
  transport: Transportation;
  grayRow = [];
  totalCases: number;
  totalShipped: number;
  totalInvoiced: number;
  totalExtCost: number;
  totalMarketing: number;
  totalBilling: number;
  totalTerms: number;
  totalCommission: number;
  totalKalcor: number;
  totalCndFrtCostCase: number;
  totalStorageCostCase: number;
  cndFrtCostCase = [];
  storageCostCase = [];
  totalPurchaseCaseCostExt: number;
  usdFrtCost = [];
  usdClearing = [];
  usdDuty = [];
  usdRoyalty = [];
  usdVendorCost = [];  
  totalFreightCostExt: number;
  totalClearing: number;
  totalDuty: number;
  totalRoyalty: number;
  totalVendor: number;
  budgetActuals: BudgetActuals[];
  budgetActualPost: BudgetActuals;
  budgetActualTotalCount: number;
  counter: number;
  indexMarketing: number;
  indexBilling: number;
  indexTerms: number;
  indexCommission: number;
  indexKalcor: number;
  indexFreightCnd: number;
  indexStorage: number;
  indexVendor: number;
  indexClearing: number;
  indexDuty: number;
  indexFreightUSD: number;
  indexRoyalty: number;
  indexFobDiff: number;
  commPct1: number;
  commPct2: number;
  exchangeProduct: number;
  year: number;
  years = [];
  periods = [];
  weeks = [];
  periodOptions: any;
  shipDate: Date;
  stockOrder = false;
  budgetActual = [
    {
      id: 0,
      sortNumber: 0,
      edit: 'N',
      funds: 'CND',
      item: 'Sales',
      budgetAmount: 0,
      difference: 0,
      actualAmount: 0,
      company: '',
      reference: '',
      confirm: '',
      freightType: '',
      freightCost: 0,
      freightCostSales: 0,
      freightTotalCost: 0,
      commPct1: 0,
      commPct2: 0,
      commission1: 0,
      commission2: 0,
      period: 0,
      year: 0,
      revenue: 0,
      freightCustom: ''
    },
    {
      id: 0,
      sortNumber: 100,
      edit: 'N',
      funds: 'CND',
      item: 'Marketing',
      budgetAmount: 0,
      difference: 0,
      actualAmount: 0,
      company: '',
      reference: '',
      confirm: '',
      freightType: '',
      freightCost: 0,
      freightCostSales: 0,
      freightTotalCost: 0,
      commPct1: 0,
      commPct2: 0,
      commission1: 0,
      commission2: 0,
      period: 0,
      year: 0,
      revenue: 0,
      freightCustom: ''
    },
    {
      id: 0,
      sortNumber: 200,
      edit: 'Y',
      funds: 'CND',
      item: 'Billing',
      budgetAmount: 0,
      difference: 0,
      actualAmount: 0,
      company: '',
      reference: '',
      confirm: '',
      freightType: '',
      freightCost: 0,
      freightCostSales: 0,
      freightTotalCost: 0,
      commPct1: 0,
      commPct2: 0,
      commission1: 0,
      commission2: 0,
      period: 0,
      year: 0,
      revenue: 0,
      freightCustom: ''
    },
    {
      id: 0,
      sortNumber: 300,
      edit: 'Y',
      funds: 'CND',
      item: 'Terms',
      budgetAmount: 0,
      difference: 0,
      actualAmount: 0,
      company: '',
      reference: '',
      confirm: '',
      freightType: '',
      freightCost: 0,
      freightCostSales: 0,
      freightTotalCost: 0,
      commPct1: 0,
      commPct2: 0,
      commission1: 0,
      commission2: 0,
      period: 0,
      year: 0,
      revenue: 0,
      freightCustom: ''
    },
    {
      id: 0,
      sortNumber: 400,
      edit: 'N',
      funds: 'CND',
      item: 'Commission',
      budgetAmount: 0,
      difference: 0,
      actualAmount: 0,
      company: 'Kalcor Marketing Ltd',
      reference: '',
      confirm: 'YES',
      freightType: '',
      freightCost: 0,
      freightCostSales: 0,
      freightTotalCost: 0,
      commPct1: 0,
      commPct2: 0,
      commission1: 0,
      commission2: 0,
      period: 0,
      year: 0,
      revenue: 0,
      freightCustom: ''
    },
    {
      id: 0,
      sortNumber: 500,
      edit: 'N',
      funds: 'CND',
      item: 'Kalcor',
      budgetAmount: 0,
      difference: 0,
      actualAmount: 0,
      company: 'Kalcor Marketing Ltd',
      reference: '',
      confirm: 'YES',
      freightType: '',
      freightCost: 0,
      freightCostSales: 0,
      freightTotalCost: 0,
      commPct1: 0,
      commPct2: 0,
      commission1: 0,
      commission2: 0,
      period: 0,
      year: 0,
      revenue: 0,
      freightCustom: ''
    },
    {
      id: 0,
      sortNumber: 600,
      edit: 'Y',
      funds: 'CND',
      item: 'Freight',
      budgetAmount: 0,
      difference: 0,
      actualAmount: 0,
      company: '',
      reference: '',
      confirm: '',
      freightType: '',
      freightCost: 0,
      freightCostSales: 0,
      freightTotalCost: 0,
      commPct1: 0,
      commPct2: 0,
      commission1: 0,
      commission2: 0,
      period: 0,
      year: 0,
      revenue: 0,
      freightCustom: 'N'
    },
    {
      id: 0,
      sortNumber: 700,
      edit: 'N',
      funds: '',
      item: '',
      budgetAmount: 0,
      difference: 0,
      actualAmount: 0,
      company: '',
      reference: '',
      confirm: '',
      freightType: 'CND Freight',
      freightCost: 0,
      freightCostSales: 0,
      freightTotalCost: 0,
      commPct1: 0,
      commPct2: 0,
      commission1: 0,
      commission2: 0,
      period: 0,
      year: 0,
      revenue: 0,
      freightCustom: ''
    },
    {
      id: 0,
      sortNumber: 800,
      edit: 'Y',
      funds: 'CND',
      item: 'Storage',
      budgetAmount: 0,
      difference: 0,
      actualAmount: 0,
      company: '',
      reference: '',
      confirm: '',
      freightType: '',
      freightCost: 0,
      freightCostSales: 0,
      freightTotalCost: 0,
      commPct1: 0,
      commPct2: 0,
      commission1: 0,
      commission2: 0,      
      period: 0,
      year: 0,
      revenue: 0,
      freightCustom: ''
    },
    {
      id: 0,
      sortNumber: 900,
      edit: 'N',
      funds: 'CND',
      item: 'Net CND',
      budgetAmount: 0,
      difference: 0,
      actualAmount: 0,
      company: '',
      reference: '',
      confirm: '',
      freightType: '',
      freightCost: 0,
      freightCostSales: 0,
      freightTotalCost: 0,
      commPct1: 0,
      commPct2: 0,
      commission1: 0,
      commission2: 0,
      period: 0,
      year: 0,
      revenue: 0,
      freightCustom: ''
    },
    {
      id: 0,
      sortNumber: 1000,
      edit: 'N',
      funds: 'CND',
      item: 'FOB Difference Exchange',
      budgetAmount: 0,
      difference: 0,
      actualAmount: 0,
      company: '',
      reference: '',
      confirm: '',
      freightType: '',
      freightCost: 0,
      freightCostSales: 0,
      freightTotalCost: 0,
      commPct1: 0,
      commPct2: 0,
      commission1: 0,
      commission2: 0,
      period: 0,
      year: 0,
      revenue: 0,
      freightCustom: ''
    },
    {
      id: 0,
      sortNumber: 1100,
      edit: 'N',
      funds: 'CND',
      item: 'FOB Net Return',
      budgetAmount: 0,
      difference: 0,
      actualAmount: 0,
      company: '',
      reference: '',
      confirm: '',
      freightType: '',
      freightCost: 0,
      freightCostSales: 0,
      freightTotalCost: 0,
      commPct1: 0,
      commPct2: 0,
      commission1: 0,
      commission2: 0,
      period: 0,
      year: 0,
      revenue: 0,
      freightCustom: ''
    },
    {
      id: 0,
      sortNumber: 1200,
      edit: 'N',
      funds: '',
      item: 'Exchange',
      budgetAmount: 0,
      difference: 0,
      actualAmount: 0,
      company: '',
      reference: '',
      confirm: '',
      freightType: '',
      freightCost: 0,
      freightCostSales: 0,
      freightTotalCost: 0,
      commPct1: 0,
      commPct2: 0,
      commission1: 0,
      commission2: 0,
      period: 0,
      year: 0,
      revenue: 0,
      freightCustom: ''
    },
    {
      id: 0,
      sortNumber: 1300,
      edit: 'N',
      funds: 'USD',
      item: 'Vendor Purchase Case Cost',
      budgetAmount: 0,
      difference: 0,
      actualAmount: 0,
      company: '',
      reference: '',
      confirm: '',
      freightType: '',
      freightCost: 0,
      freightCostSales: 0,
      freightTotalCost: 0,
      commPct1: 0,
      commPct2: 0,
      commission1: 0,
      commission2: 0,
      period: 0,
      year: 0,
      revenue: 0,
      freightCustom: ''
    },
    {
      id: 0,
      sortNumber: 1350,
      edit: 'N',
      funds: 'USD',
      item: 'Royalty',
      budgetAmount: 0,
      difference: 0,
      actualAmount: 0,
      company: '',
      reference: '',
      confirm: '',
      freightType: '',
      freightCost: 0,
      freightCostSales: 0,
      freightTotalCost: 0,
      commPct1: 0,
      commPct2: 0,
      commission1: 0,
      commission2: 0,
      period: 0,
      year: 0,
      revenue: 0,
      freightCustom: ''
    },
    {
      id: 0,
      sortNumber: 1400,
      edit: 'Y',
      funds: 'USD',
      item: 'Clearing',
      budgetAmount: 0,
      difference: 0,
      actualAmount: 0,
      company: '',
      reference: '',
      confirm: '',
      freightType: '',
      freightCost: 0,
      freightCostSales: 0,
      freightTotalCost: 0,
      commPct1: 0,
      commPct2: 0,
      commission1: 0,
      commission2: 0,
      period: 0,
      year: 0,
      revenue: 0,
      freightCustom: ''
    },
    {
      id: 0,
      sortNumber: 1500,
      edit: 'Y',
      funds: 'USD',
      // renamed to storage per rick 11/27/24
      // item: 'Duty',
      item: 'Vendor Storage',
      budgetAmount: 0,
      difference: 0,
      actualAmount: 0,
      company: '',
      reference: '',
      confirm: '',
      freightType: '',
      freightCost: 0,
      freightCostSales: 0,
      freightTotalCost: 0,
      commPct1: 0,
      commPct2: 0,
      commission1: 0,
      commission2: 0,
      period: 0,
      year: 0,
      revenue: 0,
      freightCustom: ''
    },
    {
      id: 0,
      sortNumber: 1600,
      edit: 'Y',
      funds: 'USD',
      item: 'Freight',
      budgetAmount: 0,
      difference: 0,
      actualAmount: 0,
      company: '',
      reference: '',
      confirm: '',
      freightType: '',
      freightCost: 0,
      freightCostSales: 0,
      freightTotalCost: 0,
      commPct1: 0,
      commPct2: 0,
      commission1: 0,
      commission2: 0,
      period: 0,
      year: 0,
      revenue: 0,
      freightCustom: ''
    },
    {
      id: 0,
      sortNumber: 1700,
      edit: 'N',
      funds: '',
      item: '',
      budgetAmount: 0,
      difference: 0,
      actualAmount: 0,
      company: '',
      reference: '',
      confirm: '',
      freightType: 'USD Freight',
      freightCost: 0,
      freightCostSales: 0,
      freightTotalCost: 0,
      commPct1: 0,
      commPct2: 0,
      commission1: 0,
      commission2: 0,
      period: 0,
      year: 0,
      revenue: 0,
      freightCustom: ''
    },
    {
      id: 0,
      sortNumber: 1800,
      edit: 'Y',
      funds: 'USD',
      item: 'Vendor',
      budgetAmount: 0,
      difference: 0,
      actualAmount: 0,
      company: '',
      reference: '',
      confirm: '',
      freightType: '',
      freightCost: 0,
      freightCostSales: 0,
      freightTotalCost: 0,
      commPct1: 0,
      commPct2: 0,
      commission1: 0,
      commission2: 0,
      period: 0,
      year: 0,
      revenue: 0,
      freightCustom: ''
    }

  ];
  constructor(
    private customerService: CustomerService,
    private shipToService: ShipToAddressService,
    private vendorService: VendorService,
    private pickupService: PickupLocationService,
    private warehouseService: WarehouseService,
    private orderService: OrderService,
    private route: ActivatedRoute,
    private router: Router,
    private checkRef: ChangeDetectorRef,
    private fb: UntypedFormBuilder,
    private brokerService: BrokerService,
    private termService: TermsService,
    private freightService: FreightTypeService,
    private locationService: LocationService,
    private transportService: TransportationService,
    private orderItemService: OrderItemsService,
    private budgetActualService: BudgetActualsService,
    private weekKalcorService: WeekKalcorService
  ) { }

  ngOnInit() {
    this.salesOrderNo = ';'
    this.orderForm = this.fb.group({
      exchangeActual: [''],
      exchangeBudget: [{ value: '', disabled: true }],
      exchangeConfirmed: [''],
      period: [''],
      year: [''],
      revenue: [''],
      freightCustom: ['']
    });
      this.weekKalcorService.getYears().subscribe(data => {
        data.forEach(x => {
          this.years.push(x);
        })
      });
    this.route.data.subscribe(order => {
      if (order.dataProfitLoss !== undefined) {
        this.order = order.dataProfitLoss;
        this.setPeriodDefault();
        this.items = order.dataProfitLoss.items;
        this.itemsUSD = [];
        this.items.forEach( item => {

          let itemCopied = new OrderItems();
          itemCopied.orderCode = item.orderCode;
          itemCopied.brandCategory = item.brandCategory;
          itemCopied.size = item.size;
          itemCopied.cases = item.cases;
          itemCopied.shipped = item.shipped;
          itemCopied.invoiced = item.invoiced;
          itemCopied.purchaseCaseCost = item.purchaseCaseCost;
          let cases = item.cases;
          if (this.order.orderStatus == 'Shipped') {
            cases = item.shipped;
          } 
          if (this.order.orderStatus == 'Invoiced' || this.order.orderStatus == 'Closed') {
            cases = item.shipped;
          }
          cases = cases ? cases : 0;
          const bigNum: any = Big(cases)
            .times(item.caseCost)
            .round(0,2);
            item.extCaseCost = bigNum * 1;

          itemCopied.extCaseCost = item.extCaseCost;
          itemCopied.clearingCs = item.clearingCs;
          itemCopied.freightCs = item.freightCs;
          itemCopied.duty = item.duty;
          itemCopied.royalty = item.royalty;

          this.itemsUSD.push(itemCopied);

          this.salesOrderNo = item.salesOrderNo;
          this.cndFrtCostCase.push(0);
          this.storageCostCase.push(0);
          if (!this.orderForm.controls.exchangeBudget.value) {
            if (item.exchangeBudget) {
              this.orderForm.controls.exchangeBudget.setValue(item.exchangeBudget);
              this.orderForm.controls.exchangeActual.setValue(item.exchangeBudget);
              this.order.exchangeBudget = item.exchangeBudget;
            }
          }
        })
        this.itemsUSD.forEach( item => {
          this.usdFrtCost.push(0);
          this.usdClearing.push(0);
          this.usdDuty.push(0);
          this.usdRoyalty.push(0);
          this.usdVendorCost.push(0);
        })
        this.loadServices();
        if (this.salesOrderNo) {
          this.budgetActualService.getBySalesNumber(this.salesOrderNo).subscribe(budget => {
            this.budgetActuals = budget;
            this.budgetActuals.forEach(item => {
              const orderIndex = this.budgetActual.findIndex(o => o.sortNumber == item.sortNumber);
              if (orderIndex > -1) {
                this.budgetActual[orderIndex].id = item.id;
                this.budgetActual[orderIndex].actualAmount = item.actualAmount;
                this.budgetActual[orderIndex].company = item.company.trim() ? item.company : this.budgetActual[orderIndex].company;
                this.budgetActual[orderIndex].reference = item.reference.trim();
                this.budgetActual[orderIndex].confirm = item.confirm.trim() ? item.confirm : this.budgetActual[orderIndex].confirm;
                if (item.item == 'Exchange') {
                  this.budgetActual[orderIndex].budgetAmount = item.budgetAmount;
                  this.orderForm.controls.exchangeBudget.setValue(item.budgetExchange);
                  if (item.actualExchange == null) {
                    this.orderForm.controls.exchangeActual.setValue(item.budgetExchange);
                  } else {
                    this.orderForm.controls.exchangeActual.setValue(item.actualExchange);
                  }
                  this.orderForm.controls.exchangeConfirmed.setValue(item.confirm);
                  if (item.year == null && item.period == null) {
                    //this.setPeriodDefault();
                  } else {
                    this.orderForm.controls.year.setValue(item.year);
                    this.orderForm.controls.period.setValue(item.period);
                  }
                }
                if (item.item == 'Freight' && item.funds == 'CND') {
                  this.orderForm.controls.revenue.setValue(item.revenue);
                  this.orderForm.controls.freightCustom.setValue(item.freightCustom);
                }
              }
            })
            this.calculate();
            this.onChanges();
            this.loaded = true;
          })
        } else {
          this.calculate();
          this.onChanges();
          this.loaded = true;
        }
      }
    });
    setTimeout(() => this.staticAlertClosed = true, 20000);
    this.success.subscribe((message) => this.successMessage = message);
    this.success.pipe(
      debounceTime(5000)
    ).subscribe(() => this.successMessage = null);    
  }

  loadServices() {
    
    this.vendorService.getCompany().subscribe(vendor => this.vendors = vendor);
    this.transportService.getCompany().subscribe(transport => this.transport = transport);
    if (!this.order) {
      return;
    }
    if (this.order.billToId) {
      const id = this.order.billToId;
      this.customerService.getOne(id).subscribe(cust => this.customer = cust);
    }
    if (this.order.brokerId) {
      const id = this.order.brokerId;
      this.brokerService.getOne(id).subscribe(broker => this.broker = broker);
    }
    if (this.order.shipToId) {
      const id = this.order.shipToId;
      this.shipToService.getOne(id).subscribe(ship => this.shipTo = ship);
    }
    if (this.order.termsId) {
      const id = this.order.termsId;
      this.termService.getOne(id).subscribe(terms => this.term = terms);
    }
    
    if (this.order.vendorPickupId) {
      const id = this.order.vendorPickupId;
      this.pickupService.getOne(id).subscribe(pick => this.pickup = pick);
    }

    if (this.order.shippingLane1) {
      const id = this.order.shippingLane1;
      this.locationService.getOne(id).subscribe(loc => this.location1 = loc);
    }
    if (this.order.shippingLane2) {
      const id = this.order.shippingLane2;
      this.locationService.getOne(id).subscribe(loc => this.location2 = loc);
    }
    if (this.order.shippingLane3) {
      const id = this.order.shippingLane3;
      this.locationService.getOne(id).subscribe(loc => this.location3 = loc);
    }
    if (this.order.shippingLane4) {
      const id = this.order.shippingLane4;
      this.locationService.getOne(id).subscribe(loc => this.location4 = loc);
    }
    if (this.order.shippingLane5) {
      const id = this.order.shippingLane5;
      this.locationService.getOne(id).subscribe(loc => this.location5 = loc);
    }

    if (this.order.whsid) {
      const id = this.order.whsid;
      this.warehouseService.getOne(id).subscribe(whse => this.warehouse = whse);
    }

    if (this.order.transportCo1) {
      const id = this.order.transportCo1;
      this.transportService.getOne(id).subscribe(transport => this.transport1 = transport.company);
    }

    if (this.order.transportCo2) {
      const id = this.order.transportCo2;
      this.transportService.getOne(id).subscribe(transport => this.transport2 = transport.company);
    }

    if (this.order.transportCo3) {
      const id = this.order.transportCo3;
      this.transportService.getOne(id).subscribe(transport => this.transport3 = transport.company);
    }

    if (this.order.transportCo4) {
      const id = this.order.transportCo4;
      this.transportService.getOne(id).subscribe(transport => this.transport4 = transport.company);
    }

    if (this.order.transportCo5) {
      const id = this.order.transportCo5;
      this.transportService.getOne(id).subscribe(transport => this.transport5 = transport.company);
    }

    if (this.order.freightTypeId) {
      this.freightService.getOne(this.order.freightTypeId).subscribe(freight => this.freightType = freight);
    }
    this.vendorPaymentTerms = '';
    if (this.order.vendorId) {
      const id = this.order.vendorId;
      this.vendorService.getOne(id).subscribe(vend => {
        this.vendor = vend;
        if (this.vendor.termsId) {
          this.termService.getOne(this.vendor.termsId).subscribe(vendT => this.vendorPaymentTerms = vendT.paymentTerms);
        }
      })
      this.vendorService.getOne(id).subscribe(vend => this.vendor = vend);
    }
  }
  getAddress2(item) {
    let city = '';
    let state = '';
    let zip = '';
    let ret = '';
    switch (item) {
      case 'customer':
        if (this.customer) {
          city = this.customer.city;
          state = this.customer.stateProvince;
          zip = this.customer.zipPostalCode;
        }
        break;
      case 'shipto':
          if (this.shipTo) {
            city = this.shipTo.city;
            state = this.shipTo.stateProvince;
            zip = this.shipTo.zipPostalCode;
          }
          break;
      case 'pickup':
          if (this.pickup) {
            city = this.pickup.city;
            state = this.pickup.stateProvince;
            zip = this.pickup.zipPostalCode;
          }
          break;
          
      case 'warehouse':
          if (this.warehouse) {
            city = this.warehouse.city;
            state = this.warehouse.stateProvince;
            zip = this.warehouse.zipPostalCode;
          }
          break;
      case 'vendor':
          if (this.vendor) {
            city = this.vendor.city;
            state = this.vendor.stateProvince;
            zip = this.vendor.zipPostalCode;
          }
          break;
    }
    
    if (city) {
      ret = city;
      if (state) {
        ret += ', ' + state;
      }
    }

    if (zip) {
      ret += ' ' + zip;
    }

    return ret.trim();

  }
  displayDate(dt, t) {
    
    if (dt) {
      if (t === undefined) {
        t = '';
      }
      let x = new Date(dt);
      if (t == 'T') {
        return x.toLocaleDateString() + ' @ ' + x.toLocaleTimeString();
      }
      return x.toLocaleDateString();
    } 
    return dt
  }
  getVendor() {
    let ret = '';
    if (this.vendor) {
      ret += this.vendor.company.trim();
      ret += ' ' + this.vendor.address;
      ret += ' ' + this.getAddress2('vendor');
    }

    return ret;
  }
  setPeriodDefault() {
    this.shipDate = new Date(this.order.shipmentDate);
    this.year = this.shipDate.getFullYear();
    this.orderForm.controls.year.setValue(this.year);
    this.getPeriods();
  }
  yearChange(y) {
    if (y != null) {
      this.year = y;
    }
    if (this.year * 1 > 999) {
      this.getPeriods();
    }
  }
  getPeriods() {
    const shippedDate = this.order.shipmentDate.toString().substring(0, 10);
    this.periods = [];
    this.orderForm.controls.period.setValue('');
    this.weekKalcorService.getYear(this.year).subscribe(data => {
      const periodYear = data;
      for (let i = 0; i < periodYear.length; i++) {
        if (periodYear[i].period != 0) {
          const index = this.periods.findIndex(x => x == periodYear[i].period);
          if (index == -1) {
            this.periods.push(periodYear[i].period);
          }
        }     
        // this.orderForm.controls.year.setValue(item.year);
        // this.orderForm.controls.period.setValue(item.period); 
        if (shippedDate >= periodYear[i].weekStartDate && shippedDate <= periodYear[i].weekEndDate) { 
          this.orderForm.controls.period.setValue(periodYear[i].period);
        }
      }      
    })
  }
  calculate() {
    this.totalCases = 0;
    this.totalShipped = 0;
    this.totalInvoiced = 0;
    this.totalExtCost = 0;
    this.totalMarketing = 0;
    this.totalBilling = 0;
    this.totalTerms = 0;
    this.totalCommission = 0;
    this.totalKalcor = 0;
    this.exchangeProduct = 0;
    this.cndFrtCostCase = [];
    this.storageCostCase = [];
    this.totalCndFrtCostCase = 0;
    this.totalStorageCostCase = 0;
    this.totalPurchaseCaseCostExt = 0;
    this.totalFreightCostExt = 0;
    this.totalClearing = 0;
    this.totalDuty = 0;
    this.totalVendor = 0;
    this.totalRoyalty = 0;
    let bigNum: any;
    let totalCommission1 = 0;
    let totalCommission2 = 0;
    this.items.forEach((element, index) => {
      // table 1
      const invoiced = element.invoiced ? element.invoiced : 0;
      const caseCost = element.caseCost ? element.caseCost : 0;
      bigNum = Big(invoiced)
      .times(caseCost)
      .round(2);
      const extCaseCost = bigNum * 1;
      element.extCaseCost = extCaseCost;      

      this.totalCases += element.cases ? element.cases : 0;
      this.totalShipped += element.shipped ? element.shipped : 0;
      this.totalInvoiced += invoiced;
      this.totalExtCost += extCaseCost;
      // table 2
      const marketing1 = element.marketing1 ? element.marketing1 : 0;
      const billing1 = element.billing1 ? element.billing1 : 0;
      const terms1 = element.terms1 ? element.terms1 : 0;
      if (isNaN(this.orderForm.controls.exchangeActual.value)) {
        element.exchangeActual = 0;
      } else {
        element.exchangeActual = this.orderForm.controls.exchangeActual.value;
      }
      


      bigNum = Big(extCaseCost)
        .times(marketing1)
        .times(.01)
        .round(2);
      element.marketing = bigNum * 1;
      this.totalMarketing += element.marketing;

      bigNum = Big(extCaseCost)
        .times(billing1)
        .times(.01)
        .round(2);
      element.billing = bigNum * 1;
      this.totalBilling += element.billing;

      bigNum = Big(extCaseCost)
        .times(terms1)
        .times(.01)
        .round(2);
      element.terms = bigNum * 1;
      this.totalTerms += element.terms;

      // table 3
      const commission1 = element.commission1 ? element.commission1 : 0;
      this.commPct1 = commission1;
      bigNum = Big(extCaseCost)
        .times(commission1)
        .times(.01)
        .round(2);
      element.commission11 = bigNum * 1;
      totalCommission1 += element.commission11;
    
      const commission2 = element.commission2 ? element.commission2 : 0;
      this.commPct2 = commission2;
      bigNum = Big(extCaseCost)
        .times(commission2)
        .times(.01)
        .round(2);
      element.commission21 = bigNum * 1;
      totalCommission2 += element.commission21;

      this.totalCommission += element.commission11 + element.commission21;

      const kalcor1 = element.kalcor1 ? element.kalcor1 : 0;
      bigNum = Big(extCaseCost)
        .times(kalcor1)
        .times(.01)
        .round(2);
      element.kalcor = bigNum * 1;
      this.totalKalcor += element.kalcor;

      //exchangeProduct     
      if (this.order.orderType != 'Stock') {
        this.stockOrder = true;
        const fobDiff = element.fobDifference ? element.fobDifference : 0;
        bigNum = Big(invoiced)
        .times(fobDiff)
        .round(4);
        this.exchangeProduct += bigNum * 1;
      } 

      // table 4
      const cndFrtCostCase = element.cndFrtCostCase ? element.cndFrtCostCase : 0;
      bigNum = Big(cndFrtCostCase)
        .times(invoiced)
        .round(2);
      const cndFrtCostCaseExt = bigNum * 1;
      this.cndFrtCostCase.push(cndFrtCostCaseExt);

      const storageCostCase = element.storageCostCase ? element.storageCostCase : 0;
      bigNum = Big(storageCostCase)
        .times(invoiced)
        .round(2);
      const storageCostCaseExt = bigNum * 1;
      // const storageCostCaseExt = invoiced * storageCostCase;
      this.storageCostCase.push(storageCostCaseExt);


      this.totalCndFrtCostCase += cndFrtCostCaseExt;
      this.totalStorageCostCase += storageCostCaseExt;

      
    });
    
    this.itemsUSD.forEach((element, index) => {
      const invoiced = element.invoiced ? element.invoiced : 0;
      const purchaseCaseCost = element.purchaseCaseCost ? element.purchaseCaseCost : 0;
      const clearingCs = element.clearingCs ? element.clearingCs : 0;
      const freightCs = element.freightCs ? element.freightCs : 0;
      const duty = element.duty ? element.duty : 0;
      const royalty = element.royalty ? element.royalty : 0;
      
      // usd table 1
      bigNum = Big(purchaseCaseCost)
        .times(invoiced)
        .round(2);
      element.extCaseCost = bigNum * 1;
      this.totalPurchaseCaseCostExt += element.extCaseCost;

      bigNum = Big(purchaseCaseCost)
      .minus(royalty)
      .minus(clearingCs)
      .minus(freightCs)
      .minus(duty)
      .round(2);
      const netVendorCost = bigNum * 1;
      this.usdVendorCost[index] = netVendorCost;
      element.extCaseCost = invoiced * netVendorCost;
      this.totalVendor += element.extCaseCost;

      this.usdFrtCost[index] = freightCs * invoiced;
      this.totalFreightCostExt += this.usdFrtCost[index];

      this.usdClearing[index] = clearingCs * invoiced;
      this.totalClearing += this.usdClearing[index];

      this.usdDuty[index] = duty * invoiced;
      this.usdRoyalty[index] = royalty * invoiced;
      this.totalDuty += this.usdDuty[index];
      this.totalRoyalty += this.usdRoyalty[index];
    });

    this.budgetActual.forEach(element => {
      element.actualAmount = element.actualAmount ? element.actualAmount : 0;
      element.budgetAmount = element.budgetAmount ? element.budgetAmount : 0;
    })  
    // 0 sales
    const indexSales = this.getBudgetIndex(0);
    if (indexSales > -1) {
      this.budgetActual[indexSales].budgetAmount = this.totalExtCost;
      this.budgetActual[indexSales].actualAmount = this.totalExtCost;
    }
    // 100 marketing
    this.indexMarketing = this.getBudgetIndex(100);
    if (this.indexMarketing > -1) { 
      this.budgetActual[this.indexMarketing].budgetAmount = this.totalMarketing;
      this.budgetActual[this.indexMarketing].actualAmount = this.totalMarketing;
    }
    // 200 billing
    this.indexBilling = this.getBudgetIndex(200);
    if (this.indexBilling > -1) { 
      this.budgetActual[this.indexBilling].budgetAmount = this.totalBilling;
      this.budgetActual[this.indexBilling].difference = this.budgetActual[this.indexBilling].budgetAmount - this.budgetActual[this.indexBilling].actualAmount;
    }      
    // 300 terms
    this.indexTerms = this.getBudgetIndex(300);
    if (this.indexTerms > -1) { 
      this.budgetActual[this.indexTerms].budgetAmount = this.totalTerms;
      this.budgetActual[this.indexTerms].difference = this.budgetActual[this.indexTerms].budgetAmount - this.budgetActual[this.indexTerms].actualAmount;
    }
    // 400 commission
    this.indexCommission = this.getBudgetIndex(400);
    if (this.indexCommission > -1) { 
      this.budgetActual[this.indexCommission].budgetAmount = this.totalCommission;
      this.budgetActual[this.indexCommission].actualAmount = this.totalCommission;
      this.budgetActual[this.indexCommission].reference = this.order.invoiceNumber;
      this.budgetActual[this.indexCommission].commission1 = totalCommission1;
      this.budgetActual[this.indexCommission].commission2 = totalCommission2;
      this.budgetActual[this.indexCommission].commPct1 = this.commPct1;
      this.budgetActual[this.indexCommission].commPct2 = this.commPct2;
    }
    // 500 kalcor
    this.indexKalcor = this.getBudgetIndex(500);
    if (this.indexKalcor > -1) { 
      this.budgetActual[this.indexKalcor].budgetAmount = this.totalKalcor;
      this.budgetActual[this.indexKalcor].actualAmount = this.totalKalcor;
      this.budgetActual[this.indexKalcor].reference = this.order.invoiceNumber;
    }
    // 600 freight CND
    this.indexFreightCnd = this.getBudgetIndex(600);
    if (this.indexFreightCnd > -1) {
      this.budgetActual[this.indexFreightCnd].budgetAmount = this.totalCndFrtCostCase;
      this.budgetActual[this.indexFreightCnd].difference = this.budgetActual[this.indexFreightCnd].budgetAmount - this.budgetActual[this.indexFreightCnd].actualAmount;
      this.budgetActual[this.indexFreightCnd].revenue = this.orderForm.controls.revenue.value;
      if (this.orderForm.controls.revenue.value > 0 && this.budgetActual[this.indexFreightCnd].freightCustom == 'Y') {
        const actualTotal = this.orderForm.controls.revenue.value - this.exchangeProduct - this.budgetActual[this.indexMarketing].actualAmount - this.budgetActual[this.indexCommission].actualAmount - this.budgetActual[this.indexKalcor].actualAmount;
        bigNum = Big(actualTotal)
        .minus(this.budgetActual[this.indexFreightCnd].budgetAmount)
        .round(2);
        this.budgetActual[this.indexFreightCnd].actualAmount = bigNum * -1;     
      }
    }  
    
    

    // 700 
    const index700 = this.getBudgetIndex(700);
    if (index700 > -1) { 
      this.budgetActual[index700].freightCost = this.getFreightCost('CND');
      this.budgetActual[index700].freightCostSales = this.getFreightCostSales('CND');
      this.budgetActual[index700].freightTotalCost = this.getFreightTotal('CND');
    }
    // 800 storage
    this.indexStorage = this.getBudgetIndex(800);
    if (this.indexStorage > -1) { 
      this.budgetActual[this.indexStorage].budgetAmount = this.totalStorageCostCase;
      this.budgetActual[this.indexStorage].difference = this.budgetActual[this.indexStorage].budgetAmount - this.budgetActual[this.indexStorage].actualAmount;
    }
    // 900 net CND
    const indexNetCND = this.getBudgetIndex(900);
    if (indexNetCND > -1) { 
      this.budgetActual[indexNetCND].budgetAmount = this.budgetActual[indexSales].budgetAmount - 
      this.budgetActual[this.indexMarketing].budgetAmount - this.budgetActual[this.indexBilling].budgetAmount -
      this.budgetActual[this.indexTerms].budgetAmount - this.budgetActual[this.indexCommission].budgetAmount -
      this.budgetActual[this.indexKalcor].budgetAmount - this.budgetActual[this.indexFreightCnd].budgetAmount -
      this.budgetActual[this.indexStorage].budgetAmount;
      this.budgetActual[indexNetCND].actualAmount = 0;      
    }    
    // 1200 exchamge
    const indexExchange = this.getBudgetIndex(1200);
    if (indexExchange > -1) { 
      this.budgetActual[indexExchange].budgetAmount = this.order.exchangeBudget;
      if (isNaN(this.orderForm.controls.exchangeActual.value) || this.orderForm.controls.exchangeActual.value === null) {
        this.budgetActual[indexExchange].actualAmount = 0;
      } else {
        this.budgetActual[indexExchange].actualAmount = this.orderForm.controls.exchangeActual.value;
      }
      this.budgetActual[indexExchange].confirm = this.orderForm.controls.exchangeConfirmed.value;
      this.budgetActual[indexExchange].period = this.orderForm.controls.period.value;
      this.budgetActual[indexExchange].year = this.orderForm.controls.year.value;
    }

    // 1400 clearing
    this.indexClearing = this.getBudgetIndex(1400);
    if (this.indexClearing > -1) { 
      this.budgetActual[this.indexClearing].budgetAmount = this.totalClearing;
      this.budgetActual[this.indexClearing].difference = this.budgetActual[this.indexClearing].budgetAmount - this.budgetActual[this.indexClearing].actualAmount;
    }
    
    // 1500 duty
    this.indexDuty = this.getBudgetIndex(1500);
    if (this.indexDuty > -1) { 
      this.budgetActual[this.indexDuty].budgetAmount = this.totalDuty;
      this.budgetActual[this.indexDuty].difference = this.budgetActual[this.indexDuty].budgetAmount - this.budgetActual[this.indexDuty].actualAmount;
    }
    
    // 1600 freight usd
    this.indexFreightUSD = this.getBudgetIndex(1600);
    if (this.indexFreightUSD > -1) { 
      this.budgetActual[this.indexFreightUSD].budgetAmount = this.totalFreightCostExt;
      this.budgetActual[this.indexFreightUSD].difference = this.budgetActual[this.indexFreightUSD].budgetAmount - this.budgetActual[this.indexFreightUSD].actualAmount;
    }
    // 1700 
    const indexFreightCostUSD = this.getBudgetIndex(1700);
    if (indexFreightCostUSD > -1) { 
      this.budgetActual[indexFreightCostUSD].freightCost = this.getFreightCost('USD');
      this.budgetActual[indexFreightCostUSD].freightCostSales = this.getFreightCostSales('USD');
      this.budgetActual[indexFreightCostUSD].freightTotalCost = this.getFreightTotal('USD');
    }
    // 1800 vendor
    this.indexVendor = this.getBudgetIndex(1800);
    if (this.indexVendor > -1) { 
      this.budgetActual[this.indexVendor].budgetAmount = this.totalVendor;
      this.budgetActual[this.indexVendor].actualAmount = this.budgetActual[this.indexVendor].budgetAmount;
    }
    
    // 1350 royalty
    this.indexRoyalty = this.getBudgetIndex(1350);
    if (this.indexRoyalty > -1) { 
      this.budgetActual[this.indexRoyalty].budgetAmount = this.totalRoyalty;
      this.budgetActual[this.indexRoyalty].actualAmount = this.totalRoyalty;
    } 

    // 1300 vendor purchase
    const indexVendorPurchase = this.getBudgetIndex(1300);
    if (indexVendorPurchase > -1) { 
    bigNum = Big(this.budgetActual[this.indexVendor].budgetAmount)
      .plus(this.budgetActual[this.indexRoyalty].budgetAmount)
      .plus(this.budgetActual[this.indexClearing].budgetAmount)
      .plus(this.budgetActual[this.indexDuty].budgetAmount)
      .plus(this.budgetActual[this.indexFreightUSD].budgetAmount)
      .round(2);
    this.budgetActual[indexVendorPurchase].budgetAmount = bigNum * 1;
    this.budgetActual[indexVendorPurchase].actualAmount = this.budgetActual[indexVendorPurchase].budgetAmount
  }
    // 1100 fob net return
    // Vendor Purchase Case Cost / Exchange Budget
    const indexFobNetReturn = this.getBudgetIndex(1100);
    if (indexFobNetReturn > -1) { 
      if (this.budgetActual[indexExchange].budgetAmount != 0) {
        bigNum = Big(this.budgetActual[indexVendorPurchase].budgetAmount)
        .div(this.budgetActual[indexExchange].budgetAmount)
        .round(2);
        this.budgetActual[indexFobNetReturn].budgetAmount = bigNum * 1;
      } else {
        this.budgetActual[indexFobNetReturn].budgetAmount = 0;
      }
    }

    if (this.budgetActual[indexExchange].actualAmount !=0 ) {
      Big.DP = 7;
      bigNum = Big(this.budgetActual[indexVendorPurchase].actualAmount)
      .div(this.budgetActual[indexExchange].actualAmount)
      .round(2);
      this.budgetActual[indexFobNetReturn].actualAmount = bigNum * 1;
    } else {
      this.budgetActual[indexFobNetReturn].actualAmount = 0;
    }

    // 10 fob dofferemce
    // fob difference = Net CND - FOB Net Return
    // new 2/7/20 Fob Difference [actual] = Net CND[Budget] - FOB Net Return Actual
    // new 9/9/20 Blank out budget. Only show p/l
    // new 3/7/2023 Add exchange difference to Fob Difference exchange actual amount
    this.indexFobDiff = this.getBudgetIndex(1000);
    if (this.indexFobDiff > -1) {
      this.budgetActual[this.indexFobDiff].budgetAmount = 0; 
      bigNum = Big(this.budgetActual[indexFobNetReturn].budgetAmount)
        .minus(this.budgetActual[indexFobNetReturn].actualAmount)
        .plus(this.exchangeProduct)
        .round(2);
        this.budgetActual[this.indexFobDiff].actualAmount = bigNum * 1; 
    }
  }
  getBudgetIndex(sortNumber) {
    const index = this.budgetActual.findIndex(o => o.sortNumber == sortNumber);
    return index;
  }
  getFreightDefault(){
    if (this.budgetActual[this.indexFreightCnd].revenue > 0) {
      this.budgetActual[this.indexFreightCnd].freightCustom = 'Y';
      this.calculate();
    }
  }
  getFreightCost(type) {
    let retValue = 0;
    retValue += this.order.freightType1 === type ? this.order.freightCost1 : 0;
    retValue += this.order.freightType2 === type ? this.order.freightCost2 : 0;
    retValue += this.order.freightType3 === type ? this.order.freightCost3 : 0;
    retValue += this.order.freightType4 === type ? this.order.freightCost4 : 0;
    retValue += this.order.freightType5 === type ? this.order.freightCost5 : 0;
    return retValue;    
  }
  getFreightCostSales(type) {
    let retValue = 0;
    retValue += this.order.freightType1 === type ? this.order.freightCostSales1 : 0;
    retValue += this.order.freightType2 === type ? this.order.freightCostSales2 : 0;
    retValue += this.order.freightType3 === type ? this.order.freightCostSales3 : 0;
    retValue += this.order.freightType4 === type ? this.order.freightCostSales4 : 0;
    retValue += this.order.freightType5 === type ? this.order.freightCostSales5 : 0;
    return retValue;    
  }
  getFreightTotal(type) {
    let retValue = 0;
    retValue += this.order.freightType1 === type ? this.order.freightTotalCost1 : 0;
    retValue += this.order.freightType2 === type ? this.order.freightTotalCost2 : 0;
    retValue += this.order.freightType3 === type ? this.order.freightTotalCost3 : 0;
    retValue += this.order.freightType4 === type ? this.order.freightTotalCost4 : 0;
    retValue += this.order.freightType5 === type ? this.order.freightTotalCost5 : 0;
    return retValue;    

  }

  onChanges(): void {
    this.orderForm.get('exchangeActual').valueChanges.subscribe(val => {
      this.calculate();
    })
    this.orderForm.get('year').valueChanges.subscribe(val => {
      this.yearChange(val);
    })
  }
  save() {
    this.updateActuals('');
  }
  validate() {
    let returnValue = true;
    let indexItem = this.getBudgetIndex(1200);
    this.budgetActual[indexItem].confirm = this.orderForm.controls.exchangeConfirmed.value;
//    const confirmRequired = [2, 3, 6, 8, 12, 14, 15, 16, 18];
    const confirmRequired = [200, 300, 600, 800, 1200, 1400, 1500, 1600, 1800];

    confirmRequired.forEach(item => {

      if (returnValue) {
        indexItem = this.getBudgetIndex(item);
        if (indexItem > -1) {
          if (this.budgetActual[indexItem].actualAmount === null) {
            alert('Please Enter Actual Amount for ' + this.budgetActual[indexItem].item);
            returnValue = false;
            return;
          }
          if (item != 1200) {
            if (!this.budgetActual[indexItem].company) {
              alert('Please Enter Company name for ' + this.budgetActual[indexItem].item);
              returnValue = false;
              return;
            }
      
            if (!this.budgetActual[indexItem].reference) {
              alert('Please Enter reference/invoice number for ' + this.budgetActual[indexItem].item);
              returnValue = false;
              return;
            }
          }
  
          if (this.budgetActual[indexItem].confirm !=="YES") {
            alert('Please Confirm ' + this.budgetActual[indexItem].item);
            returnValue = false;
          }
        }
      }
    })
    return returnValue;
  }
  onSubmit() {
    if (!this.validate()) {
      return;
    }
    this.updateActuals('Closed');
  }
  
  updateActuals(status) {
    this.calculate();
    this.counter = 0;
    this.budgetActualTotalCount = this.budgetActuals.length;

    // update budget acutals
    this.budgetActual.forEach(item => {
      this.budgetActualPost = new BudgetActuals();
      this.budgetActualPost.actualAmount = item.actualAmount;
      this.budgetActualPost.budgetAmount  = item.budgetAmount;
      this.budgetActualPost.company = item.company;
      this.budgetActualPost.confirm = item.confirm;
      this.budgetActualPost.difference = item.difference;
      this.budgetActualPost.edit = item.edit;
      this.budgetActualPost.freightCost = item.freightCost;
      this.budgetActualPost.freightCostSales = item.freightCostSales;
      this.budgetActualPost.freightTotalCost = item.freightTotalCost;
      this.budgetActualPost.freightType = item.freightType;
      this.budgetActualPost.funds = item.funds;
      this.budgetActualPost.id = item.id;
      this.budgetActualPost.item = item.item;
      this.budgetActualPost.reference = item.reference;
      this.budgetActualPost.salesOrderNo = this.salesOrderNo;
      this.budgetActualPost.sortNumber = item.sortNumber;
      this.budgetActualPost.commission1 = item.commission1;
      this.budgetActualPost.commission2 = item.commission2;
      this.budgetActualPost.commPct1 = item.commPct1;
      this.budgetActualPost.commPct2 = item.commPct2;
      this.budgetActualPost.period = item.period;
      this.budgetActualPost.year = item.year;
      this.budgetActualPost.revenue = item.revenue;
      this.budgetActualPost.freightCustom = item.freightCustom;

      if (item.item == 'Exchange') {
        this.budgetActualPost.actualExchange = item.actualAmount;
        this.budgetActualPost.budgetExchange = item.budgetAmount;
      }
      if (item.id == 0) {
        this.budgetActualService.create(this.budgetActualPost).subscribe( data => {
          const indexSort = this.getBudgetIndex(data.sortNumber);
          this.budgetActual[indexSort].id = data.id;
          this.counter++;
          if (this.counter >= this.budgetActualTotalCount) {
            this.updateOrder(status);
          }
        });
      } else {
        this.budgetActualService.update(this.budgetActualPost).subscribe( data => {
          this.counter++;
          if (this.counter >= this.budgetActualTotalCount) {
            this.updateOrder(status);
          }
        })        
      }
    })
  }
  updateOrder(status) {
    this.order.exchangeActual = this.orderForm.controls.exchangeActual.value;
    if (status == "Closed") {
      this.order.orderStatus = "Closed";
    }
    this.orderService.update(this.order).subscribe( data => {
      this.success.next(`Successfully updated.`);
    })
  }

  printIt() {
    this.orderPDF = {};
    this.orderPDF.billTo = this.customer.company ? this.customer.company : '';
    this.orderPDF.billAddress = this.customer.address ? this.customer.address : '';
    const customerCity = this.customer.city ? this.customer.city : '';
    const customerState = this.customer.stateProvince ? this.customer.stateProvince : '';
    const customerZip = this.customer.zipPostalCode ? this.customer.zipPostalCode : '';
    this.orderPDF.billCSZ = customerCity.trim() + ", " + 
      customerState.trim() + " " + customerZip;

    this.orderPDF.shipTo = this.shipTo.company ? this.shipTo.company : '';
    this.orderPDF.shipToAddress = this.shipTo.address ? this.shipTo.address : '';
    const shipCity = this.shipTo.city ? this.shipTo.city : '';
    const shipState = this.shipTo.stateProvince ? this.shipTo.stateProvince : '';
    const shipZip = this.shipTo.zipPostalCode ? this.shipTo.zipPostalCode : '';
    this.orderPDF.shipToCSZ = shipCity.trim() + ", " + 
      shipState.trim() + " " + 
      shipZip;

    this.orderPDF.salesOrderDate = this.order.salesOrderDate;
    this.orderPDF.shipmentDate = this.order.shipmentDate;

    this.orderPDF.pickCompany = this.pickup.company ? this.pickup.company : '';
    this.orderPDF.pickAddress = this.pickup.address ? this.pickup.address : '';
    const pickupCity = this.pickup.city ? this.pickup.city : '';
    const stateProvince = this.pickup.stateProvince ? this.pickup.stateProvince : '';
    const zipPostalCode = this.pickup.zipPostalCode ? this.pickup.zipPostalCode : '';
    this.orderPDF.pickCSZ = pickupCity.trim() + ", " +
      stateProvince.trim() + " " + 
      zipPostalCode;


    this.orderPDF.exchangeBudget = this.order.exchangeBudget;
    this.orderPDF.salesOrderNo = this.order.salesOrderNo;
    this.orderPDF.deliveredDateAppt = this.order.deliveredDateAppt;
    this.orderPDF.purchaseOrder = this.order.purchaseOrder;
    this.orderPDF.appConfirmed = this.order.appConfirmed;
    this.orderPDF.exchangeActual = this.order.exchangeActual;
    this.orderPDF.paymentTerms = this.term.paymentTerms;
    this.orderPDF.freightType  = this.freightType.freightType;
    this.orderPDF.broker = this.broker.company;    
    this.orderPDF.orderStatus = this.order.orderStatus;
    
    this.orderPDF.vendor = this.vendor.company;
    this.orderPDF.vendorAddress = this.vendor.address;
    this.orderPDF.vendorCSZ = this.vendor.city.trim() + ", " +
      this.vendor.stateProvince.trim() + " " + 
      this.vendor.zipPostalCode;

    this.orderPDF.location1 = this.location1.location;
    this.orderPDF.location2 = this.location2.location;
    this.orderPDF.transCompany1  = this.transport1;
    this.orderPDF.transCompany2  = this.transport2
    this.orderPDF.whseCompany = this.warehouse ? this.warehouse.company : '';
    this.orderPDF.notes = this.order.notes;

    this.calculate();
    this.title = this.reportNames[0].Name;
    this.reportName = this.reportNames[0].Name;
    this.checkRef.detectChanges();
    let fileName = '';
    fileName += this.vendor.company ? this.vendor.company.trim() : '';
    fileName += this.order.purchaseOrder ? ' – PO ' + this.order.purchaseOrder.trim() : '';
    fileName += ' – Profit Loss';
    fileName += this.shipTo.company ? ' – ' + this.shipTo.company.trim() : '';
    this.pageBreak = {};
    this.pageBreak.mode = 'avoid-all';
    if (this.items.length > 12) {
      this.pageBreak.before = ['.break-CND', '.break-USD'];
    } else {
      if (this.items.length > 2) {
        this.pageBreak.before = '.break-USD';
      }
    }
 
    var element = document.getElementById(this.reportNames[0].Id);
    var opt = {
      margin:       [this.reportNames[0].TopMargin, this.reportNames[0].LRMargin],
      filename:     fileName + '.pdf',
      image:        { type: 'jpeg', quality: 0.98 },
      pagebreak:    this.pageBreak,
      html2canvas:  { scale: 2 },
      jsPDF:        { unit: 'in', format: 'letter', orientation: this.reportNames[0].Orientation }
    };
    html2pdf().set(opt).from(element).save();
    this.reportName = '';

  }

}
