import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Customer } from '../../../../models/customer.model';
import { Term } from '../../../../models/term-model';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { CustomerService } from '../../../../services/customer.service';
import { TermsService } from '../../../../services/terms.service';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { TypeOfAccount } from 'src/app/models/type-of-account-model';
import { TypeOfAccountService } from 'src/app/services/type-of-account.service';
import { Region } from 'src/app/models/region-model';
import { RegionService } from 'src/app/services/region.service';
import { WeekCustomerHeadsResolver } from 'src/app/other';
import { CustomerEndService } from 'src/app/services/customer-end.service';
import { CustomerEnd } from 'src/app/models/customer-end-model';
import { OtherModule } from 'src/app/other/other.module';
import { SorterService } from 'src/app/services/sorter.service';
import { Sorter2Service } from 'src/app/services/sorter2.service';
import { Sorter3Service } from 'src/app/services/sorter3.service';
//import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { ThrottlingUtils } from '@azure/msal-common';
import { CustomerHeads } from 'src/app/models/customer-heads.model';
import { CustomerHeadsService } from 'src/app/services/customer-heads.service';
import { threadId } from 'worker_threads';

@Component({
  selector: 'app-customer-details',
  templateUrl: './customer-details.component.html',
  styleUrls: ['./customer-details.component.scss']
})
export class CustomerDetailsComponent implements OnInit {
  customer: Customer;
  term: Term[] = [];
  customerForm: UntypedFormGroup;
  typeOfAcct: TypeOfAccount[];
  region: Region[];
  customerEnd: CustomerEnd[];
  successMessage: string;
  retailCompany = " ";
  retailRegion = " ";
  retailRegionId: number;
  retailAccountType = " ";
  retailAccountTypeId: number;
  shipToCompany = " ";
  shipToAccountType = " ";
  shipToAccountTypeId: number;
  shipToRegion = " ";
  shipToRegionId: number;
  customerHead: [];
  regionAccountTypes: any;
  originalQuery: any;
  bannerData = [];
  shipToData = [];
  regionData = [];
  reducedRegion = [];
  originalShipTo = [];
  originalBanner = [];
  counter: number;
  customerHeadIds: string;
  customerHeadSelected: [];
  customerTable: CustomerHeads[];
  originalCustomerHead = [];
  customerHeadData = [];
  customerHeadChanged = false;



  private success = new Subject<string>();
  staticAlertClosed = false;
  constructor(private route: ActivatedRoute,
              private router: Router,
              private fb: UntypedFormBuilder,
              private customerSvc: CustomerService,
              private termsSvc: TermsService,
              private typeOfAcctSvc: TypeOfAccountService,
              private regionSvc: RegionService,
              private customerEndSvc: CustomerEndService,
              private sortSvc: SorterService,
              private sort2Svc: Sorter2Service,
              private sort3Svc: Sorter3Service,
              private customerHeadSvc: CustomerHeadsService
  ) { }

  ngOnInit() {
    this.customerForm = this.fb.group({
      accountNo: [''],
      activeItem:  ['', Validators.required],
      address: [''],
      billing: [''],
      bucketsId: [''],
      businessPhone: [''],
      city: [''],
      clearing: [''],
      comments: [''],
      commission: [''],
      company: ['', Validators.required],
      countryRegion: [''],
      currencyCode: [''],
      eMail: [''],
      exchange: [''],
      faxNumber: [''],
      freightCanada: [''],
      freightUsaCanada: [''],
      fullAddress: [''],
      fullName: [''],
      jobTitle: [''],
      marketing: [''],
      mobileNumber: [''],
      orderType: [''],
      paymentTermsTerms: [''],
      stateProvince: [''],
      storage: [''],
      termsId: [''],
      tollLocalCog: [''],
      vendorId: [''],
      webPage: [''],
      zipPostalCode: [''],
      latitude: [''],
      longitude: [''],
      customerHeadIds: ['']

    });
    this.customerTable = [];
    this.customerHead = [];
    this.termsSvc.getAll().subscribe(data2 => {
      this.term = data2;
      this.typeOfAcctSvc.getAll().subscribe(typeOfAcct => {
        this.typeOfAcct = typeOfAcct;
        this.customerEndSvc.getAll().subscribe(customerEnd => {
          this.customerEnd = customerEnd;
          this.regionSvc.getAll().subscribe(region => {
            this.region = region;
            this.region.sort((a, b) => a.regionName < b.regionName ? -1 : a.regionName > b.regionName ? 1 : 0);
            this.route.data.subscribe(data3 => {
              this.customer = data3.dataCustomer;              
                this.customerHeadSvc.getCustomers(this.customer.id).subscribe(data => {
                  this.originalCustomerHead = data;
                  this.customerTable = data;
                  if (this.originalCustomerHead.length > 0) {
                    this.originalCustomerHead.forEach(e => {
                      const index = this.customerHeadData.findIndex(h => h.customerEndIds == e.customerHeadId);
                      if (index == -1) {
                        const head = {
                          customer: e.customerId,
                          head: e.headId,
                          customerHeadId: e.customerHeadId,
                        }
                        this.customerHeadData.push(e.headId);                      
                        if (e.headId > 0) {
                          const index = this.customerHeadData.findIndex(data => data.id == e.headId);
  
                        }
                      } 
                      this.fillFormData();
                    })
                  } else {
                    this.fillFormData();
                  }
              this.onChanges();
              this.getShipto();
              })
              
            });
          })
        })
      })
    });

    setTimeout(() => this.staticAlertClosed = true, 20000);

    this.success.subscribe((message) => this.successMessage = message);
    this.success.pipe(
      debounceTime(5000)
    ).subscribe(() => this.successMessage = null);
  }
  onChanges(): void {
    this.customerForm.get('customerHeadIds').valueChanges.subscribe(val => {
      this.onSubmit();
    })
  }
  getShipto() {
    this.regionData = [];
    this.shipToData = [];
    this.customerSvc.getRegions(this.customer.id).subscribe(data => {
      this.originalShipTo = data.item;
      this.originalBanner = data.item2;
      this.originalShipTo.forEach(e => { 
          const index = this.shipToData.findIndex(s => s.shipToCompany == e.shipToCompany);
          if (index == -1) {
            const ship = {
              shipToCompany: e.shipToCompany,
              shipToType: e.shipToAccountType,
              shipToTypeId: e.shipToAccountTypeId,
              shipToRegion: e.shipToRegion,
              shipToRegionId: e.shipToRegionId,
              shipToLocation: e.shipToLocation,
              locationId: e.shipToLocationId,
            }
            this.shipToData.push(ship);
          } else {
            const index2 = this.shipToData[index].shipToRegion.indexOf(e.shipToRegion);
            if (index2 == -1) {
              this.shipToData[index].shipToRegion = this.shipToData[index].shipToRegion + " " + e.shipToRegion;
            }
          }       
      })
      this.compareRegions(); 
    }) 
  }
  compareRegions() {
    this.originalBanner.forEach(e => {
      this.shipToData.forEach(s => {
        if (e.retailRegion !== null && s.shipToRegion != null) {
          if (s.shipToRegion.indexOf(e.retailRegion) > -1) {  
            const banner = {
              banner: e.retailCompany,
              bannerType: e.retailAccountType,
              bannerRegion: s.shipToRegion,
              bannerRegionId: e.retailRegionId,
              shipToCompany: s.shipToCompany,
              shipToLocation: s.shipToLocation,
              shipToRegion: s.shipToRegion
            }
            if (this.compare(banner)) {
              this.regionData.push(banner);
            }    
        }
        }      
      })
    });
  }
  compare(e) {
    let ret = true;
    for (let i = 0; i < this.regionData.length; i ++) {
      ret = false;
      if (this.regionData[i].shipToCompany != e.shipToCompany) {
        return true;
      }
      if (this.regionData[i].banner != e.banner) {
        return true;
      }
      if (this.regionData[i].location != e.location) {
        return true;
      } 
      e.bannerRegion = e.bannerRegion + " " + this.regionData[i].bannerRegion;
    }
    return ret;
  }
  onSubmit() {
    this.customer.accountNo = this.customerForm.controls.accountNo.value;
    this.customer.activeItem = this.customerForm.controls.activeItem.value;
    this.customer.address = this.customerForm.controls.address.value;
    this.customer.billing = this.customerForm.controls.billing.value;
    this.customer.bucketsId = this.customerForm.controls.bucketsId.value;
    this.customer.businessPhone = this.customerForm.controls.businessPhone.value;
    this.customer.city = this.customerForm.controls.city.value;
    this.customer.clearing = this.customerForm.controls.clearing.value;
    this.customer.comments = this.customerForm.controls.comments.value;
    this.customer.commission = this.customerForm.controls.commission.value;
    this.customer.company = this.customerForm.controls.company.value;
    this.customer.countryRegion = this.customerForm.controls.countryRegion.value;
    this.customer.currencyCode = this.customerForm.controls.currencyCode.value;
    this.customer.eMail = this.customerForm.controls.eMail.value;
    this.customer.exchange = this.customerForm.controls.exchange.value;
    this.customer.faxNumber = this.customerForm.controls.faxNumber.value;
    this.customer.freightCanada = this.customerForm.controls.freightCanada.value;
    this.customer.freightUsaCanada = this.customerForm.controls.freightUsaCanada.value;
    this.customer.fullAddress = this.customerForm.controls.fullAddress.value;
    this.customer.fullName = this.customerForm.controls.fullName.value;
    this.customer.jobTitle = this.customerForm.controls.jobTitle.value;
    this.customer.marketing = this.customerForm.controls.marketing.value;
    this.customer.mobileNumber = this.customerForm.controls.mobileNumber.value;
    this.customer.orderType = this.customerForm.controls.orderType.value;
    this.customer.paymentTermsTerms = this.customerForm.controls.paymentTermsTerms.value;
    this.customer.stateProvince = this.customerForm.controls.stateProvince.value;
    this.customer.storage = this.customerForm.controls.storage.value;
    this.customer.termsId = this.customerForm.controls.termsId.value;
    this.customer.tollLocalCog = this.customerForm.controls.tollLocalCog.value;
    this.customer.vendorId = this.customerForm.controls.vendorId.value;
    this.customer.webPage = this.customerForm.controls.webPage.value;
    this.customer.zipPostalCode = this.customerForm.controls.zipPostalCode.value;
    this.customer.latitude = this.customerForm.controls.latitude.value;
    this.customer.longitude = this.customerForm.controls.longitude.value;
    this.customerHeadSelected = this.customerForm.controls.customerHeadIds.value;
    this.counter = 0;

    this.customerSvc.update(this.customer).subscribe(data => {
      this.deleteCustomerHeads();
      this.success.next(`Successfully changed.`);
    }, error => {
      

    });
  }
  fillFormData() {
    this.customerForm.setValue({
      accountNo: this.customer.accountNo,
      activeItem: this.customer.activeItem,
      address: this.customer.address,
      billing: this.customer.billing,
      bucketsId: this.customer.bucketsId,
      businessPhone: this.customer.businessPhone,
      city: this.customer.city,
      clearing: this.customer.clearing,
      comments: this.customer.comments,
      commission: this.customer.commission,
      company: this.customer.company,
      countryRegion: this.customer.countryRegion,
      currencyCode: this.customer.currencyCode,
      eMail: this.customer.eMail,
      exchange: this.customer.exchange,
      faxNumber: this.customer.faxNumber,
      freightCanada: this.customer.freightCanada,
      freightUsaCanada: this.customer.freightUsaCanada,
      fullAddress: this.customer.fullAddress,
      fullName: this.customer.fullName,
      jobTitle: this.customer.jobTitle,
      marketing: this.customer.marketing,
      mobileNumber: this.customer.mobileNumber,
      orderType: this.customer.orderType,
      paymentTermsTerms: this.customer.paymentTermsTerms,
      stateProvince: this.customer.stateProvince,
      storage: this.customer.storage,
      termsId: this.customer.termsId,
      tollLocalCog: this.customer.tollLocalCog,
      vendorId: this.customer.vendorId,
      webPage: this.customer.webPage,
      zipPostalCode: this.customer.zipPostalCode,
      latitude: this.customer.latitude,
      longitude: this.customer.longitude,
      customerHeadIds: this.customerHeadData
      
    });
  }
  updateCustomerHeads() {
    this.counter++;
    if (this.counter <= this.customerHeadSelected.length) {
      const index = this.counter -1;
      let x = new CustomerHeads();
      x.customerHeadId = 0;
      x.customerId = this.customer.id;
      x.headId = this.customerHeadSelected[index];
      this.customerHeadSvc.create(x).subscribe(data => {
        this.updateCustomerHeads();
      });
    } else {
      window.location.reload();
    }
  }
  deleteCustomerHeads() {
    this.counter++;
    if (this.counter > this.customerTable.length) {
      this.counter = 0;
      this.updateCustomerHeads();
    } else {
      const index = this.counter -1;
      this.customerHeadSvc.remove(this.customerTable[index].customerHeadId).subscribe(data => {
        this.deleteCustomerHeads();
      }, error => {
        this.deleteCustomerHeads();
      })
    }
  }
  getCustomerWeeks() {
    this.router.navigate(['./other/week-customer-list'], { state:
      { 
        customerId: this.customer.id,
      }
    });
  }
  splitNumbers(ids) {
    let ret = [];
    if (ids) {
      let retCount = 0;
      const tempSplit = ids.split(',');
      if (tempSplit.length > 0) {
        tempSplit.forEach( id => {
          if (id) {
            ret.push(id * 1);
          }
        })
      }
    }
    return ret;
  }

  onDelete() {
    if (this.customer.id) {
      this.customerSvc.remove(this.customer.id).subscribe(data => {
        this.router.navigate(['/accounts']);
      });
    }
  }
}
