<div *ngFor="let b of buckets; index as i" class="pdf">
    <div *ngIf="b">
        <div *ngIf="i > firstSelectMonthIndex" class ="break-MONTH"></div>
        <div class="row g-0 mt-2">
            <div class="col-lg-10 pt-2 px-0 fw-bold">
                Customer: {{ customerName }}
            </div>
            <div class="col-lg-10 pt-2 px-0 fw-bold">
                Bucket: {{ bucketSelected }}
            </div>
            <div class="col-lg-4 pt-2 px-0 fw-bold">
                Month {{ monthName[i]}}
            </div>
        </div>

        <div class="row g-0">
            <div class="col-lg-20 px-0 fw-bold">
                Vendor: {{ vendorName }}
            </div>
            <div class="col-lg-4 px-0 fw-bold">
                Year {{ yearSelected }}
            </div>
        </div>
        <div class="row g-0">
            <div class="col-lg-20 px-0 fw-bold">
                Order Type: {{ orderType }}
            </div>    
          </div>        
        <div class="row g-0 mt-2">
            <div class="col-lg-8 pt-2 px-0">
                <label class="fw-bold w-100">
                    INCOMING
                </label>
            </div>
            <div class="col-lg-2 pt-2 px-0">
                <label class="fw-bold w-100 text-center">
                    MONTH
                </label>
            </div>
            <div class="col-lg-6 pt-2 px-0">
                <label class="fw-bold w-100 text-center">
                    {{ monthName[i]}} {{ yearSelected }}
                </label>
            </div>
            <div class="col-lg-4 pt-2 px-0">
                <label class="fw-bold w-100 text-center">
                    BALANCE FORWARD
                </label>
            </div>
            <div class="col-lg-4 pt-2 px-0">
                <label class="fw-bold w-100 text-end" [ngClass]="{'text-danger': balFwdBeginningMonth[i] < 0}">
                    {{ balFwdBeginningMonth[i] | currency }}
                </label>
            </div>
        </div>

        <div class="row g-0 mt-0">
            <table class="w-100">
                <thead class="mt-3 pe-2 border-top border-bottom">
                    <th>Order Status</th>
                    <th>Order Type</th>
                    <th>Sales Order No</th>
                    <th>Purchase Order</th>
                    <th>Shipment Date</th>
                    <th>Customer</th>
                    <th>Ship To</th>
                    <th>Broker</th>
                    <th class="text-end px-1">Vendor PO</th>
                    <th class="text-end px-1">Actual Ex</th>
                    <th class="text-end px-1">FOB Net Return</th>
                    <th class="text-end px-1">Net CND</th>
                    <th class="text-end px-1">FOB Difference Exchange</th>
                    <th class="text-end px-1">Balance</th>
                </thead>
                <tbody>
                    <tr *ngFor="let o of incoming[i]">
                        <td>{{ o.orderStatus }} </td>
                        <td>{{ o.orderType }} </td>
                        <td>{{ o.salesOrderNo }}</td>
                        <td>{{ o.purchaseOrder }}</td>
                        <td>{{ displayDate(o.shipmentDate, '') }}</td>
                        <td>{{ o.billTo }}</td>
                        <td>{{ o.shipTo }}</td>
                        <td>{{ o.broker }}</td>
                        <td class="text-end" [ngClass]="{'text-danger': o.actualVend < 0}">
                            {{ o.actualVend | currency }}
                        </td>
                        <td class="text-end" [ngClass]="{'text-danger': o.actualExchange < 0}">
                            {{ o.actualExchange | number :'1.6' }}
                        </td>
                        <td class="text-end" [ngClass]="{'text-danger': o.actualNet < 0}">
                            {{ o.actualNet | currency }}
                        </td>
                        <td class="text-end" [ngClass]="{'text-danger': o.actualNetCND < 0}">
                            {{ o.actualNetCND | currency }}
                        </td>
                        <td class="text-end" [ngClass]="{'text-danger': o.actualDiff < 0}">
                            {{ o.actualDiff | currency }}
                        </td>
                        <td class="text-end" [ngClass]="{'text-danger': o.balance < 0}">
                            {{ o.balance | currency }}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div class="row g-0 mt-2">
            <div class="col-lg-24 pt-2">
                <label class="fw-bold w-100">
                    TRANSFERRED
                </label>
            </div>
        </div>
        <div class="row g-0 mt-0">
            <table class="w-100">
              <thead class="mt-3 pe-2 border-top border-bottom">
                <th>Date</th>
                <th>Trans No</th>
                <th>Vendor</th>
                <th>Customer</th>
                <th>Notes</th>
                <th>Bucket Transfer</th>
                <th class="text-end">Credit</th>
                <th class="text-end">Debit</th>
                <th class="text-end">Balance</th>
              </thead>
              <tbody>
                  <tr *ngFor="let o of transfering[i]">
                    <td>{{ displayDate(o.transferDate, '') }}</td>
                    <td>{{ o.transferNo }} </td>
                      <td>{{ o.vendor }} </td>
                      <td>{{ o.billTo }}</td>
                      <td>{{ o.notes }}</td>
                      <td>{{ o.fromBucket }} to {{ o.toBucket }}</td>
                      <td class="text-end" [ngClass]="{'text-danger': o.credit < 0}">
                        {{ o.credit | currency }}
                      </td>
                      <td class="text-end" [ngClass]="{'text-danger': o.debit < 0}">
                        {{ o.debit| currency }}
                      </td>
                      <td class="text-end" [ngClass]="{'text-danger': o.balance < 0}">
                        {{ o.balance | currency }}
                      </td>
                  </tr>
              </tbody>
            </table>
          </div>
    
        <div class="row g-0 mt-2">
            <div class="col-lg-4 pt-2 px-0 pdf-bg">
                <label class="text-white w-100 background">
                    TOTAL
                </label>
            </div>
            <div class="col-lg-12 pt-2 px-0 pdf-bg">
                <label class="text-white w-100 background">
                    MONTH: {{ monthName[i]}} {{ yearSelected }}
                </label>
            </div>
            <div class="col-lg-4 pt-2 px-0 pdf-bg">
                <label class="text-white w-100 background text-center">
                    BALANCE FORWARD
                </label>
            </div>
            <div class="col-lg-4 pt-2 px-0 pdf-bg">
                <label class="text-white w-100 background text-end" [ngClass]="{'text-danger': balEndCurrentMonth[i] < 0}">
                    {{ balEndCurrentMonth[i] | currency }}
                </label>
            </div>
        </div>
    </div>
</div>    