import { Component, OnInit, Input } from '@angular/core';
import { Big } from 'big.js';
import { element } from 'protractor';
@Component({
  selector: 'app-packing-slip',
  templateUrl: './packing-slip.component.html',
  styleUrls: ['./packing-slip.component.scss']
})
export class PackingSlipComponent implements OnInit {
  @Input() title: string;
  @Input() order: any;
  @Input() orderItems: any;
  @Input() customer: any;
  @Input() term: any;
  @Input() freightType: any;
  @Input() broker: any;
  @Input() shipTo: any;
  @Input() pickup: any;
  @Input() location1: any;
  @Input() transport1: any;
  @Input() warehouse: any;
  @Input() location2: any;
  @Input() transport2: any;
  @Input() location3: any;
  @Input() transport3: any;
  @Input() vendor: any;
  // @Input() palletType: any;
  totalCases: number;
  totalPallets: number;
  totalGrossLbs: number;
  totalCaseCost: number;
  totalGrosskg: number;
  grayRow = [];
  grayRow2 = [];
  orderItems2 = [];
  bbInfo = [];
  count = 0;
  stripeChar = 'S';
    constructor() { }

  ngOnInit() {
    let stripe = 'S';
    this.totalCases = 0;
    this.totalPallets = 0;
    this.totalGrossLbs = 0;
    this.totalCaseCost = 0;
    this.totalGrosskg = 0;
    const shipped = this.orderItems.some(element => element.shipped > 0);
    this.orderItems.forEach(element => {
      stripe = stripe == 'S' ? '' : 'S';
      this.grayRow.push(stripe);
      const cases = element.cases ? element.cases : 0;
      const casesShipped = element.shipped ? element.shipped : 0;
      const purchaseCaseCost = element.purchaseCaseCost ? element.purchaseCaseCost : 0;
      const freightCs = element.freightCs ? element.freightCs : 0;
      const clearingCs = element.clearingCs ? element.clearingCs : 0;
      const caseCost = purchaseCaseCost + freightCs + clearingCs;
      const extCaseCost = cases * caseCost;
      const csPerPallet = element.csPerPallet ? element.csPerPallet : 0;
      const csGrossLbs = element.csGrossLbs ? element.csGrossLbs : 0;
      const csGrossKg = element.csGrossKg ? element.csGrossKg : 0;
      let pallets = 0;
      const casesToCalc = shipped ? casesShipped : cases;
      if (csPerPallet != 0) {
        const bigNum: any = Big(casesToCalc)
        .div(csPerPallet)
        .round(0, 3);
        pallets = bigNum * 1;
        if (pallets == 0 && casesToCalc !=0 ) {
          pallets = 1;
        }
      }  
      // const obj = {
      //   orderCode: element.orderCode,
      //   brandCategory: element.brandCategory,
      //   itemDescription: element.itemDescription,
      //   size: element.size,
      //   cases: cases,
      //   caseCost: caseCost,
      //   extCaseCost: extCaseCost,
      //   csGrossLbs: element.csGrossLbs,
      //   productType: element.productType,
      //   tiHi: element.tiHi,
      //   csPerPallet: element.csPerPallet,
      //   shipped: element.shipped
      // }
      this.count = 0;
      this.createBBInfo(element.bbDate1, element.lotNo1, element.case1, element);
      this.createBBInfo(element.bbDate2, element.lotNo2, element.case2, element);
      this.createBBInfo(element.bbDate3, element.lotNo3, element.case3, element);
      this.createBBInfo(element.bbDate4, element.lotNo4, element.case4, element);
      this.createBBInfo(element.bbDate5, element.lotNo5, element.case5, element);
      this.createBBInfo(element.bbDate6, element.lotNo6, element.case6, element);
      this.createBBInfo(element.bbDate7, element.lotNo7, element.case7, element);
      this.createBBInfo(element.bbDate8, element.lotNo8, element.case8, element);
      this.createBBInfo(element.bbDate9, element.lotNo9, element.case9, element);
      this.createBBInfo(element.bbDate10, element.lotNo10, element.case10, element);
      // this.orderItems.push(obj);

      // totals
      if (shipped) {
        this.totalCases += casesShipped;
        this.totalGrossLbs += csGrossLbs * casesShipped;
        this.totalGrosskg += csGrossKg * casesShipped;
      } else {
        this.totalCases += cases;
        this.totalGrossLbs += csGrossLbs * cases;
        this.totalGrosskg += csGrossKg * cases;
      }
      this.totalPallets += pallets;
      this.totalCaseCost += extCaseCost;
    });
    this.totalPallets = isNaN(this.order.palletCount) ? 0 : this.order.palletCount;
    const palletLbs = isNaN(this.order.palletGrossLbs) ? 0 : this.order.palletGrossLbs * 1;
    const bigNum: any = Big(palletLbs)
      .times(.453592)
      .round(2);
    const palletKg = bigNum * 1;
    this.totalGrossLbs += this.totalPallets * palletLbs;
    this.totalGrosskg += this.totalPallets * palletKg;

  }

createBBInfo(bbDate: Date, lot: string, cases: number, elem) {
  if (cases) {
    let obj = {
      orderCode: '',
      brandCategory: '',
      itemDescription: '',
      size: '',
      bbDate: bbDate,
      lot: lot,
      case: cases
    }
    if (this.count == 0) {
      this.stripeChar =  this.stripeChar == 'S' ? '' : 'S';
      obj.orderCode = elem.orderCode,
      obj.brandCategory = elem.brandCategory,
      obj.itemDescription = elem.itemDescription,
      obj.size = elem.size
    }
    this.orderItems2.push(obj);
    this.grayRow2.push(this.stripeChar);
    this.count++;
  }
}
  // displayDate(dt, t) {
    
  //   if (dt) {
  //     if (t === undefined) {
  //       t = '';
  //     }
  //     let x = new Date(dt);
  //     if (t == 'T') {
  //       return x.toLocaleDateString() + ' @ ' + x.toLocaleTimeString();
  //     }
  //     return x.toLocaleDateString();
  //   } 
  //   return dt
  // }
  displayDate(dt, t) {
    if (dt === undefined) {
      return null;
    }
    if (dt) {
      if (t === undefined) {
        t = '';
      }
      if (dt.indexOf('T') == -1) {
        dt += 'T00:00';
      }
      let x = new Date(dt);
      if (t == 'T') {
        return x.toLocaleDateString() + ' @ ' + x.toLocaleTimeString();
      }
      return x.toLocaleDateString();
    } 
    return dt
  }
}
