import { Component, OnInit } from '@angular/core';
import { OrderService } from '../../services/order.service';
import { Router, ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-shipped-list',
  templateUrl: './shipped-list.component.html',
  styleUrls: ['./shipped-list.component.scss']
})
export class ShippedListComponent implements OnInit {
  orders: any;
  page = 1;
  pageSize = 100;
  constructor(private router: Router,
    private orderService: OrderService,
    private activatedRoute: ActivatedRoute) { }

  ngOnInit() {
    this.orderService.getShipped().subscribe( order => {
      this.orders = order;
    });
  }
  onRowClicked(order) {
    this.router.navigate(['/sales/invoicing-edit',  order.id]);
  }
  displayDate(dt, t) {
    
    if (dt) {
      if (t === undefined) {
        t = '';
      }
      let x = new Date(dt);
      if (t == 'T') {
        return x.toLocaleDateString() + ' @ ' + x.toLocaleTimeString();
      }
      return x.toLocaleDateString();
    } 
    return dt
  }
}
