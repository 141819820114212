import { Component, OnInit } from '@angular/core';
import { ProductService } from '../../../../services/product.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-costing-bucket-setup-list',
  templateUrl: './costing-bucket-setup-list.component.html',
  styleUrls: ['./costing-bucket-setup-list.component.scss']
})
export class CostingBucketSetupListComponent implements OnInit {
  products: any;
  page = 1;
  pageSize = 100;
  productsOriginal: any;
  brandFilter = [];
  categoryFilter = [];
  costingFilter = [];
  costTypeFilter = [];
  groupFilter = [];
  brand = '';
  category = '';
  costing = '';
  costType = '';
  group = '';
  constructor(private router: Router,
    private productService: ProductService) { }

  ngOnInit() {
    this.productService.getItemOrder().subscribe(prod => {
      this.products = prod;
      this.productsOriginal = prod;
      this.resetFilters();
    });
  }
  getDefaultDataScreen() {
    this.brand = '';
    this.category = '';
    this.costing = '';
    this.costType = '';
    this.group = '';
    this.resetFilters();
  }
  resetFilters() {
    let pointsNeeded = 0;
    
    pointsNeeded += this.brand ? 1 : 0;
    pointsNeeded += this.category ? 1 : 0;
    pointsNeeded += this.costing ? 1 : 0;
    pointsNeeded += this.costType ? 1 : 0;
    pointsNeeded += this.group? 1 : 0;

    this.products = [];
    let firstTime = true;
    this.productsOriginal.forEach( prod => {
      let pointsCounted = 0;
      if (this.brand === prod.brand) {
        pointsCounted++;
      }
      if (this.category === prod.category) {
        pointsCounted++;
      }

      if (this.costing === prod.activeListings) {
        pointsCounted++;
      }

      if (this.costType === prod.costType) {
        pointsCounted++;
      }

      if (this.group === prod.productGroupNo) {
        pointsCounted++;
      }

      if (pointsCounted >= pointsNeeded) {
        this.products.push(prod);
        if (this.brand == '') {
          this.brandFilter = firstTime ? [] : this.brandFilter;
          if (prod.brand && this.brandFilter.indexOf(prod.brand) == -1) {
            this.brandFilter.push(prod.brand);
          }
        }
  
        if (this.category == '') {
          this.categoryFilter = firstTime ? [] : this.categoryFilter;
          if (prod.category && this.categoryFilter.indexOf(prod.category) == -1) {
            this.categoryFilter.push(prod.category);
          }
        }
  
        if (this.costing == '') {
          this.costingFilter = firstTime ? [] : this.costingFilter;
          if (prod.activeListings && this.costingFilter.indexOf(prod.activeListings) == -1) {
            this.costingFilter.push(prod.activeListings);
          }
        }

        if (this.costType == '') {
          this.costTypeFilter = firstTime ? [] : this.costTypeFilter;
          if (prod.costType && this.costTypeFilter.indexOf(prod.costType) == -1) {
            this.costTypeFilter.push(prod.costType);
          }
        }

        if (this.group == '') {
          this.groupFilter = firstTime ? [] : this.groupFilter;
          if (prod.productGroupNo && this.groupFilter.indexOf(prod.productGroupNo) == -1) {
            this.groupFilter.push(prod.productGroupNo);
          }
        }
        firstTime = false;      
      }
    })
  }
  formatValue(x) {
    if (x == null || 0) {
      let x = 0;
      return x.toFixed(2);
    } else {
      return x.toFixed(2);
    }
  }  
  getSum(a, b) {
    if (a == 0 && b == 0 || a == null && b == null) {
      return 0;
    } else {
      const overall = a + b;
      return overall;
    }
  }
  displayDate(dt, t) {
    if (dt) {
      if (t === undefined) {
        t = '';
      }
      if (dt.indexOf('T') == -1) {
        dt += 'T00:00';
      }
      let x = new Date(dt);
      if (t == 'T') {
        return x.toLocaleDateString() + ' @ ' + x.toLocaleTimeString();
      }
      return x.toLocaleDateString();
    } 
    return dt
  }
  onRowClicked(product) {
    this.router.navigate(['/products/costing-bucket-setup-edit',  product.id]);
  }
}
