import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { OrderService } from 'src/app/services/order.service';
import { VendorData } from 'src/app/models-azure/vendor-data.model';
import { DataLink } from 'src/app/models/data-link.model';
import { DataLinkService } from 'src/app/services/data-link.service';
import { VendorService } from 'src/app/services/vendor.service';
import { Vendor } from 'src/app/models/vendor-model';
import { WeekKalcorService } from 'src/app/services/week-kalcor.service';
import { AzureService } from 'src/app/services-azure/azure.service';
import { ProjectionsService } from 'src/app/services/projections.service';
import { ShipToAddressService } from 'src/app/services/ship-to-address.service';
import { BrokerService } from 'src/app/services/broker.service';
import { Broker } from 'src/app/models/broker.model';
import { CustomerRetailService } from 'src/app/services/customer-retail.service';
import { ShipToBrokerService } from 'src/app/services/ship-to-broker.service';
import { ShipToBroker } from 'src/app/models/shipto-broker';
import { Big } from 'big.js';
import { Transfer, Incoming } from 'src/app/models/bucket.model';
import { ShipToAddress } from 'src/app/models/shipto.model';
import { BrokerPayment } from 'src/app/models/broker.model';
import { CommissionData } from 'src/app/models-azure/commission-data.model';
import { CommissionService } from 'src/app/services-azure/commission.service';
import { ExchangeData } from 'src/app/models-azure/exchange-data.model';
import { ExchangeService } from 'src/app/services-azure/exchange.service';
import { FreightCndData } from 'src/app/models-azure/freight-cnd-data.model';
import { FreightCndService } from 'src/app/services-azure/freight-cnd.service';
import { KalcorData } from 'src/app/models-azure/kalcor-data.model';
import { KalcorService } from 'src/app/services-azure/kalcor.service';
import { MarketingData } from 'src/app/models-azure/marketing-data.model';
import { MarketingService } from 'src/app/services-azure/marketing.service';
import { VendorReportData } from 'src/app/models-azure/vendor-report-data.model';
import { VendorReportService } from 'src/app/services-azure/vendor-report.service';


@Component({
  selector: 'app-all-data',
  templateUrl: './all-data.component.html',
  styleUrls: ['./all-data.component.scss']
})
export class AllDataComponent implements OnInit {
  buttonLabel = 'Add more Vendors';
  year = [];
  vendorYear = [];
  items = [];
  sales = [];
  buttonClicker = [];
  buttonRemover = [];
  selectedVendor: number;
  processIndexItem: number;
  processIndex: number;
  processIndexCases: number;
  vendorId: number;
  totalCount: number;
  processIndexSales: number;
  processIndexSalesItems: number;
  processIndexBudget: number;
  processIndexShip: number;
  processIndexPeriod: number;
  dataLink: DataLink;
  vendor: Vendor[];
  dataLinks: DataLink[];
  yearRequested: any;
  processVendIndex: number;
  page: number;
  pageSize = 35;
  count: number;
  periods = [];
  kstLineCount = 0;
  kstTotalExtension = 0;
  projection: any;
  shipTos = [];
  shipToSelected = [];
  shipToOptions = [];
  brokerOriginal: Broker[];
  shipToBrokerOriginal: ShipToBroker[];
  regionShipTo = [];
  regionBanner = [];
  stores = [];
  currentPeriod: number;
  spinner = [];
  //report variables
  processIndexIncoming: number;
  processIndexIncomingItems: number;
  processIndexOutgoing: number;
  processIndexOutgoingItems: number;
  processIndexTransfering: number;
  processIndexTransferingItems: number;
  processIndexPaying: number;
  processIndexPayingItems: number;
  processIndexStock: number;
  processIndexStockItems: number;
  firstPost = true;
  itemCount: number;
  order: any;
  orderType = 'Both Retail/Food Service';
  bucket = '';
  report = 'Actual';
  monthSelected: any;
  brokerSelected: number;
  new: any;
  balfwd: number;
  balanceForwardCredit: number;
  balanceForwardActual: number;
  balanceForwardBudget: number;
  balanceForwardTransfer: number;
  balanceForwardPayment: number;
  creditData: any;
  balFwdBeginningMonth = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  balFwdAfterIncomingMonth = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  balFwdOutgoingMonth = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  balFwdTransferingMonth = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  balEndCurrentMonth = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  incoming = [[],[],[],[],[],[],[],[],[],[],[],[],[],[]];
  outgoing = [[],[],[],[],[],[],[],[],[],[],[],[],[],[]];
  transfering = [[],[],[],[],[],[],[],[],[],[],[],[],[],[]];
  paying = [[],[],[],[],[],[],[],[],[],[],[],[],[],[]];
  transfer: Transfer[];
  periodName = '';
  shipTo: ShipToAddress[];
  brokers: Broker[];
  payment: BrokerPayment[];
  monthName = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];
  bucketIndex = [
    { Bucket: 'Billing', index: -1 },
    { Bucket: 'Terms', index: -1 },
    { Bucket: 'Marketing', index: -1 },
    { Bucket: 'Commission', index: -1 },
    { Bucket: 'Kalcor', index: -1 },
    { Bucket: 'Freight CND', index: -1 },
    { Bucket: 'Storage', index: -1 },
    { Bucket: 'Exchange', index: 0 },
    { Bucket: 'Clearing', index: -1 },
    { Bucket: 'Duty', index: -1 },
    { Bucket: 'Freight USD', index: -1 }
  ]
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private orderService: OrderService,
    private dataLinkService: DataLinkService,
    private vendorService: VendorService,
    private weekKalcorService: WeekKalcorService,
    private shipToService: ShipToAddressService,
    private bannerService: CustomerRetailService,
    private brokerService: BrokerService,
    private shipToBrokerService: ShipToBrokerService,
    private azureService: AzureService,
    private projectionsService: ProjectionsService,
    private commissionService: CommissionService,
    private exchangeService: ExchangeService,
    private freightCndService: FreightCndService,
    private kalcorService: KalcorService,
    private marketingService: MarketingService,
    private vendorReportService: VendorReportService
  ) {}

  ngOnInit(): void {
    this.periods = [];
    this.currentPeriod = 0;
    this.yearRequested = this.route.snapshot.params['year'];
    this.weekKalcorService.getYear(this.yearRequested).subscribe(data => {
      this.periods = data;
    })
    this.route.data.subscribe(data => {
      this.vendorYear = data.dataList.item;
      this.page = data.dataList.page;
      this.count = data.dataList.count;
      for (let i = 0; i < this.vendorYear.length; i++) {
        this.buttonClicker.push(false);
      }
    })
    this.vendorService.getCompany().subscribe(vend => {
      this.vendor = vend;
      this.brokerService.getAll().subscribe(br => {
        this.brokerOriginal = br;
        this.brokers = br;
        this.shipToService.getAll().subscribe(ship => {
          this.shipTo = ship;
        })
      })
    })
  }
  loadPage() {
    this.dataLinkService.getList(this.yearRequested, this.page, this.pageSize).subscribe(data => {
      this.vendorYear = data.item;
      this.page = data.page;
      this.count = data.count;
  
      for (let i = 0; i < this.vendorYear.length; i++) {
        this.buttonClicker.push(false);
      }
  
    });
  }
  run(index: number) {
    this.totalCount = 0;
    this.processIndex = index;
    this.selectedVendor = index;
    this.vendorId = this.vendorYear[this.selectedVendor].vendorId;
    this.bucket = "Sales";
    this.getItemsSales()
  }
  getItemsSales() {
    this.azureService.getFilter(this.yearRequested, this.vendorId, 0, 0).subscribe(data => {
      this.items = data.item;
      if (this.items.length > 0) {
        this.processIndexItem = -1;
        this.deleteItemsSales();
      } else {
        this.getSalesData();
      }
    }, error => {
      alert('Error from azzure:' + error.message)
    }) 
  }
  deleteItemsSales() {
    this.vendorYear[this.processIndex].status = "Deleting" + "Sales/Budget";
    this.totalCount++;
    this.vendorYear[this.processIndex].counter = this.totalCount;
    this.processIndexItem++;
    if (this.processIndexItem < this.items.length) {
      this.azureService.remove(this.items[this.processIndexItem].id).subscribe(data => {
        this.deleteItemsSales();
      }, error => {
        alert('Error received from Azure: ' + error.message);
      })
    } else {
      this.getItemsSales();
    }
  }
  getSalesData() {
    this.vendorYear[this.processIndex].status = "Updating" + " " + this.bucket;
    this.totalCount = 0;
    this.processIndexSales = -1;
      //get only sales
    this.orderService.getDetailsSP(this.vendorYear[this.processIndex].salesYear, this.vendorYear[this.processIndex].vendorId).subscribe(data => {
      this.sales = data.order;
      this.kstLineCount = 0;
      this.kstTotalExtension = 0;
      this.sales.forEach(item => {
        item.items.forEach(itm => {
          itm.cases = itm.cases === null ? 0 : itm.cases;
            itm.invoiced = itm.invoiced === null ? 0 : itm.invoiced;
            itm.caseCost = itm.caseCost === null ? 0 : itm.caseCost;
  
            if (item.orderStatus == 'Sales Order' || item.orderStatus == 'Shipped') {                     
              itm.extCaseCost = itm.caseCost * itm.cases;
            } else {           
              itm.extCaseCost = itm.caseCost * itm.invoiced;
            }
  
            const ext = itm.extCaseCost === null ? 0 : itm.extCaseCost;
            this.kstTotalExtension += ext;
            this.kstLineCount += ext > 0 ? 1 : 0;
        });
      })
      this.totalCount = 0;
      this.processIndexSales = -1;
      this.processIndexSalesItems = -1;
      this.insertAzureSales();
    })
  }
  insertAzureSales() {
    this.processIndexSales++;
    if (this.processIndexSales >= this.sales.length) {
      this.updateDataLink();
      this.getBudgetData();
    } else {
      this.processIndexSalesItems = -1;
      this.insertAzureSalesItems();
    }
  }
  insertAzureSalesItems() {
    this.processIndexSalesItems++;
    if (this.processIndexSalesItems >= this.sales[this.processIndexSales].items.length) {
      //process next sales
      this.insertAzureSales();
    } else {
      const cases = this.sales[this.processIndexSales].items[this.processIndexSalesItems].cases;
      const casesInvoiced = this.sales[this.processIndexSales].items[this.processIndexSalesItems].invoiced;
      if (cases == 0 && casesInvoiced == 0) {
        this.insertAzureSalesItems();
      } else {
        //process each items
      this.totalCount++;
      this.vendorYear[this.processIndex].counter = this.totalCount;
      let x = new VendorData();
      x.id = 0;
      x.orderType = this.sales[this.processIndexSales].orderType;
      x.salesOrderNo = this.sales[this.processIndexSales].salesOrderNo;
      x.purchaseOrder = this.sales[this.processIndexSales].purchaseOrder;
      x.orderStatus = this.sales[this.processIndexSales].orderStatus;
      x.vendor = this.sales[this.processIndexSales].vendor;
      x.brand = this.sales[this.processIndexSales].items[this.processIndexSalesItems].brand;
      x.category = this.sales[this.processIndexSales].items[this.processIndexSalesItems].category;
      x.orderCode = this.sales[this.processIndexSales].items[this.processIndexSalesItems].orderCode;
      x.itemDescription = this.sales[this.processIndexSales].items[this.processIndexSalesItems].itemDescription;
      x.size = this.sales[this.processIndexSales].items[this.processIndexSalesItems].size;
      x.fullItemDescription = this.getValue('fullItemDescription');
      x.period = this.getValue('periodYear');
      x.year = this.getValue('year');
      x.cases = this.sales[this.processIndexSales].items[this.processIndexSalesItems].cases;
      x.casesInvoiced = this.sales[this.processIndexSales].items[this.processIndexSalesItems].invoiced;
      x.caseCost = this.sales[this.processIndexSales].items[this.processIndexSalesItems].caseCost;
      x.extCaseCost = this.sales[this.processIndexSales].items[this.processIndexSalesItems].extCaseCost;
      x.month = this.getValue('monthYear');
      x.shipmentDate = this.sales[this.processIndexSales].shipmentDate;
      x.salesManager = this.sales[this.processIndexSales].salesManager;
      x.typeOfAccount = this.sales[this.processIndexSales].typeOfAccount1;
      x.dotLocation = this.sales[this.processIndexSales].dotLocation;
      x.customer = this.sales[this.processIndexSales].billTo;
      x.cLatitude = this.sales[this.processIndexSales].latitudeC;
      x.cLongitude = this.sales[this.processIndexSales].longitudeC;
      x.customerZip = this.sales[this.processIndexSales].billZipPostalCode;
      x.shipTo = this.sales[this.processIndexSales].shipTo;
      x.sLatitude = this.sales[this.processIndexSales].latitudeS;
      x.sLongitude = this.sales[this.processIndexSales].longitudeS;
      x.shipToZip = this.sales[this.processIndexSales].shipZipPostalCode;
      // x.broker = this.getValue('broker');
      x.broker = this.sales[this.processIndexSales].broker;
      x.broker2 = this.sales[this.processIndexSales].broker2;
      x.vendorId = this.sales[this.processIndexSales].vendorId;
      this.azureService.create(x).subscribe(data => {
        this.insertAzureSalesItems();
      }
        )
      }
      
    }
  }
  getValue(variable) {
    let retValue;
      let shipDate = new Date(this.sales[this.processIndexSales].shipmentDate);
      switch (variable) {
        case "broker":
          let broker = this.sales[this.processIndexSales].broker ? this.sales[this.processIndexSales].broker : "";
          let broker2 = this.sales[this.processIndexSales].broker2 ? this.sales[this.processIndexSales].broker2 : "";
          if (broker) {
            if (broker2) {
              broker += " / " + broker2;
            }
          } else {
            broker = broker2;
          }
          retValue = broker;
          break;
        case "year":
            retValue = shipDate.getFullYear() + '';
            break;
        case "monthYear":
            const month = ["JAN","FEB","MAR","APR","MAY","JUN","JUL","AUG","SEP","OCT","NOV","DEC"];
            const shipMonth = shipDate.getMonth(); // 0 to 11
            const shipMonthName = month[shipMonth];
            let monthYear = "0" + (shipMonth + 1);
            monthYear = monthYear.slice(-2) + "-" + month[shipMonth] + "-" + shipDate.getFullYear();
            retValue = monthYear;
            break;
          
          case "periodYear":
              retValue = '';
              const shippedDate = this.sales[this.processIndexSales].shipmentDate.substring(0, 10);
              for (let i = 0; i < this.periods.length; i++) {
                if (shippedDate >= this.periods[i].weekStartDate && shippedDate <= this.periods[i].weekEndDate) { 
                  let periodYear = "0" + this.periods[i].period + "-PERIOD";             
                  if (this.periods[i].period > 9) {
                    periodYear = this.periods[i].period + "-PERIOD";
                  } 
                  retValue = periodYear;
                  break;
                }
              }
              break;  
            case "periodWeek":
              retValue = '';
              const shippingDate = this.sales[this.processIndexSales].shipmentDate.substring(0, 10);
              for (let i = 0; i < this.periods.length; i++) {
                if (shippingDate >= this.periods[i].weekStartDate && shippingDate <= this.periods[i].weekEndDate) { 
                  let periodWeek = this.periods[i].week;             
                  retValue = periodWeek;
                  break;
                }
              }
              break;  
          case "fullItemDescription":
            retValue = this.sales[this.processIndexSales].items[this.processIndexSalesItems].itemDescription + ' ' 
                        + this.sales[this.processIndexSales].items[this.processIndexSalesItems].size;
          break;
  
        default:
          retValue = this.sales[this.processIndexSales][variable];
          // if (variable.indexOf('latitude') > -1 || variable.indexOf('longitude') > -1) {
          //   retValue = retValue === null ? retValue : retValue + '';
          // }
          break;
      }
      return retValue;
  }
  getBudgetData() {
    this.bucket = 'Budget';
    this.vendorYear[this.processIndex].status = "Updating"  + " " + this.bucket;
    this.totalCount = 0;
    this.processIndexBudget = -1;
    this.projection = [];
    this.projectionsService.getYearBudget(this.vendorId, this.yearRequested).subscribe(projData => {
      this.projection = projData;
      this.kstLineCount = 0;
      this.kstTotalExtension = 0;
      this.initializeAzureBudget();
    })
  }
  initializeAzureBudget() {
    this.processIndexBudget++;
    if (this.processIndexBudget >= this.projection.length) {
      this.updateDataLinkBudget();
      //budget has been updated to azure begin running comission report to azure
      this.runCommission();
    } else {
      //clear arrays to process next projection by bannerId
      this.regionBanner = [];
      this.stores = [];
      this.shipToBrokerOriginal = [];
      this.shipToOptions = [];
      this.shipToSelected = [];
      this.processIndexCases = 0;
      const bannerId = this.projection[this.processIndexBudget].bannerId * 1;
      const shipToIds = this.projection[this.processIndexBudget].shipToIds;
      this.bannerService.getRegion(bannerId).subscribe(data => {
        data.forEach(e => {
          const bannerRegion = {
            bannerRegion: e.bannerRegion,
            noOfStores: e.noOfStores
          }
          this.regionBanner.push(bannerRegion);
        });  
        this.regionShipTo = [];
        this.shipTos = shipToIds.split(',');
        this.processIndexShip = -1;
        this.getShipTos();
      })
    }
  }
  getShipTos() {
    this.processIndexShip++;
    if (this.processIndexShip >= this.shipTos.length) {
      this.processIndexShip = -1;
      this.processEachShipTo();
    } else {
      const shipId = this.shipTos[this.processIndexShip] * 1;
      this.shipToService.getFilteredListing('Active', shipId, 0, 1, 999).subscribe(data => {
        this.shipToOptions = data.item;
        this.compareRegions();
        this.shipToBrokerService.getList(this.projection[this.processIndexBudget].listingId).subscribe(s => {
          this.shipToBrokerOriginal = s;
        this.getBrokers();
        this.getShipTos();
        })
      })
    }
  }
  compareRegions() {
    this.shipToOptions.forEach(e => {
      const bannerIndex = this.regionBanner.findIndex(f => f.bannerRegion == e.shipRegion);
      if (bannerIndex > -1) {
        const indexStores = this.stores.findIndex(f => f.shipId == e.id);
        if (indexStores == -1) {
          const x = {
            shipId: e.id,
            noStores: this.regionBanner[bannerIndex].noOfStores
          }
          this.stores.push(x);
        } else {
          this.stores[indexStores].noStores += this.regionBanner[bannerIndex].noOfStores;
        }
        const index = this.regionShipTo.findIndex(f => f.id == e.id && f.shipRegion == e.shipRegion);
        if (index == -1) {
          const shipIndex = this.shipToSelected.findIndex(f => f.id == e.id);
          if (shipIndex == -1) {
            this.shipToSelected.push(e);        
          }
        }
  
      }
    })
  }
  getBrokers() {
    this.shipToSelected.forEach(ship => {
      const shipToBrokerIndex = this.shipToBrokerOriginal.findIndex(f => f.shipToId == ship.id);
      let broker1 = null;
      let broker2 = null;
      if (shipToBrokerIndex > -1) {
        broker1 = this.shipToBrokerOriginal[shipToBrokerIndex].brokerId;
        broker2 = this.shipToBrokerOriginal[shipToBrokerIndex].broker2Id;
      }
      const broker1Index = this.brokerOriginal.findIndex(f => f.id == broker1);
      if (broker1Index > -1) {
        broker1 = this.brokerOriginal[broker1Index].company;
      }
      const broker2Index = this.brokerOriginal.findIndex(f => f.id == broker2);
      if (broker2Index > -1) {
        broker2 = this.brokerOriginal[broker2Index].company;
      }
      //7.10.24 only displaying one broker at this time 
      // if (broker1) {
      //   if (broker2) {
      //     broker1 += " / " + broker2;
      //   }
      // } else {
      //   broker1 = broker2;
      // }
      this.projection[this.processIndexBudget].broker = broker1;
      this.projection[this.processIndexBudget].broker2 = broker2;
    })
  }
  processEachShipTo() {
    this.processIndexShip++;
    //process one ship to a time
    if (this.processIndexShip >= this.shipToSelected.length) {
      this.initializeAzureBudget();
    } else {
      this.processIndexPeriod = -1;
      this.insertAzureBudget();
    }
  }
  insertAzureBudget() {
    this.processIndexPeriod++;
    if (this.processIndexPeriod >= this.periods.length) {
      //process next ship to for this banner
      this.processEachShipTo();
    } else {
      //avoid duplicating periods
      if (this.currentPeriod == this.periods[this.processIndexPeriod].period) {
        this.insertAzureBudget();
        return;
      }
      //get cases by week
      const tempWeeks = [this.projection[this.processIndexBudget].week1, this.projection[this.processIndexBudget].week2, this.projection[this.processIndexBudget].week3, this.projection[this.processIndexBudget].week4, this.projection[this.processIndexBudget].week5, this.projection[this.processIndexBudget].week6, this.projection[this.processIndexBudget].week7, this.projection[this.processIndexBudget].week8, this.projection[this.processIndexBudget].week9, this.projection[this.processIndexBudget].week10,
      this.projection[this.processIndexBudget].week11, this.projection[this.processIndexBudget].week12, this.projection[this.processIndexBudget].week13, this.projection[this.processIndexBudget].week14, this.projection[this.processIndexBudget].week15, this.projection[this.processIndexBudget].week16, this.projection[this.processIndexBudget].week17, this.projection[this.processIndexBudget].week18, this.projection[this.processIndexBudget].week19, this.projection[this.processIndexBudget].week20,
      this.projection[this.processIndexBudget].week21, this.projection[this.processIndexBudget].week22, this.projection[this.processIndexBudget].week23, this.projection[this.processIndexBudget].week24, this.projection[this.processIndexBudget].week25, this.projection[this.processIndexBudget].week26, this.projection[this.processIndexBudget].week27, this.projection[this.processIndexBudget].week28, this.projection[this.processIndexBudget].week29, this.projection[this.processIndexBudget].week30,
      this.projection[this.processIndexBudget].week31, this.projection[this.processIndexBudget].week32, this.projection[this.processIndexBudget].week33, this.projection[this.processIndexBudget].week34, this.projection[this.processIndexBudget].week35, this.projection[this.processIndexBudget].week36, this.projection[this.processIndexBudget].week37, this.projection[this.processIndexBudget].week38, this.projection[this.processIndexBudget].week39, this.projection[this.processIndexBudget].week40,
      this.projection[this.processIndexBudget].week41, this.projection[this.processIndexBudget].week42, this.projection[this.processIndexBudget].week43, this.projection[this.processIndexBudget].week44, this.projection[this.processIndexBudget].week45, this.projection[this.processIndexBudget].week46, this.projection[this.processIndexBudget].week47, this.projection[this.processIndexBudget].week48, this.projection[this.processIndexBudget].week49, this.projection[this.processIndexBudget].week50,
      this.projection[this.processIndexBudget].week51, this.projection[this.processIndexBudget].week52];   
      let cases = 0;
      //group weeks into periods
      for (let i = 0; i < this.periods.length; i++) {
        if (this.periods[i].period != 0) {
          if (this.periods[i].period == this.periods[this.processIndexPeriod].period) {
            let casesPerWeek = tempWeeks[i];
            //calculate cases by stores
            if (casesPerWeek > 0) {
              const storeIndex = this.stores.findIndex(f => f.shipId == this.shipToSelected[this.processIndexShip].id);
              if (storeIndex > -1) {
                const noStores = this.projection[this.processIndexBudget].noStores * 1;
                const noStoresIndex = this.stores[storeIndex].noStores * 1;
                if (casesPerWeek < noStores) {
                  const bigNum: any = Big(casesPerWeek)
                  .div(noStores)
                  .round(2);
                  const casesWeek = bigNum * 1;
    
                  const bigNum2: any = Big(casesWeek)
                  .times(noStoresIndex)
                  .round(0);
                  cases += bigNum2 * 1;
    
                } else {
                  const bigNum: any = Big(casesPerWeek)
                  .div(noStores)
                  .round(0);
                  const casesWeek = bigNum * 1;
    
                  const bigNum2: any = Big(casesWeek)
                  .times(noStoresIndex)
                  .round(0);
                  cases += bigNum2 * 1;
                }
              }
            }
            //adjust period naming
            this.currentPeriod = this.periods[i].period;
            let periodYear = "0" + this.periods[i].period + "-PERIOD";
            if (this.periods[i].period > 9) {
              periodYear = this.periods[i].period + "-PERIOD";
            }
            this.projection[this.processIndexBudget].period = periodYear;
          }
        }
      }
      //skip creating records where cases equal 0
      if (cases == 0) {
        this.insertAzureBudget();
      } else {
        //process each budget by banner
        this.totalCount++;
        this.vendorYear[this.processIndex].counter = this.totalCount;
        
        this.processIndexCases = cases;
  
        let x = new VendorData();
        x.id = 0;
        x.vendor = this.projection[this.processIndexBudget].vendor;
        x.vendorId = this.projection[this.processIndexBudget].vendorId;
        x.banner = this.projection[this.processIndexBudget].banner;
        x.headOffice = this.projection[this.processIndexBudget].customerEnd;
        x.brand = this.projection[this.processIndexBudget].brand;
        x.category = this.projection[this.processIndexBudget].category;
        x.orderType = this.refineValue('orderType');
        x.orderStatus = x.orderType;
        x.orderCode = this.projection[this.processIndexBudget].productOrderCode;
        x.itemDescription = this.projection[this.processIndexBudget].itemDescription;
        x.size = this.projection[this.processIndexBudget].size;
        x.fullItemDescription = this.refineValue('fullItemDescription');
        x.year = this.projection[this.processIndexBudget].year;
        x.period = this.projection[this.processIndexBudget].period;
        x.casesBudget = cases;
        x.caseCost = this.projection[this.processIndexBudget].caseCost;
        x.extCaseCostBudget = this.refineValue('extCaseCostBudget');
        x.salesManager = this.projection[this.processIndexBudget].salesManager;
        x.typeOfAccount = this.projection[this.processIndexBudget].typeOfAccount;
        x.dotLocation = this.shipToSelected[this.processIndexShip].location;
        x.customer = this.projection[this.processIndexBudget].customer;
        x.cLatitude = this.projection[this.processIndexBudget].clatitude;
        x.cLongitude = this.projection[this.processIndexBudget].clongitude;
        x.customerZip = this.projection[this.processIndexBudget].czip;
        x.budgetType = this.projection[this.processIndexBudget].typeOfPromotion;
        x.shipTo = this.shipToSelected[this.processIndexShip].company;
        x.shipToZip = this.shipToSelected[this.processIndexShip].zipPostalCode;
        x.broker = this.projection[this.processIndexBudget].broker;
        x.broker2 = this.projection[this.processIndexBudget].broker2;
  
        const ext = x.extCaseCostBudget === null ? 0 : x.extCaseCostBudget;
        this.kstTotalExtension += ext;
        this.kstLineCount += ext > 0 ? 1 : 0;
  
        this.azureService.create(x).subscribe(data => {
          this.insertAzureBudget();
        })
      }
    }
  }
  refineValue(variable) {
    let retValue;
      switch (variable) {
          case "orderType":
          if (this.projection[this.processIndexBudget].typeOfPromotion == 'Budget') {
            retValue = "Budget";
          } 
          if (this.projection[this.processIndexBudget].typeOfPromotion == 'Budget Promotion') {
            retValue = "Budget Promotion";
          }
          if (this.projection[this.processIndexBudget].typeOfPromotion == 'Budget Contingency') {
            retValue = "Budget Contingency"
          }
          break;
        case "extCaseCostBudget":
          const caseCost = this.projection[this.processIndexBudget].caseCost;
          const cases = this.processIndexCases;
          this.projection[this.processIndexBudget].caseCost * this.processIndexCases;
          const bigNum: any = Big(caseCost)
                  .times(cases)
                  .round(2);
                  retValue = bigNum * 1;
          break;
          case "fullItemDescription":
            retValue =  this.projection[this.processIndexBudget].itemDescription + ' ' 
                        + this.projection[this.processIndexBudget].size;
          break;
      }
      return retValue;
  }

  runCommission(){
    this.monthSelected = [];
    this.monthSelected.push("1");
    this.monthSelected.push("12");
    this.totalCount = 0;
    this.firstPost = true;
    this.items = [];
    this.bucket = 'Commission';
    this.getItemsCommission();
  }
  getItemsCommission() {
    this.commissionService.getFilter(this.yearRequested, this.vendorId, 0, 0).subscribe(data => {
      this.items = data.item;
      if (this.items.length > 0) {
        this.processIndexItem = -1;
        this.deleteItemsCommission();
      } else {
        this.getCommissionData();
      }
    }, error => {
      alert('Error from azzure:' + error.message)
    }) 
  }
  getCommissionData() {
    this.vendorYear[this.processIndex].status = "Updating" + " " + this.bucket;
    this.totalCount = 0;
    this.balfwd = 0;
    this.brokerSelected = 0;
    this.incoming = [[],[],[],[],[],[],[],[],[],[],[],[],[],[]];
    this.outgoing = [[],[],[],[],[],[],[],[],[],[],[],[],[],[]];
    this.transfering = [[],[],[],[],[],[],[],[],[],[],[],[],[],[]];
    this.paying = [[],[],[],[],[],[],[],[],[],[],[],[],[],[]];
    this.orderService.getBucketList(this.vendorYear[this.processIndex].salesYear, this.monthSelected, this.vendorYear[this.processIndex].vendorId, 0, 0, this.bucket, this.report, this.orderType).subscribe(data => {
      this.order = data.order; 
      this.creditData = data.creditData;
      this.transfer = data.transferData;
      this.payment = data.paymentData;
      this.balanceForwardCredit = data.balanceForwardCredit;
      this.balanceForwardActual = data.balanceForwardActual;
      this.balanceForwardBudget = data.balanceForwardBudget;
      this.balanceForwardTransfer = data.balanceForwardTransfer;
      this.balanceForwardPayment = data.balanceForwardPayment;
      const balance = this.computeBalFwdCommission();
      this.balfwd = balance;
      // this.balfwd = this.balanceForwardBudget - this.balanceForwardCredit + this.balanceForwardTransfer - this.balanceForwardPayment;
      this.loadArraysCommission();
      this.totalCount = 0;
      this.processIndexIncoming = -1;
      this.processIndexIncomingItems = -1;
      this.processIndexOutgoing = -1;
      this.processIndexOutgoingItems = -1;
      this.processIndexTransfering = -1;
      this.processIndexTransferingItems = -1;
      this.processIndexPaying = -1;
      this.processIndexPayingItems = -1;
      this.kstLineCount = 0;
      this.kstTotalExtension = 0;
      if (this.firstPost == true) {
        this.insertBalFwdCommission();
        this.firstPost = false;
      } 
      this.initializeIncomingCommission();
    })
  }
  computeBalFwdCommission() {
    let bigNum: any;
    bigNum = Big(this.balanceForwardBudget)
    .minus(this.balanceForwardCredit)
    .plus(this.balanceForwardTransfer)
    .minus(this.balanceForwardPayment)
    .round(2)
    return bigNum * 1;
  }
  insertBalFwdCommission() {
    const year = this.vendorYear[this.processIndex].salesYear;
    let firstDate = new Date(Date.UTC(year, 0, 2, 0, 0, 0));
    let dateFormat = firstDate.toISOString();
    let x = new CommissionData();
    x.id = 0;
    x.category = 'Balance Forward';
    x.period = this.getPeriodName(dateFormat);
    x.year = year;
    x.date = firstDate;
    x.vendor = this.vendorYear[this.processIndex].vendorName;
    x.vendorId = this.vendorYear[this.processIndex].vendorId;
    x.balance = this.balFwdBeginningMonth[0];
    this.createCommission(x);
  }
  loadArraysCommission() {
    this.order.forEach(element => {
      // Splits items into incoming and outgoing arrays by period
      // 7.10 Commission 2 commented out throughout to be used at a later date. 
      const period = this.getPeriod(element.shipmentDate);
      element.budgetAmount = this.getCommission(element.brokerId, element.commission1);
      element.commPct = this.getCommissionPct(element.brokerId, element.commPct1);
      // element.budgetAmount = this.getCommission(element.brokerId, element.commission1, element.broker2Id, element.commission2);
      // element.commPct = this.getCommissionPct(element.brokerId, element.commPct1, element.broker2Id, element.commPct2);
      element.broker = this.getBrokerCommission(element.brokerId, element.broker, element.broker2Id, element.broker2);
      this.incoming[period].push(element);
    });
    this.creditData.forEach(element => {
      const period = this.getPeriod(element.creditDate);
      const itemCount = element.items.length;
      if (itemCount == 0) {
        element.brokerCompany = this.getBrokerCommission(element.brokerId, element.brokerCompany, element.brokerId2, element.brokerCompany2);
        element.commissionPct = this.getCommissionPct(element.brokerId, element.commission1),
        // element.commissionPct = this.getCommissionPct(element.brokerId, element.commission1, element.brokerId2, element.commission2),
        element.shipTo = '';
        this.outgoing[period].push(element);
      } else {
        element.items.forEach(item => {
          if (this.brokerSelected == 0 || this.brokerSelected == item.brokerId || this.brokerSelected == item.brokerId2) {
            const newItem = {
              balance: element.balance,
              brokerCompany: this.getBrokerCommission(item.brokerId, "", item.brokerId2, ""),
              brokerCompany2: element.brokerCompany2,
              brokerId: item.brokerId,
              brokerId2: item.brokerId2,
              bucket: element.bucket,
              commissionPct: this.getCommissionPct(item.brokerId, item.commission1),
              // commissionPct: this.getCommissionPct(item.brokerId, item.commission1, item.brokerId2, item.commission2),
              commission1: item.commission1,
              commission2: item.commission2,
              creditAmount: item.commissionDollars1 + item.commissionDollars2,
              creditDate: element.creditDate,
              creditNo: element.creditNo,
              creditPromotionExplaination: element.creditPromotionExplaination,
              creditReason: element.creditReason1,
              creditStatus: element.creditStatus,
              creditType: element.creditType,
              customerCompany: element.customerCompany,
              customerCreditNo: element.customerCreditNo,
              customerId: element.customerId,
              debit: element.debit,
              purchaseOrder: element.purchaseOrder,
              vendorCompany: element.vendorCompany,
              vendorId: element.vendorId,
              shipTo: this.getShipTo(item.shipToId)
            }
            this.outgoing[period].push(newItem);
          }
        });
      }
    });
    this.transfer.forEach(element => {
       const period = this.getPeriod(element.transferDate);
       this.transfering[period].push(element);
    });
    this.payment.forEach(e => {
      const index = this.monthName.findIndex( mo => mo == e.month);
      if (index > -1) {
        this.paying[index].push(e);
      }
    })

    this.balFwdBeginningMonth.forEach((element, index) => {
      this.balFwdBeginningMonth[index] = this.balfwd;
      element = this.balfwd;
      this.balEndCurrentMonth[index] = this.balfwd;
      this.incoming[index].forEach(element2 => {
        this.balEndCurrentMonth[index] += element2.budgetAmount; 
        element2.balance = this.balEndCurrentMonth[index];
      })
      this.outgoing[index].forEach(element3 => {
        element3.debit = 0;
        const commDollar = this.computeCommission(element3.creditAmount, element3.commission1)
        const commDollar2 = this.computeCommission(element3.creditAmount, element3.commission2)

        if (this.brokerSelected == 0 || this.brokerSelected === element3.brokerId) {
          element3.debit += commDollar;
        }
        // if (this.brokerSelected == 0 || this.brokerSelected === element3.brokerId2) {
        //   element3.debit += commDollar2;
        // }
        this.balEndCurrentMonth[index] -= element3.debit;
        element3.balance = this.balEndCurrentMonth[index];
      })

      this.transfering[index].forEach(element4 => {
        if (element4.fromBucket == this.bucket) {
          element4.debit = element4.transferAmount;
        } else {
          element4.credit = element4.transferAmount;
        }
        
        this.balEndCurrentMonth[index] += element4.credit - element4.debit;
        element4.balance = this.balEndCurrentMonth[index];
      })

      this.paying[index].forEach(e => {
        this.balEndCurrentMonth[index] -= e.paymentAmount;
        e.balance = this.balEndCurrentMonth[index];
      })

      this.balfwd = this.balEndCurrentMonth[index];
    })
  }
  getShipTo(shipToId) {
    let ship = '';
    const index = this.shipTo.findIndex(ship => ship.id == shipToId);
    if (index > -1) {
      ship = this.shipTo[index].company;
    }
    return ship;
  }
  getBrokerCommission(id, broker1, id2, broker2) {
    let broker = '';
    broker1 = typeof broker1 == 'undefined' || !broker1 ? '' : broker1;
    broker2 = typeof broker2 == 'undefined' || !broker2 ? '' : broker2;
    id = typeof id == 'undefined' || id == null ? 0 : id * 1;
    id2 = typeof id2 == 'undefined' || id2 == null ? 0 : id2 * 1;

    if (broker1 == '') {
      const index = this.brokers.findIndex(brok => brok.id == id);
      if (index > -1) {
        broker1 = this.brokers[index].company;
      }
    }

    if (broker2 == '') {
      const index = this.brokers.findIndex(brok => brok.id == id2);
      if (index > -1) {
        broker2 = '';
        // broker2 = this.brokers[index].company;
      }
    }

    if (this.brokerSelected > 0) {
       if (this.brokerSelected != id) {
        broker1 = '';
       }
       if (this.brokerSelected != id2) {
         broker2 = '';
       }
    }

    if (broker1 != '' && broker2 != '') {
      broker = broker1
      // broker = broker1 + '/' + broker2
    } else {
      broker += broker1;
      // broker += broker2;
    }
   
    return broker;
  }
  // getCommissionorg(id, comm1, id2, comm2) {
  //   let commissionAmount = 0;
  //   if (this.brokerSelected == 0 || this.brokerSelected === id) {
  //     commissionAmount += comm1;
  //   }
  //   if (this.brokerSelected == 0 || this.brokerSelected === id2) {
  //     commissionAmount += comm2;
  //   }
  //   return commissionAmount;
  // }
  getCommission(id, comm1) {
    let commissionAmount = 0;
    if (this.brokerSelected == 0 || this.brokerSelected === id) {
      commissionAmount += comm1;
    }
    return commissionAmount;
  }
  // getCommissionPctorg(id, comm1, id2, comm2) {
  //   let commissionPct = 0;
  //   if (this.brokerSelected == 0 || this.brokerSelected === id) {
  //     commissionPct += comm1;
  //   }
  //   if (this.brokerSelected == 0 || this.brokerSelected === id2) {
  //     commissionPct += comm2;
  //   }
  //   return commissionPct *.01;
  // }
  getCommissionPct(id, comm1) {
    let commissionPct = 0;
    if (this.brokerSelected == 0 || this.brokerSelected === id) {
      commissionPct += comm1;
    }
    return commissionPct *.01;
  }
  computeCommission(credit, commPct) {
    credit = credit ? credit : 0;
    commPct = commPct ? commPct : 0;

    let bigNum: any;
    bigNum = Big(credit)
    .times(commPct)
    .times(.01)
    .round(2)
    return bigNum * 1;
  }
  getPeriod(date) {
    let retValue;
    const baseDate = date.substring(0, 10);
    for (let i = 0; i < this.periods.length; i++) {
      if (baseDate >= this.periods[i].weekStartDate && baseDate <= this.periods[i].weekEndDate) {
        let period = this.periods[i].period;
        retValue = period;
      }
    }
    return retValue;
  }
  getPeriodName(date) {
    let retValue;
    const baseDate = date.substring(0, 10);
    for (let i = 0; i < this.periods.length; i++) {
      if (baseDate >= this.periods[i].weekStartDate && baseDate <= this.periods[i].weekEndDate) {
        let periodYear = "0" + this.periods[i].period + "-PERIOD";             
        if (this.periods[i].period > 9) {
          periodYear = this.periods[i].period + "-PERIOD";
        } 
        retValue = periodYear;
      }
    }
    return retValue;
  }
  initializeIncomingCommission() {
    //begin inserting data into azure starting with incoming array
    this.processIndexIncoming++;
    if (this.processIndexIncoming >= this.incoming.length) {
      this.processIndexOutgoing = -1;
      this.initializeOutgoingCommission();
    } else {
      if (this.incoming[this.processIndexIncoming].length == 0) {
        this.initializeIncomingCommission();
      }
      this.processIndexIncomingItems = -1;
      this.insertIncomingCommission();
    }
  }
  insertIncomingCommission() {
    //insert into azure incoming array one subarray at a time
    this.processIndexIncomingItems++;
    if (this.processIndexIncomingItems >= this.incoming[this.processIndexIncoming].length) {
      this.initializeIncomingCommission();
    } else {
      this.totalCount++;
      this.vendorYear[this.processIndex].counter = this.totalCount;
      const shipDate = this.incoming[this.processIndexIncoming][this.processIndexIncomingItems].shipmentDate;
      let dateFormat = new Date(this.incoming[this.processIndexIncoming][this.processIndexIncomingItems].shipmentDate);
      let x = new CommissionData();
      x.id = 0;
      x.category = 'Incoming';
      x.period = this.getPeriodName(shipDate);
      x.year = dateFormat.getFullYear();
      x.status = this.incoming[this.processIndexIncoming][this.processIndexIncomingItems].orderStatus;
      x.type = this.incoming[this.processIndexIncoming][this.processIndexIncomingItems].orderType;
      x.no = this.incoming[this.processIndexIncoming][this.processIndexIncomingItems].salesOrderNo;
      x.purchaseOrder = this.incoming[this.processIndexIncoming][this.processIndexIncomingItems].purchaseOrder;
      x.date = shipDate;
      x.customer = this.incoming[this.processIndexIncoming][this.processIndexIncomingItems].billTo;
      x.vendor = this.incoming[this.processIndexIncoming][this.processIndexIncomingItems].vendor;
      x.vendorId = this.incoming[this.processIndexIncoming][this.processIndexIncomingItems].vendorId;
      x.shipTo = this.incoming[this.processIndexIncoming][this.processIndexIncomingItems].shipTo;
      x.broker = this.incoming[this.processIndexIncoming][this.processIndexIncomingItems].broker;
      x.broker2 = this.incoming[this.processIndexIncoming][this.processIndexIncomingItems].broker2;
      x.salesAmount = this.incoming[this.processIndexIncoming][this.processIndexIncomingItems].salesAmount;
      x.commissionIncPercent = this.incoming[this.processIndexIncoming][this.processIndexIncomingItems].commPct;
      x.commissionInc = this.incoming[this.processIndexIncoming][this.processIndexIncomingItems].commission1;
      // x.commissionInc = this.incoming[this.processIndexIncoming][this.processIndexIncomingItems].budgetAmount;
      x.balance = 0;

      const ext = x.balance === null ? 0 : x.balance;
      this.kstTotalExtension += ext;
      this.kstLineCount += ext > 0 ? 1 : 0;
      
      this.createCommission(x);
      this.insertIncomingCommission(); 
    }
  }
  createCommission(row) {
    this.commissionService.create(row).subscribe(data => {
    })
  }
  initializeOutgoingCommission() {
    //continue inserting data into azure now dealing with outgoing array
    this.processIndexOutgoing++;
    if (this.processIndexOutgoing >= this.outgoing.length) {
      this.processIndexTransfering = -1;
      this.initializeTransferingCommission();
    } else {
      if (this.outgoing[this.processIndexOutgoing].length == 0) {
        this.initializeOutgoingCommission();
      }
      this.processIndexOutgoingItems = -1;
      this.insertOutgoingCommission();
    }
  }
  insertOutgoingCommission() {
    //insert into azure outgoing array one subarray at a time
    this.processIndexOutgoingItems++;
    if (this.processIndexOutgoingItems >= this.outgoing[this.processIndexOutgoing].length) {
      this.initializeOutgoingCommission();
    } else {
      this.totalCount++;
      this.vendorYear[this.processIndex].counter = this.totalCount;
      const creditDate = this.outgoing[this.processIndexOutgoing][this.processIndexOutgoingItems].creditDate;
      let dateFormat = new Date(this.outgoing[this.processIndexOutgoing][this.processIndexOutgoingItems].creditDate);
      let x = new CommissionData();
      x.id = 0;
      x.category = 'Outgoing';
      x.period = this.getPeriodName(creditDate);
      x.year = dateFormat.getFullYear();
      x.status = this.outgoing[this.processIndexOutgoing][this.processIndexOutgoingItems].creditStatus;
      x.type = this.outgoing[this.processIndexOutgoing][this.processIndexOutgoingItems].creditType;
      x.no = this.outgoing[this.processIndexOutgoing][this.processIndexOutgoingItems].creditNo;
      x.purchaseOrder = this.outgoing[this.processIndexOutgoing][this.processIndexOutgoingItems].purchaseOrder;
      x.date = creditDate;
      x.customer = this.outgoing[this.processIndexOutgoing][this.processIndexOutgoingItems].customerCompany;
      x.vendor = this.outgoing[this.processIndexOutgoing][this.processIndexOutgoingItems].vendorCompany;
      x.vendorId = this.outgoing[this.processIndexOutgoing][this.processIndexOutgoingItems].vendorId;
      x.shipTo = this.outgoing[this.processIndexOutgoing][this.processIndexOutgoingItems].shipTo;
      x.broker = this.outgoing[this.processIndexOutgoing][this.processIndexOutgoingItems].brokerCompany;
      // x.broker2 = this.outgoing[this.processIndexOutgoing][this.processIndexOutgoingItems].broker2;
      x.customerCreditNo = this.outgoing[this.processIndexOutgoing][this.processIndexOutgoingItems].customerCreditNo;
      x.creditAmount = this.outgoing[this.processIndexOutgoing][this.processIndexOutgoingItems].creditAmount;
      x.notes = this.outgoing[this.processIndexOutgoing][this.processIndexOutgoingItems].creditPromotionExplaination;
      x.creditReason = this.outgoing[this.processIndexOutgoing][this.processIndexOutgoingItems].creditReason;
      x.commissionOut = this.outgoing[this.processIndexOutgoing][this.processIndexOutgoingItems].debit;
      x.commissionOutPercent = this.outgoing[this.processIndexOutgoing][this.processIndexOutgoingItems].commissionPct;
      x.balance = 0;

      const ext = x.balance === null ? 0 : x.balance;
      this.kstTotalExtension += ext;
      this.kstLineCount += ext > 0 ? 1 : 0;

      this.createCommission(x);
      this.insertOutgoingCommission(); 
    }
  }
  initializeTransferingCommission() {
    //continue inserting data into azure now dealing with transferring array
    this.processIndexTransfering++;
    if (this.processIndexTransfering >= this.transfering.length) {
      //continue inserting data into azure now dealing with paying array
      this.processIndexPaying = -1;
      this.initializePayingCommission();
    } else {
      if (this.transfering[this.processIndexTransfering].length == 0) {
        this.initializeTransferingCommission();
      }
      this.processIndexTransferingItems = -1;
      this.insertTransferingCommission();
    }
  }
  insertTransferingCommission() {
    //insert into azure transferring array one subarray at a time
    this.processIndexTransferingItems++;
    if (this.processIndexTransferingItems >= this.transfering[this.processIndexTransfering].length) {
      this.initializeTransferingCommission();
    } else {
      this.totalCount++;
      this.vendorYear[this.processIndex].counter = this.totalCount;
      const transferDate = this.transfering[this.processIndexTransfering][this.processIndexTransferingItems].transferDate;
      const bucketFrom = this.transfering[this.processIndexTransfering][this.processIndexTransferingItems].fromBucket;
      const bucketTo = this.transfering[this.processIndexTransfering][this.processIndexTransferingItems].toBucket;
      let dateFormat = new Date(this.transfering[this.processIndexTransfering][this.processIndexTransferingItems].transferDate);
      let x = new CommissionData();
      x.id = 0;
      x.category = 'Transfering';
      x.period = this.getPeriodName(transferDate);
      x.year = dateFormat.getFullYear();
      x.status = this.transfering[this.processIndexTransfering][this.processIndexTransferingItems].transferStatus;
      x.type = this.transfering[this.processIndexTransfering][this.processIndexTransferingItems].orderType1;
      x.no = this.transfering[this.processIndexTransfering][this.processIndexTransferingItems].transferNo;
      x.date = transferDate;
      x.customer = this.transfering[this.processIndexTransfering][this.processIndexTransferingItems].billTo;
      x.vendor = this.transfering[this.processIndexTransfering][this.processIndexTransferingItems].vendor;
      x.vendorId = this.transfering[this.processIndexTransfering][this.processIndexTransferingItems].vendorId;
      x.credit = this.transfering[this.processIndexTransfering][this.processIndexTransferingItems].credit;
      x.debit = this.transfering[this.processIndexTransfering][this.processIndexTransferingItems].debit;
      x.balance = 0;
      x.notes = this.transfering[this.processIndexTransfering][this.processIndexTransferingItems].notes;
      x.bucketTransfer = bucketFrom + " " + "to" + " " + bucketTo;

      const ext = x.balance === null ? 0 : x.balance;
      this.kstTotalExtension += ext;
      this.kstLineCount += ext > 0 ? 1 : 0;

      this.createCommission(x);
      this.insertTransferingCommission(); 
    }
  }
  initializePayingCommission() {
    //continue inserting data into azure now dealing with transferring array
    this.processIndexPaying++;
    if (this.processIndexPaying >= this.paying.length) {
      this.processIndexPaying = -1;
      //all arrays for commission have been posted to azure move to exchange report
      this.updateDataLink();
      this.runExchange();
    } else {
      if (this.paying[this.processIndexPaying].length == 0) {
        this.initializePayingCommission();
      }
      this.processIndexPayingItems = -1;
      this.insertPayingCommission();
    }
  }
  insertPayingCommission() {
    //insert into azure paying array one subarray at a time
    this.processIndexPayingItems++;
    if (this.processIndexPayingItems >= this.paying[this.processIndexPaying].length) {
      this.initializePayingCommission();
    } else {
      this.totalCount++;
      this.vendorYear[this.processIndex].counter = this.totalCount;
      const paymentDate = this.paying[this.processIndexPaying][this.processIndexPayingItems].paymentDate;
      let dateFormat = new Date(this.paying[this.processIndexPaying][this.processIndexPayingItems].paymentDate);
      let x = new CommissionData();
      x.id = 0;
      x.category = 'Payment';
      x.period = this.getPeriodName(paymentDate);
      x.year = this.paying[this.processIndexPaying][this.processIndexPayingItems].year;
      x.month = this.paying[this.processIndexPaying][this.processIndexPayingItems].month;
      x.no = this.paying[this.processIndexPaying][this.processIndexPayingItems].brokerPayNumber;
      x.purchaseOrder = this.paying[this.processIndexPaying][this.processIndexPayingItems].reference;
      x.date = paymentDate;
      x.vendor = this.paying[this.processIndexPaying][this.processIndexPayingItems].vendor;
      x.vendorId = this.vendorId;
      x.notes = this.paying[this.processIndexPaying][this.processIndexPayingItems].notes;
      x.payment = this.paying[this.processIndexPaying][this.processIndexPayingItems].paymentAmount;
      x.balance = 0;
      
      const ext = x.balance === null ? 0 : x.balance;
      this.kstTotalExtension += ext;
      this.kstLineCount += ext > 0 ? 1 : 0;

      this.createCommission(x);
      this.insertPayingCommission(); 
    }
  }
  deleteItemsCommission() {
    this.vendorYear[this.processIndex].status = "Deleting" + " " + this.bucket;
    this.totalCount++;
    this.vendorYear[this.processIndex].counter = this.totalCount;
    this.processIndexItem++;
    if (this.processIndexItem < this.items.length) {
      this.commissionService.remove(this.items[this.processIndexItem].id).subscribe(data => {
        this.deleteItemsCommission();
      }, error => {
        alert('Error received from Azure: ' + error.message);
      })
    } else {
      this.getItemsCommission();
    }
  }
  runExchange(){
    this.monthSelected = [];
    this.monthSelected.push("1");
    this.monthSelected.push("12");
    this.totalCount = 0;
    this.firstPost = true;
    this.items = [];
    this.bucket = 'Exchange';
    this.report = '';
    this.getItemsExchange();
  }
  getItemsExchange() {
    this.exchangeService.getFilter(this.yearRequested, this.vendorId, 0, 0).subscribe(data => {
      this.items = data.item;
      if (this.items.length > 0) {
        this.processIndexItem = -1;
        this.deleteItemsExchange();
      } else {
        this.totalCount = 0;
        this.kstLineCount = 0;
        this.kstTotalExtension = 0;
        this.getExchangeData();
      }
    }, error => {
      alert('Error from azzure:' + error.message)
    }) 
  }
  getExchangeData() {
    this.vendorYear[this.processIndex].status = "Updating" + " " + this.bucket;
    // this.totalCount = 0;
    this.balfwd = 0;
    this.incoming = [[],[],[],[],[],[],[],[],[],[],[],[],[],[]];
    this.transfering = [[],[],[],[],[],[],[],[],[],[],[],[],[],[]];
    this.orderService.getBucketExchange(this.vendorYear[this.processIndex].salesYear, this.monthSelected, this.vendorYear[this.processIndex].vendorId, 0, 0, this.bucket, this.orderType).subscribe(data => {
      this.order = data.order; 
      this.creditData = data.creditData;
      this.transfer = data.transferData;
      this.balanceForwardCredit = data.balanceForwardCredit;
      this.balanceForwardActual = data.balanceForwardActual;
      this.balanceForwardBudget = data.balanceForwardBudget;
      this.balanceForwardTransfer = data.balanceForwardTransfer;
      this.balfwd = this.balanceForwardActual + this.balanceForwardTransfer;
      this.loadArraysExchange();
      // this.totalCount = 0;
      this.processIndexIncoming = -1;
      this.processIndexIncomingItems = -1;
      this.processIndexStock = -1;
      this.processIndexStockItems = -1;
      this.processIndexTransfering = -1;
      this.processIndexTransferingItems = -1;
      // this.kstLineCount = 0;
      // this.kstTotalExtension = 0;
      if (this.firstPost == true) {
        this.insertBalFwdExchange();
        this.firstPost = false;
      } 
      this.initializeIncomingExchange();
    })
  }
  insertBalFwdExchange() {
    const year = this.vendorYear[this.processIndex].salesYear;
    let firstDate = new Date(Date.UTC(year, 0, 2, 0, 0, 0));
    let dateFormat = firstDate.toISOString();
    let x = new ExchangeData();
    x.id = 0;
    x.category = 'Balance Forward';
    x.period = this.getPeriodName(dateFormat);
    x.year = year;
    x.date = firstDate;
    x.vendor = this.vendorYear[this.processIndex].vendorName;
    x.vendorId = this.vendorYear[this.processIndex].vendorId;
    x.balance = this.balfwd;
    x.type = this.orderType;
    this.createExchange(x);
  }
  loadArraysExchange() {
    this.order.forEach(element => {
      // Splits items into incoming and outgoing arrays by period
      const period = this.getPeriod(element.shipmentDate);
      element.broker = this.getBroker(element.brokerId, element.broker, element.broker2Id, element.broker2);
      this.incoming[period].push(element);
    });
    this.transfer.forEach(element => {
      const bucketSelected = 'Exchange';
      const period = this.getPeriod(element.transferDate);
      if (element.fromBucket == bucketSelected) {
        element.debit = element.transferAmount;
      } else {
        element.credit = element.transferAmount;
      }
      this.transfering[period].push(element);
   });
  }
  getBroker(id, broker1, id2, broker2) {
    let broker = '';
    broker1 = typeof broker1 == 'undefined' || !broker1 ? '' : broker1;
    broker2 = typeof broker2 == 'undefined' || !broker2 ? '' : broker2;
    if (this.brokerSelected > 0) {
      if (this.brokerSelected === id) {
        broker2 = '';
      }
      if (this.brokerSelected === id2) {
        broker1 = '';
      }
    }

    if (broker1 != '' && broker2 != '') {
      broker = broker1 + '/' + broker2
    } else {
      broker += broker1;
      broker += broker2;
    }
   
    return broker;
  }
  initializeIncomingExchange() {
    //begin inserting data into azure starting with incoming array
    this.processIndexIncoming++;
    if (this.processIndexIncoming >= this.incoming.length) {
      this.processIndexTransfering = -1; 
      this.initializeTransferingExchange()
    } else {
      if (this.incoming[this.processIndexIncoming].length == 0) {
        this.initializeIncomingExchange();
      }
      this.processIndexIncomingItems = -1;
      this.insertIncomingExchange();
    }
  }
  insertIncomingExchange() {
    //insert into azure incoming array one subarray at a time
    this.processIndexIncomingItems++;
    if (this.processIndexIncomingItems >= this.incoming[this.processIndexIncoming].length) {
      this.initializeIncomingExchange();
    } else {
      this.totalCount++;
      this.vendorYear[this.processIndex].counter = this.totalCount;
      const shipDate = this.incoming[this.processIndexIncoming][this.processIndexIncomingItems].shipmentDate;
      let dateFormat = new Date(this.incoming[this.processIndexIncoming][this.processIndexIncomingItems].shipmentDate);
      let x = new ExchangeData();
      x.id = 0;
      x.category = 'Incoming';
      x.period = this.getPeriodName(shipDate);
      x.year = dateFormat.getFullYear();
      x.status = this.incoming[this.processIndexIncoming][this.processIndexIncomingItems].orderStatus;
      x.type = this.incoming[this.processIndexIncoming][this.processIndexIncomingItems].orderType;
      x.no = this.incoming[this.processIndexIncoming][this.processIndexIncomingItems].salesOrderNo;
      x.purchaseOrder = this.incoming[this.processIndexIncoming][this.processIndexIncomingItems].purchaseOrder;
      x.date = shipDate;
      x.customer = this.incoming[this.processIndexIncoming][this.processIndexIncomingItems].billTo;
      x.vendor = this.incoming[this.processIndexIncoming][this.processIndexIncomingItems].vendor;
      x.vendorId = this.incoming[this.processIndexIncoming][this.processIndexIncomingItems].vendorId;
      x.shipTo = this.incoming[this.processIndexIncoming][this.processIndexIncomingItems].shipTo;
      x.broker = this.incoming[this.processIndexIncoming][this.processIndexIncomingItems].broker;
      x.vendorPO = this.incoming[this.processIndexIncoming][this.processIndexIncomingItems].actualVend;
      x.actualExchange = this.incoming[this.processIndexIncoming][this.processIndexIncomingItems].actualExchange;
      x.fOBNetReturn = this.incoming[this.processIndexIncoming][this.processIndexIncomingItems].actualNet;
      x.netCND = this.incoming[this.processIndexIncoming][this.processIndexIncomingItems].actualNetCND;
      x.fOBDiffExchange = this.incoming[this.processIndexIncoming][this.processIndexIncomingItems].actualDiff;
      x.balance = 0;

      const ext = x.balance === null ? 0 : x.balance;
      this.kstTotalExtension += ext;
      this.kstLineCount += ext > 0 ? 1 : 0;
      
      this.createExchange(x);
      this.firstPost = false;
      this.insertIncomingExchange(); 
    }
  }
  createExchange(row) {
    this.exchangeService.create(row).subscribe(data => {
    })
  }
  getStockOrders() {
    this.orderType = 'Stock';
    this.firstPost = true;
    this.getExchangeData();
  }
  initializeTransferingExchange() {
    //continue inserting data into azure now dealing with transferring array
    this.processIndexTransfering++;
    if (this.processIndexTransfering >= this.transfering.length) {
      //repeat process for stock on first run
      if (this.orderType == 'Both Retail/Food Service'){
        this.getStockOrders();
      } else {
        //all arrays have been posted to azure for exchange move to cnd freight report
        this.updateDataLink();
        this.runFreightCnd();
      }
    } else {
      if (this.transfering[this.processIndexTransfering].length == 0) {
        this.initializeTransferingExchange();
      }
      this.processIndexTransferingItems = -1;
      this.insertTransferingExchange();
    }
  }
  insertTransferingExchange() {
    //insert into azure transferring array one subarray at a time
    this.processIndexTransferingItems++;
    if (this.processIndexTransferingItems >= this.transfering[this.processIndexTransfering].length) {
      this.initializeTransferingExchange();
    } else {
      this.totalCount++;
      this.vendorYear[this.processIndex].counter = this.totalCount;
      const transferDate = this.transfering[this.processIndexTransfering][this.processIndexTransferingItems].transferDate;
      const bucketFrom = this.transfering[this.processIndexTransfering][this.processIndexTransferingItems].fromBucket;
      const bucketTo = this.transfering[this.processIndexTransfering][this.processIndexTransferingItems].toBucket;
      let dateFormat = new Date(this.transfering[this.processIndexTransfering][this.processIndexTransferingItems].transferDate);
      let x = new ExchangeData();
      x.id = 0;
      x.category = 'Transfering';
      x.period = this.getPeriodName(transferDate);
      x.year = dateFormat.getFullYear();
      x.status = this.transfering[this.processIndexTransfering][this.processIndexTransferingItems].transferStatus;
      x.type = this.transfering[this.processIndexTransfering][this.processIndexTransferingItems].orderType1;
      x.no = this.transfering[this.processIndexTransfering][this.processIndexTransferingItems].transferNo;
      x.date = transferDate;
      x.customer = this.transfering[this.processIndexTransfering][this.processIndexTransferingItems].billTo;
      x.vendor = this.transfering[this.processIndexTransfering][this.processIndexTransferingItems].vendor;
      x.vendorId = this.transfering[this.processIndexTransfering][this.processIndexTransferingItems].vendorId;
      x.credit = this.transfering[this.processIndexTransfering][this.processIndexTransferingItems].credit;
      x.debit = this.transfering[this.processIndexTransfering][this.processIndexTransferingItems].debit;
      x.balance = 0;
      x.notes = this.transfering[this.processIndexTransfering][this.processIndexTransferingItems].notes;
      x.bucketTransfer = bucketFrom + " " + "to" + " " + bucketTo;

      const ext = x.balance === null ? 0 : x.balance;
      this.kstTotalExtension += ext;
      this.kstLineCount += ext > 0 ? 1 : 0;

      this.createExchange(x);
      this.insertTransferingExchange(); 
    }
  }
  deleteItemsExchange() {
    this.vendorYear[this.processIndex].status = "Deleting" + " " + this.bucket;
    this.totalCount++;
    this.vendorYear[this.processIndex].counter = this.totalCount;
    this.processIndexItem++;
    if (this.processIndexItem < this.items.length) {
      this.exchangeService.remove(this.items[this.processIndexItem].id).subscribe(data => {
        this.deleteItemsExchange();
      }, error => {
        alert('Error received from Azure: ' + error.message);
      })
    } else {
      this.getItemsExchange();
    }
  }
  runFreightCnd(){
    this.monthSelected = [];
    this.monthSelected.push("1");
    this.monthSelected.push("12");
    this.totalCount = 0;
    this.firstPost = true;
    this.items = [];
    this.bucket = 'Freight CND';
    this.report = 'Actual';
    this.orderType = 'Both Retail/Food Service';
    this.getItemsFreightCnd();
  }
  getItemsFreightCnd() {
    this.freightCndService.getFilter(this.yearRequested, this.vendorId, 0, 0).subscribe(data => {
      this.items = data.item;
      if (this.items.length > 0) {
        this.processIndexItem = -1;
        this.deleteItemsFreightCnd();
      } else {
        this.getFreightCndData();
      }
    }, error => {
      alert('Error from azzure:' + error.message)
    }) 
  }
  getFreightCndData() {
    this.vendorYear[this.processIndex].status = "Updating" + " " + this.bucket;
    this.totalCount = 0;
    this.balfwd = 0;
    this.incoming = [[],[],[],[],[],[],[],[],[],[],[],[],[],[]];
    this.outgoing = [[],[],[],[],[],[],[],[],[],[],[],[],[],[]];
    this.transfering = [[],[],[],[],[],[],[],[],[],[],[],[],[],[]];
    this.orderService.getBucketList(this.vendorYear[this.processIndex].salesYear, this.monthSelected, this.vendorYear[this.processIndex].vendorId, 0, 0, this.bucket, this.report, this.orderType).subscribe(data => {
      this.order = data.order; 
      this.creditData = data.creditData;
      this.transfer = data.transferData;
      this.balanceForwardCredit = data.balanceForwardCredit;
      this.balanceForwardActual = data.balanceForwardActual;
      this.balanceForwardBudget = data.balanceForwardBudget;
      this.balanceForwardTransfer = data.balanceForwardTransfer;
      this.balfwd = this.balanceForwardBudget - this.balanceForwardActual + this.balanceForwardTransfer;
      this.loadArraysFreightCnd();
      this.totalCount = 0;
      this.processIndexIncoming = -1;
      this.processIndexIncomingItems = -1;
      this.processIndexOutgoing = -1;
      this.processIndexOutgoingItems = -1;
      this.processIndexTransfering = -1;
      this.processIndexTransferingItems = -1;
      this.kstLineCount = 0;
      this.kstTotalExtension = 0;
      if (this.firstPost == true) {
        this.insertBalFwdFreightCnd();
        this.firstPost = false;
      } 
      this.initializeIncomingFreightCnd();
    })
  }
  insertBalFwdFreightCnd() {
    const year = this.vendorYear[this.processIndex].salesYear;
    let firstDate = new Date(Date.UTC(year, 0, 2, 0, 0, 0));
    let dateFormat = firstDate.toISOString();
    let x = new FreightCndData();
    x.id = 0;
    x.category = 'Balance Forward';
    x.period = this.getPeriodName(dateFormat);
    x.year = year;
    x.date = firstDate;
    x.vendor = this.vendorYear[this.processIndex].vendorName;
    x.vendorId = this.vendorYear[this.processIndex].vendorId;
    x.balance = this.balfwd;
    this.createFreightCnd(x);
  }
  loadArraysFreightCnd() {
    this.order.forEach(element => {
      // Splits items into incoming and outgoing arrays by period
      const period = this.getPeriod(element.shipmentDate);
      element.broker = this.getBroker(element.brokerId, element.broker, element.broker2Id, element.broker2);
      this.incoming[period].push(element);
      const elementOut = this.duplicateItem(element);
      this.outgoing[period].push(elementOut);
    });
    this.transfer.forEach(element => {
      const period = this.getPeriod(element.transferDate);
      if (element.fromBucket == this.bucket) {
        element.debit = element.transferAmount;
      } else {
        element.credit = element.transferAmount;
      }
      this.transfering[period].push(element);
    });
  }
  duplicateItem(e) {
    let item = new Incoming();
    item.id = e.id;
    item.orderStatus = e.orderStatus;
    item.orderType = e.orderType;
    item.salesOrderNo = e.salesOrderNo;
    item.purchaseOrder = e.purchaseOrder;
    item.shipmentDate = e.shipmentDate;
    item.billTo = e.billTo;
    item.billToId = e.billToId;
    item.shipTo = e.shipTo;
    item.shipToId = e.shipToId;
    item.broker = e.broker;
    item.brokerId = e.brokerId;
    item.vendorId = e.vendorId;
    item.vendor = e.vendor;
    item.budgetAmount = e.budgetAmount;
    item.actualAmount = e.actualAmount;
    item.difference = e.difference;
    item.bucketCompany = e.bucketCompany;
    item.bucketReference = e.bucketReference;
    item.bucketQuery = e.bucketQuery;
    item.salesAmount = e.salesAmount;
    item.itemSales = e.itemSales;
    item.vendorSales = e.vendorSales;
    item.itemVendor = e.itemVendor;
    item.funds = e.funds;
    item.credit = e.credit;
    item.balance = e.balance;
    return item;
  }
  initializeIncomingFreightCnd() {
    //begin inserting data into azure starting with incoming array
    this.processIndexIncoming++;
    if (this.processIndexIncoming >= this.incoming.length) {
      this.processIndexOutgoing = -1;
      this.initializeOutgoingFreightCnd();
    } else {
      if (this.incoming[this.processIndexIncoming].length == 0) {
        this.initializeIncomingFreightCnd();
      }
      this.processIndexIncomingItems = -1;
      this.insertIncomingFreightCnd();
    }
  }
  insertIncomingFreightCnd() {
    //insert into azure incoming array one subarray at a time
    this.processIndexIncomingItems++;
    if (this.processIndexIncomingItems >= this.incoming[this.processIndexIncoming].length) {
      this.initializeIncomingFreightCnd();
    } else {
      this.totalCount++;
      this.vendorYear[this.processIndex].counter = this.totalCount;
      const shipDate = this.incoming[this.processIndexIncoming][this.processIndexIncomingItems].shipmentDate;
      let dateFormat = new Date(this.incoming[this.processIndexIncoming][this.processIndexIncomingItems].shipmentDate);
      let x = new FreightCndData();
      x.id = 0;
      x.category = 'Incoming';
      x.period = this.getPeriodName(shipDate);
      x.year = dateFormat.getFullYear();
      x.status = this.incoming[this.processIndexIncoming][this.processIndexIncomingItems].orderStatus;
      x.type = this.incoming[this.processIndexIncoming][this.processIndexIncomingItems].orderType;
      x.no = this.incoming[this.processIndexIncoming][this.processIndexIncomingItems].salesOrderNo;
      x.purchaseOrder = this.incoming[this.processIndexIncoming][this.processIndexIncomingItems].purchaseOrder;
      x.date = shipDate;
      x.customer = this.incoming[this.processIndexIncoming][this.processIndexIncomingItems].billTo;
      x.vendor = this.incoming[this.processIndexIncoming][this.processIndexIncomingItems].vendor;
      x.vendorId = this.incoming[this.processIndexIncoming][this.processIndexIncomingItems].vendorId;
      x.shipTo = this.incoming[this.processIndexIncoming][this.processIndexIncomingItems].shipTo;
      x.broker = this.incoming[this.processIndexIncoming][this.processIndexIncomingItems].broker;
      x.salesAmount = this.incoming[this.processIndexIncoming][this.processIndexIncomingItems].salesAmount;
      x.credit = this.incoming[this.processIndexIncoming][this.processIndexIncomingItems].budgetAmount;
      x.balance = 0;

      const ext = x.balance === null ? 0 : x.balance;
      this.kstTotalExtension += ext;
      this.kstLineCount += ext > 0 ? 1 : 0;
      
      this.createFreightCnd(x);
      this.firstPost = false;
      this.insertIncomingFreightCnd(); 
    }
  }
  createFreightCnd(row) {
    this.freightCndService.create(row).subscribe(data => {
    })
  }
  initializeOutgoingFreightCnd() {
    //continue inserting data into azure now dealing with outgoing array
    this.processIndexOutgoing++;
    if (this.processIndexOutgoing >= this.outgoing.length) {
      this.processIndexTransfering = -1;
      this.initializeTransferingFreightCnd();
    } else {
      if (this.outgoing[this.processIndexOutgoing].length == 0) {
        this.initializeOutgoingFreightCnd();
      }
      this.processIndexOutgoingItems = -1;
      this.insertOutgoingFreightCnd();
    }
  }
  insertOutgoingFreightCnd() {
    //insert into azure outgoing array one subarray at a time
    this.processIndexOutgoingItems++;
    if (this.processIndexOutgoingItems >= this.outgoing[this.processIndexOutgoing].length) {
      this.initializeOutgoingFreightCnd();
    } else {
      this.totalCount++;
      this.vendorYear[this.processIndex].counter = this.totalCount;
      const creditDate = this.outgoing[this.processIndexOutgoing][this.processIndexOutgoingItems].shipmentDate;
      let dateFormat = new Date(this.outgoing[this.processIndexOutgoing][this.processIndexOutgoingItems].shipmentDate);
      let x = new FreightCndData();
      x.id = 0;
      x.category = 'Outgoing';
      x.period = this.getPeriodName(creditDate);
      x.year = dateFormat.getFullYear();
      x.status = this.outgoing[this.processIndexOutgoing][this.processIndexOutgoingItems].orderStatus;
      x.type = this.outgoing[this.processIndexOutgoing][this.processIndexOutgoingItems].orderType;
      x.no = this.outgoing[this.processIndexOutgoing][this.processIndexOutgoingItems].bucketReference;
      x.purchaseOrder = this.outgoing[this.processIndexOutgoing][this.processIndexOutgoingItems].purchaseOrder;
      x.date = creditDate;
      x.customer = this.outgoing[this.processIndexOutgoing][this.processIndexOutgoingItems].billTo;
      x.vendor = this.outgoing[this.processIndexOutgoing][this.processIndexOutgoingItems].vendor;
      x.vendorId = this.outgoing[this.processIndexOutgoing][this.processIndexOutgoingItems].vendorId;
      x.shipTo = this.outgoing[this.processIndexOutgoing][this.processIndexOutgoingItems].shipTo;
      // x.broker = this.outgoing[this.processIndexOutgoing][this.processIndexOutgoingItems].broker;
      x.creditAmount = this.outgoing[this.processIndexOutgoing][this.processIndexOutgoingItems].salesAmount;
      x.notes = this.outgoing[this.processIndexOutgoing][this.processIndexOutgoingItems].bucketCompany;
      x.debit = this.outgoing[this.processIndexOutgoing][this.processIndexOutgoingItems].actualAmount;
      x.balance = 0;

      const ext = x.balance === null ? 0 : x.balance;
      this.kstTotalExtension += ext;
      this.kstLineCount += ext > 0 ? 1 : 0;

      this.createFreightCnd(x);
      this.insertOutgoingFreightCnd(); 
    }
  }
  initializeTransferingFreightCnd() {
    //continue inserting data into azure now dealing with transferring array
    this.processIndexTransfering++;
    if (this.processIndexTransfering >= this.transfering.length) {
      //all arrays have been posted to azure for freight cnd begin kalcor report
      this.updateDataLink();
      this.runKalcor();
    } else {
      if (this.transfering[this.processIndexTransfering].length == 0) {
        this.initializeTransferingFreightCnd();
      }
      this.processIndexTransferingItems = -1;
      this.insertTransferingFreightCnd();
    }
  }
  insertTransferingFreightCnd() {
    //insert into azure transferring array one subarray at a time
    this.processIndexTransferingItems++;
    if (this.processIndexTransferingItems >= this.transfering[this.processIndexTransfering].length) {
      this.initializeTransferingFreightCnd();
    } else {
      this.totalCount++;
      this.vendorYear[this.processIndex].counter = this.totalCount;
      const transferDate = this.transfering[this.processIndexTransfering][this.processIndexTransferingItems].transferDate;
      const bucketFrom = this.transfering[this.processIndexTransfering][this.processIndexTransferingItems].fromBucket;
      const bucketTo = this.transfering[this.processIndexTransfering][this.processIndexTransferingItems].toBucket;
      let dateFormat = new Date(this.transfering[this.processIndexTransfering][this.processIndexTransferingItems].transferDate);
      let x = new FreightCndData();
      x.id = 0;
      x.category = 'Transfering';
      x.period = this.getPeriodName(transferDate);
      x.year = dateFormat.getFullYear();
      x.status = this.transfering[this.processIndexTransfering][this.processIndexTransferingItems].transferStatus;
      x.type = this.transfering[this.processIndexTransfering][this.processIndexTransferingItems].orderType1;
      x.no = this.transfering[this.processIndexTransfering][this.processIndexTransferingItems].transferNo;
      x.date = transferDate;
      x.customer = this.transfering[this.processIndexTransfering][this.processIndexTransferingItems].billTo;
      x.vendor = this.transfering[this.processIndexTransfering][this.processIndexTransferingItems].vendor;
      x.vendorId = this.transfering[this.processIndexTransfering][this.processIndexTransferingItems].vendorId;
      x.credit = this.transfering[this.processIndexTransfering][this.processIndexTransferingItems].credit;
      x.debit = this.transfering[this.processIndexTransfering][this.processIndexTransferingItems].debit;
      x.balance = 0;
      x.notes = this.transfering[this.processIndexTransfering][this.processIndexTransferingItems].notes;
      x.bucketTransfer = bucketFrom + " " + "to" + " " + bucketTo;

      const ext = x.balance === null ? 0 : x.balance;
      this.kstTotalExtension += ext;
      this.kstLineCount += ext > 0 ? 1 : 0;

      this.createFreightCnd(x);
      this.insertTransferingFreightCnd(); 
    }
  }
  deleteItemsFreightCnd() {
    this.vendorYear[this.processIndex].status = "Deleting" + " " + this.bucket;
    this.totalCount++;
    this.vendorYear[this.processIndex].counter = this.totalCount;
    this.processIndexItem++;
    if (this.processIndexItem < this.items.length) {
      this.freightCndService.remove(this.items[this.processIndexItem].id).subscribe(data => {
        this.deleteItemsFreightCnd();
      }, error => {
        alert('Error received from Azure: ' + error.message);
      })
    } else {
      this.getItemsFreightCnd();
    }
  }
  runKalcor(){
    this.monthSelected = [];
    this.monthSelected.push("1");
    this.monthSelected.push("12");
    this.totalCount = 0;
    this.firstPost = true;
    this.items = [];
    this.bucket = 'Kalcor';
    this.report = 'Actual';
    this.orderType = 'Both Retail/Food Service';
    this.getItemsKalcor();
  }
  getItemsKalcor() {
    this.kalcorService.getFilter(this.yearRequested, this.vendorId, 0, 0).subscribe(data => {
      this.items = data.item;
      if (this.items.length > 0) {
        this.processIndexItem = -1;
        this.deleteItemsKalcor();
      } else {
        this.getKalcorData();
      }
    }, error => {
      alert('Error from azzure:' + error.message)
    }) 
  }
  getKalcorData() {
    this.vendorYear[this.processIndex].status = "Updating" + " " + this.bucket;
    this.totalCount = 0;
    this.balfwd = 0;
    this.incoming = [[],[],[],[],[],[],[],[],[],[],[],[],[],[]];
    this.outgoing = [[],[],[],[],[],[],[],[],[],[],[],[],[],[]];
    this.transfering = [[],[],[],[],[],[],[],[],[],[],[],[],[],[]];
    this.orderService.getBucketList(this.vendorYear[this.processIndex].salesYear, this.monthSelected, this.vendorYear[this.processIndex].vendorId, 0, 0, this.bucket, this.report, this.orderType).subscribe(data => {
      this.order = data.order; 
      this.creditData = data.creditData;
      this.transfer = data.transferData;
      this.balanceForwardCredit = data.balanceForwardCredit;
      this.balanceForwardActual = data.balanceForwardActual;
      this.balanceForwardBudget = data.balanceForwardBudget;
      this.balanceForwardTransfer = data.balanceForwardTransfer;
      this.balfwd = this.balanceForwardBudget - this.balanceForwardCredit + this.balanceForwardTransfer;
      this.loadArraysKalcor();
      this.totalCount = 0;
      this.processIndexIncoming = -1;
      this.processIndexIncomingItems = -1;
      this.processIndexOutgoing = -1;
      this.processIndexOutgoingItems = -1;
      this.processIndexTransfering = -1;
      this.processIndexTransferingItems = -1;
      this.kstLineCount = 0;
      this.kstTotalExtension = 0;
      if (this.firstPost == true) {
        this.insertBalFwdKalcor();
        this.firstPost = false;
      } 
      this.initializeIncomingKalcor();
    })
  }
  insertBalFwdKalcor() {
    const year = this.vendorYear[this.processIndex].salesYear;
    let firstDate = new Date(Date.UTC(year, 0, 2, 0, 0, 0));
    let dateFormat = firstDate.toISOString();
    let x = new KalcorData();
    x.id = 0;
    x.category = 'Balance Forward';
    x.period = this.getPeriodName(dateFormat);
    x.year = year;
    x.date = firstDate;
    x.vendor = this.vendorYear[this.processIndex].vendorName;
    x.vendorId = this.vendorYear[this.processIndex].vendorId;
    x.balance = this.balFwdBeginningMonth[0];
    this.createKalcor(x);
  }
  loadArraysKalcor() {
    this.order.forEach(element => {
      // Splits items into incoming and outgoing arrays by period
      const period = this.getPeriod(element.shipmentDate);
      element.broker = this.getBroker(element.brokerId, element.broker, element.broker2Id, element.broker2);
      this.incoming[period].push(element);
    });
    this.transfer.forEach(element => {
       const period = this.getPeriod(element.transferDate);
       if (element.fromBucket == this.bucket) {
        element.debit = element.transferAmount;
      } else {
        element.credit = element.transferAmount;
      }
       this.transfering[period].push(element);
    });
  }
  initializeIncomingKalcor() {
    //begin inserting data into azure starting with incoming array
    this.processIndexIncoming++;
    if (this.processIndexIncoming >= this.incoming.length) {
      this.processIndexOutgoing = -1;
      // this.updateDataLink();
      this.initializeTransferingKalcor();
    } else {
      if (this.incoming[this.processIndexIncoming].length == 0) {
        this.initializeIncomingKalcor();
      }
      this.processIndexIncomingItems = -1;
      this.insertIncomingKalcor();
    }
  }
  insertIncomingKalcor() {
    //insert into azure incoming array one subarray at a time
    this.processIndexIncomingItems++;
    if (this.processIndexIncomingItems >= this.incoming[this.processIndexIncoming].length) {
      this.initializeIncomingKalcor();
    } else {
      this.totalCount++;
      this.vendorYear[this.processIndex].counter = this.totalCount;
      const shipDate = this.incoming[this.processIndexIncoming][this.processIndexIncomingItems].shipmentDate;
      let dateFormat = new Date(this.incoming[this.processIndexIncoming][this.processIndexIncomingItems].shipmentDate);
      let x = new KalcorData();
      x.id = 0;
      x.category = 'Incoming';
      x.period = this.getPeriodName(shipDate);
      x.year = dateFormat.getFullYear();
      x.status = this.incoming[this.processIndexIncoming][this.processIndexIncomingItems].orderStatus;
      x.type = this.incoming[this.processIndexIncoming][this.processIndexIncomingItems].orderType;
      x.no = this.incoming[this.processIndexIncoming][this.processIndexIncomingItems].salesOrderNo;
      x.purchaseOrder = this.incoming[this.processIndexIncoming][this.processIndexIncomingItems].purchaseOrder;
      x.date = shipDate;
      x.customer = this.incoming[this.processIndexIncoming][this.processIndexIncomingItems].billTo;
      x.vendor = this.incoming[this.processIndexIncoming][this.processIndexIncomingItems].vendor;
      x.vendorId = this.incoming[this.processIndexIncoming][this.processIndexIncomingItems].vendorId;
      x.shipTo = this.incoming[this.processIndexIncoming][this.processIndexIncomingItems].shipTo;
      x.broker = this.incoming[this.processIndexIncoming][this.processIndexIncomingItems].broker;
      x.salesAmount = this.incoming[this.processIndexIncoming][this.processIndexIncomingItems].salesAmount;
      x.credit = this.incoming[this.processIndexIncoming][this.processIndexIncomingItems].budgetAmount;
      x.balance = 0;

      const ext = x.balance === null ? 0 : x.balance;
      this.kstTotalExtension += ext;
      this.kstLineCount += ext > 0 ? 1 : 0;
      
      this.createKalcor(x);
      this.firstPost = false;
      this.insertIncomingKalcor(); 
    }
  }
  createKalcor(row) {
    this.kalcorService.create(row).subscribe(data => {
    })
  }
  initializeTransferingKalcor() {
    //continue inserting data into azure now dealing with transferring array
    this.processIndexTransfering++;
    if (this.processIndexTransfering >= this.transfering.length) {
      //all arrays have been posted to azure for kalcor begin processing marketing
      this.updateDataLink();
      this.runMarketing();
    } else {
      if (this.transfering[this.processIndexTransfering].length == 0) {
        this.initializeTransferingKalcor();
      }
      this.processIndexTransferingItems = -1;
      this.insertTransferingKalcor();
    }
  }
  insertTransferingKalcor() {
    //insert into azure transferring array one subarray at a time
    this.processIndexTransferingItems++;
    if (this.processIndexTransferingItems >= this.transfering[this.processIndexTransfering].length) {
      this.initializeTransferingKalcor();
    } else {
      this.totalCount++;
      this.vendorYear[this.processIndex].counter = this.totalCount;
      const transferDate = this.transfering[this.processIndexTransfering][this.processIndexTransferingItems].transferDate;
      const bucketFrom = this.transfering[this.processIndexTransfering][this.processIndexTransferingItems].fromBucket;
      const bucketTo = this.transfering[this.processIndexTransfering][this.processIndexTransferingItems].toBucket;
      let dateFormat = new Date(this.transfering[this.processIndexTransfering][this.processIndexTransferingItems].transferDate);
      let x = new KalcorData();
      x.id = 0;
      x.category = 'Transfering';
      x.period = this.getPeriodName(transferDate);
      x.year = dateFormat.getFullYear();
      x.status = this.transfering[this.processIndexTransfering][this.processIndexTransferingItems].transferStatus;
      x.type = this.transfering[this.processIndexTransfering][this.processIndexTransferingItems].orderType1;
      x.no = this.transfering[this.processIndexTransfering][this.processIndexTransferingItems].transferNo;
      x.date = transferDate;
      x.customer = this.transfering[this.processIndexTransfering][this.processIndexTransferingItems].billTo;
      x.vendor = this.transfering[this.processIndexTransfering][this.processIndexTransferingItems].vendor;
      x.vendorId = this.transfering[this.processIndexTransfering][this.processIndexTransferingItems].vendorId;
      x.credit = this.transfering[this.processIndexTransfering][this.processIndexTransferingItems].credit;
      x.debit = this.transfering[this.processIndexTransfering][this.processIndexTransferingItems].debit;
      x.balance = 0;
      x.notes = this.transfering[this.processIndexTransfering][this.processIndexTransferingItems].notes;
      x.bucketTransfer = bucketFrom + " " + "to" + " " + bucketTo;

      const ext = x.balance === null ? 0 : x.balance;
      this.kstTotalExtension += ext;
      this.kstLineCount += ext > 0 ? 1 : 0;

      this.createKalcor(x);
      this.insertTransferingKalcor(); 
    }
  }
  deleteItemsKalcor() {
    this.vendorYear[this.processIndex].status = "Deleting" + " " + this.bucket;
    this.totalCount++;
    this.vendorYear[this.processIndex].counter = this.totalCount;
    this.processIndexItem++;
    if (this.processIndexItem < this.items.length) {
      this.kalcorService.remove(this.items[this.processIndexItem].id).subscribe(data => {
        this.deleteItemsKalcor();
      }, error => {
        alert('Error received from Azure: ' + error.message);
      })
    } else {
      this.getItemsKalcor();
    }
  }
  runMarketing(){
    this.monthSelected = [];
    this.monthSelected.push("1");
    this.monthSelected.push("12");
    this.totalCount = 0;
    this.firstPost = true;
    this.items = [];
    this.bucket = 'Marketing';
    this.report = 'Actual';
    this.orderType = 'Both Retail/Food Service';
    this.getItemsMarketing();
  }
  getItemsMarketing() {
    this.marketingService.getFilter(this.yearRequested, this.vendorId, 0, 0).subscribe(data => {
      this.items = data.item;
      if (this.items.length > 0) {
        this.processIndexItem = -1;
        this.deleteItemsMarketing();
      } else {
        this.getMarketingData();
      }
    }, error => {
      alert('Error from azzure:' + error.message)
    }) 
  }
  getMarketingData() {
    this.vendorYear[this.processIndex].status = "Updating" + " " + this.bucket;
    this.totalCount = 0;
    this.balfwd = 0;
    this.incoming = [[],[],[],[],[],[],[],[],[],[],[],[],[],[]];
    this.outgoing = [[],[],[],[],[],[],[],[],[],[],[],[],[],[]];
    this.transfering = [[],[],[],[],[],[],[],[],[],[],[],[],[],[]];
    this.orderService.getBucketList(this.vendorYear[this.processIndex].salesYear, this.monthSelected, this.vendorYear[this.processIndex].vendorId, 0, 0, this.bucket, this.report, this.orderType).subscribe(data => {
      this.order = data.order; 
      this.creditData = data.creditData;
      this.transfer = data.transferData;
      this.balanceForwardCredit = data.balanceForwardCredit;
      this.balanceForwardActual = data.balanceForwardActual;
      this.balanceForwardBudget = data.balanceForwardBudget;
      this.balanceForwardTransfer = data.balanceForwardTransfer;
      this.balfwd = this.balanceForwardBudget - this.balanceForwardCredit + this.balanceForwardTransfer;
      this.loadArraysMarketing();
      this.totalCount = 0;
      this.processIndexIncoming = -1;
      this.processIndexIncomingItems = -1;
      this.processIndexOutgoing = -1;
      this.processIndexOutgoingItems = -1;
      this.processIndexTransfering = -1;
      this.processIndexTransferingItems = -1;
      this.kstLineCount = 0;
      this.kstTotalExtension = 0;
      if (this.firstPost == true) {
        this.insertBalFwdMarketing();
        this.firstPost = false;
      } 
      this.initializeIncomingMarketing();
    })
  }
  insertBalFwdMarketing() {
    const year = this.vendorYear[this.processIndex].salesYear;
    let firstDate = new Date(Date.UTC(year, 0, 2, 0, 0, 0));
    let dateFormat = firstDate.toISOString();
    let x = new MarketingData();
    x.id = 0;
    x.category = 'Balance Forward';
    x.period = this.getPeriodName(dateFormat);
    x.year = year;
    x.date = firstDate;
    x.vendor = this.vendorYear[this.processIndex].vendorName;
    x.vendorId = this.vendorYear[this.processIndex].vendorId;
    x.balance = this.balFwdBeginningMonth[0];
    this.createMarketing(x);
  }
  loadArraysMarketing() {
    this.order.forEach(element => {
      // Splits items into incoming and outgoing arrays by period
      const period = this.getPeriod(element.shipmentDate);
      element.broker = this.getBroker(element.brokerId, element.broker, element.broker2Id, element.broker2);
      this.incoming[period].push(element);
    });
    this.creditData.forEach(element => {
      const period = this.getPeriod(element.creditDate);
      element.brokerCompany = this.getBroker(element.brokerId, element.brokerCompany, element.brokerId2, element.brokerCompany2);
      this.outgoing[period].push(element);
    });
    this.transfer.forEach(element => {
       const period = this.getPeriod(element.transferDate);
       this.transfering[period].push(element);
    });

    this.balFwdBeginningMonth.forEach((element, index) => {
      this.balFwdBeginningMonth[index] = this.balfwd;
      element = this.balfwd;
      this.balEndCurrentMonth[index] = this.balfwd;
      this.incoming[index].forEach(element2 => {
        this.balEndCurrentMonth[index] += element2.budgetAmount; 
        element2.balance = this.balEndCurrentMonth[index];
      })
      this.outgoing[index].forEach(element3 => {
        this.balEndCurrentMonth[index] += element3.debit - element3.creditAmount;
        element3.balance = this.balEndCurrentMonth[index];
      })

      this.transfering[index].forEach(element4 => {
        if (element4.fromBucket == this.bucket) {
          element4.debit = element4.transferAmount;
        } else {
          element4.credit = element4.transferAmount;
        }
        
        this.balEndCurrentMonth[index] += element4.credit - element4.debit;
        element4.balance = this.balEndCurrentMonth[index];
      })
      this.balfwd = this.balEndCurrentMonth[index];
    })
  }
  initializeIncomingMarketing() {
    //begin inserting data into azure starting with incoming array
    this.processIndexIncoming++;
    if (this.processIndexIncoming >= this.incoming.length) {
      this.processIndexOutgoing = -1;
      this.initializeOutgoingMarketing();
    } else {
      if (this.incoming[this.processIndexIncoming].length == 0) {
        this.initializeIncomingMarketing();
      }
      this.processIndexIncomingItems = -1;
      this.insertIncomingMarketing();
    }
  }
  insertIncomingMarketing() {
    //insert into azure incoming array one subarray at a time
    this.processIndexIncomingItems++;
    if (this.processIndexIncomingItems >= this.incoming[this.processIndexIncoming].length) {
      this.initializeIncomingMarketing();
    } else {
      this.totalCount++;
      this.vendorYear[this.processIndex].counter = this.totalCount;
      const shipDate = this.incoming[this.processIndexIncoming][this.processIndexIncomingItems].shipmentDate;
      let dateFormat = new Date(this.incoming[this.processIndexIncoming][this.processIndexIncomingItems].shipmentDate);
      let x = new MarketingData();
      x.id = 0;
      x.category = 'Incoming';
      x.period = this.getPeriodName(shipDate);
      x.year = dateFormat.getFullYear();
      x.status = this.incoming[this.processIndexIncoming][this.processIndexIncomingItems].orderStatus;
      x.type = this.incoming[this.processIndexIncoming][this.processIndexIncomingItems].orderType;
      x.no = this.incoming[this.processIndexIncoming][this.processIndexIncomingItems].salesOrderNo;
      x.purchaseOrder = this.incoming[this.processIndexIncoming][this.processIndexIncomingItems].purchaseOrder;
      x.date = shipDate;
      x.customer = this.incoming[this.processIndexIncoming][this.processIndexIncomingItems].billTo;
      x.vendor = this.incoming[this.processIndexIncoming][this.processIndexIncomingItems].vendor;
      x.vendorId = this.incoming[this.processIndexIncoming][this.processIndexIncomingItems].vendorId;
      x.shipTo = this.incoming[this.processIndexIncoming][this.processIndexIncomingItems].shipTo;
      x.broker = this.incoming[this.processIndexIncoming][this.processIndexIncomingItems].broker;
      x.broker2 = this.incoming[this.processIndexIncoming][this.processIndexIncomingItems].broker2;
      x.salesAmount = this.incoming[this.processIndexIncoming][this.processIndexIncomingItems].salesAmount;
      x.credit = this.incoming[this.processIndexIncoming][this.processIndexIncomingItems].budgetAmount;
      x.balance = 0;

      const ext = x.balance === null ? 0 : x.balance;
      this.kstTotalExtension += ext;
      this.kstLineCount += ext > 0 ? 1 : 0;
      
      this.createMarketing(x);
      this.firstPost = false;
      this.insertIncomingMarketing(); 
    }
  }
  createMarketing(row) {
    this.marketingService.create(row).subscribe(data => {
    })
  }
  initializeOutgoingMarketing() {
    //continue inserting data into azure now dealing with outgoing array
    this.processIndexOutgoing++;
    if (this.processIndexOutgoing >= this.outgoing.length) {
      this.processIndexTransfering = -1;
      this.initializeTransferingMarketing();
    } else {
      if (this.outgoing[this.processIndexOutgoing].length == 0) {
        this.initializeOutgoingMarketing();
      }
      this.processIndexOutgoingItems = -1;
      this.insertOutgoingMarketing();
    }
  }
  insertOutgoingMarketing() {
    //insert into azure outgoing array one subarray at a time
    this.processIndexOutgoingItems++;
    if (this.processIndexOutgoingItems >= this.outgoing[this.processIndexOutgoing].length) {
      this.initializeOutgoingMarketing();
    } else {
      this.totalCount++;
      this.vendorYear[this.processIndex].counter = this.totalCount;
      const creditDate = this.outgoing[this.processIndexOutgoing][this.processIndexOutgoingItems].creditDate;
      let dateFormat = new Date(this.outgoing[this.processIndexOutgoing][this.processIndexOutgoingItems].creditDate);
      let x = new MarketingData();
      x.id = 0;
      x.category = 'Outgoing';
      x.period = this.getPeriodName(creditDate);
      x.year = dateFormat.getFullYear();
      x.status = this.outgoing[this.processIndexOutgoing][this.processIndexOutgoingItems].creditStatus;
      x.type = this.outgoing[this.processIndexOutgoing][this.processIndexOutgoingItems].creditType;
      x.no = this.outgoing[this.processIndexOutgoing][this.processIndexOutgoingItems].creditNo;
      x.purchaseOrder = this.outgoing[this.processIndexOutgoing][this.processIndexOutgoingItems].purchaseOrder;
      x.date = creditDate;
      x.customer = this.outgoing[this.processIndexOutgoing][this.processIndexOutgoingItems].customerCompany;
      x.vendor = this.outgoing[this.processIndexOutgoing][this.processIndexOutgoingItems].vendorCompany;
      x.vendorId = this.outgoing[this.processIndexOutgoing][this.processIndexOutgoingItems].vendorId;
      x.customerCreditNo = this.outgoing[this.processIndexOutgoing][this.processIndexOutgoingItems].customerCreditNo;
      x.creditAmount = this.outgoing[this.processIndexOutgoing][this.processIndexOutgoingItems].creditAmount;
      x.notes = this.outgoing[this.processIndexOutgoing][this.processIndexOutgoingItems].creditPromotionExplaination;
      x.creditReason = this.outgoing[this.processIndexOutgoing][this.processIndexOutgoingItems].creditReason1;
      x.debit = this.outgoing[this.processIndexOutgoing][this.processIndexOutgoingItems].debit;
      x.balance = 0;


      const ext = x.balance === null ? 0 : x.balance;
      this.kstTotalExtension += ext;
      this.kstLineCount += ext > 0 ? 1 : 0;

      this.createMarketing(x);
      this.insertOutgoingMarketing(); 
    }
  }
  initializeTransferingMarketing() {
    //continue inserting data into azure now dealing with transferring array
    this.processIndexTransfering++;
    if (this.processIndexTransfering >= this.transfering.length) {
      //all arrays have been posted to azure for marketing proceed to vendor report
      this.updateDataLink();
      this.runVendor();
    } else {
      if (this.transfering[this.processIndexTransfering].length == 0) {
        this.initializeTransferingMarketing();
      }
      this.processIndexTransferingItems = -1;
      this.insertTransferingMarketing();
    }
  }
  insertTransferingMarketing() {
    //insert into azure transferring array one subarray at a time
    this.processIndexTransferingItems++;
    if (this.processIndexTransferingItems >= this.transfering[this.processIndexTransfering].length) {
      this.initializeTransferingMarketing();
    } else {
      this.totalCount++;
      this.vendorYear[this.processIndex].counter = this.totalCount;
      const transferDate = this.transfering[this.processIndexTransfering][this.processIndexTransferingItems].transferDate;
      const bucketFrom = this.transfering[this.processIndexTransfering][this.processIndexTransferingItems].fromBucket;
      const bucketTo = this.transfering[this.processIndexTransfering][this.processIndexTransferingItems].toBucket;
      let dateFormat = new Date(this.transfering[this.processIndexTransfering][this.processIndexTransferingItems].transferDate);
      let x = new MarketingData();
      x.id = 0;
      x.category = 'Transfering';
      x.period = this.getPeriodName(transferDate);
      x.year = dateFormat.getFullYear();
      x.status = this.transfering[this.processIndexTransfering][this.processIndexTransferingItems].transferStatus;
      x.type = this.transfering[this.processIndexTransfering][this.processIndexTransferingItems].orderType1;
      x.no = this.transfering[this.processIndexTransfering][this.processIndexTransferingItems].transferNo;
      x.date = transferDate;
      x.customer = this.transfering[this.processIndexTransfering][this.processIndexTransferingItems].billTo;
      x.vendor = this.transfering[this.processIndexTransfering][this.processIndexTransferingItems].vendor;
      x.vendorId = this.transfering[this.processIndexTransfering][this.processIndexTransferingItems].vendorId;
      x.credit = this.transfering[this.processIndexTransfering][this.processIndexTransferingItems].credit;
      x.debit = this.transfering[this.processIndexTransfering][this.processIndexTransferingItems].debit;
      x.balance = 0;
      x.notes = this.transfering[this.processIndexTransfering][this.processIndexTransferingItems].notes;
      x.bucketTransfer = bucketFrom + " " + "to" + " " + bucketTo;

      const ext = x.balance === null ? 0 : x.balance;
      this.kstTotalExtension += ext;
      this.kstLineCount += ext > 0 ? 1 : 0;

      this.createMarketing(x);
      this.insertTransferingMarketing(); 
    }
  }
  deleteItemsMarketing() {
    this.vendorYear[this.processIndex].status = "Deleting" + " " + this.bucket;
    this.totalCount++;
    this.vendorYear[this.processIndex].counter = this.totalCount;
    this.processIndexItem++;
    if (this.processIndexItem < this.items.length) {
      this.marketingService.remove(this.items[this.processIndexItem].id).subscribe(data => {
        this.deleteItemsMarketing();
      }, error => {
        alert('Error received from Azure: ' + error.message);
      })
    } else {
      this.getItemsMarketing();
    }
  }
  runVendor(){
    this.monthSelected = [];
    this.monthSelected.push("1");
    this.monthSelected.push("12");
    this.totalCount = 0;
    this.firstPost = true;
    this.items = [];
    this.bucket = 'Vendor';
    this.report = 'Actual';
    this.orderType = 'Both Retail/Food Service';
    this.getItemsVendor();
  }
  getItemsVendor() {
    this.vendorReportService.getFilter(this.yearRequested, this.vendorId, 0, 0).subscribe(data => {
      this.items = data.item;
      if (this.items.length > 0) {
        this.processIndexItem = -1;
        this.deleteItemsVendor();
      } else {
        this.getVendorReportData();
      }
    }, error => {
      alert('Error from azzure:' + error.message)
    }) 
  }
  getVendorReportData() {
    this.vendorYear[this.processIndex].status = "Updating" + " " + this.bucket;
    this.totalCount = 0;
    this.balfwd = 0;
    this.outgoing = [[],[],[],[],[],[],[],[],[],[],[],[],[],[]];
    this.transfering = [[],[],[],[],[],[],[],[],[],[],[],[],[],[]];
    this.orderService.getBucketList(this.vendorYear[this.processIndex].salesYear, this.monthSelected, this.vendorYear[this.processIndex].vendorId, 0, 0, this.bucket, this.report, this.orderType).subscribe(data => {
      this.creditData = data.creditData;
      this.transfer = data.transferData;
      this.balanceForwardCredit = data.balanceForwardCredit;
      this.balanceForwardActual = data.balanceForwardActual;
      this.balanceForwardBudget = data.balanceForwardBudget;
      this.balanceForwardTransfer = data.balanceForwardTransfer;
      this.balfwd = this.balanceForwardCredit + this.balanceForwardTransfer;
      this.loadArraysVendor();
      this.totalCount = 0;
      this.processIndexIncoming = -1;
      this.processIndexIncomingItems = -1;
      this.processIndexOutgoing = -1;
      this.processIndexOutgoingItems = -1;
      this.processIndexTransfering = -1;
      this.processIndexTransferingItems = -1;
      this.kstLineCount = 0;
      this.kstTotalExtension = 0;
      if (this.firstPost == true) {
        this.insertBalFwdVendor();
        this.firstPost = false;
      } 
      this.initializeOutgoingVendor();
    })
  }
  insertBalFwdVendor() {
    const year = this.vendorYear[this.processIndex].salesYear;
    let firstDate = new Date(Date.UTC(year, 0, 2, 0, 0, 0));
    let dateFormat = firstDate.toISOString();
    let x = new VendorReportData();
    x.id = 0;
    x.category = 'Balance Forward';
    x.period = this.getPeriodName(dateFormat);
    x.year = year;
    x.date = firstDate;
    x.vendor = this.vendorYear[this.processIndex].vendorName;
    x.vendorId = this.vendorYear[this.processIndex].vendorId;
    x.balance = this.balFwdBeginningMonth[0];
    this.createVendor(x);
  }
  loadArraysVendor(){
    this.creditData.forEach(element => {
      const period = this.getPeriod(element.creditDate);
      element.brokerCompany = this.getBroker(element.brokerId, element.brokerCompany, element.brokerId2, element.brokerCompany2);
      this.outgoing[period].push(element);
    });
    this.transfer.forEach(element => {
      const period = this.getPeriod(element.transferDate);
      this.transfering[period].push(element);
    });

    this.balFwdBeginningMonth.forEach((element, index) => {
      this.balFwdBeginningMonth[index] = this.balfwd;
      element = this.balfwd;
      this.balEndCurrentMonth[index] = this.balfwd;
      this.incoming[index].forEach(element2 => {
        this.balEndCurrentMonth[index] += element2.budgetAmount;
        element2.balance = this.balEndCurrentMonth[index];
      })

      this.outgoing[index].forEach(element3 => {
        this.balEndCurrentMonth[index] += element3.creditAmount;
        element3.balance = this.balEndCurrentMonth[index];
      })

      this.transfering[index].forEach(element4 => {
        if (element4.fromBucket == this.bucket) {
          element4.debit = element4.transferAmount;
        } else {
          element4.credit = element4.transferAmount;
        }
        
        this.balEndCurrentMonth[index] += element4.credit - element4.debit;
        element4.balance = this.balEndCurrentMonth[index];
      })
      this.balfwd = this.balEndCurrentMonth[index];
    })
  }
  createVendor(row) {
    this.vendorReportService.create(row).subscribe(data => {
    })
  }
  initializeOutgoingVendor() {
    //insert data into azure dealing with outgoing array
    this.processIndexOutgoing++;
    if (this.processIndexOutgoing >= this.outgoing.length) {
      this.processIndexTransfering = -1;
      this.initializeTransferingVendor();
    } else {
      if (this.outgoing[this.processIndexOutgoing].length == 0) {
        this.initializeOutgoingVendor();
      }
      this.processIndexOutgoingItems = -1;
      this.insertOutgoingVendor();
    }
  }
  insertOutgoingVendor() {
    //insert into azure outgoing array one subarray at a time
    this.processIndexOutgoingItems++;
    if (this.processIndexOutgoingItems >= this.outgoing[this.processIndexOutgoing].length) {
      this.initializeOutgoingVendor();
    } else {
      this.totalCount++;
      this.vendorYear[this.processIndex].counter = this.totalCount;
      const creditDate = this.outgoing[this.processIndexOutgoing][this.processIndexOutgoingItems].creditDate;
      let dateFormat = new Date(this.outgoing[this.processIndexOutgoing][this.processIndexOutgoingItems].creditDate);
      let x = new VendorReportData();
      x.id = 0;
      x.category = 'Outgoing';
      x.period = this.getPeriodName(creditDate);
      x.year = dateFormat.getFullYear();
      x.status = this.outgoing[this.processIndexOutgoing][this.processIndexOutgoingItems].creditStatus;
      x.type = this.outgoing[this.processIndexOutgoing][this.processIndexOutgoingItems].creditType;
      x.no = this.outgoing[this.processIndexOutgoing][this.processIndexOutgoingItems].creditNo;
      x.date = creditDate;
      x.customer = this.outgoing[this.processIndexOutgoing][this.processIndexOutgoingItems].customerCompany;
      x.vendor = this.outgoing[this.processIndexOutgoing][this.processIndexOutgoingItems].vendorCompany;
      x.vendorId = this.outgoing[this.processIndexOutgoing][this.processIndexOutgoingItems].vendorId;
      x.customerCreditNo = this.outgoing[this.processIndexOutgoing][this.processIndexOutgoingItems].customerCreditNo;
      x.creditAmount = this.outgoing[this.processIndexOutgoing][this.processIndexOutgoingItems].creditAmount;
      x.notes = this.outgoing[this.processIndexOutgoing][this.processIndexOutgoingItems].creditPromotionExplaination;
      x.balance = 0;

      const ext = x.balance === null ? 0 : x.balance;
      this.kstTotalExtension += ext;
      this.kstLineCount += ext > 0 ? 1 : 0;

      this.createVendor(x);
      this.insertOutgoingVendor(); 
    }
  }
  initializeTransferingVendor() {
    //continue inserting data into azure now dealing with transferring array
    this.processIndexTransfering++;
    if (this.processIndexTransfering >= this.transfering.length) {
      //all arrays have been posted to azure for vendor. all reports have been run, update status as complete.
      this.updateDataLink();
      this.updateStatus();
    } else {
      if (this.transfering[this.processIndexTransfering].length == 0) {
        this.initializeTransferingVendor();
      }
      this.processIndexTransferingItems = -1;
      this.insertTransferingVendor();
    }
  }
  insertTransferingVendor() {
    //insert into azure transferring array one subarray at a time
    this.processIndexTransferingItems++;
    if (this.processIndexTransferingItems >= this.transfering[this.processIndexTransfering].length) {
      this.initializeTransferingVendor();
    } else {
      this.totalCount++;
      this.vendorYear[this.processIndex].counter = this.totalCount;
      const transferDate = this.transfering[this.processIndexTransfering][this.processIndexTransferingItems].transferDate;
      const bucketFrom = this.transfering[this.processIndexTransfering][this.processIndexTransferingItems].fromBucket;
      const bucketTo = this.transfering[this.processIndexTransfering][this.processIndexTransferingItems].toBucket;
      let dateFormat = new Date(this.transfering[this.processIndexTransfering][this.processIndexTransferingItems].transferDate);
      let x = new VendorReportData();
      x.id = 0;
      x.category = 'Transfering';
      x.period = this.getPeriodName(transferDate);
      x.year = dateFormat.getFullYear();
      x.status = this.transfering[this.processIndexTransfering][this.processIndexTransferingItems].transferStatus;
      x.type = this.transfering[this.processIndexTransfering][this.processIndexTransferingItems].orderType1;
      x.no = this.transfering[this.processIndexTransfering][this.processIndexTransferingItems].transferNo;
      x.date = transferDate;
      x.customer = this.transfering[this.processIndexTransfering][this.processIndexTransferingItems].billTo;
      x.vendor = this.transfering[this.processIndexTransfering][this.processIndexTransferingItems].vendor;
      x.vendorId = this.transfering[this.processIndexTransfering][this.processIndexTransferingItems].vendorId;
      x.credit = this.transfering[this.processIndexTransfering][this.processIndexTransferingItems].credit;
      x.debit = this.transfering[this.processIndexTransfering][this.processIndexTransferingItems].debit;
      x.balance = 0;
      x.notes = this.transfering[this.processIndexTransfering][this.processIndexTransferingItems].notes;
      x.bucketTransfer = bucketFrom + " " + "to" + " " + bucketTo;

      const ext = x.balance === null ? 0 : x.balance;
      this.kstTotalExtension += ext;
      this.kstLineCount += ext > 0 ? 1 : 0;

      this.createVendor(x);
      this.insertTransferingVendor(); 
    }
  }
  deleteItemsVendor() {
    this.vendorYear[this.processIndex].status = "Deleting" + " " + this.bucket;
    this.totalCount++;
    this.vendorYear[this.processIndex].counter = this.totalCount;
    this.processIndexItem++;
    if (this.processIndexItem < this.items.length) {
      this.vendorReportService.remove(this.items[this.processIndexItem].id).subscribe(data => {
        this.deleteItemsVendor();
      }, error => {
        alert('Error received from Azure: ' + error.message);
      })
    } else {
      this.getItemsVendor();
    }
  }

  updateDataLink() {
    this.dataLink = new DataLink();
    this.vendorYear[this.processIndex].status = this.bucket + " " + "Completed";
    this.buttonClicker[this.processIndex] = false;
    const todaysDate = new Date();
    this.dataLink.dataLinkId = this.vendorYear[this.processIndex].dataLinkId;
    this.dataLink.lastUpdate = todaysDate;
    this.vendorYear[this.processIndex].lastUpdate = todaysDate;
    this.dataLink.userId = this.getUserId();
    this.vendorYear[this.processIndex].name = this.getUserName();
    this.dataLinkService.update(this.dataLink).subscribe(data => {
      // alert("Click Ok to process Budgets. " + "\r\n" + "Total Sales Items: " + this.kstLineCount + "\r\n" + " Total Extension Case Cost "+ this.kstTotalExtension.toFixed(2));
    })
  }
  updateDataLinkBudget() {
    this.dataLink = new DataLink();
    this.vendorYear[this.processIndex].status = "Budget Completed";
    this.buttonClicker[this.processIndex] = false;
    const todaysDate = new Date();
    this.dataLink.dataLinkId = this.vendorYear[this.processIndex].dataLinkId;
    this.dataLink.lastUpdate = todaysDate;
    this.vendorYear[this.processIndex].lastUpdate = todaysDate;
    this.dataLink.userId = this.getUserId();
    this.vendorYear[this.processIndex].name = this.getUserName();
    this.dataLinkService.update(this.dataLink).subscribe(data => {
      // alert("All done. " + "\r\n" + "Total Budget Items: " + this.kstLineCount + "\r\n" + " Total Budget Extension Case Cost "+ this.kstTotalExtension.toFixed(2));
    })
  }
  getUserId() {
    let userId = 0;
    const currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
    if (currentUser) {
      userId = currentUser.id;  
    }
    return userId;
  }
  getUserName() {
    let userName = '';
    const currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
    if (currentUser) {
      userName = currentUser.firstName;  
    }
    return userName;
  }
  addVendors() {
    this.dataLinks = [];
    this.vendor.forEach(vend => {
      const vendIndex = this.vendorYear.findIndex(v => v.vendorId == vend.id);
      if (vendIndex == -1) {
        this.dataLink = new DataLink();
        this.dataLink.salesYear = this.yearRequested * 1;
        this.dataLink.vendorId = vend.id
        this.dataLinks.push(this.dataLink);
      }
    })
    this.processVendIndex = -1;
    this.buttonLabel = 'Adding more vendors ...'
    this.addVend();
  }
  
  addVend() {
    this.processVendIndex++;
    if (this.processVendIndex < this.dataLinks.length) {
      this.dataLinkService.create(this.dataLinks[this.processVendIndex]).subscribe(data => {
        this.addVend();
      }, error => {
        this.addVend();
      })
    } else {
      alert('Added more vendors completed. Press enter to Refresh ');
      window.location.reload();
    }
  }
  
  removeVendor( index: number ) {
    this.selectedVendor = index;
    const venData = this.vendorYear[this.selectedVendor].dataLinkId;
  
    if (venData) {
      if (confirm('Delete this item?'))
      this.dataLinkService.remove(venData).subscribe(data => {
        this.router.navigate(['/portal/azure-link']);
      });
      }
    }
    updateStatus() {
      this.bucket = '';
      alert('Year ' + this.yearRequested + ' successfully updated.');
    }
}
