<h5>{{ title }} </h5>
<form [formGroup]="borderCrossingForm" (ngSubmit)="onSubmit()" novalidate>
  <div class="form-group">
    <div class="col-lg-44">
      <label>
        Area:
        <input type="text" formControlName="area" class="form-control"  size="60">
      </label>
    </div>
    <div class="col-lg-44">
        <label>
          Canada Port Of Entry Name:
          <input type="text" formControlName="canadaPortOfEntryName" class="form-control"  size="60">
        </label>
    </div>
  
    <div class="col-lg-44">
        <label>
          Canada Road Highway:
          <input type="text" formControlName="canadaRoadHighway" class="form-control"  size="60">
        </label>
    </div>

    <div class="col-lg-44">
        <label>
            United States Port Of Entry Name:
          <input type="text" formControlName="unitedStatesPortOfEntryName" class="form-control"  size="60">
        </label>
    </div>

    <div class="col-lg-44">
        <label>
            United States Road Highway Community:
          <input type="text" formControlName="unitedStatesRoadHighwayCommunity" class="form-control"  size="60">
        </label>
    </div>

    <div class="col-lg-44">
        <label>
            Notes:
          <input type="textarea" formControlName="notes" class="form-control"  size="60">
        </label>
    </div>

    <div class="col-lg-44">
        <label>
            Structure Or Notable Feature:
          <input type="text" formControlName="structureOrNotableFeature" class="form-control"  size="60">
        </label>
    </div>

    <div class="col-lg-44">
        <label>
            Coordinates:
          <input type="text" formControlName="coordinates" class="form-control"  size="60">
        </label>
    </div>

    <button type="submit" class="btn btn-primary mt-3 w-25">Save</button>
    <button type="button" class="btn btn-secondary ml-3 mt-3 w-25" (click)="onDelete()">Delete</button>
  </div>
    <ngb-alert *ngIf="successMessage" type="success" (close)="successMessage = null">{{ successMessage }}</ngb-alert>
</form>