import { Component, OnInit, ChangeDetectorRef  } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { OrderService } from '../../../services/order.service';
import { OrderItemsService } from '../../../services/order-items.service';
import { BudgetActuals } from '../../../models/budget-actuals.model';
import { BudgetActualsService } from '../../../services/budget-actuals.service';
import * as jsPDF from 'jspdf';
import * as html2pdf from 'html2pdf.js';
import { Big } from 'big.js';
import { OrderItems } from '../../../models/orderItems.model';
// import { analyzeAndValidateNgModules } from '@angular/compiler';

@Component({
  selector: 'app-profit-list',
  templateUrl: './profit-list.component.html',
  styleUrls: ['./profit-list.component.scss']
})
export class ProfitListComponent implements OnInit {
  orders: any;
  orderSelected: any;
  orderItems: any;
  itemsUSD: OrderItems[];

  page = 1;
  pageSize = 30;
  collectionSize = 0;
  type: string;
  // orderStatusOptions = [];
  orderNumberOptions = [];
  purchaseOrderOptions = [];
  billToOptions = [];
  shipToOptions = [];
  vendorOptions = [];
  orderStatusSelected = 'Invoiced';
  orderNumberSelected = 0;
  purchaseOrderSelected = 0;
  billToSelected = 0;
  shipToSelected = 0;
  vendorSelected = 0;
  filterSelections = [
    {Name: 'Status', Active: false, order: 0, reload: true },
    {Name: 'Number', Active: false, order: 0, reload: true},
    {Name: 'Purchase', Active: false, order: 0, reload: true},
    {Name: 'Bill', Active: false, order: 0, reload: true},
    {Name: 'Ship', Active: false, order: 0, reload: true},
    {Name: 'vend', Active: false, order: 0, reload: true}];
  sortOrder = [];
  sortItemsCount = 0;
  statusIndex = 0;
  numberIndex = 1;
  purchaseindex = 2;
  billIndex = 3;
  shipIndex = 4;
  vendorIndex = 5;
  currentIndex: number;
  title: string;
  pageBreak: any;
  reportName = '';
  reportNames = [
    {
    Name: 'Sales Order Profit & Loss', 
    Id: 'my-profit-loss',
    filename: 'Profit Loss',
    Orientation: 'landscape',
    TopMargin: .5,
    LRMargin: .2
    }
  ];
  totalCases: number;
  totalShipped: number;
  totalInvoiced: number;
  totalExtCost: number;
  totalMarketing: number;
  totalBilling: number;
  totalTerms: number;
  totalCommission: number;
  totalKalcor: number;
  cndFrtCostCase = [];
  storageCostCase = [];
  totalCndFrtCostCase: number;
  totalStorageCostCase: number;
  totalPurchaseCaseCostExt: number;
  totalFreightCostExt: number;
  totalClearing: number;
  totalDuty: number;
  totalRoyalty: number;
  totalVendor: number;
  usdVendorCost = [];  
  usdFrtCost = [];
  usdClearing = [];
  usdDuty = [];
  usdRoyalty = [];
  indexMarketing: number;
  indexBilling: number;
  indexTerms: number;
  indexCommission: number;
  indexKalcor: number;
  indexFreightCnd: number;
  indexStorage: number;
  indexVendor: number;
  indexClearing: number;
  indexDuty: number;
  indexFreightUSD: number;
  indexRoyalty: number;  
  indexFobDiff: number;
  exchangeProduct: number;
  budgetActual = [
    {
      id: 0,
      sortNumber: 0,
      edit: 'N',
      funds: 'CND',
      item: 'Sales',
      budgetAmount: 0,
      difference: 0,
      actualAmount: 0,
      company: '',
      reference: '',
      confirm: '',
      freightType: '',
      freightCost: 0,
      freightCostSales: 0,
      freightTotalCost: 0,
      commission1: 0,
      commission2: 0      
    },
    {
      id: 0,
      sortNumber: 100,
      edit: 'N',
      funds: 'CND',
      item: 'Marketing',
      budgetAmount: 0,
      difference: 0,
      actualAmount: 0,
      company: '',
      reference: '',
      confirm: '',
      freightType: '',
      freightCost: 0,
      freightCostSales: 0,
      freightTotalCost: 0,
      commission1: 0,
      commission2: 0      
    },
    {
      id: 0,
      sortNumber: 200,
      edit: 'Y',
      funds: 'CND',
      item: 'Billing',
      budgetAmount: 0,
      difference: 0,
      actualAmount: 0,
      company: '',
      reference: '',
      confirm: '',
      freightType: '',
      freightCost: 0,
      freightCostSales: 0,
      freightTotalCost: 0,
      commission1: 0,
      commission2: 0

    },
    {
      id: 0,
      sortNumber: 300,
      edit: 'Y',
      funds: 'CND',
      item: 'Terms',
      budgetAmount: 0,
      difference: 0,
      actualAmount: 0,
      company: '',
      reference: '',
      confirm: '',
      freightType: '',
      freightCost: 0,
      freightCostSales: 0,
      freightTotalCost: 0,
      commission1: 0,
      commission2: 0      
    },
    {
      id: 0,
      sortNumber: 400,
      edit: 'N',
      funds: 'CND',
      item: 'Commission',
      budgetAmount: 0,
      difference: 0,
      actualAmount: 0,
      company: 'Kalcor Marketing Ltd',
      reference: '',
      confirm: 'YES',
      freightType: '',
      freightCost: 0,
      freightCostSales: 0,
      freightTotalCost: 0,
      commission1: 0,
      commission2: 0      
    },
    {
      id: 0,
      sortNumber: 500,
      edit: 'N',
      funds: 'CND',
      item: 'Kalcor',
      budgetAmount: 0,
      difference: 0,
      actualAmount: 0,
      company: 'Kalcor Marketing Ltd',
      reference: '',
      confirm: 'YES',
      freightType: '',
      freightCost: 0,
      freightCostSales: 0,
      freightTotalCost: 0,
      commission1: 0,
      commission2: 0      
    },
    {
      id: 0,
      sortNumber: 600,
      edit: 'Y',
      funds: 'CND',
      item: 'Freight',
      budgetAmount: 0,
      difference: 0,
      actualAmount: 0,
      company: '',
      reference: '',
      confirm: '',
      freightType: '',
      freightCost: 0,
      freightCostSales: 0,
      freightTotalCost: 0,
      commission1: 0,
      commission2: 0
    },
    {
      id: 0,
      sortNumber: 700,
      edit: 'N',
      funds: '',
      item: '',
      budgetAmount: 0,
      difference: 0,
      actualAmount: 0,
      company: '',
      reference: '',
      confirm: '',
      freightType: 'CND Freight',
      freightCost: 0,
      freightCostSales: 0,
      freightTotalCost: 0,
      commission1: 0,
      commission2: 0
    },
    {
      id: 0,
      sortNumber: 800,
      edit: 'Y',
      funds: 'CND',
      item: 'Storage',
      budgetAmount: 0,
      difference: 0,
      actualAmount: 0,
      company: '',
      reference: '',
      confirm: '',
      freightType: '',
      freightCost: 0,
      freightCostSales: 0,
      freightTotalCost: 0,
      commission1: 0,
      commission2: 0
    },
    {
      id: 0,
      sortNumber: 900,
      edit: 'N',
      funds: 'CND',
      item: 'Net CND',
      budgetAmount: 0,
      difference: 0,
      actualAmount: 0,
      company: '',
      reference: '',
      confirm: '',
      freightType: '',
      freightCost: 0,
      freightCostSales: 0,
      freightTotalCost: 0,
      commission1: 0,
      commission2: 0
    },
    {
      id: 0,
      sortNumber: 1000,
      edit: 'N',
      funds: 'CND',
      item: 'FOB Difference Exchange',
      budgetAmount: 0,
      difference: 0,
      actualAmount: 0,
      company: '',
      reference: '',
      confirm: '',
      freightType: '',
      freightCost: 0,
      freightCostSales: 0,
      freightTotalCost: 0,
      commission1: 0,
      commission2: 0
    },
    {
      id: 0,
      sortNumber: 1100,
      edit: 'N',
      funds: 'CND',
      item: 'FOB Net Return',
      budgetAmount: 0,
      difference: 0,
      actualAmount: 0,
      company: '',
      reference: '',
      confirm: '',
      freightType: '',
      freightCost: 0,
      freightCostSales: 0,
      freightTotalCost: 0,
      commission1: 0,
      commission2: 0
    },
    {
      id: 0,
      sortNumber: 1200,
      edit: 'N',
      funds: '',
      item: 'Exchange',
      budgetAmount: 0,
      difference: 0,
      actualAmount: 0,
      company: '',
      reference: '',
      confirm: '',
      freightType: '',
      freightCost: 0,
      freightCostSales: 0,
      freightTotalCost: 0,
      commission1: 0,
      commission2: 0
    },
    {
      id: 0,
      sortNumber: 1300,
      edit: 'N',
      funds: 'USD',
      item: 'Vendor Purchase Case Cost',
      budgetAmount: 0,
      difference: 0,
      actualAmount: 0,
      company: '',
      reference: '',
      confirm: '',
      freightType: '',
      freightCost: 0,
      freightCostSales: 0,
      freightTotalCost: 0,
      commission1: 0,
      commission2: 0
    },
    {
      id: 0,
      sortNumber: 1350,
      edit: 'N',
      funds: 'USD',
      item: 'Royalty',
      budgetAmount: 0,
      difference: 0,
      actualAmount: 0,
      company: '',
      reference: '',
      confirm: '',
      freightType: '',
      freightCost: 0,
      freightCostSales: 0,
      freightTotalCost: 0,
      commission1: 0,
      commission2: 0
    },    
    {
      id: 0,
      sortNumber: 1400,
      edit: 'Y',
      funds: 'USD',
      item: 'Clearing',
      budgetAmount: 0,
      difference: 0,
      actualAmount: 0,
      company: '',
      reference: '',
      confirm: '',
      freightType: '',
      freightCost: 0,
      freightCostSales: 0,
      freightTotalCost: 0,
      commission1: 0,
      commission2: 0
    },
    {
      id: 0,
      sortNumber: 1500,
      edit: 'Y',
      funds: 'USD',
      item: 'Duty',
      budgetAmount: 0,
      difference: 0,
      actualAmount: 0,
      company: '',
      reference: '',
      confirm: '',
      freightType: '',
      freightCost: 0,
      freightCostSales: 0,
      freightTotalCost: 0,
      commission1: 0,
      commission2: 0
    },
    {
      id: 0,
      sortNumber: 1600,
      edit: 'Y',
      funds: 'USD',
      item: 'Freight',
      budgetAmount: 0,
      difference: 0,
      actualAmount: 0,
      company: '',
      reference: '',
      confirm: '',
      freightType: '',
      freightCost: 0,
      freightCostSales: 0,
      freightTotalCost: 0,
      commission1: 0,
      commission2: 0
    },
    {
      id: 0,
      sortNumber: 1700,
      edit: 'N',
      funds: '',
      item: '',
      budgetAmount: 0,
      difference: 0,
      actualAmount: 0,
      company: '',
      reference: '',
      confirm: '',
      freightType: 'USD Freight',
      freightCost: 0,
      freightCostSales: 0,
      freightTotalCost: 0,
      commission1: 0,
      commission2: 0
    },
    {
      id: 0,
      sortNumber: 1800,
      edit: 'Y',
      funds: 'USD',
      item: 'Vendor',
      budgetAmount: 0,
      difference: 0,
      actualAmount: 0,
      company: '',
      reference: '',
      confirm: '',
      freightType: '',
      freightCost: 0,
      freightCostSales: 0,
      freightTotalCost: 0,
      commission1: 0,
      commission2: 0
    }
  ];
  budgetActuals: BudgetActuals[];
  constructor(private router: Router,
    private orderService: OrderService,
    private route: ActivatedRoute,
    private checkRef: ChangeDetectorRef,
    private orderItemService: OrderItemsService,
    private budgetActualService: BudgetActualsService) { }

  ngOnInit() {
    this.route.data.subscribe(order => {
      this.orders = order.dataProfitLoss.order;
      this.collectionSize = order.dataProfitLoss.count;
      this.page = order.dataProfitLoss.page;
      this.pageSize = order.dataProfitLoss.pageSize;
      this.getSales();
    });
  }
  resetFilters() {
    if (this.purchaseOrderSelected || this.orderNumberSelected) {
      const selected = this.purchaseOrderSelected ? this.purchaseOrderSelected : this.orderNumberSelected;
      this.orderService.getFilteredList(selected, '', '', 0, 0, 0, this.page, this.pageSize).subscribe(data => {
        this.page = 1;
        this.collectionSize = 1;
        this.orders = data.order;
        this.getSales();
      })
      return;
    }

    if (this.orderStatusSelected || this.billToSelected || this.shipToSelected || this.vendorSelected) {
      let billTo = 0;
      let shipTo = 0;
      let vendor = 0;
      if (this.billToSelected) {
        billTo = this.billToSelected;
        this.setUpOrder(this.billIndex);
      }
      
      if (this.shipToSelected) {
        shipTo = this.shipToSelected;
        this.setUpOrder(this.shipIndex);
      } 

      if (this.orderStatusSelected) {
        this.setUpOrder(this.statusIndex);        
      }
      if (this.vendorSelected) {
        vendor = this.vendorSelected;
        this.setUpOrder(this.vendorIndex);
      }
       this.orderService.getFilteredList(0, this.orderStatusSelected, '', billTo, shipTo, vendor, this.page, this.pageSize).subscribe(data => {
          this.orders = data.order;
          this.collectionSize = data.count;
          this.getSales();
          // if (this.filterSelections[this.statusIndex].order == 0) {
          //   this.orderService.getStatusOptions(this.orderStatusSelected, billTo, shipTo, vendor).subscribe(status => this.orderStatusOptions = status);
          // }
          this.orderService.getOrderNumberOptions(this.orderStatusSelected, billTo, shipTo, vendor).subscribe(orderNumber => this.orderNumberOptions = orderNumber);
          this.orderService.getPurchaseOrderOptions(this.orderStatusSelected, billTo, shipTo, vendor).subscribe(purchase => this.purchaseOrderOptions = purchase);

          if (this.filterSelections[this.billIndex].order == 0) {
            this.orderService.getBillToOptions(this.orderStatusSelected, billTo, shipTo, vendor).subscribe(bill => this.billToOptions = bill);
          }
          if (this.filterSelections[this.shipIndex].order == 0) {
            this.orderService.getShipToOptions(this.orderStatusSelected, billTo, shipTo, vendor).subscribe(ship => this.shipToOptions = ship);
          }
          if (this.filterSelections[this.vendorIndex].order == 0) {
            this.orderService.getVendorOptions(this.orderStatusSelected, billTo, shipTo, vendor).subscribe(vendor => this.vendorOptions = vendor);
          }
        })
       return;
    }
    
    this.getDefaultDataScreen('Invoiced');
  }
  setUpOrder(itemIndex) {
    if (this.filterSelections[itemIndex].order == 0) {
        this.sortItemsCount++;
        this.filterSelections[itemIndex].order = this.sortItemsCount;
    }
  }
  getDefaultDataScreen(option) {
    this.orderStatusSelected = option;
    this.orderNumberSelected = 0;
    this.purchaseOrderSelected = 0;
    this.billToSelected = 0;
    this.shipToSelected = 0;
    this.vendorSelected = 0;
    this.sortOrder = ['Status'];
        
    this.orderService.getFilteredList(0, this.orderStatusSelected, '', 0, 0, 0, this.page, this.pageSize).subscribe( order => {
        this.orders = order.order;
        this.collectionSize = order.count;
        this.getSales();
    });
    // this.orderService.getStatusOptions('', 0, 0, 0).subscribe(status => this.orderStatusOptions = status);
    this.orderService.getOrderNumberOptions(this.orderStatusSelected, 0, 0, 0).subscribe(orderNumber => this.orderNumberOptions = orderNumber);
    this.orderService.getPurchaseOrderOptions(this.orderStatusSelected, 0, 0, 0).subscribe(purchase => this.purchaseOrderOptions = purchase);
    this.orderService.getBillToOptions(this.orderStatusSelected, 0, 0, 0).subscribe(bill => this.billToOptions = bill);
    this.orderService.getShipToOptions(this.orderStatusSelected, 0, 0, 0).subscribe(ship => this.shipToOptions = ship);
    this.orderService.getVendorOptions(this.orderStatusSelected, 0, 0, 0).subscribe(vendor => this.vendorOptions = vendor);
  }
  loadPage($event) {
    this.resetFilters();
  }
  getSales() {
    this.orders.forEach(element => {
      this.orderService.getOneSum(element.id).subscribe(data => {
        if (data.length > 0) {
          const orderIndex = this.orders.findIndex(o => o.salesOrderNo.trim() == data[0].salesOrderNo);
          if (orderIndex > -1) {
          this.orders[orderIndex].salesAmount = data[0].salesAmountInvoiced;
          }
        }
      })
    });
  }
  displayDate(dt, t) {
  
    if (dt) {
      if (t === undefined) {
        t = '';
      }
      let x = new Date(dt);
      if (t == 'T') {
        return x.toLocaleDateString() + ' @ ' + x.toLocaleTimeString();
      }
      return x.toLocaleDateString();
    } 
    return dt

  }
  onRowClicked(order) {
    this.router.navigate(['/accounts/profit-lost-edit',  order.id]);
  }

  printProcess(report, ord) {
    this.orderSelected = ord;
    this.currentIndex = this.reportNames.findIndex(r => r.Name == report);
    this.orderItemService.getBySalesNumber(this.orderSelected.salesOrderNo).subscribe(data => {
      this.orderItems = data;
      this.itemsUSD = [];
      this.orderItems.forEach( item => {

        let itemCopied = new OrderItems();
        itemCopied.orderCode = item.orderCode;
        itemCopied.brandCategory = item.brandCategory;
        itemCopied.size = item.size;
        itemCopied.cases = item.cases;
        itemCopied.shipped = item.shipped;
        itemCopied.invoiced = item.invoiced;
        itemCopied.purchaseCaseCost = item.purchaseCaseCost;
        itemCopied.extCaseCost = item.extCaseCost;
        itemCopied.clearingCs = item.clearingCs;
        itemCopied.freightCs = item.freightCs;
        itemCopied.duty = item.duty;
        itemCopied.royalty = item.royalty;

        this.itemsUSD.push(itemCopied);
        this.cndFrtCostCase.push(0);
        this.storageCostCase.push(0);
        this.usdVendorCost.push(0);
        this.usdFrtCost.push(0);
        this.usdClearing.push(0);
        this.usdDuty.push(0);
        this.usdRoyalty.push(0);
        if (!this.orderSelected.exchangeBudget) {
          if (item.exchangeBudget) {
            this.orderSelected.exchangeBudget = item.exchangeBudget;
          }
        }
      })

      if (this.orderSelected.salesOrderNo) {
        this.budgetActualService.getBySalesNumber(this.orderSelected.salesOrderNo).subscribe(budget => {
          this.budgetActuals = budget;
          this.budgetActuals.forEach(item => {
            const orderIndex = this.budgetActual.findIndex(o => o.sortNumber == item.sortNumber);
            this.budgetActual[orderIndex].id = item.id;
            this.budgetActual[orderIndex].actualAmount = item.actualAmount;
            this.budgetActual[orderIndex].company = item.company.trim() ? item.company : this.budgetActual[orderIndex].company;
            this.budgetActual[orderIndex].reference = item.reference.trim();
            this.budgetActual[orderIndex].confirm = item.confirm.trim() ? item.confirm : this.budgetActual[orderIndex].confirm;
          if (item.item == 'Exchange') {
              this.budgetActual[orderIndex].budgetAmount = item.budgetExchange;
              //3.9.2023 Changed item.budgetAmount to item.budgetExchange
              //this.budgetActual[orderIndex].budgetAmount = item.budgetAmount;
              this.budgetActual[orderIndex].actualAmount = item.actualExchange;
            }
          });
          this.printIt();
        });
      } else {
        this.printIt();
      }
    })
  }
  printIt() {
    this.calculateTotals();
    this.title = this.reportNames[this.currentIndex].Name;
    this.reportName = this.reportNames[this.currentIndex].Name;
    this.checkRef.detectChanges();
    let fileName = '';
    fileName += this.orderSelected.vendor ? this.orderSelected.vendor.trim() : '';
    fileName += this.orderSelected.purchaseOrder ? ' – PO ' + this.orderSelected.purchaseOrder.trim() : '';
    fileName += ' – Profit Loss';
    fileName += this.orderSelected.shipTo ? ' – ' + this.orderSelected.shipTo.trim() : '';
    
    this.pageBreak = {};
    this.pageBreak.mode = 'avoid-all';
    if (this.orderItems.length > 12) {
      this.pageBreak.before = ['.break-CND', '.break-USD'];
    } else {
      if (this.orderItems.length > 2) {
        this.pageBreak.before = '.break-USD';
      }
    }

    var element = document.getElementById(this.reportNames[this.currentIndex].Id);
    var opt = {
      margin:       [this.reportNames[this.currentIndex].TopMargin, this.reportNames[this.currentIndex].LRMargin],
      filename:     fileName + '.pdf',
      pagebreak:    this.pageBreak,
      image:        { type: 'jpeg', quality: 0.98 },
      html2canvas:  { scale: 2 },
      jsPDF:        { unit: 'in', format: 'letter', orientation: this.reportNames[this.currentIndex].Orientation }
    };
    html2pdf().set(opt).from(element).save();
    this.reportName = '';

  }
  calculateTotals() {
    this.totalCases = 0;
    this.totalShipped = 0;
    this.totalInvoiced = 0;
    this.totalExtCost = 0;
    this.totalMarketing = 0;
    this.totalBilling = 0;
    this.totalTerms = 0;
    this.totalCommission = 0;
    this.totalKalcor = 0;
    this.exchangeProduct = 0;
    this.cndFrtCostCase = [];
    this.storageCostCase = [];
    this.totalCndFrtCostCase = 0;
    this.totalStorageCostCase = 0;
    this.totalPurchaseCaseCostExt = 0;
    this.totalFreightCostExt = 0;
    this.totalClearing = 0;
    this.totalDuty = 0;
    this.totalRoyalty = 0;
    this.totalVendor = 0;
    let bigNum: any;
    this.orderItems.forEach(element => {
      // table 1
      const invoiced = element.invoiced ? element.invoiced : 0;
      const caseCost = element.caseCost ? element.caseCost : 0;
      bigNum = Big(invoiced)
      .times(caseCost)
      .round(2);
      const extCaseCost = bigNum * 1;
      element.extCaseCost = extCaseCost;      
      this.totalCases += element.cases ? element.cases : 0;
      this.totalShipped += element.shipped ? element.shipped : 0;
      this.totalInvoiced += invoiced;
      this.totalExtCost += extCaseCost;
      const marketing1 = element.marketing1 ? element.marketing1 : 0;
      const billing1 = element.billing1 ? element.billing1 : 0;
      const terms1 = element.terms1 ? element.terms1 : 0;

      bigNum = Big(extCaseCost)
        .times(marketing1)
        .times(.01)
        .round(2);
        element.marketing = bigNum * 1;
      this.totalMarketing += element.marketing;

      bigNum = Big(extCaseCost)
        .times(billing1)
        .times(.01)
        .round(2);
        element.billing = bigNum * 1;
        this.totalBilling += element.billing;
      bigNum = Big(extCaseCost)
        .times(terms1)
        .times(.01)
        .round(2);
        element.terms = bigNum * 1;
      this.totalTerms += element.terms;

    // table 2
      const commission1 = element.commission1 ? element.commission1 : 0;
      bigNum = Big(extCaseCost)
        .times(commission1)
        .times(.01)
        .round(2);
      element.commission11 = bigNum * 1;

      const commission2 = element.commission2 ? element.commission2 : 0;
      bigNum = Big(extCaseCost)
        .times(commission2)
        .times(.01)
        .round(2);
      element.commission21 = bigNum * 1;
      this.totalCommission += element.commission11 + element.commission21;

      const kalcor1 = element.kalcor1 ? element.kalcor1 : 0;
      bigNum = Big(extCaseCost)
        .times(kalcor1)
        .times(.01)
        .round(2);
      element.kalcor = bigNum * 1;
      this.totalKalcor += element.kalcor;

      //exchangeProduct     
      if (this.orders.orderType != 'Stock') {
        const fobDiff = element.fobDifference ? element.fobDifference : 0;
        bigNum = Big(invoiced)
        .times(fobDiff)
        .round(4);
        this.exchangeProduct += bigNum * 1;
      } 
      
      const cndFrtCostCase = element.cndFrtCostCase ? element.cndFrtCostCase : 0;
      bigNum = Big(cndFrtCostCase)
        .times(invoiced)
        .round(2);
      const cndFrtCostCaseExt = bigNum * 1;
      this.cndFrtCostCase.push(cndFrtCostCaseExt);

      const storageCostCase = element.storageCostCase ? element.storageCostCase : 0;
      bigNum = Big(storageCostCase)
        .times(invoiced)
        .round(2);
      const storageCostCaseExt = bigNum * 1;
      this.storageCostCase.push(storageCostCaseExt);

      this.totalCndFrtCostCase += cndFrtCostCaseExt;
      this.totalStorageCostCase += storageCostCaseExt;
    });

    this.itemsUSD.forEach((element, index) => {
      const invoiced = element.invoiced ? element.invoiced : 0;
      const purchaseCaseCost = element.purchaseCaseCost ? element.purchaseCaseCost : 0;
      const clearingCs = element.clearingCs ? element.clearingCs : 0;
      const freightCs = element.freightCs ? element.freightCs : 0;
      const duty = element.duty ? element.duty : 0;
      const royalty = element.royalty ? element.royalty : 0;
      
      // usd table 1
      bigNum = Big(purchaseCaseCost)
        .times(invoiced)
        .round(2);
      element.extCaseCost = bigNum * 1;
      this.totalPurchaseCaseCostExt += element.extCaseCost;

      bigNum = Big(purchaseCaseCost)
      .minus(royalty)
      .minus(clearingCs)
      .minus(freightCs)
      .minus(duty)
      .round(2);
      const netVendorCost = bigNum * 1;
      
      element.extCaseCost = invoiced * netVendorCost;
      this.totalVendor += element.extCaseCost;

      this.usdVendorCost[index] = netVendorCost;
      this.usdFrtCost[index] = freightCs * invoiced;
      this.totalFreightCostExt += this.usdFrtCost[index];

      this.usdClearing[index] = clearingCs * invoiced;
      this.totalClearing += this.usdClearing[index];

      this.usdDuty[index] = duty * invoiced;
      this.totalDuty += this.usdDuty[index];
      this.usdRoyalty[index] = royalty * invoiced;
      this.totalRoyalty += this.usdRoyalty[index];
    });

    // 0 sales
    const indexSales = this.getBudgetIndex(0);
    if (indexSales > -1) {
      this.budgetActual[indexSales].budgetAmount = this.totalExtCost;
      this.budgetActual[indexSales].actualAmount = this.totalExtCost;
    }
    // 100 marketing
    this.indexMarketing = this.getBudgetIndex(100);    
    if (this.indexMarketing > -1) { 
      this.budgetActual[this.indexMarketing].budgetAmount = this.totalMarketing;
      this.budgetActual[this.indexMarketing].actualAmount = this.totalMarketing;
    }
    // 200 billing
    this.indexBilling = this.getBudgetIndex(200);
    if (this.indexBilling > -1) { 
      this.budgetActual[this.indexBilling].budgetAmount = this.totalBilling;
      this.budgetActual[this.indexBilling].difference = this.budgetActual[this.indexBilling].budgetAmount - this.budgetActual[this.indexBilling].actualAmount;
    }      
    // 300 terms
    this.indexTerms = this.getBudgetIndex(300);
    if (this.indexTerms > -1) { 
      this.budgetActual[this.indexTerms].budgetAmount = this.totalTerms;
      this.budgetActual[this.indexTerms].difference = this.budgetActual[this.indexTerms].budgetAmount - this.budgetActual[this.indexTerms].actualAmount;
    }
    // 400 commission
    this.indexCommission = this.getBudgetIndex(400);
    if (this.indexCommission > -1) { 
      this.budgetActual[this.indexCommission].budgetAmount = this.totalCommission;
      this.budgetActual[this.indexCommission].actualAmount = this.totalCommission;
    }
    // 500 kalcor
    this.indexKalcor = this.getBudgetIndex(500);
    if (this.indexKalcor > -1) { 
      this.budgetActual[this.indexKalcor].budgetAmount = this.totalKalcor;
      this.budgetActual[this.indexKalcor].actualAmount = this.totalKalcor;
    }
    // 600 freight CND
    this.indexFreightCnd = this.getBudgetIndex(600);
    if (this.indexFreightCnd > -1) {
      this.budgetActual[this.indexFreightCnd].budgetAmount = this.totalCndFrtCostCase;
      this.budgetActual[this.indexFreightCnd].difference = this.budgetActual[this.indexFreightCnd].budgetAmount - this.budgetActual[this.indexFreightCnd].actualAmount;
    }

    // 700 
    const index700 = this.getBudgetIndex(700);
    if (index700 > -1) { 
      this.budgetActual[index700].freightCost = this.getFreightCost('CND');
      this.budgetActual[index700].freightCostSales = this.getFreightCostSales('CND');
      this.budgetActual[index700].freightTotalCost = this.getFreightTotal('CND');
    }

    // 800 storage
    this.indexStorage = this.getBudgetIndex(800);
    if (this.indexStorage > -1) { 
      this.budgetActual[this.indexStorage].budgetAmount = this.totalStorageCostCase;
      this.budgetActual[this.indexStorage].difference = this.budgetActual[this.indexStorage].budgetAmount - this.budgetActual[this.indexStorage].actualAmount;
    }

    // 900 net CND
    const indexNetCND = this.getBudgetIndex(900);
    if (indexNetCND > -1) { 
      this.budgetActual[indexNetCND].budgetAmount = this.budgetActual[indexSales].budgetAmount - 
      this.budgetActual[this.indexMarketing].budgetAmount - this.budgetActual[this.indexBilling].budgetAmount -
      this.budgetActual[this.indexTerms].budgetAmount - this.budgetActual[this.indexCommission].budgetAmount -
      this.budgetActual[this.indexKalcor].budgetAmount - this.budgetActual[this.indexFreightCnd].budgetAmount -
      this.budgetActual[this.indexStorage].budgetAmount;
      this.budgetActual[indexNetCND].actualAmount = 0;      
    }    
    
    // 1200 exchamge
    const indexExchange = this.getBudgetIndex(1200);
    if (indexExchange > -1) { 
      bigNum = Big(this.budgetActual[indexExchange].budgetAmount)
      .minus(this.budgetActual[indexExchange].actualAmount)
      .round(7);
      this.budgetActual[indexExchange].difference = bigNum * 1;
    }    
    
    // 1400 clearing
    this.indexClearing = this.getBudgetIndex(1400);
    if (this.indexClearing > -1) { 
      this.budgetActual[this.indexClearing].budgetAmount = this.totalClearing;
      this.budgetActual[this.indexClearing].difference = this.budgetActual[this.indexClearing].budgetAmount - this.budgetActual[this.indexClearing].actualAmount;
    }
    
    // 1500 duty
    this.indexDuty = this.getBudgetIndex(1500);
    if (this.indexDuty > -1) { 
      this.budgetActual[this.indexDuty].budgetAmount = this.totalDuty;
      this.budgetActual[this.indexDuty].difference = this.budgetActual[this.indexDuty].budgetAmount - this.budgetActual[this.indexDuty].actualAmount;
    }

    // 1600 freight usd
    this.indexFreightUSD = this.getBudgetIndex(1600);
    if (this.indexFreightUSD > -1) { 
      this.budgetActual[this.indexFreightUSD].budgetAmount = this.totalFreightCostExt;
      this.budgetActual[this.indexFreightUSD].difference = this.budgetActual[this.indexFreightUSD].budgetAmount - this.budgetActual[this.indexFreightUSD].actualAmount;
    }

    // 1700 
    const indexFreightCostUSD = this.getBudgetIndex(1700);
    if (indexFreightCostUSD > -1) { 
      this.budgetActual[indexFreightCostUSD].freightCost = this.getFreightCost('USD');
      this.budgetActual[indexFreightCostUSD].freightCostSales = this.getFreightCostSales('USD');
      this.budgetActual[indexFreightCostUSD].freightTotalCost = this.getFreightTotal('USD');
    }

    // 1800 vendor
    this.indexVendor = this.getBudgetIndex(1800);
    if (this.indexVendor > -1) { 
      this.budgetActual[this.indexVendor].budgetAmount = this.totalVendor;
      this.budgetActual[this.indexVendor].actualAmount = this.budgetActual[this.indexVendor].budgetAmount;
    }

    // 1350 royalty
    this.indexRoyalty = this.getBudgetIndex(1350);
    if (this.indexRoyalty > -1) { 
      this.budgetActual[this.indexRoyalty].budgetAmount = this.totalRoyalty;
      this.budgetActual[this.indexRoyalty].actualAmount = this.totalRoyalty;
    } 

    // 1300 vendor purchase
    const indexVendorPurchase = this.getBudgetIndex(1300);
    if (indexVendorPurchase > -1) { 
    bigNum = Big(this.budgetActual[this.indexVendor].budgetAmount)
      .plus(this.budgetActual[this.indexRoyalty].budgetAmount)
      .plus(this.budgetActual[this.indexClearing].budgetAmount)
      .plus(this.budgetActual[this.indexDuty].budgetAmount)
      .plus(this.budgetActual[this.indexFreightUSD].budgetAmount)
      .round(2);
    this.budgetActual[indexVendorPurchase].budgetAmount = bigNum * 1;
    this.budgetActual[indexVendorPurchase].actualAmount = this.budgetActual[indexVendorPurchase].budgetAmount
  }

    // 1100 fob net return
    // Vendor Purchase Case Cost / Exchange Budget
    const indexFobNetReturn = this.getBudgetIndex(1100);
    if (indexFobNetReturn > -1) { 
      if (this.budgetActual[indexExchange].budgetAmount != 0) {
        bigNum = Big(this.budgetActual[indexVendorPurchase].budgetAmount)
        .div(this.budgetActual[indexExchange].budgetAmount)
        .round(2);
        this.budgetActual[indexFobNetReturn].budgetAmount = bigNum * 1;
      } else {
        this.budgetActual[indexFobNetReturn].budgetAmount = 0;
      }
    }

    if (this.budgetActual[indexExchange].actualAmount !=0 ) {
      Big.DP = 7;
      bigNum = Big(this.budgetActual[indexVendorPurchase].actualAmount)
      .div(this.budgetActual[indexExchange].actualAmount)
      .round(2);
      this.budgetActual[indexFobNetReturn].actualAmount = bigNum * 1;
    } else {
      this.budgetActual[indexFobNetReturn].actualAmount = 0;
    }

    // 10 fob dofferemce
    // fob difference = Net CND - FOB Net Return
    // new 2/7/20 Fob Difference [actual] = Net CND[Budget] - FOB Net Return Actual
    // 
    this.indexFobDiff = this.getBudgetIndex(1000);
    if (this.indexFobDiff > -1) {
      this.budgetActual[this.indexFobDiff].budgetAmount = 0;
        bigNum = Big(this.budgetActual[indexFobNetReturn].budgetAmount)
        .minus(this.budgetActual[indexFobNetReturn].actualAmount)
        .plus(this.exchangeProduct)
        .round(2);
        this.budgetActual[this.indexFobDiff].actualAmount = bigNum * 1; 
    }
    // this.indexFobDiff = this.getBudgetIndex(1000);
    // if (this.indexFobDiff > -1) {
    //   this.budgetActual[this.indexFobDiff].budgetAmount = 0;
    //     bigNum = Big(this.budgetActual[indexNetCND].budgetAmount)
    //     .minus(this.budgetActual[indexFobNetReturn].actualAmount)
    //     .round(2);
    //     this.budgetActual[this.indexFobDiff].actualAmount = bigNum * 1; 
    // }
  }
  getBudgetIndex(sortNumber) {
    const index = this.budgetActual.findIndex(o => o.sortNumber == sortNumber);
    return index;
  }

  getFreightCost(type) {
    let retValue = 0;
    retValue += this.orderSelected.freightType1 === type ? this.orderSelected.freightCost1 : 0;
    retValue += this.orderSelected.freightType2 === type ? this.orderSelected.freightCost2 : 0;
    retValue += this.orderSelected.freightType3 === type ? this.orderSelected.freightCost3 : 0;
    retValue += this.orderSelected.freightType4 === type ? this.orderSelected.freightCost4 : 0;
    retValue += this.orderSelected.freightType5 === type ? this.orderSelected.freightCost5 : 0;
    return retValue;    
  }
  getFreightCostSales(type) {
    let retValue = 0;
    retValue += this.orderSelected.freightType1 === type ? this.orderSelected.freightCostSales1 : 0;
    retValue += this.orderSelected.freightType2 === type ? this.orderSelected.freightCostSales2 : 0;
    retValue += this.orderSelected.freightType3 === type ? this.orderSelected.freightCostSales3 : 0;
    retValue += this.orderSelected.freightType4 === type ? this.orderSelected.freightCostSales4 : 0;
    retValue += this.orderSelected.freightType5 === type ? this.orderSelected.freightCostSales5 : 0;
    return retValue;    
  }
  getFreightTotal(type) {
    let retValue = 0;
    retValue += this.orderSelected.freightType1 === type ? this.orderSelected.freightTotalCost1 : 0;
    retValue += this.orderSelected.freightType2 === type ? this.orderSelected.freightTotalCost2 : 0;
    retValue += this.orderSelected.freightType3 === type ? this.orderSelected.freightTotalCost3 : 0;
    retValue += this.orderSelected.freightType4 === type ? this.orderSelected.freightTotalCost4 : 0;
    retValue += this.orderSelected.freightType5 === type ? this.orderSelected.freightTotalCost5 : 0;
    return retValue;    

  }

}
