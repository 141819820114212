import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { Brand } from '../../models/brand.model';
import { BrandService } from '../../services/brand.service';

@Injectable()
export class BrandResolver implements Resolve<Brand> {
  constructor(private brandService: BrandService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Brand> {
    const idField = 'id';
    const id =  parseInt(route.params[idField], 10);

    if (isNaN(id)) {
      return this.brandService.getFilteredListing('Active', 0, 0, 1, 30);
    }

    if (id === 0) {
      return;
    }

    return this.brandService.getOne(id);
  }
}
