<h5>FUNDS TRANSFER</h5>
<form [formGroup]="transferForm"  novalidate>
    <div class="form-group">
        <div class="row">
            <label for="transferDate" class="col-lg-4 pt-2">
                Transfer Date (*)
            </label>
            <div class="col-lg-4 pt-2">
            <input id="transferDate" type="date" formControlName="transferDate" class="form-control" >
        </div>
        </div>
        <div class="row">
            <label for="transferNo" class="col-lg-4 pt-2">
                Transfer No. (*)
            </label>
            <div class="col-lg-4 pt-2">
            <input id="transferNo" type="text" formControlName="transferNo" class="form-control" >
        </div>
        </div>
        <div class="row">
            <label for="billToId" class="col-lg-4 pt-2">
                BILL TO (*)
            </label>
            <div class="col-lg-4 pt-2">
            <select formControlName="billToId" class="form-control">
                <option [value]=""></option>
                <option *ngFor="let c of customers" [value]="c.id">{{ c.company }}</option>
            </select>
            </div>
        </div>
        <div class="row">
            <label for="vendorId" class="col-lg-4 pt-2">
                VENDOR (*)
            </label>
            <div class="col-lg-4 pt-2">
            <select formControlName="vendorId" class="form-control">
                <option [value]=""></option>
                <option *ngFor="let v of vendors" [value]="v.id">{{ v.company }}</option>
            </select>
            </div>
        </div>
        <div class="row">
            <label for="transferStatus" class="col-lg-4 pt-2">
                Transfer Status
            </label>
            <div class="col-lg-4 pt-2">
            <input id="transferStatus" type="text" formControlName="transferStatus" class="form-control" >
            </div>
        </div>
        <div class="row">
            <label for="notes" class="col-lg-4 pt-2">
                Notes
            </label>
            <div class="col-lg-6 pt-2">
            <textarea id="notes" formControlName="notes" class="form-control">
            </textarea>
        </div>
        </div>
        <div class="row">
            <label for="transferAmount" class="col-lg-4 pt-2">
                Transfer Amount (*)
            </label>
            <div class="col-lg-4 pt-2">
            <input id="transferAmount" type="text" formControlName="transferAmount" class="form-control" >
            </div>
        </div>

        <div class="row">
            <label for="fromBucket" class="col-lg-4 pt-2">
                From Bucket (*)
            </label>
            <div class="col-lg-4 pt-2">
            <select formControlName="fromBucket" class="form-control">
                <option [value]=""></option>
                <option *ngFor="let b of bucket" [value]="b.buckets">{{ b.buckets }}</option>
            </select>
            </div>
        </div>
        <div class="row">
            <label for="toBucket" class="col-lg-4 pt-2">
                To Bucket (*)
            </label>
            <div class="col-lg-4 pt-2">
            <select formControlName="toBucket" class="form-control">
                <option [value]=""></option>
                <option *ngFor="let b of bucket" [value]="b.buckets">{{ b.buckets }}</option>
            </select>
            </div>
        </div>
        <div class="row">
            <div class="col-4">
            </div>
            <div class="col-4 px-0">
              <button type="button" (click)="onSubmit('Open')" class="btn btn-primary mt-3 w-100">SAVE TRANSFER - OPEN</button>
            </div>
        </div>
        <div class="row">
            <div class="col-4">
            </div>
            <div class="col-4 px-0">
              <button type="button" (click)="onSubmit('Closed')" class="btn btn-primary mt-3 w-100">SAVE TRANSFER - CLOSED</button>
            </div>
        </div>
        <div class="row">
            <div class="col-4">
            </div>
            <div class="col-4 px-0">
              <button type="button" (click)="onDelete()" class="btn btn-secondary mt-3 w-100">Delete</button>
            </div>
        </div>
    </div>
</form>
<p>(*) Denotes Mandatory</p>
<ngb-alert *ngIf="successMessage" [type]="alertType" (close)="successMessage = null">{{ successMessage }}</ngb-alert>
