import { Component, OnInit } from '@angular/core';
import { WeekCustomerService } from 'src/app/services/week-customer.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { CustomerService } from 'src/app/services/customer.service';
import { Customer } from 'src/app/models/customer.model';


@Component({
  selector: 'app-week-customer-list',
  templateUrl: './week-customer-list.component.html',
  styleUrls: ['./week-customer-list.component.scss']
})
export class WeekCustomerListComponent implements OnInit {
  year = [];
  customer: [];
  customerSelected: number;
  customerOptions: [];
  customerId: number;
  customerName: string;
  successMessage: string;
  private success = new Subject<string>();
  alertType = "success";
  staticAlertClosed = false;
  page = 1;
  pageSize = 30;
  searchText = '';
  itemFound = [];
  collectionSize = 0;
  status = 'Active';
  company = '';
  companies: any;

  constructor(private router: Router,
    private activatedRoute: ActivatedRoute,
    private weekCustomerSvc: WeekCustomerService,
    private customerSvc: CustomerService
    ) { }

  ngOnInit(): void {
    this.customerSelected = history.state.customerId !== undefined ? history.state.customerId : 0;
    if (this.customerSelected == 0) {
      this.displayItems();
    } else {
      this.loadPage();
    }
    setTimeout(() => this.staticAlertClosed = true, 20000);
    this.success.subscribe((message) => this.successMessage = message);
    this.success.pipe(
      debounceTime(5000)
    ).subscribe(() => this.successMessage = null);
  }
  displayItems() {
    this.year=[];
    this.customer=[];
    this.customerSvc.getCustomerYears().subscribe(data => {
      this.customer = data;
    });
  }
  clickedItem(i) {
    this.router.navigate(['./other/week-customer-edit', i.id, i.year]);
  }
  createNewYear() {
    this.router.navigate(['./other/week-customer-edit', this.customerSelected, 0]);
  }
  loadPage() {
    this.customerSvc.getOneYears(this.customerSelected).subscribe(data => {
      this.customer = data;
    }, error => {
      this.successMessage = 'Unable to process request';
      this.alertType = "danger";
    });
  }

}
