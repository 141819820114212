import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { WeekCustomer } from 'src/app/models/week-customer.model';
import { WeekCustomerService } from 'src/app/services/week-customer.service';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { CustomerService } from 'src/app/services/customer.service';
import { Customer } from 'src/app/models/customer.model';
//import { Message } from '@angular/compiler/src/i18n/i18n_ast';

@Component({
  selector: 'app-week-customer-new',
  templateUrl: './week-customer-new.component.html',
  styleUrls: ['./week-customer-new.component.scss']
})
export class WeekCustomerNewComponent implements OnInit {
  customerSelected: string;
  customers: Customer[];
  successMessage: string;
  private success = new Subject<string>();
  staticAlertClosed = false;
  alertType = "success";


  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private weekCustomerSvc: WeekCustomerService,
    private customerSvc: CustomerService
  ) { }

  ngOnInit(): void {

    this.customerSvc.getCompany().subscribe(cust => {
      this.customers = cust;
      this.displayItems();
    },error => {
      this.successMessage = 'Unable to process request';
      this.alertType = "danger";
    })
    setTimeout(() => this.staticAlertClosed = true, 2000);
    this.success.subscribe((message) => this.successMessage = message);
    this.success.pipe(
      debounceTime(5000)
    ).subscribe(() => this.successMessage = null);
    
  }
  displayItems() {
    this.customers;
  }
  onRowClicked(customers){    
      this.router.navigate(['./other/week-customer-edit', customers.id, 0]);
  }

}
