import { Component, OnInit } from '@angular/core';
import { BrokerService } from '../../../services/broker.service';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
@Component({
  selector: 'app-broker-list',
  templateUrl: './broker-list.component.html',
  styleUrls: ['./broker-list.component.scss']
})
export class BrokerListComponent implements OnInit {
  broker: any;
  activeItemSelected: string;
  companySelected: number;
  companyOptions:[];
  page: number;
  pageSize: number;
  count: number;
  successMessage: string;
  private success = new Subject<string>();
  alertType = "success";
  staticAlertClosed = false;

  constructor(private router: Router,
              private brokerSvc: BrokerService) { }

  ngOnInit() {
    this.reset();
    setTimeout(() => this.staticAlertClosed = true, 20000);
    this.success.subscribe((message) => this.successMessage = message);
    this.success.pipe(
      debounceTime(5000)
    ).subscribe(() => this.successMessage = null);     
  }
  loadPage() {
    this.brokerSvc.getFilteredListing(this.activeItemSelected, this.companySelected, this.page, this.pageSize).subscribe(data => {
      this.broker = data.item;
      this.companyOptions = data.item2;
      this.count = data.count;
    },error => {
      this.successMessage = 'Unable to process request';
      this.alertType = "danger";
    });
  }
  reset() {
    this.page = 1;
    this.pageSize = 30;
    this.activeItemSelected = 'Active';
    this.companySelected = 0;
    this.loadPage();    
  }  
  onRowClicked(broker) {
    this.router.navigate(['/other/broker-edit', broker.id]);
  }
}
