import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { OrderService } from '../../services/order.service';
import { CreditData } from '../../models/credit-model';
import { Incoming, Transfer } from '../../models/bucket.model';
import { Big } from 'big.js';
import * as jsPDF from 'jspdf';
import * as html2pdf from 'html2pdf.js';
@Component({
  selector: 'app-storage',
  templateUrl: './storage.component.html',
  styleUrls: ['./storage.component.scss']
})
export class StorageComponent implements OnInit {
  credit: CreditData;
  transfer: Transfer[];
  yearSelected: number;
  monthSelected: [];
  months: []
  vendorSelected: number;
  customerSelected: number;
  brokerSelected: number;
  bucketSelected: string;
  order: any;
  balfwd: number;
  balanceForwardCredit: number;
  balanceForwardActual: number;
  balanceForwardBudget: number;
  balanceForwardTransfer: number;
  creditData: any;
  balFwdBeginningMonth = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  balFwdAfterIncomingMonth = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  balFwdOutgoingMonth = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  balFwdTransferingMonth = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  balEndCurrentMonth = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  incoming = [[],[],[],[],[],[],[],[],[],[],[],[]];
  outgoing = [[],[],[],[],[],[],[],[],[],[],[],[]];
  transfering = [[],[],[],[],[],[],[],[],[],[],[],[]];

  buckets = [false, false , false, false, false, false, false, false, false, false, false, false];
  bucketsOrg = [false, false , false, false, false, false, false, false, false, false, false, false];
  numberMonthsRequested: number;
  monthName = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];
  customerName: string;
  vendorName: string;
  reportType: string;
  orderType: string;
  incomingSalesTitle: string;
  outgoingSalesTitle: string;
// pdf
  title: string;
  pageBreak: any;
  reportName = '';
  reportNames = [
    {
    Name: 'Bucket Report Storage', 
    Id: 'my-bucket-storage',
    filename: 'Storage Report',
    Orientation: 'landscape',
    TopMargin: .5,
    LRMargin: .2
    }
  ];
  currentIndex: number;
  firstSelectMonthIndex: number;
  bucketIndex = [
    { Bucket: 'Billing', index: -1 },
    { Bucket: 'Terms', index: -1 },
    { Bucket: 'Marketing', index: -1 },
    { Bucket: 'Commission', index: -1 },
    { Bucket: 'Kalcor', index: -1 },
    { Bucket: 'Freight CND', index: -1 },
    { Bucket: 'Storage', index: 0 },
    { Bucket: 'Exchange', index: -1 },
    { Bucket: 'Clearing', index: -1 },
    { Bucket: 'Duty', index: -1 },
    { Bucket: 'Freight USD', index: -1 },
    { Bucket: 'Vendor', index: -1 },
    { Bucket: 'Royalty', index: -1 }
  ]

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private checkRef: ChangeDetectorRef,
    private orderService: OrderService    
  ) { }

  ngOnInit(): void {
    this.yearSelected = 0;
    this.monthSelected = [];
    this.vendorSelected = 0;
    this.customerSelected = 0;
    this.brokerSelected = 0;
    this.bucketSelected = '';
    this.balfwd = 0;
    this.yearSelected = history.state.year !== undefined ? history.state.year : 0;
    this.monthSelected = history.state.month !== undefined ? history.state.month : 0;
    this.vendorSelected = history.state.vendor !== undefined ? history.state.vendor : 0;
    this.customerSelected = history.state.customer !== undefined ? history.state.customer : 0;
    this.brokerSelected = history.state.broker !== undefined ? history.state.broker : 0;
    this.customerName = history.state.customerName !== undefined ? history.state.customerName : '';
    this.vendorName = history.state.vendorName !== undefined ? history.state.vendorName : '';
    this.reportType = history.state.type !== undefined ? history.state.type : '';
    this.orderType = history.state.orderType !== undefined ? history.state.orderType : '';
    this.bucketSelected = history.state.bucket !== undefined ? history.state.bucket : '';
    const index = this.bucketIndex.findIndex( bucket => bucket.Bucket == this.bucketSelected);
    this.currentIndex = this.bucketIndex[index].index;
    this.title = this.reportNames[this.currentIndex].Name;
    this.orderService.getBucketList(this.yearSelected, this.monthSelected, this.vendorSelected,
      this.customerSelected, this.brokerSelected, this.bucketSelected, this.reportType, this.orderType).subscribe( data => {
          this.order = data.order; 
          this.creditData = data.creditData;
          this.transfer = data.transferData;
          this.balanceForwardCredit = data.balanceForwardCredit;
          this.balanceForwardActual = data.balanceForwardActual;
          this.balanceForwardBudget = data.balanceForwardBudget;
          this.balanceForwardTransfer = data.balanceForwardTransfer;
          this.balfwd = this.balanceForwardBudget - this.balanceForwardCredit + this.balanceForwardTransfer;
          this.loadArrays();
      })

  }
  loadArrays() {
    this.order.forEach(element => {
      // Splits items into incoming and outgoing arrays by month
      const theDate = new Date(element.shipmentDate);
      const nMonth = theDate.getMonth();
      element.broker = this.getBroker(element.brokerId, element.broker, element.broker2Id, element.broker2);
      this.incoming[nMonth].push(element);
    });
    this.creditData.forEach(element => {
      const theDate = new Date(element.creditDate);
      const nMonth = theDate.getMonth();
      element.brokerCompany = this.getBroker(element.brokerId, element.brokerCompany, element.brokerId2, element.brokerCompany2);
      this.outgoing[nMonth].push(element);
    });
    this.transfer.forEach(element => {
       const theDate = new Date(element.transferDate);
       const nMonth = theDate.getMonth();
       this.transfering[nMonth].push(element);
    });
    this.balFwdBeginningMonth.forEach((element, index) => {
      this.balFwdBeginningMonth[index] = this.balfwd;
      element = this.balfwd;
      this.balEndCurrentMonth[index] = this.balfwd;
      this.incoming[index].forEach(element2 => {
        this.balEndCurrentMonth[index] += element2.budgetAmount; 
        element2.balance = this.balEndCurrentMonth[index];
      })
      this.outgoing[index].forEach(element3 => {
        this.balEndCurrentMonth[index] += element3.debit - element3.creditAmount;
        element3.balance = this.balEndCurrentMonth[index];
      })

      this.transfering[index].forEach(element4 => {
        if (element4.fromBucket == this.bucketSelected) {
          element4.debit = element4.transferAmount;
        } else {
          element4.credit = element4.transferAmount;
        }
        
        this.balEndCurrentMonth[index] += element4.credit - element4.debit;
        element4.balance = this.balEndCurrentMonth[index];
      })
    })

    this.firstSelectMonthIndex = -1;
    this.numberMonthsRequested = 1;
    if (typeof this.monthSelected == "string") {
      const monthIndex = this.monthSelected - 1;
      this.buckets[monthIndex] = true;
      this.bucketsOrg[monthIndex] = true;
      this.firstSelectMonthIndex = monthIndex;
    } else {
      this.numberMonthsRequested = this.monthSelected.length;
      this.monthSelected.forEach(element => {
        const monthIndex = element - 1;
        this.buckets[monthIndex] = true;
        this.bucketsOrg[monthIndex] = true;
        this.firstSelectMonthIndex = this.firstSelectMonthIndex == -1 ? monthIndex : this.firstSelectMonthIndex;
      })
    }
  }
  getBroker(id, broker1, id2, broker2) {
    let broker = '';
    broker1 = typeof broker1 == 'undefined' || !broker1 ? '' : broker1;
    broker2 = typeof broker2 == 'undefined' || !broker2 ? '' : broker2;
    if (this.brokerSelected > 0) {
      if (this.brokerSelected === id) {
        broker2 = '';
      }
      if (this.brokerSelected === id2) {
        broker1 = '';
      }
    }

    if (broker1 != '' && broker2 != '') {
      broker = broker1 + '/' + broker2
    } else {
      broker += broker1;
      broker += broker2;
    }
   
    return broker;
  }

  displayDate(dt, t) {
    if (dt === undefined) {
      return null;
    }
    if (dt) {
      if (t === undefined) {
        t = '';
      }
      if (dt.indexOf('T') == -1) {
        dt += 'T00:00';
      }
      let x = new Date(dt);
      if (t == 'T') {
        return x.toLocaleDateString() + ' @ ' + x.toLocaleTimeString();
      }
      return x.toLocaleDateString();
    } 
    return dt
  }
  printProcess() {
    const index = this.bucketIndex.findIndex( bucket => bucket.Bucket == this.bucketSelected);
    this.currentIndex = this.bucketIndex[index].index;
    if (this.currentIndex == -1) {
      alert("Under Construction");
      return;
    }
    if (this.bucketsOrg.every(x => x == true)) {
      this.printIt(-1);
    } else {
      this.bucketsOrg.forEach((bucket, index) => {
        if (bucket) {
          this.buckets.forEach((buck, index) => this.buckets[index] = false);
          this.buckets[index] = true;
          this.printIt(index);
        }
      });
    }
    this.bucketsOrg.forEach((bucket, index) => this.buckets[index] = bucket);

    this.reportName = '';
  }
  printIt(index) {
    this.title = this.reportNames[this.currentIndex].Name;
    this.reportName = this.reportNames[this.currentIndex].Name;
    this.checkRef.detectChanges();
    let fileName = '';
    fileName += this.vendorName.trim();
    fileName += ' – Bucket Report: ';
    fileName += this.bucketSelected.trim();
    fileName += ' ' + this.yearSelected;
    if (index > -1) {
      const moIndex = index + 1;
      let monthNumber = moIndex + '';
      monthNumber = monthNumber.length == 1 ? "0" + monthNumber : monthNumber;
      fileName += ' ' + monthNumber;
      fileName += ' ' + this.monthName[index];
    }
    fileName += '-' + this.reportType.toUpperCase();

    this.pageBreak = {};
    this.pageBreak.mode = 'avoid-all';
    if (this.numberMonthsRequested > 1) {
      this.pageBreak.before = '.break-MONTH';
    }
    var element = document.getElementById(this.reportNames[this.currentIndex].Id);
    var opt = {
      margin:       [this.reportNames[this.currentIndex].TopMargin, this.reportNames[this.currentIndex].LRMargin],
      filename:     fileName + '.pdf',
      pagebreak:    this.pageBreak,
      image:        { type: 'jpeg', quality: 0.98 },
      html2canvas:  { scale: 2 },
      jsPDF:        { unit: 'in', format: 'letter', orientation: this.reportNames[this.currentIndex].Orientation }
    };
    html2pdf().set(opt).from(element).save();
    this.reportName = '';
    this.checkRef.detectChanges();

  }
}
