export class CommissionData {
    id: number;
    category: string;
    period: string;
    status: string;
    type: string;
    no: string;
    purchaseOrder: string;
    date: Date;
    customer: string;
    vendor: string;
    vendorId: number;
    shipTo: string;
    broker: string;
    broker2: string;
    salesAmount: number;
    commissionIncPercent: number;
    commissionInc: number;
    commissionIncPercent2: number;
    commissionInc2: number;
    balance: number;
    customerCreditNo: string;
    creditAmount: number;
    commissionOutPercent: number;
    commissionOut: number;
    commissionOutPercent2: number;
    commissionOut2: number;
    notes: string;
    bucketTransfer: string;
    credit: number;
    debit: number;
    month: string;
    year: number;
    payment: number;
    creditReason: string;
}