import { Component, OnInit } from '@angular/core';
import { CreditService } from '../../../services/credit.service';
import { Router, ActivatedRoute } from '@angular/router';
import { BrokerService } from '../../../services/broker.service';
import { Observable, of, OperatorFunction } from 'rxjs';
import { switchMap, map, debounceTime, distinctUntilChanged, catchError, tap } from 'rxjs/operators';
import { Credit, CreditData } from 'src/app/models/credit-model';
import { Term } from 'src/app/models/term-model';



@Component({
  selector: 'app-credit-list',
  templateUrl: './credit-list.component.html',
  providers: [CreditService],
  styleUrls: ['./credit-list.component.scss']

})
export class CreditListComponent implements OnInit {
  credit: any;
  page = 1;
  pageSize = 30;
  collectionSize = 0;
  creditStatusSelected: string;
  creditNumberSelected: string;
  purchaseOrderSelected: string;
  customerCreditNo: string;
  billToSelected: number;
  vendorSelected: number;
  batchNoSelected: number;
  batchTypeSelected: string;
  creditStatusOptions = ['Open', 'Closed', 'Budget'];
  creditNumberOptions = [];
  purchaseOrderOptions = [];
  customerCreditNoOptions = [];
  billToOptions = [];
  vendorOptions = [];
  brokers: any;
  batchNoOptions = [];
  batchTypeOptions = [
    'Dot Deduction',
    'Vendor Payment',
    'Direct Payment',
    'Other Payment'
  ];
  searching = false;
  searchFailed = false;
  model: any;
  
 


  constructor(
    private router: Router,
    private creditService: CreditService,
    private brokerService: BrokerService,
    private route: ActivatedRoute) { }

  ngOnInit() {
    this.creditStatusSelected = 'Open';
    this.creditNumberSelected = '';
    this.purchaseOrderSelected = '';
    this.batchNoSelected = 0;
    this.batchTypeSelected = '';
    this.customerCreditNo = '';
    this.billToSelected = 0;
    this.vendorSelected = 0;
    this.brokerService.getCompany().subscribe(data => {
      this.brokers = data;
      this.route.data.subscribe( credit => {
        if (credit.dataCredit !== undefined) {
          this.credit = credit.dataCredit.item;
          this.collectionSize = credit.dataCredit.count;
          this.creditNumberOptions = credit.dataCredit.item2;
          this.purchaseOrderOptions = credit.dataCredit.item3;
          this.customerCreditNoOptions = credit.dataCredit.item4;
          this.billToOptions = credit.dataCredit.item5;
          this.vendorOptions = credit.dataCredit.item6;
          this.batchNoOptions = credit.dataCredit.item7;
          this.batchTypeOptions = credit.dataCredit.item8;
          this.getBroker();      
        } else {
        }                
      })
    });
  }

  search: OperatorFunction<string, readonly string[]> = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => this.searching = true),
      switchMap(id =>
        this.creditService.search(id).pipe(
          tap(() => this.searchFailed = false),
          catchError(() => {
            this.searchFailed = true;
            return of([]);
          }))
      ),
      tap(() => this.searching = false)           
    )

  itemSelected($event) {
      this.creditStatusSelected = '';
      this.creditNumberSelected = '';
      this.purchaseOrderSelected = '';
      this.customerCreditNo = $event.item;
      this.billToSelected = 0;
      this.vendorSelected = 0;
      this.batchNoSelected = 0;
      this.batchTypeSelected = '';
      this.page = 1
      this.resetFilters();  
  }
        
  getBroker() {
    this.credit.forEach(element => {
      if (element.items.length > 0) {
        element.broker = '';
        element.broker2 = '';
        element.items.forEach(broker => {
          let brokerName = this.getBrokerName(broker.brokerId);
          if (element.broker.indexOf(brokerName) == -1) {
            element.broker += element.broker ? ' / ' : element.broker; 
            element.broker += brokerName;
          }
          brokerName = this.getBrokerName(broker.brokerId2);
          if (element.broker2.indexOf(brokerName) == -1) {
            element.broker2 += element.broker2 ? ' / ' : element.broker2; 
            element.broker2 += brokerName;
            }
        })
      }
    });
  }
  getBrokerName(id) {
    id = id === null ? 0 : id;
    let broker = '';
    if (id > 0) {
      const index = this.brokers.findIndex( data => data.id == id); 
      if (index > -1) {
        broker = this.brokers[index].company.trim();
      }
    }
    return broker;
  }
  displayDate(dt, t) {
    
    if (dt) {
      if (t === undefined) {
        t = '';
      }
      let x = new Date(dt);
      if (t == 'T') {
        return x.toLocaleDateString() + ' @ ' + x.toLocaleTimeString();
      }
      return x.toLocaleDateString();
    } 
    return dt

  }

  getDefaultDataScreen() {
    this.creditStatusSelected = 'Open';
    this.creditNumberSelected = '';
    this.purchaseOrderSelected = '';
    this.customerCreditNo = '';
    this.billToSelected = 0;
    this.vendorSelected = 0;
    this.batchNoSelected = 0;
    this.batchTypeSelected = '';
    this.page = 1;
    this.resetFilters();
  } 
  resetFilters() {
    this.creditService.getFilter(this.creditStatusSelected, this.creditNumberSelected, 
      this.purchaseOrderSelected, this.customerCreditNo, 
      this.billToSelected, this.vendorSelected, this.batchNoSelected, this.batchTypeSelected, this.page, this.pageSize).subscribe(credit => {
        this.credit = credit.item;
        this.collectionSize = credit.count;
        this.creditNumberOptions = credit.item2;
        this.purchaseOrderOptions = credit.item3;
        this.customerCreditNoOptions = credit.item4;
        this.billToOptions = credit.item5;
        this.vendorOptions = credit.item6;
        this.batchNoOptions = credit.item7;
        this.batchTypeOptions = credit.item8;
        this.getBroker();      
      });
  }
  goToEdit(index) {
    this.router.navigate(['/accounts/credit-edit',  this.credit[index].id]);
  }
}
