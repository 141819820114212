<h5>{{ title }} </h5>
<div class="row">
    <div class="column">
        <form [formGroup]="productForm" (ngSubmit)="onSubmit()" novalidate>
            <div class="form-group">
                <div class="col">
                    <label>
                        Active Listing:
                        <input type="text" formControlName="activeListings" class="form-control">
                    </label>

                    <label class="ms-3">
                        Product Order Code:
                        <input type="text" formControlName="productOrderCode" class="form-control">
                    </label>
                </div>

                <div class="col">
                    <label>
                        Brand:
                        <input type="text" formControlName="brand" class="form-control">
                    </label>

                    <label class="ms-3">
                        Category:
                        <input type="text" formControlName="category" class="form-control">
                    </label>
                </div>

                <div class="col">
                    <label>
                        Item Desciption:
                        <input type="text" formControlName="itemDescription" class="form-control">
                    </label>

                    <label class="ms-3">
                        Size:
                        <input type="text" formControlName="size" class="form-control">
                    </label>
                </div>
                <div class="smallSpacer"></div>
                <div class="col">
                    <label>
                        Location 1 Starting:
                        <select formControlName="location1Id" class="form-control">
                            <option *ngFor="let l of locations" [value]="l.id">{{l.location}}</option>
                        </select>
                    </label>
                    <label>
                        Location 1 Ending:
                        <select formControlName="shippingLaneEnd1" class="form-control">
                            <option *ngFor="let l of locations" [value]="l.id">{{l.location}}</option>
                        </select>
                    </label>
                    <label class="col-lg-3 ms-3">
                        Total Cost 1:
                        <input type="text" formControlName="truckCost1" class="form-control">
                    </label>
                    <label class="col-lg-3 ms-3">
                        Location 1 %:
                        <input type="text" formControlName="location1" class="form-control">
                    </label>
                </div>

                <div class="col">
                    <label>
                        Transport Co. 1:
                        <select formControlName="transportCo1" class="form-control">
                            <option *ngFor="let t of transport" [value]="t.id">{{t.company }}</option>
                        </select>
                    </label>
                    <label class="ms-3">
                        Type of Freight:
                        <select formControlName="freightType1" class="form-control">
                            <option [value]=""></option>
                            <option value="CND">CND Freight Cost</option>
                            <option value="USD">USD Freight Cost</option>
                        </select>
                    </label>
                    <label class="col-lg-3 ms-3">
                        Cost
                        <input type="text" formControlName="freightCost1" class="form-control">
                    </label>
                    <label class="col-lg-3 ms-3">
                        FSC%
                        <input type="text" formControlName="freightCostSales1" class="form-control">
                    </label>
                    <label class="col-lg">
                        Notes:
                        <input type="text" formControlName="transNotesCo1" class="form-control" size="60">
                    </label>
                </div>
                <div class="smallSpacer"></div>
                <div class="col">
                    <label>
                        Location 2 Starting:
                        <select formControlName="location2Id" class="form-control">
                            <option *ngFor="let l of locations" [value]="l.id">{{l.location}}</option>
                        </select>
                    </label>
                    <label>
                        Location 2 Ending:
                        <select formControlName="shippingLaneEnd2" class="form-control">
                            <option *ngFor="let l of locations" [value]="l.id">{{l.location}}</option>
                        </select>
                    </label>
                    <label class="col-lg-3 ms-3">
                        Total Cost 2:
                        <input type="text" formControlName="truckCost2" class="form-control">
                    </label>
                    <label class="col-lg-3 ms-3">
                        Location 2 %:
                        <input type="text" formControlName="location2" class="form-control">
                    </label>
                </div>
                <div class="col">
                    <label>
                        Transport Co. 2:
                        <select formControlName="transportCo2" class="form-control">
                            <option *ngFor="let t of transport" [value]="t.id">{{t.company }}</option>
                        </select>
                    </label>
                    <label class="ms-3">
                        Type of Freight:
                        <select formControlName="freightType2" class="form-control">
                            <option [value]=""></option>
                            <option value="CND">CND Freight Cost</option>
                            <option value="USD">USD Freight Cost</option>
                        </select>
                    </label>
                    <label class="col-lg-3 ms-3">
                        Cost
                        <input type="text" formControlName="freightCost2" class="form-control">
                    </label>
                    <label class="col-lg-3 ms-3">
                        FSC%
                        <input type="text" formControlName="freightCostSales2" class="form-control">
                    </label>
                    <label>
                        Notes:
                        <input type="text" formControlName="transNotesCo2" class="form-control" size="60">
                    </label>
                </div>
                <div class="smallSpacer"></div>
                <div class="col">
                    <label>
                        Location 3 Starting:
                        <select formControlName="location3Id" class="form-control">
                            <option *ngFor="let l of locations" [value]="l.id">{{l.location}}</option>
                        </select>
                    </label>
                    <label>
                        Location 3 Ending:
                        <select formControlName="shippingLaneEnd3" class="form-control">
                            <option *ngFor="let l of locations" [value]="l.id">{{l.location}}</option>
                        </select>
                    </label>
                    <label class="col-lg-3 ms-3">
                        Total Cost 3:
                        <input type="text" formControlName="truckCost3" class="form-control">
                    </label>
                    <label class="col-lg-3 ms-3">
                        Location 3 %:
                        <input type="text" formControlName="location3" class="form-control">
                    </label>
                </div>
                <div class="col">
                    <label>
                        Transport Co. 3:
                        <select formControlName="transportCo3" class="form-control">
                            <option *ngFor="let t of transport" [value]="t.id">{{t.company }}</option>
                        </select>
                    </label>
                    <label class="ms-3">
                        Type of Freight:
                        <select formControlName="freightType3" class="form-control">
                            <option [value]=""></option>
                            <option value="CND">CND Freight Cost</option>
                            <option value="USD">USD Freight Cost</option>
                        </select>
                    </label>
                    <label class="col-lg-3 ms-3">
                        Cost
                        <input type="text" formControlName="freightCost3" class="form-control">
                    </label>
                    <label class="col-lg-3 ms-3">
                        FSC%
                        <input type="text" formControlName="freightCostSales3" class="form-control">
                    </label>

                    <label>
                        Notes:
                        <input type="text" formControlName="transNotesCo3" class="form-control" size="60">
                    </label>
                </div>
                <div class="smallSpacer"></div>
                <div class="col">
                    <label>
                        Location 4 Starting:
                        <select formControlName="location4Id" class="form-control">
                            <option *ngFor="let l of locations" [value]="l.id">{{l.location}}</option>
                        </select>
                    </label>
                    <label>
                        Location 4 Ending:
                        <select formControlName="shippingLaneEnd4" class="form-control">
                            <option *ngFor="let l of locations" [value]="l.id">{{l.location}}</option>
                        </select>
                    </label>
                    <label class="col-lg-3 ms-3">
                        Total Cost 4:
                        <input type="text" formControlName="truckCost4" class="form-control">
                    </label>
                    <label class="col-lg-3 ms-3">
                        Location 4 %:
                        <input type="text" formControlName="location4" class="form-control">
                    </label>
                </div>

                <div class="col">
                    <label>
                        Transport Co. 4:
                        <select formControlName="transportCo4" class="form-control">
                            <option *ngFor="let t of transport" [value]="t.id">{{t.company }}</option>
                        </select>
                    </label>
                    <label class="ms-3">
                        Type of Freight:
                        <select formControlName="freightType4" class="form-control">
                            <option [value]=""></option>
                            <option value="CND">CND Freight Cost</option>
                            <option value="USD">USD Freight Cost</option>
                        </select>
                    </label>
                    <label class="col-lg-3 ms-3">
                        Cost
                        <input type="text" formControlName="freightCost4" class="form-control">
                    </label>
                    <label class="col-lg-3 ms-3">
                        FSC%
                        <input type="text" formControlName="freightCostSales4" class="form-control">
                    </label>

                    <label>
                        Notes:
                        <input type="text" formControlName="transNotesCo4" class="form-control" size="60">
                    </label>
                </div>
                <div class="smallSpacer"></div>
                <div class="col">
                    <label>
                        Location 5 Starting:
                        <select formControlName="location5Id" class="form-control">
                            <option *ngFor="let l of locations" [value]="l.id">{{l.location}}</option>
                        </select>
                    </label>
                    <label>
                        Location 5 Ending:
                        <select formControlName="shippingLaneEnd5" class="form-control">
                            <option *ngFor="let l of locations" [value]="l.id">{{l.location}}</option>
                        </select>
                    </label>
                    <label class="col-lg-3 ms-3">
                        Total Cost 5:
                        <input type="text" formControlName="truckCost5" class="form-control">
                    </label>
                    <label class="col-lg-3 ms-3">
                        Location 5 %:
                        <input type="text" formControlName="location5" class="form-control">
                    </label>
                </div>

                <div class="col">
                    <label>
                        Transport Co. 5:
                        <select formControlName="transportCo5" class="form-control">
                            <option *ngFor="let t of transport" [value]="t.id">{{t.company }}</option>
                        </select>
                    </label>

                    <label class="ms-3">
                        Type of Freight:
                        <select formControlName="freightType5" class="form-control">
                            <option [value]=""></option>
                            <option value="CND">CND Freight Cost</option>
                            <option value="USD">USD Freight Cost</option>
                        </select>
                    </label>
                    <label class="col-lg-3 ms-3">
                        Cost
                        <input type="text" formControlName="freightCost5" class="form-control">
                    </label>
                    <label class="col-lg-3 ms-3">
                        FSC%
                        <input type="text" formControlName="freightCostSales5" class="form-control">
                    </label>

                    <label>
                        Notes:
                        <input type="text" formControlName="transNotesCo5" class="form-control" size="60">
                    </label>
                </div>
                <div class="smallSpacer"></div>
                <div class="col">
                    <label>
                        Location 6 Starting:
                        <select formControlName="location6Id" class="form-control">
                            <option *ngFor="let l of locations" [value]="l.id">{{l.location}}</option>
                        </select>
                    </label>
                    <label>
                        Location 6 Ending:
                        <select formControlName="shippingLaneEnd6" class="form-control">
                            <option *ngFor="let l of locations" [value]="l.id">{{l.location}}</option>
                        </select>
                    </label>
                    <label class="col-lg-3 ms-3">
                        Total Cost 6:
                        <input type="text" formControlName="truckCost6" class="form-control">
                    </label>
                    <label class="col-lg-3 ms-3">
                        Location 6 %:
                        <input type="text" formControlName="location6" class="form-control">
                    </label>
                </div>

                <div class="col">
                    <label>
                        Transport Co. 6:
                        <select formControlName="transportCo6" class="form-control">
                            <option *ngFor="let t of transport" [value]="t.id">{{t.company }}</option>
                        </select>
                    </label>
                    <label class="ms-3">
                        Type of Freight:
                        <select formControlName="freightType6" class="form-control">
                            <option [value]=""></option>
                            <option value="CND">CND Freight Cost</option>
                            <option value="USD">USD Freight Cost</option>
                        </select>
                    </label>
                    <label class="col-lg-3 ms-3">
                        Cost
                        <input type="text" formControlName="freightCost6" class="form-control">
                    </label>
                    <label class="col-lg-3 ms-3">
                        FSC%
                        <input type="text" formControlName="freightCostSales6" class="form-control">
                    </label>

                    <label>
                        Notes:
                        <input type="text" formControlName="transNotesCo6" class="form-control" size="60">
                    </label>
                </div>

                <div class="smallSpacer"></div>
                <div class="col">
                    <label>
                        Location 7 Starting:
                        <select formControlName="location7Id" class="form-control">
                            <option *ngFor="let l of locations" [value]="l.id">{{l.location}}</option>
                        </select>
                    </label>
                    <label>
                        Location 7 Ending:
                        <select formControlName="shippingLaneEnd7" class="form-control">
                            <option *ngFor="let l of locations" [value]="l.id">{{l.location}}</option>
                        </select>
                    </label>
                    <label class="col-lg-3 ms-3">
                        Total Cost 7:
                        <input type="text" formControlName="truckCost7" class="form-control">
                    </label>
                    <label class="col-lg-3 ms-3">
                        Location 7 %:
                        <input type="text" formControlName="location7" class="form-control">
                    </label>
                </div>

                <div class="col">
                    <label>
                        Transport Co. 7:
                        <select formControlName="transportCo7" class="form-control">
                            <option *ngFor="let t of transport" [value]="t.id">{{t.company }}</option>
                        </select>
                    </label>
                    <label class="ms-3">
                        Type of Freight:
                        <select formControlName="freightType7" class="form-control">
                            <option [value]=""></option>
                            <option value="CND">CND Freight Cost</option>
                            <option value="USD">USD Freight Cost</option>
                        </select>
                    </label>
                    <label class="col-lg-3 ms-3">
                        Cost
                        <input type="text" formControlName="freightCost7" class="form-control">
                    </label>
                    <label class="col-lg-3 ms-3">
                        FSC%
                        <input type="text" formControlName="freightCostSales7" class="form-control">
                    </label>

                    <label>
                        Notes:
                        <input type="text" formControlName="transNotesCo7" class="form-control" size="60">
                    </label>
                </div>

                <div class="smallSpacer"></div>
                <div class="col">
                    <label>
                        Location 8 Starting:
                        <select formControlName="location8Id" class="form-control">
                            <option *ngFor="let l of locations" [value]="l.id">{{l.location}}</option>
                        </select>
                    </label>
                    <label>
                        Location 8 Ending:
                        <select formControlName="shippingLaneEnd8" class="form-control">
                            <option *ngFor="let l of locations" [value]="l.id">{{l.location}}</option>
                        </select>
                    </label>
                    <label class="col-lg-3 ms-3">
                        Total Cost 8:
                        <input type="text" formControlName="truckCost8" class="form-control">
                    </label>
                    <label class="col-lg-3 ms-3">
                        Location 8 %:
                        <input type="text" formControlName="location8" class="form-control">
                    </label>
                </div>

                <div class="col">
                    <label>
                        Transport Co. 8:
                        <select formControlName="transportCo8" class="form-control">
                            <option *ngFor="let t of transport" [value]="t.id">{{t.company }}</option>
                        </select>
                    </label>
                    <label class="ms-3">
                        Type of Freight:
                        <select formControlName="freightType8" class="form-control">
                            <option [value]=""></option>
                            <option value="CND">CND Freight Cost</option>
                            <option value="USD">USD Freight Cost</option>
                        </select>
                    </label>
                    <label class="col-lg-3 ms-3">
                        Cost
                        <input type="text" formControlName="freightCost8" class="form-control">
                    </label>
                    <label class="col-lg-3 ms-3">
                        FSC%
                        <input type="text" formControlName="freightCostSales8" class="form-control">
                    </label>

                    <label>
                        Notes:
                        <input type="text" formControlName="transNotesCo8" class="form-control" size="60">
                    </label>
                </div>
                <div class="smallSpacer"></div>
                <div class="col">
                    <label>
                        Blended Freight Cost:
                        <input type="text" formControlName="blendedFreightCost" class="form-control">
                    </label>

                    <label class="ms-3">
                        Blended %:
                        <input type="text" formControlName="blended" class="form-control">
                    </label>
                </div>
                <div *ngIf="this.spinner=='...Updating'"> <button type='button' class="btn btn-primary mt-3 w-25"
                        disabled>
                        <span class="spinner-border spinner-border-sm"></span>{{ spinner }}</button>
                </div>
                <div *ngIf="this.spinner==''">
                    <button type="submit" class="btn btn-primary mt-3 w-25">Save</button>
                    <button *ngIf="!listing" type="button" class="btn btn-warning ms-3 mt-3 w-25"
                        (click)="validate()">Save to Group</button>
                    <button *ngIf="listing" type="button" class="btn btn-warning ms-3 mt-3 w-25"
                        (click)="validateListing()">Save to Listing Group</button>
                    <br>
                    <button type="button" class="btn btn-secondary mt-3 w-25" (click)="editSetup()">Edit Setup</button>
                    <button type="button" class="btn btn-secondary ms-3 mt-3 w-25" (click)="editCaseSetup()">Case
                        Setup</button>
                    <button type="button" class="btn btn-secondary ms-3 mt-3 w-25" (click)="editPalletSetup()">Pallet
                        Setup</button>
                    <br>
                    <button type="button" class="btn btn-secondary mt-3 w-25" (click)="editCostingBucketSetup()">Costing
                        Bucket Setup</button>
                    <button *ngIf="listing" type="button" class="btn btn-secondary ms-3 mt-3 w-25"
                        (click)="editListingSetup()">Listing Setup</button>
                </div>
            </div>
            <ngb-alert *ngIf="successMessage" type="success" (close)="successMessage = null">{{ successMessage
                }}</ngb-alert>
        </form>
    </div>
    <div *ngIf="!listing" class="column">
        <h5>Group Products: {{ this.groupName }}</h5>
        <table class="table table-striped">
            <thead>
                <tr>
                    <th scope="col">Product Order Code</th>
                    <th scope="col">Order Type</th>
                    <th scope="col">Brand</th>
                    <th scope="col">Category</th>
                    <th scope="col">Item Description</th>
                    <th scope="col">Size</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let p of linkedProduct">
                    <td>{{ p.orderCode }}</td>
                    <td>{{ p.orderType }}</td>
                    <td>{{ p.brand }}</td>
                    <td>{{ p.category }}</td>
                    <td>{{ p.itemDescription }}</td>
                    <td>{{ p.size }}</td>
                </tr>
            </tbody>
        </table>
    </div>
    <div *ngIf="listing" class="column">
        <h5>Listing Products</h5>
        <table class="table table-striped">
            <thead>
                <tr>
                    <th scope="col">Product Order Code</th>
                    <th scope="col">Order Type</th>
                    <th scope="col">Brand</th>
                    <th scope="col">Category</th>
                    <th scope="col">Item Description</th>
                    <th scope="col">Size</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let p of linkedListing">
                    <td>{{ p.orderCode }}</td>
                    <td>{{ p.orderType }}</td>
                    <td>{{ p.brand }}</td>
                    <td>{{ p.category }}</td>
                    <td>{{ p.itemDescription }}</td>
                    <td>{{ p.size }}</td>
                </tr>
            </tbody>
        </table>
    </div>
</div>