export class CustomerEnd {
  id: number;
  activeItem: string;
  company: string;
  address: string;
  city: string;
  comments: string;
  countryRegion: string;
  currencyCode: string;
  eMail: string;
  faxNumber: string;
  fullAddress: string;
  fullName: string;
  jobTitle: string;
  mapLinkOffice: string;
  mapLinkWarehouse: string;
  mobileNumber: string;
  stateProvince: string;
  title: string;
  webPage: string;
  zipPostalCode: string;
  latitude: number;
  longitude: number;
  accountTypeId: number;
  regionId: number;
  customerId: number;
  shipToIds: string;
}
