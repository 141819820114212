<h4>Weeks - Kalcor</h4>
<h5></h5>
<table class="table" style="overflow-x:scroll;">
    <thead>
    <tr>
      <th scope="col">Year</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let y of year; index as i" (click)="clickedItem(i)">
      <a (click)="clickedItem(i)" class="pointer">
        <u class="text-primary">{{ y }}</u>
      </a>
    </tr> 
    </tbody>
  </table>

