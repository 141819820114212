import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { RestService } from '../../../services/rest.service';
import { OrderService } from '../../../services/order.service';
import { OrderSP } from '../../../models/order-model';
import { DataLink } from '../../../models/data-link.model';
import { DataLinkService } from '../../../services/data-link.service';
import { VendorService } from '../../../services/vendor.service';
import { Vendor } from '../../../models/vendor-model';
import { ThisReceiver } from '@angular/compiler';
//import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { WeekKalcorService } from 'src/app/services/week-kalcor.service';

@Component({
  selector: 'app-connect-budget',
  templateUrl: './connect-budget.component.html',
  styleUrls: ['./connect-budget.component.scss']
})
export class ConnectBudgetComponent implements OnInit {
  buttonLabel = 'Add more Vendors';
  vendorYear = [];
  lists = [];
  items = [];
  sales = [];
  buttonClicker = [];
  buttonRemover = [];
//  salesSp: OrderSP;
  salesSp = {};
  processIndexItem: number;
  processTotalCount: number;
  processIndex: number;
  processListId: string;
  totalCount: number;
  itemCounter: number;
  itemCount: number;
  processIndexSales: number;
  processIndexSalesItems: number;
  itemsPerOrder = [];
  dataLink: DataLink;
  vendor: Vendor[];
  dataLinks: DataLink[];
  yearRequested: any;
  selectedVendor: number;
  processVendIndex: number;
  page: number;
  pageSize = 35;
  count: number;
  spFields = [];
  myFields = [];
  periods = [];
  sp = [
    {displayName: "VENDOR", name: "vendor"},
    {displayName: "ORDER STATUS", name: "orderStatus"},
    {displayName: "ORDER TYPE", name: "orderType"},
    {displayName: "SALES ORDER NO", name: "salesOrderNo"},
    {displayName: "PURCHASE ORDER", name: "purchaseOrder"},
    {displayName: "SHIPMENT DATE", name: "shipmentDate"},
    {displayName: "CUSTOMER", name: "billTo"},
    {displayName: "SHIP TO", name: "shipTo"},
    {displayName: "BROKER", name: "broker"},
    {displayName: "ORDER CODE", name: "orderCode"},
    {displayName: "BRAND.", name: "brand"},
    {displayName: "CATEGORY.", name: "category"},
    {displayName: "ITEM DESCRIPTION", name: "itemDescription"},
    {displayName: "SIZE", name: "size"},
    {displayName: "CASES", name: "cases"},
    {displayName: "CASE COST", name: "caseCost"},
    {displayName: "EXT CASE COST", name: "extCaseCost"},
    {displayName: "MONTH/YEAR", name: "monthYear"},
    {displayName: "CUSTOMER CITY", name: "billCity"},
    {displayName: "CUSTOMER COUNTRY", name: "billCountryRegion"},
    {displayName: "CUSTOMER STATE", name: "billStateProvince"},
    {displayName: "CUSTOMER ZIP", name: "billZipPostalCode"},
    {displayName: "SHIP TO CITY", name: "shipCity"},
    {displayName: "SHIP TO COUNTRY", name: "shipCountryRegion"},
    {displayName: "SHIP TO STATE", name: "shipStateProvince"},
    {displayName: "SHIP TO ZIP", name: "shipZipPostalCode"},
    {displayName: "DOT LOCATION", name: "dotLocation"},
    {displayName: "YEAR", name: "year"},
    {displayName: "LATITUDE-C", name: "latitudeC"},
    {displayName: "LATITUDE-P", name: "latitudeP"},
    {displayName: "LATITUDE-S", name: "latitudeS"},
    {displayName: "LATITUDE-V", name: "latitudeV"},
    {displayName: "LONGITUDE-C", name: "longitudeC"},
    {displayName: "LONGITUDE-P", name: "longitudeP"},
    {displayName: "LONGITUDE-S", name: "longitudeS"},
    {displayName: "LONGITUDE-V", name: "longitudeV"},
    {displayName: "SALES MANAGER", name: "salesManager"},
    {displayName: "PERIOD/YEAR", name: "periodYear"},
    {displayName: "TYPE OF ACCOUNT.", name: "typeOfAccount1"},
    {displayName: "CASES INVOICED", name: "invoiced"}
  ]  
//  {displayName: "LATITUDE", name: "latitude"},
//  {displayName: "LONGITUDE", name: "longitude"},
  kstLineCount = 0;
  kstTotalExtension = 0;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private sharepoint: RestService,
    private orderService: OrderService,
    private dataLinkService: DataLinkService,
    private vendorService: VendorService,
    private weekKalcorService: WeekKalcorService,
  ) { }

  ngOnInit(): void {
    this.periods = [];
    this.yearRequested = this.route.snapshot.params['year'];
    this.weekKalcorService.getYear(this.yearRequested).subscribe(data => {
      this.periods = data;
    })
    this.route.data.subscribe(data => {
      this.vendorYear = data.dataList.item;
      this.page = data.dataList.page;
      this.count = data.dataList.count;
      for (let i = 0; i < this.vendorYear.length; i++) {
        this.buttonClicker.push(false);
      }
    })
    this.vendorService.getCompany().subscribe(vend => {
      this.vendor = vend;
    })
    // this.yearRequested = this.route.snapshot.params['year'];
  }
  run (index: number) {
    this.processIndex = index;
    if (!this.vendorYear[this.processIndex].sPListName) {
      alert('Please edit the list name in vendor maintenance');
      return;
    }

    this.buttonClicker[this.processIndex] = true;
    // get lists
    this.totalCount = 0;
    this.sharepoint.getLists().subscribe(list => {
      this.lists = list.value;
      const LIST_NAME = this.vendorYear[this.processIndex].salesYear + this.vendorYear[this.processIndex].sPListName + "BUDGET";
      const listIndex = this.lists.findIndex(l => l.name == LIST_NAME);
      if (listIndex > -1) {
        this.processListId = this.lists[listIndex].id;
        this.getItems();
      } else {
        alert("Unable to find SharePoint " + LIST_NAME + " list.")
        this.buttonClicker[this.processIndex] = false;
      }
    })
  }
  loadPage() {
    this.dataLinkService.getList(this.yearRequested, this.page, this.pageSize).subscribe(data => {
      this.vendorYear = data.item;
      this.page = data.page;
      this.count = data.count;

      for (let i = 0; i < this.vendorYear.length; i++) {
        this.buttonClicker.push(false);
      }

    });
  }
  getItems() {
    // reminder: sharepoint gives max of 200 items
    this.sharepoint.getListIdBudget(this.processListId).subscribe(items => {
      this.items = items.value;
      if (this.items.length > 0) {
        this.processIndexItem = -1;
        this.deleteItems();
      } else {
        this.getSalesData();
      }
    },error => {
      alert('Error received from SharePoint: ' + error.message)
    })

  }
  deleteItems() {
    this.vendorYear[this.processIndex].status = "Deleting";
    this.totalCount++;
    this.vendorYear[this.processIndex].counter = this.totalCount;
    this.processIndexItem++;
    if (this.processIndexItem < this.items.length) {
      this.sharepoint.remove(this.processListId, this.items[this.processIndexItem].id).subscribe(data => {
        this.deleteItems();
      },error => {
        alert('Error received from SharePoint: ' + error.message)
        // this.deleteItems();
      })
    } else {
    // reminder: sharepoint gives max of 200 items. So keep requesting until no items left.
      this.getItems();
    }
  }
  getSalesData() {
    this.vendorYear[this.processIndex].status = "Updating";
    this.totalCount = 0;
    this.processIndexSales = -1;
    this.orderService.getDetailsSP(this.vendorYear[this.processIndex].salesYear, this.vendorYear[this.processIndex].vendorId).subscribe(data => {
      this.sales = data.order;
      this.kstLineCount = 0;
      this.kstTotalExtension = 0;
      this.sales.forEach(item => {
        item.items.forEach(itm => {
          //3.15.23 direct cases to SP column "cases" direct invoiced to SP column "cases invoiced"
          // switch (item.orderStatus) {
          //   case 'Closed':
          //     itm.cases = itm.invoiced === null ? 0 : itm.invoiced;
          //     break;
          //   case 'Invoiced':
          //     itm.cases = itm.invoiced === null ? 0 : itm.invoiced;
          //     break;
          //   case 'Shipped':
          //     itm.cases = itm.shipped === null ? 0 : itm.shipped;
          //     break;
          //   default:
          //     itm.cases = itm.cases === null ? 0 : itm.cases;  
          //     break;
          // }

          // itm.caseCost = itm.caseCost === null ? 0 : itm.caseCost;
          // itm.extCaseCost = itm.caseCost * itm.cases;
          // const ext = itm.extCaseCost === null ? 0 : itm.extCaseCost;
          // this.kstTotalExtension += ext;
          // this.kstLineCount += ext > 0 ? 1 : 0;
          
          itm.cases = itm.cases === null ? 0 : itm.cases;
          itm.invoiced = itm.invoiced === null ? 0 : itm.invoiced;
          itm.caseCost = itm.caseCost === null ? 0 : itm.caseCost;

          if (item.orderStatus == 'Sales Order' || item.orderStatus == 'Shipped') {                     
            itm.extCaseCost = itm.caseCost * itm.cases;
          } else {           
            itm.extCaseCost = itm.caseCost * itm.invoiced;
          }

          const ext = itm.extCaseCost === null ? 0 : itm.extCaseCost;
          this.kstTotalExtension += ext;
          this.kstLineCount += ext > 0 ? 1 : 0;

        });
      })
      this.totalCount = 0;
      this.processIndexSales = 0;
      this.processIndexSalesItems = -1;
      this.getColumns();
    })
  }
  getColumns() {
    this.sharepoint.getListColumn(this.processListId).subscribe(columns => {
     const column = columns.columns;
     column.forEach(e => {
       const index = this.sp.findIndex(v => v.displayName == e.displayName);
       if (index > -1) {
         this.spFields.push(e.name);
         this.myFields.push(this.sp[index].name);
       }
     });
     this.insertSharePoint();
    })
  }

  insertSharePoint() {
    this.processIndexSalesItems++;
    if (this.processIndexSalesItems >= this.sales[this.processIndexSales].items.length) {
     this.processIndexSales++;
      this.processIndexSalesItems = 0;
    }
    if (this.processIndexSales < this.sales.length) {
        this.totalCount++;
        this.vendorYear[this.processIndex].counter = this.totalCount;
        this.initVar();
        this.sharepoint.create(this.processListId, this.salesSp).subscribe(data => {
          this.insertSharePoint();
        }, error => {
          alert('Error received from SharePoint: ' + error.message)
          // this.insertSharePoint();
        })
    } else {
      this.updateDataLink();      
    }
  //3.21.23 allow extCaseCost with 0 
  //   if (this.processIndexSales < this.sales.length) {
  //     if (this.sales[this.processIndexSales].items[this.processIndexSalesItems].extCaseCost !=0) {
  //       this.totalCount++;
  //       this.vendorYear[this.processIndex].counter = this.totalCount;
  //       this.initVar();
  //       this.sharepoint.create(this.processListId, this.salesSp).subscribe(data => {
  //         this.insertSharePoint();
  //       }, error => {
  //         alert('Error received from SharePoint: ' + error.message)
  //         // this.insertSharePoint();
  //       })
  //     } else {
  //       this.insertSharePoint();
  //     }
  // } else {
  //   this.updateDataLink();      
  //   }
  }

  initVar() {
    this.salesSp = {};
    this.spFields.forEach((field, index) => {
      this.salesSp[field] = this.getValue(this.myFields[index]);
    })
  }
  getValue(variable) {
    let retValue;
    let shipDate = new Date(this.sales[this.processIndexSales].shipmentDate);
    switch (variable) {
      case "broker":
        let broker = this.sales[this.processIndexSales].broker ? this.sales[this.processIndexSales].broker : "";
        let broker2 = this.sales[this.processIndexSales].broker2 ? this.sales[this.processIndexSales].broker2 : "";
        if (broker) {
          if (broker2) {
            broker += " / " + broker2;
          }
        } else {
          broker = broker2;
        }
        retValue = broker;
        break;
      case "year":
          retValue = shipDate.getFullYear() + '';
          break;
      case "monthYear":
          const month = ["JAN","FEB","MAR","APR","MAY","JUN","JUL","AUG","SEP","OCT","NOV","DEC"];
          const shipMonth = shipDate.getMonth(); // 0 to 11
          const shipMonthName = month[shipMonth];
          let monthYear = "0" + (shipMonth + 1);
          monthYear = monthYear.slice(-2) + "-" + month[shipMonth] + "-" + shipDate.getFullYear();
          retValue = monthYear;
          break;
        
        case "periodYear":
            retValue = '';
            const shippedDate = this.sales[this.processIndexSales].shipmentDate.substring(0, 10);
            for (let i = 0; i < this.periods.length; i++) {
              if (shippedDate >= this.periods[i].weekStartDate && shippedDate <= this.periods[i].weekEndDate) { 
                let periodYear = "0" + this.periods[i].period + "-PERIOD";             
                if (this.periods[i].period > 9) {
                  periodYear = this.periods[i].period + "-PERIOD";
                } 
                retValue = periodYear;
                break;
              }
            }
            break;  
       case "orderCode":
          retValue = this.sales[this.processIndexSales].items[this.processIndexSalesItems].orderCode;
          break;

       case "brand":
        retValue = this.sales[this.processIndexSales].items[this.processIndexSalesItems].brand;
        break;
      
       case "itemDescription":
        retValue = this.sales[this.processIndexSales].items[this.processIndexSalesItems].itemDescription;
        break;
       case "size":
        retValue = this.sales[this.processIndexSales].items[this.processIndexSalesItems].size;
        break;
       case "cases":
        retValue = this.sales[this.processIndexSales].items[this.processIndexSalesItems].cases;
        break;
       case "caseCost":
        retValue = this.sales[this.processIndexSales].items[this.processIndexSalesItems].caseCost;
         break;
       case "extCaseCost":
        retValue = this.sales[this.processIndexSales].items[this.processIndexSalesItems].extCaseCost;
        break;
        case "category":
        retValue = this.sales[this.processIndexSales].items[this.processIndexSalesItems].category;
        break;
        case "invoiced":
          retValue = this.sales[this.processIndexSales].items[this.processIndexSalesItems].invoiced;
          break;
      default:
        retValue = this.sales[this.processIndexSales][variable];
        if (variable.indexOf('latitude') > -1 || variable.indexOf('longitude') > -1) {
          retValue = retValue === null ? retValue : retValue + '';
        }
        break;
    }
    return retValue;
  }  

  updateDataLink() {
    this.dataLink = new DataLink();
    this.vendorYear[this.processIndex].status = "Completed";
    this.buttonClicker[this.processIndex] = false;
    const todaysDate = new Date();
    // let today = 
    // todaysDate = new Date();
    // "2018-12-04T12:02:56"
    this.dataLink.dataLinkId = this.vendorYear[this.processIndex].dataLinkId;
    this.dataLink.lastUpdate = todaysDate;
    this.vendorYear[this.processIndex].lastUpdate = todaysDate;
    this.dataLink.userId = this.getUserId();
    this.vendorYear[this.processIndex].name = this.getUserName();
    this.dataLinkService.update(this.dataLink).subscribe(data => {
      alert("all done. " + "\r\n" + "Total items: " + this.kstLineCount + "\r\n" + " Total Extension Case Cost "+ this.kstTotalExtension.toFixed(2));
    })
  }
  getUserId() {
    let userId = 0;
    const currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
    if (currentUser) {
      userId = currentUser.id;  
    }
    return userId;
  }
  getUserName() {
    let userName = '';
    const currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
    if (currentUser) {
      userName = currentUser.firstName;  
    }
    return userName;
  }
  addVendors() {
    this.dataLinks = [];
    this.vendor.forEach(vend => {
      const vendIndex = this.vendorYear.findIndex(v => v.vendorId == vend.id);
      if (vendIndex == -1) {
        this.dataLink = new DataLink();
        this.dataLink.salesYear = this.yearRequested * 1;
        this.dataLink.vendorId = vend.id
        this.dataLinks.push(this.dataLink);
      }
    })
    this.processVendIndex = -1;
    this.buttonLabel = 'Adding more vendors ...'
    this.addVend();
  }

  addVend() {
    this.processVendIndex++;
    if (this.processVendIndex < this.dataLinks.length) {
      this.dataLinkService.create(this.dataLinks[this.processVendIndex]).subscribe(data => {
        this.addVend();
      }, error => {
        this.addVend();
      })
    } else {
      alert('Added more vendors completed. Press enter to Refresh ');
      window.location.reload();
    }
  }

  removeVendor( index: number ) {
    this.selectedVendor = index;
    const venData = this.vendorYear[this.selectedVendor].dataLinkId;

    if (venData) {
      if (confirm('Delete this item?'))
      this.dataLinkService.remove(venData).subscribe(data => {
        this.router.navigate(['/portal/share-link']);
      });
      }
    }
  
}
