import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { SalesManager } from '../../../models/sales-manager.model';
import { SalesManagerService } from '../../../services/sales-manager.service';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
@Component({
  selector: 'app-sales-manager-edit',
  templateUrl: './sales-manager-edit.component.html',
  styleUrls: ['./sales-manager-edit.component.scss']
})
export class SalesManagerEditComponent implements OnInit {
  title = 'Sales Manager New';
  newOne = true;
  SalesManager: SalesManager;
  SalesManagerForm: UntypedFormGroup;
  successMessage: string;
  private success = new Subject<string>();
  staticAlertClosed = false;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private fb: UntypedFormBuilder,
              private SalesManagerSvc: SalesManagerService) {
                  this.SalesManager = new SalesManager();
              }

  ngOnInit() {
    this.SalesManagerForm = this.fb.group({
      fullName: [''],
      jobTitle: [''],
      eMail: [''],
    });
    this.route.data.subscribe(data => {
      if (data.dataSalesManager !== undefined) {
        this.title = 'Sales Manager Edit';
        this.newOne = false;
        this.SalesManager = data.dataSalesManager;
        this.fillFormData();
      } else {
        this.title = 'Sales Manager New';
        this.newOne = true;
        this.SalesManager = new SalesManager();
        this.SalesManagerForm.reset();
      }
    });
    setTimeout(() => this.staticAlertClosed = true, 20000);
    this.success.subscribe((message) => this.successMessage = message);
    this.success.pipe(
      debounceTime(5000)
    ).subscribe(() => this.successMessage = null);
  }
  fillFormData() {
    this.SalesManagerForm.setValue({
      fullName: this.SalesManager.fullName,
      jobTitle: this.SalesManager.jobTitle,
      eMail: this.SalesManager.email,
    });
  }
  onSubmit() {
    this.SalesManager.fullName = this.SalesManagerForm.controls.fullName.value;
    this.SalesManager.jobTitle = this.SalesManagerForm.controls.jobTitle.value;
    this.SalesManager.email = this.SalesManagerForm.controls.eMail.value;
    if (this.newOne) {
      this.SalesManagerSvc.create(this.SalesManager).subscribe(data => {
        this.SalesManager.salesMgrId = data.id;
        this.newOne = false;
        this.title = 'Sales Manager Edit';
        this.success.next(`Successfully added.`);
      }, error => {
      });
    } else {
      this.SalesManagerSvc.update(this.SalesManager).subscribe(data => {
        this.success.next(`Successfully changed.`);
      }, error => {
      });
    }
  }
  onDelete() {
    if (this.SalesManager.salesMgrId) {
      this.SalesManagerSvc.remove(this.SalesManager.salesMgrId).subscribe(data => {
        this.router.navigate(['/other/sales-manager-list']);
      });
    }
  }
}
