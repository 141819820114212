import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { CustomerEnd } from '../models/customer-end-model';
const url = environment.kalcorApiUrl;

@Injectable({
  providedIn: 'root'
})
export class CustomerEndService {

  constructor(private http: HttpClient) { }
  getAll(): Observable<any> {
    return this.http.get(url + 'AccountsCustomersEnds', this.jwt());
  }
  getOne(id: number): Observable<any> {
    return this.http.get(url + 'AccountsCustomersEnds/' + id, this.jwt());
  }
  getCompany(): Observable<any> {
    return this.http.get(url + 'AccountsCustomersEnds/Company', this.jwt());
  }
  getFilteredListing(activeItem: string, id: number, page: number, pageSize: number): Observable<any> {
    page = page ? page : 1;
    pageSize = pageSize ? pageSize : 30;
    return this.http.get(url + 'AccountsCustomersEnds/Filter?activeItem=' + activeItem + '&id=' + id
    + '&page=' + page + '&pageSize=' + pageSize, this.jwt());
  }
  getFilter(): Observable<any> {
      return this.http.get(url + 'AccountsCustomersEnds/Filter', this.jwt());
  }
  getCustomerEndYears(): Observable<any> {
    return this.http.get(url + 'AccountsCustomersEnds/Years', this.jwt());
  }
  getOneYears(id: number): Observable<any> {
    return this.http.get(url + 'AccountsCustomersEnds/' + id + '/Years', this.jwt());
  }
  update(customerEnd) {
    return this.http.put(url + 'AccountsCustomersEnds/' + customerEnd.id, customerEnd, this.jwt());
  }
  create(customerEnd): Observable<any> {
    return this.http.post(url + 'AccountsCustomersEnds', customerEnd, this.jwt());
  }
  remove(id) {
    return this.http.delete(url + 'AccountsCustomersEnds/' + id, this.jwt());
  }

  private jwt() {
    // create authorization header with jwt token
    const currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
    if (currentUser && currentUser.token) {
        const headersInfo = new HttpHeaders({ Authorization: 'Bearer ' + currentUser.token });
        return { headers: headersInfo };
    }
  }
}
