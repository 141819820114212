import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { PalletType } from '../../models/pallet-type.model';
import { PalletTypeService } from '../../services/pallet-type.service';

@Injectable()
export class PalletTypeResolver implements Resolve<PalletType> {
    constructor(private palletTypeService: PalletTypeService) {
    }
  
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<PalletType> {
      const idField = 'id';
      const id =  parseInt(route.params[idField], 10);
  
      if (id === 0) {
        return;
      }
      return this.palletTypeService.getOne(id);
    }
  }
  