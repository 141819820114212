import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { UpcGtn } from '../../models/upc-gtn.model';
import { UpcGtnService } from '../../services/upc-gtn.service';

@Injectable()
export class UpcGtnResolver implements Resolve<UpcGtn> {
  constructor(private upcGtnService: UpcGtnService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<UpcGtn> {
    const idField = 'id';
    const id =  parseInt(route.params[idField], 10);

    if (id === 0) {
      return;
    }
    return this.upcGtnService.getOne(id);
  }
}
