<h5>CREDIT NOTE INFORMATION</h5>
<form [formGroup]="creditForm"  novalidate>
    <div class="form-group">
        <div class="row">
            <label for="creditDate" class="col-lg-4 pt-2">
                Credit Date (*)
            </label>
            <div class="col-lg-4 pt-2">
            <input id="creditDate" type="date" formControlName="creditDate" class="form-control" >
            </div>
        </div>
        <div class="row">
            <label for="creditNo" class="col-lg-4 pt-2">
                Credit No. (*)
            </label>
            <div class="col-lg-4 pt-2">
            <input id="creditNo" type="text" formControlName="creditNo" class="form-control" >
        </div>
        </div>
        <div class="row">
            <label for="purchaseOrder" class="col-lg-4 pt-2">
                Purchase Order
            </label>
            <div class="col-lg-4 pt-2">
            <select id="purchaseOrder" formControlName="poIndex" class="form-control">
                <option [value]=""></option>
                <option *ngFor="let p of po; index as i" [value]="i">{{ p.purchaseOrder }}</option>
            </select>
            </div>
        </div>
        <div class="row">
            <label for="billToId" class="col-lg-4 pt-2">
                BILL TO (*)
            </label>
            <div class="col-lg-4 pt-2">
            <select formControlName="billToId" class="form-control">
                <option [value]=""></option>
                <option *ngFor="let c of customers" [value]="c.id">{{ c.company }}</option>
            </select>
            </div>
        </div>
        <div class="row">
            <label for="vendorId" class="col-lg-4 pt-2">
                VENDOR (*)
            </label>
            <div class="col-lg-4 pt-2">
            <select formControlName="vendorId" class="form-control">
                <option [value]=""></option>
                <option *ngFor="let v of vendors" [value]="v.id">{{ v.company }}</option>
            </select>
            </div>
        </div>
        <div class="row">
            <label class="col-lg-4 pt-2">
                SHIP TO (*)
            </label>
            <label class="col-lg-5 pt-2 ps-0">
                Company
            </label>
            <label class="col-lg-3 pt-2">
                Broker 1
            </label>
            <label class="col-lg-2 pt-2">
                Commission 1
            </label>
            <label class="col-lg-3 pt-2">
                Broker 2
            </label>
            <label class="col-lg-2 pt-2">
                Commission 2
            </label>
            <label class="col-lg-2 pt-2">
                % of Credit
            </label>
        </div>   
        <div class="row" *ngFor="let c of creditShipTo; let i = index;">
            <label class="col-lg-4 pt-2">
            </label>
            <div class="col-lg-5 ps-0">
                <input type="text" readonly [(ngModel)]="c.shipToCompany" class="form-control" [ngModelOptions]="{standalone: true}">
            </div>
            <div class="col-lg-3">
                <select  [(ngModel)]="c.brokerId"  class="form-control"  [ngModelOptions]="{standalone: true}" >
                    <option [value]="0"></option>
                    <option *ngFor="let b of brokers" [value]="b.id">{{b.company}}</option>
                </select>
            </div>
            <div class="col-lg-2">
                <input type="text" [(ngModel)]="c.commission1" class="form-control" [ngModelOptions]="{standalone: true}" (change)="commUpdate(i)">
            </div>
            <div class="col-lg-3">
                <select  [(ngModel)]="c.brokerId2"  class="form-control"  [ngModelOptions]="{standalone: true}" >
                    <option [value]="0"></option>
                    <option *ngFor="let b of brokers" [value]="b.id">{{b.company}}</option>
                </select>
              </div>
            <div class="col-lg-2">
                <input type="text" [(ngModel)]="c.commission2" class="form-control" [ngModelOptions]="{standalone: true}" (change)="commUpdate(i)">
            </div>
            <div class="col-lg-2">
                <input type="text" [(ngModel)]="c.creditPercent" class="form-control" [ngModelOptions]="{standalone: true}" (change)="commUpdate(i)">
            </div>
            
        </div>
        <div class="row">
            <label for="customerCreditNo" class="col-lg-4 pt-2">
                Customer Credit No. (*)
            </label>
            <div class="col-lg-4 pt-2">
            <input id="customerCreditNo" type="text" formControlName="customerCreditNo" class="form-control" >
            </div>
        </div>
        
        <div class="row">
            <label for="batchNo" class="col-lg-4 pt-2">
                Batch No.
            </label>
            <div class="col-lg-4 pt-2">
            <select id="batchNo"  [(ngModel)]="credit.batchNo"  class="form-control"  [ngModelOptions]="{standalone: true}" >
                <option [value]=""></option>
                <option *ngFor="let b of batchNumbers" [value]="b.batchNo">{{b.batchNo}}</option>
            </select>
            </div>
        </div>
                
        <div class="row">
            <label for="batchType" class="col-lg-4 pt-2">
                Type of Batch
            </label>
            <div class="col-lg-4 pt-2">
            <select id="batchType"  [(ngModel)]="credit.batchType"  class="form-control"  [ngModelOptions]="{standalone: true}" >
                <option [value]=""></option>
                <option *ngFor="let b of batchTypes" [value]="b">{{ b }}</option>
            </select>
            </div>
        </div>

        <div class="row">
            <label for="batchStatus" class="col-lg-4 pt-2">
                Batch Status
            </label>
            <div class="col-lg-4 pt-2">
            <select id="batchStatus"  [(ngModel)]="credit.batchStatus"  class="form-control"  [ngModelOptions]="{standalone: true}" >
                <option [value]=""></option>
                <option value="Open">Open</option>
                <option value="Closed">Closed</option>
            </select>
            </div>
        </div>

        <div class="row">
            <label for="creditStatus" class="col-lg-4 pt-2">
                Credit Status
            </label>
            <div class="col-lg-4 pt-2">
            <input id="creditStatus" type="text" formControlName="creditStatus" class="form-control" >
            </div>
        </div>


        <div class="row">
            <label for="creditType" class="col-lg-4 pt-2">
                Credit Type (*)
            </label>
            <div class="col-lg-4 pt-2">
            <select id="creditType" formControlName="creditType" class="form-control">
                <option [value]=""></option>
                <option value="Retail">Retail</option>
                <option value="Retail Budget">Retail Budget</option>
                <option value="Food Service">Food Service</option>
                <option value="Food Service Budget">Food Service Budget</option>
                <option value="Stock">Stock</option>
                <option value="Stock Budget">Stock Budget</option>
            </select>
            </div>
        </div>    
        <div class="row">
            <label for="promotionalNumber" class="col-lg-4 pt-2">
                Promotional Number
            </label>
            <div class="col-lg-4 pt-2">
            <input id="promotionalNumber" type="text" formControlName="promotionalNumber" class="form-control" >
            </div>
        </div>
        <div class="row">
            <label for="creditReasonId" class="col-lg-4 pt-2">
                Credit Reason
            </label>
            <div class="col-lg-4 pt-2">
            <select formControlName="creditReasonID" class="form-control">
                <option [ngValue]=""></option>
                <option *ngFor="let c of creditReason" [ngValue]="c.id">{{ c.creditReason1 }}</option>
            </select>
            </div>
        </div>
        <div class="row">
            <label for="creditPromotionExplaination" class="col-lg-4 pt-2">
                Credit/Promotion Explanation
            </label>
            <div class="col-lg-6 pt-2">
            <textarea id="creditPromotionExplaination" formControlName="creditPromotionExplaination" class="form-control">
            </textarea>
            </div>
        </div>
        <div class="row">
            <label for="promoStartDate" class="col-lg-4 pt-2">
                Promo Start Date
            </label>
            <div class="col-lg-4 pt-2">
            <input id="promoStartDate" type="date" formControlName="promoStartDate" class="form-control" >
            </div>
        </div>
        <div class="row">
            <label for="promoEndDate" class="col-lg-4 pt-2">
                Promo End Date
            </label>
            <div class="col-lg-4 pt-2">
            <input id="promoEndDate" type="date" formControlName="promoEndDate" class="form-control" >
            </div>
        </div>
        <div class="row">
            <label for="brandId" class="col-lg-4 pt-2">
                Brand
            </label>
            <div class="col-lg-4 pt-2">
            <select formControlName="brandId" class="form-control">
                <option [ngValue]=""></option>
                <option *ngFor="let b of brand" [ngValue]="b.id">{{ b.brand }}</option>
            </select>
            </div>
        </div>
        <div class="row">
            <label for="categoryId" class="col-lg-4 pt-2">
                Category
            </label>
            <div class="col-lg-4 pt-2">
            <select formControlName="categoryId" class="form-control">
                <option [value]=""></option>
                <option *ngFor="let c of category" [ngValue]="c.id">{{ c.category }}</option>
            </select>
            </div>
        </div>
        <div class="row">
            <label for="cases" class="col-lg-4 pt-2">
                Cases
            </label>
            <div class="col-lg-4 pt-2">
            <input id="cases" type="text" formControlName="cases" class="form-control" >
            </div>
        </div>

        <div class="row">
            <label for="billBackPerCase" class="col-lg-4 pt-2">
                Bill Back per Case
            </label>
            <div class="col-lg-4 pt-2">
            <input id="billBackPerCase" type="text" formControlName="billBackPerCase" class="form-control" >
            </div>
        </div>

        <div class="row">
            <label for="creditAmount" class="col-lg-4 pt-2">
                Credit Amount (*)
            </label>
            <div class="col-lg-4 pt-2">
            <input id="creditAmount" type="text" formControlName="creditAmount" class="form-control" >
            </div>
        </div>
        <div class="row">
            <label for="bucket" class="col-lg-4 pt-2">
                Bucket (*)
            </label>
            <div class="col-lg-4 pt-2">
            <select formControlName="bucket" class="form-control">
                <option [value]=""></option>
                <option *ngFor="let b of bucket" [value]="b.buckets">{{ b.buckets }}</option>
            </select>
            </div>
        </div>
        <div class="row">
            <div class="col-4">
            </div>
            <div class="col-4 px-0">
              <button type="button" (click)="onSubmit('Open')" class="btn btn-primary mt-3 w-100">SAVE CREDIT NOTE - OPEN</button>
            </div>
        </div>
        <div class="row">
            <div class="col-4">
            </div>
            <div class="col-4 px-0">
              <button type="button" (click)="onSubmit('Closed')" class="btn btn-primary mt-3 w-100">SAVE CREDIT NOTE - CLOSED</button>
            </div>
        </div>
        <div class="row">
            <div class="col-4">
            </div>
            <div class="col-4 px-0">
              <button type="button" (click)="onSubmit('Budget')" class="btn btn-primary mt-3 w-100">SAVE CREDIT NOTE - BUDGET</button>
            </div>
        </div>
        <div class="row">
            <div class="col-4">
            </div>
            <div class="col-4 px-0">
              <button type="button" (click)="onDelete()" class="btn btn-secondary mt-3 w-100">Delete</button>
            </div>
        </div>
    </div>
</form>
<p>(*) Denotes Mandatory</p>
<ngb-alert *ngIf="successMessage" [type]="alertType" (close)="successMessage = null">{{ successMessage }}</ngb-alert>
