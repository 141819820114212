export class VendorData {
    id: number;
    orderType: string; 
    salesOrderNo: string;
    purchaseOrder: string;
    orderStatus: string;
    vendor: string;
    brand: string;
    category: string;
    orderCode: string;
    itemDescription: string;
    size: string;
    fullItemDescription: string;
    casesBudget: number;
    extCaseCostBudget: number;
    cases: number;
    casesInvoiced: number;
    caseCost: number;
    extCaseCost: number;
    period: string;
    month: string;
    year: number;
    shipmentDate: Date;
    salesManager: string;
    typeOfAccount: string;
    dotLocation: string;
    customer: string;
    cLatitude: number;
    cLongitude: number;
    customerZip: string;
    shipTo: string;
    sLatitude: number;
    sLongitude: number;
    shipToZip: string;
    broker: string;
    vendorId: number;
    banner: string;
    headOffice: string;
    budgetType: string;
    broker2: string;
}