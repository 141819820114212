<div *ngFor="let b of buckets; index as i" class="pdf">
    <div *ngIf="b">
        <div *ngIf="i > firstSelectMonthIndex" class ="break-MONTH"></div>
        <div class="row g-0 mt-2">
            <div class="col-lg-10 pt-2 px-0 fw-bold">
                Customer: {{ customerName }}
            </div>
            <div class="col-lg-10 pt-2 px-0 fw-bold">
                {{ bucketSelected }}
            </div>
            <div class="col-lg-4 pt-2 px-0 fw-bold">
                Month {{ monthName[i]}}
            </div>
        </div>

        <div class="row g-0">
            <div class="col-lg-20 px-0 fw-bold">
                Vendor: {{ vendorName }}
            </div>
            <div class="col-lg-4 px-0 fw-bold">
                Year {{ yearSelected }}
            </div>
        </div>
        <div class="row g-0">
            <div class="col-lg-20 px-0 fw-bold">
                Order Type: {{ orderType }}
            </div>    
          </div>
   

        <div class="row g-0 mt-0">
            <table class="w-100">
                <thead class="mt-3 pe-2 border-top border-bottom">
                    <th>Order Status</th>
                    <th>Order Type</th>
                    <th>Sales Order No</th>
                    <th>Purchase Order</th>
                    <th>Shipment Date</th>
                    <th>Customer</th>
                    <th>Ship To</th>
                    <th>Broker</th>
                    <th class="text-end">Sales Amount</th>
                    <th class="text-end">Balance</th>
                </thead>
                <tbody>
                    <tr *ngFor="let o of incoming[i]">
                        <td>{{ o.orderStatus }} </td>
                        <td>{{ o.orderType }} </td>
                        <td>{{ o.salesOrderNo }}</td>
                        <td>{{ o.purchaseOrder }}</td>
                        <td>{{ displayDate(o.shipmentDate, '') }}</td>
                        <td>{{ o.billTo }}</td>
                        <td>{{ o.shipTo }}</td>
                        <td>{{ o.broker }}</td>
                        <td class="text-end" [ngClass]="{'text-danger': o.salesAmount < 0}">
                            {{ o.salesAmount | currency }}
                        </td>
                        <td class="text-end" [ngClass]="{'text-danger': o.balance < 0}">
                            {{ o.balance | currency }}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    
        <div class="row g-0 mt-2">
            <div class="col-lg-16 pt-2 px-0 pdf-bg">
                <label class="text-white w-100 background">
                    TOTAL
                </label>
            </div>
            <div class="col-lg-4 pt-2 px-0 pdf-bg">
                <label class="text-white w-100 background text-center">
                   MONTH: {{ monthName[i]}} {{ yearSelected }}
                </label>
            </div>
            <div class="col-lg-4 pt-2 px-0 pdf-bg">
                <label class="text-white w-100 background text-end" [ngClass]="{'text-danger': balEndCurrentMonth[i] < 0}">
                    {{ balEndCurrentMonth[i] | currency }}
                </label>
            </div>
        </div>
    </div>
</div>    