export class Credit {
    id: number;
    creditDate: string;
    creditNo: string;
    purchaseOrder: string;
    billToId: number;
    vendorId: number;
    customerCreditNo: string;
    creditStatus: string;
    creditType: string;
    creditPromotionExplaination: string;
    promoStartDate: Date;
    promoEndDate: Date;
    broker1Id: number;
    broker2Id: number;
    commission1: number;
    commission2: number;
    brandId: number;
    categoryId: number;
    cases: number;
    billBackPerCase: number;
    creditAmount: number;
    bucket: string;
    batchNo: number;
    batchType: string;
    batchStatus: string;
    promotionalNumber: string;
    creditReasonID: number;
}
  
export class CreditData {
    creditStatus: string;
    creditType: string;
    creditNo: string;
    purchaseOrder: string;
    creditDate: Date;
    customerCreditNo: string;
    customerCompany: string;
    customerId: number;
    vendorCompany: string;
    vendorId: number;
    creditPromotionExplaination: string;
    brokerCompany: string;
    brokerId: number;
    creditAmount: number;
    bucket: string;
    debit: number;
    balance: number;
    commission1: number;
    commission2: number;
}

export class CreditShipToBroker {
    creditShipToBrokerId: number;
    creditInfoId: number;
    shipToId: number;
    brokerId: number;
    brokerId2: number;
    commission1: number;
    commission2: number;
    commissionDollars1: number;
    commissionDollars2: number;
    creditPercent: number;
}

export class CreditBatch {
    creditBatchId: number;
    batchNo: number;
    batchStatus: string;
}