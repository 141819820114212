import { Component, OnInit } from '@angular/core';
import { ExchangeRateService } from '../../../services/exchange-rate.service';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
@Component({
  selector: 'app-exchange-rate-list',
  templateUrl: './exchange-rate-list.component.html',
  styleUrls: ['./exchange-rate-list.component.css']
})
export class ExchangeRateListComponent implements OnInit {
  exchangeRate: any;
  page = 1;
  pageSize = 30;
  successMessage: string;
  private success = new Subject<string>();
  alertType = "success";
  staticAlertClosed = false;
  constructor(
    private router: Router,
    private exchangeRateSvc: ExchangeRateService
  ) { }

  ngOnInit(): void {
    this.exchangeRateSvc.getAll().subscribe(data => {
      this.exchangeRate = data
    },error => {
      this.successMessage = 'Unable to process request';
      this.alertType = "danger";
    });
    setTimeout(() => this.staticAlertClosed = true, 20000);
    this.success.subscribe((message) => this.successMessage = message);
    this.success.pipe(
      debounceTime(5000)
    ).subscribe(() => this.successMessage = null); 
  }
  onRowClicked(exchange) {
    this.router.navigate(['/other/exchange-rate-edit', exchange.exchangeId]);
  }
}
