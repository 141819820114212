<h5>{{ title }} </h5>
<div class="row">
    <div class="column">
        <form [formGroup]="productForm" (ngSubmit)="onSubmit()" novalidate>
            <div class="form-group">
                <div class="col">
                    <label>
                        Active Listing:
                        <input type="text" formControlName="activeListings" class="form-control">
                    </label>

                    <label class="ms-3">
                        Product Order Code:
                        <input type="text" formControlName="productOrderCode" class="form-control">
                    </label>
                </div>

                <div class="col">
                    <label>
                        Brand:
                        <input type="text" formControlName="brand" class="form-control">
                    </label>

                    <label class="ms-3">
                        Category:
                        <input type="text" formControlName="category" class="form-control">
                    </label>
                </div>

                <div class="col">
                    <label>
                        Item Desciption:
                        <input type="text" formControlName="itemDescription" class="form-control">
                    </label>

                    <label class="ms-3">
                        Size:
                        <input type="text" formControlName="size" class="form-control">
                    </label>
                </div>

                <div class="col">
                    <label>
                        WHS Selling:
                        <input type="text" formControlName="whsSelling" class="form-control">
                    </label>
                    <label class="ms-3">
                        WHS SRP:
                        <input type="text" formControlName="whsSrp" class="form-control">
                    </label>
                    <label class="ms-3">
                        WHS Unit:
                        <input type="text" formControlName="whsUnit" class="form-control">
                    </label>
                </div>

                <div class="col">
                    <label>
                        Marketing:
                        <input type="text" formControlName="marketing" class="form-control">
                    </label>
                    <label class="ms-3">
                        Marketing %:
                        <input type="text" formControlName="marketing1" class="form-control">
                    </label>
                </div>

                <div class="col">
                    <label>
                        Billing:
                        <input type="text" formControlName="billing" class="form-control">
                    </label>
                    <label class="ms-3">
                        Billing %:
                        <input type="text" formControlName="billing1" class="form-control">
                    </label>
                </div>

                <div class="col">
                    <label>
                        Terms:
                        <input type="text" formControlName="terms" class="form-control">
                    </label>
                    <label class="ms-3">
                        Terms %:
                        <input type="text" formControlName="terms1" class="form-control">
                    </label>
                </div>

                <div class="col">
                    <label>
                        Commission Overall:
                        <input type="text" formControlName="commission" class="form-control">
                    </label>
                    <label class="ms-3">
                        Commission % :
                        <input type="text" formControlName="commission1" class="form-control">
                    </label>
                    <!-- <label class="ms-3">
                Commission % 2:
                <input type="text" formControlName="commission2" class="form-control">
            </label> -->
                </div>
                <div class="row ms-0">
                    <div class="col-lg-10">Selected Ship To:</div>
                    <div class="col-lg-3">Comm %1:</div>
                    <div class="col-lg-3">Comm %2:</div>
                </div>

                <div class="row ms-0" *ngFor="let s of shipToBroker; let i = index;">
                    <!-- <div class="col-lg-5">{{ s.name }}</div> -->
                    <div class="col-lg-10">
                        <input type="text" readonly [(ngModel)]="s.name" class="form-control"
                            [ngModelOptions]="{standalone: true}">
                    </div>
                    <div class="col-lg-3">
                        <input type="text" [(ngModel)]="s.commission1" class="form-control"
                            [ngModelOptions]="{standalone: true}" (change)="commUpdate(i)">
                    </div>

                    <div class="col-lg-3">
                        <input type="text" [(ngModel)]="s.commission2" class="form-control"
                            [ngModelOptions]="{standalone: true}" (change)="commUpdate(i)">
                    </div>

                </div>
                <div class="col">
                    <label>
                        Kalcor:
                        <input type="text" formControlName="kalcor" class="form-control">
                    </label>
                    <label class="ms-3">
                        Kalcor %:
                        <input type="text" formControlName="kalcor1" class="form-control">
                    </label>
                </div>

                <div class="col">
                    <label>
                        CND FRT Cost/lb:
                        <input type="text" formControlName="cndFrtCostLb" class="form-control">
                    </label>
                </div>

                <div class="col">
                    <label>
                        CND FRT Cost/Case:
                        <input type="text" formControlName="cndFrtCostCase" class="form-control">
                    </label>
                    <label class="ms-3">
                        CND FRT Cost/Pallet:
                        <input type="text" formControlName="cndFrtCostPallet" class="form-control">
                    </label>
                </div>

                <div class="col">
                    <label>
                        Storage Cost/Case:
                        <input type="text" formControlName="storageCostCase" class="form-control">
                    </label>
                    <label class="ms-3">
                        Storage Cost/Pallet:
                        <input type="text" formControlName="storageCostPallet" class="form-control">
                    </label>
                </div>

                <div class="col">
                    <label>
                        Net CND:
                        <input type="text" formControlName="netCnd" class="form-control">
                    </label>
                </div>

                <div class="col">
                    <label>
                        FOB Net Return:
                        <input type="text" formControlName="fobNetReturn" class="form-control">
                    </label>
                    <label class="ms-3">
                        Exchange Difference:
                        <input type="text" formControlName="fobDifference" class="form-control">
                    </label>
                </div>

                <div class="col">
                    <label>
                        Exchange Budget:
                        <select formControlName="exchangeBudget" class="form-control">
                            <option *ngFor="let e of exchangeRate" [value]="e.exchangeRateAmount">{{
                                e.exchangeRateAmount }}</option>
                        </select>
                    </label>
                    <!-- <label class="ms-3">
                Net CS USD:
                <input type="text" formControlName="netCsUsd" class="form-control">
            </label> -->
                </div>

                <div class="col">
                    <label>
                        Vendor Purchase Case Cost
                        <input type="text" formControlName="vendorPurchaseCaseCost" class="form-control">
                    </label>
                    <label class="ms-3">
                        Vendor Purchase Unit Cost
                        <input type="text" formControlName="vendorPurchaseCaseCostUnit" class="form-control">
                    </label>
                </div>

                <div class="col">
                    <label>
                        Clearing $ CS:
                        <input type="text" formControlName="clearingCs" class="form-control">
                    </label>
                    <label class="ms-3">
                        Clearing Cost:
                        <input type="text" formControlName="clearingCost" class="form-control">
                    </label>
                </div>

                <div class="col">
                    <label>
                        Storage $ CS:
                        <input type="text" formControlName="duty" class="form-control">
                    </label>
                    <label class="ms-3">
                        Freight $ CS:
                        <input type="text" formControlName="freightCs" class="form-control">
                    </label>
                </div>

                <div class="col">
                    <label>
                        Royalty %:
                        <input type="text" formControlName="royalty1" class="form-control">
                    </label>
                    <label class="ms-3">
                        Royalty:
                        <input type="text" formControlName="royalty" class="form-control">
                    </label>
                </div>

                <div class="col">
                    <label>
                        FOB Net Vendor Cost:
                        <input type="text" formControlName="fobNetVendorCost" class="form-control">
                    </label>
                    <label class="ms-3">
                        FOB Net Vendor Unit:
                        <input type="text" formControlName="fobNetVendorUnit" class="form-control">
                    </label>
                </div>

                <div class="col-24">
                    <label>
                        Currency Code:
                        <select formControlName="currencyCode" class="form-control">
                            <option value="CAN">CAN</option>
                            <option value="USD">USD</option>
                        </select>
                    </label>
                </div>

                <div class="col">
                    <label>
                        Confirm By:
                        <input type="text" formControlName="confirmedBy" class="form-control">
                    </label>
                    <label class="ms-3">
                        Confirmed Date:
                        <input type="date" formControlName="confirmDate" class="form-control">
                    </label>
                </div>
                <div class="col">
                    <label>
                        FOB Confirmed:
                        <select formControlName="fobConfirmed" class="form-control">
                            <option value="Not Confirmed">Not Confirmed</option>
                            <option value="Confirmed">Confirmed</option>
                        </select>
                    </label>
                </div>

                <div *ngIf="this.spinner=='...Updating'"> <button type='button' class="btn btn-primary mt-3 w-25"
                        disabled>
                        <span class="spinner-border spinner-border-sm"></span>{{ spinner }}</button>
                </div>
                <div *ngIf="this.spinner==''">
                    <button type="submit" class="btn btn-primary mt-3 w-25">Save</button>
                    <button *ngIf="!listing" type="button" class="btn btn-warning ms-3 mt-3 w-25"
                        (click)="validate()">Save to Group</button>
                    <button *ngIf="listing" type="button" class="btn btn-warning ms-3 mt-3 w-25"
                        (click)="validateListing()">Save to Listing Group</button>
                    <br>
                    <button type="button" class="btn btn-secondary mt-3 w-25" (click)="editSetup()">Product
                        Setup</button>
                    <button type="button" class="btn btn-secondary ms-3 mt-3 w-25" (click)="editCaseSetup()">Case
                        Setup</button>
                    <button type="button" class="btn btn-secondary ms-3 mt-3 w-25" (click)="editPalletSetup()">Pallet
                        Setup</button>
                    <br>
                    <button type="button" class="btn btn-secondary mt-3 w-25" (click)="editFreightCostSetup()">Freight
                        Cost Setup</button>
                    <button *ngIf="listing" type="button" class="btn btn-secondary ms-3 mt-3 w-25"
                        (click)="editListingSetup()">Listing Setup</button>
                </div>
            </div>
            <ngb-alert *ngIf="successMessage" [type]="alertType" (close)="successMessage = null">{{ successMessage
                }}</ngb-alert>
        </form>
    </div>
    <div *ngIf="!listing" class="column">
        <h5>Group Products: {{ this.groupName }}</h5>
        <table class="table table-striped">
            <thead>
                <tr>
                    <th scope="col">Product Order Code</th>
                    <th scope="col">Order Type</th>
                    <th scope="col">Brand</th>
                    <th scope="col">Category</th>
                    <th scope="col">Item Description</th>
                    <th scope="col">Size</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let p of linkedProduct">
                    <td>{{ p.orderCode }}</td>
                    <td>{{ p.orderType }}</td>
                    <td>{{ p.brand }}</td>
                    <td>{{ p.category }}</td>
                    <td>{{ p.itemDescription }}</td>
                    <td>{{ p.size }}</td>
                </tr>
            </tbody>
        </table>
    </div>
    <div *ngIf="listing" class="column">
        <h5>Listing Products</h5>
        <table class="table table-striped">
            <thead>
                <tr>
                    <th scope="col">Product Order Code</th>
                    <th scope="col">Order Type</th>
                    <th scope="col">Brand</th>
                    <th scope="col">Category</th>
                    <th scope="col">Item Description</th>
                    <th scope="col">Size</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let p of linkedListing">
                    <td>{{ p.orderCode }}</td>
                    <td>{{ p.orderType }}</td>
                    <td>{{ p.brand }}</td>
                    <td>{{ p.category }}</td>
                    <td>{{ p.itemDescription }}</td>
                    <td>{{ p.size }}</td>
                </tr>
            </tbody>
        </table>
    </div>
</div>