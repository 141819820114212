import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ActiveListings } from 'src/app/models/product.model';
import { ProductService } from 'src/app/services/product.service';
import { ProductListing } from 'src/app/models/product.model';
import { ShipToAddressService } from 'src/app/services/ship-to-address.service';
import { BrokerService } from 'src/app/services/broker.service';
import { Broker } from 'src/app/models/broker.model';
import { ShipToAddress } from 'src/app/models/shipto.model';
import { ExcelService } from 'src/app/services/excel.service';
import { Sorter3Service } from 'src/app/services/sorter3.service';
@Component({
  selector: 'app-exhibit-a',
  templateUrl: './exhibit-a.component.html',
  styleUrls: ['./exhibit-a.component.scss']
})
export class ExhibitAComponent implements OnInit {
  // productsSelected: number;
  products = [];
  productsReset = [];
  productsSelected = [];
  productsOriginal = [];
  vendorSelected: number;
  vendors = [];
  vendorOptions = [];
  activeListingsSelected: string;
  activeListingsOptions = [];
  broker1Selected: number;
  brokers: Broker[];
  brokerOptions: Broker[];
  broker1 = [];
  broker1Options = [];
  broker2Selected: number;
  broker2 = [];
  broker2Options = [];
  customerSelected: number;
  customers = [];
  customerOptions = [];
  shipToSelected: number;
  shipTo: ShipToAddress[];
  shipToOptions: ShipToAddress[];
  page = 1;
  pageSize = 50;
  collectionSize = 0;
  activeList = '';
  indexItem = -1;
  indexProd = -1;
  filterRun = false;
  constructor(private route: ActivatedRoute,
    private router: Router,
    private productService: ProductService,
    private shipToService: ShipToAddressService,
    private brokerService: BrokerService,
    private excelService: ExcelService,
    private sort3Service: Sorter3Service
  ) {}
  
  ngOnInit(): void {
    this.vendorSelected = 0;
    this.vendorOptions = [];
    this.customerSelected = 0;
    this.customerOptions = [];
    this.shipToSelected = 0;
    this.shipToOptions = [];
    this.broker1Selected = 0;
    this.broker1Options = [];
    this.broker2Selected = 0;
    this.broker2Options = [];
    this.products = [];
    this.productsSelected = [];
    this.shipToService.getAll().subscribe(ship => {
      this.shipTo = ship;
      this.brokerService.getAll().subscribe(broker => {
        this.brokers = broker;
        this.productService.getActiveListings().subscribe(prod => {
          this.productsOriginal = prod;
        })
      })
      this.resetFilters();
    })
    // this.resetFilters();
  }
  getDefaultDataScreen() {
    this.vendorSelected = 0;
    this.vendorOptions = [];
    this.customerSelected = 0;
    this.customerOptions = [];
    this.shipToSelected = 0;
    this.shipToOptions = [];
    this.broker1Selected = 0;
    this.broker1Options = [];
    this.broker2Selected = 0;
    this.broker2Options = [];
    this.page = 1;
    this.filterRun = false;
    this.resetFilters();
  } 
  loadPage($event){
    this.resetFilters();
  }
  resetFilters() {
    this.products = [];
    this.productsSelected = [];
    this.vendorOptions = [];
    this.customerOptions = [];
    this.shipToOptions = [];
    this.broker1Options = [];
    this.broker2Options = [];
    this.productService.getExhibitA(this.vendorSelected, this.broker1Selected,
      this.broker2Selected, this.customerSelected, this.shipToSelected, this.page,
      this.pageSize).subscribe(data => {
        //filter products 
        data.item.forEach(b => {
          b.items.forEach((e, index) => {
            let found = true;
            //filter products by broker1 if selected
            if (this.broker1Selected) {
              found = false;
              if (e.brokerId == this.broker1Selected) {
                found = true;
              }
            }
            //filter products by broker2 if selected
            let found2 = true;
            if (this.broker2Selected) {
              found2 = false;
              if (e.broker2Id == this.broker2Selected) {
                found2 = true;
              }
            }
            //always push products
            if (found && found2) {
              this.products.push(b);
              this.products = this.sort3Service.sorter(this.products, 'vendor', 'brand', 'customer');
            }
          });
        });
        this.vendorOptions = data.item2;
        this.customerOptions = data.item4;
        this.collectionSize = data.count;
        data.item5.forEach(e => {
          if (e !== null) {
            const ret = e.split(',');
            ret.forEach(element => {
              this.buildShipOptions(element);
            });
          }
        });
        this.shipToOptions.sort((a, b) => a.company < b.company ? -1 : a.company > b.company ? 1 : 0);
        data.item.forEach(broker => {
          broker.broker = '';
          broker.broker2 = '';
          broker.items.forEach(b => {
            if (b !== null) {
              const name1 = this.getBrokerNames(b.brokerId);
              const name2 = this.getBrokerNames(b.broker2Id);
              if (broker.broker.indexOf(name1) == -1) {
                this.buildBrokerOptions1(b.brokerId, name1);
              }
              if (broker.broker.indexOf(name2) == -1) {
                this.buildBrokerOptions2(b.broker2Id, name2);
              }
            }
          })
        })
        this.broker1Options.sort((a, b) => a.company < b.company ? -1 : a.company > b.company ? 1 : 0);
        this.broker2Options.sort((a, b) => a.company < b.company ? -1 : a.company > b.company ? 1 : 0);
      })
  }
  buildBrokerOptions1(brokerId, company) {
    if (brokerId === null) {
      return;
    }
    const id = +brokerId;
    if (id) {
      const idx = this.broker1Options.findIndex(broke => broke.id === id);
      if (idx == -1) {
        let broker = new Broker();
        broker.id = brokerId;
        broker.company = company;
        this.broker1Options.push(broker);
      }
    }
  }
  buildBrokerOptions2(brokerId, company) {
    if (brokerId === null) {
      return;
    }
    const id = +brokerId;
    if (id) {
      const idx = this.broker2Options.findIndex(broke => broke.id === id);
      if (idx == -1) {
        let broker = new Broker();
        broker.id = brokerId;
        broker.company = company;
        this.broker2Options.push(broker);
      }
    }
  }
  buildShipOptions(e) {
    if (e === null) {
      return;
    }
    const id = +e;
    if (id) {
      const idx = this.shipToOptions.findIndex(ship => ship.id === id);
      if (idx > -1) {
        return;
      }
      const index = this.shipTo.findIndex(item => item.id === id);
      if (index >  -1) {
        let ship = new ShipToAddress();
        ship.id = this.shipTo[index].id;
        ship.company = this.shipTo[index].company;
        this.shipToOptions.push(ship);
      }
    }
  }
  getShipTo(id) {
    let ret = '';
    let shipToCount = 0;
    if (id) {
      const ids = id.split(',');
      ids.forEach(ship => {
        const firstId = +ship;
        const index = this.shipTo.findIndex(item => item.id === firstId);
        if (index >  -1) {
          shipToCount++;
          ret += ' (' + shipToCount + ')' + this.shipTo[index].company;
        }
        
      });
    }

    return ret;
  }
  getCommission1(row) {
    let ret = '';
    let commCount = 0;
    for (let i = 0; i < this.products[row].items.length; i ++) {
    if (this.products[row].items[i].commission1 > 0) {
      commCount++;
      ret += ' (' + commCount + ')' + this.products[row].items[i].commission1.toString() + '%';
    }
  }
    return ret;
  }
  getCommission2(row) {
    let ret = '';
    let commCount = 0;
    for (let i = 0; i < this.products[row].items.length; i ++) {
    if (this.products[row].items[i].commission2 > 0) {
      commCount++;
      ret += ' (' + commCount + ')' + this.products[row].items[i].commission2.toString() + '%';
    }
  }
    return ret;
  }
  getBroker1(row) {
    let ret = '';
    let brokerCount = 0;
    let brokerId = 0;
    for (let i = 0; i < this.products[row].items.length; i ++) {
      if (this.products[row].items[i].brokerId > 0) {
        // brokerCount++;
         brokerId = this.products[row].items[i].brokerId;
      }
      const index = this.brokers.findIndex(item => item.id === brokerId);
      if (index >  -1) {
        brokerCount++;
        ret = this.brokers[index].company;
      }
    }
    return ret;
  }
  getBroker2(row) {
    let ret = '';
    let brokerCount = 0;
    let brokerId = 0;
    for (let i = 0; i < this.products[row].items.length; i ++) {
      if (this.products[row].items[i].broker2Id > 0) {
        // brokerCount++;
         brokerId = this.products[row].items[i].broker2Id;
      }
      const index = this.brokers.findIndex(item => item.id === brokerId);
      if (index >  -1) {
        brokerCount++;
        ret = this.brokers[index].company;
      }
    }
    return ret;
  }

  getBrokerNames(id) {
    id = id === null ? 0 : id * 1;
    let name = '';
    if (id > -1) {
        const index = this.brokers.findIndex(item => item.id == id);
        if (index > -1) {
          name = this.brokers[index].company.trim();
        }
    } 
    return name;
  }
   refreshProducts() {
    this.products = [];
    this.productsSelected = [];
    this.productService.getExhibitA(this.vendorSelected, this.broker1Selected, 
      this.broker2Selected, this.customerSelected, this.shipToSelected, this.page, 
      999).subscribe(data => {
        //filter products 
        data.item.forEach(b => {
          b.items.forEach((e, index) => {
            let found = true;
            //filter products by broker1 if selected
            if (this.broker1Selected) {
              found = false;
              if (e.brokerId == this.broker1Selected) {
                found = true;
              }
            }
            //filter products by broker2 if selected
            let found2 = true;
            if (this.broker2Selected) {
              found2 = false;
              if (e.broker2Id == this.broker2Selected) {
                found2 = true;
              }
            }
            //always push products
            if (found && found2) {
              this.products.push(b);
            }
          });
          });
        this.filterProducts();
      })
      
  }
  filterProducts() {
    this.products.forEach(e => {
      for (let indexItem = 0; indexItem < e.items.length; indexItem++) {
        let itemIndex = -1;
        // const index = this.productsSelected.findIndex(f => f.vendorId == e.vendorId && f.customerId == e.customerId);
        //filtering with brandId
        const index = this.productsSelected.findIndex(f => f.vendorId == e.vendorId && f.customerId == e.customerId && f.brandId == e.brandId);
        if (index > -1) {
          itemIndex = this.productsSelected[index].items.findIndex(f => f.brokerId == e.items[indexItem].brokerId || f.broker2Id == e.items[indexItem].broker2Id);
        }
        if (itemIndex == -1) {
          this.productsSelected.push(e);
        }
      }
    })
    this.productsSelected = this.sort3Service.sorter(this.productsSelected, 'vendor', 'brand', 'customer');
    this.exportToExcel();
  }
  exportToExcel(){
    const excelData = [];
    let indexProd = 0;
      this.productsSelected.forEach(e => {
          const temp = {
            vendor: e.vendor,
            brand: e.brand,
            customer: e.customer,
            shipTo: this.getShipTo(e.shipToIds),
            commissionone: this.getCommission1(indexProd),
            commissiontwo: this.getCommission2(indexProd),
            brokerone: this.getBroker1(indexProd),
            brokertwo: this.getBroker2(indexProd)
          }
          excelData.push(temp);
          indexProd++;
      })
    this.excelService.generateExcel(excelData, 'Exhibit A');    
  }
}
