<div class="navbar navbar-expand-md top-navigation p-0">
    <div class="collapse navbar-collapse" id="navbarNav1">
        <div class="navbar-nav ms-auto">
            <button type="button" class="btn btn-link" (click)="turnLoginOn()">Login</button>            
            <button type="button" *ngIf="administrationRole && !loginDisplay" class="btn btn-link" (click)="login()">[SP Login]</button>
            <button type="button" *ngIf="administrationRole && loginDisplay" class="btn btn-link" (click)="logout()">[SP Logout]</button>
        </div>
    </div>
  </div>

              <ng-container>
                <!-- <mat-toolbar class="example-header navbar navbar-expand-md p-0">
                </mat-toolbar> -->
              
                <mat-sidenav-container class="mat-drawer-content">
                  <mat-sidenav #sidenav class="mat-elevation-z8 list" opened mode="side" [style.width]="'250px'">

                    <div class="sidenav-header">
 
                        <img width="100" height="100" src="/assets/images/KalcorLTDBevelEffect2.png">

                        <div class="header-text">
                           <h3></h3> 
                        </div>
                    </div>
                    <td></td>

                    <mat-accordion (panelChange)="beforeChange($event)">
                        <mat-expansion-panel class="list"  hideToggle>
                            <mat-expansion-panel-header class="card-body" >
                              <mat-panel-title [routerLink]="['dashboard']">
                                  <mat-icon class="text-white">dashboard</mat-icon>
                                  <span class="text-white ps-2">Dashboard</span>
                              </mat-panel-title>
                            </mat-expansion-panel-header>                   
                          </mat-expansion-panel>

                        <mat-expansion-panel class="list"  hideToggle *ngIf="sales || administrationRole"  title="SALES">
                          <mat-expansion-panel-header class="card-body" >
                            <mat-panel-title>
                                <mat-icon class="text-white">shopping_cart</mat-icon>
                                <span class="text-white ps-2">Sales</span>
                            </mat-panel-title>

                          </mat-expansion-panel-header>
                          
                          <a class="text-white ps-3 panel-body" [routerLink]="['/sales/order-list']">Sales Orders</a>
                          <a class="text-white float-end panel-body" [routerLink]="['/sales/order-edit/0']">+</a><br>
                            
                        </mat-expansion-panel>
                        
                        <mat-expansion-panel class="list" hideToggle *ngIf="customClearing || administrationRole" title="CUSTOMS CLEARING">
                          <mat-expansion-panel-header class="card-body">
                            <mat-panel-title>
                                <mat-icon class="text-white">swap_vert</mat-icon>
                                <span class="text-white ps-2">Customs Clearing</span>
                            </mat-panel-title>
                          </mat-expansion-panel-header>
                          <a class="text-white ps-3 panel-body" [routerLink]="['dashboard']">Pars Notification</a>
                          <a class="text-white float-end panel-body" [routerLink]="['dashboard']">+</a><br>
                          <a class="text-white ps-3 panel-body" [routerLink]="['dashboard']">Canada Custom Invoice</a>
                          <a class="text-white float-end panel-body" [routerLink]="['dashboard']">+</a><br>
                          <a class="text-white ps-3 panel-body" [routerLink]="['dashboard']">Intent to Import</a>
                          <a class="text-white float-end panel-body" [routerLink]="['dashboard']">+</a><br>
                        </mat-expansion-panel>

                        <mat-expansion-panel class="list" hideToggle  *ngIf="accounting || administrationRole" title="ACCOUNTING">
                            <mat-expansion-panel-header class="card-body">
                            <mat-panel-title>
                                <mat-icon class="text-white">attach_money</mat-icon>
                                <span class="text-white ps-2">Accounting</span>
                            </mat-panel-title>
                            </mat-expansion-panel-header>
                            <a class="text-white ps-3 panel-body" [routerLink]="['/accounts/credit-list']">Credit</a>
                            <a class="text-white float-end panel-body" [routerLink]="['/accounts/credit-edit']">+</a><br>
                            <a class="text-white ps-3 panel-body" [routerLink]="['/accounts/transfer-list']">Transfer</a>
                            <a class="text-white float-end panel-body" [routerLink]="['/accounts/transfer-edit/0']">+</a><br>
                            <a class="text-white ps-3 panel-body" [routerLink]="['/accounts/profit-lost-list']">Profit & Loss</a><br>
                            <a class="text-white ps-3 panel-body" [routerLink]="['/accounts/broker-pay-list']">Payment</a>
                            <a class="text-white float-end panel-body" [routerLink]="['/accounts/broker-pay-edit/0']">+</a><br>
                        </mat-expansion-panel>

                        <mat-expansion-panel class="list" hideToggle title="CUSTOMERS" *ngIf="customer || administrationRole">
                            <mat-expansion-panel-header class="card-body">
                            <mat-panel-title>
                                <mat-icon class="text-white">group</mat-icon>
                                <span class="text-white ps-2" >Customers</span>
                            </mat-panel-title>
                            </mat-expansion-panel-header>
                            <a class="text-white ps-3 panel-body" [routerLink]="['customers']">Customers</a>
                            <a class="text-white float-end panel-body" [routerLink]="['/customers/customer-create']">+</a><br>
                            <a class="text-white ps-3 panel-body" [routerLink]="['/customers/ship-to-address-list']">Ship To Address</a>
                            <a class="text-white float-end panel-body" [routerLink]="['/customers/ship-to-address-create']">+</a><br>
                            <a class="text-white ps-3 panel-body" [routerLink]="['/customers/customer-end-list']">Customer Head Office</a>
                            <a class="text-white float-end panel-body" [routerLink]="['/customers/customer-end-edit/0']">+</a><br>
                            <a class="text-white ps-3 panel-body" [routerLink]="['/customers/retail-list']">Banners</a>
                            <a class="text-white float-end panel-body" [routerLink]="['/customers/retail-edit/0']">+</a><br>
                        </mat-expansion-panel>

                        <mat-expansion-panel class="list" hideToggle title="VENDORS" *ngIf="vendor || administrationRole">
                            <mat-expansion-panel-header>
                            <mat-panel-title>
                                <mat-icon class="text-white">store</mat-icon>
                                <span class="text-white ps-2">Vendors</span>
                            </mat-panel-title>
                            </mat-expansion-panel-header>
                            <a class="text-white ps-3 panel-body" [routerLink]="['/vendors/vendor-list']">Vendors</a>
                            <a class="text-white float-end panel-body" [routerLink]="['/vendors/vendor-edit/0']">+</a><br>
                            <a class="text-white ps-3 panel-body" [routerLink]="['/vendors/pickup-location-list']">Pickup Locations</a>
                            <a class="text-white float-end panel-body" [routerLink]="['/vendors/pickup-location-edit/0']">+</a><br>
                        </mat-expansion-panel>

                        <mat-expansion-panel class="list" hideToggle title="OTHER" *ngIf="other || administrationRole">
                            <mat-expansion-panel-header>
                            <mat-panel-title>
                                <mat-icon class="text-white">all_inclusive</mat-icon>
                                <span class="text-white ps-2">Other</span>
                            </mat-panel-title>
                            </mat-expansion-panel-header>
                            <a class="text-white ps-3 panel-body" [routerLink]="['/other/billing-agent-list']">Billing Agents</a>
                            <a class="text-white float-end panel-body" [routerLink]="['/other/billing-agent-edit/0']">+</a><br>
                            <a class="text-white ps-3 panel-body" [routerLink]="['/other/broker-list']">Brokers</a>
                            <a class="text-white float-end panel-body" [routerLink]="['/other/broker-edit/0']">+</a><br>
                            <a class="text-white ps-3 panel-body" [routerLink]="['/other/sales-manager-list']">Sales Managers</a>
                            <a class="text-white float-end panel-body" [routerLink]="['/other/sales-manager-edit/0']">+</a><br>
                            <a class="text-white ps-3 panel-body" [routerLink]="['/other/transportation-list']">Transportation</a>
                            <a class="text-white float-end panel-body" [routerLink]="['/other/transportation-edit/0']">+</a><br>
                            <a class="text-white ps-3 panel-body" [routerLink]="['/other/clearing-co-list']">Clearing Companies</a>
                            <a class="text-white float-end panel-body" [routerLink]="['/other/clearing-co-edit/0']">+</a><br>
                            <a class="text-white ps-3 panel-body" [routerLink]="['/other/credit-reasons-list']">Credit Reasons</a>
                            <a class="text-white float-end panel-body" [routerLink]="['/other/credit-reasons-edit/0']">+</a><br>
                            <a class="text-white ps-3 panel-body" [routerLink]="['/other/warehouse-list']">Warehouse</a>
                            <a class="text-white float-end panel-body" [routerLink]="['/other/warehouse-edit/0']">+</a><br>
                            <a class="text-white ps-3 panel-body" [routerLink]="['/other/gst-hst-list']">Gst/Hst</a>
                            <a class="text-white float-end panel-body" [routerLink]="['/other/gst-hst-edit/0']">+</a><br>
                            <a class="text-white ps-3 panel-body" [routerLink]="['/other/terms-list']">Terms</a>
                            <a class="text-white float-end panel-body" [routerLink]="['/other/terms-edit/0']">+</a><br>
                            <a class="text-white ps-3 panel-body" [routerLink]="['/other/location-list']">Locations</a>
                            <a class="text-white float-end panel-body" [routerLink]="['/other/location-edit/0']">+</a><br>
                            <a class="text-white ps-3 panel-body" [routerLink]="['/other/border-crossing-list']">Border Crossing</a>
                            <a class="text-white float-end panel-body" [routerLink]="['/other/border-crossing-edit/0']">+</a><br>
                            <a class="text-white ps-3 panel-body" [routerLink]="['/other/pallet-type-list']">Type of Pallet</a>
                            <a class="text-white float-end panel-body" [routerLink]="['/other/pallet-type-edit/0']">+</a><br>
                            <a class="text-white ps-3 panel-body" [routerLink]="['/other/exchange-rate-list']">Exchange</a>
                            <a class="text-white float-end panel-body" [routerLink]="['/other/exchange-rate-edit/0']">+</a><br>
                            <a class="text-white ps-3 panel-body" [routerLink]="['/other/region-list']">Region</a>
                            <a class="text-white float-end panel-body" [routerLink]="['/other/region-edit/0']">+</a><br>
                            <a class="text-white ps-3 panel-body" [routerLink]="['/other/type-of-account-list']">Type of Account</a>
                            <a class="text-white float-end panel-body" [routerLink]="['/other/type-of-account-edit/0']">+</a><br>
                            <a class="text-white ps-3 panel-body" [routerLink]="['/other/week-customer-list']">Weeks - Customer</a>
                            <a class="text-white float-end panel-body" [routerLink]="['/other/week-customer-new']">+</a><br>
                            <a class="text-white ps-3 panel-body" [routerLink]="['/other/week-customer-end-list']">Weeks - Customer Head Office</a>
                            <a class="text-white float-end panel-body" [routerLink]="['/other/week-customer-end-new']">+</a><br>
                            <a class="text-white ps-3 panel-body" [routerLink]="['/other/week-kalcor-list']">Weeks - Kalcor</a>
                            <a class="text-white float-end panel-body" [routerLink]="['/other/week-kalcor-edit/0']">+</a><br>
                        </mat-expansion-panel>

                        <mat-expansion-panel class="list" hideToggle title="ITEMS" *ngIf="items || administrationRole">
                            <mat-expansion-panel-header>
                            <mat-panel-title>
                                <mat-icon class="text-white">info</mat-icon>
                                <span class="text-white ps-2">Items</span>
                            </mat-panel-title>
                            </mat-expansion-panel-header>
                            <a class="text-white ps-3 panel-body" [routerLink]="['items/brand-list']">Brands</a>
                            <a class="text-white float-end panel-body" [routerLink]="['/items/brand-details/0']">+</a><br>
                            <a class="text-white ps-3 panel-body" [routerLink]="['items/category-list']">Category</a>
                            <a class="text-white float-end panel-body" [routerLink]="['/items/category-edit/0']">+</a><br>
                            <a class="text-white ps-3 panel-body" [routerLink]="['items/sub-category-list']">Sub-Category</a>
                            <a class="text-white float-end panel-body" [routerLink]="['/items/sub-category-edit/0']">+</a><br>
                            <a class="text-white ps-3 panel-body" [routerLink]="['items/upc-gtn-list']">UPC/GTIN</a>
                            <a class="text-white float-end panel-body" [routerLink]="['/items/upc-gtn-edit/0']">+</a><br>
                        </mat-expansion-panel>

                        <mat-expansion-panel class="list" hideToggle title="PRODUCTS" *ngIf="products || administrationRole">
                            <mat-expansion-panel-header>
                            <mat-panel-title>
                                <mat-icon class="text-white">shop</mat-icon>
                                <span class="text-white ps-2">Products</span>
                            </mat-panel-title>
                            </mat-expansion-panel-header>
                            <a class="text-white ps-3 panel-body" [routerLink]="['products/setup-list']">Products</a>
                            <a class="text-white float-end panel-body" [routerLink]="['/products/setup-edit/0']">+</a><br>
                            <a class="text-white ps-3 panel-body" [routerLink]="['products/group-list']">Product Groups</a>
                            <a class="text-white float-end panel-body" [routerLink]="['/products/group-edit/0']">+</a><br>
                            <a class="text-white ps-3 panel-body" [routerLink]="['products/cases-setup-list']">Case Review</a><br>
                            <!-- <a class="text-white float-end panel-body" [routerLink]="['products/cases-setup-edit/0']">+</a><br> -->
                            <a class="text-white ps-3 panel-body" [routerLink]="['products/costing-bucket-setup-list']">Costing Review</a><br>
                            <!-- <a class="text-white float-end panel-body" [routerLink]="['products/costing-bucket-setup-edit/0']">+</a><br> -->
                            <a class="text-white ps-3 panel-body" [routerLink]="['products/freight-cost-setup-list']">Freight Review</a><br>
                            <!-- <a class="text-white float-end panel-body" [routerLink]="['products/freight-cost-setup-edit/0']">+</a><br> -->
                            <a class="text-white ps-3 panel-body" [routerLink]="['products/pallet-setup-list']">Pallet Review</a><br>
                            <!-- <a class="text-white float-end panel-body" [routerLink]="['products/pallet-setup-edit/0']">+</a><br> -->
                        </mat-expansion-panel>

                        <mat-expansion-panel class="list" hideToggle title="PRODUCT LISTING" *ngIf="productListing || administrationRole">
                            <mat-expansion-panel-header>
                            <mat-panel-title>
                                <mat-icon class="text-white">shop_2</mat-icon>
                                <span class="text-white ps-2">Product Listing</span>
                            </mat-panel-title>
                            </mat-expansion-panel-header>
                            <a class="text-white ps-3 panel-body" [routerLink]="['productListings/listing-setup-list']">Listing List</a>
                            <a class="text-white float-end panel-body" *ngIf="!vendorRole" [routerLink]="['productListings/listing-setup-new']">+</a><br>
                            <a class="text-white ps-3 panel-body" [routerLink]="['productListings/listing-list-case']">Case Review</a><br>
                            <a class="text-white ps-3 panel-body" [routerLink]="['productListings/listing-list-costing']">Costing Review</a><br>
                            <a class="text-white ps-3 panel-body" [routerLink]="['productListings/listing-list-freight']">Freight Review</a><br>
                            <a class="text-white ps-3 panel-body" [routerLink]="['productListings/listing-list-pallet']">Pallet Review</a><br>
                            <!-- below to be created after running tests on product groups 10.7.24 -->
                            <!-- <a class="text-white ps-3 panel-body" [routerLink]="['productListings/listing-group-list']">Listing Group List</a>
                            <a class="text-white float-end panel-body" *ngIf="!vendorRole" [routerLink]="['productListings/listing-group-new']">+</a><br> -->
                        </mat-expansion-panel>

                        <mat-expansion-panel class="list" hideToggle title="PROJECTIONS" *ngIf="projections || administrationRole">
                            <mat-expansion-panel-header>
                            <mat-panel-title>
                                <mat-icon class="text-white">timeline</mat-icon>
                                <span class="text-white ps-2">Projections</span>
                            </mat-panel-title>
                            </mat-expansion-panel-header>
                            <a class="text-white ps-3 panel-body" [routerLink]="['projections/projections-list']">Projections List</a>
                            <a class="text-white ps-3 float-end panel-body" [routerLink]="['projections/projections-new']">+</a><br>
                            <!-- <a class="text-white ps-3 panel-body" [routerLink]="['projections/projections-new']">Setup Projection</a><br> -->
                            <a class="text-white ps-3 panel-body" [routerLink]="['projections/review-week']">Review Week</a><br>
                        </mat-expansion-panel>

                        <mat-expansion-panel class="list" hideToggle title="REPORTS" *ngIf="reports || administrationRole">
                            <mat-expansion-panel-header>
                            <mat-panel-title>
                                <mat-icon class="text-white">print</mat-icon>
                                <span class="text-white ps-2">Reports</span>
                            </mat-panel-title>
                            </mat-expansion-panel-header>
                            <a class="text-white ps-3 panel-body" [routerLink]="['portal/portal']">Report Portal</a><br>
                            <a class="text-white ps-3 panel-body" [routerLink]="['portal/period-report']">Period Report</a><br>
                            <a class="text-white ps-3 panel-body" [routerLink]="['portal/exhibit-a']">Broker Exhibit-A</a><br>
                            <a class="text-white ps-3 panel-body" [routerLink]="['portal/lot-bb']">Lot BB Report</a><br>
                            <a class="text-white ps-3 panel-body" [routerLink]="['portal/share-link']">Sharepoint Data Link</a><br>
                        </mat-expansion-panel>

                        <mat-expansion-panel class="list" hideToggle title="AZURE" *ngIf="reports || administrationRole">
                            <mat-expansion-panel-header>
                            <mat-panel-title>
                                <mat-icon class="text-white">cloud_upload</mat-icon>
                                <span class="text-white ps-2">Azure</span>
                            </mat-panel-title>
                            </mat-expansion-panel-header>
                            <a class="text-white ps-3 panel-body" [routerLink]="['portal/all-link']">All</a><br>
                            <a class="text-white ps-3 panel-body" [routerLink]="['portal/azure-link']">Sales & Budget</a><br>
                            <a class="text-white ps-3 panel-body" [routerLink]="['portal/marketing-link']">Marketing</a><br>
                            <a class="text-white ps-3 panel-body" [routerLink]="['portal/commission-link']">Commission</a><br>
                            <a class="text-white ps-3 panel-body" [routerLink]="['portal/kalcor-link']">Kalcor</a><br>
                            <a class="text-white ps-3 panel-body" [routerLink]="['portal/exchange-link']">Exchange</a><br>
                            <a class="text-white ps-3 panel-body" [routerLink]="['portal/freight-cnd-link']">Freight CND</a><br>
                            <a class="text-white ps-3 panel-body" [routerLink]="['portal/vendor-report-link']">Vendor</a><br>
                        </mat-expansion-panel>

                        <mat-expansion-panel class="list" hideToggle title="ADMINISTRATION" *ngIf="administrationRole">
                            <mat-expansion-panel-header>
                            <mat-panel-title>
                                <mat-icon class="text-white">settings</mat-icon>
                                <span class="text-white ps-2">Administration</span>
                            </mat-panel-title>
                            </mat-expansion-panel-header>
                            <a class="text-white ps-3 panel-body" [routerLink]="['admin/user-list']">Users</a>
                            <a class="text-white float-end panel-body" [routerLink]="['admin/user-edit/0']">+</a><br>
                            <a class="text-white ps-3 panel-body" [routerLink]="['admin/roles-list']">Permissions/Roles</a>
                            <a class="text-white float-end panel-body" [routerLink]="['admin/roles-edit/0']">+</a><br>
                            <a class="text-white ps-3 panel-body" [routerLink]="['dashboard']">Orders@kalcor.ca</a>
                        </mat-expansion-panel>

                      </mat-accordion>
                  </mat-sidenav>

                  <mat-sidenav-content>
                        <div [ngClass]="'ps-3 mt-3'">
                        <router-outlet name="login">
                            <app-login *ngIf="loginPressed"
                            [(sales)] = "sales"
                            [(dashboard)] = "dashboard"
                            [(customClearing)] = "customClearing"
                            [(accounting)] = "accounting"
                            [(customer)] = "customer"
                            [(vendor)] = "vendor"
                            [(other)] = "other"
                            [(items)] = "items"
                            [(products)] = "products"
                            [(productListing)] = "productListing"
                            [(projections)] = "projections"
                            [(reports)] = "reports"
                            [(portal)] = "portal"
                            [(customerId)] = "customerId"
                            [(vendorId)] = "vendorId"
                            [(brokerId)] = "brokerId"
                            [(administrationRole)] = "administrationRole"
                            [(trafficRole)] = "trafficRole"
                            [(accountingRole)] = "accountingRole"
                            [(marketingRole)] = "marketingRole"
                            [(vendorRole)] = "vendorRole"
                            [(projectionsRole)] = "projectionsRole"
                            (logged)="onlogged($event)"> </app-login>
                        </router-outlet> 
                        <router-outlet  *ngIf="!isIframe">
                        </router-outlet>
                    </div>
                    
                  </mat-sidenav-content>
                  
                </mat-sidenav-container>
              
                <!-- <mat-toolbar class="example-footer">Footer</mat-toolbar> -->
              </ng-container>
              

    
          <!-- </div> -->
          <!-- Original navigation bar changed with bootstrap upgrade to angular material side nav/expand panel 10/25/23 -->
          <!-- <div class="navbar navbar-expand-md top-navigation p-0"> -->
            <!-- <img class="logo ps-3" src="/assets/images/Kalcor Menu.png" height="100%" width="158px"> -->
            <!-- <div class="collapse navbar-collapse" id="navbarNav1"> -->
                <!-- <div class="navbar-nav">
                </div> -->
                <!-- <div class="navbar-nav ms-auto">
                    <button type="button" class="btn btn-link" (click)="turnLoginOn()">Login</button>            
                    <button type="button" *ngIf="administrationRole && !loginDisplay" class="btn btn-link" (click)="login()">[SP Login]</button>
                    <button type="button" *ngIf="administrationRole && loginDisplay" class="btn btn-link" (click)="logout()">[SP Logout]</button>
                </div>
            </div>
          </div> -->
        
          <!-- <div class="container-fluid h-100" style="overflow-x:scroll;">
              <div class="row h-100">
                  <div id="navbarNav" class="col-md-3 ps-0 pe-0 side-navigation"> -->
          <!-- <div id="navbarNav" class="col-md-3 pl-0 pr-0 side-navigation" [ngbCollapse]="!isExpanded">
            <ngb-accordion #acc="ngbAccordion" activeIds="ngb-panel-0" (panelChange)="beforeChange($event)">
                <ngb-panel>
                  <ng-template ngbPanelHeader>
                      <a id="menu-dashboard" class="text-white text-bold" [routerLink]="['dashboard']">DASHBOARD</a>
                  </ng-template>                      
                </ngb-panel>
                <ngb-panel *ngIf="sales || administrationRole"  title="SALES">
                  <ng-template ngbPanelContent class="py-0 my-card-body ml-5">
                      <a class="text-white pl-3" [routerLink]="['/sales/order-list']">Sales Orders</a>
                      <a class="text-white float-right" [routerLink]="['/sales/order-edit/0']">+</a><br>
                  </ng-template>
              </ngb-panel>
                <ngb-panel *ngIf="customClearing || administrationRole" title="CUSTOMS CLEARING">
                    <ng-template ngbPanelContent class="py-0 my-card-body ml-5">
                        <a class="text-white pl-3" [routerLink]="['dashboard']">Pars Notification</a>
                        <a class="text-white float-right" [routerLink]="['dashboard']">+</a><br>
                        <a class="text-white pl-3" [routerLink]="['dashboard']">Canada Custom Invoice</a>
                        <a class="text-white float-right" [routerLink]="['dashboard']">+</a><br>
                        <a class="text-white pl-3" [routerLink]="['dashboard']">Intent to Import</a>
                        <a class="text-white float-right" [routerLink]="['dashboard']">+</a><br>
                    </ng-template>
                </ngb-panel>
                <ngb-panel *ngIf="accounting || administrationRole" title="ACCOUNTING">
                    <ng-template ngbPanelContent class="py-0 my-card-body ml-5">
                        <a class="text-white pl-3" [routerLink]="['/accounts/credit-list']">Credit</a>
                        <a class="text-white float-right" [routerLink]="['/accounts/credit-edit']">+</a><br>
                        <a class="text-white pl-3" [routerLink]="['/accounts/transfer-list']">Transfer</a>
                        <a class="text-white float-right" [routerLink]="['/accounts/transfer-edit/0']">+</a><br>
                        <a class="text-white pl-3" [routerLink]="['/accounts/profit-lost-list']">Profit & Loss</a><br>
                        <a class="text-white pl-3" [routerLink]="['/accounts/broker-pay-list']">Payment</a>
                        <a class="text-white float-right" [routerLink]="['/accounts/broker-pay-edit/0']">+</a><br>
                    </ng-template>
                </ngb-panel>
                <ngb-panel title="CUSTOMERS" *ngIf="customer || administrationRole">
                    <ng-template ngbPanelContent class="py-0 my-card-body ml-5">
                        <a class="text-white pl-3" [routerLink]="['customers']">Customers</a>
                        <a class="text-white float-right" [routerLink]="['/customers/customer-create']">+</a><br>
                        <a class="text-white pl-3" [routerLink]="['/customers/ship-to-address-list']">Ship To Address</a>
                        <a class="text-white float-right" [routerLink]="['/customers/ship-to-address-create']">+</a><br>
                        <a class="text-white pl-3" [routerLink]="['/customers/customer-end-list']">Customer Head Office</a>
                        <a class="text-white float-right" [routerLink]="['/customers/customer-end-edit/0']">+</a><br>
                        <a class="text-white pl-3" [routerLink]="['/customers/retail-list']">Banners</a>
                        <a class="text-white float-right" [routerLink]="['/customers/retail-edit/0']">+</a><br>
                    </ng-template>
                </ngb-panel>
                <ngb-panel title="VENDORS" *ngIf="vendor || administrationRole">
                    <ng-template ngbPanelContent class="py-0 my-card-body ml-5">
                        <a class="text-white pl-3" [routerLink]="['/vendors/vendor-list']">Vendors</a>
                        <a class="text-white float-right" [routerLink]="['/vendors/vendor-edit/0']">+</a><br>
                        <a class="text-white pl-3" [routerLink]="['/vendors/pickup-location-list']">Pickup Locations</a>
                        <a class="text-white float-right" [routerLink]="['/vendors/pickup-location-edit/0']">+</a><br>
                    </ng-template>
                </ngb-panel>
                <ngb-panel title="OTHER" *ngIf="other || administrationRole">
                    <ng-template ngbPanelContent class="py-0 my-card-body ml-5">
                        <a class="text-white pl-3" [routerLink]="['/other/broker-list']">Brokers</a>
                        <a class="text-white float-right" [routerLink]="['/other/broker-edit/0']">+</a><br>
                        <a class="text-white pl-3" [routerLink]="['/other/sales-manager-list']">Sales Managers</a>
                        <a class="text-white float-right" [routerLink]="['/other/sales-manager-edit/0']">+</a><br>
                        <a class="text-white pl-3" [routerLink]="['/other/transportation-list']">Transportation</a>
                        <a class="text-white float-right" [routerLink]="['/other/transportation-edit/0']">+</a><br>
                        <a class="text-white pl-3" [routerLink]="['/other/clearing-co-list']">Clearing Companies</a>
                        <a class="text-white float-right" [routerLink]="['/other/clearing-co-edit/0']">+</a><br>
                        <a class="text-white pl-3" [routerLink]="['/other/warehouse-list']">Warehouse</a>
                        <a class="text-white float-right" [routerLink]="['/other/warehouse-edit/0']">+</a><br>
                        <a class="text-white pl-3" [routerLink]="['/other/terms-list']">Terms</a>
                        <a class="text-white float-right" [routerLink]="['/other/terms-edit/0']">+</a><br>
                        <a class="text-white pl-3" [routerLink]="['/other/location-list']">Locations</a>
                        <a class="text-white float-right" [routerLink]="['/other/location-edit/0']">+</a><br>
                        <a class="text-white pl-3" [routerLink]="['/other/border-crossing-list']">Border Crossing</a>
                        <a class="text-white float-right" [routerLink]="['/other/border-crossing-edit/0']">+</a><br>
                        <a class="text-white pl-3" [routerLink]="['/other/pallet-type-list']">Type of Pallet</a>
                        <a class="text-white float-right" [routerLink]="['/other/pallet-type-edit/0']">+</a><br>
                        <a class="text-white pl-3" [routerLink]="['/other/exchange-rate-list']">Exchange</a>
                        <a class="text-white float-right" [routerLink]="['/other/exchange-rate-edit/0']">+</a><br>
                        <a class="text-white pl-3" [routerLink]="['/other/region-list']">Region</a>
                        <a class="text-white float-right" [routerLink]="['/other/region-edit/0']">+</a><br>
                        <a class="text-white pl-3" [routerLink]="['/other/type-of-account-list']">Type of Account</a>
                        <a class="text-white float-right" [routerLink]="['/other/type-of-account-edit/0']">+</a><br>
                        <a class="text-white pl-3" [routerLink]="['/other/week-customer-list']">Weeks - Customer</a>
                        <a class="text-white float-right" [routerLink]="['/other/week-customer-new']">+</a><br>
                        <a class="text-white pl-3" [routerLink]="['/other/week-customer-end-list']">Weeks - Customer Head Office</a>
                        <a class="text-white float-right" [routerLink]="['/other/week-customer-end-new']">+</a><br>
                        <a class="text-white pl-3" [routerLink]="['/other/week-kalcor-list']">Weeks - Kalcor</a>
                        <a class="text-white float-right" [routerLink]="['/other/week-kalcor-edit/0']">+</a><br>
                    </ng-template>
                </ngb-panel>
                <ngb-panel title="ITEMS" *ngIf="items || administrationRole">
                    <ng-template ngbPanelContent class="py-0 my-card-body ml-5">
                        <a class="text-white pl-3" [routerLink]="['items/brand-list']">Brands</a>
                        <a class="text-white float-right" [routerLink]="['/items/brand-details/0']">+</a><br>
                        <a class="text-white pl-3" [routerLink]="['items/category-list']">Category</a>
                        <a class="text-white float-right" [routerLink]="['/items/category-edit/0']">+</a><br>
                        <a class="text-white pl-3" [routerLink]="['items/sub-category-list']">Sub-Category</a>
                        <a class="text-white float-right" [routerLink]="['/items/sub-category-edit/0']">+</a><br>
                        <a class="text-white pl-3" [routerLink]="['items/upc-gtn-list']">UPC/GTIN</a>
                        <a class="text-white float-right" [routerLink]="['/items/upc-gtn-edit/0']">+</a><br>
                    </ng-template>
                </ngb-panel>
                <ngb-panel title="PRODUCTS" *ngIf="products || administrationRole">
                    <ng-template ngbPanelContent class="py-0 my-card-body ml-5">
                        <a class="text-white pl-3" [routerLink]="['products/setup-list']">Products List</a>
                        <a class="text-white float-right" [routerLink]="['/products/setup-edit/0']">+</a><br>
                        below links are commented out 10/18/23
                        <a class="text-white pl-3" [routerLink]="['products/cases-setup-list']">Case Dimensions</a>
                        <a class="text-white float-right" [routerLink]="['products/cases-setup-edit/0']">+</a><br>
                        <a class="text-white pl-3" [routerLink]="['products/pallet-setup-list']">Pallet Patterns</a>
                        <a class="text-white float-right" [routerLink]="['products/pallet-setup-edit/0']">+</a><br>
                        <a class="text-white pl-3" [routerLink]="['products/freight-cost-setup-list']">Freight Cost</a>
                        <a class="text-white float-right" [routerLink]="['products/freight-cost-setup-edit/0']">+</a><br>
                        <a class="text-white pl-3" [routerLink]="['products/costing-bucket-setup-list']">Bucket Cost</a>
                        <a class="text-white float-right" [routerLink]="['products/costing-bucket-setup-edit/0']">+</a><br>
                    </ng-template>
                </ngb-panel>
                <ngb-panel title="PRODUCT LISTING" *ngIf="productListing || administrationRole">
                    <ng-template ngbPanelContent class="py-0 my-card-body ml-5">
                        <a class="text-white pl-3" [routerLink]="['productListings/listing-setup-list']">Listing List</a>
                        <a class="text-white float-right" *ngIf="!vendorRole" [routerLink]="['productListings/listing-setup-new']">+</a><br>
                    </ng-template>
                </ngb-panel>
                <ngb-panel title="PROJECTIONS" *ngIf="projections || administrationRole">
                    <ng-template ngbPanelContent class="py-0 my-card-body ml-5">
                        <a class="text-white pl-3" [routerLink]="['projections/projections-new']">Setup Projection</a><br>
                        <a class="text-white pl-3" [routerLink]="['projections/review-week']">Review Week</a><br>
                    </ng-template>
                </ngb-panel>
                <ngb-panel title="REPORTS" *ngIf="reports || administrationRole">
                  <ng-template ngbPanelContent class="py-0 my-card-body ml-5">
                      <a class="text-white pl-3" [routerLink]="['portal/portal']">Report Portal</a><br>
                      <a class="text-white pl-3" [routerLink]="['portal/share-link']">Data Link</a><br>
                      <a class="text-white pl-3" [routerLink]="['portal/period-report']">Period Report</a><br>
                  </ng-template>
              </ngb-panel>
              <ngb-panel title="ADMINISTRATION" *ngIf="administrationRole"  >
                  <ng-template ngbPanelContent class="py-0 my-card-body ml-5">
                      <a class="text-white pl-3" [routerLink]="['admin/user-list']">Users</a>
                      <a class="text-white float-right" [routerLink]="['admin/user-edit/0']">+</a><br>
                      <a class="text-white pl-3" [routerLink]="['admin/roles-list']">Permissions/Roles</a>
                      <a class="text-white float-right" [routerLink]="['admin/roles-edit/0']">+</a><br>
                      <a class="text-white pl-3" [routerLink]="['dashboard']">Orders@kalcor.ca</a>
                  </ng-template>
              </ngb-panel>
              <ngb-panel title="">
              </ngb-panel>
           </ngb-accordion>
        </div> -->
          <!-- <div
              [ngClass]="{'col-md-21': isExpanded, 'col-md-24': !isExpanded}">
              <router-outlet name="login">
                <app-login *ngIf="loginPressed"
                [(sales)] = "sales"
                [(dashboard)] = "dashboard"
                [(customClearing)] = "customClearing"
                [(accounting)] = "accounting"
                [(customer)] = "customer"
                [(vendor)] = "vendor"
                [(other)] = "other"
                [(items)] = "items"
                [(products)] = "products"
                [(productListing)] = "productListing"
                [(projections)] = "projections"
                [(reports)] = "reports"
                [(portal)] = "portal"
                [(customerId)] = "customerId"
                [(vendorId)] = "vendorId"
                [(brokerId)] = "brokerId"
                [(administrationRole)] = "administrationRole"
                [(trafficRole)] = "trafficRole"
                [(accountingRole)] = "accountingRole"
                [(marketingRole)] = "marketingRole"
                [(vendorRole)] = "vendorRole"
                [(projectionsRole)] = "projectionsRole"
                (logged)="onlogged($event)"> </app-login>
              </router-outlet> 
            <router-outlet  *ngIf="!isIframe">
            </router-outlet>
        </div> -->
      <!-- </div>
  </div> -->