import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
const url =  environment.kalcorApiUrl;
@Injectable({
  providedIn: 'root'
})
export class VendorService {

  constructor(private http: HttpClient) { }

  getAll(): Observable<any> {
    return this.http.get(url + 'AccountsVendors', this.jwt());
  }
  getCompany(): Observable<any> {
    return this.http.get(url + 'AccountsVendors/Company', this.jwt());
  }
  getOne(id: number): Observable<any> {
    return this.http.get(url + 'AccountsVendors/' + id, this.jwt());
  }
  getFilteredListing(activeItem: string, id: number, page: number, pageSize: number): Observable<any> {
    page = page ? page : 1;
    pageSize = pageSize ? pageSize : 30;
    return this.http.get(url + 'AccountsVendors/Filter?activeItem=' + activeItem + '&id=' + id +
          '&page=' + page + '&pageSize=' + pageSize, this.jwt());
  }
  update(transport) {
    return this.http.put(url + 'AccountsVendors/' + transport.id, transport, this.jwt());
  }
  create(transport): Observable<any> {
    return this.http.post(url + 'AccountsVendors', transport, this.jwt());
  }
  remove(id) {
    return this.http.delete(url + 'AccountsVendors/' + id, this.jwt());
  }
  private jwt() {
    // create authorization header with jwt token
    const currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
    if (currentUser && currentUser.token) {
        const headersInfo = new HttpHeaders({ Authorization: 'Bearer ' + currentUser.token });
        return { headers: headersInfo };
    }
  }

}
