import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve,  RouterStateSnapshot } from "@angular/router";
import { Observable, observable } from "rxjs";
import { WeekCustomer } from "src/app/models/week-customer.model";
import { WeekCustomerService } from "src/app/services/week-customer.service";
import { CustomerService } from "src/app/services/customer.service";

@Injectable()
export class WeekCustomerResolver implements Resolve<WeekCustomer> {
    constructor(private WeekCustomerService : WeekCustomerService,
                private CustomerService: CustomerService) {
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<WeekCustomer> {

        if (route.url[0].path === "week-customer-new") {
            return this.CustomerService.getCompany();
        }
        const idField = 'id';
        const id = parseInt(route.params[idField], 10);

        if (id === 0) {
            return;
        }
            return this.WeekCustomerService.getYears(id);
    }
}