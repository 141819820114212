<h5>{{ title }} </h5>
<form [formGroup]="customerRetailForm" (ngSubmit)="onSubmit()" novalidate>
  <div class="form-group">
    <div class="col">
      <label>
        Active Item:
        <select formControlName="activeItem" class="form-control">
          <option value="Active">Active</option>
          <option value="Not Active">Not Active</option>
        </select>
      </label>
    </div>

    <div class="col">
      <label>
       Customer Head Office (*):
        <select formControlName="customerHeadId" class="form-control">
          <option *ngFor="let c of customer" [value]="c.id">{{c.company}}</option>
        </select>
      </label>
    </div>

    <div class="col-lg-24">
      <label>
        Company (*):
        <input type="text" formControlName="company" class="form-control"  size="60">
      </label>
    </div>

    <div class="col-lg-24">
      <label>
        Address:
        <input type="text" formControlName="address" class="form-control" size="60">
      </label>
    </div>

    <div class="col-lg-24">
      <label>
        City:
        <input type="text" formControlName="city" class="form-control" size="60">
      </label>
    </div>

    <div class="col">
      <label>
        State/Province:
        <input type="text" formControlName="stateProvince" class="form-control">
      </label>

      <label class="ml-3">
        Country/Region:
        <input type="text" formControlName="countryRegion" class="form-control">
      </label>

      <label class="ml-3">
        Zip/Postal Code:
        <input type="text" formControlName="zipPostalCode" class="form-control">
      </label>
    </div>

    <div class="col-lg-24">
      <label>
        Comments:
        <input type="text" formControlName="comments" class="form-control" size="60">
      </label>
    </div>

    <div class="col-lg-24">
      <label>
        Total No. of Stores:
        <input type="text" formControlName="noStores" class="form-control grid-stripe" size="60">
      </label>
    </div>

    <div class="col-lg-24">
      <label>
        Web Page:
        <input type="text" formControlName="webPage" class="form-control" size="60">
      </label>
    </div>

    <div class="col-lg-10">
      <label>
        Type of Account:
        <select formControlName="accountTypeId" class="form-control">
          <option *ngFor="let t of typeOfAcct" [value]="t.accountTypeId">{{t.typeOfAccount1}}</option>
        </select>
      </label>
    </div>


    <div class="row ml-3">
      <label>
        Regions:
      </label>
    </div>  

    <div class="row ml-3">
      <div class="col-lg-3"></div>
      <div class="col-lg-7"></div>
      <div class="col-lg-3">No. Stores:</div>
      <div class="col-lg-5"></div>
    </div>
        
    <div class="row ml-3" *ngFor="let r of region; let i = index;">

      <div class="col-lg-3">
        <div type="text" class="form-control grid-stripe">{{ r.regionName }}</div>
      </div>
      <div class="col-lg-7">
        <div type="text" class="form-control grid-stripe">{{ r.province }}</div>
      </div>
      <div class="col-lg-3">
        <input type="text" class="form-control" [(ngModel)]="noOfStores[i]" (ngModelChange)="onChangesStores()" [ngModelOptions]="{standalone: true}">
      </div>
      <div class="col-lg-5">
        <div type="text" class="ml-3">Enter value to confirm region</div>
      </div>
    </div>
      

    <button *ngIf="newOne" type="submit" class="btn btn-primary mt-3 w-25">Create</button>
    <button *ngIf="!newOne" type="submit" class="btn btn-primary mt-3 w-25">Save</button>
    <button *ngIf="!newOne" type="button" class="btn btn-secondary ml-3 mt-3 w-25" (click)="onDelete()">Delete</button>
    </div>
    <p>(*) Denotes Mandatory</p>
     <ngb-alert *ngIf="successMessage" [type]="alertType" (close)="successMessage = null">{{ successMessage }}</ngb-alert>
</form>

