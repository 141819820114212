<h5>Listing Costing Review </h5>
<div class="smallSpacer"><h6>Filter</h6></div>
<div class="col-lg-44">
  <label class="formControlName ms-3">
    Type of Costing:
    <select class="form-control" [(ngModel)]="activeListingsSelected" (change)="resetFilters()">
      <option [value]=""></option>
      <option *ngFor="let o of activeListingsOptions" [value]="o">{{ o }}</option>
    </select>
  </label>
    <label class="formControlName ms-3">
    Cost Type:
    <select class="form-control" [(ngModel)]="costTypeSelected" (change)="resetFilters()">
      <option [value]=""></option>
      <option *ngFor="let o of costTypeOptions" [value]="o">{{ o }}</option>
    </select>
  </label>
    <label class="formControlName ms-3">
    Customer:
    <select class="form-control" [(ngModel)]="customerSelected" (change)="resetFilters()">
      <option [value]=""></option>
      <option *ngFor="let o of customerOptions" [value]="o.customerId">{{ o.customer }}</option>
    </select>
  </label>

  <label class="formControlName ms-3">
    Ship To:
    <select class="form-control" [(ngModel)]="shipToSelected" (change)="resetFilters()">
      <option [value]=""></option>
      <option *ngFor="let o of shipToOptions" [value]="o.id">{{ o.company }}</option>
    </select>
  </label>
    <label class="formControlName ms-3">
    Broker:
    <select class="form-control" [(ngModel)]="brokerSelected" (change)="resetFilters()">
      <option [value]=""></option>
      <option *ngFor="let o of brokerOptions" [value]="o.id">{{ o.company }}</option>
    </select>
  </label>
  <label class="formControlName ms-3">
    Vendor
    <select class="form-control" [(ngModel)]="vendorSelected" (change)="resetFilters()">
      <option [value]="0"></option>
      <option *ngFor="let o of vendorOptions" [value]="o.vendorId">{{ o.vendor }}</option>
    </select> 
  </label>
    <label class="formControlName ms-3">
    Brand:
    <select class="form-control" [(ngModel)]="brandSelected" (change)="resetFilters()">
      <option [value]=""></option>
      <option *ngFor="let o of brandOptions" [value]="o.brandId">{{ o.brand }}</option>
    </select>
  </label>
    <label class="formControlName ms-3">
    Category:
    <select class="form-control" [(ngModel)]="categorySelected" (change)="resetFilters()">
      <option [value]=""></option>
      <option *ngFor="let o of categoryOptions" [value]="o.categoryId">{{ o.category }}</option>
    </select>
  </label>
  <div class="smallSpacer"></div>
  <button type="button" class="btn btn-primary mt-0 ms-3" (click)="getDefaultDataScreen()">Reset</button>
</div>
<div class="spacer"></div>
<table class="table table-striped">
  <thead>
  <tr>
    <th scope="col">Customer</th>
    <th scope="col">Ship To</th>
    <th scope="col">Broker</th>
    <th scope="col">Product Order Code</th> 
    <th scope="col">Order Type</th> 
    <th scope="col">Item Description</th>
    <th scope="col">Size</th>
    <th scope="col">WHS Selling</th>
    <th scope="col">WHS Srp</th>
    <th scope="col">WHS Unit</th>
    <th scope="col">Marketing</th>
    <th scope="col">Marketing%</th>
    <th scope="col">Billing</th>
    <th scope="col">Billing%</th>
    <th scope="col">Terms</th>
    <th scope="col">Terms%</th>
    <th scope="col">Commission Overall</th>
    <th scope="col">Commission%</th>
    <th scope="col">Commission 1%</th>
    <th scope="col">Commission 2%</th>
    <th scope="col">Kalcor</th>
    <th scope="col">Kalcor%</th>
    <th scope="col">CND FRT Lb</th>
    <th scope="col">CND Frt Cost/Case</th>
    <th scope="col">CND Frt Cost/Pallet</th>
    <th scope="col">Storage Cost/Case</th>
    <th scope="col">Storage Cost/Pallet</th>
    <th scope="col">Net CND</th>
    <th scope="col">Exchange Budget</th>
    <th scope="col">Exchange Difference</th>
    <th scope="col">Vendor Purchase Case Cost</th>
    <th scope="col">Vendor Purchase Unit Cost</th>
    <th scope="col">Clearing $ CS</th>
    <th scope="col">Clearing Cost</th>
    <th scope="col">Storage $ CS</th>
    <th scope="col">Freight $ CS</th>
    <th scope="col">Royalty</th>
    <th scope="col">Royalty%</th>
    <th scope="col">FOB Net Vendor Cost</th>
    <th scope="col">FOB Net Vendor Unit</th>
    <th scope="col">Currency Code</th>
    <th scope="col">Confirmed By</th>
    <th scope="col">Confirm Date</th>
    <th scope="col">FOB Confirmed</th>
    
    </tr>
  </thead>
  <tbody>
  <tr *ngFor="let p of products; index as i">
    <td>{{ p.customer }}</td>
    <td>{{ getShipTo(p.shipToIds) }}</td>
    <td>{{ p.broker }}</td>
    <td>
      <a class="pointer" (click)="onRowClicked(p)">
      <u class="text-primary">{{ p.orderCode }}</u>
      </a>
    </td>
    <td>{{ p.orderType }}</td>
    <td>{{ p.itemDescription }}</td>
    <td>{{ p.size }}</td>
    <td>{{ p.whsSelling | currency  }}</td>
    <td>{{ p.whsSrp | currency  }}</td>
    <td>{{ p.whsUnit | currency  }}</td>
    <td>{{ p.marketing | currency }}</td>
    <td>{{ p.marketing1 }}%</td>
    <td>{{ p.billing | currency }}</td>
    <td>{{ p.billing1 }}%</td>
    <td>{{ p.terms | currency }}</td>
    <td>{{ p.terms1 }}%</td>
    <td>{{ p.commission | currency  }}</td>
    <td>{{ getSum(p.commission1, p.commission2) }}%</td>
    <td>{{ p.commission1 }}%</td>
    <td>{{ p.commission2 }}%</td>
    <td>{{ p.kalcor | currency  }}</td>
    <td>{{ p.kalcor1 }}%</td>
    <td>{{ p.cndFrtCostLb | currency  }}</td>
    <td>{{ p.cndFrtCostCase | currency  }}</td>
    <td>{{ p.cndFrtCostPallet | currency  }}</td>
    <td>{{ p.storageCostCase | currency  }}</td>
    <td>{{ p.storageCostPallet | currency  }}</td>
    <td>{{ p.fobNetReturn | currency  }}</td>
    <td>{{ formatValue(p.exchangeBudget) }}</td>
    <td>{{ p.fobDifference | currency  }}</td>
    <td>{{ p.vendorPurchaseCaseCost | currency  }}</td>
    <td>{{ p.vendorPurchaseCaseCostUnit | currency  }}</td>
    <td>{{ p.clearingCs | currency  }}</td>
    <td>{{ p.clearingCost | currency  }}</td>
    <td>{{ p.duty | currency  }}</td>
    <td>{{ p.freightCs | currency  }}</td>
    <td>{{ p.royalty | currency  }}</td>
    <td>{{ p.royalty1 }}%</td>
    <td>{{ p.fobNetVendorCost | currency  }}</td>
    <td>{{ p.fobNetVendorUnit | currency  }}</td>
    <td>{{ p.currencyCode }}</td>
    <td>{{ p.confirmedBy }}</td>
    <td>{{ displayDate(p.confirmDate, '') }}</td>
    <td>{{ p.fobConfirmed }}</td>
  </tr>
  </tbody>
</table>

<div *ngIf="products!==undefined">
  <ngb-pagination
  [(page)]="page"
  [pageSize]="pageSize"
  [collectionSize]="collectionSize"
  [maxSize]="5"
  (pageChange)="loadPage($event)">
 </ngb-pagination>
</div>

<div class="btn-group" role="group" aria-label="Basic radio toggle button group">
      
  <input class="btn-check" type="radio" name="radio1" id="choice1" [(ngModel)]="pageSize"  (change)="loadPage($event)" autocomplete="off" checked [value]="25">
  <label class="btn btn-outline-primary" for="choice1">
    25
  </label>

  <input class="btn-check" type="radio" name="radio1" id="choice2" [(ngModel)]="pageSize"  (change)="loadPage($event)" [value]="50" autocomplete="off">
  <label class="btn btn-outline-primary" for="choice2">
    50
  </label>

  <input class="btn-check" type="radio" name="radio1" id="choice3" [(ngModel)]="pageSize"  (change)="loadPage($event)" [value]="100" autocomplete="off">
  <label class="btn btn-outline-primary" for="choice3">
    100
  </label>

</div>
