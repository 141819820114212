import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { FundsTransfer } from '../../models/transfer-funds';
import { FundsTransferService } from '../../services/funds-transfer.service'

@Injectable()
export class TransferResolver implements Resolve<FundsTransfer> {
  constructor(private transferService: FundsTransferService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<FundsTransfer> {
    const idField = 'id';
    const id =  parseInt(route.params[idField], 10);

    if (id === 0) {
      return;
    }
    if (route.url[0].path === "transfer-list") {
      return this.transferService.getFilter('Open', '', 0, 0, 1, 30);
    }
    return this.transferService.getOne(id);
  }
}
