<h4>Broker List </h4>
<h5>Filter</h5>
<div class="col-lg-44">
  <label>
    Active:
    <select [(ngModel)]="activeItemSelected" (change)="loadPage()">
      <option value="Active">Active</option>
      <option value="Not Active">Not Active</option>
    </select>
  </label>
  <label class="ms-3">
      Company:
     <select [(ngModel)]="companySelected" (change)="loadPage()">
      <option [value]=""></option>
      <option *ngFor="let c of companyOptions" [value]="c.id">{{ c.company }}</option>
    </select>
  </label>
  <button type="button" class="btn btn-primary mt-0 ms-3" (click)="reset()">Reset</button>
</div>
<table class="table table-striped" style="overflow-x:scroll;">
    <thead>
    <tr>
      <th scope="col">Active</th>
      <th scope="col">Company</th>
      <th scope="col">Address</th>
      <th scope="col">City</th>
      <th scope="col">State/Province</th>
      <th scope="col">Country/Region</th>
      <th scope="col">Zip Code</th>
      <th scope="col">Full Name</th>
      <th scope="col">Job Title</th>
      </tr>
    </thead>
    <tbody>
    <tr *ngFor="let b of broker; index as i">
      <td>{{ b.activeItem }}</td>
      <td>
        <a class="pointer" (click)="onRowClicked(b)">
          <u class="text-primary">{{ b.company }}</u>
        </a>
          </td>
      <td>{{ b.address }}</td>
      <td>{{ b.city }}</td>
      <td>{{ b.stateProvince }}</td>
      <td>{{ b.countryRegion }}</td>
      <td>{{ b.zipPostalCode }}</td>
      <td>{{ b.fullName }}</td>
      <td>{{ b.jobTitle }}</td>
    </tr>
    </tbody>
  </table>
  <div *ngIf="broker!==undefined">
    <ngb-pagination [(page)]="page" [pageSize]="pageSize" [collectionSize]="count" (pageChange)="loadPage()">
    </ngb-pagination>
  </div>
  <ngb-alert *ngIf="successMessage" [type]="alertType" (close)="successMessage = null">{{ successMessage }}</ngb-alert>  
  