import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
const url = environment.kalcorApiUrl;

@Injectable({
  providedIn: 'root'
})
export class ClearingCompanyService {

  constructor(private http: HttpClient) { }
  getAll(): Observable<any> {
    return this.http.get(url + 'AccountsClearingCompanies', this.jwt());
  }
  getOne(id: number): Observable<any> {
    return this.http.get(url + 'AccountsClearingCompanies/' + id, this.jwt());
  }
  update(clearing) {
    return this.http.put(url + 'AccountsClearingCompanies/' + clearing.id, clearing, this.jwt());
  }
  create(clearing): Observable<any> {
    return this.http.post(url + 'AccountsClearingCompanies', clearing, this.jwt());
  }
  remove(id) {
    return this.http.delete(url + 'AccountsClearingCompanies/' + id, this.jwt());
  }
  private jwt() {
    // create authorization header with jwt token
    const currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
    if (currentUser && currentUser.token) {
      const headersInfo = new HttpHeaders({ Authorization: 'Bearer ' + currentUser.token });
      return { headers: headersInfo };
    }
  }
}
