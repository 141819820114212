import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Location } from '../../../models/location-model';
import { LocationService } from '../../../services/location.service';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-location-edit',
  templateUrl: './location-edit.component.html',
  styleUrls: ['./location-edit.component.scss']
})
export class LocationEditComponent implements OnInit {
  title = 'Locations';
  newOne = true;
  location: Location;
  locationForm: UntypedFormGroup;
  successMessage: string;
  private success = new Subject<string>();
  staticAlertClosed = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private fb: UntypedFormBuilder,
    private locationService: LocationService
  ) { }

  ngOnInit() {
    this.locationForm = this.fb.group({
      location: [''],
      city: ['']
    })
    this.route.data.subscribe(data => {
      if (data.dataLocation !== undefined) {
        this.title = 'Location Edit';
        this.newOne = false;
        this.location = data.dataLocation;
        this.fillFormData();
      } else {
        this.title = 'Location New';
        this.newOne = true;
        this.location = new Location();
        this.locationForm.reset();
      }
    });
    setTimeout(() => this.staticAlertClosed = true, 20000);
    this.success.subscribe((message) => this.successMessage = message);
    this.success.pipe(
      debounceTime(5000)
    ).subscribe(() => this.successMessage = null); 
  }

  fillFormData() {
    this.locationForm.setValue({
      location: this.location.location,
      city: this.location.city
    });
  }
  onSubmit() {
    this.location.location = this.locationForm.controls.location.value;
    this.location.city = this.locationForm.controls.city.value;
    if (this.newOne) {
      this.locationService.create(this.location).subscribe(data => {
        this.location.id =  data.id;
        this.newOne = false;
        this.title = 'Location Edit';
        this.success.next(`Successfully added.`);
      }, error => {
      });
    } else {
      this.locationService.update(this.location).subscribe(data => {
        this.success.next(`Successfully changed.`);
      }, error => {
      });
    }
  }
  onDelete() {
    if (this.location.id) {
      this.locationService.remove(this.location.id).subscribe(data => {
        this.router.navigate(['/other/location-list']);
      });
    }
  }    
}
