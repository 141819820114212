import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Broker } from '../../../models/broker.model';
import { BrokerService } from '../../../services/broker.service';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-broker-edit',
  templateUrl: './broker-edit.component.html',
  styleUrls: ['./broker-edit.component.scss']
})
export class BrokerEditComponent implements OnInit {
  title = 'Broker New';
  newOne = true;
  broker: Broker;
  brokerForm: UntypedFormGroup;
  successMessage: string;
  private success = new Subject<string>();
  staticAlertClosed = false;
  constructor(private route: ActivatedRoute,
              private router: Router,
              private fb: UntypedFormBuilder,
              private brokerSvc: BrokerService) {
    this.broker = new Broker();
  }

  ngOnInit() {
    this.brokerForm = this.fb.group({
      activeItem: [''],
      address: [''],
      businessPhone: [''],
      city: [''],
      comments: [''],
      company: [''],
      countryRegion: [''],
      eMail: [''],
      faxNumber: [''],
      fullName: [''],
      jobTitle: [''],
      mobileNumber: [''],
      stateProvince: [''],
      webPage: [''],
      zipPostalCode: ['']
    });
    this.route.data.subscribe(data => {
      if (data.dataBroker !== undefined) {
        this.title = 'Broker Edit';
        this.newOne = false;
        this.broker = data.dataBroker;
        this.fillFormData();
      } else {
        this.title = 'Broker New';
        this.newOne = true;
        this.broker = new Broker();
        this.brokerForm.reset();
      }
    });
    setTimeout(() => this.staticAlertClosed = true, 20000);
    this.success.subscribe((message) => this.successMessage = message);
    this.success.pipe(
      debounceTime(5000)
    ).subscribe(() => this.successMessage = null);
  }
  fillFormData() {
    this.brokerForm.setValue({
      activeItem: this.broker.activeItem,
      address: this.broker.address,
      businessPhone: this.broker.businessPhone,
      city: this.broker.city,
      comments: this.broker.comments,
      company: this.broker.company,
      countryRegion: this.broker.countryRegion,
      eMail: this.broker.eMail,
      faxNumber: this.broker.faxNumber,
      fullName: this.broker.fullName,
      jobTitle: this.broker.jobTitle,
      mobileNumber: this.broker.mobileNumber,
      stateProvince: this.broker.stateProvince,
      webPage: this.broker.webPage,
      zipPostalCode: this.broker.zipPostalCode
    });
  }
  onSubmit() {
    this.broker.activeItem = this.brokerForm.controls.activeItem.value;
    this.broker.address = this.brokerForm.controls.address.value;
    this.broker.businessPhone = this.brokerForm.controls.businessPhone.value;
    this.broker.city = this.brokerForm.controls.city.value;
    this.broker.comments = this.brokerForm.controls.comments.value;
    this.broker.company = this.brokerForm.controls.company.value;
    this.broker.countryRegion = this.brokerForm.controls.countryRegion.value;
    this.broker.eMail = this.brokerForm.controls.eMail.value;
    this.broker.faxNumber = this.brokerForm.controls.faxNumber.value;
    this.broker.fullName = this.brokerForm.controls.fullName.value;
    this.broker.jobTitle = this.brokerForm.controls.jobTitle.value;
    this.broker.mobileNumber = this.brokerForm.controls.mobileNumber.value;
    this.broker.stateProvince = this.brokerForm.controls.stateProvince.value;
    this.broker.webPage = this.brokerForm.controls.webPage.value;
    this.broker.zipPostalCode = this.brokerForm.controls.zipPostalCode.value;
    if (this.newOne) {
      this.brokerSvc.create(this.broker).subscribe(data => {
        this.broker.id =  data.id;
        this.newOne = false;
        this.title = 'Broker Edit';
        this.success.next(`Successfully added.`);
      }, error => {
      });
    } else {
      this.brokerSvc.update(this.broker).subscribe(data => {
        this.success.next(`Successfully changed.`);
      }, error => {
      });
    }
  }
  onDelete() {
    if (this.broker.id) {
      this.brokerSvc.remove(this.broker.id).subscribe(data => {
        this.router.navigate(['/other/broker-list']);
      });
    }
  }
}
