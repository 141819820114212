<div class="pdf">
    <div class="row g-0 text-white mt-2 px-2 pdf-bg pdf-size20">
      <div class="col-lg-24 pt-2 px-2 text-center">
        SHIPPING
      </div>
    </div>
  
    <div class="row g-0 mt-2">
      <div class="col-lg-8 pt-2 px-0">
        <label class="text-white w-100 pdf-bg pdf-size11">
          BILL TO
        </label>
      </div>
      <div class="col-lg-8 pt-2 px-0">
        <label class="text-white w-100 pdf-bg pdf-size11">
          SALES ORDER INFORMATION
        </label>
      </div>
      <div class="col-lg-8 pt-2 px-0">
        <label class="text-white w-100 pdf-bg pdf-size11">
          SHIPPING DATES/TYPES
        </label>
      </div>
    </div>
  
    <div class="row g-0">
      <div class="col-lg-8 pt-0 px-0">
        {{ order?.billTo }}
      </div>
      <div class="col-lg-4 pt-0 px-0">
        Sales Order Date
      </div>
      <div class="col-lg-4 pt-0 px-0">
        {{ displayDate(order?.salesOrderDate, '')  }}
      </div>
      <div class="col-lg-4 pt-0 px-0">
            Shipment Date
      </div>
      <div class="col-lg-4 pt-0 px-0">
        {{ displayDate(order?.shipmentDate, '')  }}
      </div>
    </div>
  
    <div class="row g-0">
      <div class="col-lg-8 pt-0 px-0">
        {{ order?.billAddress }}
      </div>
      <div class="col-lg-4 pt-0 px-0">
        Sales Order No.
      </div>
      <div class="col-lg-4 pt-0 px-0">
        {{ order?.salesOrderNo }}
      </div>
      <div class="col-lg-4 pt-0 px-0">
        Delivered Date/Appt
      </div>
      <div class="col-lg-4 pt-0 px-0">
        {{ displayDate(order?.deliveredDateAppt, 'T') }}
      </div>
    </div>
  
    <div class="row g-0">
      <div class="col-lg-8 pt-0 px-0">
        {{ order?.billCSZ }}
      </div>
      <div class="col-lg-4 pt-0 px-0">
        Purchase Order
      </div>
      <div class="col-lg-4 pt-0 px-0">
        {{ order?.purchaseOrder }}
      </div>
      <div class="col-lg-4 pt-0 px-0">
        App Confirmed
      </div>
      <div class="col-lg-4 pt-0 px-0">
        {{ order?.appConfirmed }}
    </div>
    </div>
  
    <div class="row g-0">
      <div class="col-lg-8 pt-0 px-0">
      </div>
      <div class="col-lg-4 pt-0 px-0">
        Payment Terms
      </div>
      <div class="col-lg-4 pt-0 px-0">
        {{ order?.paymentTerms }}
      </div>
      <div class="col-lg-4 pt-0 px-0">
        Delivery Type
      </div>
      <div class="col-lg-4 pt-0 px-0">
        {{ order?.freightType }}
      </div>
    </div>
  
    <div class="row g-0">
      <div class="col-lg-8 pt-0 px-0">
      </div>
      <div class="col-lg-4 pt-0 px-0">
        Broker
      </div>
      <div class="col-lg-4 pt-0 px-0">
        {{ order?.broker }}
      </div>
      <div class="col-lg-4 pt-0 px-0">
            Order Status
      </div>
      <div class="col-lg-4 pt-0 px-0">
            {{ order?.orderStatus }}
      </div>
    </div>
  
 
    <div class="row g-0">
    <div class="col-lg-6 pt-0 px-0">
        <label class="text-white w-100 pdf-bg pdf-size11">
            SHIP TO
        </label>
    </div>
            
      <div class="col-lg-4 pt-0 px-0">
        <label class="text-white w-100 pdf-bg pdf-size11">
          VENDOR PICK UP
        </label>
      </div>
      <div class="col-lg-6 pt-0 px-0">
        <label class="text-white w-100 pdf-bg pdf-size11">
          SHIPPING LANE
        </label>
      </div>
  
      <div class="col-lg-4 pt-0 px-0">
        <label class="text-white w-100 pdf-bg pdf-size11">
          TRANSPORT COMPANY
        </label>
      </div>
      <div class="col-lg-4 pt-0 px-0">
        <label class="text-white text-center w-100 pdf-bg pdf-size11">
          WAREHOUSE
        </label>
      </div>
    </div>
  
    <div class="row g-0">
        <div class="col-lg-6 pt-0 px-0">
            {{ order?.shipTo }}
        </div>
      <div class="col-lg-4 pt-0 px-0">
        {{ order?.pickCompany }}
      </div>
      <div class="col-lg-6 pt-0 px-0">
        {{ order?.location1 }}
      </div>
      <div class="col-lg-4 pt-0 px-0">
        {{ order?.transCompany1 }}
      </div>
      <div class="col-lg-4 pt-0 px-0">
        {{ order?.whseCompany }}
      </div>
    </div>
  
    <div class="row g-0">
        <div class="col-lg-6 pt-0 px-0">
            {{ order?.shipToAddress }}
        </div>
        
      <div class="col-lg-4 pt-0 px-0">
        {{ order?.pickAddress }}
      </div>
      <div class="col-lg-6 pt-0 px-0">
        {{ order?.location2 }}
      </div>
      <div class="col-lg-4 pt-0 px-0">
        {{ order?.transCompany2 }}
      </div>
      <div class="col-lg-4 pt-0 px-0">
        {{ order?.whseAddress }}
      </div>
    </div>
  
    <div class="row g-0">
        <div class="col-lg-6 pt-0 px-0">
            {{ order?.shipToCSZ }}
        </div>
      <div class="col-lg-4 pt-0 px-0">
        {{ order?.pickAddress }}
      </div>
      <div class="col-lg-6 pt-0 px-0">
        {{ order?.location3 }}
      </div>
      <div class="col-lg-4 pt-0 px-0">
        {{ order?.transCompany3 }}
      </div>
      <div class="col-lg-4 pt-0 px-0">
        {{ order?.whseCSZ }}
      </div>
    </div>
    <div *ngIf="order.whseEst" class="row g-0">
      <div class="col-lg-20 pt-0 px-0">
      </div>
      <div class="col-lg-4 pt-0 px-0">
        {{ order?.whseEst }}
      </div>
    </div>
    
    <div class="row g-0">
      <div class="col-lg-10 pt-3 px-0">
        <span class="font-weight-bold">Vendor: </span>
        {{ order?.vendorInfo  }}
      </div>
      <div class="col-lg-14 pt-3 px-2">
        <span class="font-weight-bold">Notes: </span>
        {{ order?.notes }}
      </div>
    </div>
  
    <div class="row g-0 text-white mt-3 pdf-bg pdf-size10">
      <div class="col-lg-2 pt-0 px-2">
        <label class="text-white">
          ORDER CODE
        </label>
      </div>
      <div class="col-lg-6 pt-0 px-2">
        <label class="text-white">
          BRAND/CATEGORY
        </label>
      </div>
  
      <div class="col-lg-10 pt-0 px-2">
        <label class="text-white">
          ITEM DESCRIPTION
        </label>
      </div>
  
      <div class="col-lg-2 pt-0 px-2">
        <label class="text-white">
          SIZE
        </label>
      </div>
      <div class="col-lg-2 pt-0 px-2">
        <label class="text-white text-end w-100">
          ORDER
        </label>
      </div>
      <div class="col-lg-2 pt-0 px-2">
        <label class="text-white text-end w-100">
          SHIPPED
        </label>
      </div>
    </div>
      <div class="form-group">
        <div *ngFor="let item of items; let i = index;">
          <div class="row g-0"
            [ngClass]="{'grid-stripe': i % 2 != 0}">
            <div class="col-lg-2 pt-0 px-2 ">
              {{ item.orderCode}}
            </div>
            <div class="col-lg-6 pt-0 px-2 ">
              {{ item.brandCategory }}
            </div>
            <div class="col-lg-10 pt-0 px-2 ">
              {{ item.itemDescription}}
            </div>
            <div class="col-lg-2 pt-0 px-2 ">
              {{ item.size }}
            </div>
            <div class="col-lg-2 pt-0 px-2 text-end">
              {{ item.cases }}
            </div>
            <div class="col-lg-2 pt-0 px-2  text-end">
                {{ item.shipped ? item.shipped : 0 | number : '1.0-0' }}
            </div>
          </div>
          <div class="row g-0"
          [ngClass]="{'grid-stripe': i % 2 != 0}">
            <div class="col-lg-4 pt-0 pb-2 px-2 ">
              <span class="pe-2">CS Gross Lbs</span>
              {{ item.csGrossLbs | number : '1.2-2' }}
            </div>
            <div class="col-lg-4 pt-0 pb-2 px-2 ">
              <span class="pe-2">Product Type</span>
              {{ item.productType }}
            </div>
            <div class="col-lg-6 pt-0 pb-2 px-2 ">
              <span class="pe-2">TI/HI</span>
              {{ item.tiHi }}
              <span class="ps-8 pe-2">CS per Pallet</span>
              {{ item.csPerPallet }}
            </div>
          </div>
        </div>
      </div>
      <div class="row g-0 text-white mt-3 pdf-bg pdf-size12">
        <div class="col-lg-6 pt-0 px-2">
          <span class="pe-2">TOTAL CASES</span>
          {{ totalCases | number : '1.0-0' }}
        </div>
        <div class="col-lg-4 pt-0 px-2">
          <span class="pe-2">TOTAL PALLETS</span>
          {{ totalPallets |  number : '1.0-0' }}
        </div>
  
        <div class="col-lg-8 pt-0 px-2 ms-auto">
          <span class="pe-2">TOTAL GROSS</span>
          {{ totalGrossLbs | number : '1.2-2'  }}
          <span class="pe-3"> lbs</span>
          {{ totalGrosskg | number : '1.2-2'  }}
          <span class="pe-2"> kg</span>
        </div>
  
      </div>
      <div class="row g-0 mt-3">
        <div class="col-lg-6 pt-0 px-2">
          <img class="logo img-responsive" src="/assets/images/Kalcor_Canada.png" height="100%" width="158px">
        </div>
        <div class="col-lg-18 pt-3 px-2">
          ​​CANADA OFFICES: 2275 Upper Middle Road East, Suite 101, Oakville, ON, L6H 0C3. Office 866.779.9850 Email:
          Inbox@kalcor.ca Website: www.kalcor.ca
        </div>
      </div>
    </div>
