import { Component, OnInit } from '@angular/core';
import { TransportationService } from '../../../services/transportation.service';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
@Component({
  selector: 'app-transportation-list',
  templateUrl: './transportation-list.component.html',
  styleUrls: ['./transportation-list.component.scss']
})
export class TransportationListComponent implements OnInit {
  transport: any;
  activeItemSelected: string;
  companySelected: number;
  companyOptions:[];
  page: number;
  pageSize: number;
  count: number;
  successMessage: string;
  private success = new Subject<string>();
  alertType = "success";
  staticAlertClosed = false;

  constructor(private router: Router,
              private transportSvc: TransportationService) { }

  ngOnInit() {
    this.reset();
    setTimeout(() => this.staticAlertClosed = true, 20000);
    this.success.subscribe((message) => this.successMessage = message);
    this.success.pipe(
      debounceTime(5000)
    ).subscribe(() => this.successMessage = null);    
  }
  
  loadPage() {
    this.transportSvc.getFilteredListing(this.activeItemSelected, this.companySelected, this.page, this.pageSize).subscribe(data => {
      this.transport = data.item;
      this.companyOptions = data.item2;
      this.count = data.count;
    },error => {
      this.successMessage = 'Unable to process request';
      this.alertType = "danger";
    });
  }
  
  reset() {
    this.page = 1;
    this.pageSize = 30;
    this.activeItemSelected = 'Active';
    this.companySelected = 0;
    this.loadPage();    
  }  
  onRowClicked(clear) {
    this.router.navigate(['/other/transportation-edit', clear.id]);
  }
}
