<h5>Listing Setup List Edit</h5>
<div class="row">
  <div class="column">
    <form [formGroup]="productForm" (ngSubmit)="onSubmit()" novalidate>
      <div class="form-group">
        <div class="col">
          <label>
            Active Listing:
            <select formControlName="activeListings" class="form-control">
              <option *ngFor="let a of activeList; let i = index" [value]="a">{{ a }}</option>
            </select>
          </label>

          <label class="ms-3">
            Product Order Code:
            <input type="text" formControlName="productOrderCode" class="form-control">
          </label>
        </div>

        <div class="col">
          <label>
            Brand:
            <input type="text" formControlName="brand" class="form-control">
          </label>

          <label class="ms-3">
            Category:
            <input type="text" formControlName="category" class="form-control">
          </label>
        </div>

        <div class="col">
          <label>
            Item Desciption:
            <input type="text" formControlName="itemDescription" class="form-control" size="60">
          </label>

          <label class="ms-3">
            Size:
            <input type="text" formControlName="size" class="form-control">
          </label>
        </div>

        <div class="col">
          <label>
            Customer:
            <select formControlName="customerId" class="form-control">
              <option *ngFor="let c of customers" [value]="c.id">{{c.company}}</option>
            </select>
          </label>
        </div>

        <div class="col">
          <label>
            Ship To:
            <select multiple formControlName="shipToIds" class="form-control">
              <option *ngFor="let s of shipTo" [value]="s.id">{{s.company}}</option>
            </select>
          </label>
        </div>
        <div class="row ms-3">
          <div class="col-lg">Selected Ship To:</div>
          <div class="col-lg">Broker 1:</div>
          <div class="col-lg">Broker 2:</div>
        </div>

        <div class="row ms-3" *ngFor="let s of shipToBroker; let i = index;">
          <div class="col-lg">{{ s.name }}</div>

          <div class="col-lg">
            <select [(ngModel)]="s.brokerId" class="form-control" [ngModelOptions]="{standalone: true}">
              <option [value]="0"></option>
              <option *ngFor="let b of brokers" [value]="b.id">{{b.company}}</option>
            </select>
          </div>

          <div class="col-lg">
            <select [(ngModel)]="s.broker2Id" class="form-control" [ngModelOptions]="{standalone: true}">
              <option [value]="0"></option>
              <option *ngFor="let b of brokers" [value]="b.id">{{b.company}}</option>
            </select>
          </div>

        </div>

        <div class="col">
          <label>
            Sales Manager
            <select formControlName="salesMgrId" class="form-control">
              <option *ngFor="let s of salesmanager" [value]="s.salesMgrId">{{s.fullName}}</option>
            </select>
          </label>
        </div>

        <div class="col">
          <label>
            Billing Agent
            <select formControlName="billingAgentId" class="form-control">
              <option *ngFor="let b of billingAgent" [value]="b.id">{{b.billingAgentName}}</option>
            </select>
          </label>
        </div>

        <div class="col">
          <label>
            Customer Head Office:
            <select multiple formControlName="headOfficeIds" class="form-control">
              <option *ngFor="let e of customerEnd" [value]="e.head">{{e.company}}</option>
            </select>
          </label>
        </div>

        <div class="col">
          <label>
            Banners:
            <select multiple formControlName="retailIds" class="form-control">
              <option *ngFor="let r of customerRetail" [value]="r.retail">{{r.retailCompany}}</option>
            </select>
          </label>
        </div>

        <div class="col">
          <label *ngIf="taxable">
            GST/HST:
            <select formControlName="gstHstId" class="form-control">
              <option value="0">N/A</option>
              <option *ngFor="let g of gstHst" [value]="g.id">{{ g.taxName }}</option>
            </select>
          </label>
        </div>

        <div *ngIf="this.spinner=='...Updating'"> <button type='button' class="btn btn-primary mt-3 w-25" disabled>
            <span class="spinner-border spinner-border-sm"></span>{{ spinner }}</button>
        </div>
        <div *ngIf="this.spinner==''">
          <button type="submit" class="btn btn-primary mt-3 w-25">Save</button>
          <button type="button" class="btn btn-warning mt-3 ms-3" (click)="validate()">Save to Group Listing</button>
          <button type="button" class="btn btn-secondary ms-3 mt-3 w-25" (click)="onDelete()">Delete</button>
          <br>
          <button type="button" class="btn btn-secondary mt-3 w-25" (click)="editSetup()">Edit Setup</button>
          <button type="button" class="btn btn-secondary ms-3 mt-3 w-25" (click)="editCaseSetup()">Case Setup</button>
          <button type="button" class="btn btn-secondary ms-3 mt-3 w-25" (click)="editPalletSetup()">Pallet
            Setup</button>
          <br>
          <button type="button" class="btn btn-secondary mt-3 w-25" (click)="editFreightCostSetup()">Freight Cost
            Setup</button>
          <button type="button" class="btn btn-secondary ms-3 mt-3 w-25" (click)="editCostingBucketSetup()">Costing
            Bucket Setup</button>
        </div>
      </div>
      <ngb-alert *ngIf="successMessage" type="success" (close)="successMessage = null">{{ successMessage }}</ngb-alert>
    </form>
  </div>
  <div class="column">
    <button *ngIf="newListing" type="button" class="btn btn-warning mt-3 mb-3" (click)="getListingId()">Add Group
      Products to Listing</button>
    <h5 *ngIf="newListing">Group Products: {{ this.groupName }}</h5>
    <table *ngIf="newListing" class="table table-striped">
      <thead>
        <tr>
          <th scope="col">Product Order Code</th>
          <th scope="col">Order Type</th>
          <th scope="col">Brand</th>
          <th scope="col">Category</th>
          <th scope="col">Item Description</th>
          <th scope="col">Size</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let p of linkedProduct">
          <td>{{ p.orderCode }}</td>
          <td>{{ p.orderType }}</td>
          <td>{{ p.brand }}</td>
          <td>{{ p.category }}</td>
          <td>{{ p.itemDescription }}</td>
          <td>{{ p.size }}</td>
        </tr>
      </tbody>
    </table>
    <h5 *ngIf="!newListing">Listing Products</h5>
    <table *ngIf="!newListing" class="table table-striped">
      <thead>
        <tr>
          <th scope="col">Product Order Code</th>
          <th scope="col">Order Type</th>
          <th scope="col">Brand</th>
          <th scope="col">Category</th>
          <th scope="col">Item Description</th>
          <th scope="col">Size</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let p of linkedListing">
          <td>{{ p.orderCode }}</td>
          <td>{{ p.orderType }}</td>
          <td>{{ p.brand }}</td>
          <td>{{ p.category }}</td>
          <td>{{ p.itemDescription }}</td>
          <td>{{ p.size }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>