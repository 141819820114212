import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from "@angular/router";
import { Observable } from "rxjs";
import { AzureService } from "src/app/services-azure/azure.service";
import { VendorData } from "src/app/models-azure/vendor-data.model";

@Injectable()
export class AzureResolver implements Resolve<VendorData> {
    vendorData: VendorData;
    constructor(private azureService: AzureService) {
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):  Observable<VendorData>  {
    //     const yearField = 'year';
    //     const year = parseInt(route.params[yearField], 10);

    //     if (year === 0) {
    //         return;
    //     }
    //     return this.azureService.getList(year, 1, 35);
    // }

        const idField = 'id';
        const id = parseInt(route.params[idField], 10);

        if (id === 0) {
            return;
        }
        return this.azureService.getOne(id);
    }
}