import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ProjectionsService } from 'src/app/services/projections.service';
import { Router, ActivatedRoute } from '@angular/router';
import { debounceTime } from 'rxjs/operators';
import { Projection } from 'src/app/models/projection-model';
import { Subject } from 'rxjs';
import { Sorter2Service } from 'src/app/services/sorter2.service';
import { error } from 'console';
import { Items } from 'src/app/models/product.model';
import { WeekKalcor } from 'src/app/models/week-kalcor.model';
import { WeekKalcorService } from 'src/app/services/week-kalcor.service';
import { Big } from 'big.js';
import { Sorter3Service } from 'src/app/services/sorter3.service';


@Component({
  selector: 'app-projections-list',
  templateUrl: './projections-list.component.html',
  styleUrls: ['./projections-list.component.scss']
})
export class ProjectionsListComponent {
  projection: any;
  projectionOriginal: any;
  vendorSelected: number;
  vendorOptions: [];
  customerSelected: number;
  customerOptions: [];
  headOfficeSelected: number;
  headOfficeOptions: [];
  bannerSelected: number;
  bannerOptions: [];
  yearSelected: number;
  yearOptions: [];
  typeSelected: string;
  typeOptions: [];
  todaysDate: Date;
  weeks = [];
  weekOptions = [];
  begWeek: number;
  endWeek: number;
  weeksKalcor = [];
  processIndex: number;
  processIndexWeek: number;
  currentWeek: number;
  page = 1;
  pageSize = 25;
  count: number;
  collectionSize = 0;
  successMessage: string;
  private success = new Subject<string>();
  alertType = "success";
  staticAlertClosed = false;

  constructor(private router: Router,
    private projectionService: ProjectionsService,
    private activatedRoute: ActivatedRoute,
    private weekKalcorService: WeekKalcorService,
    private sort3Svc: Sorter3Service,
    private sort2Svc: Sorter2Service) { }

  ngOnInit() {
    this.yearOptions = [];
    this.weeksKalcor = [];
    this.weekKalcorService.getYears().subscribe(x => {
      this.yearOptions = x;
      this.reset();
      setTimeout(() => this.staticAlertClosed = true, 20000);
      this.success.subscribe((message) => this.successMessage = message);
      this.success.pipe(
        debounceTime(5000)
      ).subscribe(() => this.successMessage = null);
    })
  }

  reset() {
    this.page = 1;
    this.collectionSize = 1;
    this.pageSize = 30;
    this.vendorSelected = 0;
    this.customerSelected = 0;
    this.headOfficeSelected = 0;
    this.bannerSelected = 0;
    this.typeSelected = '';
    this.currentWeek = 0;
    this.weeksKalcor = [];
    this.todaysDate = new Date();
    const firstYear = this.todaysDate.getFullYear();
    this.yearSelected = firstYear;
    this.begWeek = 1;
    this.endWeek = 52;
    this.loadPage();
  }

  loadPage() {
    this.projection = [];
    this.processIndex = -1;
    this.projectionService.getFilteredList(0, this.vendorSelected, this.customerSelected, this.headOfficeSelected, this.bannerSelected, this.yearSelected, this.typeSelected, this.page, this.pageSize).subscribe(data => {
      this.projection = data.item;
      this.projection = this.sort3Svc.sorter(this.projection, 'vendor', 'promotionalNumber', 'banner');
      // this.projection = this.sort3Svc.sorter(this.projection, 'promotionalNumber', 'typeOfPromotion', 'banner');
      // this.projection = this.sort3Svc.sorter(this.projection, 'promotionalNumber', 'banner', 'itemDescription');
      this.vendorOptions = data.item2;
      this.customerOptions = data.item3;
      this.headOfficeOptions = data.item4;
      this.bannerOptions = data.item5;
      this.page = 1;
      this.collectionSize = 1;
      this.count = data.count;
      this.getWeeks();
    }, error => {
      this.successMessage = 'Unable to process request';
      this.alertType = "danger";
    });
  }

  getWeeks() {
    this.projection.forEach(item => {
      let tempWeeks = [item.week1, item.week2, item.week3, item.week4, item.week5, item.week6, item.week7, item.week8, item.week9, item.week10,
      item.week11, item.week12, item.week13, item.week14, item.week15, item.week16, item.week17, item.week18, item.week19, item.week20,
      item.week21, item.week22, item.week23, item.week24, item.week25, item.week26, item.week27, item.week28, item.week29, item.week30,
      item.week31, item.week32, item.week33, item.week34, item.week35, item.week36, item.week37, item.week38, item.week39, item.week40,
      item.week41, item.week42, item.week43, item.week44, item.week45, item.week46, item.week47, item.week48, item.week49, item.week50,
      item.week51, item.week52];

      let cases = 0;
      let weeks = [];
      tempWeeks.forEach((w) => {
        weeks.push(w);
      })
      let lowestWeek = 55;
      let highestWeek = -1;

      if (item.typeOfPromotion == 'Budget Promotion') {
        
        const promoIndex = item.itemsPromo.findIndex(f => f.promotionalNumber == item.promotionalNumber);
        if (promoIndex > -1) {
          const promoWeeks = item.itemsPromo[promoIndex].promotionWeeks.split(',');
          promoWeeks.forEach((w, weekIndex) => {
            if (w == 'Y') {
              cases += weeks[weekIndex];
              if (weekIndex < lowestWeek) {
                lowestWeek = weekIndex;
              }
              if (weekIndex > highestWeek) {
                highestWeek = weekIndex;
              }
            }
          })
        }
      } else {
        weeks.forEach(w => {
          cases += w;
        })
        for (let i = 0; i < weeks.length; i++)
          if (weeks[i] !== null) {
            this.currentWeek++;
            if (i < lowestWeek) {
              lowestWeek = i;
            }
            if (i > highestWeek) {
              highestWeek = i;
            }
          }
      }
      item.casesPerWeek = cases;
      lowestWeek++;
      highestWeek++;
      item.week1 = lowestWeek;
      item.week2 = highestWeek;
    });
  }

  onRowClicked(projection) {
    //  this.router.navigate(['/projections/projections-edit', projection.id]);
    let promotionWeeks = '';
    if (projection.itemsPromo.length > 0) {
      const promoWeeks = projection.itemsPromo.find(x => x.promotionalNumber == projection.promotionalNumber);
      promotionWeeks = promoWeeks.promotionWeeks;
    }
    
    this.router.navigate(['/projections/projections-edit', projection.id], {state: 
      {
        promotion: projection.promotionalNumber,
        promotionWeeks: promotionWeeks
        // promotionWeeks: promoWeeks.promotionWeeks
      }});
  }

}
