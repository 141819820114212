export class Bucket {
    id: number;
    buckets: string;
}
export class Incoming {
    id: number;
    orderStatus: string;
    orderType: string;
    salesOrderNo: string;
    purchaseOrder: string;
    shipmentDate: Date;
    billTo: string;
    billToId: number;
    shipTo: string;
    shipToId: number;
    broker: string;
    brokerId: number;
    vendorId: number;
    vendor: string;
    budgetAmount: number;
    actualAmount: number;
    difference: number;
    bucketCompany: string;
    bucketReference: string;
    bucketQuery: string;
    salesAmount: number;
    itemSales: number;
    vendorSales: number;
    itemVendor: number;
    funds: number;
    credit: number;
    balance: number;
}
export class Transfer {
    id: number;
    transferStatus: string;
    transferNo: string;
    transferDate: Date;
    vendorId: number;
    vendor: string;
    billToId: number;
    billTo: string;
    notes: string;
    fromBucket: string;
    toBucket: string;
    transferAmount: number;
    credit: number;
    debit: number;
    balance: number;
}