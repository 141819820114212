export class OrderItems {
    id: number;
    salesOrderNo: string;
    orderCode: string;
    brand: string;
    category: string;
    brandCategory: string;
    itemDescription: string;
    size: string;
    fullDescription: string;
    cases: number;
    caseCost: number;
    extCaseCost: number;
    csGrossLbs: number;
    productType: string;
    ti: number;
    hi: number;
    tiHi: string;
    csPerPallet: number;
    purchaseCaseCost: number;
    billing: number;
    billing1: number;
    terms: number;
    terms1: number;
    commission1: number;
    commission11: number;
    commission2: number;
    commission21: number;
    kalcor: number;
    kalcor1: number;
    marketing: number;
    marketing1: number;
    cndFrtCostPallet: number;
    cndFrtCostCase: number;
    storageCostPallet: number;
    storageCostCase: number;
    duty: number;
    clearingCs: number;
    clearingCost: number;
    palletPerTruck: number;
    freightCs: number;
    bbDate1: Date;
    bbDate2: Date;
    bbDate3: Date;
    bbDate4: Date;
    bbDate5: Date;
    bbDate6: Date;
    bbDate7: Date;
    bbDate8: Date;
    bbDate9: Date;
    bbDate10: Date;
    case1: number;
    case2: number;
    case3: number;
    case4: number;
    case5: number;
    case6: number;
    case7: number;
    case8: number;
    case9: number;
    case10: number;
    lotNo1: string;
    lotNo2: string;
    lotNo3: string;
    lotNo4: string;
    lotNo5: string;
    lotNo6: string;
    lotNo7: string;
    lotNo8: string;
    lotNo9: string;
    lotNo10: string;
    purchased: number;
    shipped: number;
    yearMonth: string;
    yearMonthId: number;
    lockDate: Date;
    lockYN: string;
    csGrossKg: number;
    exchangeActual: number;
    exchangeBudget: number;
    invoiced: number;
    royalty: number;
    royalty1: number;
    currencyCode: string;
    csNetKg: number;
    csNetLbs: number;
    cndFrtCostLb: number;
    fobDifference: number;
    palletType: string;
    gstHst: number;
}