import { Component, OnInit } from '@angular/core';
import { TermsService } from '../../../services/terms.service';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
@Component({
  selector: 'app-terms-list',
  templateUrl: './terms-list.component.html',
  styleUrls: ['./terms-list.component.scss']
})

export class TermsListComponent implements OnInit {
  terms: any;
  page = 1;
  pageSize = 30;
  successMessage: string;
  private success = new Subject<string>();
  alertType = "success";
  staticAlertClosed = false;
  constructor(
    private router: Router,
    private termsService: TermsService) { }

  ngOnInit() {
    this.termsService.getAll().subscribe(data => {
      this.terms = data
    },error => {
      this.successMessage = 'Unable to process request';
      this.alertType = "danger";
    });
    setTimeout(() => this.staticAlertClosed = true, 20000);
    this.success.subscribe((message) => this.successMessage = message);
    this.success.pipe(
      debounceTime(5000)
    ).subscribe(() => this.successMessage = null); 
  }
  onRowClicked(terms) {
    this.router.navigate(['/other/terms-edit', terms.id]);
  }

}
