import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../guard/auth.guard';
import { 
  UserListComponent,
  UserEditComponent,
  UserResolver
} from './user/index';
import { 
  RolesListComponent, 
  RolesEditComponent, 
  RolesResolver 
} from './roles/index';

const routes: Routes = [
  {
      path: 'admin',
      canActivate: [AuthGuard],
      data: { section: 'admin' },
      children: [
          {
              path: 'user-list', component: UserListComponent,
              canActivate: [AuthGuard]
          },
          {
              path: 'user-edit/:id', component: UserEditComponent,
              canActivate: [AuthGuard],
              resolve: { dataUser: UserResolver }
          },
          {
            path: 'roles-list', component: RolesListComponent,
            canActivate: [AuthGuard],
            resolve: { dataRoles: RolesResolver }
          },
          {
            path: 'roles-edit/:id', component: RolesEditComponent,
            canActivate: [AuthGuard],
            resolve: { dataRoles: RolesResolver}
          }
      ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdminRoutingModule { }
