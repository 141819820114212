import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { OrderService } from '../../services/order.service'
import { Order } from 'src/app/models/order-model';
@Injectable()

export class ProfitLossResolver implements Resolve<Order> {
  constructor(private orderService: OrderService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Order> {
    const idField = 'id';
    const id =  parseInt(route.params[idField], 10);
    if (id) {
      return this.orderService.getItems(id);
    }
    return this.orderService.getFilteredList(0, 'Invoiced','', 0, 0, 0, 1, 30);
  }
}
