import { Component, OnInit } from '@angular/core';
import { SubCategoryService } from '../../../services/sub-category.service';
import { Router, ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-sub-category-list',
  templateUrl: './sub-category-list.component.html',
  styleUrls: ['./sub-category-list.component.scss']
})
export class SubCategoryListComponent implements OnInit {
  category: any;
  activeItemSelected: string;
  vendorSelected: number;
  vendorOptions:[];
  brandSelected: number;
  brandOptions:[];
  categorySelected: number;
  categoryOptions:[];
  page: number;
  pageSize: number;
  count: number;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private subCategorySvc: SubCategoryService) { }

  ngOnInit() {
    this.activeItemSelected = 'Active';
    this.vendorSelected = 0;
    this.brandSelected = 0;
    this.categorySelected = 0;
    this.page = 1;
    this.pageSize = 30;
    this.count = 0;
    this.route.data.subscribe(data => {
      this.category = data.dataCategory.item;
      this.vendorOptions = data.dataCategory.item2;
      this.brandOptions = data.dataCategory.item3;
      this.categoryOptions = data.dataCategory.item4; 
    });
  }
  
  loadPage() {
    this.subCategorySvc.getFilteredListing(this.activeItemSelected, this.vendorSelected, this.brandSelected, this.categorySelected, this.page, this.pageSize).subscribe(data => {
      this.category = data.item;
      this.vendorOptions  = data.item2;
      this.brandOptions = data.item3;
      this.categoryOptions = data.item4;
      this.count = data.count;
    });
  }
  
  reset() {
    this.page = 1;
    this.pageSize = 30;
    this.activeItemSelected = 'Active';
    this.brandSelected = 0;
    this.vendorSelected = 0;
    this.categorySelected = 0;
    this.loadPage();    
  }  
  onRowClicked(category) {
    this.router.navigate(['/items/sub-category-edit',  category.id]);
  }
}
