import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ShipToAddress } from '../../../../models/shipto.model';
import { Location } from '../../../../models/location-model';
import { Customer } from '../../../../models/customer.model';
import { ShipToAddressService } from '../../../../services/ship-to-address.service';
import { CustomerService } from '../../../../services/customer.service';
import { LocationService } from '../../../../services/location.service';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { Region } from 'src/app/models/region-model';
import { RegionService } from 'src/app/services/region.service';
import { TypeOfAccount } from 'src/app/models/type-of-account-model';
import { TypeOfAccountService } from 'src/app/services/type-of-account.service';
import { ShipToRegions } from 'src/app/models/shipto-regions.model';
import { ShipToRegionsService } from 'src/app/services/ship-to-regions.service';

@Component({
  selector: 'app-ship-to-address-edit',
  templateUrl: './ship-to-address-edit.component.html',
  styleUrls: ['./ship-to-address-edit.component.scss']
})
export class ShipToAddressEditComponent implements OnInit {
  shipTo: ShipToAddress;
  shipToForm: UntypedFormGroup;
  locations: Location;
  customer: Customer;
  typeOfAcct: TypeOfAccount[];
  region: Region[];
  regionId: ShipToRegions[];
  ships: any;
  successMessage: string;
  counter: number;
  statusSelected = '';
  checkBox = [];
  alertType = "success";
  private success = new Subject<string>();
  staticAlertClosed = false;
  constructor(private route: ActivatedRoute,
    private router: Router,
    private fb: UntypedFormBuilder,
    private shipToSvc: ShipToAddressService,
    private custSvc: CustomerService,
    private locationSvc: LocationService,
    private typeOfAcctSvc: TypeOfAccountService,
    private regionSvc: RegionService,
    private shipToRegionSvc: ShipToRegionsService,
  ) { }

  ngOnInit() {
    this.shipToForm = this.fb.group({
      activeItem: [''],
      vendor: [''],
      company: ['', Validators.required],
      address: [''],
      city: [''],
      stateProvince: [''],
      countryRegion: [''],
      zipPostalCode: [''],
      fullName: [''],
      jobTitle: [''],
      businessPhone: [''],
      faxNumber: [''],
      mobileNumber: [''],
      eMail: [''],
      webPage: [''],
      comments: [''],
      fullAddress: [''],
      customerOrderType: [''],
      appointments: [''],
      customerId: ['', Validators.required],
      loadingTime: [''],
      locationId: ['', Validators.required],
      notes: [''],
      latitude: [''],
      longitude: [''],
      accountTypeId: [''],
    });
    this.ships = [];
    this.custSvc.getAll().subscribe(data => {
      this.customer = data;
      // this.custSvc.getFilteredList('Active', '', 30, 1).subscribe(data => {
      // this.customer = data.item2;
      this.locationSvc.getAll().subscribe(data2 => {
        this.locations = data2;
        this.typeOfAcctSvc.getAll().subscribe(typeOfAcct => {
          this.typeOfAcct = typeOfAcct;
          this.regionSvc.getAll().subscribe(region => {
            this.region = region;
            this.checkBox = [];
            this.region.forEach(data => {
              this.checkBox.push(false)
            });
            this.route.data.subscribe(data4 => {
              this.shipTo = data4.dataShipTo;
              this.shipToRegionSvc.getRegion(this.shipTo.id).subscribe(data => {
                this.regionId = data;
                this.shipToSvc.getFilteredListing('Active', this.shipTo.id, this.shipTo.customerId, 1, 999).subscribe(data => {
                  data.item.forEach(e => {
                    const shipto = {
                      ship: e.shipCompany,
                      province: e.shipProvince,
                      region: e.shipRegion,
                      shipRegionId: e.shipRegionId,
                      customer: e.customerId,
                      checkBox: 1  
                    };
                    this.ships.push(shipto);
                    if (e.shipRegionId > 0) {
                      const index = this.region.findIndex(data => data.regionId == e.shipRegionId);
                      if (index > -1) {
                        this.checkBox[index] = true;
                      }
                    }
                  })
                })
              })
              this.fillFormData();
              // this.onChanges();
            });
          });
        });
      });
    });

    setTimeout(() => this.staticAlertClosed = true, 20000);

    this.success.subscribe((message) => this.successMessage = message);
    this.success.pipe(
      debounceTime(5000)
    ).subscribe(() => this.successMessage = null);
  }

  fillFormData() {
    this.shipToForm.setValue({
      activeItem: this.shipTo.activeItem,
      vendor: this.shipTo.vendor,
      company: this.shipTo.company,
      address: this.shipTo.address,
      city: this.shipTo.city,
      stateProvince: this.shipTo.stateProvince,
      countryRegion: this.shipTo.countryRegion,
      zipPostalCode: this.shipTo.zipPostalCode,
      fullName: this.shipTo.fullName,
      jobTitle: this.shipTo.jobTitle,
      businessPhone: this.shipTo.businessPhone,
      faxNumber: this.shipTo.faxNumber,
      mobileNumber: this.shipTo.mobileNumber,
      eMail: this.shipTo.eMail,
      webPage: this.shipTo.webPage,
      comments: this.shipTo.comments,
      fullAddress: this.shipTo.fullAddress,
      customerOrderType: this.shipTo.customerOrderType,
      appointments: this.shipTo.appointments,
      customerId: this.shipTo.customerId,
      loadingTime: this.shipTo.loadingTime,
      locationId: this.shipTo.locationId,
      notes: this.shipTo.notes,
      latitude: this.shipTo.latitude,
      longitude: this.shipTo.longitude,
      accountTypeId: this.shipTo.accountTypeId,
    });
  }
  // onChanges(): void {
  //   this.shipToForm.get('activeItem').valueChanges.subscribe(val => {
  //     this.custSvc.getFilteredList(val, '', 30, 1).subscribe(data => {
  //       this.customer = data.item2;
  //       this.shipToForm.controls.customerId.setValue('');
  //     });
  //   })
  // }
  splitNumbers(ids) {
    let ret = [];
    if (ids) {
      let retCount = 0;
      const tempSplit = ids.split(',');
      if (tempSplit.length > 0) {
        tempSplit.forEach(id => {
          if (id) {
            ret.push(id * 1);
          }
        })
      }
    }
    return ret;
  }
  joinNumbers() {
    let ret = [];
    this.regionId.forEach(r => {
      if (r.regionId) {
        ret.push(r.regionId)
      }
    })
    return ret;
  }
  onSubmit() {
    const cbox = this.checkBox.some(x => x === true);
    if (!cbox) {
      this.success.next('Please select a region.');
      this.alertType = "danger";
      return;
    }
    if (this.shipToForm.invalid) {
      this.success.next('Please enter required fields.');
      this.alertType = "danger";
      return;
    }
    this.shipTo.activeItem = this.shipToForm.controls.activeItem.value;
    this.shipTo.vendor = this.shipToForm.controls.vendor.value;
    this.shipTo.company = this.shipToForm.controls.company.value;
    this.shipTo.address = this.shipToForm.controls.address.value;
    this.shipTo.city = this.shipToForm.controls.city.value;
    this.shipTo.stateProvince = this.shipToForm.controls.stateProvince.value;
    this.shipTo.countryRegion = this.shipToForm.controls.countryRegion.value;
    this.shipTo.zipPostalCode = this.shipToForm.controls.zipPostalCode.value;
    this.shipTo.fullName = this.shipToForm.controls.fullName.value;
    this.shipTo.jobTitle = this.shipToForm.controls.jobTitle.value;
    this.shipTo.businessPhone = this.shipToForm.controls.businessPhone.value;
    this.shipTo.faxNumber = this.shipToForm.controls.faxNumber.value;
    this.shipTo.mobileNumber = this.shipToForm.controls.mobileNumber.value;
    this.shipTo.eMail = this.shipToForm.controls.eMail.value;
    this.shipTo.webPage = this.shipToForm.controls.webPage.value;
    this.shipTo.comments = this.shipToForm.controls.comments.value;
    this.shipTo.fullAddress = this.shipToForm.controls.fullAddress.value;
    this.shipTo.customerOrderType = this.shipToForm.controls.customerOrderType.value;
    this.shipTo.appointments = this.shipToForm.controls.appointments.value;
    this.shipTo.customerId = this.shipToForm.controls.customerId.value;
    this.shipTo.loadingTime = this.shipToForm.controls.loadingTime.value;
    this.shipTo.locationId = this.shipToForm.controls.locationId.value;
    this.shipTo.notes = this.shipToForm.controls.notes.value;
    this.shipTo.latitude = this.shipToForm.controls.latitude.value;
    this.shipTo.longitude = this.shipToForm.controls.longitude.value;
    this.shipTo.accountTypeId = this.shipToForm.controls.accountTypeId.value;
    this.counter = 0;
    this.shipToSvc.update(this.shipTo).subscribe(data => {
      this.deleteShipToRegions();
      this.success.next(`Successfully changed.`);
      this.alertType = "success";
    }, error => {

    });
  
  }
  deleteShipToRegions() {
    this.counter++;
    if(this.counter > this.regionId.length) {
      this.counter = 0;
      this.updateShipToRegions();
    } else {
      const index = this.counter -1;
      this.shipToRegionSvc.remove(this.regionId[index].shipToRegionId).subscribe(data => {
        this.deleteShipToRegions();
      }, error => {
      this.deleteShipToRegions();
      });
    }
  }
  updateShipToRegions() {
    this.counter++;
    if (this.counter <= this.region.length) {
      const index = this.counter -1;
      if (this.checkBox[index] === false) {
        this.updateShipToRegions();
        return;
      }
      let x = new ShipToRegions();
      x.shipToRegionId = 0;
      x.shipToId = this.shipTo.id;
      x.customerId = this.shipTo.customerId;
      x.regionId = this.region[index].regionId;
      this.shipToRegionSvc.create(x).subscribe(data => {
        this.updateShipToRegions();
      });
    } else {
      this.shipToRegionSvc.getRegion(this.shipTo.id).subscribe(data => {
        this.regionId = data;
      })
    }
  }
  onDelete() {
    if (this.shipTo.id) {
      this.shipToSvc.remove(this.shipTo.id).subscribe(data => {
        this.router.navigate(['/customers/ship-to-address-list']);
      });
    }
  }
}
