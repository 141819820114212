import { Component, OnInit } from '@angular/core';
import { PalletTypeService } from '../../../services/pallet-type.service';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-pallet-type-list',
  templateUrl: './pallet-type-list.component.html',
  styleUrls: ['./pallet-type-list.component.css']
})
export class PalletTypeListComponent implements OnInit {
  palletType: any;
  page = 1;
  pageSize = 30;
  successMessage: string;
  private success = new Subject<string>();
  alertType = "success";
  staticAlertClosed = false;
  
  constructor(
    private router: Router,
    private palletTypeSvc: PalletTypeService
  ) { }

  ngOnInit(): void {
    this.palletTypeSvc.getAll().subscribe(data => {
      this.palletType = data
    },error => {
      this.successMessage = 'Unable to process request';
      this.alertType = "danger";
    });
    setTimeout(() => this.staticAlertClosed = true, 20000);
    this.success.subscribe((message) => this.successMessage = message);
    this.success.pipe(
      debounceTime(5000)
    ).subscribe(() => this.successMessage = null); 
  }
  onRowClicked(pallet) {
    this.router.navigate(['/other/pallet-type-edit', pallet.palletTypeId]);
  }

}
