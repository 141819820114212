import { Component,OnInit, ChangeDetectorRef, ViewChild, ElementRef,ViewEncapsulation, OnDestroy, Inject  } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, map, mergeMap, takeUntil } from 'rxjs/operators';
import { MsalService, MsalBroadcastService, MSAL_GUARD_CONFIG, MsalGuardConfiguration } from '@azure/msal-angular';
import { InteractionStatus, RedirectRequest } from '@azure/msal-browser';
import { Subject } from 'rxjs';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/compiler';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit, OnDestroy  {
  title = 'kalcor';
  isExpanded = false;
  activeSideNav: any;
  activeSide = '';
  dashboard = false;
  sales = false;
  customClearing = false;
  accounting = false;
  customer = false;
  vendor = false;
  other = false;
  items = false;
  products = false;
  productListing = false;
  projections = false;
  reports = false;
  portal = false;
  customerId: number;
  vendorId: number;
  brokerId: number;
  disabled = false;
  administrationRole = false;
  trafficRole = false;
  accountingRole = false;
  marketingRole = false;
  vendorRole = false;
  projectionsRole = false;
  loginPressed = false;
  isCollapsed = false;
  panelOpenState = false;
  isIframe = false;
  loginDisplay = false;
  private readonly _destroying$ = new Subject<void>();
  @ViewChild('configurationSideNav', { static: true }) configurationSideNav: ElementRef;

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private changeDetector: ChangeDetectorRef,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private authService: MsalService,
    private broadcastService: MsalBroadcastService
  ) {
      this.getUserInfo();
          // Setup Side Nav Swapper
        this.router.events.pipe(
          filter(event => event instanceof NavigationEnd),
          map(() => this.activatedRoute),
          map((route) => {
            while (route.firstChild) {
              route = route.firstChild;
            }
            return route;
          })).pipe(
          filter((route) => route.outlet === 'primary'),
          mergeMap((route) => route.data)
        ).subscribe((event) => {
          this.activeSide = event.section;
          // Add additional sections here
          switch (event.section) {
            case 'dashboard':
              this.activeSideNav = undefined;
              this.isExpanded = false;
              this.refresh();
              break;
              case 'sales':
                this.activeSideNav = undefined;
                this.isExpanded = false;
                this.refresh();
                break;              
            case 'accounts':
              this.activeSideNav = this.configurationSideNav;
              this.isExpanded = false;
              this.refresh();
              break;
            case 'products':
              this.activeSideNav = this.configurationSideNav;
              this.isExpanded = false;
              this.refresh();
              break;
            default:
              this.activeSideNav = this.configurationSideNav;
              this.isExpanded = false;
              this.refresh();
              break;
          }

        });

  }
  ngOnInit() {
    this.isIframe = window !== window.parent && !window.opener;
    this.broadcastService.inProgress$
    .pipe(
      filter((status: InteractionStatus) => status === InteractionStatus.None),
      takeUntil(this._destroying$)
    )
    .subscribe(() => {
      this.setLoginDisplay();
    })
  }
  login() {
    if (this.msalGuardConfig.authRequest){
      this.authService.loginRedirect({...this.msalGuardConfig.authRequest} as RedirectRequest);
    } else {
      this.authService.loginRedirect();
    }
  }
  logout() { // Add log out function here
    this.authService.logoutRedirect({
      postLogoutRedirectUri: 'http://localhost:4200'
    });
  }
  setLoginDisplay() {
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
  }
  refresh() {
    this.isExpanded = !this.isExpanded;
    this.changeDetector.detectChanges();
  }
  
  public beforeChange($event) {

    if ($event.panelId === 'menu-dashboard') {
      this.router.navigate(['dashboard']);
    }
  }
  onlogged(e) {
    if (this.administrationRole) {
      this.sales = true;
      this.customClearing = true;
      this.accounting = true;
      this.customer = true;
      this.vendor = true;
      this.other = true;
      this.items = true;
      this.products = true;
      this.productListing = true;
      this.projections = true;
      this.reports = true;
      this.portal = true;
      }
    this.loginPressed = false;
  }
  getUserInfo() {
    const user = JSON.parse(sessionStorage.getItem('currentUser'));
    if (user === null) { return};
    this.dashboard = user.dashboard === 1 ? true : false;
    this.sales = user.sales === 1 ? true : false;
    this.customClearing = user.customClearing ? true : false;
    this.accounting = user.accounting ? true : false;
    this.customer = user.customer ? true : false;
    this.vendor = user.vendor ? true : false;
    this.other = user.other ? true : false;
    this.items = user.items ? true : false;
    this.products = user.products ? true : false;
    this.productListing = user.productListing ? true : false;
    this.projections = user.projections ? true : false;
    this.reports = user.reports ? true : false;
    this.portal = user.portal ? true : false;
    this.customerId = user.customerId ? user.customerId : 0;
    this.vendorId = user.vendorId ? user.vendorId : 0;
    this.brokerId = user.brokerId ? user.brokerId : 0;
    this.administrationRole = user.administrationRole ? true : false;
    this.trafficRole = user.trafficRole ? true : false;
    this.accountingRole = user.accountingRole ? true : false;
    this.marketingRole = user.marketingRole ? true : false;
    this.vendorRole = user.vendorRole ? true : false;
    this.projectionsRole = user.projectionsRole ? true : false;
    
  }
  turnLoginOn() {
    this.router.navigate(['dashboard'])
    this.loginPressed = true;
  }
  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }
}
