import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { KalcorData } from 'src/app/models-azure/kalcor-data.model';
import { DataLink } from 'src/app/models/data-link.model';
import { DataLinkService } from 'src/app/services/data-link.service';
import { Vendor } from 'src/app/models/vendor-model';
import { VendorService } from 'src/app/services/vendor.service';
import { WeekKalcorService } from 'src/app/services/week-kalcor.service';
import { KalcorService } from 'src/app/services-azure/kalcor.service';
import { OrderService } from 'src/app/services/order.service';
import { BrokerService } from 'src/app/services/broker.service';
import { Transfer } from 'src/app/models/bucket.model';


@Component({
  selector: 'app-kalcor-data',
  templateUrl: './kalcor-data.component.html',
  styleUrls: ['./kalcor-data.component.scss']
})
export class KalcorDataComponent implements OnInit {
  buttonLabel = 'Add more Vendors';
  vendorYear = [];
  items = [];
  buttonClicker = [];
  buttonRemover = [];
  selectedVendor: number;
  processIndexItem: number;
  processTotalCount: number;
  processIndex: number;
  processIndexCases: number;
  processIndexIncoming: number;
  processIndexIncomingItems: number;
  processIndexOutgoing: number;
  processIndexOutgoingItems: number;
  processIndexTransfering: number;
  processIndexTransferingItems: number;
  firstPost = true;
  vendorId: number;
  totalCount: number;
  itemCounter: number;
  itemCount: number;
  processIndexMarketing: number;
  dataLink: DataLink;
  vendor: Vendor[];
  dataLinks: DataLink[];
  yearRequested: any;
  processVendIndex: number;
  page: number;
  pageSize = 35;
  count: number;
  periods = [];
  kstLineCount = 0;
  kstTotalExtension = 0;
  order: any;
  orderType = 'Both Retail/Food Service';
  bucket = 'Kalcor';
  report = 'Actual';
  monthSelected: any;
  brokerSelected: number;
  new: any;
  balfwd: number;
  balanceForwardCredit: number;
  balanceForwardActual: number;
  balanceForwardBudget: number;
  balanceForwardTransfer: number;
  creditData: any;
  balFwdBeginningMonth = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  balFwdAfterIncomingMonth = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  balFwdOutgoingMonth = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  balFwdTransferingMonth = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  balEndCurrentMonth = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  incoming = [[],[],[],[],[],[],[],[],[],[],[],[],[],[]];
  outgoing = [[],[],[],[],[],[],[],[],[],[],[],[],[],[]];
  transfering = [[],[],[],[],[],[],[],[],[],[],[],[],[],[]];
  transfer: Transfer[];
  periodName = '';

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private dataLinkService: DataLinkService,
    private vendorService: VendorService,
    private weekKalcorService: WeekKalcorService,
    private kalcorService: KalcorService,
    private orderService: OrderService
  ) {}

  ngOnInit(): void {
    this.periods = [];
    this.monthSelected = [];
    this.monthSelected.push("1");
    this.monthSelected.push("12");
    this.yearRequested = this.route.snapshot.params['year'];    
    this.weekKalcorService.getYear(this.yearRequested).subscribe(data => {
      this.periods = data;
    })
    this.route.data.subscribe(data => {
      this.vendorYear = data.dataList.item;
      this.page = data.dataList.page;
      this.count = data.dataList.count;
      for (let i = 0; i < this.vendorYear.length; i++) {
        this.buttonClicker.push(false);
      }
    })
    this.vendorService.getCompany().subscribe(vend => {
      this.vendor = vend;
    })
  }
  loadPage() {
    this.dataLinkService.getList(this.yearRequested, this.page, this.pageSize).subscribe(data => {
      this.vendorYear = data.item;
      this.page = data.page;
      this.count = data.count;
  
      for (let i = 0; i < this.vendorYear.length; i++) {
        this.buttonClicker.push(false);
      }
  
    });
  }
  run(index: number) {
    this.totalCount = 0;
    this.processIndex = index;
    this.selectedVendor = index;
    this.vendorId = this.vendorYear[this.selectedVendor].vendorId;
    this.firstPost = true;
    this.getItems()
  }
  getItems() {
    this.kalcorService.getFilter(this.yearRequested, this.vendorId, 0, 0).subscribe(data => {
      this.items = data.item;
      if (this.items.length > 0) {
        this.processIndexItem = -1;
        this.deleteItems();
      } else {
        this.getKalcorData();
      }
    }, error => {
      alert('Error from azzure:' + error.message)
    }) 
  }
  getKalcorData() {
    this.vendorYear[this.processIndex].status = "Updating";
    this.totalCount = 0;
    this.balfwd = 0;
    this.incoming = [[],[],[],[],[],[],[],[],[],[],[],[],[],[]];
    this.outgoing = [[],[],[],[],[],[],[],[],[],[],[],[],[],[]];
    this.transfering = [[],[],[],[],[],[],[],[],[],[],[],[],[],[]];
    this.orderService.getBucketList(this.vendorYear[this.processIndex].salesYear, this.monthSelected, this.vendorYear[this.processIndex].vendorId, 0, 0, this.bucket, this.report, this.orderType).subscribe(data => {
      this.order = data.order; 
      this.creditData = data.creditData;
      this.transfer = data.transferData;
      this.balanceForwardCredit = data.balanceForwardCredit;
      this.balanceForwardActual = data.balanceForwardActual;
      this.balanceForwardBudget = data.balanceForwardBudget;
      this.balanceForwardTransfer = data.balanceForwardTransfer;
      this.balfwd = this.balanceForwardBudget - this.balanceForwardCredit + this.balanceForwardTransfer;
      this.loadArrays();
      this.totalCount = 0;
      this.processIndexIncoming = -1;
      this.processIndexIncomingItems = -1;
      this.processIndexOutgoing = -1;
      this.processIndexOutgoingItems = -1;
      this.processIndexTransfering = -1;
      this.processIndexTransferingItems = -1;
      this.kstLineCount = 0;
      this.kstTotalExtension = 0;
      if (this.firstPost == true) {
        this.insertBalFwd();
        this.firstPost = false;
      } 
      this.initializeIncoming();
    })
  }
  insertBalFwd() {
    const year = this.vendorYear[this.processIndex].salesYear;
    let firstDate = new Date(Date.UTC(year, 0, 2, 0, 0, 0));
    let dateFormat = firstDate.toISOString();
    let x = new KalcorData();
    x.id = 0;
    x.category = 'Balance Forward';
    x.period = this.getPeriodName(dateFormat);
    x.year = year;
    x.date = firstDate;
    x.vendor = this.vendorYear[this.processIndex].vendorName;
    x.vendorId = this.vendorYear[this.processIndex].vendorId;
    x.balance = this.balFwdBeginningMonth[0];
    this.create(x);
  }
  loadArrays() {
    this.order.forEach(element => {
      // Splits items into incoming and outgoing arrays by period
      const period = this.getPeriod(element.shipmentDate);
      element.broker = this.getBroker(element.brokerId, element.broker, element.broker2Id, element.broker2);
      this.incoming[period].push(element);
    });
    this.transfer.forEach(element => {
       const period = this.getPeriod(element.transferDate);
       if (element.fromBucket == this.bucket) {
        element.debit = element.transferAmount;
      } else {
        element.credit = element.transferAmount;
      }
       this.transfering[period].push(element);
    });
  }
  getBroker(id, broker1, id2, broker2) {
    let broker = '';
    broker1 = typeof broker1 == 'undefined' || !broker1 ? '' : broker1;
    broker2 = typeof broker2 == 'undefined' || !broker2 ? '' : broker2;
    if (this.brokerSelected > 0) {
      if (this.brokerSelected === id) {
        broker2 = '';
      }
      if (this.brokerSelected === id2) {
        broker1 = '';
      }
    }

    if (broker1 != '' && broker2 != '') {
      broker = broker1 + '/' + broker2
    } else {
      broker += broker1;
      broker += broker2;
    }
   
    return broker;
  }
  getPeriod(date) {
    let retValue;
    const baseDate = date.substring(0, 10);
    for (let i = 0; i < this.periods.length; i++) {
      if (baseDate >= this.periods[i].weekStartDate && baseDate <= this.periods[i].weekEndDate) {
        let period = this.periods[i].period;
        retValue = period;
      }
    }
    return retValue;
  }
  getPeriodName(date) {
    let retValue;
    const baseDate = date.substring(0, 10);
    for (let i = 0; i < this.periods.length; i++) {
      if (baseDate >= this.periods[i].weekStartDate && baseDate <= this.periods[i].weekEndDate) {
        let periodYear = "0" + this.periods[i].period + "-PERIOD";             
        if (this.periods[i].period > 9) {
          periodYear = this.periods[i].period + "-PERIOD";
        } 
        retValue = periodYear;
      }
    }
    return retValue;
  }
  initializeIncoming() {
    //begin inserting data into azure starting with incoming array
    this.processIndexIncoming++;
    if (this.processIndexIncoming >= this.incoming.length) {
      this.processIndexOutgoing = -1;
      // this.updateDataLink();
      this.initializeTransfering();
    } else {
      if (this.incoming[this.processIndexIncoming].length == 0) {
        this.initializeIncoming();
      }
      this.processIndexIncomingItems = -1;
      this.insertIncoming();
    }
  }
  insertIncoming() {
    //insert into azure incoming array one subarray at a time
    this.processIndexIncomingItems++;
    if (this.processIndexIncomingItems >= this.incoming[this.processIndexIncoming].length) {
      this.initializeIncoming();
    } else {
      this.totalCount++;
      this.vendorYear[this.processIndex].counter = this.totalCount;
      const shipDate = this.incoming[this.processIndexIncoming][this.processIndexIncomingItems].shipmentDate;
      let dateFormat = new Date(this.incoming[this.processIndexIncoming][this.processIndexIncomingItems].shipmentDate);
      let x = new KalcorData();
      x.id = 0;
      x.category = 'Incoming';
      x.period = this.getPeriodName(shipDate);
      x.year = dateFormat.getFullYear();
      x.status = this.incoming[this.processIndexIncoming][this.processIndexIncomingItems].orderStatus;
      x.type = this.incoming[this.processIndexIncoming][this.processIndexIncomingItems].orderType;
      x.no = this.incoming[this.processIndexIncoming][this.processIndexIncomingItems].salesOrderNo;
      x.purchaseOrder = this.incoming[this.processIndexIncoming][this.processIndexIncomingItems].purchaseOrder;
      x.date = shipDate;
      x.customer = this.incoming[this.processIndexIncoming][this.processIndexIncomingItems].billTo;
      x.vendor = this.incoming[this.processIndexIncoming][this.processIndexIncomingItems].vendor;
      x.vendorId = this.incoming[this.processIndexIncoming][this.processIndexIncomingItems].vendorId;
      x.shipTo = this.incoming[this.processIndexIncoming][this.processIndexIncomingItems].shipTo;
      x.broker = this.incoming[this.processIndexIncoming][this.processIndexIncomingItems].broker;
      x.salesAmount = this.incoming[this.processIndexIncoming][this.processIndexIncomingItems].salesAmount;
      x.credit = this.incoming[this.processIndexIncoming][this.processIndexIncomingItems].budgetAmount;
      x.balance = 0;

      const ext = x.balance === null ? 0 : x.balance;
      this.kstTotalExtension += ext;
      this.kstLineCount += ext > 0 ? 1 : 0;
      
      this.create(x);
      this.firstPost = false;
      this.insertIncoming(); 
    }
  }
  create(row) {
    this.kalcorService.create(row).subscribe(data => {
    })
  }
  initializeTransfering() {
    //continue inserting data into azure now dealing with transferring array
    this.processIndexTransfering++;
    if (this.processIndexTransfering >= this.transfering.length) {
      //all arrays have been posted to azure update kst datalink table
      this.updateDataLink();
    } else {
      if (this.transfering[this.processIndexTransfering].length == 0) {
        this.initializeTransfering();
      }
      this.processIndexTransferingItems = -1;
      this.insertTransfering();
    }
  }
  insertTransfering() {
    //insert into azure transferring array one subarray at a time
    this.processIndexTransferingItems++;
    if (this.processIndexTransferingItems >= this.transfering[this.processIndexTransfering].length) {
      this.initializeTransfering();
    } else {
      this.totalCount++;
      this.vendorYear[this.processIndex].counter = this.totalCount;
      const transferDate = this.transfering[this.processIndexTransfering][this.processIndexTransferingItems].transferDate;
      const bucketFrom = this.transfering[this.processIndexTransfering][this.processIndexTransferingItems].fromBucket;
      const bucketTo = this.transfering[this.processIndexTransfering][this.processIndexTransferingItems].toBucket;
      let dateFormat = new Date(this.transfering[this.processIndexTransfering][this.processIndexTransferingItems].transferDate);
      let x = new KalcorData();
      x.id = 0;
      x.category = 'Transfering';
      x.period = this.getPeriodName(transferDate);
      x.year = dateFormat.getFullYear();
      x.status = this.transfering[this.processIndexTransfering][this.processIndexTransferingItems].transferStatus;
      x.type = this.transfering[this.processIndexTransfering][this.processIndexTransferingItems].orderType1;
      x.no = this.transfering[this.processIndexTransfering][this.processIndexTransferingItems].transferNo;
      x.date = transferDate;
      x.customer = this.transfering[this.processIndexTransfering][this.processIndexTransferingItems].billTo;
      x.vendor = this.transfering[this.processIndexTransfering][this.processIndexTransferingItems].vendor;
      x.vendorId = this.transfering[this.processIndexTransfering][this.processIndexTransferingItems].vendorId;
      x.credit = this.transfering[this.processIndexTransfering][this.processIndexTransferingItems].credit;
      x.debit = this.transfering[this.processIndexTransfering][this.processIndexTransferingItems].debit;
      x.balance = 0;
      x.notes = this.transfering[this.processIndexTransfering][this.processIndexTransferingItems].notes;
      x.bucketTransfer = bucketFrom + " " + "to" + " " + bucketTo;

      const ext = x.balance === null ? 0 : x.balance;
      this.kstTotalExtension += ext;
      this.kstLineCount += ext > 0 ? 1 : 0;

      this.create(x);
      this.insertTransfering(); 
    }
  }
  deleteItems() {
    this.vendorYear[this.processIndex].status = "Deleting";
    this.totalCount++;
    this.vendorYear[this.processIndex].counter = this.totalCount;
    this.processIndexItem++;
    if (this.processIndexItem < this.items.length) {
      this.kalcorService.remove(this.items[this.processIndexItem].id).subscribe(data => {
        this.deleteItems();
      }, error => {
        alert('Error received from Azure: ' + error.message);
      })
    } else {
      this.getItems();
    }
  }
  updateDataLink() {
    this.dataLink = new DataLink();
    this.vendorYear[this.processIndex].status = "Completed";
    this.buttonClicker[this.processIndex] = false;
    const todaysDate = new Date();
    this.dataLink.dataLinkId = this.vendorYear[this.processIndex].dataLinkId;
    this.dataLink.lastUpdate = todaysDate;
    this.vendorYear[this.processIndex].lastUpdate = todaysDate;
    this.dataLink.userId = this.getUserId();
    this.vendorYear[this.processIndex].name = this.getUserName();
    this.dataLinkService.update(this.dataLink).subscribe(data => {
      alert("All done.");
    })
  }
  getUserId() {
    let userId = 0;
    const currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
    if (currentUser) {
      userId = currentUser.id;  
    }
    return userId;
  }
  getUserName() {
    let userName = '';
    const currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
    if (currentUser) {
      userName = currentUser.firstName;  
    }
    return userName;
  }
  addVendors() {
    this.dataLinks = [];
    this.vendor.forEach(vend => {
      const vendIndex = this.vendorYear.findIndex(v => v.vendorId == vend.id);
      if (vendIndex == -1) {
        this.dataLink = new DataLink();
        this.dataLink.salesYear = this.yearRequested * 1;
        this.dataLink.vendorId = vend.id
        this.dataLinks.push(this.dataLink);
      }
    })
    this.processVendIndex = -1;
    this.buttonLabel = 'Adding more vendors ...'
    this.addVend();
  }
  
  addVend() {
    this.processVendIndex++;
    if (this.processVendIndex < this.dataLinks.length) {
      this.dataLinkService.create(this.dataLinks[this.processVendIndex]).subscribe(data => {
        this.addVend();
      }, error => {
        this.addVend();
      })
    } else {
      alert('Added more vendors completed. Press enter to Refresh ');
      window.location.reload();
    }
  }
  
  removeVendor( index: number ) {
    this.selectedVendor = index;
    const venData = this.vendorYear[this.selectedVendor].dataLinkId;
  
    if (venData) {
      if (confirm('Delete this item?'))
      this.dataLinkService.remove(venData).subscribe(data => {
        this.router.navigate(['/portal/marketing-link']);
      });
      }
    }

}
