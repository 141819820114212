import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SorterService {

  constructor() { }
  
  sorter(tobeSorted, name) {

    tobeSorted.sort((a, b) => {
      if (a[name] === null && b[name] === null) {
        return 0;
      }

      if (a[name] !== null && b[name] === null) {
        return 1;
      }

      if (a[name] === null && b[name] !== null) {
        return -1;
      }

      if (a[name] < b[name]) {
        return -1;
      }

      if (a[name] > b[name]) {
        return 1;
      }

      return 0;
    });
    return tobeSorted;
  }
}
