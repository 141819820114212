export class FundsTransfer {
    id: number;
    transferDate: Date;
    transferNo: string;
    billToId: number;
    notes: string;
    transferAmount: number;
    fromBucket: string;
    toBucket: string;
    transferStatus: string;
    vendorId: number;
    orderType1: string;
    orderType2: string;
    orderType3: string;
}