import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Form, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { WeekKalcor } from 'src/app/models/week-kalcor.model';
import { WeekKalcorService } from 'src/app/services/week-kalcor.service';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-week-kalcor-edit',
  templateUrl: './week-kalcor-edit.component.html',
  styleUrls: ['./week-kalcor-edit.component.scss']
})
export class WeekKalcorEditComponent implements OnInit {
title = 'Weeks - Kalcor';
weekKalcor: WeekKalcor[];
weekKalcor1: WeekKalcor;
weeks: WeekKalcor[];
weekKalcorForm: UntypedFormGroup; 
successMessage: string;
private success = new Subject<string>();
alertType = "success";
staticAlertClosed = false;
firstStartDate: any;
firstEndDate: any;
lastEndDate: any;
currentIndex = -1;
lastIndex = 0;
yearCreating = 0;
yearExists = false;
currentYear = 0;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private fb: UntypedFormBuilder,
    private weekKalcorSvc: WeekKalcorService
  ) { }

  ngOnInit(): void {

    this.route.data.subscribe(data => {
      if (data.dataWeekKalcor !== undefined) {
        this.weeks = data.dataWeekKalcor;
        this.currentYear = this.weeks.length > 0 ? this.weeks[0].year : this.currentYear;
        this.title = 'Week Kalcor Edit' + this.currentYear;
      } else {
        this.weeks = [];
        this.weekKalcorSvc.getYears().subscribe(week => {
          if (week.length > 0) {
            const weekIndex = week.length - 1;
            this.currentYear = week[weekIndex] + 1;
          } else {
            const tempDate = new Date();
            this.currentYear = tempDate.getFullYear();
          }
          this.title = 'Week Kalcor New' + this.currentYear;
          this.create();
        })
      }
    });

    setTimeout(() => this.staticAlertClosed = true, 20000);
    this.success.subscribe((message) => this.successMessage = message);
    this.success.pipe(
      debounceTime(5000)
    ).subscribe(() => this.successMessage = null);
  }
  
  create() {
    const unBuilt = this.weeks.length == 0 ? true : false;
    let periodGroup = 1;
    let periodCounter = 2;

    let weekStartDate;
    // Prepare 1st week
    if (unBuilt) {
      weekStartDate = new Date(this.currentYear, 0, 1);
      let week = new WeekKalcor();
      week.period = periodGroup;
      week.week = 1;
      week.weekStartDate = weekStartDate.toISOString().substring(0,10);
      weekStartDate = this.addDays(weekStartDate,6);
      week.weekEndDate = weekStartDate.toISOString().substring(0,10);
      week.weekId = 0;
      week.year = this.currentYear;
      this.weeks.push(week);
    } else {
      weekStartDate = new Date(this.weeks[0].weekEndDate);
    }
    
    //create other weeks
    for ( let loopWeek = 2; loopWeek < 54; loopWeek++) {
      const currentIndex = loopWeek -1;
      const previousIndex = loopWeek -2;
      if (unBuilt) {
        let week = new WeekKalcor();
        week.period = loopWeek == 53 ? 0 : periodGroup;
        week.week = loopWeek;
        weekStartDate = this.addDays(weekStartDate,1);
        week.weekStartDate = weekStartDate.toISOString().substring(0,10);
        week.weekEndDate = weekStartDate.toISOString().substring(0,10);
        week.year = this.currentYear;
        week.weekId = 0;
        this.weeks.push(week);
        if (weekStartDate.getFullYear() != this.currentYear) {
          break;
        }
        periodCounter++;
        if (periodCounter > 4) {
          periodGroup++;
          periodCounter = 1
        }   
      } else {
        if (currentIndex < this.weeks.length) {
          weekStartDate = this.addDays(weekStartDate,1);
          this.weeks[currentIndex].weekStartDate = weekStartDate.toISOString().substring(0,10);
          weekStartDate = this.addDays(weekStartDate,6);
          this.weeks[currentIndex].weekEndDate = weekStartDate.toISOString().substring(0,10);
        }
      }
    }
  }
  addDays(date, days) {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }
  newDateFormat(dateIn) {
    if (dateIn) {
      const dates = dateIn.split('-');
      let dateYear = 0;
      let dateMonth = 0;
      let dateDay = 0;
      if (dates.length == 3) {
        if (!isNaN(dates[0])) {
          dateYear = dates[0] * 1;
        }
        if (!isNaN(dates[1])) {
          dateMonth = dates[1] -1;
        }
        if (!isNaN(dates[2])) {
          dateDay = dates[2] *1;
        }
        return new Date(dateYear, dateMonth, dateDay);
      }
    }
    return new Date(dateIn);
  }
  validate() {
    let errormessage = "";
    this.weeks.forEach(element => {
      if (element.weekStartDate > element.weekEndDate) {
        errormessage = 'Week end date cannot be greater than week start date.';
      }
      let yearCheck = element.weekEndDate.toString().substring(0,4);
      if (yearCheck != this.currentYear.toString()) {
        errormessage = 'Dates must be in same year';
      }
    });
    if (errormessage) {
      this.success.next(errormessage);
      this.alertType = "danger";
    } else {
      this.onSubmit();
    }
  }

  onSubmit() {
    this.currentIndex++;
    if (this.currentIndex >= this.weeks.length) {
      this.currentIndex = -1;
      // this.title = 'Week Kalcor Edit';
      this.success.next('Successfully added.');
      this.alertType = "success";
      return;
    }

    if (this.weeks[this.currentIndex].period == 0 && this.weeks[this.currentIndex].weekId > 0) {
      this.weekKalcorSvc.remove(this.weeks[this.currentIndex].weekId).subscribe(data => {
        this.onSubmit();
      })
    } else {
      if (this.weeks[this.currentIndex].weekId == 0) {
        this.weekKalcorSvc.create(this.weeks[this.currentIndex]).subscribe(data => {
          this.weeks[this.currentIndex].weekId = data.weekId;
          this.onSubmit();
        }, error => {
          // this.success.next('This year already exists');
          // this.alertType = "danger";
          //display error message
          this.currentIndex = -1;
        });
      } else {
        this.weekKalcorSvc.update(this.weeks[this.currentIndex]).subscribe(data => {
          this.onSubmit();
        }, error => {
          // display error message
          // alert('This year already exists');
          this.currentIndex = -1;
        });
      }
    }
  }
  
        
  onDelete() {
    this.currentIndex++;
    if (this.currentIndex < this.weeks.length) {
      if (this.weeks[this.currentIndex].weekId) {
      this.weekKalcorSvc.remove(this.weeks[this.currentIndex].weekId).subscribe(data => {
        this.onDelete();
      }, error => {
        this.currentIndex = -1;
      });
      } 
    } else {
      this.router.navigate(['/other/week-kalcor-list']);
  }

  }
}

