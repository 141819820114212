import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators, UntypedFormArray, FormControl } from '@angular/forms';
import { Subject, fromEventPattern } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { Customer } from '../../models/customer.model';
import { CustomerService } from '../../services/customer.service';
import { ShipToAddress } from '../../models/shipto.model';
import { ShipToAddressService } from '../../services/ship-to-address.service';
import { Vendor } from '../../models/vendor-model';
import { VendorService } from '../../services/vendor.service';
import { PickupLocation } from '../../models/pickup-location.model';
import { PickupLocationService } from '../../services/pickup-location.service';
import { Warehouse } from '../../models/warehouse.model';
import { WarehouseService } from '../../services/warehouse.service';
import { FreightType } from '../../models/freight-type-model';
import { FreightTypeService } from '../../services/freight-type.service';
import { Location} from '../../models/location-model';
import { LocationService } from '../../services/location.service';
import { Transportation } from '../../models/transportation.model';
import { TransportationService } from '../../services/transportation.service';
import { OrderItemsService } from '../../services/order-items.service';
import { Broker } from '../../models/broker.model';
import { BrokerService } from '../../services/broker.service';
import { Term } from '../../models/term-model';
import { TermsService } from '../../services/terms.service';
import { SalesOrder, salesOrderInit } from '../../models/sales-order.model';
import { Big } from 'big.js';
import * as jsPDF from 'jspdf';
import * as html2pdf from 'html2pdf.js';

@Component({
  selector: 'app-order-items-edit',
  templateUrl: './order-items-edit.component.html',
  styleUrls: ['./order-items-edit.component.scss']
})
export class OrderItemsEditComponent implements OnInit {
  order: SalesOrder[];
  orderFilled: SalesOrder[];
  customer: Customer;
  shipTo: ShipToAddress;
  vendor: Vendor;
  pickup: PickupLocation;
  warehouse: Warehouse;
  title = 'SALES ORDER INFORMATION';
  orderForm: UntypedFormGroup;
  items: UntypedFormArray;
  successMessage: string;
  private success = new Subject<string>();
  staticAlertClosed = false;
  freightType: FreightType;
  location1: Location;
  location2: Location;
  location3: Location;
  location4: Location;
  location5: Location;
  transport1: Transportation;
  transport2: Transportation;
  transport3: Transportation;
  transport4: Transportation;
  transport5: Transportation;
  alertType = "success";
  itemsPosted: number;
  broker: Broker;
  term: Term;
  controlArrayReady = false;
  totalCases = 0;
  totalPallets = 0;
  totalGrossLbs = 0;
  totalCaseCost = 0;
  totalGrosskg = 0;
  showAll = true;
  grayRow = [];
  pdfPrint = false;
  dataChanged = false;
  reportName = '';
  reportNames = [
    {
    Name: 'Sales Order', 
    Id: 'my-sales-order',
    filename: 'Sales Order',
    Orientation: 'landscape',
    TopMargin: .1,
    LRMargin: .6
    },
    {
      Name: 'Packing Slip', 
      Id: 'my-packing-slip',
      filename: 'Packing Slip',
      Orientation: 'portrait',
      TopMargin: .6,
      LRMargin: .3
      }
  ];
  currentIndex: number;
  constructor(
    private customerService: CustomerService,
    private shipToService: ShipToAddressService,
    private vendorService: VendorService,
    private pickupService: PickupLocationService,
    private warehouseService: WarehouseService,
    private route: ActivatedRoute,
    private fb: UntypedFormBuilder,
    private freightService: FreightTypeService,
    private locationService: LocationService,
    private transportService: TransportationService,
    private brokerService: BrokerService,
    private termService: TermsService,
    private orderItemService: OrderItemsService,
    private checkRef: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.route.data.subscribe( order => {
      if (order.dataOrder !== undefined) {
        this.title = 'SALES ORDER';
        this.order = order.dataOrder;
        let addIt = false;
        let index = 0;
        this.order.forEach( item => {
          if (addIt) {
            this.addItem(item);
          } else {
            this.orderForm = this.fb.group({
              showAll: true,
              items: this.fb.array([ this.createItem(item) ])
            });
          }
          index++;
          addIt = true;
          this.grayRow.push('');
        })

        this.loadServices();
        this.onChanges();
        this.calcExtension(this.orderForm.get('items').value)

      } else {
        this.title = 'SALES ORDER';
        this.orderForm.reset();
        this.onChanges();
      }                
    })
    setTimeout(() => this.staticAlertClosed = true, 20000);
    this.success.subscribe((message) => this.successMessage = message);
    this.success.pipe(
      debounceTime(5000)
    ).subscribe(() => this.successMessage = null);   
  }
  createItem(item): UntypedFormGroup {
    return this.fb.group({
      cases: item.cases
    });
  }
  addItem(item): void {
    this.items = this.orderForm.get('items') as UntypedFormArray;
    this.items.push(this.createItem(item));
  }

  loadServices() {
    if (this.order.length == 0) {
      return;
    }
    if (this.order[0].billToId) {
      const id = this.order[0].billToId;
      this.customerService.getOne(id).subscribe(cust => this.customer = cust);
    }
    if (this.order[0].brokerId) {
      const id = this.order[0].brokerId;
      this.brokerService.getOne(id).subscribe(broker => this.broker = broker);
    }
    if (this.order[0].shipToId) {
      const id = this.order[0].shipToId;
      this.shipToService.getOne(id).subscribe(ship => this.shipTo = ship);
    }
    if (this.order[0].termsId) {
      const id = this.order[0].termsId;
      this.termService.getOne(id).subscribe(terms => this.term = terms);
    }
    if (this.order[0].freightTypeId) {
      const id = this.order[0].freightTypeId;
      this.freightService.getOne(id).subscribe(freight => this.freightType = freight);
    }
    
    if (this.order[0].vendorPickupId) {
      const id = this.order[0].vendorPickupId;
      this.pickupService.getOne(id).subscribe(pick => this.pickup = pick);
    }

    if (this.order[0].shippingLane1) {
      const id = this.order[0].shippingLane1;
      this.locationService.getOne(id).subscribe(loc => this.location1 = loc);
    }
    if (this.order[0].shippingLane2) {
      const id = this.order[0].shippingLane2;
      this.locationService.getOne(id).subscribe(loc => this.location2 = loc);
    }
    if (this.order[0].shippingLane3) {
      const id = this.order[0].shippingLane3;
      this.locationService.getOne(id).subscribe(loc => this.location3 = loc);
    }
    if (this.order[0].shippingLane4) {
      const id = this.order[0].shippingLane4;
      this.locationService.getOne(id).subscribe(loc => this.location4 = loc);
    }
    if (this.order[0].shippingLane5) {
      const id = this.order[0].shippingLane5;
      this.locationService.getOne(id).subscribe(loc => this.location5 = loc);
    }
    if (this.order[0].transportCo1) {
      const id = this.order[0].transportCo1;
      this.transportService.getOne(id).subscribe(trans => this.transport1 = trans);
    }
    if (this.order[0].transportCo2) {
      const id = this.order[0].transportCo2;
      this.transportService.getOne(id).subscribe(trans => this.transport2 = trans);
    }
    if (this.order[0].transportCo3) {
      const id = this.order[0].transportCo3;
      this.transportService.getOne(id).subscribe(trans => this.transport3 = trans);
    }
    if (this.order[0].transportCo4) {
      const id = this.order[0].transportCo4;
      this.transportService.getOne(id).subscribe(trans => this.transport4 = trans);
    }
    if (this.order[0].transportCo5) {
      const id = this.order[0].transportCo5;
      this.transportService.getOne(id).subscribe(trans => this.transport5 = trans);
    }
    if (this.order[0].whsid) {
      const id = this.order[0].whsid;
      this.warehouseService.getOne(id).subscribe(whse => this.warehouse = whse);
    }
    if (this.order[0].vendorId) {
      const id = this.order[0].vendorId;
      this.vendorService.getOne(id).subscribe(vend => this.vendor = vend);
    }
  }
  getAddress2(item) {
    let city = '';
    let state = '';
    let zip = '';
    let ret = '';
    switch (item) {
      case 'customer':
        if (this.customer) {
          city = this.customer.city;
          state = this.customer.stateProvince;
          zip = this.customer.zipPostalCode;
        }
        break;
      case 'shipto':
          if (this.shipTo) {
            city = this.shipTo.city;
            state = this.shipTo.stateProvince;
            zip = this.shipTo.zipPostalCode;
          }
          break;
      case 'pickup':
          if (this.pickup) {
            city = this.pickup.city;
            state = this.pickup.stateProvince;
            zip = this.pickup.zipPostalCode;
          }
          break;
          
      case 'warehouse':
          if (this.warehouse) {
            city = this.warehouse.city;
            state = this.warehouse.stateProvince;
            zip = this.warehouse.zipPostalCode;
          }
          break;
      case 'vendor':
          if (this.vendor) {
            city = this.vendor.city;
            state = this.vendor.stateProvince;
            zip = this.vendor.zipPostalCode;
          }
          break;
    }
    
    if (city) {
      ret = city;
      if (state) {
        ret += ', ' + state;
      }
    }

    if (zip) {
      ret += ' ' + zip;
    }

    return ret.trim();

  }
  displayDate(dt, t) {
    
    if (dt) {
      if (t === undefined) {
        t = '';
      }
      let x = new Date(dt);
      if (t == 'T') {
        return x.toLocaleDateString() + ' @ ' + x.toLocaleTimeString();
      }
      return x.toLocaleDateString();
    } 
    return dt
  }
  getVendor() {
    let ret = '';
    if (this.vendor) {
      ret += this.vendor.company.trim();
      ret += ' ' + this.vendor.address;
      ret += ' ' + this.getAddress2('vendor');
    }

    return ret;
  }
  onChanges(): void {
    this.orderForm.get('items').valueChanges.subscribe(val => {
      this.calcExtension(val);
      this.dataChanged = true;
    });
    
    this.orderForm.get('showAll').valueChanges.subscribe(val => {
      this.showHideItems(val);
      this.dataChanged = true;
    });
    
  }
  showHideItems(val) {
    this.showAll = val;
    let blankNext = true;
    this.orderForm.get('items').value.forEach((element, index) => {
      const cases = element.cases ? element.cases * 1 : 0;
      if (cases == 0 && !this.showAll) {
        if (this.pdfPrint) {
          this.grayRow[index] = 'B';
        } else {
          this.grayRow[index] = 'G';
          blankNext = true;
        }
      } else {
        if (blankNext) {
          this.grayRow[index] = '';
        } else{
          this.grayRow[index] = 'S';
        }
        blankNext = !blankNext;
      }
    });
  }
  calcExtension(item) {
    const allItems = this.orderForm.get('items') as UntypedFormArray;
    this.totalCases = 0;
    this.totalPallets = 0;
    this.totalGrossLbs = 0;
    this.totalCaseCost = 0;
    this.totalGrosskg = 0;

    // this.totalCases = sum cases (each)
    // this.totalPallets = cases / CS per Pallet  (each)
    // this.totalGrossLbs = sum(csGrossLbs * cases)
    // this.totalGrosskg = sum(csGrossKg * cases)
    // this.totalCaseCost = sum (extCaseCost)
    let blankNext = true;
    item.forEach((element, index) => {
      let cases = 0;
      if (element.cases) {
        cases = element.cases * 1;
      }
    //  const itemcontrols = allItems.controls[index]  as FormGroup;
      let caseCost = 0;
      let extCaseCost = 0;
      if (this.order[index].caseCost) {
        caseCost = this.order[index].caseCost * 1;
      }
      extCaseCost = cases * caseCost;
      this.order[index].extCaseCost = extCaseCost;
      this.totalCases += cases;
      if (this.order[index].csPerPallet != 0) {
        const bigNum: any = Big(cases)
        .div(this.order[index].csPerPallet)
        .round(0, 3);
        let pallets = bigNum * 1;
        if (pallets == 0 && cases !=0 ) {
          pallets = 1;
        }
        this.totalPallets += pallets;
      }
      this.totalCaseCost += extCaseCost;
      this.totalGrossLbs += this.order[index].csGrossLbs * cases;
      this.totalGrosskg += this.order[index].csGrossKg * cases;
      // if (cases == 0 && !this.showAll) {
      //   this.grayRow[index] = 'G';
      // } else {
      //   if ( index % 2 == 0) {
      //     this.grayRow[index] = 'S';
      //   }else{
      //     this.grayRow[index] = '';
      //   }
      // }
      if (cases == 0 && !this.showAll) {
        this.grayRow[index] = 'G';
        blankNext = true;
      } else {
        if (blankNext) {
          this.grayRow[index] = '';
        } else{
          this.grayRow[index] = 'S';
        }
        blankNext = !blankNext;
      }
    });
  }

  
  onSubmit() {
    this.alertType = "success";
    let casesChanged = 0;
    let casesProcessed = 0;
    this.orderForm.get('items').value.forEach((element, index) => {
      const cases = element.cases ? element.cases * 1 : 0;
      const currentCase = this.order[index].cases ? this.order[index].cases * 1 : 0;
      if (cases != currentCase) {
        casesChanged++;
        this.order[index].cases = cases;
        this.orderItemService.updateCase(this.order[index]).subscribe( data => {
            casesProcessed++;
            if (casesProcessed >= casesChanged) {
              this.success.next(`Successfully updated.`);
              this.dataChanged = false;
            }
          }, error => {
            casesProcessed ++; 
            this.alertType = "danger";
            if (casesProcessed >= casesChanged) {
              this.success.next(`Unable to perform update.`);
            }
          });
      }
    });
  }
  displayCase(item) {
    
    return item.cases;
  }

  onPrint() {
    if (this.dataChanged) {
      if (!confirm ('Have you saved it already?')) {
        return;
      }
      this.dataChanged = false;
    }
    this.successMessage = null;
    this.orderFilled = this.order.filter(order => order.cases);
    this.currentIndex = -1;
    this.processPDF();
  }
    
  processPDF() {
    this.currentIndex++;
    if (this.currentIndex >= this.reportNames.length) {
      this.reportName = '';
      return;
    }
    // TopMargin: .6,
    // LRMargin: .1
    if (confirm('Print ' + this.reportNames[this.currentIndex].Name + '?')) {
      this.reportName = this.reportNames[this.currentIndex].Name;
      this.title = this.reportName;
      this.checkRef.detectChanges();
      var element = document.getElementById(this.reportNames[this.currentIndex].Id);
      var opt = {
        margin:       [this.reportNames[this.currentIndex].TopMargin, this.reportNames[this.currentIndex].LRMargin],
        filename:     this.order[0].salesOrderNo.trim() + ' ' + this.reportNames[this.currentIndex].filename + '.pdf',
        image:        { type: 'jpeg', quality: 0.98 },
        html2canvas:  { scale: 2 },
        jsPDF:        { unit: 'in', format: 'letter', orientation: this.reportNames[this.currentIndex].Orientation }
      };
      html2pdf().set(opt).from(element).save();
    }
    this.processPDF();
  }
  onDelete() {
    alert("under construction");
  }
}
