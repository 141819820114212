export class User {
  userid: number;
  userinitials: string;
  userpassword: string;
  username: string;
  firstName: string;
  token: string;
  customerId: number;
  vendorId: number;
  brokerId: number;
  dashboard: number;
  sales: number;
  customClearing: number;
  accounting: number;
  customer: number;
  vendor: number;
  other: number;
  items: number;
  products: number;
  productListing: number;
  projections: number;
  reports: number;
  portal: number;
  administrationRole: string;
  trafficRole: string;
  accountingRole: string;
  marketingRole: string;
  vendorRole: string;
  projectionsRole: string;
}

export class UserFull {
  userid: number;
  userinitials: string;
  userpassword: string;
  username: string;
  passwordHash: string;
  passwordSalt: string;
  firstName: string;
  lastName: string;
  department: string;
  address: string;
  city: string;
  stateProvince: string;
  postalCode: string;
  countryRegion: string;
  email: string;
  mobile: string;
  phone: string;
  photoLocation: string;
  vendorId: number;
  roleIds: string;
}