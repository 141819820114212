import { Component, OnInit } from '@angular/core';
import { GstHstService } from 'src/app/services/gst-hst.service';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-gst-hst-list',
  templateUrl: './gst-hst-list.component.html',
  styleUrls: ['./gst-hst-list.component.scss']
})
export class GstHstListComponent implements OnInit {
gstHst: any;
page = 1;
pageSize = 30;
successMessage: string;
private success = new Subject<string>();
alertType = "success";
staticAlertClosed = false;
constructor (
  private router: Router,
  private gstHstService: GstHstService
) {}

ngOnInit(): void {
  this.gstHstService.getAll().subscribe(data => {
    this.gstHst = data;
  }, error => {
    this.successMessage = 'Unable to process request';
    this.alertType = 'danger';
  });
  setTimeout(() => this.staticAlertClosed = true, 20000);
  this.success.subscribe((message) => this.successMessage = message);
  this.success.pipe(
    debounceTime(5000)
  ).subscribe(() => this.successMessage = null);   
}
onRowClicked(gstHst) {
  this.router.navigate(['/other/gst-hst-edit', gstHst.id]);
}
}
