import { NgModule } from '@angular/core';  
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {ConfirmDialogComponent} from './confirm-dialog.component';
import { CommonModule } from '@angular/common';
@NgModule({  
    declarations: [  
        ConfirmDialogComponent  
    ],  
    imports: [
        NgbModule,
        CommonModule
    ],
    exports: [  
        ConfirmDialogComponent  
    ]  
})  
export class ConfirmDialogModule  
{  
}  