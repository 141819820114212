import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { LkupProductGroupService } from 'src/app/services/lkup-product-group.service';
import { LkupProductGroups } from 'src/app/models/lkup-product-group.model';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { ProductService } from 'src/app/services/product.service';
import { Product, ProductInit } from 'src/app/models/product.model';
import { Vendor } from 'src/app/models/vendor-model';
import { VendorService } from 'src/app/services/vendor.service';

@Component({
  selector: 'app-product-group-edit',
  templateUrl: './product-group-edit.component.html',
  styleUrls: ['./product-group-edit.component.scss']
})
export class ProductGroupEditComponent {
  title = 'Product Group New';
  newOne = true;
  productGroupForm: UntypedFormGroup;
  successMessage: string;
  private success = new Subject<string>();
  staticAlertClosed = false;
  productGroup: LkupProductGroups;
  vendors: Vendor[];

  constructor(private route: ActivatedRoute, 
              private router: Router,
              private fb: UntypedFormBuilder,
              private productService: ProductService,
              private productGroupService: LkupProductGroupService,
              private vendorService: VendorService
  ) {}

  ngOnInit() {
    this.productGroupForm = this.fb.group({
      productGroupNo: ['', Validators.required],
      vendorId: ['']
    });

    this.vendorService.getFilteredListing('Active', 0, 1, 999).subscribe(ven => {
      this.vendors = ven.item2;
    })

    this.route.data.subscribe(data => {
      if (data.dataProductGroup !== undefined) {
        this.title = 'Product Group Edit';
        this.newOne = false;
        this.productGroup = data.dataProductGroup;
        this.fillFormData();
      } else {
        this.title = 'Product Group New';
        this.newOne = true;
        this.productGroup = new LkupProductGroups();
        this.productGroupForm.reset();

      }
    })
    setTimeout(() => this.staticAlertClosed = true, 20000);
    this.success.subscribe((message) => this.successMessage = message);
    this.success.pipe(
      debounceTime(5000)
    ).subscribe(() => this.successMessage = null);
  }
  fillFormData() {
    this.productGroupForm.setValue({
      productGroupNo: this.productGroup.productGroupNo,
      vendorId: this.productGroup.vendorId
    });
  }
  onSubmit() {
    this.productGroup.productGroupNo = this.productGroupForm.controls.productGroupNo.value;
    this.productGroup.vendorId = this.productGroupForm.controls.vendorId.value;
    if (this.newOne) {
      this.productGroupService.create(this.productGroup).subscribe(data => {
        this.productGroup.id = data.id;
        this.newOne = false;
        this.title = 'Product Group Edit';
        this.success.next('Successfully added.')
      }, error => {
      });
    } else {
      this.productGroupService.update(this.productGroup).subscribe(data => {
        this.success.next('Successfully changed.');
      }, error => {
      });
    }
  }
  onDelete() {
    if (this.productGroup.id) {
      this.productGroupService.remove(this.productGroup.id).subscribe(data => {
        this.router.navigate(['/products/group-list']);
      });
    }
  }
}
