import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from "@angular/router";
import { Observable } from "rxjs";
import { LkupProductGroupService } from "src/app/services/lkup-product-group.service";
import { LkupProductGroups } from "src/app/models/lkup-product-group.model";

@Injectable()

export class LkupProductGroupsResolver implements Resolve<LkupProductGroups> {
    constructor(private lkupProductGroupService: LkupProductGroupService) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<LkupProductGroups> {
        const idField = 'id';
        const id = parseInt(route.params[idField], 10);

        if (id === 0) {
            return;
        }
        return this.lkupProductGroupService.getOne(id);
    }
}