import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { UserService } from '../services/user.service';
import { User } from '../models/user.model';
@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
  // model: any = {};
  user = new User();
  loading = false;
  registerForm: UntypedFormGroup;
  constructor(
    private router: Router,
    private userService: UserService,
    private formBuilder: UntypedFormBuilder) { }

  ngOnInit() {
    this.registerForm = this.formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      userinitials: ['', Validators.required],
      userpassword: ['', Validators.required],
    });
  }
  get f() { return this.registerForm.controls; }
  register() {
    this.loading = true;
    this.user.userinitials = this.registerForm.controls.userinitials.value;
    this.user.userpassword = this.registerForm.controls.userpassword.value;
    this.user.username = this.registerForm.controls.firstName.value + ' ' + this.registerForm.controls.lastName.value;
    this.userService.create(this.user)
        .subscribe(
            data => {
                this.router.navigate(['/login']);
            },
            error => {
                this.loading = false;
            });
  }
}
