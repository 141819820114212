import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { SubCategory } from '../../../models/sub-category.model';
import { SubCategoryService } from '../../../services/sub-category.service';
import { Vendor } from '../../../models/vendor-model';
import { VendorService } from '../../../services/vendor.service';
import { Brand } from '../../../models/brand.model';
import { BrandService } from '../../../services/brand.service';
import { Category } from '../../../models/category.model';
import { CategoryService } from '../../../services/category.service';
import { SorterService } from '../../../services/sorter.service';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
@Component({
  selector: 'app-sub-category-edit',
  templateUrl: './sub-category-edit.component.html',
  styleUrls: ['./sub-category-edit.component.scss']
})
export class SubCategoryEditComponent implements OnInit {
  title = 'Sub Category New';
  newOne = true;
  subCategory: SubCategory;
  category: Category[] = [];
  brand: Brand[] = [];
  categoryForm: UntypedFormGroup;
  successMessage: string;
  private success = new Subject<string>();
  staticAlertClosed = false;
  vendor: Vendor[] = [];
  currentBrand = '';
  currentVendor = '';
  constructor(private route: ActivatedRoute,
              private router: Router,
              private fb: UntypedFormBuilder,
              private subCategoryService: SubCategoryService,
              private categoryService: CategoryService,
              private brandService: BrandService,
              private sortSvc: SorterService,
              private vendorService: VendorService) { }

  ngOnInit() {
    this.categoryForm = this.fb.group({
      activeItem: [''],
      subCategory: [''],
      brand: [{ value: '', disabled: true }],
      vendor: [{ value: '', disabled: true }],
      categoryId: [''],
    });

    this.onChanges();

    this.categoryService.getAll().subscribe(cat => {
      this.category = this.sortSvc.sorter(cat, 'category');
      this.brandService.getAll().subscribe(brand => {
        this.brand = brand;
        this.vendorService.getAll().subscribe(vendor => {
          this.vendor = vendor;
          this.route.data.subscribe(data => {
            if (data.dataCategory !== undefined) {
              this.title = 'Sub Category Edit';
              this.newOne = false;
              this.subCategory = data.dataCategory;
              this.fillFormData();
            } else {
              this.title = 'Sub Category New';
              this.newOne = true;
              this.subCategory = new SubCategory();
              this.categoryForm.reset();
            }
          });
        });
      })
    })
    setTimeout(() => this.staticAlertClosed = true, 20000);
    this.success.subscribe((message) => this.successMessage = message);
    this.success.pipe(
      debounceTime(5000)
    ).subscribe(() => this.successMessage = null);
  }
  onChanges(): void {
    this.categoryForm.get('categoryId').valueChanges.subscribe(val => {
      this.getBrand(val * 1);
    });
  }
  fillFormData() {
    this.categoryForm.setValue({
      activeItem: this.subCategory.activeItem,
      subCategory: this.subCategory.category,
      brand: this.currentBrand,
      vendor: this.currentVendor,
      categoryId: this.subCategory.categoryId
    });
    this.getBrand(this.subCategory.categoryId);
  }
  
  getBrand(catId) {
    this.currentBrand = '';
    this.currentVendor = '';
    if (catId) {
      const categoryIndex = this.category.findIndex(item => item.id === catId);
      if (categoryIndex > -1) {
        const brandId = this.category[categoryIndex].brandId;
        const index = this.brand.findIndex(item => item.id === brandId);
        if (index > -1) {
          this.currentBrand = this.brand[index].brand;
          this.getVendor(this.brand[index].vendorId);
        }
      }
    }
    this.categoryForm.controls.brand.setValue(this.currentBrand);
    this.categoryForm.controls.vendor.setValue(this.currentVendor);
  }
  getVendor(vendId) {
    this.currentVendor = '';
    if (vendId) {
      const vendorIndex = this.vendor.findIndex(item => item.id === vendId);
      if (vendorIndex >  -1) {
        this.currentVendor = this.vendor[vendorIndex].company;
      }
    }
    this.categoryForm.controls.vendor.setValue(this.currentVendor);
  }

  onSubmit() {
    this.subCategory.activeItem = this.categoryForm.controls.activeItem.value;
    this.subCategory.category = this.categoryForm.controls.subCategory.value;
    this.subCategory.categoryId = this.categoryForm.controls.categoryId.value;
    if (this.newOne) {
      this.subCategoryService.create(this.subCategory).subscribe(data => {
        this.subCategory.id = data.id;
        this.newOne = false;
        this.title = 'Sub Category Edit';
        this.success.next(`Successfully added.`);
      }, error => {
      });
    } else {
      this.subCategoryService.update(this.subCategory).subscribe(data => {
        this.success.next(`Successfully changed.`);
      }, error => {
      });
    }
  }

  onDelete() {
    if (this.subCategory.id) {
      this.subCategoryService.remove(this.subCategory.id).subscribe(data => {
        this.router.navigate(['/items/sub-category-list']);
      });
    }
  }
}
