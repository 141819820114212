<h5>{{ title }} </h5>
<form [formGroup]="upcGtnForm" (ngSubmit)="onSubmit()" novalidate>
  <div class="form-group">
    <div class="col">
      <label>
        Active Status:
        <select formControlName="activeStatus" class="form-control">
          <option value="True">True</option>
          <option value="False">False</option>
        </select>
      </label>
    </div>

    <div class="col-lg-24">
      <label>
        Vendor Order Code:
        <input type="text" formControlName="vendorOrderCode" class="form-control"  size="60">
      </label>
    </div>

    <div class="col-lg-24">
      <label>
        UPC:
        <input type="text" formControlName="upc" class="form-control"  size="60">
      </label>
    </div>

    <div class="col-lg-24">
      <label>
        GTN:
        <input type="text" formControlName="gtn14" class="form-control"  size="60">
      </label>
    </div>

    <div class="col">
      <label>
        UPC Area:
        <select formControlName="upcArea" class="form-control">
          <option value="USA">USA</option>
          <option value="CND">CND</option>
        </select>
      </label>
    </div>
    
    <div class="col">
      <label>
       Brand:
        <select formControlName="brandId" class="form-control">
          <option *ngFor="let b of brand" [value]="b.id">{{b.brand}}</option>
        </select>
      </label>
    </div>

    <div class="col">
      <label>
       Vendor:
       <input type="text" formControlName="vendor" class="form-control"  size="60">
      </label>
    </div>
    <button type="submit" class="btn btn-primary mt-3 w-25">Save</button>
    <button type="button" class="btn btn-secondary ml-3 mt-3 w-25" (click)="onDelete()">Delete</button>
    </div>
     <ngb-alert *ngIf="successMessage" type="success" (close)="successMessage = null">{{ successMessage }}</ngb-alert>
</form>

