import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { LkupProductGrouplisting } from '../models/lkup-product-grouplisting.model';
const url = environment.kalcorApiUrl;

@Injectable({
  providedIn: 'root'
})
export class LkupProductGrouplistingService {

  constructor(private http: HttpClient) { }

  getAll(): Observable<any> {
    return this.http.get(url + 'LkupProductsGrouplistings', this.jwt());
  }
  getOne(id: number): Observable<any> {
    return this.http.get(url + 'LkupProductsGrouplistings/' +id, this.jwt());
  }
  update(lkupProductsGrouplisting) {
    return this.http.put(url + 'LkupProductsGrouplistings/' + lkupProductsGrouplisting.id, lkupProductsGrouplisting, this.jwt());
  }
  create(lkupProductsGrouplisting): Observable<any> {
    return this.http.post(url + 'LkupProductsGrouplistings', lkupProductsGrouplisting, this.jwt());
  }
  remove(id: number) {
    return this.http.delete(url + 'LkupProductsGrouplistings/' + id, this.jwt());
  }

  private jwt() {
    // create authorization header with jwt token
    const currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
    if (currentUser && currentUser.token) {
        const headersInfo = new HttpHeaders({ Authorization: 'Bearer ' + currentUser.token });
        return { headers: headersInfo };
    }
  }
  
}
