export class BudgetActuals {
    id: number;
    salesOrderNo: string;
    sortNumber: number;
    edit: string;
    funds: string;
    item: string;
    budgetAmount: number;
    difference: number;
    actualAmount: number;
    company: string;
    reference: string;
    confirm: string;
    freightType: string;
    freightCost: number;
    freightCostSales: number;
    freightTotalCost: number;
    actualExchange: number;
    commission1: number;
    commission2: number;
    commPct1: number;
    commPct2: number;
    budgetExchange: number;
    period: number;
    year: number;
    revenue: number;
    freightCustom: string;
}