import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { Category } from '../../models/category.model';
import { CategoryService } from '../../services/category.service';

@Injectable()
export class CategoryResolver implements Resolve<Category> {
  constructor(private categoryService: CategoryService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Category> {

    if (route.url[0].path === 'category-list') {
      return this.categoryService.getFilteredListing('Active', 0, 0, 1, 30);
    }

    const idField = 'id';
    const id =  parseInt(route.params[idField], 10);

    if (id === 0) {
      return;
    }
    return this.categoryService.getOne(id);
  }
}
