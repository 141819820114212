<h4>Weeks - Customer Head Office</h4>
<h5>Filter</h5>
<div class="col-lg-44">
  <label>
    Customer Head Office:
    <select [(ngModel)]="customerHeadSelected" (change)="loadPage()">
      <option [value]=""></option>
      <option *ngFor="let c of customerHead" [value]="c.id">{{ c.company }}</option>
    </select>
  </label>
</div>
<table class="table table-striped" style="overflow-x:scroll;">
    <thead>
    <tr>
      <th scope="col">Customer</th>
      <th scope="col">Year</th>
    </tr>
    </thead>
    <tbody>
      <tr *ngFor="let c of customerHead; index as i">
        <td (click)="clickedItem(c)" class="pointer">
          <u class="text-primary">  {{ c.company }} </u>
        </td>
        <td>{{ c.year }}</td>
      </tr> 
    </tbody>
  </table>

  <button *ngIf="customerHeadSelected > 0" type="button" class="btn btn-primary mt-3 w-25" (click)="createNewYear()">Create Year</button>

  <ngb-alert *ngIf="successMessage" [type]="alertType" (close)="successMessage = null">{{ successMessage }}</ngb-alert>