import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { Location } from '../models/location-model';
const url = environment.kalcorApiUrl;

@Injectable({
  providedIn: 'root'
})
export class LocationService {

  constructor(private http: HttpClient) { }

  getAll(): Observable<any> {
    return this.http.get(url + 'LkupListLocations', this.jwt());
  }
  getOne(id: number): Observable<any> {
    return this.http.get(url + 'LkupListLocations/' + id, this.jwt());
  }
  getDot(): Observable<any> {
    return this.http.get(url + 'LkupListLocations/Dot', this.jwt());
  }
  update(location: Location) {
    return this.http.put(url + 'LkupListLocations/' + location.id, location, this.jwt());
  }
  create(location: Location): Observable<any> {
    return this.http.post(url + 'LkupListLocations', location, this.jwt());
  }
  remove(id: number) {
    return this.http.delete(url + 'LkupListLocations/' + id, this.jwt());
  }

  private jwt() {
    // create authorization header with jwt token
    const currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
    if (currentUser && currentUser.token) {
        const headersInfo = new HttpHeaders({ Authorization: 'Bearer ' + currentUser.token });
        return { headers: headersInfo };
    }
  }


}
