<h5>GST/HST List </h5>
<div class="smallSpacer"></div>
<table class="table table-striped" style="overflow-x:scroll;">
    <thead>
    <tr>
      <th scope="col">Tax Name</th>
      <th scope="col">Rate %</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let g of gstHst; index as i">
        <td>
            <a class="pointer" (click)="onRowClicked(g)">
            <u class="text-primary">{{ g.taxName }}</u>
            </a>
          </td>
      <td>{{ g.rate }}</td>
    </tr>
    </tbody>
  </table>
  <div *ngIf="gstHst!==undefined">
    <ngb-pagination [(page)]="page" [pageSize]="pageSize" [collectionSize]="gstHst.length">
    </ngb-pagination>
  </div>
  <ngb-alert *ngIf="successMessage" [type]="alertType" (close)="successMessage = null">{{ successMessage }}</ngb-alert>
  