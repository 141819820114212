import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RolesService } from '../../../services/roles.service';
@Component({
  selector: 'app-roles-list',
  templateUrl: './roles-list.component.html',
  styleUrls: ['./roles-list.component.css']
})
export class RolesListComponent implements OnInit {
  roles: any;
  
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private rolesService: RolesService
  ) { }

  ngOnInit(): void {
    this.route.data.subscribe(data => {
      this.roles = data.dataRoles.sort((a, b) => a.roleName < b.roleName ? -1 : a.roleName > b.roleName ? 1 : 0);
    });
  }
  onRowClicked(role) {
    this.router.navigate(['/admin/roles-edit', role.roleid]);
  }
}
