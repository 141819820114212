import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ProductService } from '../../../../services/product.service';
import { UpcGtnService } from '../../../../services/upc-gtn.service';
import { UpcGtn } from '../../../../models/upc-gtn.model';
import { FormsModule } from '@angular/forms';
import { Product, ActiveListings, ProductInit } from 'src/app/models/product.model';
import { BrandService } from '../../../../services/brand.service';
import { Brand } from '../../../../models/brand.model';
import { CategoryService } from '../../../../services/category.service';
import { Category } from '../../../../models/category.model';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { UtilityService } from '../../../../services/utility.service';
import { ShipToBrokerService } from '../../../../services/ship-to-broker.service';
import { ShipToBroker } from '../../../../models/shipto-broker';
import { ExchangeRate } from '../../../../models/exchange-rate.model';
import { ExchangeRateService } from '../../../../services/exchange-rate.service';
import { LkupProductGroupService } from 'src/app/services/lkup-product-group.service';
import { LkupProductGroups } from 'src/app/models/lkup-product-group.model';
@Component({
  selector: 'app-costing-bucket-setup-edit',
  templateUrl: './costing-bucket-setup-edit.component.html',
  styleUrls: ['./costing-bucket-setup-edit.component.scss']
})
export class CostingBucketSetupEditComponent implements OnInit {
  title = 'Costing Bucket Setup New';
  newOne = true;
  brands: Brand[];
  categories: Category[];
  product: Product;
  originalProduct: Product;
  gtn: UpcGtn[];
  productForm: UntypedFormGroup;
  successMessage: string;
  private success = new Subject<string>();
  alertType = "success";
  staticAlertClosed = false;
  activeList = [];
  shipToBroker = [];
  shipToBrokerOriginal: ShipToBroker[];
  shipToBrokerChanged = [];
  countTotal = 0;
  counter = 0;
  exchangeRate: ExchangeRate[];
  productGroups: LkupProductGroups[];
  groupName = '';
  linkedProduct: any;
  processIndex: number;
  grouped = false;
  listing = false;
  linkedListing: any;
  processIndexBroker: number;
  processIndexBrokerOriginal: number;
  groupListingInfo: any;
  spinner = '';
  constructor(private route: ActivatedRoute,
    private router: Router,
    private fb: UntypedFormBuilder,
    private gtnService: UpcGtnService,
    private brandService: BrandService,
    private categoryService: CategoryService,
    private productService: ProductService,
    private utiityService: UtilityService,
    private shipToBrokerSvc: ShipToBrokerService,
    private productGroupService: LkupProductGroupService,
    private exchangeSvc: ExchangeRateService) { }

  ngOnInit() {
    this.activeList = ActiveListings;
    this.productForm = this.fb.group({
      activeListings: [{ value: '', disabled: true }],
      costType: [{ value: '', disabled: true }],
      productOrderCode: [{ value: '', disabled: true }],
      brand: [{ value: '', disabled: true }],
      category: [{ value: '', disabled: true }],
      itemDescription: [{ value: '', disabled: true }],
      size: [{ value: '', disabled: true }],
      whsSelling: [''],
      whsSrp: [''],
      whsUnit: [{ value: '', disabled: true }],
      marketing: [{ value: '', disabled: true }],
      marketing1: [''],
      billing: [{ value: '', disabled: true }],
      billing1: [''],
      terms: [{ value: '', disabled: true }],
      terms1: [''],
      commission: [{ value: '', disabled: true }],
      commission1: [''],
      commission2: [''],
      kalcor: [{ value: '', disabled: true }],
      kalcor1: [''],
      cndFrtCostCase: [{ value: '', disabled: true }],
      cndFrtCostPallet: [''],
      storageCostCase: [{ value: '', disabled: true }],
      storageCostPallet: [''],
      netCnd: [{ value: '', disabled: true }],
      exchangeBudget: [''],
      netCsUsd: [{ value: '', disabled: true }],
      clearingCs: [{ value: '', disabled: true }],
      clearingCost: [''],
      duty: [''],
      freightCs: [{ value: '', disabled: true }],
      fobNetReturn: [{ value: '', disabled: true }],
      fobDifference: [{ value: '', disabled: true }],
      fobNetVendorCost: [''],
      fobNetVendorUnit: [{ value: '', disabled: true }],
      confirmedBy: [''],
      confirmDate: [''],
      fobConfirmed: [''],
      vendorPurchaseCaseCost: [{ value: '', disabled: true }],
      vendorPurchaseCaseCostUnit: [{ value: '', disabled: true }],
      royalty: [{ value: '', disabled: true }],
      royalty1: [''],
      currencyCode: ['', Validators.required],
      cndFrtCostLb: [{ value: '', disabled: true }],
    });

    this.brandService.getAll().subscribe(brand => {
      this.brands = brand;
      this.categoryService.getAll().subscribe(category => {
        this.categories = category;
        this.gtnService.getAll().subscribe(gtns => {
          this.gtn = gtns;
          this.exchangeSvc.getAll().subscribe(exchange => {
            this.exchangeRate = exchange;
            this.productGroupService.getAll().subscribe(group => {
              this.productGroups = group;

            this.route.data.subscribe(data => {
              if (data.dataProductSetup !== undefined) {
                this.product = data.dataProductSetup;
                this.product.activeListings = data.dataProductSetup.activeListings.replace("–","-");
                this.title = 'Costing Bucket Setup Edit';
                this.newOne = false;
                if (this.product.activeListings.includes("LISTING")) {
                  this.listing = true;
                  if (this.product.groupListingId > 0) {
                    let x = this.product.groupListingId;
                    this.getListingGroup(x);
                  }
                }
                this.shipToBrokerSvc.getCommission(this.product.id).subscribe(commission => {
                  this.shipToBrokerOriginal = commission;
                  this.setupShipToBroker();
                  this.fillFormData();
                  this.reCalculate('ALL');
                  this.onChanges();
                })  
              } else {
                this.title = 'Costing Bucket Setup New';
                this.newOne = true;
                this.product = ProductInit;
                this.productForm.reset();
                this.onChanges();
              }
            });
          })
        })
        });
      });
    });
    setTimeout(() => this.staticAlertClosed = true, 20000);
    this.success.subscribe((message) => this.successMessage = message);
    this.success.pipe(
      debounceTime(5000)
    ).subscribe(() => this.successMessage = null);
  }
  setupShipToBroker() {
    this.shipToBroker = [];
    this.shipToBrokerOriginal.forEach(data => {
        this.shipToBroker.push(data);
        this.shipToBrokerChanged.push(false);
    })
  }
  fillFormData() {
    const productOrderCodeValue = this.getValue(this.product.productOrderCodeId, 'productOrderCodeId');
    const brandValue = this.getValue(this.product.productOrderCodeId, 'brandId');
    const categoryValue = this.getValue(this.product.categoryId, 'categoryId');
    const productGroupValue = this.getValue(this.product.productGroupId, 'productGroupId');
    this.groupName = productGroupValue;
    const comm1 = this.product.commission1 === null ? 0 : this.product.commission1;
    const comm2 = this.product.commission2 === null ? 0 : this.product.commission2;
    this.product.commission1 = comm1 + comm2;  
    if (this.product.exchangeBudget === null || this.product.exchangeBudget === 0)  {
      const index = this.exchangeRate.findIndex(exchange => exchange.exchangeDefault === "Yes");
      if (index > -1 ){
        this.product.exchangeBudget = this.exchangeRate[index].exchangeRateAmount;
      }
    }
    this.productForm.setValue({
      activeListings: this.product.activeListings,
      costType: this.product.costType,
      productOrderCode: productOrderCodeValue,
      brand: brandValue,
      category: categoryValue,
      itemDescription: this.product.itemDescription,
      size: this.product.size,
      whsSelling: this.product.whsSelling,
      whsSrp: this.product.whsSrp,
      whsUnit: this.product.whsUnit,
      marketing: this.product.marketing,
      marketing1: this.product.marketing1,
      billing: this.product.billing,
      billing1: this.product.billing1,
      terms: this.product.terms,
      terms1: this.product.terms1,
      commission: this.product.commission,
      commission1: this.product.commission1,
      commission2: 0,
      kalcor: this.product.kalcor,
      kalcor1: this.product.kalcor1,
      cndFrtCostCase: this.product.cndFrtCostCase,
      cndFrtCostPallet: this.product.cndFrtCostPallet,
      storageCostCase: this.product.storageCostPallet,
      storageCostPallet: this.product.storageCostPallet,
      netCnd: this.product.netCnd,
      exchangeBudget: this.product.exchangeBudget === null ? this.product.exchangeBudget : this.product.exchangeBudget.toString(),
      netCsUsd: this.product.netCsUsd,
      clearingCs: this.product.clearingCs,
      clearingCost: this.product.clearingCost,
      duty: this.product.duty,
      freightCs: this.product.freightCs,
      fobNetReturn: this.product.fobNetReturn,
      fobDifference: this.product.fobDifference,
      fobNetVendorCost: this.product.fobNetVendorCost,
      fobNetVendorUnit: this.product.fobNetVendorUnit,
      confirmedBy: this.product.confirmedBy,
      confirmDate: this.product.confirmDate === null ? this.product.confirmDate : this.product.confirmDate.toString().substr(0,10),
      fobConfirmed: this.product.fobConfirmed,
      vendorPurchaseCaseCost: 0,
      vendorPurchaseCaseCostUnit: 0,
      royalty: this.product.royalty,
      royalty1: this.product.royalty1,
      currencyCode: this.product.currencyCode,
      cndFrtCostLb: this.product.cndFrtCostLb
    });
    if (productGroupValue) {
      this.getProductGroup(this.product.productGroupId);
    }
  }
  getProductGroup(val) {
    this.productService.getProductGroup(val).subscribe(data => {
      this.linkedProduct = data;
    })
  }
  getListingGroup(x){
    this.productService.getProductGroupListing(x).subscribe(data => {
      this.linkedListing = data;
    })
  }
  getValue(id: number, name: string) {
    let ret = '';
    if (id) {
      let index = -1;
      switch (name) {
        case 'productOrderCodeId':
          index = this.gtn.findIndex(item => item.id === id);
          if (index > -1) {
            ret = this.gtn[index].vendorOrderCode;
          }
          break;
          case 'brandId':
            index = this.gtn.findIndex(item => item.id === id);
            if (index > -1) {
              const brandId = this.gtn[index].brandId;
              if (brandId > -1 ) {
                const brandIndex = this.brands.findIndex(item => item.id === brandId);
                if (brandIndex > -1) {
                  ret = this.brands[brandIndex].brand;
                }  
              }
            }
            break;
        case 'categoryId':
          index = this.categories.findIndex(item => item.id === id);
          if (index > -1) {
            ret = this.categories[index].category;
          }
          break;
          case 'productGroupId':
            index = this.productGroups.findIndex(item => item.id === id);
            if (index > -1) {
              ret = this.productGroups[index].productGroupNo;
            }
          break;
        }
    }
    return ret;
  }
  commUpdate(i) {
    this.shipToBrokerChanged[i] = true;
  }
  onChanges(): void {
    const item = ['whsSelling', 'marketing1', 'billing1', 'terms1', 'royalty1', 'duty',
                  'commission1', 'commission2', 'kalcor1', 'cndFrtCostPallet', 'storageCostPallet',
                  'exchangeBudget','clearingCost','fobNetVendorCost'];
                  
    item.forEach(name => {
      this.productForm.get(name).valueChanges.subscribe(val => {
        this.reCalculate(name);
      });
    });
  }
  reCalculate(key) {
    this.updateProduct();
    const changedItems = this.utiityService.productCalculations(key, this.product);
    for (const item in changedItems) {
      if (changedItems.hasOwnProperty(item)) {
        if (this.productForm.controls.hasOwnProperty(item)) {
          this.productForm.controls[item].setValue(changedItems[item]);
        }
        this.product[item] = changedItems[item];
      }
    }
  }
  
  updateProduct() {
    for (const item in this.productForm.controls) {
      if (this.productForm.controls.hasOwnProperty(item)) {
       if (this.product[item] !== undefined) {
        this.product[item] = this.productForm.controls[item].value;
        if (item == 'exchangeBudget' && this.product[item] !== null) {
          this.product[item] *= 1; 
        }
       }
      }
    }
  }
  onSubmit() {
    if (this.productForm.invalid) {
      this.success.next(`Please select currency code.`);
      this.spinner = '';
      this.alertType = "danger";
      return;
    }
    this.updateProduct();
    let errorMessage = '';
    this.shipToBroker.forEach(broker => {
      let overallCommission = 0;
      overallCommission += broker.commission1 === null ? 0 : broker.commission1 * 1;
      overallCommission += broker.commission2 === null ? 0 : broker.commission2 * 1;
      if (overallCommission != this.product.commission1) {
        errorMessage = "Sum of brokers' commission of each Ship To must be equal to overall commission.";
        this.success.next(errorMessage);
        this.alertType = "danger";
      }
    })

    if (errorMessage) {
      return;
    }

    this.alertType = "success";

    if (this.newOne) {
      this.productService.create(this.product).subscribe(data => {
        this.product = data;
        this.newOne = false;
        this.title = 'Costing Bucket Setup Edit';
        this.success.next(`Successfully added.`);
      }, error => {
      });
    } else {
      this.productService.update(this.product).subscribe(data => {
        this.success.next(`Successfully changed.`);
      }, error => {
      });
    }
    this.countTotal = 0;
    
    this.shipToBrokerChanged.forEach((commChanged, index) => {
      if (commChanged) {
        this.countTotal ++;
        const shipTo = new ShipToBroker();
        shipTo.shipToBrokerId = this.shipToBroker[index].shipToBrokerId;
        shipTo.commission = this.shipToBroker[index].commission;
        shipTo.commission1 = this.shipToBroker[index].commission1;
        shipTo.commission2 = this.shipToBroker[index].commission2;
        this.shipToBrokerSvc.updateCommission(shipTo).subscribe(data => {
           this.monitorPosting('success'); 
        }, error => {
          this.monitorPosting('danger'); 
        })
      }
    })
  }
  
  monitorPosting(status) {
    this.counter++;
    this.alertType = this.alertType == 'danger' ? 'danger' : status;
    if (this.counter >= this.countTotal) {
      const message = this.alertType == 'danger' ? 'Unable to update Commission' : 'Successful Commission Update';
      this.success.next(message);
    }

  }
  validate() {
    if (this.product.productGroupId == 0 || this.product.productGroupId == null) {
      this.success.next(`This product does not belong to a group.`);
      this.alertType = "danger";
      this.spinner = '';
      return;
    }
    if (confirm('Costing information will be updated for all products in the group.'))
    this.processIndex = -1;
    this.grouped = true;
    this.originalProduct = this.product;
    this.initializeProducts();
  }
  initializeProducts() {
    //update cost information for all products in linkedProduct array
    this.spinner = '...Updating';
    this.processIndex++;
      if (this.processIndex >= this.linkedProduct.length) {
        this.processIndex = -1;
        this.grouped = false;
        this.product = this.originalProduct;
        this.spinner = '';
        alert('All products updated successfully.');
      } else {
        this.insertLinkedProducts();
      }
  }
  insertLinkedProducts() {
    if (this.productForm.invalid) {
      this.success.next(`Please select currency code.`);
      this.spinner = '';
      this.alertType = "danger";
      return;
    }
    this.updateLinkedProduct();
    let errorMessage = '';
    this.shipToBroker.forEach(broker => {
      let overallCommission = 0;
      overallCommission += broker.commission1 === null ? 0 : broker.commission1 * 1;
      overallCommission += broker.commission2 === null ? 0 : broker.commission2 * 1;
      if (overallCommission != this.product.commission1) {
        errorMessage = "Sum of brokers' commission of each Ship To must be equal to overall commission.";
        this.success.next(errorMessage);
        this.alertType = "danger";
        this.spinner = '';
      }
    })

    if (errorMessage) {
      return;
    }

    this.alertType = "success";

    if (this.newOne) {
      this.productService.create(this.product).subscribe(data => {
        this.product = data;
        this.newOne = false;
        this.title = 'Costing Bucket Setup Edit';
        this.initializeProducts();
        // this.success.next(`Successfully added.`);
      }, error => {
      });
    } else {
      this.productService.update(this.product).subscribe(data => {
        this.initializeProducts();
        // this.success.next(`Successfully changed.`);
      }, error => {
      });
    }
    this.countTotal = 0;
    
    this.shipToBrokerChanged.forEach((commChanged, index) => {
      if (commChanged) {
        this.countTotal ++;
        const shipTo = new ShipToBroker();
        shipTo.shipToBrokerId = this.shipToBroker[index].shipToBrokerId;
        shipTo.commission = this.shipToBroker[index].commission;
        shipTo.commission1 = this.shipToBroker[index].commission1;
        shipTo.commission2 = this.shipToBroker[index].commission2;
        this.shipToBrokerSvc.updateCommission(shipTo).subscribe(data => {
           this.monitorPosting('success'); 
        }, error => {
          this.monitorPosting('danger'); 
        })
      }
    })
  }
  updateLinkedProduct() {
    this.product = this.linkedProduct[this.processIndex];
    for (const item in this.productForm.controls) {
      if (this.productForm.controls.hasOwnProperty(item)) {
        if (this.product[item] !== undefined) {
           if (item !== 'productOrderCode') {
            if (item !== 'itemDescription') {
              if (item !== 'category') {
                if (item !== 'size') {
                  if (item !== 'brand') {
                    this.product[item] = this.productForm.controls[item].value;
                    if (item == 'exchangeBudget' && this.product[item] !== null) {
                      this.product[item] *= 1; 
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  validateListing() {
    if (this.product.groupListingId == 0 || this.product.groupListingId == null) {
      this.success.next(`This product does not belong to a group listing.`);
      this.alertType = "danger";
      this.spinner = '';
      return;
    }
    if (this.productForm.invalid) {
      this.success.next(`Please select currency code.`);
      this.alertType = "danger";
      this.spinner = '';
      return;
    }
    if (confirm('Costing information will be updated for all products in the group listing.'))
    this.processIndex = -1;
    this.grouped = true;
    this.originalProduct = this.product;
    this.spinner = '...Updating';
    this.initializeListings();
  }
  initializeListings() {
    //update cost information for all products in linkedListing array
    this.processIndex++;
      if (this.processIndex >= this.linkedListing.length) {
        this.processIndex = -1;
        this.grouped = false;
        this.product = this.originalProduct;
        this.spinner = '';
        alert('All products updated successfully.');
        //reset broker on screen?
      } else {
        this.processLinkedListings();
      }
  }
  processLinkedListings() {
    this.updateLinkedListings();
    this.productService.update(this.product).subscribe(data => {
          this.getShipToBroker();
        }, error => {
          this.success.next('Unable to update listing.');
          this.alertType = "danger";
    });
  }
  getShipToBroker() {
    this.shipToBrokerSvc.getList(this.product.id).subscribe(ship => {
      this.shipToBrokerOriginal = ship;
      this.processIndexBroker = -1;
      this.initializeShipToBroker();
    })
  }
  initializeShipToBroker() {
    this.processIndexBroker++;
    if (this.processIndexBroker >= this.shipToBroker.length) {
      this.processIndexBroker = -1;
      this.initializeListings();
    } else {
      this.processShipToBroker();
    }
  }
  processShipToBroker() {
    let errorMessage = '';
    let overallCommission = 0;
      overallCommission += this.shipToBroker[this.processIndexBroker].commission1 === null ? 0 : this.shipToBroker[this.processIndexBroker].commission1 * 1;
      overallCommission += this.shipToBroker[this.processIndexBroker].commission2 === null ? 0 : this.shipToBroker[this.processIndexBroker].commission2 * 1;
      if (overallCommission != this.product.commission1) {
        errorMessage = "Sum of brokers' commission of each Ship To must be equal to overall commission.";
        this.success.next(errorMessage);
        this.alertType = "danger";
      }
      const shipToIndex = this.shipToBroker.findIndex(o => o.shipToId == this.shipToBrokerOriginal[this.processIndexBroker].shipToId);
      const shipTo = new ShipToBroker();
      shipTo.shipToBrokerId = this.shipToBrokerOriginal[shipToIndex].shipToBrokerId;
      shipTo.commission = this.shipToBroker[shipToIndex].commission;
      shipTo.commission1 = this.shipToBroker[shipToIndex].commission1;
      shipTo.commission2 = this.shipToBroker[shipToIndex].commission2;
      this.updateShipToBroker(shipTo);    
  }
  updateShipToBroker(item) {
    this.shipToBrokerSvc.updateCommission(item).subscribe(data => {
      // this.monitorLinked('success');
      this.initializeShipToBroker();
    }, error => {
        // this.monitorLinked('danger');
      })
  }
  updateLinkedListings() {
    this.product = this.linkedListing[this.processIndex];
    for (const item in this.productForm.controls) {
      if (this.productForm.controls.hasOwnProperty(item)) {
        if (this.product[item] !== undefined) {
           if (item !== 'productOrderCode') {
            if (item !== 'itemDescription') {
              if (item !== 'category') {
                if (item !== 'size') {
                  if (item !== 'brand') {
                    this.product[item] = this.productForm.controls[item].value;
                    if (item == 'exchangeBudget' && this.product[item] !== null) {
                      this.product[item] *= 1; 
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  editSetup() {
    this.router.navigate(['/products/setup-edit',  this.product.id]);
  }
  
  editCaseSetup() {
    this.router.navigate(['/products/cases-setup-edit',  this.product.id]);
  }
  
  editPalletSetup() {
    this.router.navigate(['/products/pallet-setup-edit',  this.product.id]);
  } 
  
  editFreightCostSetup() {
    this.router.navigate(['/products/freight-cost-setup-edit',  this.product.id]);
  }
  editListingSetup() {  
    this.router.navigate(['/productListings/listing-setup-edit',  this.product.id]);
  }
}
