import { Component, OnInit } from '@angular/core';
import { WeekCustomerEndService } from 'src/app/services/week-customer-end.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { CustomerEnd } from 'src/app/models/customer-end-model';
import { CustomerEndService } from 'src/app/services/customer-end.service';

@Component({
  selector: 'app-week-customer-end-list',
  templateUrl: './week-customer-end-list.component.html',
  styleUrls: ['./week-customer-end-list.component.scss']
})
export class WeekCustomerEndListComponent implements OnInit {
  year = [];
  customerHead: [];
  customerHeadSelected: number;
  customerHeadId: [];
  customerName: string;
  successMessage: string;
  private success = new Subject<string>();
  alertType = "success";
  staticAlertClosed = false;
  page = 1;
  pageSize = 30;
  searchText = '';
  itemFound = [];
  collectionSize = 0;

  constructor(private router: Router,
    private activatedRoute: ActivatedRoute,
    private weekCustomerEndSvc: WeekCustomerEndService,
    private customerEndSvc: CustomerEndService
    ) { }

  ngOnInit(): void {
    this.customerHeadSelected = history.state.customerHeadId !== undefined ? history.state.customerHeadId : 0;
    if (this.customerHeadSelected == 0) {
      this.displayItems();
    } else {
      this.loadPage();
    }
    setTimeout(() => this.staticAlertClosed = true, 20000);
    this.success.subscribe((message) => this.successMessage = message);
    this.success.pipe(
      debounceTime(5000)
    ).subscribe(() => this.successMessage = null);
  }
  displayItems() {
    this.year=[];
    this.customerHead=[];
    this.customerEndSvc.getCustomerEndYears().subscribe(data => {
      this.customerHead = data;
      });
  }
  clickedItem(i) {
    this.router.navigate(['./other/week-customer-end-edit', i.id, i.year]);
  }
  createNewYear() {
    this.router.navigate(['./other/week-customer-end-edit', this.customerHeadSelected, 0])
  }
  loadPage() {
    this.customerEndSvc.getOneYears(this.customerHeadSelected).subscribe(data => { 
      this.customerHead = data;
    }, error => {
      this.successMessage = 'Unable to process request';
      this.alertType = "danger";
    });
  }

}
