<h5>Sales Manager List </h5>
<table class="table table-striped" style="overflow-x:scroll;">
    <thead>
    <tr>
      <th scope="col">Full Name</th>
      <th scope="col">Job Title</th>
      <th scope="col">e-Mail</th>
      </tr>
    </thead>
    <tbody>
    <tr *ngFor="let s of SalesManager; index as i">
      <td>
        <a class="pointer" (click)="onRowClicked(s)">
          <u class="text-primary">{{ s.fullName }}</u>
        </a>
          </td>
      <td>{{ s.jobTitle }}</td>
      <td>{{ s.email }}</td>
    </tr>
    </tbody>
  </table>
  <ngb-alert *ngIf="successMessage" [type]="alertType" (close)="successMessage = null">{{ successMessage }}</ngb-alert>   
