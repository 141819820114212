<div id="my-profit-loss" *ngIf="reportName=='Sales Order Profit & Loss'">
  <app-profit-loss
  [title] = "title"
  [order] = "orderSelected"
  [items] = "orderItems"
  [itemsUSD] = "itemsUSD"
  [totalCases] = "totalCases"
  [totalShipped] = "totalShipped"
  [totalInvoiced] = "totalInvoiced"
  [totalExtCost] = "totalExtCost"
  [totalMarketing] = "totalMarketing"
  [totalBilling] = "totalBilling"
  [totalTerms] = "totalTerms"
  [totalCommission] = "totalCommission"
  [totalKalcor] = "totalKalcor"
  [cndFrtCostCase] = "cndFrtCostCase"
  [storageCostCase] = "storageCostCase"
  [totalCndFrtCostCase] = "totalCndFrtCostCase"
  [totalStorageCostCase] = "totalStorageCostCase"
  [totalPurchaseCaseCostExt] = "totalPurchaseCaseCostExt"
  [totalFreightCostExt] = "totalFreightCostExt"
  [totalClearing] = "totalClearing"
  [totalDuty] = "totalDuty"
  [totalRoyalty] = "totalRoyalty"
  [totalVendor] = "totalVendor"
  [budgetActual] = "budgetActual"
  [usdVendorCost] = "usdVendorCost"
  [usdFrtCost] = "usdFrtCost"
  [usdClearing] = "usdClearing"
  [usdDuty] = "usdDuty"
  [usdRoyalty] = "usdRoyalty"
  [indexMarketing] = "indexMarketing"
  [indexBilling] = "indexBilling"
  [indexTerms] = "indexTerms"
  [indexCommission] = "indexCommission"
  [indexKalcor] = "indexKalcor"
  [indexFreightCnd] = "indexFreightCnd"
  [indexStorage] = "indexStorage"
  [indexVendor] = "indexVendor"
  [indexClearing] = "indexClearing"
  [indexDuty] = "indexDuty"
  [indexFreightUSD] = "indexFreightUSD"
  [indexRoyalty] = "indexRoyalty"
  [indexFobDiff] = "indexFobDiff"
  >
</app-profit-loss>
</div>
<div *ngIf="reportName==''">
<h4>Order List</h4>
<h5>Filter</h5>
<div class="col-lg-44">
    <label>
      Order Status:
      <select [(ngModel)]="orderStatusSelected" (change)="resetFilters()">
        <option value="Invoiced">Invoiced</option>
        <option value="Closed">Closed</option>
      </select>
    </label>

    <label class="ms-3">
      Sales Order No:
      <select [(ngModel)]="orderNumberSelected" (change)="resetFilters()">
        <option [value]=""></option>
        <option *ngFor="let o of orderNumberOptions" [value]="o.id">{{ o.salesOrderNo }}</option>
      </select>
    </label>

    <label class="ms-3">
      Purchase Order:
      <select [(ngModel)]="purchaseOrderSelected" (change)="resetFilters()">
        <option [value]=""></option>
        <option *ngFor="let p of purchaseOrderOptions" [value]="p.id">{{ p.purchaseOrder }}</option>
      </select>
    </label>

    <label class="ms-3">
      Bill to:
      <select [(ngModel)]="billToSelected" (change)="resetFilters()">
        <option [value]=""></option>
        <option *ngFor="let b of billToOptions" [value]="b.billToId">{{ b.company }}</option>
      </select>
    </label>

    <label class="ms-3">
      Ship to:
      <select [(ngModel)]="shipToSelected" (change)="resetFilters()">
        <option [value]=""></option>
        <option *ngFor="let s of shipToOptions" [value]="s.shipToId">{{ s.company }}</option>
      </select>
    </label>

    <label class="ms-3">
      Vendor:
      <select [(ngModel)]="vendorSelected" (change)="resetFilters()">
        <option [value]=""></option>
        <option *ngFor="let v of vendorOptions" [value]="v.vendorId">{{ v.company }}</option>
      </select>
    </label>
    <button type="button" class="btn btn-primary mt-0 ms-3" (click)="getDefaultDataScreen('Invoiced')">Reset</button>

  </div>
  <table class="table table-striped">
    <thead>
      <tr>
        <th scope="col">Order Status</th>
        <th scope="col">Order Type</th>
        <th scope="col">Sales Order No</th>
        <th scope="col">Purchase Order</th>
        <th scope="col">Sales Order Date</th>
        <th scope="col">Shipment Date</th>
        <th scope="col">Delivered Date/Appt</th>
        <th scope="col">Bill To</th>
        <th scope="col">Ship To</th>
        <th scope="col">Broker</th>
        <th scope="col">Vendor</th>
        <th scope="col">Sales Amount</th>
        <th scope="col">Profit & Loss</th>
        <th scope="col">Print</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let o of orders; index as i">
        <td>{{ o.orderStatus }}</td>
        <td>{{ o.orderType }}</td>
        <td>{{ o.salesOrderNo }}</td>
        <td>{{ o.purchaseOrder }}</td>
        <td>{{ displayDate(o.salesOrderDate, '') }}</td>
        <td>{{ displayDate(o.shipmentDate, '') }}</td>
        <td>{{ displayDate(o.deliveredDateAppt, 'T') }}</td>
        <td>{{ o.billTo }}</td>
        <td>{{ o.shipTo }}</td>
        <td>{{ o.broker }}</td>
        <td>{{ o.vendor }}</td>
        <td>{{ o.salesAmount | currency  }}</td>
        <td>
          <a (click)="onRowClicked(o)" class="pointer">
            <u class="text-primary">Pofit & Loss</u>
          </a>
        </td>
        <td>
          <a (click)="printProcess('Sales Order Profit & Loss', o)" class="pointer">
            <u class="text-primary">Print</u>
          </a>
        </td>        
      </tr>
    </tbody>
  </table>
  <div *ngIf="orders!==undefined">
    <ngb-pagination [(page)]="page" [pageSize]="pageSize" [collectionSize]="collectionSize" [maxSize]="5"
      (pageChange)="loadPage($event)"></ngb-pagination>
  </div>
</div>