import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { Roles } from '../../models/roles.model';
import { RolesService } from '../../services/roles.service';

@Injectable()
export class RolesResolver implements Resolve<Roles> {
  constructor(private rolesService: RolesService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Roles> {
    const idField = 'id';
    const id =  parseInt(route.params[idField], 10);

    if (isNaN(id)) {
        return this.rolesService.getAll();
    }
    if (id === 0) {
      return;
    }
  return this.rolesService.getOne(id);
  }
}
