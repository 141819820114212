import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { OrderService } from 'src/app/services/order.service';
import { VendorData } from 'src/app/models-azure/vendor-data.model';
import { DataLink } from 'src/app/models/data-link.model';
import { DataLinkService } from 'src/app/services/data-link.service';
import { VendorService } from 'src/app/services/vendor.service';
import { Vendor } from 'src/app/models/vendor-model';
import { WeekKalcorService } from 'src/app/services/week-kalcor.service';
import { AzureService } from 'src/app/services-azure/azure.service';
import { ProjectionsService } from 'src/app/services/projections.service';
import { ShipToAddressService } from 'src/app/services/ship-to-address.service';
import { BrokerService } from 'src/app/services/broker.service';
import { Broker } from 'src/app/models/broker.model';
import { CustomerRetailService } from 'src/app/services/customer-retail.service';
import { ShipToBrokerService } from 'src/app/services/ship-to-broker.service';
import { ShipToBroker } from 'src/app/models/shipto-broker';
import { Big } from 'big.js';
import { BannerRegions } from 'src/app/models/banner-regions.model';


@Component({
  selector: 'app-azure-link',
  templateUrl: './azure-link.component.html',
  styleUrls: ['./azure-link.component.scss']
})
export class AzureLinkComponent implements OnInit {
  year = [];
  vendorYear = [];
  items = [];
  sales = [];
  buttonClicker = [];
  buttonRemover = [];
  selectedVendor: number;
  processIndexItem: number;
  processIndex: number;
  processIndexCases: number;
  vendorId: number;
  totalCount: number;
  processIndexSales: number;
  processIndexSalesItems: number;
  processIndexBudget: number;
  processIndexShip: number;
  processIndexPeriod: number;
  dataLink: DataLink;
  vendor: Vendor[];
  dataLinks: DataLink[];
  yearRequested: any;
  processVendIndex: number;
  page: number;
  pageSize = 35;
  count: number;
  periods = [];
  kstLineCount = 0;
  kstTotalExtension = 0;
  projection: any;
  shipTos = [];
  shipToSelected = [];
  shipToOptions = [];
  brokerOriginal: Broker[];
  shipToBrokerOriginal: ShipToBroker[];
  regionShipTo = [];
  regionBanner = [];
  stores = [];
  currentPeriod: number;
  spinner = [];
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private orderService: OrderService,
    private dataLinkService: DataLinkService,
    private vendorService: VendorService,
    private weekKalcorService: WeekKalcorService,
    private azureService: AzureService,
    private projectionsService: ProjectionsService,
    private shipToService: ShipToAddressService,
    private bannerService: CustomerRetailService,
    private brokerService: BrokerService,
    private shipToBrokerService: ShipToBrokerService

  ) {}

  ngOnInit(): void {
    this.periods = [];
    this.currentPeriod = 0;
    this.vendorService.getCompany().subscribe(vend => {
      this.vendor = vend;
      this.brokerService.getAll().subscribe(br => {
        this.brokerOriginal = br;
      })
    })
    this.displayItems();
  }

  displayItems() {
    const theDate = new Date();
    const middle = theDate.getFullYear();
    this.year.push(2025);
    this.year.push(2024);
    this.year.push(2023);
    this.year.push(2022);
    this.year.push(2021);
    this.year.push(2020);
    this.spinner.push('');
    this.spinner.push('');
    this.spinner.push('');
    this.spinner.push('');
    this.spinner.push('');
    this.spinner.push('');
  }
  clickedItem(i: number) {
    this.router.navigate(['/portal/vendor-data', this.year[i]]);
  }
  run(i: number) {
    this.yearRequested = this.year[i];
    this.spinner[i] = '...Updating';
    this.weekKalcorService.getYear(this.yearRequested).subscribe(data => {
      this.periods = data;
      this.loadVendorYear();
    })
  }
  loadVendorYear() {
    this.dataLinkService.getList(this.yearRequested, this.page, this.pageSize).subscribe(data => {
      this.vendorYear = data.item;
      this.page = data.page;
      this.count = data.count;
      this.processIndex = -1;
      this.initializeVendors();
    })
  }
  initializeVendors() {
    //process one vendor at a time
    this.processIndex++;
    if (this.processIndex >= this.vendorYear.length) {
      //all vendors have been processed update status
      this.updateStatus();
    } else {
      this.getItems();
    }
  }
  getItems() {  
    this.azureService.getFilter(this.vendorYear[this.processIndex].salesYear, this.vendorYear[this.processIndex].vendorId, 0, 0).subscribe(data => {
      this.items = data.item;
      if (this.items.length > 0) {
        this.processIndexItem = -1;
        this.deleteItems();
      } else {
        this.processIndexItem = -1;
        this.getSalesData();
      }
    }, error => {
      alert('Error from azzure:' + error.message)
    }) 
  }
  deleteItems() {
    this.vendorYear[this.processIndex].status = "Deleting";
    this.totalCount++;
    this.vendorYear[this.processIndex].counter = this.totalCount;
    this.processIndexItem++;
    if (this.processIndexItem < this.items.length) {
      this.azureService.remove(this.items[this.processIndexItem].id).subscribe(data => {
        this.deleteItems();
      }, error => {
        alert('Error received from Azure: ' + error.message);
      })
    } else {
      this.getItems();
    }
  }
  getSalesData() {
    this.vendorYear[this.processIndex].status = "Updating";
    this.totalCount = 0;
    this.processIndexSales = -1;
      //get only sales
    this.orderService.getDetailsSP(this.vendorYear[this.processIndex].salesYear, this.vendorYear[this.processIndex].vendorId).subscribe(data => {
      this.sales = data.order;
      this.kstLineCount = 0;
      this.kstTotalExtension = 0;
      this.sales.forEach(item => {
        item.items.forEach(itm => {
          itm.cases = itm.cases === null ? 0 : itm.cases;
            itm.invoiced = itm.invoiced === null ? 0 : itm.invoiced;
            itm.caseCost = itm.caseCost === null ? 0 : itm.caseCost;
  
            if (item.orderStatus == 'Sales Order' || item.orderStatus == 'Shipped') {                     
              itm.extCaseCost = itm.caseCost * itm.cases;
            } else {           
              itm.extCaseCost = itm.caseCost * itm.invoiced;
            }
  
            const ext = itm.extCaseCost === null ? 0 : itm.extCaseCost;
            this.kstTotalExtension += ext;
            this.kstLineCount += ext > 0 ? 1 : 0;
        });
      })
      this.totalCount = 0;
      this.processIndexSales = -1;
      this.processIndexSalesItems = -1;
      this.insertAzureSales();
    })
  }
  insertAzureSales() {
    this.processIndexSales++;
    if (this.processIndexSales >= this.sales.length) {
      this.updateDataLink();
      this.getBudgetData();
    } else {
      this.processIndexSalesItems = -1;
      this.insertAzureSalesItems();
    }
  }
  insertAzureSalesItems() {
    this.processIndexSalesItems++;
    if (this.processIndexSalesItems >= this.sales[this.processIndexSales].items.length) {
      //process next sales
      this.insertAzureSales();
    } else {
      const cases = this.sales[this.processIndexSales].items[this.processIndexSalesItems].cases;
      const casesInvoiced = this.sales[this.processIndexSales].items[this.processIndexSalesItems].invoiced;
      if (cases == 0 && casesInvoiced == 0) {
        this.insertAzureSalesItems();
      } else {
        //process each items
      this.totalCount++;
      this.vendorYear[this.processIndex].counter = this.totalCount;
      let x = new VendorData();
      x.id = 0;
      x.orderType = this.sales[this.processIndexSales].orderType;
      x.salesOrderNo = this.sales[this.processIndexSales].salesOrderNo;
      x.purchaseOrder = this.sales[this.processIndexSales].purchaseOrder;
      x.orderStatus = this.sales[this.processIndexSales].orderStatus;
      x.vendor = this.sales[this.processIndexSales].vendor;
      x.brand = this.sales[this.processIndexSales].items[this.processIndexSalesItems].brand;
      x.category = this.sales[this.processIndexSales].items[this.processIndexSalesItems].category;
      x.orderCode = this.sales[this.processIndexSales].items[this.processIndexSalesItems].orderCode;
      x.itemDescription = this.sales[this.processIndexSales].items[this.processIndexSalesItems].itemDescription;
      x.size = this.sales[this.processIndexSales].items[this.processIndexSalesItems].size;
      x.fullItemDescription = this.getValue('fullItemDescription');
      x.period = this.getValue('periodYear');
      x.year = this.getValue('year');
      x.cases = this.sales[this.processIndexSales].items[this.processIndexSalesItems].cases;
      x.casesInvoiced = this.sales[this.processIndexSales].items[this.processIndexSalesItems].invoiced;
      x.caseCost = this.sales[this.processIndexSales].items[this.processIndexSalesItems].caseCost;
      x.extCaseCost = this.sales[this.processIndexSales].items[this.processIndexSalesItems].extCaseCost;
      x.month = this.getValue('monthYear');
      x.shipmentDate = this.sales[this.processIndexSales].shipmentDate;
      x.salesManager = this.sales[this.processIndexSales].salesManager;
      x.typeOfAccount = this.sales[this.processIndexSales].typeOfAccount1;
      x.dotLocation = this.sales[this.processIndexSales].dotLocation;
      x.customer = this.sales[this.processIndexSales].billTo;
      x.cLatitude = this.sales[this.processIndexSales].latitudeC;
      x.cLongitude = this.sales[this.processIndexSales].longitudeC;
      x.customerZip = this.sales[this.processIndexSales].billZipPostalCode;
      x.shipTo = this.sales[this.processIndexSales].shipTo;
      x.sLatitude = this.sales[this.processIndexSales].latitudeS;
      x.sLongitude = this.sales[this.processIndexSales].longitudeS;
      x.shipToZip = this.sales[this.processIndexSales].shipZipPostalCode;
      // x.broker = this.getValue('broker');
      x.broker = this.sales[this.processIndexSales].broker;
      x.broker2 = this.sales[this.processIndexSales].broker2;
      x.vendorId = this.sales[this.processIndexSales].vendorId;
      this.azureService.create(x).subscribe(data => {
        this.insertAzureSalesItems();
      }
        )
      }
      
    }
  }
  getValue(variable) {
    let retValue;
      let shipDate = new Date(this.sales[this.processIndexSales].shipmentDate);
      switch (variable) {
        case "broker":
          let broker = this.sales[this.processIndexSales].broker ? this.sales[this.processIndexSales].broker : "";
          let broker2 = this.sales[this.processIndexSales].broker2 ? this.sales[this.processIndexSales].broker2 : "";
          if (broker) {
            if (broker2) {
              broker += " / " + broker2;
            }
          } else {
            broker = broker2;
          }
          retValue = broker;
          break;
        case "year":
            retValue = shipDate.getFullYear() + '';
            break;
        case "monthYear":
            const month = ["JAN","FEB","MAR","APR","MAY","JUN","JUL","AUG","SEP","OCT","NOV","DEC"];
            const shipMonth = shipDate.getMonth(); // 0 to 11
            const shipMonthName = month[shipMonth];
            let monthYear = "0" + (shipMonth + 1);
            monthYear = monthYear.slice(-2) + "-" + month[shipMonth] + "-" + shipDate.getFullYear();
            retValue = monthYear;
            break;
          
          case "periodYear":
              retValue = '';
              const shippedDate = this.sales[this.processIndexSales].shipmentDate.substring(0, 10);
              for (let i = 0; i < this.periods.length; i++) {
                if (shippedDate >= this.periods[i].weekStartDate && shippedDate <= this.periods[i].weekEndDate) { 
                  let periodYear = "0" + this.periods[i].period + "-PERIOD";             
                  if (this.periods[i].period > 9) {
                    periodYear = this.periods[i].period + "-PERIOD";
                  } 
                  retValue = periodYear;
                  break;
                }
              }
              break;  
            case "periodWeek":
              retValue = '';
              const shippingDate = this.sales[this.processIndexSales].shipmentDate.substring(0, 10);
              for (let i = 0; i < this.periods.length; i++) {
                if (shippingDate >= this.periods[i].weekStartDate && shippingDate <= this.periods[i].weekEndDate) { 
                  let periodWeek = this.periods[i].week;             
                  retValue = periodWeek;
                  break;
                }
              }
              break;  
          case "fullItemDescription":
            retValue = this.sales[this.processIndexSales].items[this.processIndexSalesItems].itemDescription + ' ' 
                        + this.sales[this.processIndexSales].items[this.processIndexSalesItems].size;
          break;
  
        default:
          retValue = this.sales[this.processIndexSales][variable];
          // if (variable.indexOf('latitude') > -1 || variable.indexOf('longitude') > -1) {
          //   retValue = retValue === null ? retValue : retValue + '';
          // }
          break;
      }
      return retValue;
  }
  getBudgetData() {
    this.vendorYear[this.processIndex].status = "Updating";
    this.totalCount = 0;
    this.processIndexBudget = -1;
    this.projection = [];
    this.projectionsService.getYearBudget(this.vendorYear[this.processIndex].vendorId, this.vendorYear[this.processIndex].salesYear).subscribe(projData => {
      this.projection = projData;
      this.kstLineCount = 0;
      this.kstTotalExtension = 0;
      this.initializeAzureBudget();
    })
  }
  initializeAzureBudget() {
    this.processIndexBudget++;
    if (this.processIndexBudget >= this.projection.length) {
      this.updateDataLinkBudget();
    } else {
      //clear arrays to process next projection by bannerId
      this.regionBanner = [];
      this.stores = [];
      this.shipToBrokerOriginal = [];
      this.shipToOptions = [];
      this.shipToSelected = [];
      this.processIndexCases = 0;
      const bannerId = this.projection[this.processIndexBudget].bannerId * 1;
      const shipToIds = this.projection[this.processIndexBudget].shipToIds;
      this.bannerService.getRegion(bannerId).subscribe(data => {
        data.forEach(e => {
          const bannerRegion = {
            bannerRegion: e.bannerRegion,
            noOfStores: e.noOfStores
          }
          this.regionBanner.push(bannerRegion);
        });  
        this.regionShipTo = [];
        this.shipTos = shipToIds.split(',');
        this.processIndexShip = -1;
        this.getShipTo();
      })
    }
  }
  getShipTo() {
    this.processIndexShip++;
    if (this.processIndexShip >= this.shipTos.length) {
      this.processIndexShip = -1;
      this.processEachShipTo();
    } else {
      const shipId = this.shipTos[this.processIndexShip] * 1;
      this.shipToService.getFilteredListing('Active', shipId, 0, 1, 999).subscribe(data => {
        this.shipToOptions = data.item;
        this.compareRegions();
        this.shipToBrokerService.getList(this.projection[this.processIndexBudget].listingId).subscribe(s => {
          this.shipToBrokerOriginal = s;
        this.getBrokers();
        this.getShipTo();
        })
      })
    }
  }
  compareRegions() {
    this.shipToOptions.forEach(e => {
      const bannerIndex = this.regionBanner.findIndex(f => f.bannerRegion == e.shipRegion);
      if (bannerIndex > -1) {
        const indexStores = this.stores.findIndex(f => f.shipId == e.id);
        if (indexStores == -1) {
          const x = {
            shipId: e.id,
            noStores: this.regionBanner[bannerIndex].noOfStores
          }
          this.stores.push(x);
        } else {
          this.stores[indexStores].noStores += this.regionBanner[bannerIndex].noOfStores;
        }
        const index = this.regionShipTo.findIndex(f => f.id == e.id && f.shipRegion == e.shipRegion);
        if (index == -1) {
          const shipIndex = this.shipToSelected.findIndex(f => f.id == e.id);
          if (shipIndex == -1) {
            this.shipToSelected.push(e);        
          }
        }
  
      }
    })
  }
  getBrokers() {
    this.shipToSelected.forEach(ship => {
      const shipToBrokerIndex = this.shipToBrokerOriginal.findIndex(f => f.shipToId == ship.id);
      let broker1 = null;
      let broker2 = null;
      if (shipToBrokerIndex > -1) {
        broker1 = this.shipToBrokerOriginal[shipToBrokerIndex].brokerId;
        broker2 = this.shipToBrokerOriginal[shipToBrokerIndex].broker2Id;
      }
      const broker1Index = this.brokerOriginal.findIndex(f => f.id == broker1);
      if (broker1Index > -1) {
        broker1 = this.brokerOriginal[broker1Index].company;
      }
      const broker2Index = this.brokerOriginal.findIndex(f => f.id == broker2);
      if (broker2Index > -1) {
        broker2 = this.brokerOriginal[broker2Index].company;
      }
      //7.10.24 only displaying one broker at this time 
      // if (broker1) {
      //   if (broker2) {
      //     broker1 += " / " + broker2;
      //   }
      // } else {
      //   broker1 = broker2;
      // }
      this.projection[this.processIndexBudget].broker = broker1;
      this.projection[this.processIndexBudget].broker2 = broker2;
    })
  }
  processEachShipTo() {
    this.processIndexShip++;
    //process one ship to a time
    if (this.processIndexShip >= this.shipToSelected.length) {
      this.initializeAzureBudget();
    } else {
      this.processIndexPeriod = -1;
      this.insertAzureBudget();
    }
  }
  insertAzureBudget() {
    this.processIndexPeriod++;
    if (this.processIndexPeriod >= this.periods.length) {
      //process next ship to for this banner
      this.processEachShipTo();
    } else {
      //avoid duplicating periods
      if (this.currentPeriod == this.periods[this.processIndexPeriod].period) {
        this.insertAzureBudget();
        return;
      }
      //get cases by week
      const tempWeeks = [this.projection[this.processIndexBudget].week1, this.projection[this.processIndexBudget].week2, this.projection[this.processIndexBudget].week3, this.projection[this.processIndexBudget].week4, this.projection[this.processIndexBudget].week5, this.projection[this.processIndexBudget].week6, this.projection[this.processIndexBudget].week7, this.projection[this.processIndexBudget].week8, this.projection[this.processIndexBudget].week9, this.projection[this.processIndexBudget].week10,
      this.projection[this.processIndexBudget].week11, this.projection[this.processIndexBudget].week12, this.projection[this.processIndexBudget].week13, this.projection[this.processIndexBudget].week14, this.projection[this.processIndexBudget].week15, this.projection[this.processIndexBudget].week16, this.projection[this.processIndexBudget].week17, this.projection[this.processIndexBudget].week18, this.projection[this.processIndexBudget].week19, this.projection[this.processIndexBudget].week20,
      this.projection[this.processIndexBudget].week21, this.projection[this.processIndexBudget].week22, this.projection[this.processIndexBudget].week23, this.projection[this.processIndexBudget].week24, this.projection[this.processIndexBudget].week25, this.projection[this.processIndexBudget].week26, this.projection[this.processIndexBudget].week27, this.projection[this.processIndexBudget].week28, this.projection[this.processIndexBudget].week29, this.projection[this.processIndexBudget].week30,
      this.projection[this.processIndexBudget].week31, this.projection[this.processIndexBudget].week32, this.projection[this.processIndexBudget].week33, this.projection[this.processIndexBudget].week34, this.projection[this.processIndexBudget].week35, this.projection[this.processIndexBudget].week36, this.projection[this.processIndexBudget].week37, this.projection[this.processIndexBudget].week38, this.projection[this.processIndexBudget].week39, this.projection[this.processIndexBudget].week40,
      this.projection[this.processIndexBudget].week41, this.projection[this.processIndexBudget].week42, this.projection[this.processIndexBudget].week43, this.projection[this.processIndexBudget].week44, this.projection[this.processIndexBudget].week45, this.projection[this.processIndexBudget].week46, this.projection[this.processIndexBudget].week47, this.projection[this.processIndexBudget].week48, this.projection[this.processIndexBudget].week49, this.projection[this.processIndexBudget].week50,
      this.projection[this.processIndexBudget].week51, this.projection[this.processIndexBudget].week52];   
      let cases = 0;
      //group weeks into periods
      for (let i = 0; i < this.periods.length; i++) {
        if (this.periods[i].period != 0) {
          if (this.periods[i].period == this.periods[this.processIndexPeriod].period) {
            let casesPerWeek = tempWeeks[i];
            //calculate cases by stores
            if (casesPerWeek > 0) {
              const storeIndex = this.stores.findIndex(f => f.shipId == this.shipToSelected[this.processIndexShip].id);
              if (storeIndex > -1) {
                const noStores = this.projection[this.processIndexBudget].noStores * 1;
                const noStoresIndex = this.stores[storeIndex].noStores * 1;
                if (casesPerWeek < noStores) {
                  const bigNum: any = Big(casesPerWeek)
                  .div(noStores)
                  .round(2);
                  const casesWeek = bigNum * 1;
    
                  const bigNum2: any = Big(casesWeek)
                  .times(noStoresIndex)
                  .round(0);
                  cases += bigNum2 * 1;
    
                } else {
                  const bigNum: any = Big(casesPerWeek)
                  .div(noStores)
                  .round(0);
                  const casesWeek = bigNum * 1;
    
                  const bigNum2: any = Big(casesWeek)
                  .times(noStoresIndex)
                  .round(0);
                  cases += bigNum2 * 1;
                }
              }
            }
            //adjust period naming
            this.currentPeriod = this.periods[i].period;
            let periodYear = "0" + this.periods[i].period + "-PERIOD";
            if (this.periods[i].period > 9) {
              periodYear = this.periods[i].period + "-PERIOD";
            }
            this.projection[this.processIndexBudget].period = periodYear;
          }
        }
      }
      //skip creating records where cases equal 0
      if (cases == 0) {
        this.insertAzureBudget();
      } else {
        //process each budget by banner
        this.totalCount++;
        this.vendorYear[this.processIndex].counter = this.totalCount;
        
        this.processIndexCases = cases;
  
        let x = new VendorData();
        x.id = 0;
        x.vendor = this.projection[this.processIndexBudget].vendor;
        x.vendorId = this.projection[this.processIndexBudget].vendorId;
        x.banner = this.projection[this.processIndexBudget].banner;
        x.headOffice = this.projection[this.processIndexBudget].customerEnd;
        x.brand = this.projection[this.processIndexBudget].brand;
        x.category = this.projection[this.processIndexBudget].category;
        x.orderType = this.refineValue('orderType');
        x.orderStatus = x.orderType;
        x.orderCode = this.projection[this.processIndexBudget].productOrderCode;
        x.itemDescription = this.projection[this.processIndexBudget].itemDescription;
        x.size = this.projection[this.processIndexBudget].size;
        x.fullItemDescription = this.refineValue('fullItemDescription');
        x.year = this.projection[this.processIndexBudget].year;
        x.period = this.projection[this.processIndexBudget].period;
        x.casesBudget = cases;
        x.caseCost = this.projection[this.processIndexBudget].caseCost;
        x.extCaseCostBudget = this.refineValue('extCaseCostBudget');
        x.salesManager = this.projection[this.processIndexBudget].salesManager;
        x.typeOfAccount = this.projection[this.processIndexBudget].typeOfAccount;
        x.dotLocation = this.shipToSelected[this.processIndexShip].location;
        x.customer = this.projection[this.processIndexBudget].customer;
        x.cLatitude = this.projection[this.processIndexBudget].clatitude;
        x.cLongitude = this.projection[this.processIndexBudget].clongitude;
        x.customerZip = this.projection[this.processIndexBudget].czip;
        x.budgetType = this.projection[this.processIndexBudget].typeOfPromotion;
        x.shipTo = this.shipToSelected[this.processIndexShip].company;
        x.shipToZip = this.shipToSelected[this.processIndexShip].zipPostalCode;
        x.broker = this.projection[this.processIndexBudget].broker;
        x.broker2 = this.projection[this.processIndexBudget].broker2;
  
        const ext = x.extCaseCostBudget === null ? 0 : x.extCaseCostBudget;
        this.kstTotalExtension += ext;
        this.kstLineCount += ext > 0 ? 1 : 0;
  
        this.azureService.create(x).subscribe(data => {
          this.insertAzureBudget();
        })
      }
    }
  }
  refineValue(variable) {
    let retValue;
      switch (variable) {
          case "orderType":
          if (this.projection[this.processIndexBudget].typeOfPromotion == 'Budget') {
            retValue = "Budget";
          } 
          if (this.projection[this.processIndexBudget].typeOfPromotion == 'Budget Promotion') {
            retValue = "Budget Promotion";
          }
          if (this.projection[this.processIndexBudget].typeOfPromotion == 'Budget Contingency') {
            retValue = "Budget Contingency"
          }
          break;
        case "extCaseCostBudget":
          const caseCost = this.projection[this.processIndexBudget].caseCost;
          const cases = this.processIndexCases;
          this.projection[this.processIndexBudget].caseCost * this.processIndexCases;
          const bigNum: any = Big(caseCost)
                  .times(cases)
                  .round(2);
                  retValue = bigNum * 1;
          break;
          case "fullItemDescription":
            retValue =  this.projection[this.processIndexBudget].itemDescription + ' ' 
                        + this.projection[this.processIndexBudget].size;
          break;
      }
      return retValue;
  }
  updateDataLink() {
    this.dataLink = new DataLink();
    this.vendorYear[this.processIndex].status = "Sales Completed";
    this.buttonClicker[this.processIndex] = false;
    const todaysDate = new Date();
    this.dataLink.dataLinkId = this.vendorYear[this.processIndex].dataLinkId;
    this.dataLink.lastUpdate = todaysDate;
    this.vendorYear[this.processIndex].lastUpdate = todaysDate;
    this.dataLink.userId = this.getUserId();
    this.vendorYear[this.processIndex].name = this.getUserName();
    this.dataLinkService.update(this.dataLink).subscribe(data => {
      // alert("Click Ok to process Budgets. " + "\r\n" + "Total Sales Items: " + this.kstLineCount + "\r\n" + " Total Extension Case Cost "+ this.kstTotalExtension.toFixed(2));
    })
  }
  updateDataLinkBudget() {
    this.dataLink = new DataLink();
    this.vendorYear[this.processIndex].status = "Budget Completed";
    this.buttonClicker[this.processIndex] = false;
    const todaysDate = new Date();
    this.dataLink.dataLinkId = this.vendorYear[this.processIndex].dataLinkId;
    this.dataLink.lastUpdate = todaysDate;
    this.vendorYear[this.processIndex].lastUpdate = todaysDate;
    this.dataLink.userId = this.getUserId();
    this.vendorYear[this.processIndex].name = this.getUserName();
    this.dataLinkService.update(this.dataLink).subscribe(data => {
      // alert("All done. " + "\r\n" + "Total Budget Items: " + this.kstLineCount + "\r\n" + " Total Budget Extension Case Cost "+ this.kstTotalExtension.toFixed(2));
    })
    this.initializeVendors();
  }
  getUserId() {
    let userId = 0;
    const currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
    if (currentUser) {
      userId = currentUser.id;  
    }
    return userId;
  }
  getUserName() {
    let userName = '';
    const currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
    if (currentUser) {
      userName = currentUser.firstName;  
    }
    return userName;
  }
  updateStatus() {
    this.spinner = [];
    alert('Year ' + this.yearRequested + ' successfully updated.');
  }
}
