<h4>Customer List </h4>
<h5>Filter</h5>
<div class="col-lg-44">
  <label>
    Active:
    <select [(ngModel)]="status" (change)="loadPage('status')">
      <option value="Active">Active</option>
      <option value="Not Active">Not Active</option>
    </select>
  </label>
  <label class="ms-3">
      Company:
     <select [(ngModel)]="company" (change)="loadPage('company')">
      <option [value]=""></option>
      <option *ngFor="let c of companyOptions" [value]="c.company">{{ c.company }}</option>
    </select>
  </label>
</div>

<table class="table table-striped">
  <thead>
  <tr>
    <th scope="col">Active</th>
    <th scope="col">Company</th>
    <th scope="col">Address</th>
    <th scope="col">City</th>
    <th scope="col">State/Province</th>
    <th scope="col">Country/Region</th>
    <th scope="col">Zip Code</th>
    <th scope="col">Currency Code</th>
    <th scope="col">Account Number</th>
    <th scope="col">Terms</th>
    </tr>
  </thead>
  <tbody>
  <tr *ngFor="let c of customers; index as i">
    <td>{{ c.activeItem }}</td>
    <td> 
      <a class="pointer" (click)="onRowClicked(c)">
        <u class="text-primary">{{ c.company }}</u>
      </a>
      </td>
    <td>{{ c.address }}</td>
    <td>{{ c.city }}</td>
    <td>{{ c.stateProvince }}</td>
    <td>{{ c.countryRegion }}</td>
    <td>{{ c.zipPostalCode }}</td>
    <td>{{ c.currencyCode }}</td>
    <td>{{ c.accountNo }}</td>
    <td>{{ c.paymentTerms }}</td>
  </tr>
  </tbody>
</table>
<div *ngIf="customers!==undefined">
  <ngb-pagination [(page)]="page" [pageSize]="pageSize" [collectionSize]="collectionSize" [maxSize]="5"
    (pageChange)="loadPage('')"></ngb-pagination>
</div>

<div class="btn-group" role="group" aria-label="Basic radio toggle button group">
      
  <input class="btn-check" type="radio" name="radio1" id="choice1" [(ngModel)]="pageSize"  (change)="loadPage($event)" autocomplete="off" checked [value]="25">
  <label class="btn btn-outline-primary" for="choice1">
    25
  </label>

  <input class="btn-check" type="radio" name="radio1" id="choice2" [(ngModel)]="pageSize"  (change)="loadPage($event)" [value]="50" autocomplete="off">
  <label class="btn btn-outline-primary" for="choice2">
    50
  </label>

  <input class="btn-check" type="radio" name="radio1" id="choice3" [(ngModel)]="pageSize"  (change)="loadPage($event)" [value]="100" autocomplete="off">
  <label class="btn btn-outline-primary" for="choice3">
    100
  </label>

</div>

<ngb-alert *ngIf="successMessage" [type]="alertType" (close)="successMessage = null">{{ successMessage }}</ngb-alert>