import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { OrderService } from '../../services/order.service';
import { Incoming, Transfer } from '../../models/bucket.model';
import { BrokerPayment } from '../../models/broker.model';
import * as jsPDF from 'jspdf';
import * as html2pdf from 'html2pdf.js';
import { Big } from 'big.js';
import { CreditData } from '../../models/credit-model';
import { Broker } from '../../models/broker.model';
import { BrokerService } from '../../services/broker.service';
import { ShipToAddressService } from '../../services/ship-to-address.service';
import { ShipToAddress } from '../../models/shipto.model';

@Component({
  selector: 'app-commission',
  templateUrl: './commission.component.html',
  styleUrls: ['./commission.component.scss']
})
export class CommissionComponent implements OnInit {
  credit: CreditData;
  transfer: Transfer[];
  payment: BrokerPayment[];
  yearSelected: number;
  monthSelected: [];
  months: []
  vendorSelected: number;
  customerSelected: number;
  brokerSelected: number;
  bucketSelected: string;
  order: any;
  balfwd: number;
  balanceForwardCredit: number;
  balanceForwardActual: number;
  balanceForwardBudget: number;
  balanceForwardTransfer: number;
  balanceForwardPayment: number;
  creditData: any;
  balFwdBeginningMonth = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  balFwdAfterIncomingMonth = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  balFwdOutgoingMonth = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  balFwdTransferingMonth = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  balEndCurrentMonth = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  incoming = [[],[],[],[],[],[],[],[],[],[],[],[]];
  outgoing = [[],[],[],[],[],[],[],[],[],[],[],[]];
  transfering = [[],[],[],[],[],[],[],[],[],[],[],[]];
  paying = [[],[],[],[],[],[],[],[],[],[],[],[]];
  buckets = [false, false , false, false, false, false, false, false, false, false, false, false];
  bucketsOrg = [false, false , false, false, false, false, false, false, false, false, false, false];
  numberMonthsRequested: number;
  monthName = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];
  customerName: string;
  vendorName: string;
  brokerName: string;
  reportType: string;
  orderType: string;
  incomingSalesTitle: string;
  outgoingSalesTitle: string;
  shipTo: ShipToAddress[];
  brokers: Broker[];

// pdf
  title: string;
  pageBreak: any;
  reportName = '';
  reportNames = [
    {
    Name: 'Bucket Report Commission', 
    Id: 'my-bucket-commission',
    filename: 'Bucket Report Commission',
    Orientation: 'landscape',
    TopMargin: .5,
    LRMargin: .2
    }
  ];
  currentIndex: number;
  firstSelectMonthIndex: number;
  bucketIndex = [
    { Bucket: 'Billing', index: -1 },
    { Bucket: 'Terms', index: -1 },
    { Bucket: 'Marketing', index: -1 },
    { Bucket: 'Commission', index: 0 },
    { Bucket: 'Kalcor', index: -1 },
    { Bucket: 'Freight CND', index: -1 },
    { Bucket: 'Storage', index: -1 },
    { Bucket: 'Exchange', index: -1 },
    { Bucket: 'Clearing', index: -1 },
    { Bucket: 'Duty', index: -1 },
    { Bucket: 'Freight USD', index: -1 }
  ]

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private checkRef: ChangeDetectorRef,
    private orderService: OrderService,
    private brokerService: BrokerService,
    private shipToService: ShipToAddressService
  ) { }

  ngOnInit() {
    this.yearSelected = 0;
    this.monthSelected = [];
    this.vendorSelected = 0;
    this.customerSelected = 0;
    this.brokerSelected = 0;
    this.bucketSelected = '';
    this.balfwd = 0;
    this.yearSelected = history.state.year !== undefined ? history.state.year : 0;
    this.monthSelected = history.state.month !== undefined ? history.state.month : 0;
    this.vendorSelected = history.state.vendor !== undefined ? history.state.vendor : 0;
    this.customerSelected = history.state.customer !== undefined ? history.state.customer : 0;
    this.brokerSelected = history.state.broker !== undefined ? +history.state.broker : 0;
    this.customerName = history.state.customerName !== undefined ? history.state.customerName : '';
    this.brokerName = history.state.brokerName !== undefined ? history.state.brokerName : '';
    this.vendorName = history.state.vendorName !== undefined ? history.state.vendorName : '';
    this.reportType = history.state.type !== undefined ? history.state.type : '';
    this.orderType = history.state.orderType !== undefined ? history.state.orderType : '';
    this.bucketSelected = history.state.bucket !== undefined ? history.state.bucket : '';
    const index = this.bucketIndex.findIndex( bucket => bucket.Bucket == this.bucketSelected);
    this.currentIndex = this.bucketIndex[index].index;
    this.brokerSelected = this.brokerSelected ? this.brokerSelected : 0;
    this.customerSelected = this.customerSelected ? this.customerSelected : 0;
    this.title = this.reportNames[this.currentIndex].Name;
    this.shipToService.getAll().subscribe(shipTo => {
      this.shipTo = shipTo;
      this.brokerService.getAll().subscribe(broker => {
        this.brokers = broker;
        this.orderService.getBucketList(this.yearSelected, this.monthSelected, this.vendorSelected,
          this.customerSelected, this.brokerSelected, this.bucketSelected, this.reportType, this.orderType).subscribe( data => {
              this.order = data.order; 
              this.creditData = data.creditData;
              this.transfer = data.transferData;
              this.payment = data.paymentData;
              this.balanceForwardCredit = data.balanceForwardCredit;
              this.balanceForwardActual = data.balanceForwardActual;
              this.balanceForwardBudget = data.balanceForwardBudget;
              this.balanceForwardTransfer = data.balanceForwardTransfer;
              this.balanceForwardPayment = data.balanceForwardPayment;
              this.balfwd = this.balanceForwardBudget - this.balanceForwardCredit + this.balanceForwardTransfer - this.balanceForwardPayment;
              this.loadArrays();
          })
      });
    });
  }

  loadArrays() {
    let bigNum: any;
    this.order.forEach(element => {
      // Splits items into incoming and outgoing arrays by month
      const theDate = new Date(element.shipmentDate);
      const nMonth = theDate.getMonth();
      element.budgetAmount = this.getCommission(element.brokerId, element.commission1, element.broker2Id, element.commission2);
      element.commPct = this.getCommissionPct(element.brokerId, element.commPct1, element.broker2Id, element.commPct2);
      element.broker = this.getBroker(element.brokerId, element.broker, element.broker2Id, element.broker2);
      this.incoming[nMonth].push(element);
    });
    this.creditData.forEach(element => {
      const theDate = new Date(element.creditDate);
      const nMonth = theDate.getMonth();
      const itemCount = element.items.length;
      if (itemCount == 0) {
        element.brokerCompany = this.getBroker(element.brokerId, element.brokerCompany, element.brokerId2, element.brokerCompany2);
        element.commissionPct = this.getCommissionPct(element.brokerId, element.commission1, element.brokerId2, element.commission2),
        element.shipTo = '';
        this.outgoing[nMonth].push(element);
      } else {
        element.items.forEach(item => {
          if (this.brokerSelected == 0 || this.brokerSelected == item.brokerId || this.brokerSelected == item.brokerId2) {
            const newItem = {
              balance: element.balance,
              brokerCompany: this.getBroker(item.brokerId, "", item.brokerId2, ""),
              brokerCompany2: element.brokerCompany2,
              brokerId: item.brokerId,
              brokerId2: item.brokerId2,
              bucket: element.bucket,
              commissionPct: this.getCommissionPct(item.brokerId, item.commission1, item.brokerId2, item.commission2),
              commission1: item.commission1,
              commission2: item.commission2,
              creditAmount: item.commissionDollars1 + item.commissionDollars2,
              creditDate: element.creditDate,
              creditNo: element.creditNo,
              creditPromotionExplaination: element.creditPromotionExplaination,
              creditReason1: element.creditReason1,
              creditStatus: element.creditStatus,
              creditType: element.creditType,
              customerCompany: element.customerCompany,
              customerCreditNo: element.customerCreditNo,
              customerId: element.customerId,
              debit: element.debit,
              purchaseOrder: element.purchaseOrder,
              vendorCompany: element.vendorCompany,
              vendorId: element.vendorId,
              shipTo: this.getShipTo(item.shipToId)
            }
            this.outgoing[nMonth].push(newItem);
          }
        });
  
      }

    });
    this.transfer.forEach(element => {
       const theDate = new Date(element.transferDate);
       const nMonth = theDate.getMonth();
       this.transfering[nMonth].push(element);
    });
    this.payment.forEach(e => {
      const index = this.monthName.findIndex( mo => mo == e.month);
      if (index > -1) {
        this.paying[index].push(e);
      }
    })

    this.balFwdBeginningMonth.forEach((element, index) => {
      this.balFwdBeginningMonth[index] = this.balfwd;
      element = this.balfwd;
      this.balEndCurrentMonth[index] = this.balfwd;
      
      this.incoming[index].forEach(element2 => {
        this.balEndCurrentMonth[index] += element2.budgetAmount; 
        element2.balance = this.balEndCurrentMonth[index];
      })
      
      this.outgoing[index].forEach(element3 => {
        element3.debit = 0;
        const commDollar = this.computeCommission(element3.creditAmount, element3.commission1)
        const commDollar2 = this.computeCommission(element3.creditAmount, element3.commission2)

        if (this.brokerSelected == 0 || this.brokerSelected === element3.brokerId) {
          element3.debit += commDollar;
        }
        if (this.brokerSelected == 0 || this.brokerSelected === element3.brokerId2) {
          element3.debit += commDollar2;
        }
        this.balEndCurrentMonth[index] -= element3.debit;
        element3.balance = this.balEndCurrentMonth[index];
      })

      this.transfering[index].forEach(element4 => {
        if (element4.fromBucket == this.bucketSelected) {
          element4.debit = element4.transferAmount;
        } else {
          element4.credit = element4.transferAmount;
        }
        
        this.balEndCurrentMonth[index] += element4.credit - element4.debit;
        element4.balance = this.balEndCurrentMonth[index];
      })
      
      this.paying[index].forEach(e => {
        this.balEndCurrentMonth[index] -= e.paymentAmount;
        e.balance = this.balEndCurrentMonth[index];
      })
      this.balfwd = this.balEndCurrentMonth[index];
    })

    this.firstSelectMonthIndex = -1;
    this.numberMonthsRequested = 1;
    if (typeof this.monthSelected == "string") {
      const monthIndex = this.monthSelected - 1;
      this.buckets[monthIndex] = true;
      this.bucketsOrg[monthIndex] = true;
      this.firstSelectMonthIndex = monthIndex;
    } else {
      this.numberMonthsRequested = this.monthSelected.length;
      this.monthSelected.forEach(element => {
        const monthIndex = element - 1;
        this.buckets[monthIndex] = true;
        this.bucketsOrg[monthIndex] = true;
        this.firstSelectMonthIndex = this.firstSelectMonthIndex == -1 ? monthIndex : this.firstSelectMonthIndex;
      })
    }
  }

  getShipTo(shipToId) {
    let ship = '';
    const index = this.shipTo.findIndex(ship => ship.id == shipToId);
    if (index > -1) {
      ship = this.shipTo[index].company;
    }
    return ship;
  }
  getBroker(id, broker1, id2, broker2) {
    let broker = '';
    broker1 = typeof broker1 == 'undefined' || !broker1 ? '' : broker1;
    broker2 = typeof broker2 == 'undefined' || !broker2 ? '' : broker2;
    id = typeof id == 'undefined' || id == null ? 0 : id * 1;
    id2 = typeof id2 == 'undefined' || id2 == null ? 0 : id2 * 1;

    if (broker1 == '') {
      const index = this.brokers.findIndex(brok => brok.id == id);
      if (index > -1) {
        broker1 = this.brokers[index].company;
      }
    }

    if (broker2 == '') {
      const index = this.brokers.findIndex(brok => brok.id == id2);
      if (index > -1) {
        broker2 = this.brokers[index].company;
      }
    }

    if (this.brokerSelected > 0) {
       if (this.brokerSelected != id) {
        broker1 = '';
       }
       if (this.brokerSelected != id2) {
         broker2 = '';
       }
    }

    if (broker1 != '' && broker2 != '') {
      broker = broker1 + '/' + broker2
    } else {
      broker += broker1;
      broker += broker2;
    }
   
    return broker;
  }
  getCommission(id, comm1, id2, comm2) {
    let commissionAmount = 0;
    if (this.brokerSelected == 0 || this.brokerSelected === id) {
      commissionAmount += comm1;
    }
    if (this.brokerSelected == 0 || this.brokerSelected === id2) {
      commissionAmount += comm2;
    }
    return commissionAmount;
  }
  getCommissionPct(id, comm1, id2, comm2) {
    let commissionPct = 0;
    if (this.brokerSelected == 0 || this.brokerSelected === id) {
      commissionPct += comm1;
    }
    if (this.brokerSelected == 0 || this.brokerSelected === id2) {
      commissionPct += comm2;
    }
    return commissionPct *.01;
  }


  computeCommission(credit, commPct) {
    credit = credit ? credit : 0;
    commPct = commPct ? commPct : 0;

    let bigNum: any;
    bigNum = Big(credit)
    .times(commPct)
    .times(.01)
    .round(2)
    return bigNum * 1;
  }

  displayDate(dt, t) {
    if (dt === undefined) {
      return null;
    }
    if (dt) {
      if (t === undefined) {
        t = '';
      }
      if (dt.indexOf('T') == -1) {
        dt += 'T00:00';
      }
      let x = new Date(dt);
      if (t == 'T') {
        return x.toLocaleDateString() + ' @ ' + x.toLocaleTimeString();
      }
      return x.toLocaleDateString();
    } 
    return dt
  }
  printProcess() {
    const index = this.bucketIndex.findIndex( bucket => bucket.Bucket == this.bucketSelected);
    this.currentIndex = this.bucketIndex[index].index;
    if (this.currentIndex == -1) {
      alert("Under Construction");
      return;
    }

    if (this.bucketsOrg.every(x => x == true)) {
      this.printIt(-1);
    } else {
      this.bucketsOrg.forEach((bucket, index) => {
        if (bucket) {
          this.buckets.forEach((buck, index) => this.buckets[index] = false);
          this.buckets[index] = true;
          this.printIt(index);
        }
      });
    }
    this.bucketsOrg.forEach((bucket, index) => this.buckets[index] = bucket);
    this.reportName = '';
  }

  printIt(index) {
    this.title = this.reportNames[this.currentIndex].Name;
    this.reportName = this.reportNames[this.currentIndex].Name;
    this.checkRef.detectChanges();
    let fileName = '';
    fileName += this.vendorName.trim();
    fileName += ' – ';
    fileName += this.brokerName ? this.brokerName.trim() : 'ALL BROKERS';
    fileName += ' – ' + this.bucketSelected.trim();
    fileName += ' ' + this.yearSelected;
    if (index > -1) {
      const moIndex = index + 1;
      let monthNumber = moIndex + '';
      monthNumber = monthNumber.length == 1 ? "0" + monthNumber : monthNumber;
      fileName += ' ' + monthNumber;
      fileName += ' ' + this.monthName[index];
    }
    fileName += '-' + this.reportType.toUpperCase();

    this.pageBreak = {};
    // this.pageBreak.mode = 'avoid-all';
    this.pageBreak.mode = 'css';
    // this.pageBreak.mode = { mode: ['avoid-all', 'css', 'legacy'] };
    if (this.numberMonthsRequested > 1) {
      this.pageBreak.before = '.break-MONTH';
    }
    var element = document.getElementById(this.reportNames[this.currentIndex].Id);
    var opt = {
      margin:       [this.reportNames[this.currentIndex].TopMargin, this.reportNames[this.currentIndex].LRMargin],
      filename:     fileName + '.pdf',
      pagebreak:    this.pageBreak,
      image:        { type: 'jpeg', quality: 0.98 },
      html2canvas:  { scale: 2 },
      jsPDF:        { unit: 'in', format: 'letter', orientation: this.reportNames[this.currentIndex].Orientation }
    };
    html2pdf().set(opt).from(element).save();
    this.reportName = '';
    this.checkRef.detectChanges();
  }
}