<h5>{{ title }} </h5>
<form [formGroup]="roleForm" (ngSubmit)="onSubmit()" novalidate>
    <div class="form-group">
        <div class="col-lg-44">
            <label>
              Role Name:
              <input type="text" formControlName="roleName" class="form-control"  size="60">
            </label>
        </div>

        <div class="col-lg-44">
            <input type="checkbox" formControlName="dashboard"> Dashboard
        </div>
        <div class="col-lg-44">
            <input type="checkbox" formControlName="sales"> Sales
        </div>
        <div class="col-lg-44">
            <input type="checkbox" formControlName="customClearing"> Custom Clearing
        </div>
        <div class="col-lg-44">
            <input type="checkbox" formControlName="accounting"> Accounting
        </div>
        <div class="col-lg-44">
            <input type="checkbox" formControlName="customer"> Customer
        </div>
        <div class="col-lg-44">
            <input type="checkbox" formControlName="vendor"> Vendor
        </div>
        <div class="col-lg-44">
            <input type="checkbox" formControlName="other"> Other
        </div>
        <div class="col-lg-44">
            <input type="checkbox" formControlName="items"> items
        </div>
        <div class="col-lg-44">
            <input type="checkbox" formControlName="products"> Products
        </div>
        <div class="col-lg-44">
            <input type="checkbox" formControlName="productListing"> Product Listing
        </div>
        <div class="col-lg-44">
             <input type="checkbox" formControlName="projections"> Projections
        </div>
        <div class="col-lg-44">
            <input type="checkbox" formControlName="reports"> Reports
        </div>
        <button type="submit" class="btn btn-primary mt-3 w-25">Save</button>
        <button type="button" class="btn btn-secondary ms-3 mt-3 w-25" (click)="onDelete()">Delete</button>
    </div>
    <ngb-alert *ngIf="successMessage" type="success" (close)="successMessage = null">{{ successMessage }}</ngb-alert>
</form>