import { Component, OnInit } from '@angular/core';
import { LocationService } from '../../../services/location.service';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
@Component({
  selector: 'app-location-list',
  templateUrl: './location-list.component.html',
  styleUrls: ['./location-list.component.scss']
})
export class LocationListComponent implements OnInit {
location: any;
page = 1;
pageSize = 30;
successMessage: string;
private success = new Subject<string>();
alertType = "success";
staticAlertClosed = false;
  constructor(
    private router: Router,
    private locationService: LocationService
  ) { }

  ngOnInit() {
    this.locationService.getAll().subscribe(data => {
      this.location = data
    },error => {
      this.successMessage = 'Unable to process request';
      this.alertType = "danger";
    });
    setTimeout(() => this.staticAlertClosed = true, 20000);
    this.success.subscribe((message) => this.successMessage = message);
    this.success.pipe(
      debounceTime(5000)
    ).subscribe(() => this.successMessage = null);    
  }
  onRowClicked(location) {
    this.router.navigate(['/other/location-edit', location.id]);
  }

}
