export class ClearingCompany {
  id: number;
  activeItem: string;
  company: string;
  address: string;
  city: string;
  stateProvince: string;
  countryRegion: string;
  zipPostalCode: string;
  fullName: string;
  jobTitle: string;
  businessPhone: string;
  faxNumber: string;
  mobileNumber: string;
  eMail: string;
  webPage: string;
  comments: string;
  billingCompany: string;
  customerNumber: string;
}
