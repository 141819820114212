import { Component, OnInit } from '@angular/core';
import { CategoryService } from '../../../services/category.service';
import { Router, ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-category-list',
  templateUrl: './category-list.component.html',
  styleUrls: ['./category-list.component.scss']
})
export class CategoryListComponent implements OnInit {
  category: any;
  activeItemSelected: string;
  vendorSelected: number;
  vendorOptions:[];
  brandSelected: number;
  brandOptions:[];
  page: number;
  pageSize: number;
  count: number;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private categorySvc: CategoryService) { }

  ngOnInit() {
    this.page = 1;
    this.pageSize = 30;
    this.activeItemSelected = 'Active';
    this.vendorSelected = 0;
    this.brandSelected = 0;
    this.count = 0;
    this.route.data.subscribe(data => {
      this.category = data.dataCategory.item;
      this.vendorOptions  = data.dataCategory.item2;
      this.brandOptions = data.dataCategory.item3;
      this.count = data.dataCategory.count;
    });
  }
  loadPage() {
    this.categorySvc.getFilteredListing(this.activeItemSelected, this.vendorSelected, this.brandSelected, this.page, this.pageSize).subscribe(data => {
      this.category = data.item;
      this.vendorOptions  = data.item2;
      this.brandOptions = data.item3;
      this.count = data.count;
    });
  }
  
  reset() {
    this.page = 1;
    this.pageSize = 30;
    this.activeItemSelected = 'Active';
    this.brandSelected = 0;
    this.vendorSelected = 0;
    this.loadPage();    
  }  
  onRowClicked(category) {
    this.router.navigate(['/items/category-edit',  category.id]);
  }

}
