import { Component, OnInit } from '@angular/core';
import { CreditReasonsService } from 'src/app/services/credit-reasons.service';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { SorterService } from 'src/app/services/sorter.service';

@Component({
  selector: 'app-credit-reasons-list',
  templateUrl: './credit-reasons-list.component.html',
  styleUrls: ['./credit-reasons-list.component.scss']
})
export class CreditReasonsListComponent implements OnInit{
  creditReason: any;
  successMessage: string;
  private success = new Subject<string>();
  alertType = "success";
  staticAlertClosed = false;

  constructor(private router: Router,
              private creditReasonsService: CreditReasonsService,
              private sortSvc: SorterService
  ) {}

  ngOnInit() {
    this.creditReasonsService.getAll().subscribe(data => {
      this.creditReason = data;
      this.creditReason = this.sortSvc.sorter(this.creditReason, 'creditReason1');
    }, error => {
      this.successMessage = 'Unable to process request';
      this.alertType = 'danger';
    });
    setTimeout(() => this.staticAlertClosed = true, 20000);
    this.success.subscribe((message) => this.successMessage = message);
    this.success.pipe(
      debounceTime(5000)
    ).subscribe(() => this.successMessage = null);      
  }
  onRowClicked(rowSelected) {
    this.router.navigate(['/other/credit-reasons-edit', rowSelected.id]);
  }
}
