<div class="frame">
  <span class="helper"></span><img src="/assets/images/Kalcor_Canada.png" height=250px />
</div>
 

  <!-- <img class="logo img-responsive" src="/assets/images/Kalcor.png" > -->

 
<!--  <div *ngIf="!loginDisplay">
    <p>Please sign-in to see your profile information.</p>
  </div>
  
  <div *ngIf="loginDisplay">
    <p>Login successful!</p>
    <p>Request your profile information by clicking Profile above.</p>
  </div> -->