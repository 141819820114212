import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
const url =  environment.kalcorApiUrl;
@Injectable({
  providedIn: 'root'
})
export class PickupLocationService {

  constructor(private http: HttpClient) { }
  getAll(): Observable<any> {
    return this.http.get(url + 'AccountsPickupAddresses', this.jwt());
  }
  getVendorOrder(): Observable<any> {
    return this.http.get(url + 'AccountsPickupAddresses/VendorOrder', this.jwt());
  }
  
  getOne(id: number): Observable<any> {
    return this.http.get(url + 'AccountsPickupAddresses/' + id, this.jwt());
  }
  
  getFilteredListing(activeItem: string, id: number, vendorId: number, page: number, pageSize: number): Observable<any> {
    page = page ? page : 1;
    pageSize = pageSize ? pageSize : 30;
    return this.http.get(url + 'AccountsPickupAddresses/Filter?activeItem=' + activeItem + '&id=' + id + '&vendorId=' + 
    vendorId + '&page=' + page + '&pageSize=' + pageSize, this.jwt());
  }  
  update(pickup) {
    return this.http.put(url + 'AccountsPickupAddresses/' + pickup.id, pickup, this.jwt());
  }
  create(pickup): Observable<any> {
    return this.http.post(url + 'AccountsPickupAddresses', pickup, this.jwt());
  }
  remove(id) {
    return this.http.delete(url + 'AccountsPickupAddresses/' + id, this.jwt());
  }
  private jwt() {
    // create authorization header with jwt token
    const currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
    if (currentUser && currentUser.token) {
        const headersInfo = new HttpHeaders({ Authorization: 'Bearer ' + currentUser.token });
        return { headers: headersInfo };
    }
  }
}
