import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable, fromEventPattern } from 'rxjs';
import { map } from 'rxjs/operators';
import { User } from '../models/user.model';
const url =  environment.kalcorApiUrl;

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  // user: User;
  user: any;
  constructor(private http: HttpClient) { }
  login(userinitials: string, password: string): Observable<any> {
    return this.http.post(url + 'users/authenticate', { Userinitials: userinitials, Userpassword: password })
      .pipe(map((response: Response) => {
        this.user = response;
        return this.user;
      }));
    }
  // login(userinitials: string, password: string): Observable<any> {
  //   return this.http.post(url + 'users/authenticate', { Userinitials: userinitials, Userpassword: password });
  // }
  getUserInfo() {
    if (this.user === undefined) {
      this.user = JSON.parse(sessionStorage.getItem('currentUser'));
    }
    return this.user;
  }
  logout() {
      // remove user from local storage to log user out
      sessionStorage.removeItem('currentUser');
  }
}
