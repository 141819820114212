import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
const url = environment.kalcorApiUrl;

@Injectable({
  providedIn: 'root'
})
export class WeekCustomerEndService {

  constructor(private http: HttpClient) { }
  getAll(): Observable<any> {
    return this.http.get(url + 'WeekCustomerHeads', this.jwt());
  }
  getOne(id:number): Observable<any> {
    return this.http.get(url +  'WeekCustomerHeads/' + id, this.jwt());
  }
  getYear(customerHeadId:number, year:number): Observable<any> {
    return this.http.get(url + 'WeekCustomerHeads/' + customerHeadId + '/Year/' + year, this.jwt());
  }
  getYears(customerHeadId:number): Observable<any> {
    return this.http.get(url + 'WeekCustomerHeads/' + customerHeadId + '/Years', this.jwt());
  }
  update(wkCustomerHd): Observable<any> {
    return this.http.put(url + 'WeekCustomerHeads/' + wkCustomerHd.weekId, wkCustomerHd, this.jwt());
  }
  create(wkCustomerHd): Observable<any> {
    return this.http.post(url + 'WeekCustomerHeads', wkCustomerHd, this.jwt());
  }
  remove(id: number) {
    return this.http.delete(url + 'WeekCustomerHeads/' + id, this.jwt());
  }
  private jwt() {
    //create authorization header with jwt token
  const currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
  if (currentUser && currentUser.token) {
    const headersInfo = new HttpHeaders({ Authorization: 'Bearer ' + currentUser.token });
    return { headers: headersInfo };
  }
  }
}
