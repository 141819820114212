import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { Customer } from '../../../models/customer.model';
import { CustomerService } from '../../../services/customer.service';

@Injectable()
export class CustomerResolver implements Resolve<Customer> {
  customerId: number;

  constructor(private customerService: CustomerService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Customer> {
    const idField = 'id';
    this.customerId = parseInt(route.params[idField], 10);

    if (this.customerId === 0) {
      return;
    }
    return this.customerService.getOne(this.customerId);
  }
}
