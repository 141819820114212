<h5>{{ title }} </h5>
<form [formGroup]="exchangeForm" (ngSubmit)="onSubmit()" novalidate>
  <div class="form-group">
    <div class="col-lg-44">
      <label>
        Rate:
        <input type="text" formControlName="exchangeRate" class="form-control"  size="60">
      </label>
      <label class="ml-3">
        Default:
        <select  type="text" formControlName="exchangeDefault" class="form-control" >
            <option value = ''></option>
            <option value ='Yes'>Yes</option>
            <option value="No">No</option>
        </select>            
      </label>
    </div>

    <button type="submit" class="btn btn-primary mt-3 w-25">Save</button>
    <button type="button" class="btn btn-secondary ml-3 mt-3 w-25" (click)="onDelete()">Delete</button>
  </div>
    <ngb-alert *ngIf="successMessage" type="success" (close)="successMessage = null">{{ successMessage }}</ngb-alert>
</form>