import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ProductService } from '../../../../services/product.service';
import { UpcGtnService } from '../../../../services/upc-gtn.service';
import { UpcGtn } from '../../../../models/upc-gtn.model';
import { FormsModule } from '@angular/forms';
import { Product, ActiveListings, ProductInit } from 'src/app/models/product.model';
import { BrandService } from '../../../../services/brand.service';
import { Brand } from '../../../../models/brand.model';
import { CategoryService } from '../../../../services/category.service';
import { Category } from '../../../../models/category.model';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { UtilityService } from '../../../../services/utility.service';
import { LocationService } from '../../../../services/location.service';
import { Location } from '../../../../models/location-model';
import { Transportation } from '../../../../models/transportation.model';
import { TransportationService } from '../../../../services/transportation.service';
import { LkupProductGroupService } from 'src/app/services/lkup-product-group.service';
import { LkupProductGroups } from 'src/app/models/lkup-product-group.model';
@Component({
  selector: 'app-freight-cost-setup-edit',
  templateUrl: './freight-cost-setup-edit.component.html',
  styleUrls: ['./freight-cost-setup-edit.component.scss']
})
export class FreightCostSetupEditComponent implements OnInit {
  title = 'Freight Cost Setup New';
  newOne = true;
  brands: Brand[];
  categories: Category[];
  product: Product;
  originalProduct: Product;
  gtn: UpcGtn[];
  productForm: UntypedFormGroup;
  successMessage: string;
  private success = new Subject<string>();
  staticAlertClosed = false;
  activeList = [];
  locations: Location[];
  transport: Transportation[];
  productGroups: LkupProductGroups[];
  groupName = '';
  linkedProduct: any;
  processIndex: number;
  alertType = "success";
  grouped = false;
  listing = false;
  linkedListing: any;
  spinner = '';
  constructor(private route: ActivatedRoute,
    private router: Router,
    private fb: UntypedFormBuilder,
    private gtnService: UpcGtnService,
    private brandService: BrandService,
    private categoryService: CategoryService,
    private productService: ProductService,
    private locationService: LocationService,
    private transportService: TransportationService,
    private productGroupService: LkupProductGroupService,
    private utiityService: UtilityService) { }

  ngOnInit() {
    this.activeList = ActiveListings;
    this.productForm = this.fb.group({
      activeListings: [{ value: '', disabled: true }],
      costType: [{ value: '', disabled: true }],
      productOrderCode: [{ value: '', disabled: true }],
      brand: [{ value: '', disabled: true }],
      category: [{ value: '', disabled: true }],
      itemDescription: [{ value: '', disabled: true }],
      size: [{ value: '', disabled: true }],
      location1Id: [''],
      location1: [''],
      truckCost1: [{ value: '', disabled: true }],
      location2Id: [''],
      location2: [''],
      truckCost2: [{ value: '', disabled: true }],
      location3Id: [''],
      location3: [''],
      truckCost3: [{ value: '', disabled: true }],
      location4Id: [''],
      location4: [''],
      truckCost4: [{ value: '', disabled: true }],
      location5Id: [''],
      location5: [''],
      truckCost5: [{ value: '', disabled: true }],
      location6Id: [''],
      location6:  [''],
      truckCost6:  [{ value: '', disabled: true }],
      location7Id: [''],
      location7: [''],
      truckCost7: [{ value: '', disabled: true }],
      location8Id: [''],
      location8: [''],
      truckCost8: [{ value: '', disabled: true }],
      transportCo1: [''],
      transportCo2: [''],
      transportCo3: [''],
      transportCo4: [''],
      transportCo5: [''],
      transportCo6: [''],
      transportCo7: [''],
      transportCo8: [''],
      transNotesCo1: [''],
      transNotesCo2: [''],
      transNotesCo3: [''],
      transNotesCo4: [''],
      transNotesCo5: [''],
      transNotesCo6: [''],
      transNotesCo7: [''],
      transNotesCo8: [''],
      blended: [{ value: '', disabled: true }],
      blendedFreightCost: [{ value: '', disabled: true }], 
      shippingLaneEnd1: [''],
      shippingLaneEnd2: [''],
      shippingLaneEnd3: [''],
      shippingLaneEnd4: [''],
      shippingLaneEnd5: [''],
      shippingLaneEnd6: [''],
      shippingLaneEnd7: [''],
      shippingLaneEnd8: [''],
      freightType1: [''],
      freightType2: [''],
      freightType3: [''],
      freightType4: [''],
      freightType5: [''],
      freightType6: [''],
      freightType7: [''],
      freightType8: [''],
      freightCost1: [''],
      freightCost2: [''],
      freightCost3: [''],
      freightCost4: [''],
      freightCost5: [''],
      freightCost6: [''],
      freightCost7: [''],
      freightCost8: [''],
      freightCostSales1: [''],
      freightCostSales2: [''],
      freightCostSales3: [''],
      freightCostSales4: [''],
      freightCostSales5: [''],
      freightCostSales6: [''],
      freightCostSales7: [''],
      freightCostSales8: ['']
    });

    this.transportService.getAll().subscribe(trans => {
      this.transport = trans;
      this.locationService.getAll().subscribe(locate => {
        this.locations = locate;
        this.brandService.getAll().subscribe(brand => {
          this.brands = brand;
          this.categoryService.getAll().subscribe(category => {
            this.categories = category;
            this.gtnService.getAll().subscribe(gtns => {
              this.gtn = gtns;
              this.productGroupService.getAll().subscribe(group => {
                this.productGroups = group;

              this.route.data.subscribe(data => {
                if (data.dataProductSetup !== undefined) {
                  this.title = 'Freight Cost Setup Edit';
                  this.newOne = false;
                  this.product = data.dataProductSetup;
                  this.product.activeListings = data.dataProductSetup.activeListings.replace("–","-");
                  if (this.product.activeListings.includes("LISTING")) {
                    this.listing = true;
                    if (this.product.groupListingId > 0) {
                      let x = this.product.groupListingId;
                      this.getListingGroup(x);
                    }
                  }
                  this.fillFormData();
                  this.reCalculate('ALL');
                  this.onChanges();
                } else {
                  this.title = 'Freight Cost Setup New';
                  this.newOne = true;
                  this.product = ProductInit;
                  this.productForm.reset();
                  this.onChanges();
                }
              });
            });
          })
          });
        });
      })
    })
    setTimeout(() => this.staticAlertClosed = true, 20000);
    this.success.subscribe((message) => this.successMessage = message);
    this.success.pipe(
      debounceTime(5000)
    ).subscribe(() => this.successMessage = null);
  }

  fillFormData() {
    const productOrderCodeValue = this.getValue(this.product.productOrderCodeId, 'productOrderCodeId');
    const brandValue = this.getValue(this.product.productOrderCodeId, 'brandId');
    const categoryValue = this.getValue(this.product.categoryId, 'categoryId');
    const productGroupValue = this.getValue(this.product.productGroupId, 'productGroupId');
    this.groupName = productGroupValue;
    this.productForm.setValue({
      activeListings: this.product.activeListings,
      costType: this.product.costType,
      productOrderCode: productOrderCodeValue,
      brand: brandValue,
      category: categoryValue,
      itemDescription: this.product.itemDescription,
      size: this.product.size,
      location1Id: this.product.location1Id,
      location1: this.product.location1,
      truckCost1: this.product.truckCost1,
      location2Id: this.product.location2Id,
      location2: this.product.location2,
      truckCost2: this.product.truckCost2,
      location3Id: this.product.location3Id,
      location3: this.product.location3,
      truckCost3: this.product.truckCost3,
      location4Id: this.product.location4Id,
      location4: this.product.location4,
      truckCost4: this.product.truckCost4,
      location5Id: this.product.location5Id,
      location5: this.product.location5,
      truckCost5: this.product.truckCost5,
      location6Id: this.product.location6Id,
      location6:  this.product.location6,
      truckCost6:  this.product.truckCost6,
      location7Id: this.product.location7Id,
      location7: this.product.location7,
      truckCost7: this.product.truckCost7,
      location8Id: this.product.location8Id,
      location8: this.product.location8,
      truckCost8: this.product.truckCost8,
      blended: [{ value: '', disabled: true }],
      blendedFreightCost: [{ value: '', disabled: true }],
      transportCo1: this.product.transportCo1,
      transportCo2: this.product.transportCo2,
      transportCo3: this.product.transportCo3,
      transportCo4: this.product.transportCo4,
      transportCo5: this.product.transportCo5,
      transportCo6: this.product.transportCo6,
      transportCo7: this.product.transportCo7,
      transportCo8: this.product.transportCo8,
      transNotesCo1: this.product.transNotesCo1,
      transNotesCo2: this.product.transNotesCo2,
      transNotesCo3: this.product.transNotesCo3,
      transNotesCo4: this.product.transNotesCo4,
      transNotesCo5: this.product.transNotesCo5,
      transNotesCo6: this.product.transNotesCo6,
      transNotesCo7: this.product.transNotesCo7,
      transNotesCo8: this.product.transNotesCo8,
      shippingLaneEnd1: this.product.shippingLaneEnd1,
      shippingLaneEnd2: this.product.shippingLaneEnd2,
      shippingLaneEnd3: this.product.shippingLaneEnd3,
      shippingLaneEnd4: this.product.shippingLaneEnd4,
      shippingLaneEnd5: this.product.shippingLaneEnd5,
      shippingLaneEnd6: this.product.shippingLaneEnd6,
      shippingLaneEnd7: this.product.shippingLaneEnd7,
      shippingLaneEnd8: this.product.shippingLaneEnd8,
      freightType1: this.product.freightType1,
      freightType2: this.product.freightType2,
      freightType3: this.product.freightType3,
      freightType4: this.product.freightType4,
      freightType5: this.product.freightType5,
      freightType6: this.product.freightType6,
      freightType7: this.product.freightType7,
      freightType8: this.product.freightType8,
      freightCost1: this.product.freightCost1,
      freightCost2: this.product.freightCost2,
      freightCost3: this.product.freightCost3,
      freightCost4: this.product.freightCost4,
      freightCost5: this.product.freightCost5,
      freightCost6: this.product.freightCost6,
      freightCost7: this.product.freightCost7,
      freightCost8: this.product.freightCost8,
      freightCostSales1: this.product.freightCostSales1,
      freightCostSales2: this.product.freightCostSales2,
      freightCostSales3: this.product.freightCostSales3,
      freightCostSales4: this.product.freightCostSales4,
      freightCostSales5: this.product.freightCostSales5,
      freightCostSales6: this.product.freightCostSales6,
      freightCostSales7: this.product.freightCostSales7,
      freightCostSales8: this.product.freightCostSales8
    });
    if (productGroupValue) {
      this.getProductGroup(this.product.productGroupId);
    }
  }
  getProductGroup(val) {
    this.productService.getProductGroup(val).subscribe(data => {
      this.linkedProduct = data;
    })
  }
  getListingGroup(x){
    this.productService.getProductGroupListing(x).subscribe(data => {
      this.linkedListing = data;
    })
  }
  getValue(id: number, name: string) {
    let ret = '';
    if (id) {
      let index = -1;
      switch (name) {
        case 'productOrderCodeId':
          index = this.gtn.findIndex(item => item.id === id);
          if (index > -1) {
            ret = this.gtn[index].vendorOrderCode;
          }
          break;
          case 'brandId':
            index = this.gtn.findIndex(item => item.id === id);
            if (index > -1) {
              const brandId = this.gtn[index].brandId;
              if (brandId > -1 ) {
                const brandIndex = this.brands.findIndex(item => item.id === brandId);
                if (brandIndex > -1) {
                  ret = this.brands[brandIndex].brand;
                }  
              }
            }
            break;
        case 'categoryId':
          index = this.categories.findIndex(item => item.id === id);
          if (index > -1) {
            ret = this.categories[index].category;
          }
          break;
          case 'productGroupId':
            index = this.productGroups.findIndex(item => item.id === id);
            if (index > -1) {
              ret = this.productGroups[index].productGroupNo;
            }
          break;
        }
    }
    return ret;
  }

  onChanges(): void {
    const item = ['location1', 'location2', 'location3', 'location4',
                  'location5', 'location6', 'location7', 'location8',
                  'freightCost1','freightCost2','freightCost3','freightCost4',
                  'freightCost5','freightCost6','freightCost7','freightCost8',
                  'freightCostSales1','freightCostSales2','freightCostSales3','freightCostSales4',
                  'freightCostSales5','freightCostSales6','freightCostSales7','freightCostSales8'];
    item.forEach(name => {
      this.productForm.get(name).valueChanges.subscribe(val => {
        this.reCalculate(name);
      });
    });
  }

  reCalculate(key) {
    this.updateProduct();
    const changedItems = this.utiityService.productCalculations(key, this.product);
    for (const item in changedItems) {
      if (changedItems.hasOwnProperty(item)) {
        if (this.productForm.controls.hasOwnProperty(item)) {
          this.productForm.controls[item].setValue(changedItems[item]);
        }
        this.product[item] = changedItems[item];
      }
    }
  }
  
  updateProduct() {
    for (const item in this.productForm.controls) {
      if (this.productForm.controls.hasOwnProperty(item)) {
       if (this.product[item] !== undefined) {
          this.product[item] = this.productForm.controls[item].value;
       }
      }
    }
  }

  onSubmit() {
    this.updateProduct();
    if (this.newOne) {
      this.productService.create(this.product).subscribe(data => {
        this.product = data;
        this.newOne = false;
        this.title = 'Freight Cost Setup Edit';
        this.success.next(`Successfully added.`);
      }, error => {
      });
    } else {
      this.productService.update(this.product).subscribe(data => {
        this.success.next(`Successfully changed.`);
      }, error => {
      });
    }
  }
  validate() {
    if (this.product.productGroupId == 0 || this.product.productGroupId == null) {
      this.success.next(`This product does not belong to a group.`);
      this.alertType = "danger";
      return;
    }
    if (confirm('Case information will be updated for all products in the group.'))
    this.processIndex = -1;
    this.grouped = true;
    this.originalProduct = this.product;
    this.spinner = '...Updating';
    this.initializeProducts();
  }
  initializeProducts() {
    //update case information for all products in linkedProduct array
    this.processIndex++;
      if (this.processIndex >= this.linkedProduct.length) {
        this.processIndex = -1;
        this.grouped = false;
        this.product = this.originalProduct;
        this.spinner = '';
        alert('All products updated successfully.');
      } else {
        this.insertLinkedProducts();
      }
  }
  insertLinkedProducts() {
    this.updateLinkedProduct();
    if (this.newOne) {
      this.productService.create(this.product).subscribe(data => {
        this.product = data;
        this.newOne = false;
        this.title = 'Freight Cost Setup Edit';
        this.initializeProducts();
        // this.success.next(`Successfully added.`);
      }, error => {
      });
    } else {
      this.productService.update(this.product).subscribe(data => {
        this.initializeProducts();
        // this.success.next(`Successfully changed.`);
      }, error => {
      });
    }
  }
  updateLinkedProduct() {
    this.product = this.linkedProduct[this.processIndex];
    for (const item in this.productForm.controls) {
      if (this.productForm.controls.hasOwnProperty(item)) {
        if (this.product[item] !== undefined) {
           if (item !== 'productOrderCode') {
            if (item !== 'itemDescription') {
              if (item !== 'category') {
                if (item !== 'size') {
                  if (item !== 'brand') {
                    this.product[item] = this.productForm.controls[item].value;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  validateListing() {
    if (this.product.groupListingId == 0 || this.product.groupListingId == null) {
      this.success.next(`This product does not belong to a group listing.`);
      this.alertType = "danger";
      return;
    }
    if (confirm('Case information will be updated for all products in the group listing.'))
    this.processIndex = -1;
    this.grouped = true;
    this.originalProduct = this.product;
    this.spinner = '...Updating';
    this.initializeListings();
  }
  initializeListings() {
    //update case information for all products in linkedProduct array
    this.processIndex++;
      if (this.processIndex >= this.linkedListing.length) {
        this.processIndex = -1;
        this.grouped = false;
        this.product = this.originalProduct;
        this.spinner = '';
        alert('All listings updated successfully.');
      } else {
        this.insertLinkedListings();
      }
  }
  insertLinkedListings() {
    this.updateLinkedListings();
    if (this.newOne) {
      this.productService.create(this.product).subscribe(data => {
        this.product = data;
        this.newOne = false;
        this.title = 'Freight Cost Setup Edit';
        this.initializeListings();
        // this.success.next(`Successfully added.`);
      }, error => {
      });
    } else {
      this.productService.update(this.product).subscribe(data => {
        this.initializeListings();
        // this.success.next(`Successfully changed.`);
      }, error => {
      });
    }
  }
  updateLinkedListings() {
    this.product = this.linkedListing[this.processIndex];
    for (const item in this.productForm.controls) {
      if (this.productForm.controls.hasOwnProperty(item)) {
        if (this.product[item] !== undefined) {
           if (item !== 'productOrderCode') {
            if (item !== 'itemDescription') {
              if (item !== 'category') {
                if (item !== 'size') {
                  if (item !== 'brand') {
                    this.product[item] = this.productForm.controls[item].value;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  editSetup() {
    this.router.navigate(['/products/setup-edit',  this.product.id]);
  }
  
  editCaseSetup() {
    this.router.navigate(['/products/cases-setup-edit',  this.product.id]);
  }
  
  editPalletSetup() {
    this.router.navigate(['/products/pallet-setup-edit',  this.product.id]);
  }
  editCostingBucketSetup() {
    this.router.navigate(['/products/costing-bucket-setup-edit',  this.product.id]);
  }
  editListingSetup() {  
    this.router.navigate(['/productListings/listing-setup-edit',  this.product.id]);
  }
}
