import { Component, OnInit } from '@angular/core';
import { RegionService } from '../../../services/region.service';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-region-list',
  templateUrl: './region-list.component.html',
  styleUrls: ['./region-list.component.css']
})
export class RegionListComponent implements OnInit {
  
  region: any;
  page = 1;
  pageSize = 30;
  successMessage: string;
  private success = new Subject<string>();
  alertType = "success";
  staticAlertClosed = false;
  constructor(
    private router: Router,
    private regionSvc: RegionService
  ) { }

  ngOnInit(): void {
    this.regionSvc.getAll().subscribe(data => {
      this.region = data
    },error => {
      this.successMessage = 'Unable to process request';
      this.alertType = "danger";
    })
    setTimeout(() => this.staticAlertClosed = true, 20000);
    this.success.subscribe((message) => this.successMessage = message);
    this.success.pipe(
      debounceTime(5000)
    ).subscribe(() => this.successMessage = null); 
  }
  onRowClicked(region) {
    this.router.navigate(['/other/region-edit', region.regionId]);
  }
}
