import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { OrderItems } from '../models/orderItems.model';
import { OrderService } from '../services/order.service';

@Injectable()
export class OrderItemsResolver implements Resolve<OrderItems> {
  OrderItems: OrderItems
  constructor(private OrderService: OrderService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    const idField = 'id';
    const id =  parseInt(route.params[idField], 10);

    if (id === 0) {
      return;
    }
    if (route.url[0].path === "shipping-edit" || route.url[0].path === "invoicing-edit") {
      return this.OrderService.getItems(id);
    }
    return this.OrderService.getDetails(id);
  }
}