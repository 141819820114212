<h5>Credit Reasons List</h5>
<table class="table table-striped" style="overflow-x:scroll;">
    <thead>
        <tr>
            <th scope="col">Credit Reason</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let c of creditReason; index as i">
            <td>
                <a class="pointer" (click)="onRowClicked(c)">
                    <u class="text-primary">{{ c.creditReason1 }}</u>
                </a>
            </td>
        </tr>
    </tbody>
</table>
<ngb-alert *ngIf="successMessage" [type]="alertType" (close)="successMessage = null">{{ successMessage }}</ngb-alert>
