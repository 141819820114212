<h5>Border Crossing List </h5>
<table class="table table-striped" style="overflow-x:scroll;">
    <thead>
    <tr>
      <th scope="col">Area</th>
      <th scope="col">Canada Port Of Entry Name</th>
      <th scope="col">Canada Road Highway</th>
      <th scope="col">United States Port Of Entry Name</th>
      <th scope="col">United States Road Highway Community</th>
      <th scope="col">Coordinates</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let b of borderCrossing | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize" (click)="onRowClicked(b)">
      <td>{{ b.area }}</td>
      <td>{{ b.canadaPortOfEntryName }}</td>
      <td>{{ b.canadaRoadHighway }}</td>
      <td>{{ b.unitedStatesPortOfEntryName }}</td>
      <td>{{ b.unitedStatesRoadHighwayCommunity }}</td>
      <td>{{ b.coordinates }}</td>
    </tr>
    </tbody>
  </table>
  <div *ngIf="borderCrossing!==undefined">
    <ngb-pagination [(page)]="page" [pageSize]="pageSize" [collectionSize]="borderCrossing.length">
    </ngb-pagination>
  </div>
  <ngb-alert *ngIf="successMessage" [type]="alertType" (close)="successMessage = null">{{ successMessage }}</ngb-alert>
  