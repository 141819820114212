import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { Credit } from '../../models/credit-model';
import { CreditService } from '../../services/credit.service'

@Injectable()
export class CreditResolver implements Resolve<Credit> {
  constructor(private creditService: CreditService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Credit> {
    const idField = 'id';
    const id =  parseInt(route.params[idField], 10);

    if (route.url[0].path === "credit-list") {
      return this.creditService.getFilter('Open', '', '', '', 0, 0, 0, '', 1, 30);
    }

    if (id === 0 || isNaN(id)) {
      return;
    }
    return this.creditService.getOneBatch(id);
  }
}
