import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { CustomerEnd } from '../../../models/customer-end-model';
import { CustomerEndService } from '../../../services/customer-end.service';

@Injectable()
export class CustomerEndResolver implements Resolve<CustomerEnd> {
  customerEnd: CustomerEnd;
  constructor(private customerEndService: CustomerEndService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<CustomerEnd> {
    const idField = 'id';
    const id =  parseInt(route.params[idField], 10);

    if (id === 0) {
      // this.customerEnd = new CustomerEnd();
      // return of(this.customerEnd);
      return;
    }
    return this.customerEndService.getOne(id);
  }
}
