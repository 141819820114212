export class Projection {
    id: number;
    projectionNo: string;
    vendorId: number;
    customerId: number;
    shipToId: number;
    customerEndId: number;
    bannerIds: string;
    bannerRegionIds: string;
    noStores: number;
    year: number;
    productOrderCode: string;
    fullDescription: string;
    category: string;
    week1: number; 
    week2: number;  
    week3: number; 
    week4: number;  
    week5: number;  
    week6: number;  
    week7: number;  
    week8: number;  
    week9: number;  
    week10: number;  
    week11: number;  
    week12: number;  
    week13: number;  
    week14: number;  
    week15: number;  
    week16: number;  
    week17: number;  
    week18: number;  
    week19: number;  
    week20: number;  
    week21: number;  
    week22: number;  
    week23: number;  
    week24: number;  
    week25: number;  
    week26: number;  
    week27: number;  
    week28: number;  
    week29: number;  
    week30: number;  
    week31: number;  
    week32: number;  
    week33: number;  
    week34: number;  
    week35: number;  
    week36: number;  
    week37: number;  
    week38: number;  
    week39: number;  
    week40: number;  
    week41: number;  
    week42: number;  
    week43: number;  
    week44: number;  
    week45: number;  
    week46: number;  
    week47: number;  
    week48: number;  
    week49: number;  
    week50: number;  
    week51: number;  
    week52: number;  
    week53 : number; 
    typeOfPromotion: string;  
    productId: number;
    whsSelling: number;  
    brandId: number;
    confirmWeeks: string;
    bannerId: number;
    promotionWeeks: string;
    budgetPromotionId: number;
}