<div class="pdf">
    <div class="row g-0 text-white mt-2">
        <div class="col-lg-24 pt-2 px-2 text-center pdf, pdf-bg, pdf-size20">
            {{ title }}
        </div>
    </div>
    <div class="row g-0 text-white mt-2">
        <table class="w-100">
            <thead class="text-white mt-3 pe-2 pdf-bg">
                <th>BILL TO</th>
                <th>SHIP TO</th>
                <th colspan="2">SALES ORDER INFORMATION</th>
                <th colspan="2">SHIPPING DATES/TYPES</th>
            </thead>
            <tbody class="text-dark">
                <tr>
                    <td>{{ order?.billTo }} </td>
                    <td>{{ order?.shipTo }} </td>
                    <td>Sales Order Date</td>
                    <td>{{ displayDate(order.salesOrderDate, '')  }}</td>
                    <td>Shipment Date</td>
                    <td>{{ displayDate(order.shipmentDate, '')  }}</td>
                </tr>

                <tr>
                    <td>{{ order?.billAddress }}</td>
                    <td>{{ order?.shipToAddress }}</td>
                    <td>Sales Order No.</td>
                    <td>{{ order?.salesOrderNo }}</td>
                    <td>Delivered Date/Appt</td>
                    <td>{{ displayDate(order.deliveredDateAppt, 'T') }}</td>
                </tr>
                <tr>
                    <td>{{ order?.billCSZ }}</td>
                    <td>{{ order?.shipToCSZ }}</td>
                    <td>Purchase Order</td>
                    <td>{{ order?.purchaseOrder }}</td>
                    <td>App Confirmed</td>
                    <td>{{ order?.appConfirmed }}</td>
                </tr>
                <tr>
                    <td></td>
                    <td></td>
                    <td>Payment Terms</td>
                    <td>{{ order?.paymentTerms }}</td>
                    <td>Delivery Type</td>
                    <td>{{ order?.freightType }}</td>
                </tr>
                <tr>
                    <td></td>
                    <td></td>
                    <td>Broker</td>
                    <td>{{ order?.broker }}</td>
                    <td>Order Status</td>
                    <td>{{ order.orderStatus }}</td>
                </tr>
            </tbody>
        </table>
    </div>

    <div class="row g-0 text-white mt-2">
        <table class="w-100">
            <thead class="text-white mt-3 pe-2 pdf-bg">
                <th>VENDOR PICK UP</th>
                <th>SHIP DATE</th>
                <th>DELIVER</th>
                <th>SHIPPING LANE STARTING</th>
                <th>SHIPPING LANE ENDING</th>
                <th>TRANSPORT COMPANY</th>
                <th>WAREHOUSE</th>
            </thead>
            <tbody class="text-dark">
                <tr>
                    <td>{{ order?.pickCompany }}</td>
                    <td [ngClass]="[order.location1 ? 'border-dark border-left' : '', order.location1 && !order.location2 ? 'border-bottom' : '']">
                        {{ displayDate(order.shipDate1, '')  }}
                    </td>
                    <td [ngClass]="order.location1 && !order.location2 ? 'border-dark border-bottom' : ''">
                        {{ displayDate(order.deliveryDate1, '')  }}
                    </td>
                    <td [ngClass]="order.location1 && !order.location2 ? 'border-dark border-bottom' : ''">
                        {{ order?.location1 }}
                    </td>
                    <td [ngClass]="order.location1 && !order.location2 ? 'border-dark border-bottom' : ''">
                        {{ order?.locationEnd1 }}
                    </td>
                    <td [ngClass]="[order.location1 ? 'border-dark border-right' : '', order.location1 && !order.location2 ? 'border-bottom' : '']">
                        {{ order?.transCompany1 }}
                    </td>
                    <td>{{ order?.whseCompany }}</td>
                </tr>
                <tr>
                    <td>{{ order?.pickAddress }}</td>
                    <td [ngClass]="[order.location2 ? 'border-dark border-left' : '', (order.location2 && !order.location3) ? 'border-bottom' : '']">
                        {{ displayDate(order.shipDate2, '') }}
                    </td>
                    <td [ngClass]="order.location2 && !order.location3 ? 'border-dark border-bottom' : ''">
                        {{ displayDate(order.deliveryDate2, '')  }}
                    </td>
                    <td [ngClass]="order.location2 && !order.location3 ? 'border-dark border-bottom' : ''">
                        {{ order?.location2 }}
                    </td>
                    <td [ngClass]="order.location2 && !order.location3 ? 'border-dark border-bottom' : ''">
                        {{ order?.locationEnd2 }}
                    </td>
                    <td [ngClass]="[order.location2 ? 'border-dark border-right' : '', order.location2 && !order.location3 ? 'border-bottom' : '']">
                        {{ order?.transCompany2 }}
                    </td>
                    <td>{{ order?.whseAddress }}</td>
                </tr> 
                <tr>
                    <td>{{ order?.pickCSZ }}</td>
                    <td [ngClass]="[order.location3 ? 'border-dark border-left' : '', (order.location3 && !order.location4) ? 'border-bottom' : '']">
                        {{ displayDate(order.shipDate3, '')  }}
                    </td>
                    <td [ngClass]="order.location3 && !order.location4 ? 'border-dark border-bottom' : ''">
                        {{ displayDate(order.deliveryDate3, '')  }}
                    </td>
                    <td [ngClass]="order.location3 && !order.location4 ? 'border-dark border-bottom' : ''">
                        {{ order?.location3 }}
                    </td>
                    <td [ngClass]="order.location3 && !order.location4 ? 'border-dark border-bottom' : ''">
                        {{ order?.locationEnd3 }}
                    </td>
                    <td [ngClass]="[order.location3 ? 'border-dark border-right' : '', order.location3 && !order.location4 ? 'border-bottom' : '']">
                        {{ order?.transCompany3 }}
                    </td>
                    <td>
                        {{ order?.whseCSZ }}
                    </td>
                </tr>
                <tr *ngIf="order.whseEst">
                    <td colspan="6"></td>
                    <td>{{ order?.whseEst }}</td>
                </tr>                
                <tr *ngIf="order.location4">
                    <td></td>
                    <td class="border-dark border-left" [ngClass]="!order.location5 ? 'border-bottom' : '' ">
                        {{ displayDate(order.shipDate4, '')  }}
                    </td>
                    <td [ngClass]="!order.location5 ? 'border-dark border-bottom' : ''">
                        {{ displayDate(order.deliveryDate4, '')  }}
                    </td>
                    <td [ngClass]="!order.location5 ? 'border-dark border-bottom' : ''">
                        {{ order?.location4 }}
                    </td>
                    <td [ngClass]="!order.location5 ? 'border-dark border-bottom' : ''">
                        {{ order?.locationEnd4 }}
                    </td>
                    <td class="border-dark border-right" [ngClass]="!order.location5 ? 'border-bottom' : ''">
                        {{ order?.transCompany4 }}
                    </td>
                    <td></td>
                </tr>
                <tr *ngIf="order.location5">
                    <td></td>
                    <td class="border-dark border-left border-bottom">
                        {{ displayDate(order.shipDate5, '')  }}
                    </td>
                    <td class="border-dark border-bottom">
                        {{ displayDate(order.deliveryDate5, '')  }}
                    </td>
                    <td class="border-dark border-bottom">
                        {{ order?.location5 }}
                    </td>
                    <td class="border-dark border-bottom">
                        {{ order?.locationEnd5 }}
                    </td>
                    <td class="border-dark border-bottom border-right">
                        {{ order?.transCompany5 }}
                    </td>
                    <td></td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="row g-0">
        <div class="col-lg-10 pt-3 px-2">
            <span class="fw-bold">Vendor: </span>
            {{ order?.vendorInfo }}
        </div>
        <div class="col-lg-14 pt-3 px-2">
            <span class="fw-bold">Notes: </span>
            {{ order?.notes }}
        </div>
    </div>

    <div class="row g-0 text-white mt-2">
        <table class="w-100">
            <thead class="text-white mt-3 pe-2 pdf-bg">
                <th>ORDER CODE</th>
                <th>BRAND/CATEGORY</th>
                <th>ITEM DESCRIPTION</th>
                <th>SIZE</th>
                <th>ORDER</th>
                <th>SHIPPED</th>
            </thead>
            <tbody class="text-dark"  *ngFor="let item of orderItems; let i = index;">
                <tr [ngClass]="{'grid-stripe': grayRow[i]==='S'}">
                    <td>{{ item?.orderCode }}</td>
                    <td>{{ item?.brandCategory }}</td>
                    <td>{{ item?.itemDescription }}</td>
                    <td>{{ item?.size }}</td>
                    <td>{{ item.cases  |  number : '1.0-0' }}</td>
                    <td>{{ item.shipped | number : '1.0-0' }}</td>
                </tr>
                <tr [ngClass]="{'grid-stripe': grayRow[i]==='S'}">
                    <td></td>
                    <td colspan="3" class="text-center">
                        <span class="pe-1">[ CS Gross Lbs</span>
                        {{ item?.csGrossLbs | number : '1.2-2' }}
                        <span class="pe-1"> | Product Type</span>
                        {{ item?.productType }}
                        <span class="pe-1"> | TI/HI</span>
                        {{ item?.tiHi }}
                        <span class="pe-1"> | CS per Pallet</span>
                        {{ item?.csPerPallet }}
                        <span class="pe-1"> | Pallet Type</span>
                        {{ item?.palletType }} ]
                    </td>
                </tr>
            </tbody>
        </table>
    </div>

<div class="row g-0 text-white mt-2">
    <table class="w-100">
        <thead class="text-white mt-3 pe-2 pdf-bg">
            <th>ORDER CODE</th>
            <th>BRAND/CATEGORY</th>
            <th>ITEM DESCRIPTION</th>
            <th>SIZE</th>
            <th>BB</th>
            <th>LOT</th>
            <th>CASES</th>
        </thead>
        <tbody class="text-dark"  *ngFor="let item of orderItems2; let i = index;">
            <tr [ngClass]="{'grid-stripe': grayRow2[i]==='S'}">
                <td>{{ item?.orderCode }}</td>
                <td>{{ item?.brandCategory }}</td>
                <td>{{ item?.itemDescription }}</td>
                <td>{{ item?.size }}</td>
                <td>{{ displayDate(item.bbDate, '') }}</td>
                <td>{{ item?.lot }}</td>
                <td>{{ item?.case | number : '1.0-0'  }}</td>
            </tr>
        </tbody>
    </table>
</div>

<div class="row g-0 text-white mt-3 pdf-bg pdf-size10">
    <div class="col-lg-6 pt-0 px-2">
        <span class="pe-2">TOTAL CASES</span>
        {{ totalCases | number : '1.0-0' }}
    </div>
    <div class="col-lg-8 pt-0 px-2">
        <span class="pe-2">TOTAL PALLETS</span>
        {{ totalPallets |  number : '1.0-0' }}
    </div>
    <!-- <div class="col-lg-10 pt-0 px-2">
        <span class="pe-2">PALLET TYPE:</span>
        {{ order?.palletType }}
    </div> -->

    <div class="col-lg-10 pt-0 px-2 text-end">
        <span class="pe-2">TOTAL GROSS</span>
        {{ totalGrossLbs | number : '1.2-2'  }}
        <span class="pe-3"> lbs</span>
        {{ totalGrosskg | number : '1.2-2'  }}
        <span class="pe-2"> kg</span>
    </div>
</div>
<div class="row g-0 mt-3">
    <div class="col-lg-6 pt-0 px-2">
        <img class="logo img-responsive" src="/assets/images/Kalcor_Canada.png" height="100%" width="158px">
    </div>
    <div class="col-lg-18 pt-3 px-2">
        ​​CANADA OFFICES: 2275 Upper Middle Road East, Suite 101, Oakville, ON, L6H 0C3. Office 866.779.9850 Email:
        Inbox@kalcor.ca Website: www.kalcor.ca
    </div>
</div>
</div>