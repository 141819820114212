import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from "@angular/router";
import { Observable } from "rxjs";
import { MarketingService } from "src/app/services-azure/marketing.service";
import { MarketingData } from "src/app/models-azure/marketing-data.model";

@Injectable()
export class MarketingResolver implements Resolve<MarketingData> {
    marketingData: MarketingData;
    constructor(private marketingService: MarketingService) {
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<MarketingData> {
        const idField = 'id';
    const id =  parseInt(route.params[idField], 10);

    if (id === 0) {
      return;
    }
    return this.marketingService.getOne(id);
  }
        
    
}