<h5>Product Costing Review</h5>
<div class="smallSpacer"><h6>Filter</h6></div>
<div class="col-lg-44">

  <label class="formControlName ms-3">
    Brand:
    <select class="form-control" [(ngModel)]="brand" (change)="resetFilters()">
      <option [value]=""></option>
      <option *ngFor="let b of brandFilter" [value]="b">{{ b }}</option>
    </select>
  </label>

  <label class="formControlName ms-3">
    Category:
    <select class="form-control" [(ngModel)]="category" (change)="resetFilters()">
        <option [value]=""></option>
        <option *ngFor="let c of categoryFilter" [value]="c">{{ c }}</option>
    </select>
  </label>

  <label class="formControlName ms-3">
    Type of Costing:
    <select class="form-control" [(ngModel)]="costing" (change)="resetFilters()">
        <option [value]=""></option>
        <option *ngFor="let t of costingFilter" [value]="t">{{ t }}</option>
    </select>
  </label>

  <label class="formControlName ms-3">
    Cost Type:
    <select class="form-control" [(ngModel)]="costType" (change)="resetFilters()">
      <option [value]=""></option>
      <option *ngFor="let t of costTypeFilter" [value]="t">{{ t }}</option>
    </select>
  </label>

  <label class="formControlName ms-3">
    Group:
    <select class="form-control" [(ngModel)]="group" (change)="resetFilters()">
      <option [value]=""></option>
      <option *ngFor="let t of groupFilter" [value]="t">{{ t }}</option>
    </select>
  </label>
  <div class="smallSpacer"></div>
  <button type="button" class="btn btn-primary mt-0 ms-3" (click)="getDefaultDataScreen()">Reset</button>
</div>
<div class="spacer"></div>
<table class="table table-striped">
  <thead>
  <tr>
    <!-- <th scope="col">Type of Costing</th>
    <th scope="col">Cost Type</th> -->
    <th scope="col">Order Code</th>
    <!-- <th scope="col">Brand</th>
    <th scope="col">Category</th> -->
    <th scope="col">Item Description</th>
    <th scope="col">Size</th>

    <th scope="col">whsSelling</th>
    <th scope="col">whsSrp</th>
    <th scope="col">whsUnit</th>
    <th scope="col">marketing</th>
    <th scope="col">marketing1</th>
    <th scope="col">billing</th>
    <th scope="col">billing1</th>
    <th scope="col">terms</th>
    <th scope="col">terms1</th>
    <th scope="col">commission</th>
    <th scope="col">commission1</th>
    <th scope="col">commission2</th>
    <th scope="col">kalcor</th>
    <th scope="col">kalcor1</th>
    <th scope="col">cndFrtCostCase</th>
    <th scope="col">cndFrtCostPallet</th>
    <th scope="col">storageCostCase</th>
    <th scope="col">storageCostPallet</th>
    <th scope="col">exchangeBudget</th>
    <th scope="col">netCsUsd</th>
    <th scope="col">clearingCs</th>
    <th scope="col">clearingCost</th>
    <th scope="col">duty</th>
    <th scope="col">freightCs</th>
    <th scope="col">fobNetReturn</th>
    <th scope="col">fobDifference</th>
    <th scope="col">fobNetVendorCost</th>
    <th scope="col">fobNetVendorUnit</th>
    <th scope="col">confirmedBy</th>
    <th scope="col">confirmDate</th>
    <th scope="col">fobConfirmed</th>
    <th scope="col">vendorPurchaseCaseCost</th>
    <th scope="col">vendorPurchaseCaseCostUnit</th>
    <th scope="col">fobDifference</th>
    <th scope="col">royalty</th>
    <th scope="col">royalty1</th>
    <th scope="col">currencyCode</th>
    <th scope="col">cndFrtCostLb</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let p of products | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize">
  <!-- <tr *ngFor="let p of products | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize" (click)="onRowClicked(p)"> -->
    <!-- <td>{{ p.activeListings }}</td>
    <td>{{ p.costType }}</td> -->
    <td>
      <a (click)="onRowClicked(p)" class="pointer">
        <u class="text-primary">{{ p.orderCode }}</u>
      </a>
    </td>
    <!-- <td>{{ p.brand }}</td>
    <td>{{ p.category }}</td> -->
    <td>{{ p.itemDescription }}</td>
    <td>{{ p.size }}</td>

    <td>{{ p.whsSelling }}</td>
    <td>{{ p.whsSrp }}</td>
    <td>{{ p.whsUnit }}</td>
    <td>{{ p.marketing }}</td>
    <td>{{ p.marketing1 }}</td>
    <td>{{ p.billing }}</td>
    <td>{{ p.billing1 }}</td>
    <td>{{ p.terms }}</td>
    <td>{{ p.terms1 }}</td>
    <td>{{ p.commission }}</td>
    <td>{{ p.commission1 }}</td>
    <td>{{ p.commission2 }}</td>
    <td>{{ p.kalcor }}</td>
    <td>{{ p.kalcor1 }}</td>
    <td>{{ p.cndFrtCostCase }}</td>
    <td>{{ p.cndFrtCostPallet }}</td>
    <td>{{ p.storageCostCase }}</td>
    <td>{{ p.storageCostPallet }}</td>
    <td>{{ p.exchangeBudget }}</td>
    <td>{{ p.netCsUsd }}</td>
    <td>{{ p.clearingCs }}</td>
    <td>{{ p.clearingCost }}</td>
    <td>{{ p.duty }}</td>
    <td>{{ p.freightCs }}</td>
    <td>{{ p.fobNetReturn }}</td>
    <td>{{ p.fobDifference }}</td>
    <td>{{ p.fobNetVendorCost }}</td>
    <td>{{ p.fobNetVendorUnit }}</td>
    <td>{{ p.confirmedBy }}</td>
    <td>{{ p.confirmDate }}</td>
    <td>{{ p.fobConfirmed }}</td>
    <td>{{ p.vendorPurchaseCaseCost }}</td>
    <td>{{ p.vendorPurchaseCaseCostUnit }}</td>
    <td>{{ p.fobDifference }}</td>
    <td>{{ p.royalty }}</td>
    <td>{{ p.royalty1 }}</td>
    <td>{{ p.currencyCode }}</td>
    <td>{{ p.cndFrtCostLb }}</td>


  </tr>
  <div *ngIf="products!==undefined">
  <ngb-pagination
  [(page)]="page"
  [pageSize]="pageSize"
  [collectionSize]="products?.length"></ngb-pagination>
  </div>
  </tbody>
</table>
