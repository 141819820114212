import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators
 } from '@angular/forms';
import { Subject, fromEventPattern, throwError } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { Transportation } from '../../models/transportation.model';
import { TransportationService } from '../../services/transportation.service';
import { OrderItems } from '../../models/orderItems.model';
import { OrderItemsService } from '../../services/order-items.service';
import { Order, OrderPDF } from '../../models/order-model';
import { OrderService } from '../../services/order.service';
import { FreightType } from '../../models/freight-type-model';
import { FreightTypeService } from '../../services/freight-type.service';
import { OrderMasterDetails } from '../../models/order-master-details.model';
import { Customer } from '../../models/customer.model';
import { CustomerService } from '../../services/customer.service';
import { ShipToAddress } from '../../models/shipto.model';
import { ShipToAddressService } from '../../services/ship-to-address.service';
import { Vendor } from '../../models/vendor-model';
import { VendorService } from '../../services/vendor.service';
import { PickupLocation } from '../../models/pickup-location.model';
import { PickupLocationService } from '../../services/pickup-location.service';
import { Warehouse } from '../../models/warehouse.model';
import { WarehouseService } from '../../services/warehouse.service';
import { Location} from '../../models/location-model';
import { LocationService } from '../../services/location.service';
import { Broker } from '../../models/broker.model';
import { BrokerService } from '../../services/broker.service';
import { Term } from '../../models/term-model';
import { TermsService } from '../../services/terms.service';
import { Big } from 'big.js';
import * as jsPDF from 'jspdf';
import * as html2pdf from 'html2pdf.js';
import { Sorter2Service } from '../../services/sorter2.service';
@Component({
  selector: 'app-shipping-edit',
  templateUrl: './shipping-edit.component.html',
  styleUrls: ['./shipping-edit.component.scss']
})
export class ShippingEditComponent implements OnInit {
  transport: Transportation[];
  order: Order;
  orderSelected: OrderPDF;
  itemsOriginal: OrderItems[];
  items: OrderItems[];
  orderItems: OrderItems[];
  masterDetails: OrderMasterDetails;
  orderForm: UntypedFormGroup;
  freightType: FreightType[];
  successMessage: string;
  private success = new Subject<string>();
  staticAlertClosed = false;
  grayRow = [];
  loaded = false;
  customer: Customer;
  shipTo: ShipToAddress;
  vendor: Vendor;
  pickup: PickupLocation;
  warehouse: Warehouse;
  broker: Broker;
  term: Term;
  transport1: string;
  transport2: string;
  transport3: string;
  transport4: string;
  transport5: string;
  location1: Location;
  location2: Location;
  location3: Location;
  location4: Location;
  location5: Location;
  locationEnd1: Location;
  locationEnd2: Location;
  locationEnd3: Location;
  locationEnd4: Location;
  locationEnd5: Location;

  freightTypeCompany: string;
  display='none';
  currentItemIndex = 0;
  modalTitle = 'ENTER CASE SHIPPING INFORMATION';
  rowModalShow = [true, false, false, false, false, false, false, false, false, false ];
  caseName = ['case1', 'case2', 'case3', 'case4', 'case5', 'case6', 'case7', 'case8', 'case9', 'case10'];
  masterName = ['shipmentDate', 'deliveredDateAppt', 'freightTypeId', 'appConfirmed',
                'transportCo1', 'transportCo2', 'transportCo3', 'palletCount', 'palletGrossLbs'];
  subCase1: any;
  subCase2: any;
  subCase3: any;
  subCase4: any;
  subCase5: any;
  subCase6: any;
  subCase7: any;
  subCase8: any;
  subCase9: any;
  subCase10: any;
  totalCases = 0;
  totalPallets = 0;
  totalGrossLbs = 0;
  totalGrosskg = 0;
  totalCaseCost = 0;
  updateOrder = false;
  updateItems = [];
  caseCostOriginal = [];
  alertType = "success";
  vendorIndex = 5;
  title = '';
  reportName = '';
  reportNames = [
    {
    Name: 'Sales Order', 
    Id: 'my-sales-order',
    filename: 'Sales Order',
    Orientation: 'landscape',
    TopMargin: .1,
    LRMargin: .6
    },
    {
      Name: 'Packing Slip', 
      Id: 'my-packing-slip',
      filename: 'Packing Slip',
      Orientation: 'landscape',
      TopMargin: .6,
      LRMargin: .3
    },
    {
      Name: 'Shipping', 
      Id: 'my-shipping',
      filename: 'Shipping',
      Orientation: 'landscape',
      TopMargin: .1,
      LRMargin: .6
    },
    {
      Name: 'Commercial Invoice', 
      Id: 'my-commercial-invoice',
      filename: 'Commercial Invoice',
      Orientation: 'landscape',
      TopMargin: .1,
      LRMargin: .6
      },    
      {
        Name: 'Vendor Sales Order', 
        Id: 'my-sales-order-vendor',
        filename: 'Vendor Sales Order',
        Orientation: 'landscape',
        TopMargin: .1,
        LRMargin: .6
      },
      {
        Name: 'Invoice', 
        Id: 'my-invoicing',
        filename: 'Invoice',
        Orientation: 'landscape',
        TopMargin: .1,
        LRMargin: .6
      }      
  ];
  currentIndex: number;
  dataChanged = false;
  shipOrderBtn = false;
  invoiceOrderBtn = false;
  salesOrderPrintBtn = false;
  slipPrintBtn = false;
  shipPrintBtn = false;
  invoicePrintBtn = false;
  poPrintBtn = false;
  deleteOrderBtn = false;
  vendorPaymentTerms: string;
  vendorPurchaseCaseCost = true;
  fOBNetVendorCost = false;
  warehouseLine3 = '';
  warehouseLine4 = '';
  constructor(
    private customerService: CustomerService,
    private shipToService: ShipToAddressService,
    private vendorService: VendorService,
    private pickupService: PickupLocationService,
    private warehouseService: WarehouseService,
    private orderService: OrderService,
    private route: ActivatedRoute,
    private router: Router,
    private fb: UntypedFormBuilder,
    private brokerService: BrokerService,
    private termService: TermsService,
    private freightService: FreightTypeService,
    private locationService: LocationService,
    private transportService: TransportationService,
    private checkRef: ChangeDetectorRef,
    private orderItemService: OrderItemsService,
    private sort2Svc: Sorter2Service
  ) { }

  ngOnInit() {
    this.orderForm = this.fb.group({
      shipmentDate: [''],
      deliveredDateAppt: [''],
      freightTypeId: [''],
      appConfirmed: [''],
      transportCo1: [''],
      transportCo2: [''],
      transportCo3: [''],
      freightType1: [''],
      freightType2: [''],
      freightType3: [''],
      freightCost1: [''],
      freightCost2: [''],
      freightCost3: [''],
      freightCostSales1: [''],
      freightCostSales2: [''],
      freightCostSales3: [''],
      freightTotalCost1: [{ value: '', disabled: true }],
      freightTotalCost2: [{ value: '', disabled: true }],
      freightTotalCost3: [{ value: '', disabled: true }],
      bbDate1: [''],
      bbDate2: [''],
      bbDate3: [''],
      bbDate4: [''],
      bbDate5: [''],
      bbDate6: [''],
      bbDate7: [''],
      bbDate8: [''],
      bbDate9: [''],
      bbDate10: [''],
      case1: [''],
      case2: [''],
      case3: [''],
      case4: [''],
      case5: [''],
      case6: [''],
      case7: [''],
      case8: [''],
      case9: [''],
      case10: [''],
      lotNo1: [''],
      lotNo2: [''],
      lotNo3: [''],
      lotNo4: [''],
      lotNo5: [''],
      lotNo6: [''],
      lotNo7: [''],
      lotNo8: [''],
      lotNo9: [''],
      lotNo10: [''],
      palletGrossLbs: ['', Validators.required],
      palletCount: ['', Validators.required]
     });
    this.transportService.getAll().subscribe(transport => {
      this.transport = transport;
      this.freightService.getAll().subscribe(freight => {
        this.freightType = freight;
        this.route.data.subscribe(order => {
          if (order.dataOrder !== undefined) {
            this.order = order.dataOrder;
            this.itemsOriginal = this.sort2Svc.sorter(order.dataOrder.items,'brandCategory','itemDescription');
            this.itemsOriginal.forEach(e => {
              const caseCostOnly = {id: e.id, 
                                  caseCost: e.caseCost,
                                  cases: e.cases,
                                  extCaseCost: e.extCaseCost};
              this.caseCostOriginal.push(caseCostOnly);
            })
            
            this.items = this.itemsOriginal.filter(item => item.cases);
            this.buttonEnabler();
            this.items.forEach( item => {
              this.updateItems.push(false);
            })
            this.fillFormData();
            this.loadServices();
            this.onChanges();
            this.reCalculate();
            this.loaded = true;
          }
        });
      });
    });
    setTimeout(() => this.staticAlertClosed = true, 20000);
    this.success.subscribe((message) => this.successMessage = message);
    this.success.pipe(
      debounceTime(5000)
    ).subscribe(() => this.successMessage = null);    
  }
  fillFormData() {
    this.orderForm.setValue({
      shipmentDate: this.order.shipmentDate === null ? this.order.shipmentDate : this.order.shipmentDate.toString().substr(0,10),
      deliveredDateAppt: this.order.deliveredDateAppt,
      freightTypeId: this.order.freightTypeId,
      appConfirmed: this.order.appConfirmed ? this.order.appConfirmed.trim() : this.order.appConfirmed,
      transportCo1: this.order.transportCo1,
      transportCo2: this.order.transportCo2,
      transportCo3: this.order.transportCo3,
      freightType1: this.order.freightType1,
      freightType2: this.order.freightType2,
      freightType3: this.order.freightType3,
      freightCost1: this.order.freightCost1,
      freightCost2: this.order.freightCost2,
      freightCost3: this.order.freightCost3,
      freightCostSales1: this.order.freightCostSales1,
      freightCostSales2: this.order.freightCostSales2,
      freightCostSales3: this.order.freightCostSales3,
      freightTotalCost1: this.order.freightTotalCost1,
      freightTotalCost2: this.order.freightTotalCost2,
      freightTotalCost3: this.order.freightTotalCost3,
      bbDate1: [''],
      bbDate2: [''],
      bbDate3: [''],
      bbDate4: [''],
      bbDate5: [''],
      bbDate6: [''],
      bbDate7: [''],
      bbDate8: [''],
      bbDate9: [''],
      bbDate10: [''],
      case1: [''],
      case2: [''],
      case3: [''],
      case4: [''],
      case5: [''],
      case6: [''],
      case7: [''],
      case8: [''],
      case9: [''],
      case10: [''],
      lotNo1: [''],
      lotNo2: [''],
      lotNo3: [''],
      lotNo4: [''],
      lotNo5: [''],
      lotNo6: [''],
      lotNo7: [''],
      lotNo8: [''],
      lotNo9: [''],
      lotNo10: [''],
      palletGrossLbs: this.order.palletGrossLbs,
      palletCount: this.order.palletCount
     });
  }
  loadServices() {
    if (!this.order) {
      return;
    }
    if (this.order.billToId) {
      const id = this.order.billToId;
      this.customerService.getOne(id).subscribe(cust => this.customer = cust);
    }
    if (this.order.brokerId) {
      const id = this.order.brokerId;
      this.brokerService.getOne(id).subscribe(broker => this.broker = broker);
    }
    if (this.order.shipToId) {
      const id = this.order.shipToId;
      this.shipToService.getOne(id).subscribe(ship => this.shipTo = ship);
    }
    if (this.order.termsId) {
      const id = this.order.termsId;
      this.termService.getOne(id).subscribe(terms => this.term = terms);
    }
    
    if (this.order.vendorPickupId) {
      const id = this.order.vendorPickupId;
      this.pickupService.getOne(id).subscribe(pick => this.pickup = pick);
    }

    if (this.order.shippingLane1) {
      const id = this.order.shippingLane1;
      this.locationService.getOne(id).subscribe(loc => this.location1 = loc);
    }
    if (this.order.shippingLane2) {
      const id = this.order.shippingLane2;
      this.locationService.getOne(id).subscribe(loc => this.location2 = loc);
    }
    if (this.order.shippingLane3) {
      const id = this.order.shippingLane3;
      this.locationService.getOne(id).subscribe(loc => this.location3 = loc);
    }
    if (this.order.shippingLane4) {
      const id = this.order.shippingLane4;
      this.locationService.getOne(id).subscribe(loc => this.location4 = loc);
    }
    if (this.order.shippingLane5) {
      const id = this.order.shippingLane5;
      this.locationService.getOne(id).subscribe(loc => this.location5 = loc);
    }

    if (this.order.whsid) {
      const id = this.order.whsid;
      this.warehouseService.getOne(id).subscribe(whse => {
        this.warehouse = whse;
        const whseEst = this.isEmpty(this.warehouse.federalEstablishment) ? '' : 'Est. ' + this.warehouse.federalEstablishment;
        const whseCSZ = this.getAddress2('warehouse');
        this.warehouseLine3 = whseCSZ;
        this.warehouseLine4 = whseEst;
      });
    }
    this.vendorPaymentTerms = '';
    if (this.order.vendorId) {
      const id = this.order.vendorId;
      this.vendorService.getOne(id).subscribe(vend => {
        this.vendor = vend;
        if (this.vendor.termsId) {
          this.termService.getOne(this.vendor.termsId).subscribe(vendT => this.vendorPaymentTerms = vendT.paymentTerms);
        }
      })
      this.vendorService.getOne(id).subscribe(vend => this.vendor = vend);
    }

    if (this.order.shippingLaneEnd1) {
      const id = this.order.shippingLaneEnd1;
      this.locationService.getOne(id).subscribe(loc => this.locationEnd1 = loc);
    }
    if (this.order.shippingLaneEnd2) {
      const id = this.order.shippingLaneEnd2;
      this.locationService.getOne(id).subscribe(loc => this.locationEnd2 = loc);
    }
    if (this.order.shippingLaneEnd3) {
      const id = this.order.shippingLaneEnd3;
      this.locationService.getOne(id).subscribe(loc => this.locationEnd3 = loc);
    }
    if (this.order.shippingLaneEnd4) {
      const id = this.order.shippingLaneEnd4;
      this.locationService.getOne(id).subscribe(loc => this.locationEnd4 = loc);
    }
    if (this.order.shippingLaneEnd5) {
      const id = this.order.shippingLaneEnd5;
      this.locationService.getOne(id).subscribe(loc => this.locationEnd5 = loc);
    }

  }
  getAddress2(item) {
    let city = '';
    let state = '';
    let zip = '';
    let ret = '';
    switch (item) {
      case 'customer':
        if (this.customer) {
          city = this.customer.city;
          state = this.customer.stateProvince;
          zip = this.customer.zipPostalCode;
        }
        break;
      case 'shipto':
          if (this.shipTo) {
            city = this.shipTo.city;
            state = this.shipTo.stateProvince;
            zip = this.shipTo.zipPostalCode;
          }
          break;
      case 'pickup':
          if (this.pickup) {
            city = this.pickup.city;
            state = this.pickup.stateProvince;
            zip = this.pickup.zipPostalCode;
          }
          break;
          
      case 'warehouse':
          if (this.warehouse) {
            city = this.warehouse.city;
            state = this.warehouse.stateProvince;
            zip = this.warehouse.zipPostalCode;
          }
          break;
      case 'vendor':
          if (this.vendor) {
            city = this.vendor.city;
            state = this.vendor.stateProvince;
            zip = this.vendor.zipPostalCode;
          }
          break;
    }
    
    if (city) {
      ret = city;
      if (state) {
        ret += ', ' + state;
      }
    }

    if (zip) {
      ret += ' ' + zip;
    }

    return ret.trim();

  }
  displayDate(dt, t) {
    
    if (dt) {
      if (t === undefined) {
        t = '';
      }
      let x = new Date(dt);
      if (t == 'T') {
        return x.toLocaleDateString() + ' @ ' + x.toLocaleTimeString();
      }
      return x.toLocaleDateString();
    } 
    return dt
  }
  getVendor() {
    let ret = '';
    if (this.vendor) {
      ret += this.vendor.company.trim();
      ret += ' ' + this.vendor.address;
      ret += ' ' + this.getAddress2('vendor');
    }

    return ret;
  }

  onChangesCase() {
    this.subCase1 = this.orderForm.get('case1').valueChanges.subscribe(val => {
      this.addCases();
    })
    this.subCase2 = this.orderForm.get('case2').valueChanges.subscribe(val => {
      this.addCases();
    })
    this.subCase3 = this.orderForm.get('case3').valueChanges.subscribe(val => {
      this.addCases();
    })
    this.subCase4 = this.orderForm.get('case4').valueChanges.subscribe(val => {
      this.addCases();
    })
    this.subCase5 = this.orderForm.get('case5').valueChanges.subscribe(val => {
      this.addCases();
    })
    this.subCase6 = this.orderForm.get('case6').valueChanges.subscribe(val => {
      this.addCases();
    })
    this.subCase7 = this.orderForm.get('case7').valueChanges.subscribe(val => {
      this.addCases();
    })
    this.subCase8 = this.orderForm.get('case8').valueChanges.subscribe(val => {
      this.addCases();
    })
    this.subCase9 = this.orderForm.get('case9').valueChanges.subscribe(val => {
      this.addCases();
    })
    this.subCase10 = this.orderForm.get('case10').valueChanges.subscribe(val => {
      this.addCases();
    })

  }
  onChanges(): void {
    this.masterName.forEach(name => {
      this.orderForm.get(name).valueChanges.subscribe(val => {
        this.updateOrder = true;
      });
    });
    this.orderForm.get('freightCost1').valueChanges.subscribe(val => {
      this.calculateFreightCost(1);
    })
    this.orderForm.get('freightCost2').valueChanges.subscribe(val => {
      this.calculateFreightCost(2);
    })
    this.orderForm.get('freightCost3').valueChanges.subscribe(val => {
      this.calculateFreightCost(3);
    })
    this.orderForm.get('freightCostSales1').valueChanges.subscribe(val => {
      this.calculateFreightCost(1);
    })
    this.orderForm.get('freightCostSales2').valueChanges.subscribe(val => {
      this.calculateFreightCost(2);
    })
    this.orderForm.get('freightCostSales3').valueChanges.subscribe(val => {
      this.calculateFreightCost(3);
    })
    this.orderForm.get('transportCo1').valueChanges.subscribe(val => {
      this.calculateFreightCost(1);
    })
    this.orderForm.get('transportCo2').valueChanges.subscribe(val => {
      this.calculateFreightCost(2);
    })
    this.orderForm.get('transportCo3').valueChanges.subscribe(val => {
      this.calculateFreightCost(3);
    })
    this.orderForm.get('palletCount').valueChanges.subscribe(val => {
      this.reCalculate();
    })
    this.orderForm.get('palletGrossLbs').valueChanges.subscribe(val => {
      this.reCalculate();
    })

  }

  calculateFreightCost(index) {
    this.updateOrder = true;
    const transportVar = 'transportCo' + index;
    const costVar = 'freightCost' + index;
    const fcsPerCentVar = 'freightCostSales' + index;
    const totalCostVar = 'freightTotalCost' + index;
    const transport = this.orderForm.get(transportVar).value;
    let cost = +this.orderForm.get(costVar).value;
    let fcs = +this.orderForm.get(fcsPerCentVar).value;
    if (!transport) {
      if (cost != 0) {
        this.orderForm.controls[costVar].setValue(0);
        cost = 0;
      }
      if (fcs != 0) {
        this.orderForm.controls[fcsPerCentVar].setValue(0);
        fcs = 0;
      }
    }
    let totalCost = 0;
    // total cost [Cost + (Cost x FCS%) = Total Cost] 
    const bigNum: any = Big(fcs)
      .times(.01)
      .times(cost)
      .plus(cost)
      .round(2)
      totalCost = bigNum * 1;
     this.orderForm.controls[totalCostVar].setValue(totalCost);
  }

  addCases() {
    this.dataChanged = true;
    let caseCount = 0;
    this.caseName.forEach(name => {
      if (this.orderForm.controls[name].value) {
        caseCount += this.orderForm.controls[name].value * 1;
      }
    });
    this.items[this.currentItemIndex].shipped = caseCount;
    this.reCalculate();
  }
  reCalculate() {
    this.totalCases = 0;
    this.totalPallets = this.orderForm.controls["palletCount"].value === null ? 0 : this.orderForm.controls["palletCount"].value * 1;
    this.totalGrossLbs = 0;
    this.totalGrosskg = 0;
    let blankNext = true;
    this.items.forEach((element, index) => {
      let cases = 0;
      if (element.shipped) {
        cases = element.shipped * 1;
      }
      this.totalCases += cases;
      if (element.csPerPallet != 0) {
        const bigNum: any = Big(cases)
        .div(element.csPerPallet)
        .round(0, 3);
        let pallets = bigNum * 1;
        if (pallets == 0 && cases !=0 ) {
          pallets = 1;
        }
        // this.totalPallets += pallets;
      }
      this.totalGrossLbs += element.csGrossLbs * cases;
      this.totalGrosskg += element.csGrossKg * cases;
    });
    const palletLbs = this.orderForm.controls["palletGrossLbs"].value === null ? 0 : this.orderForm.controls["palletGrossLbs"].value * 1;
    const bigNum: any = Big(palletLbs)
      .times(.453592)
      .round(2);
    const palletKg = bigNum * 1;
    this.totalGrossLbs += this.totalPallets * palletLbs;
    this.totalGrosskg += this.totalPallets * palletKg;

    this.checkRef.detectChanges();
  }

  onCloseHandled(){
    this.display='none';
    this.items[this.currentItemIndex].bbDate1 = this.orderForm.controls.bbDate1.value;
    this.items[this.currentItemIndex].bbDate2 = this.orderForm.controls.bbDate2.value;
    this.items[this.currentItemIndex].bbDate3 = this.orderForm.controls.bbDate3.value;
    this.items[this.currentItemIndex].bbDate4 = this.orderForm.controls.bbDate4.value;
    this.items[this.currentItemIndex].bbDate5 = this.orderForm.controls.bbDate5.value;
    this.items[this.currentItemIndex].bbDate6 = this.orderForm.controls.bbDate6.value;
    this.items[this.currentItemIndex].bbDate7 = this.orderForm.controls.bbDate7.value;
    this.items[this.currentItemIndex].bbDate8 = this.orderForm.controls.bbDate8.value;
    this.items[this.currentItemIndex].bbDate9 = this.orderForm.controls.bbDate9.value;
    this.items[this.currentItemIndex].bbDate10 = this.orderForm.controls.bbDate10.value;
    this.items[this.currentItemIndex].case1 = this.orderForm.controls.case1.value;
    this.items[this.currentItemIndex].case2 = this.orderForm.controls.case2.value;
    this.items[this.currentItemIndex].case3 = this.orderForm.controls.case3.value;
    this.items[this.currentItemIndex].case4 = this.orderForm.controls.case4.value;
    this.items[this.currentItemIndex].case5 = this.orderForm.controls.case5.value;
    this.items[this.currentItemIndex].case6 = this.orderForm.controls.case6.value;
    this.items[this.currentItemIndex].case7 = this.orderForm.controls.case7.value;
    this.items[this.currentItemIndex].case8 = this.orderForm.controls.case8.value;
    this.items[this.currentItemIndex].case9 = this.orderForm.controls.case9.value;
    this.items[this.currentItemIndex].case10 = this.orderForm.controls.case10.value;
    this.items[this.currentItemIndex].lotNo1 = this.orderForm.controls.lotNo1.value;
    this.items[this.currentItemIndex].lotNo2 = this.orderForm.controls.lotNo2.value;
    this.items[this.currentItemIndex].lotNo3 = this.orderForm.controls.lotNo3.value;
    this.items[this.currentItemIndex].lotNo4 = this.orderForm.controls.lotNo4.value;
    this.items[this.currentItemIndex].lotNo5 = this.orderForm.controls.lotNo5.value;
    this.items[this.currentItemIndex].lotNo6 = this.orderForm.controls.lotNo6.value;
    this.items[this.currentItemIndex].lotNo7 = this.orderForm.controls.lotNo7.value;
    this.items[this.currentItemIndex].lotNo8 = this.orderForm.controls.lotNo8.value;
    this.items[this.currentItemIndex].lotNo9 = this.orderForm.controls.lotNo9.value;
    this.items[this.currentItemIndex].lotNo10 = this.orderForm.controls.lotNo10.value;
    this.subCase1.unsubscribe();
    this.subCase2.unsubscribe();
    this.subCase3.unsubscribe();
    this.subCase4.unsubscribe();
    this.subCase5.unsubscribe();
    this.subCase6.unsubscribe();
    this.subCase7.unsubscribe();
    this.subCase8.unsubscribe();
    this.subCase9.unsubscribe();
    this.subCase10.unsubscribe();    
 }
massageDate(shipDate) {
  return shipDate ? shipDate.toString().substr(0,10) : shipDate;
}
 openModal(index){
    this.updateItems[index] = true;

    this.modalTitle = 'ENTER CASE SHIPPING INFORMATION - ' + this.items[index].orderCode;
    this.currentItemIndex = index;

    this.orderForm.controls.bbDate1.setValue(this.massageDate(this.items[index].bbDate1));
    this.orderForm.controls.bbDate2.setValue(this.massageDate(this.items[index].bbDate2));
    this.orderForm.controls.bbDate3.setValue(this.massageDate(this.items[index].bbDate3));
    this.orderForm.controls.bbDate4.setValue(this.massageDate(this.items[index].bbDate4));
    this.orderForm.controls.bbDate5.setValue(this.massageDate(this.items[index].bbDate5));
    this.orderForm.controls.bbDate6.setValue(this.massageDate(this.items[index].bbDate6));
    this.orderForm.controls.bbDate7.setValue(this.massageDate(this.items[index].bbDate7));
    this.orderForm.controls.bbDate8.setValue(this.massageDate(this.items[index].bbDate8));
    this.orderForm.controls.bbDate9.setValue(this.massageDate(this.items[index].bbDate9));
    this.orderForm.controls.bbDate10.setValue(this.massageDate(this.items[index].bbDate10));
    this.orderForm.controls.case1.setValue(this.items[index].case1);
    this.orderForm.controls.case2.setValue(this.items[index].case2);
    this.orderForm.controls.case3.setValue(this.items[index].case3);
    this.orderForm.controls.case4.setValue(this.items[index].case4);
    this.orderForm.controls.case5.setValue(this.items[index].case5);
    this.orderForm.controls.case6.setValue(this.items[index].case6);
    this.orderForm.controls.case7.setValue(this.items[index].case7);
    this.orderForm.controls.case8.setValue(this.items[index].case8);
    this.orderForm.controls.case9.setValue(this.items[index].case9);
    this.orderForm.controls.case10.setValue(this.items[index].case10);
    this.orderForm.controls.lotNo1.setValue(this.items[index].lotNo1);
    this.orderForm.controls.lotNo2.setValue(this.items[index].lotNo2);
    this.orderForm.controls.lotNo3.setValue(this.items[index].lotNo3);
    this.orderForm.controls.lotNo4.setValue(this.items[index].lotNo4);
    this.orderForm.controls.lotNo5.setValue(this.items[index].lotNo5);
    this.orderForm.controls.lotNo6.setValue(this.items[index].lotNo6);
    this.orderForm.controls.lotNo7.setValue(this.items[index].lotNo7);
    this.orderForm.controls.lotNo8.setValue(this.items[index].lotNo8);
    this.orderForm.controls.lotNo9.setValue(this.items[index].lotNo9);
    this.orderForm.controls.lotNo10.setValue(this.items[index].lotNo10);
    this.rowModalShow[1] = this.items[index].case2 ? true : false;
    this.rowModalShow[2] = this.items[index].case3 ? true : false;
    this.rowModalShow[3] = this.items[index].case4 ? true : false;
    this.rowModalShow[4] = this.items[index].case5 ? true : false;
    this.rowModalShow[5] = this.items[index].case6 ? true : false;
    this.rowModalShow[6] = this.items[index].case7 ? true : false;
    this.rowModalShow[7] = this.items[index].case8 ? true : false;
    this.rowModalShow[8] = this.items[index].case9 ? true : false;
    this.rowModalShow[9] = this.items[index].case10 ? true : false;
    this.onChangesCase();
    this.display='block';
  }
  onAddLine() {
    const noShowIndex = this.rowModalShow.findIndex( row => !row);
    this.rowModalShow[noShowIndex] = true;
  }

  onSubmit() {
    this.alertType = "success";
    if (this.updateOrder) {
      this.updateOrderProcess();
      this.orderService.update(this.order).subscribe(data => {
        this.success.next(`Successfully updated order.`);
        this.updateOrder = false;
        this.updateStatus();
      }, error => {
        this.alertType = "danger";
        this.success.next(`Unable to update order.`);
      });
    } else {
      this.updateStatus();
    }
    let shipChanged = 0;
    let shipProcessed = 0;

    if (this.dataChanged) {
      this.updateItems.forEach((element, index) => {
        if (element) {
          shipChanged++;
          this.orderItemService.update(this.items[index]).subscribe( data => {
            shipProcessed++;
            if (shipProcessed >= shipChanged) {
              this.success.next(`Successfully updated items.`);
              this.dataChanged = false;
              this.updateStatus();
            }
          }, error => {
            shipProcessed ++; 
            this.alertType = "danger";
            if (shipProcessed >= shipChanged) {
              this.success.next(`Unable to perform items update.`);
            }
          });
        }
      });
    } else {
      this.updateStatus();
    }
  }
  updateStatus() {
    if (this.updateOrder || this.dataChanged) {
      return;
    }
    this.order.orderStatus = "Shipped";
    this.orderService.updateStatus(this.order).subscribe(data => {
      this.success.next(`Successfully updated status.`);
      this.buttonEnabler();
    })
  }
  updateOrderProcess() {
    this.order.palletCount = this.orderForm.controls.palletCount.value;
    this.order.palletGrossLbs = this.orderForm.controls.palletGrossLbs.value;
    this.order.shipmentDate = this.orderForm.controls.shipmentDate.value;
    this.order.deliveredDateAppt = this.orderForm.controls.deliveredDateAppt.value;
    this.order.freightTypeId = this.orderForm.controls.freightTypeId.value;
    this.order.appConfirmed = this.orderForm.controls.appConfirmed.value;
    this.order.transportCo1 = this.orderForm.controls.transportCo1.value;
    this.order.transportCo2 = this.orderForm.controls.transportCo2.value;
    this.order.transportCo3 = this.orderForm.controls.transportCo3.value;
    this.order.freightType1 = this.orderForm.controls.freightType1.value;
    this.order.freightType2 = this.orderForm.controls.freightType2.value;
    this.order.freightType3 = this.orderForm.controls.freightType3.value;
    this.order.freightCost1 = this.orderForm.controls.freightCost1.value;
    this.order.freightCost2 = this.orderForm.controls.freightCost2.value;
    this.order.freightCost3 = this.orderForm.controls.freightCost3.value;
    this.order.freightCostSales1 = this.orderForm.controls.freightCostSales1.value;
    this.order.freightCostSales2 = this.orderForm.controls.freightCostSales2.value;
    this.order.freightCostSales3 = this.orderForm.controls.freightCostSales3.value;
    this.order.freightTotalCost1 = this.orderForm.controls.freightTotalCost1.value;
    this.order.freightTotalCost2 = this.orderForm.controls.freightTotalCost2.value;
    this.order.freightTotalCost3 = this.orderForm.controls.freightTotalCost3.value;
  }
  printProcess(report) {
    const myTotalCases = this.totalCases;
    const myTotalPallets = this.totalPallets;
    const myTotalGrossLbs = this.totalGrossLbs;
    const myTotalCaseCost = this.totalCaseCost;
    const mytotalGrosskg = this.totalGrosskg;
    this.items.forEach(e => {
      const index = this.caseCostOriginal.findIndex(x => x.id == e.id);
      if (index > -1) {
        e.caseCost = this.caseCostOriginal[index].caseCost;
      }
    })
    this.updateOrderProcess();
    this.updateVars();

   this.orderItems = this.items.filter(item => item.cases);
    this.currentIndex = this.reportNames.findIndex(r => r.Name == report);
    if (this.reportNames[this.currentIndex].Name == 'Vendor Sales Order') {

      this.orderSelected.paymentTerms = this.vendorPaymentTerms;

      if (this.orderItems.length > 0) {
        if (this.orderSelected.orderStatus == 'Shipped' || this.orderSelected.orderStatus == 'Invoiced' || this.orderSelected.orderStatus == 'Closed') {
          this.orderItems = this.orderItems.filter(item => item.shipped);
        } 
        this.orderItems.forEach(element => {
          if (this.vendorPurchaseCaseCost) {
            element.caseCost = element.purchaseCaseCost;
          } else {
            element.caseCost = element.purchaseCaseCost - element.clearingCs - element.freightCs - element.duty - element.royalty;
          }
        });
      }
    }
    if (this.reportNames[this.currentIndex].Name == 'Invoice') {
      this.orderSelected.paymentTerms = 'Due on Receipt';
    }

    this.calculateTotals2();
    this.title = this.reportNames[this.currentIndex].Name;
    this.reportName = this.reportNames[this.currentIndex].Name;
    this.checkRef.detectChanges();
    var element = document.getElementById(this.reportNames[this.currentIndex].Id);
      var opt = {
        margin:       [this.reportNames[this.currentIndex].TopMargin, this.reportNames[this.currentIndex].LRMargin],
        filename:     this.orderSelected.salesOrderNo.trim() + ' ' + this.reportNames[this.currentIndex].filename + '.pdf',
        image:        { type: 'jpeg', quality: 0.98 },
        html2canvas:  { scale: 2 },
        jsPDF:        { unit: 'in', format: 'letter', orientation: this.reportNames[this.currentIndex].Orientation }
      };
      html2pdf().set(opt).from(element).save();
      this.totalCases = myTotalCases;
      this.totalPallets = myTotalPallets;
      this.totalGrossLbs = myTotalGrossLbs;
      this.totalCaseCost = myTotalCaseCost;
      this.totalGrosskg = mytotalGrosskg;
      this.items.forEach(e => {
        const index = this.caseCostOriginal.findIndex(x => x.id == e.id);
        if (index > -1) {
          e.caseCost = this.caseCostOriginal[index].caseCost;
          e.cases = this.caseCostOriginal[index].cases;
          e.extCaseCost = this.caseCostOriginal[index].extCaseCost;
        }
      })
      this.reportName = '';
      this.checkRef.detectChanges();
    }
  calculateTotals2() {
    this.totalCases = 0;
    this.totalPallets = this.order.palletCount === null ? 0: this.order.palletCount;
    this.totalGrossLbs = 0;
    this.totalCaseCost = 0;
    this.totalGrosskg = 0;
    this.orderItems.forEach(element => {
      let cases = element.cases;
      switch(this.reportNames[this.currentIndex].Name) {
        case 'Commercial Invoice':
          cases = element.shipped;
          element.cases = element.shipped;
          break;
        case 'Vendor Sales Order':
          if (this.orderSelected.orderStatus == 'Shipped' || this.orderSelected.orderStatus == 'Invoiced' || this.orderSelected.orderStatus == 'Closed') {
            cases = element.shipped;
            element.cases = element.shipped;
          }
          break;
        case 'Invoice':
          if (this.orderSelected.orderStatus == 'Shipped' || this.orderSelected.orderStatus == 'Invoiced' || this.orderSelected.orderStatus == 'Closed') {
            cases = element.shipped;
            element.cases = element.shipped;
          }
          break;
        case 'Shipping':
          cases = element.shipped;
          break;
      }

      if (cases === null) {
        cases = 0;
      }
      if (cases) {
          cases = cases * 1;
      }
      if (isNaN(cases)) {
        cases = 0;
      }    
      let caseCost = 0;
      let extCaseCost = 0;
      if (element.caseCost) {
        caseCost = element.caseCost * 1;
      }
      extCaseCost = cases * caseCost;
      element.extCaseCost = extCaseCost;
      this.totalCases += cases;
      if (element.csPerPallet != 0) {
        const bigNum: any = Big(cases)
        .div(element.csPerPallet)
        .round(0, 3);
        let pallets = bigNum * 1;
        if (pallets == 0 && cases !=0 ) {
          pallets = 1;
        }
//        this.totalPallets += pallets;
      }
      this.totalCaseCost += extCaseCost;
      this.totalGrossLbs += element.csGrossLbs * cases;
      this.totalGrosskg += element.csGrossKg * cases;
    });
    const palletLbs = this.order.palletGrossLbs === null ? 0 : this.order.palletGrossLbs;
    const bigNum: any = Big(palletLbs)
      .times(.453592)
      .round(2);
    const palletKg = bigNum * 1;
    this.totalGrossLbs += this.totalPallets * palletLbs;
    this.totalGrosskg += this.totalPallets * palletKg;

  }
  isEmpty(str) {
    return (!str || 0 === str.length);
  }
  updateVars() {
    let transIndex = this.transport.findIndex(trans => trans.id == this.order.transportCo1);
    this.transport1 = transIndex != -1 ? this.transport[transIndex].company : '';
    transIndex = this.transport.findIndex(trans => trans.id == this.order.transportCo2);
    this.transport2 = transIndex != -1 ? this.transport[transIndex].company : '';
    transIndex = this.transport.findIndex(trans => trans.id == this.order.transportCo3);
    this.transport3 = transIndex != -1 ? this.transport[transIndex].company : '';
    transIndex = this.transport.findIndex(trans => trans.id == this.order.transportCo4);
    this.transport4 = transIndex != -1 ? this.transport[transIndex].company : '';
    transIndex = this.transport.findIndex(trans => trans.id == this.order.transportCo5);
    this.transport5 = transIndex != -1 ? this.transport[transIndex].company : '';

    const index = this.freightType.findIndex(type => type.id == this.order.freightTypeId);
    this.freightTypeCompany = index != -1 ? this.freightType[index].freightType : '';
    
    this.orderSelected = new OrderPDF();
    this.orderSelected.id = this.order.id;
    this.orderSelected.orderStatus = this.order.orderStatus;
    this.orderSelected.orderType = this.order.orderType;
    this.orderSelected.salesOrderNo = this.order.salesOrderNo;
    this.orderSelected.purchaseOrder = this.order.purchaseOrder;
    this.orderSelected.salesOrderDate = this.order.salesOrderDate;
    this.orderSelected.shipmentDate = this.order.shipmentDate;
    this.orderSelected.deliveredDateAppt = this.order.deliveredDateAppt;
    this.orderSelected.invoiceNumber = this.order.invoiceNumber;
    if (this.order.billToId) {
      this.orderSelected.billToId = this.order.billToId;
      this.orderSelected.billTo = this.customer.company;
      this.orderSelected.billAddress = this.customer.address;
      this.orderSelected.billCSZ = this.getAddress2('customer');
    }

    if (this.order.shipToId) {
      this.orderSelected.shipToId = this.order.shipToId;
      this.orderSelected.shipTo = this.shipTo.company;
      this.orderSelected.shipToAddress = this.shipTo.address;
      this.orderSelected.shipToCSZ = this.getAddress2('shipto');
    }

    if (this.order.brokerId) {
      this.orderSelected.broker = this.broker.company;
    }

    if (this.order.vendorId) {
      this.orderSelected.vendorId = this.order.vendorId;
      this.orderSelected.vendor = this.vendor.company;
      this.orderSelected.vendorInfo = this.getVendor();
    }

    this.orderSelected.appConfirmed = this.order.appConfirmed;
    this.orderSelected.notes = this.order.notes;

    if (this.order.vendorPickupId) {
      this.orderSelected.pickCompany = this.pickup.company;
      this.orderSelected.pickAddress = this.pickup.address;
      this.orderSelected.pickCSZ = this.getAddress2('pickup');
    }
    
    if (this.order.termsId) {
      this.orderSelected.paymentTerms = this.term.paymentTerms;
    }

    this.orderSelected.freightType = this.freightTypeCompany;


    if (this.order.shippingLane1) {
      this.orderSelected.location1 = this.location1.location;
    }

    if (this.order.shippingLane2) {
      this.orderSelected.location2 = this.location2.location;
    }

    if (this.order.shippingLane3) {
      this.orderSelected.location3 = this.location3.location;
    }

    if (this.order.shippingLane4) {
      this.orderSelected.location4 = this.location4.location;
    }

    if (this.order.shippingLane5) {
      this.orderSelected.location5 = this.location5.location;
    }

    this.orderSelected.transCompany1 = this.transport1 ? this.transport1 : '';
    this.orderSelected.transCompany2 = this.transport2 ? this.transport2 : '';
    this.orderSelected.transCompany3 = this.transport3 ? this.transport3 : '';
    this.orderSelected.transCompany4 = this.transport4 ? this.transport4 : '';
    this.orderSelected.transCompany5 = this.transport3 ? this.transport5 : '';
   
    if (this.order.whsid) {
      this.orderSelected.whseCompany = this.warehouse.company;
      this.orderSelected.whseAddress = this.warehouse.address;
      this.orderSelected.whseEst = this.isEmpty(this.warehouse.federalEstablishment) ? '' : 'Est. ' + this.warehouse.federalEstablishment;
      this.orderSelected.whseCSZ = this.getAddress2('warehouse');
      // this.warehouseLine3 = whseEst ? whseEst : whseCSZ;
      // this.warehouseLine4 = whseEst ? whseCSZ : '';
    }

    if (this.order.shippingLaneEnd1) {
      this.orderSelected.locationEnd1 = this.locationEnd1.location;
    }

    if (this.order.shippingLaneEnd2) {
      this.orderSelected.locationEnd2 = this.locationEnd2.location;
    }

    if (this.order.shippingLaneEnd3) {
      this.orderSelected.locationEnd3 = this.locationEnd3.location;
    }

    if (this.order.shippingLaneEnd4) {
      this.orderSelected.locationEnd4 = this.locationEnd4.location;
    }

    if (this.order.shippingLaneEnd5) {
      this.orderSelected.locationEnd5 = this.locationEnd5.location;
    }
    this.orderSelected.shipDate1 = this.order.shipDate1;
    this.orderSelected.shipDate2 = this.order.shipDate2;
    this.orderSelected.shipDate3 = this.order.shipDate3;
    this.orderSelected.shipDate4 = this.order.shipDate4;
    this.orderSelected.shipDate5 = this.order.shipDate5;

    this.orderSelected.deliveryDate1 = this.order.deliveryDate1;
    this.orderSelected.deliveryDate2 = this.order.deliveryDate2;
    this.orderSelected.deliveryDate3 = this.order.deliveryDate3;
    this.orderSelected.deliveryDate4 = this.order.deliveryDate4;
    this.orderSelected.deliveryDate5 = this.order.deliveryDate5;
    this.orderSelected.palletCount = this.order.palletCount;
    this.orderSelected.palletGrossLbs = this.order.palletGrossLbs;

  }
  buttonEnabler() {
    if (!this.order.orderStatus) {
      return;
    }

    this.shipOrderBtn = true;
    this.salesOrderPrintBtn = true;
    this.slipPrintBtn = true;
    this.poPrintBtn = true;
    this.deleteOrderBtn = true;
    if (this.order.orderStatus == 'Sales Order') {
      return;
    }
    if (this.order.invoiceNumber) {
      this.invoicePrintBtn = true;
    }
    this.shipPrintBtn = true;
    this.deleteOrderBtn = false;
    this.invoiceOrderBtn = true;
  }
  orderEdit() {
    if (this.order.id) {
      this.router.navigate(['/sales/order-edit', this.order.id]);
    }
  }
  editInvoice() {
    if (this.order.id) {
      this.router.navigate(['/sales/invoicing-edit', this.order.id]);
    }
  }
  onDelete() {
    if (this.order.id) {
      if (confirm('Delete this item?'))
      this.orderService.remove(this.order.id).subscribe(data => {
        this.router.navigate(['/sales/order-list']);
      });
    }
  }
  showDialog() {  
    this.reportName='Confirm';
    this.checkRef.detectChanges();
  }  
  returnDialog(result){
    this.vendorPurchaseCaseCost = result == 1 ? true : false;
    this.fOBNetVendorCost = result == 2 ? true : false;
    this.printProcess("Vendor Sales Order"); 
  }
}
