<h5>{{ title }}</h5>
<form [formGroup]="billingAgentForm" (ngSubmit)="onSubmit()">
    <div class="form-group">
        <div class="col">
            <label>
                Billing Agent:
                <input type="text" formControlName="billingAgentName" class="form-control" size="60">
            </label>
        </div>

        <button type="submit" class="btn btn-primary mt-3 w-25">Save</button>
        <button type="button" class="btn btn-secondary ms-3 mt-3 w-25" (click)="onDelete()">Delete</button>

    </div>

    <ngb-alert *ngIf="successMessage" type="success" (close)="successMessage = null">{{ successMessage }}</ngb-alert>
    
</form>
