export class Customer {
  accountNo: string;
  activeItem: string;
  address: string;
  billing: string;
  bucketsId: number;
  businessPhone: string;
  city: string;
  clearing: string;
  comments: string;
  commission: string;
  company: string;
  countryRegion: string;
  currencyCode: string;
  eMail: string;
  exchange: string;
  faxNumber: string;
  freightCanada: string;
  freightUsaCanada: string;
  fullAddress: string;
  fullName: string;
  id: number;
  jobTitle: string;
  marketing: string;
  mobileNumber: string;
  orderType: string;
  paymentTermsTerms: string;
  stateProvince: string;
  storage: string;
  termsId: number;
  tollLocalCog: string;
  vendorId: number;
  webPage: string;
  zipPostalCode: string;
  latitude: number;
  longitude: number;
  accountTypeId: number;
  regionId: string;
  customerEndId: number;
}
