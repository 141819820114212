import { Component, Input, ViewChild, TemplateRef, Output, EventEmitter } from '@angular/core';  
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';  
@Component({  
    selector: 'app-confirm-dialog',  
    templateUrl: 'confirm-dialog.component.html',  
    styleUrls: ['confirm-dialog.component.css']  
})  
export class ConfirmDialogComponent {  
    message: any;  
    closeResult = '';
    btnClicked1 = false;
    btnClicked2 = false;
    @Input() vendorPurchaseCaseCost: boolean;
    @Input() fOBNetVendorCost: boolean;
    @Input() message1: string;
    @Output("returnDialog") returnDialog: EventEmitter<any> = new EventEmitter();

    @ViewChild('content', { static: true }) content: TemplateRef<any>;
    constructor(  
        private modalService: NgbModal
    ) { }  
  
    ngOnInit() {  
        this.message = "Click 'Purchase' for Vendor Purchase Case Cost or 'FOB Net' FOB Net Vendor Cost";
        this.open(this.content);
    }

    open(content) {
        this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
          this.btnClicked1 = result == 1 ? true : false;
          this.btnClicked2 = result == 2 ? true : false;
          this.closeResult = `Closed with: ${result}`;
          this.returnDialog.emit(result);
        }, (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
      }
    
      private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
          return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
          return 'by clicking on a backdrop';
        } else {
          return `with: ${reason}`;
        }
      }
  
}  