import { Injectable } from '@angular/core';
import { Big } from 'big.js';
import { Product } from '../models/product.model';
import { timestamp } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class UtilityService {
  productCalc = {
    csCubeImperial: ['csHInch', 'csLInch', 'csWInch', 'ALL'],
    csCubeMetric: ['csHInch', 'csLInch', 'csWInch', 'ALL'],
    csGrossKg: ['csGrossLbs', 'ALL'],
    csNetKg: ['pack', 'oz', 'ALL'],
    csHCm: ['csHInch', 'ALL'],
    csLCm: ['csLInch', 'ALL'],
    csWCm: ['csWInch', 'ALL'],
    csNetLbs: ['pack', 'oz', 'ALL'],
    gm: ['oz', 'ALL'],
    unitNet: ['pack', 'oz', 'ALL'],
    unitGross: ['pack', 'csGrossLbs', 'ALL'],
    unitCubeImp: ['unitHInch', 'unitLInch', 'unitWInch', 'ALL'],
    unitLCm: ['unitLInch', 'ALL'],
    unitWCm: ['unitWInch', 'ALL'],
    unitHCm: ['unitHInch', 'ALL'],
    unitCubeM: ['unitCubeImp', 'unitHInch', 'unitLInch', 'unitWInch', 'ALL'],
    whsUnit: ['pack', 'whsSelling', 'ALL'],
    csPerPallet: ['ti', 'hi', 'ALL'],
    csPerTruck: ['ti', 'hi', 'csPerPallet', 'palletPerTruck', 'ALL'],
    cndFrtCostCase: ['csPerPallet', 'cndFrtCostPallet', 'ALL'],
    storageCostCase: ['csPerPallet', 'storageCostPallet', 'ALL'],
    fobNetVendorUnit: ['pack', 'fobNetVendorCost', 'ALL'],
    blended: ['location1', 'location2', 'location3', 'location4', 'location5', 'location6', 'location7', 'location8', 'ALL'],
    blendedFreightCost: ['location1', 'location2', 'location3', 'location4',
                         'location5', 'location6', 'location7', 'location8',
                         'freightCost1', 'freightCost2', 'freightCost3', 'freightCost4',
                         'freightCost5', 'freightCost6', 'freightCost7', 'freightCost8',
                         'freightCostSales1', 'freightCostSales2', 'freightCostSales3', 'freightCostSales4',
                         'freightCostSales5', 'freightCostSales6', 'freightCostSales7', 'freightCostSales8',
                              'ALL'],
    marketing: ['whsSelling', 'marketing1', 'ALL'],
    billing: ['whsSelling', 'billing1', 'ALL'],
    terms: ['whsSelling', 'terms1', 'ALL'],
    commission: ['whsSelling', 'marketing', 'marketing1', 'billing', 'billing1', 'terms', 'terms1', 'commission1', 'commission2', 'ALL'],
    kalcor: ['whsSelling', 'kalcor1', 'ALL'],
    royalty: ['fobNetVendorCost', 'royalty1', 'ALL'],
    netCnd: ['whsSelling', 'marketing', 'marketing1', 'billing', 'billing1', 'terms', 'terms1', 'commission', 'commission1', 'commission2', 'kalcor', 'kalcor1', 'cndFrtCostCase',
             'storageCostCase', 'csPerPallet', 'cndFrtCostPallet', 'storageCostPallet', 'ALL'],
    netCsUsd: ['whsSelling', 'marketing', 'marketing1', 'billing', 'billing1', 'terms', 'terms1', 'commission', 'commission1', 'commission2', 'kalcor', 'kalcor1', 'cndFrtCostCase',
                'storageCostCase', 'csPerPallet', 'cndFrtCostPallet', 'storageCostPallet','netCnd', 'exchangeBudget', 'ALL'],
    clearingCs: ['csPerTruck', 'clearingCost', 'ti', 'hi', 'csPerPallet', 'palletPerTruck', 'ALL'],
    freightCs: ['location1', 'location2', 'location3', 'location4',
                'location5', 'location6', 'location7', 'location8',
                'freightCost1', 'freightCost2', 'freightCost3', 'freightCost4',
                'freightCost5', 'freightCost6', 'freightCost7', 'freightCost8',
                'freightCostSales1', 'freightCostSales2', 'freightCostSales3', 'freightCostSales4',
                'freightCostSales5', 'freightCostSales6', 'freightCostSales7', 'freightCostSales8',
            'blendedFreightCost', 'csPerTruck', 'ti', 'hi', 'csPerPallet', 'palletPerTruck', 'ALL'],
    fobNetReturn: ['location1', 'location2', 'location3', 'location4',
                    'location5', 'location6', 'location7', 'location8',
                    'freightCost1', 'freightCost2', 'freightCost3', 'freightCost4',
                    'freightCost5', 'freightCost6', 'freightCost7', 'freightCost8',
                    'freightCostSales1', 'freightCostSales2', 'freightCostSales3', 'freightCostSales4',
                    'freightCostSales5', 'freightCostSales6', 'freightCostSales7', 'freightCostSales8',
                    'blendedFreightCost', 'csPerTruck', 'ti', 'hi', 'csPerPallet', 'palletPerTruck','whsSelling', 'marketing', 'marketing1',
                    'billing', 'billing1', 'terms', 'terms1', 'commission', 'commission1', 'commission2', 'kalcor', 'kalcor1', 'cndFrtCostCase',
                    'storageCostCase', 'csPerPallet', 'cndFrtCostPallet', 'storageCostPallet','netCnd', 'exchangeBudget','netCsUsd', 'clearingCs', 'freightCs',
                    'csPerTruck', 'clearingCost', 'ti', 'hi', 'palletPerTruck', 'ALL'],
    fobDifference: ['location1', 'location2', 'location3', 'location4',
                    'location5', 'location6', 'location7', 'location8',
                    'freightCost1', 'freightCost2', 'freightCost3', 'freightCost4',
                    'freightCost5', 'freightCost6', 'freightCost7', 'freightCost8',
                    'freightCostSales1', 'freightCostSales2', 'freightCostSales3', 'freightCostSales4',
                    'freightCostSales5', 'freightCostSales6', 'freightCostSales7', 'freightCostSales8',
                    'blendedFreightCost', 'csPerTruck', 'ti', 'hi', 'csPerPallet', 'palletPerTruck','whsSelling', 'marketing', 'marketing1',
                    'billing', 'billing1', 'terms', 'terms1', 'commission', 'commission1', 'commission2', 'kalcor', 'kalcor1', 'cndFrtCostCase',
                    'storageCostCase', 'csPerPallet', 'cndFrtCostPallet', 'storageCostPallet','netCnd', 'exchangeBudget','netCsUsd', 'clearingCs', 'freightCs',
                    'csPerTruck', 'clearingCost', 'ti', 'hi', 'palletPerTruck','fobNetReturn', 'fobNetVendorCost', 'ALL'],
    vendorPurchaseCaseCost: ['pack','clearingCost', 'duty', 'royalty1', 'location1', 'location2', 'location3', 'location4',
                    'location5', 'location6', 'location7', 'location8',
                    'freightCost1', 'freightCost2', 'freightCost3', 'freightCost4',
                    'freightCost5', 'freightCost6', 'freightCost7', 'freightCost8',
                    'freightCostSales1', 'freightCostSales2', 'freightCostSales3', 'freightCostSales4',
                    'freightCostSales5', 'freightCostSales6', 'freightCostSales7', 'freightCostSalesSales8',
                    'blendedFreightCost', 'csPerTruck', 'ti', 'hi', 'csPerPallet', 'palletPerTruck', 'ALL'],
    truckCost1: ['freightCost1','freightCostSales1', 'ALL'],
    truckCost2: ['freightCost2','freightCostSales2', 'ALL'],
    truckCost3: ['freightCost3','freightCostSales3', 'ALL'],
    truckCost4: ['freightCost4','freightCostSales4', 'ALL'],
    truckCost5: ['freightCost5','freightCostSales5', 'ALL'],
    truckCost6: ['freightCost6','freightCostSales6', 'ALL'],
    truckCost7: ['freightCost7','freightCostSales7', 'ALL'],
    truckCost8: ['freightCost8','freightCostSales8', 'ALL'],
    cndFrtCostLb: ['csGrossLbs', 'cndFrtCostCase', 'ALL' ]
  };
  constructor() { }

  makeNum(item, precision) {
    precision = typeof (precision) !== 'number' ? 0 : precision;

    let ret = 0;
    const whatType = typeof (item);
    if (whatType === 'number') {
      ret = item;
    } else {
      if (whatType === 'string') {
        let i: any;
        if (precision === 0) {
          i = parseInt(item, 10);
        } else {
          i = parseFloat(item);
        }
        if (!isNaN(i)) {
          ret = i;
        }
      }
    }
    return ret;
  }
  nullCheck(item) {
    const type = typeof item;
    if (type === 'number') {
      return item;
    }
    let ret = 0;
    if (type === 'string') {
      ret = item * 1;
    }
    if (isNaN(ret)) {
      ret = 0;
    }
    return ret;
  }
  productCalculations(item, product) {
    let ret = new Product();

    if (this.calcItem(item, 'truckCost1')) {
      // total cost [Cost + (Cost x FCS%) = Total Cost] 
      product.freightCostSales1 = this.nullCheck(product.freightCostSales1);
      product.freightCost1 = this.nullCheck(product.freightCost1);
      const bigNum: any = Big(product.freightCostSales1)
        .times(.01)
        .times(product.freightCost1)
        .plus(product.freightCost1)
        .round(2);
      if (bigNum * 1 !== product.truckCost1) {
        ret.truckCost1 = bigNum * 1;
        product.truckCost1 = ret.truckCost1;
      }
    }

    if (this.calcItem(item, 'truckCost2')) {
      // total cost [Cost + (Cost x FCS%) = Total Cost] 
      product.freightCostSales2 = this.nullCheck(product.freightCostSales2);
      product.freightCost2 = this.nullCheck(product.freightCost2);
      const bigNum: any = Big(product.freightCostSales2)
        .times(.01)
        .times(product.freightCost2)
        .plus(product.freightCost2)
        .round(2);
      if (bigNum * 1 !== product.truckCost2) {
        ret.truckCost2 = bigNum * 1;
        product.truckCost2 = ret.truckCost2;
      }
    }

    if (this.calcItem(item, 'truckCost3')) {
      // total cost [Cost + (Cost x FCS%) = Total Cost] 
      product.freightCostSales3 = this.nullCheck(product.freightCostSales3);
      product.freightCost3 = this.nullCheck(product.freightCost3);
      const bigNum: any = Big(product.freightCostSales3)
        .times(.01)
        .times(product.freightCost3)
        .plus(product.freightCost3)
        .round(2);
      if (bigNum * 1 !== product.truckCost3) {
        ret.truckCost3 = bigNum * 1;
        product.truckCost3 = ret.truckCost3;
      }
    }

    if (this.calcItem(item, 'truckCost4')) {
      // total cost [Cost + (Cost x FCS%) = Total Cost] 
      product.freightCostSales4 = this.nullCheck(product.freightCostSales4);
      product.freightCost4 = this.nullCheck(product.freightCost4);
      const bigNum: any = Big(product.freightCostSales4)
        .times(.01)
        .times(product.freightCost4)
        .plus(product.freightCost4)
        .round(2);
      if (bigNum * 1 !== product.truckCost4) {
        ret.truckCost4 = bigNum * 1;
        product.truckCost4 = ret.truckCost4;
      }
    }

    if (this.calcItem(item, 'truckCost5')) {
      // total cost [Cost + (Cost x FCS%) = Total Cost] 
      product.freightCostSales5 = this.nullCheck(product.freightCostSales5);
      product.freightCost5 = this.nullCheck(product.freightCost5);
      const bigNum: any = Big(product.freightCostSales5)
        .times(.01)
        .times(product.freightCost5)
        .plus(product.freightCost5)
        .round(2);
      if (bigNum * 1 !== product.truckCost5) {
        ret.truckCost5 = bigNum * 1;
        product.truckCost5 = ret.truckCost5;
      }
    }

    if (this.calcItem(item, 'truckCost6')) {
      // total cost [Cost + (Cost x FCS%) = Total Cost] 
      product.freightCostSales6 = this.nullCheck(product.freightCostSales6);
      product.freightCost6 = this.nullCheck(product.freightCost6);
      const bigNum: any = Big(product.freightCostSales6)
        .times(.01)
        .times(product.freightCost6)
        .plus(product.freightCost6)
        .round(2);
      if (bigNum * 1 !== product.truckCost6) {
        ret.truckCost6 = bigNum * 1;
        product.truckCost6 = ret.truckCost6;
      }
    }

    if (this.calcItem(item, 'truckCost7')) {
      // total cost [Cost + (Cost x FCS%) = Total Cost] 
      product.freightCostSales7 = this.nullCheck(product.freightCostSales7);
      product.freightCost7 = this.nullCheck(product.freightCost7);
      const bigNum: any = Big(product.freightCostSales7)
        .times(.01)
        .times(product.freightCost7)
        .plus(product.freightCost7)
        .round(2);
      if (bigNum * 1 !== product.truckCost7) {
        ret.truckCost7 = bigNum * 1;
        product.truckCost7 = ret.truckCost7;
      }
    }

    if (this.calcItem(item, 'truckCost8')) {
      // total cost [Cost + (Cost x FCS%) = Total Cost] 
      product.freightCostSales8 = this.nullCheck(product.freightCostSales8);
      product.freightCost8 = this.nullCheck(product.freightCost8);
      const bigNum: any = Big(product.freightCostSales8)
        .times(.01)
        .times(product.freightCost8)
        .plus(product.freightCost8)
        .round(2);
      if (bigNum * 1 !== product.truckCost8) {
        ret.truckCost8 = bigNum * 1;
        product.truckCost8 = ret.truckCost8;
      }
    }

    if (this.calcItem(item, 'csNetLbs')) {
      // Pack*Oz/16       0.00  2x"0"
      product.oz = this.nullCheck(product.oz);
      product.pack = this.nullCheck(product.pack);
      const bigNum: any = Big(product.oz)
        .times(product.pack)
        .div(16)
        .round(2);
      if (bigNum * 1 !== product.csNetLbs) {
        ret.csNetLbs = bigNum * 1;
        product.csNetLbs = ret.csNetLbs;
      }
    }

    if (this.calcItem(item, 'csNetKg')) {
      // [CS Net Lbs]*0.45359237       0.00  2x"0"
      product.csNetLbs = this.nullCheck(product.csNetLbs);
      const bigNum: any = Big(product.csNetLbs)
        .times(0.45359237)
        .round(2);
      if (bigNum * 1 !== product.csNetKg) {
        ret.csNetKg = bigNum * 1;
      }
    }

    if (this.calcItem(item, 'csGrossKg')) {
      // [CS Gross Lbs]*0.45359237
      product.csGrossLbs = this.nullCheck(product.csGrossLbs);
      const bigNum: any = Big(product.csGrossLbs)
        .times(0.45359237)
        .round(2);
      if (bigNum * 1 !== product.csGrossKg) {
        ret.csGrossKg = bigNum * 1;
      }
    }

    if (this.calcItem(item, 'csCubeImperial')) {
      // ([CS H Inch]*[CS L Inch]*[CS W Inch])/(1728)
      product.csHInch = this.nullCheck(product.csHInch);
      product.csLInch = this.nullCheck(product.csLInch);
      product.csWInch = this.nullCheck(product.csWInch);
      const bigNum: any = Big(product.csHInch)
        .times(product.csLInch)
        .times(product.csWInch)
        .div(1728)
        .round(2);
      if (bigNum * 1 !== product.csCubeImperial) {
        ret.csCubeImperial = bigNum * 1;
        product.csCubeImperial = ret.csCubeImperial;
      }
    }

    if (this.calcItem(item, 'csLCm')) {
      // [CS L Inch]*2.54        0.00  2x"0"
      product.csLInch = this.nullCheck(product.csLInch);
      const bigNum: any = Big(product.csLInch)
        .times(2.54)
        .round(2);
      if (bigNum * 1 !== product.csLCm) {
        ret.csLCm = bigNum * 1;
      }
    }

    if (this.calcItem(item, 'csHCm')) {
      // [CS H Inch]*2.54        0.00  2x"0"
      product.csHInch = this.nullCheck(product.csHInch);
      const bigNum: any = Big(product.csHInch)
        .times(2.54)
        .round(2);
      if (bigNum * 1 !== product.csHCm) {
        ret.csHCm = bigNum * 1;
      }
    }

    if (this.calcItem(item, 'csWCm')) {
      // [CS W Inch]*2.54        0.00  2x"0"
      product.csWInch = this.nullCheck(product.csWInch);
      const bigNum: any = Big(product.csWInch)
        .times(2.54)
        .round(2);
      if (bigNum * 1 !== product.csWCm) {
        ret.csWCm = bigNum * 1;
      }
    }

    if (this.calcItem(item, 'csCubeMetric')) {
      // [CS Cube Imperial]*0.0283168
      product.csCubeImperial = this.nullCheck(product.csCubeImperial);
      const bigNum: any = Big(product.csCubeImperial)
        .times(0.0283168)
        .round(2);
      if (bigNum * 1 !== product.csCubeMetric) {
        ret.csCubeMetric = bigNum * 1;
      }
  }

    if (this.calcItem(item, 'unitNet')) {
      // [CS Net Lbs]/Pack        0.000  3x"0"
      product.pack = this.nullCheck(product.pack);
      if (product.pack === 0) {
        if (product.unitNet !== 0) {
          ret.unitNet = 0;
        }
      } else {
        product.csNetLbs = this.nullCheck(product.csNetLbs);
        const bigNum: any = Big(product.csNetLbs)
          .div(product.pack)
          .round(3);
        if (bigNum * 1 !== product.unitNet) {
          ret.unitNet = bigNum * 1;
        }
      }
    }

    if (this.calcItem(item, 'unitGross')) {
      // [CS Gross Lbs]/Pack        0.000  3x"0"
      product.pack = this.nullCheck(product.pack);
      if (product.pack === 0) {
        if (product.unitGross !== 0) {
          ret.unitGross = 0;
        }
      } else {
        product.csGrossLbs = this.nullCheck(product.csGrossLbs);
        const bigNum: any = Big(product.csGrossLbs)
        .div(product.pack)
        .round(3);
        if (bigNum * 1 !== product.unitGross) {
          ret.unitGross = bigNum * 1;
        }
      }
    }

    if (this.calcItem(item, 'unitCubeImp')) {
      // ([Unit H Inch]*[Unit L Inch]*[Unit W Inch])/(1728)    0.00000  5x"0"
      product.unitHInch = this.nullCheck(product.unitHInch);
      product.unitLInch = this.nullCheck(product.unitLInch);
      product.unitWInch = this.nullCheck(product.unitWInch);

      const bigNum: any = Big(product.unitHInch)
        .times(product.unitLInch)
        .times(product.unitWInch)
        .div(1728)
        .round(5);
      if (bigNum * 1 !== product.unitCubeImp) {
        ret.unitCubeImp = bigNum * 1;
        product.unitCubeImp = ret.unitCubeImp;
      }
    }

    if (this.calcItem(item, 'unitLCm')) {
      // [Unit L Inch]*2.54     0.00  2x"0"
      product.unitLInch = this.nullCheck(product.unitLInch);
      const bigNum: any = Big(product.unitLInch)
        .times(2.54)
        .round(2);
      if (bigNum * 1 !== product.unitLCm) {
        ret.unitLCm = bigNum * 1;
      }
    }

    if (this.calcItem(item, 'unitWCm')) {
      // [Unit W Inch]*2.54     0.00  2x"0"
      product.unitWInch = this.nullCheck(product.unitWInch);
      const bigNum: any = Big(product.unitWInch)
        .times(2.54)
        .round(2);
      if (bigNum * 1 !== product.unitWCm) {
        ret.unitWCm = bigNum * 1;
      }
    }

    if (this.calcItem(item, 'unitHCm')) {
      // [Unit H Inch]*2.54     0.00  2x"0"
      product.unitHInch = this.nullCheck(product.unitHInch);
      const bigNum: any = Big(product.unitHInch)
        .times(2.54)
        .round(2);
      if (bigNum * 1 !== product.unitHCm) {
        ret.unitHCm = bigNum * 1;
      }
    }

    if (this.calcItem(item, 'unitCubeM')) {
      // [Unit Cube Imp]*0.0283168    0.00000  5x"0"
      product.unitCubeImp = this.nullCheck(product.unitCubeImp);
      const bigNum: any = Big(product.unitCubeImp)
        .times(0.0283168)
        .round(5);
      if (bigNum * 1 !== product.unitCubeM) {
        ret.unitCubeM = bigNum * 1;
      }

    }

    if (this.calcItem(item, 'whsUnit')) {
      // [WHS Selling]/Pack           $0.00  2x"0"
      product.pack = this.nullCheck(product.pack);
      if (product.pack === 0) {
        if (product.whsUnit !== 0) {
          ret.whsUnit = 0;
        }
      } else {
        product.whsSelling = this.nullCheck(product.whsSelling);
        const bigNum: any = Big(product.whsSelling)
        .div(product.pack)
          .round(2);
        if (bigNum * 1 !== product.whsUnit) {
          ret.whsUnit = bigNum * 1;
        }
      }
    }

    if (this.calcItem(item, 'cndFrtCostCase')) {
      // [CND FRT Cost/Pallet]/[CS Per Pallet]       $0.00  2x"0"
      product.csPerPallet = this.nullCheck(product.csPerPallet);
      if (product.csPerPallet === 0) {
        if (product.cndFrtCostCase !== 0) {
          ret.cndFrtCostCase = 0;
          product.cndFrtCostCase = ret.cndFrtCostCase;
        }
      } else {
        product.cndFrtCostPallet = this.nullCheck(product.cndFrtCostPallet);
        const bigNum: any = Big(product.cndFrtCostPallet)
        .div(product.csPerPallet)
        .round(2);
        if (bigNum * 1 !== product.cndFrtCostCase) {
          ret.cndFrtCostCase = bigNum * 1;
          product.cndFrtCostCase = ret.cndFrtCostCase;
        }
      }
    }

    if (this.calcItem(item, 'cndFrtCostLb')) {
      // cndFrtCostCase / csGrossLbs
      product.csGrossLbs = this.nullCheck(product.csGrossLbs);
      if (product.csGrossLbs === 0) {
          ret.cndFrtCostLb = 0;
          product.cndFrtCostLb = ret.cndFrtCostLb;
      } else {
        product.cndFrtCostCase = this.nullCheck(product.cndFrtCostCase);
        const bigNum: any = Big(product.cndFrtCostCase)
        .div(product.csGrossLbs)
        .round(2);
        if (bigNum * 1 !== product.cndFrtCostLb) {
          ret.cndFrtCostLb = bigNum * 1;
          product.cndFrtCostLb = ret.cndFrtCostLb;
        }
      }
    }

    if (this.calcItem(item, 'storageCostCase')) {
      // [Storage Case/Pallet]/[CS Per Pallet]       $0.00  2x"0"
      product.csPerPallet = this.nullCheck(product.csPerPallet);
      if (product.csPerPallet === 0) {
        if (product.storageCostCase !== 0) {
          ret.storageCostCase = 0;
          product.storageCostCase = ret.storageCostCase;
        }
      } else {
        product.storageCostPallet = this.nullCheck(product.storageCostPallet);
        const bigNum: any = Big(product.storageCostPallet)
        .div(product.csPerPallet)
        .round(2);
        if (bigNum * 1 !== product.storageCostCase) {
          ret.storageCostCase = bigNum * 1;
          product.storageCostCase = ret.storageCostCase;
        }
      }
    }

    if (this.calcItem(item, 'gm')) {
      // Oz*28.3495        0.00  2x"0"
      product.oz = this.nullCheck(product.oz);
      const bigNum: any = Big(product.oz)
        .times(28.3495)
        .round(2);
      if (bigNum * 1 !== product.gm) {
        ret.gm = bigNum * 1;
      }
    }

    if (this.calcItem(item, 'fobNetVendorUnit')) {
      // [FOB Net Vendor Cost]/Pack    $0.00  2x"0"
      product.pack = this.nullCheck(product.pack);
      if (product.pack === 0) {
        if (product.fobNetVendorUnit !== 0) {
          ret.fobNetVendorUnit = 0;
        }
      } else {
        product.fobNetVendorCost = this.nullCheck(product.fobNetVendorCost);
        const bigNum: any = Big(product.fobNetVendorCost)
          .div(product.pack)
          .round(2);
        if (bigNum * 1 !== product.fobNetVendorUnit) {
          ret.fobNetVendorUnit = bigNum * 1;
        }
      }
    }

    if (this.calcItem(item, 'csPerPallet')) {
      // TI*HI        0  0x"0"
      product.ti = this.nullCheck(product.ti);
      product.hi = this.nullCheck(product.hi);
      const bigNum: any = Big(product.ti)
        .times(product.hi)
        .round(0);
      if (bigNum * 1 !== product.csPerPallet) {
        ret.csPerPallet = bigNum * 1;
        product.csPerPallet = ret.csPerPallet;
      }
    }

    if (this.calcItem(item, 'csPerTruck')) {
      // [CS Per Pallet]*[Pallet Per Truck]
      product.csPerPallet = this.nullCheck(product.csPerPallet);
      product.palletPerTruck = this.nullCheck(product.palletPerTruck);
      const bigNum: any = Big(product.csPerPallet)
        .times(product.palletPerTruck)
        .round(0);
      if (bigNum * 1 !== product.csPerTruck) {
        ret.csPerTruck = bigNum * 1;
        product.csPerTruck = ret.csPerTruck;
      }
    }

    if (this.calcItem(item, 'blended')) {
    // [Location 1 %]+[Location 2 %]+[Location 3 %]+[Location 4 %]+[Location 5 %]+[Location 6 %]+[Location 7 %]+[Location 8 %]
      product.location1 = this.nullCheck(product.location1);
      product.location2 = this.nullCheck(product.location2);
      product.location3 = this.nullCheck(product.location3);
      product.location4 = this.nullCheck(product.location4);
      product.location5 = this.nullCheck(product.location5);
      product.location6 = this.nullCheck(product.location6);
      product.location7 = this.nullCheck(product.location7);
      product.location8 = this.nullCheck(product.location8);
      const bigNum: any = Big(product.location1)
        .plus(product.location2)
        .plus(product.location3)
        .plus(product.location4)
        .plus(product.location5)
        .plus(product.location6)
        .plus(product.location7)
        .plus(product.location8)
        .round(0);
      if (bigNum * 1 !== product.blended) {
        ret.blended = bigNum * 1;
      }
    }

    if (this.calcItem(item, 'blendedFreightCost')) {
    // ([Truck Cost 1]*[Location 1 %])+([Truck Cost 2]*[Location 2 %])+([Truck Cost 3]*[Location 3 %])+([Truck Cost 4]*[Location 4 %])+([Truck Cost 5]*[Location 5 %])+([Truck Cost 6]*[Location 6 %])+([Truck Cost 7]*[Location 7 %])+([Truck Cost 8]*[Location 8 %]))
      product.location1 = this.nullCheck(product.location1);
      product.location2 = this.nullCheck(product.location2);
      product.location3 = this.nullCheck(product.location3);
      product.location4 = this.nullCheck(product.location4);
      product.location5 = this.nullCheck(product.location5);
      product.location6 = this.nullCheck(product.location6);
      product.location7 = this.nullCheck(product.location7);
      product.location8 = this.nullCheck(product.location8);
      product.truckCost1 = this.nullCheck(product.truckCost1);
      product.truckCost2 = this.nullCheck(product.truckCost2);
      product.truckCost3 = this.nullCheck(product.truckCost3);
      product.truckCost4 = this.nullCheck(product.truckCost4);
      product.truckCost5 = this.nullCheck(product.truckCost5);
      product.truckCost6 = this.nullCheck(product.truckCost6);
      product.truckCost7 = this.nullCheck(product.truckCost7);
      product.truckCost8 = this.nullCheck(product.truckCost8);
      const bigNum1: any = Big(product.truckCost1)
        .times(product.location1)
        .times(.01)
      const bigNum2: any = Big(product.truckCost2)
        .times(product.location2)
        .times(.01)
      const bigNum3: any = Big(product.truckCost3)
        .times(product.location3)
        .times(.01)
      const bigNum4: any = Big(product.truckCost4)
        .times(product.location4)
        .times(.01)
      const bigNum5: any = Big(product.truckCost5)
        .times(product.location5)
        .times(.01)
      const bigNum6: any = Big(product.truckCost6)
        .times(product.location6)
        .times(.01)
      const bigNum7: any = Big(product.truckCost7)
        .times(product.location7)
        .times(.01)
      const bigNum8: any = Big(product.truckCost8)
        .times(product.location8)
        .times(.01)
      const bigNumTotal: any = bigNum1
        .plus(bigNum2)
        .plus(bigNum3)
        .plus(bigNum4)
        .plus(bigNum5)
        .plus(bigNum6)
        .plus(bigNum7)
        .plus(bigNum8)
        .round(2);
      if (bigNumTotal * 1 !== product.blendedFreightCost) {
        ret.blendedFreightCost = bigNumTotal * 1;
        product.blendedFreightCost = ret.blendedFreightCost;
      }
    }
    
    if (this.calcItem(item, 'marketing')) {
      // [WHS Selling]*[Marketing %]        $0.00  2x"0"
      product.marketing1 = this.nullCheck(product.marketing1);
      product.whsSelling = this.nullCheck(product.whsSelling);
      const bigNum: any = Big(product.whsSelling)
          .times(product.marketing1)
          .times(.01)
          .round(2);
      if (bigNum * 1 !== product.marketing) {
        ret.marketing = bigNum * 1;
        product.marketing = ret.marketing;
      }
    }

    if (this.calcItem(item, 'billing')) {
      // [WHS Selling]*[Billing %]        $0.00  2x"0"
      product.billing1 = this.nullCheck(product.billing1);
      product.whsSelling = this.nullCheck(product.whsSelling);
      const bigNum: any = Big(product.whsSelling)
          .times(product.billing1)
          .times(.01)
          .round(2);
      if (bigNum * 1 !== product.billing) {
        ret.billing = bigNum * 1;
        product.billing = ret.billing;
      }
    }

    if (this.calcItem(item, 'terms')) {
      // [WHS Selling]*[Terms %]        $0.00  2x"0"
      product.terms1 = this.nullCheck(product.terms1);
      product.whsSelling = this.nullCheck(product.whsSelling);
      const bigNum: any = Big(product.whsSelling)
          .times(product.terms1)
          .times(.01)
          .round(2);
      if (bigNum * 1 !== product.terms) {
        ret.terms = bigNum * 1;
        product.terms = ret.terms;
      }
    }

    if (this.calcItem(item, 'commission')) {
      // ([WHS Selling]-Marketing-Billing-Terms)*([Commission %])       $0.00  2x"0"
      // as of 10/17/2019 ([WHS Selling])*([Commission %])       $0.00  2x"0"
      // product.terms = this.nullCheck(product.terms);
      // product.billing = this.nullCheck(product.billing);
      // product.marketing = this.nullCheck(product.marketing);
      product.whsSelling = this.nullCheck(product.whsSelling);
      product.commission1 = this.nullCheck(product.commission1);
      product.commission2 = this.nullCheck(product.commission2);
      const totalCommission = product.commission1 + product.commission2;
      // const bigNum: any = Big(product.whsSelling)
      //     .minus(product.marketing)
      //     .minus(product.billing)
      //     .minus(product.terms)
      //     .times(totalCommission)
      //     .times(.01)
      //     .round(2);
      const bigNum: any = Big(product.whsSelling)
          .times(totalCommission)
          .times(.01)
          .round(2);
      if (bigNum * 1 !== product.commission) {
        ret.commission = bigNum * 1;
        product.commission = ret.commission;
      }
    }

    if (this.calcItem(item, 'kalcor')) {
      // [WHS Selling]*[Kalcor %]        $0.00  2x"0"
      product.kalcor1 = this.nullCheck(product.kalcor1);
      product.whsSelling = this.nullCheck(product.whsSelling);
      const bigNum: any = Big(product.whsSelling)
          .times(product.kalcor1)
          .times(.01)
          .round(2);
      if (bigNum * 1 !== product.kalcor) {
        ret.kalcor = bigNum * 1;
        product.kalcor = ret.kalcor;
      }
    }

    if (this.calcItem(item, 'netCnd')) {
      // [WHS Selling]-Marketing-Billing-Terms-Commission-Kalcor-[CND FRT Cost/Case]-[Storage Cost/Case]     $0.00  2x"0"
      product.whsSelling = this.nullCheck(product.whsSelling);
      product.marketing = this.nullCheck(product.marketing);
      product.billing = this.nullCheck(product.billing);
      product.terms = this.nullCheck(product.terms);
      product.commission = this.nullCheck(product.commission);
      product.kalcor = this.nullCheck(product.kalcor);
      product.cndFrtCostCase = this.nullCheck(product.cndFrtCostCase);
      product.storageCostCase = this.nullCheck(product.storageCostCase);
      const bigNum: any = Big(product.whsSelling)
          .minus(product.marketing)
          .minus(product.billing)
          .minus(product.terms)
          .minus(product.commission)
          .minus(product.kalcor)
          .minus(product.cndFrtCostCase)
          .minus(product.storageCostCase)
          .round(2);
      if (bigNum * 1 !== product.netCnd) {
        ret.netCnd = bigNum * 1;
        product.netCnd = ret.netCnd;
      }
    }

    if (this.calcItem(item, 'netCsUsd')) {
      // [Net CND]*[Exchange Budget]      $0.00  2x"0"
      product.netCnd = this.nullCheck(product.netCnd);
      product.exchangeBudget = this.nullCheck(product.exchangeBudget);
      const bigNum: any = Big(product.netCnd)
          .times(product.exchangeBudget)
          .round(2);
      if (bigNum * 1 !== product.netCsUsd) {
        ret.netCsUsd = bigNum * 1;
        product.netCsUsd = ret.netCsUsd;
      }
    }

    if (this.calcItem(item, 'clearingCs')) {
      // [Clearing Cost]/[CS per Truck]    $0.00  2x"0"
      product.csPerTruck = this.nullCheck(product.csPerTruck);
      if (product.csPerTruck === 0) {
        if (product.clearingCs !== 0) {
          ret.clearingCs = 0;
          product.clearingCs = ret.clearingCs;
        }
      } else {
        product.clearingCost = this.nullCheck(product.clearingCost);
        const bigNum: any = Big(product.clearingCost)
          .div(product.csPerTruck)
          .round(2);
        if (bigNum * 1 !== product.clearingCs) {
          ret.clearingCs = bigNum * 1;
          product.clearingCs = ret.clearingCs;
        }
      }
    }  

    if (this.calcItem(item, 'freightCs')) {
      // [Blended Freight Cost]/[CS per Truck]    $0.00  2x"0"
      product.blendedFreightCost = this.nullCheck(product.blendedFreightCost);
      if (product.blendedFreightCost === 0) {
        if (product.freightCs !== 0) {
          ret.freightCs = 0;
          product.freightCs = ret.freightCs;
        }
      } else {
        product.csPerTruck = this.nullCheck(product.csPerTruck);
        const bigNum: any = Big(product.blendedFreightCost)
          .div(product.csPerTruck)
          .round(2);
        if (bigNum * 1 !== product.freightCs) {
          ret.freightCs = bigNum * 1;
          product.freightCs = ret.freightCs;
        }
      }
    }      
 
    if (this.calcItem(item, 'royalty')) {
      // [FOB Net Vendor Cost * Royality %]        $0.00  2x"0"
      product.royalty1 = this.nullCheck(product.royalty1);
      product.fobNetVendorCost = this.nullCheck(product.fobNetVendorCost);
      const bigNum: any = Big(product.fobNetVendorCost)
          .times(product.royalty1)
          .times(.01)
          .round(2);
      if (bigNum * 1 !== product.royalty) {
        ret.royalty = bigNum * 1;
        product.royalty = ret.royalty;
      }
    }

    // if (this.calcItem(item, 'fobNetReturn')) {
    //   // [Net CS USD]-[Clearing $ CS]-[Freight $ CS]-Duty      $0.00  2x"0"
    //   product.netCsUsd = this.nullCheck(product.netCsUsd);
    //   product.clearingCs = this.nullCheck(product.clearingCs);
    //   product.freightCs = this.nullCheck(product.freightCs);
    //   product.duty = this.nullCheck(product.duty);
    //   const bigNum: any = Big(product.netCsUsd)
    //       .minus(product.clearingCs)
    //       .minus(product.freightCs)
    //       .minus(product.duty)
    //       .round(2);
    //   if (bigNum * 1 !== product.fobNetReturn) {
    //     ret.fobNetReturn = bigNum * 1;
    //     product.fobNetReturn = ret.fobNetReturn;
    //   }
    // }   

    if (this.calcItem(item, 'vendorPurchaseCaseCost')) {
      // FOB Net Vendor Cost: + Freight $ CS: + Duty: + Clearing $ CS + Royalty:
      product.fobNetVendorCost = this.nullCheck(product.fobNetVendorCost);
      product.freightCs = this.nullCheck(product.freightCs);
      product.duty = this.nullCheck(product.duty);
      product.clearingCs = this.nullCheck(product.clearingCs);
      product.royalty = this.nullCheck(product.royalty);
      const bigNum: any = Big(product.fobNetVendorCost)
          .plus(product.freightCs)
          .plus(product.duty)
          .plus(product.clearingCs)
          .plus(product.royalty)
          .round(2);
      if (bigNum * 1 !== product.vendorPurchaseCaseCost) {
        ret.vendorPurchaseCaseCost = bigNum * 1;
        product.vendorPurchaseCaseCost = ret.vendorPurchaseCaseCost;
      }

      // vendorPurchaseCaseCost /Pack        0.000  3x"0"
      product.pack = this.nullCheck(product.pack);
      if (product.pack === 0) {
        if (product.vendorPurchaseCaseCostUnit !== 0) {
          ret.vendorPurchaseCaseCostUnit = 0;
        }
      } else {
        product.vendorPurchaseCaseCost = this.nullCheck(product.vendorPurchaseCaseCost);
        const bigNum: any = Big(product.vendorPurchaseCaseCost)
          .div(product.pack)
          .round(2);
        if (bigNum * 1 !== product.vendorPurchaseCaseCostUnit) {
          ret.vendorPurchaseCaseCostUnit = bigNum * 1;
        }
      }
    }   
    if (this.calcItem(item, 'fobNetReturn')) {
      // Vendor Purchase Case Cost / Exchange Budget:    $0.00  2x"0"
      if (product.exchangeBudget === 0) {
        if (product.fobNetReturn!== 0) {
          ret.fobNetReturn = 0;
        }
      } else {
        product.vendorPurchaseCaseCost = this.nullCheck(product.vendorPurchaseCaseCost);
        product.exchangeBudget = this.nullCheck(product.exchangeBudget);
        const bigNum: any = Big(product.vendorPurchaseCaseCost)
            .div(product.exchangeBudget)
            .round(2);
        if (bigNum * 1 !== product.fobNetReturn) {
          ret.fobNetReturn = bigNum * 1;
          product.fobNetReturn = ret.fobNetReturn;
        }
      }
    }   

    if (this.calcItem(item, 'fobDifference')) {
      // [FOB Net Return]-[FOB Net Vendor Cost]     $0.00  2x"0"
      // This is a NEW CACALCULATIONS as of 12/11/19. Net CND: (-) FOB Net Return: 
      product.fobNetReturn = this.nullCheck(product.fobNetReturn);
      product.netCnd = this.nullCheck(product.netCnd);
      const bigNum: any = Big(product.netCnd)
          .minus(product.fobNetReturn)
          .round(2);
      if (bigNum * 1 !== product.fobDifference) {
        ret.fobDifference = bigNum * 1;
      }
    }   

    return ret;
  }

  
  calcItem(item, objKey ) {
    if (this.productCalc.hasOwnProperty(objKey)) {
      if (this.productCalc[objKey].indexOf(item) > -1) {
        return true;
      }
    }
    return false;
  }
}
