import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/internal/operators';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import { OrderSP } from '../models/order-model'
import { environment } from '../../environments/environment';
//const GRAPH_ENDPOINT_LIST = 'https://graph.microsoft.com/v1.0/sites/2030cae4-3246-402f-a9f8-8246db7304c1/lists/adbbf64e-e6ed-427f-a4f4-75564a2892ba/items/?$expand=fields';
const GRAPH_ENDPOINT = 'https://graph.microsoft.com/v1.0/sites/2030cae4-3246-402f-a9f8-8246db7304c1/lists';
const GRAPH_ENDPOINT_LIST = 'https://graph.microsoft.com/v1.0/sites/2030cae4-3246-402f-a9f8-8246db7304c1/lists?$select=id,name';
const GRAPH_ENDPOINT_LIST_ID = 'https://graph.microsoft.com/v1.0/sites/2030cae4-3246-402f-a9f8-8246db7304c1/lists/adbbf64e-e6ed-427f-a4f4-75564a2892ba/items/?$select=id'
const url = environment.kalcorApiUrl;

@Injectable({
  providedIn: 'root'
})
export class RestService {

  constructor(private http: HttpClient) { }
  getLists(): Observable<any> {
    return this.http.get(GRAPH_ENDPOINT + '?$select=id,name');
  }
  getListColumn(listId): Observable<any> {
    return this.http.get(GRAPH_ENDPOINT + '/' + listId + '?expand=columns,items(expand=fields)');
  }
  getListId(listId): Observable<any> {
    return this.http.get(GRAPH_ENDPOINT + '/' + listId + '/items/?expand=fields(select=id,Order_x0020_Type1)');
  }
  getListIdBudget(listId): Observable<any> {
    return this.http.get(GRAPH_ENDPOINT + '/' + listId + "/items?$filter=fields/Order_x0020_Type1 ne 'BUDGET' and fields/Order_x0020_Type1 ne 'BUDGET PROMOTIONS' and fields/Order_x0020_Type1 ne 'BUDGET CONTINGENCY'" )
  }
  create(listId, orderSp): Observable<any> {
    return this.http.post(GRAPH_ENDPOINT + '/' + listId + '/items', {fields: orderSp});
  }
  remove(listId: string, id: number) {
    return this.http.delete(GRAPH_ENDPOINT + '/' + listId + '/items/' + id);
  }
}
