import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { timingSafeEqual } from 'crypto';
const url = environment.kalcorApiUrl;

@Injectable({
  providedIn: 'root'
})
export class TransportationService {

  constructor(private http: HttpClient) { }
  getAll(): Observable<any> {
    return this.http.get(url + 'AccountsTransportations', this.jwt());
  }
  getCompany(): Observable<any> {
      return this.http.get(url + 'AccountsTransportations/Company', this.jwt());
  }
  getOne(id: number): Observable<any> {
    return this.http.get(url + 'AccountsTransportations/' + id, this.jwt());
  }
  getFilteredListing(activeItem: string, id: number, page: number, pageSize: number): Observable<any> {
    page = page ? page : 1;
    pageSize = pageSize ? pageSize : 30;
    return this.http.get(url + 'AccountsTransportations/Filter?activeItem=' + activeItem + '&id=' + id
    + '&page=' + page + '&pageSize=' + pageSize, this.jwt());
  }
  update(transport) {
    return this.http.put(url + 'AccountsTransportations/' + transport.id, transport, this.jwt());
  }
  create(transport): Observable<any> {
    return this.http.post(url + 'AccountsTransportations', transport, this.jwt());
  }
  remove(id) {
    return this.http.delete(url + 'AccountsTransportations/' + id, this.jwt());
  }
  private jwt() {
    // create authorization header with jwt token
    const currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
    if (currentUser && currentUser.token) {
      const headersInfo = new HttpHeaders({ Authorization: 'Bearer ' + currentUser.token });
      return { headers: headersInfo };
    }
  }

}
