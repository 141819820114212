import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { Vendor } from '../../../models/vendor-model';
import { VendorService } from '../../../services/vendor.service';

@Injectable()
export class VendorResolver implements Resolve<Vendor> {
  constructor(private vendorService: VendorService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Vendor> {
    const idField = 'id';
    const id =  parseInt(route.params[idField], 10);

    if (id === 0) {
      return;
    }
    return this.vendorService.getOne(id);
  }
}
