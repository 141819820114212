import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { CustomerRetail } from '../../../../models/customer-retail.model';
import { CustomerEnd } from '../../../../models/customer-end-model';
import { CustomerRetailService } from '../../../../services/customer-retail.service';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { CustomerEndService } from '../../../../services/customer-end.service';
import { Region } from 'src/app/models/region-model';
import { RegionService } from 'src/app/services/region.service';
import { TypeOfAccount } from 'src/app/models/type-of-account-model';
import { TypeOfAccountService } from 'src/app/services/type-of-account.service';
import { CustomerService } from 'src/app/services/customer.service';
import { ShipToAddressService } from 'src/app/services/ship-to-address.service';
import { Customer } from 'src/app/models/customer.model';
import { ShipToAddress } from 'src/app/models/shipto.model';
import { BannerRegions } from 'src/app/models/banner-regions.model';
import { BannerRegionsService } from 'src/app/services/banner-regions.service';
//import { ThisReceiver, ThrowStmt } from '@angular/compiler';

@Component({
  selector: 'app-customer-retail-edit',
  templateUrl: './customer-retail-edit.component.html',
  styleUrls: ['./customer-retail-edit.component.scss']
})
export class CustomerRetailEditComponent implements OnInit {
  title = 'Banners New';
  newOne = true;
  customerRetail: CustomerRetail;
  customer: CustomerEnd[] = [];
  customerOriginal: Customer[];
  customerRetailForm: UntypedFormGroup;
  customerHead: CustomerEnd;
  typeOfAcct: TypeOfAccount;
  region: Region []; 
  bannerRegion: BannerRegions[];
  shipTo: ShipToAddress [];
  shipToOriginal: ShipToAddress [];
  banners: any;
  successMessage: string;
  counter: number;
  noOfStores = [];
  totalStores = 0;
  customerId: number;
  alertType = "success";
  private success = new Subject<string>();
  staticAlertClosed = false;
  constructor(private route: ActivatedRoute,
              private router: Router,
              private fb: UntypedFormBuilder,
              private customerRetailSvc: CustomerRetailService,
              private typeOfAcctSvc: TypeOfAccountService,
              private regionSvc: RegionService,
              private customerSvc: CustomerEndService,
              private custSvc: CustomerService,
              private shipToSvc: ShipToAddressService,
              private bannerRegionsSvc: BannerRegionsService,
              private customerHeadService: CustomerEndService
  ) {
    this.customerRetail = new CustomerRetail();
     }

  ngOnInit() {
    this.customerRetailForm = this.fb.group({
      activeItem: [''],
      company: ['', Validators.required],
      address: [''],
      city: [''],
      stateProvince: [''],
      countryRegion: [''],
      zipPostalCode: [''],
      comments: [''],
      noStores: [''],
      webPage: [''],
      customerHeadId: ['', Validators.required],
      accountTypeId: [''],
      // regionId: ['']


    });
    this.totalStores = 0;
    this.banners = [];
    this.bannerRegion = [];
    this.customerSvc.getAll().subscribe(data => {
      this.customer = data;
      this.typeOfAcctSvc.getAll().subscribe(typeOfAcct => {
        this.typeOfAcct = typeOfAcct;
        this.regionSvc.getAll().subscribe(region => {
          this.region = region;
          this.noOfStores = [];
          this.region.forEach(data => {
            this.noOfStores.push(0) 
          });
          this.shipToSvc.getAll().subscribe(shipTo => {
            this.shipTo = shipTo;
            this.shipToOriginal = shipTo;
            this.custSvc.getAll().subscribe(customerOriginal => {
              this.customerOriginal = customerOriginal;
              this.route.data.subscribe(data2 => {
                if (data2.dataRetail !== undefined) {
                  this.title = 'Banners Edit';
                  this.newOne = false;
                  this.customerRetail = data2.dataRetail;
                  if (this.customerRetail.customerHeadId) {
                    this.custSvc.getCustomer(this.customerRetail.customerHeadId).subscribe(data => {
                      this.customerId = data.length > 0 ? data[0] : 0;
                      this.shipToSvc.getAll().subscribe(ship => {
                        this.shipToOriginal = ship;
                        this.bannerRegionsSvc.getRegion(this.customerRetail.id).subscribe(data => {
                          this.bannerRegion = data;
                            this.customerRetailSvc.getFilteredListing('Active', this.customerRetail.id, 0, 1, 999).subscribe(data => {
                              data.item.forEach(e => {
                                this.totalStores += e.noOfStores ? e.noOfStores : 0;
                                const retail = {
                                  banner: e.retailCompany,
                                  province: e.retailProvince,
                                  typeOfAccount: e.retailAccountType,
                                  region: e.retailRegion,
                                  noOfStores: e.noOfStores,
                                  bannersRegionId: e.bannerRegionId,
                                  customerHead: e.headOfficeId,
                                  checkBox: 1                  
                                };
                                this.banners.push(retail);
                                if (e.noOfStores > 0) {
                                  const index = this.region.findIndex(data => data.regionId == e.retailRegionId);
                                  if (index > -1) {
                                    this.noOfStores[index] = e.noOfStores;
                                  }                               
                                }
                              })
                              
                            this.fillFormData();
                          })  
                        })
                      })
                    }) 
                  } else {
                    this.fillFormData();
                  }
                    
                } else {
                  this.title = 'Banners New';
                  this.newOne = true;
                  this.customerRetail = new CustomerRetail();
                  this.customerRetailInit();
                  this.customerRetailForm.reset();
                  // this.addStores(this.customerRetail.noStores);
                  this.regionSvc.getAll().subscribe(region => {
                    this.region = region;
                    this.noOfStores = [];
                    this.region.forEach(data => {
                      this.noOfStores.push(0) 
                    });                
                  })
                  // this.shipToSvc.getAll().subscribe(ship => {
                  //   this.shipToOriginal = ship;
                  //   this.bannerRegion = [];
                  //   this.fillFormData();
                  // })
                }
              })
            })
          })
        })
      });
    });
    setTimeout(() => this.staticAlertClosed = true, 20000);
    this.success.subscribe((message) => this.successMessage = message);
    this.success.pipe(
      debounceTime(5000)
    ).subscribe(() => this.successMessage = null);
  }
  fillFormData() {
    this.customerRetailForm.setValue({
      activeItem: this.customerRetail.activeItem,
      company: this.customerRetail.company,
      address: this.customerRetail.address,
      city: this.customerRetail.city,
      stateProvince: this.customerRetail.stateProvince,
      countryRegion: this.customerRetail.countryRegion,
      zipPostalCode: this.customerRetail.zipPostalCode,
      comments: this.customerRetail.comments,
      noStores: this.totalStores,
      webPage: this.customerRetail.webPage,
      customerHeadId: this.customerRetail.customerHeadId,
      accountTypeId: this.customerRetail.accountTypeId,
    });
  }
  onChangesStores() {
    const totalStores = this.addStores(this.noOfStores);
    this.customerRetailForm.controls.noStores.setValue(totalStores);
  }
  addStores(stores){
    if (stores) {
      let sum = 0;
      for (let i = 0; i < stores.length; i += 1) {
        sum += stores[i] * 1;
      }
      return sum;
    }    
  }
  splitNumbers(ids) {
    let ret = [];
    if (ids) {
      let retCount = 0;
      const tempSplit = ids.split(',');
      if (tempSplit.length > 0) {
        tempSplit.forEach(id => {
          if (id) {
            ret.push(id * 1);
          }
        })
      }
    }
    return ret;
  }
  setupShipTo(custid) {
    this.shipTo = [];
    if(custid) {
      this.shipToOriginal.forEach( x => {
        if (x.customerId == custid) {
          this.shipTo.push(x);
        }
      })
    }
  }
  onSubmit() {
    if (this.customerRetailForm.invalid) {
      this.success.next('Please enter required fields.');
      this.alertType = "danger";
      return;
    }
    if (this.customerRetail.customerHeadId = 0) {
      this.success.next('Please enter required fields.');
      this.alertType = "danger";
      return;
    }  
    this.customerRetail.activeItem = this.customerRetailForm.controls.activeItem.value;
    this.customerRetail.company = this.customerRetailForm.controls.company.value;
    this.customerRetail.address = this.customerRetailForm.controls.address.value;
    this.customerRetail.city = this.customerRetailForm.controls.city.value;
    this.customerRetail.stateProvince = this.customerRetailForm.controls.stateProvince.value;
    this.customerRetail.countryRegion = this.customerRetailForm.controls.countryRegion.value;
    this.customerRetail.zipPostalCode = this.customerRetailForm.controls.zipPostalCode.value;
    this.customerRetail.comments = this.customerRetailForm.controls.comments.value;
    this.customerRetail.noStores = this.customerRetailForm.controls.noStores.value;
    this.customerRetail.webPage = this.customerRetailForm.controls.webPage.value;
    this.customerRetail.customerHeadId = this.customerRetailForm.controls.customerHeadId.value;
    this.customerRetail.accountTypeId = this.customerRetailForm.controls.accountTypeId.value;
    this.counter = 0;
    if (this.newOne) {
      this.customerRetailSvc.create(this.customerRetail).subscribe(data => {
        this.customerRetail.id =  data.id;
        this.deleteBannerRegions();
        this.totalStores = 0;
        this.router.navigate(['/customers/retail-edit', this.customerRetail.id]);
      }, error => {
      });
    } else {
      if (this.customerRetailForm.invalid) {
        this.success.next('Please enter required fields.');
        this.alertType = "danger";
        return;
      }
      this.customerRetailSvc.update(this.customerRetail).subscribe(data => {
        this.deleteBannerRegions();
        this.success.next(`Successfully changed.`);
        this.alertType = "success";
      }, error => {
      });
    }
  }
  deleteBannerRegions() {
    this.counter++;
    if(this.counter > this.bannerRegion.length) {
      this.counter = 0;
      this.updateBannerRegions();
    } else {
      const index = this.counter -1;
      this.bannerRegionsSvc.remove(this.bannerRegion[index].bannerRegionId).subscribe(data => {
        this.deleteBannerRegions();
      }, error => {
        this.deleteBannerRegions();
      });
    }
  }
  updateBannerRegions() {
    this.counter++;
    if (this.counter <= this.region.length) {
      const index = this.counter - 1;
      if (this.noOfStores[index] == 0) {
        this.updateBannerRegions();
        return;
      }
      let x = new BannerRegions();
      x.bannerRegionId = 0;
      x.bannersId = this.customerRetail.id;
      x.customerHeadId = this.customerRetail.customerHeadId;
      x.customerId = this.customerRetail.customerId;
      x.noStores = this.noOfStores[index];
      x.regionId = this.region[index].regionId;
      this.bannerRegionsSvc.create(x).subscribe(data => {
        this.updateBannerRegions();
      });
    } else {
      this.bannerRegionsSvc.getRegion(this.customerRetail.id).subscribe(data => {
        this.bannerRegion = data;
        // this.noOfStores = [];
      })
    }
  }
  onDelete() {
    if (this.customerRetail.id) {
      this.customerRetailSvc.remove(this.customerRetail.id).subscribe(data => {
        this.router.navigate(['/customers/retail-list']);
      });
    }
  }
  customerRetailInit() {
    this.customerRetail.id = 0;
    this.customerRetail.activeItem = '';
    this.customerRetail.customer = '';
    this.customerRetail.company = '';
    this.customerRetail.address = '';
    this.customerRetail.city = '';
    this.customerRetail.stateProvince = '';
    this.customerRetail.countryRegion = '';
    this.customerRetail.zipPostalCode = '';
    this.customerRetail.comments = '';
    this.customerRetail.noStores = 0;
    this.customerRetail.typeOfAccount = '';
    this.customerRetail.webPage = '';
    this.customerRetail.customerId = 0;
    this.customerRetail.locationId = 0;
    this.customerRetail.mapLinkOffice = '';
    this.customerRetail.mapLinkWarehouse = '';
    this.customerRetail.customerNavigation = '';
    this.customerRetail.accountTypeId = 0;
    this.customerRetail.customerHeadId = 0;
    this.customerRetail.customerId = 0;
  }
}
