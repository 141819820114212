export class ShipToBroker {
    shipToBrokerId: number;
    listingId: number;
    shipToId: number;
    brokerId: number;
    broker2Id: number;
    commission: number;
    commission1: number;
    commission2: number;
}
  