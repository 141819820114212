<div class="col-sm-22 col-sm-offset-3">
  <h1>Hi {{currentUser?.firstName}}!</h1>
  <p>You're logged in to Kalcor</p>
</div>
<div *ngIf="!loginDisplay">
  <p>Please sign-in to see your profile information.</p>
</div>

<div *ngIf="loginDisplay">
  <p>Login successful!</p>
  <p>Request your profile information by clicking Profile above.</p>
</div>