import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Vendor } from '../../../../models/vendor-model';
import { VendorService } from '../../../../services/vendor.service';
import { TermsService } from '../../../../services/terms.service';
import { Term } from '../../../../models/term-model';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
@Component({
  selector: 'app-vendor-detail',
  templateUrl: './vendor-detail.component.html',
  styleUrls: ['./vendor-detail.component.scss']
})

export class VendorDetailComponent implements OnInit {
  title = 'Vendor New';
  newOne = true;
  vendor: Vendor;
  vendorForm: UntypedFormGroup;
  successMessage: string;
  private success = new Subject<string>();
  staticAlertClosed = false;
  term: Term[] = [];

  constructor(private route: ActivatedRoute,
              private router: Router,
              private fb: UntypedFormBuilder,
              private termsSvc: TermsService,
              private vendorSvc: VendorService
  ) { }

  ngOnInit() {
    this.vendorForm = this.fb.group({
      activeItem: [''],
      company: [''],
      address: [''],
      city: [''],
      stateProvince: [''],
      countryRegion: [''],
      zipPostalCode: [''],
      fullName: [''],
      jobTitle: [''],
      businessPhone: [''],
      faxNumber: [''],
      mobileNumber: [''],
      eMail: [''],
      currencyCode: [''],
      termsId: [''],
      webPage: [''],
      comments: [''],
      accountNo: [''],
      spListName: [''],
      latitude: [''],
      longitude: ['']
    });
    this.termsSvc.getAll().subscribe(data => {
      this.term = data;
      this.route.data.subscribe(data2 => {
        if (data2.dataVendor !== undefined) {
          this.title = 'Vendor Edit';
          this.newOne = false;
          this.vendor = data2.dataVendor;
          this.fillFormData();
        } else {
          this.title = 'Vendor New';
          this.newOne = true;
          this.vendor = new Vendor();
          this.vendorForm.reset();
        }
      });
    });
    setTimeout(() => this.staticAlertClosed = true, 20000);
    this.success.subscribe((message) => this.successMessage = message);
    this.success.pipe(
      debounceTime(5000)
    ).subscribe(() => this.successMessage = null);
  }
  fillFormData() {
    this.vendorForm.setValue({
      activeItem: this.vendor.activeItem,
      company: this.vendor.company,
      address: this.vendor.address,
      city: this.vendor.city,
      stateProvince: this.vendor.stateProvince,
      countryRegion: this.vendor.countryRegion,
      zipPostalCode: this.vendor.zipPostalCode,
      fullName: this.vendor.fullName,
      jobTitle: this.vendor.jobTitle,
      businessPhone: this.vendor.businessPhone,
      faxNumber: this.vendor.faxNumber,
      mobileNumber: this.vendor.mobileNumber,
      eMail: this.vendor.eMail,
      currencyCode: this.vendor.currencyCode,
      termsId: this.vendor.termsId,
      webPage: this.vendor.webPage,
      comments: this.vendor.comments,
      accountNo: this.vendor.accountNo,
      spListName: this.vendor.spListName,
      latitude: this.vendor.latitude,
      longitude: this.vendor.longitude
    });
  }
  onSubmit() {
    this.vendor.activeItem = this.vendorForm.controls.activeItem.value;
    this.vendor.company = this.vendorForm.controls.company.value;
    this.vendor.address = this.vendorForm.controls.address.value;
    this.vendor.city = this.vendorForm.controls.city.value;
    this.vendor.stateProvince = this.vendorForm.controls.stateProvince.value;
    this.vendor.countryRegion = this.vendorForm.controls.countryRegion.value;
    this.vendor.zipPostalCode = this.vendorForm.controls.zipPostalCode.value;
    this.vendor.fullName = this.vendorForm.controls.fullName.value;
    this.vendor.jobTitle = this.vendorForm.controls.jobTitle.value;
    this.vendor.businessPhone = this.vendorForm.controls.businessPhone.value;
    this.vendor.faxNumber = this.vendorForm.controls.faxNumber.value;
    this.vendor.mobileNumber = this.vendorForm.controls.mobileNumber.value;
    this.vendor.eMail = this.vendorForm.controls.eMail.value;
    this.vendor.currencyCode = this.vendorForm.controls.currencyCode.value;
    this.vendor.termsId = this.vendorForm.controls.termsId.value;
    this.vendor.webPage = this.vendorForm.controls.webPage.value;
    this.vendor.comments = this.vendorForm.controls.comments.value;
    this.vendor.accountNo = this.vendorForm.controls.accountNo.value;
    this.vendor.spListName = this.vendorForm.controls.spListName.value;
    this.vendor.latitude = this.vendorForm.controls.latitude.value;
    this.vendor.longitude = this.vendorForm.controls.longitude.value;
    if (this.newOne) {
      this.vendorSvc.create(this.vendor).subscribe(data => {
        this.vendor.id = data.id;
        this.newOne = false;
        this.title = 'Vendor Edit';
        this.success.next(`Successfully added.`);
      }, error => {
      });
    } else {
      this.vendorSvc.update(this.vendor).subscribe(data => {
        this.success.next(`Successfully changed.`);
      }, error => {
      });
    }
  }
  onDelete() {
    if (this.vendor.id) {
      this.vendorSvc.remove(this.vendor.id).subscribe(data => {
        this.router.navigate(['/vendors/vendor-list']);
      });
    }
  }

}
