<h5>Customer Head Office List </h5>
<h4>Filter</h4>
<div class="col-lg-44">
  <label>
    Active:
    <select [(ngModel)]="activeItemSelected" (change)="loadPage()">
      <option value="Active">Active</option>
      <option value="Not Active">Not Active</option>
    </select>
  </label>
  <label class="ms-3">
      Company:
     <select [(ngModel)]="companySelected" (change)="loadPage()">
      <option [value]=""></option>
      <option *ngFor="let c of companyOptions" [value]="c.id">{{ c.company }}</option>
    </select>
  </label>
  <button type="button" class="btn btn-primary mt-0 ms-3" (click)="reset()">Reset</button>
</div>


  <table class="table table-striped" style="overflow-x:scroll;">
    <thead>
    <tr>
      <th scope="col">Active</th>
      <th scope="col">Company</th>
      <th scope="col">Address</th>
      <th scope="col">City</th>
      <th scope="col">State/Province</th>
      <th scope="col">Country/Region</th>
      <th scope="col">Zip Code</th>
      <th scope="col">Web Page</th>
      <th scope="col">Type of Account</th>
      <th scope="col">Regions</th>
      </tr>
    </thead>
    <tbody>
    <tr *ngFor="let c of customerEnd; index as i" >
      <td>{{ c.activeItem }}</td>
      <td>
        <a class="pointer" (click)="onRowClicked(c)">
          <u class="text-primary"> {{ c.company }}
          </u>        
          </a>
        </td>
      <td>{{ c.address }}</td>
      <td>{{ c.city }}</td>
      <td>{{ c.stateProvince }}</td>
      <td>{{ c.countryRegion }}</td>
      <td>{{ c.zipPostalCode }}</td>
      <td><a href="{{c.webPage}}" target="_blank">{{c.webPage }}</a></td>
      <td>{{ c.retailAccountType }}</td>
      <td>{{ c.retailRegion }}</td>
    </tr>
    </tbody>
  </table>
  <div *ngIf="customerEnd!==undefined">

    <ngb-pagination [(page)]="page" [pageSize]="pageSize" [collectionSize]="count"
      (pageChange)="loadPage()"></ngb-pagination>
  </div>

  <div class="btn-group" role="group" aria-label="Basic radio toggle button group">
      
    <input class="btn-check" type="radio" name="radio1" id="choice1" [(ngModel)]="pageSize"  (change)="loadPage()" autocomplete="off" checked [value]="25">
    <label class="btn btn-outline-primary" for="choice1">
      25
    </label>
  
    <input class="btn-check" type="radio" name="radio1" id="choice2" [(ngModel)]="pageSize"  (change)="loadPage()" [value]="50" autocomplete="off">
    <label class="btn btn-outline-primary" for="choice2">
      50
    </label>
  
    <input class="btn-check" type="radio" name="radio1" id="choice3" [(ngModel)]="pageSize"  (change)="loadPage()" [value]="100" autocomplete="off">
    <label class="btn btn-outline-primary" for="choice3">
      100
    </label>
  
  </div>

  <!-- <div class="btn-group btn-group-toggle" data-toggle="buttons" ngbRadioGroup name="radioBasic" [(ngModel)]="pageSize"  (change)="loadPage()">
    <label class="pt-2 pr-2" >Page Size</label>
    <label class="btn-outline-primary" ngbButtonLabel>
      <input type="radio" ngbButton [value]="25"> 25
    </label>
    <label class="btn-outline-primary" ngbButtonLabel>
      <input type="radio" ngbButton [value]="50"> 50
    </label>
    <label class="btn-outline-primary" ngbButtonLabel>
      <input type="radio" ngbButton [value]="100"> 100
    </label>
  </div> -->
  <ngb-alert *ngIf="successMessage" [type]="alertType" (close)="successMessage = null">{{ successMessage }}</ngb-alert>   
  