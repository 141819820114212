<ng-template #content let-modal>
    <div class="modal-body" *ngIf="message" >  
        <div class="row">  
            <div class="col-md-24">  
                <p class="text-center confirm-message">{{message}}</p>  
            </div>  
        </div>  
        <div class="row">  
            <div class="col-md-24">  
                <p class="confirm-button">  
                    <a (click)="modal.close(1)">  
                        <button class="btn btn-primary">
                            <span *ngIf="btnClicked1" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                             Purchase
                        </button>  
                    </a>  
                    <a (click)="modal.close(2)">  
                        <button class="btn btn-primary">
                            <span *ngIf="btnClicked2" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            FOB Net
                        </button>  
                    </a>  
                </p>  
            </div>  
        </div>  
    </div>  
</ng-template>


