
<div id="my-sales-order" *ngIf="reportName=='Sales Order'">
  <app-sales-order
    [title] = "title"
    [order] = "orderSelected"
    [orderItems] = "orderItems"
    [totalCases] = "totalCases"
    [totalPallets] = "totalPallets"
    [totalGrossLbs] = "totalGrossLbs"
    [totalCaseCost] = "totalCaseCost"
    [totalGrosskg] = "totalGrosskg">
  </app-sales-order>
</div> 
<div id="my-packing-slip" *ngIf="reportName=='Packing Slip'">
  <app-packing-slip
  [title] = "title"
  [order] = "orderSelected"
  [orderItems] = "orderItems">
</app-packing-slip>
</div> 
<div id="my-shipping" *ngIf="reportName=='Shipping'">
  <app-shipping
    [title] = "title"
    [order] = "orderSelected"
    [items] = "orderItems"
    [totalCases] = "totalCases"
    [totalPallets] = "totalPallets"
    [totalGrosskg] = "totalGrosskg"
    [totalGrossLbs] = "totalGrossLbs">
  </app-shipping>
</div> 
<div id="my-commercial-invoice" *ngIf="reportName=='Commercial Invoice'">
  <app-sales-order
    [title] = "title"
    [order] = "orderSelected"
    [orderItems] = "orderItems"
    [totalCases] = "totalCases"
    [totalPallets] = "totalPallets"
    [totalGrossLbs] = "totalGrossLbs"
    [totalCaseCost] = "totalCaseCost"
    [totalGrosskg] = "totalGrosskg">
  </app-sales-order>
</div> 
<div id="my-sales-order-vendor" *ngIf="reportName=='Vendor Sales Order'">
  <app-vendor-sales-order
    [title] = "title"
    [order] = "orderSelected"
    [orderItems] = "orderItems"
    [totalCases] = "totalCases"
    [totalPallets] = "totalPallets"
    [totalGrossLbs] = "totalGrossLbs"
    [totalCaseCost] = "totalCaseCost"
    [totalGrosskg] = "totalGrosskg"
    [vendorPurchaseCaseCost] = "vendorPurchaseCaseCost"
    [fOBNetVendorCost] = "fOBNetVendorCost">
  </app-vendor-sales-order>
</div> 
<div id="my-invoicing" *ngIf="reportName=='Invoice'">
  <app-invoicing
  [title] = "title"
  [order] = "orderSelected"
  [orderItems] = "orderItems"
  [totalCases] = "totalCases"
  [totalPallets] = "totalPallets"
  [totalGrossLbs] = "totalGrossLbs"
  [totalCaseCost] = "totalCaseCost"
  [totalGrosskg] = "totalGrosskg">
  </app-invoicing>
</div> 
<div *ngIf="reportName==''">
  <form [formGroup]="orderForm" *ngIf="loaded" (ngSubmit)="onSubmit()" novalidate>
    <div class="row g-0 text-white mt-2 px-2 bg-primary">
      <div class="col-lg-24 pt-2 px-2 text-center">
        SHIPPING
      </div>
    </div>
  
    <div class="row g-0 mt-2">
      <div class="col-lg-8 pt-2 px-0">
        <label class="text-white w-100 bg-primary">
          BILL TO
        </label>
      </div>
      <div class="col-lg-8 pt-2 px-0">
        <label class="text-white w-100 bg-primary">
          SALES ORDER INFORMATION
        </label>
      </div>
      <div class="col-lg-8 pt-2 px-0">
        <label class="text-white w-100 bg-primary">
          SHIPPING DATES/TYPES
        </label>
      </div>
    </div>
  
    <div class="row g-0">
      <div class="col-lg-8 pt-0 px-2">
        {{ customer?.company }}
      </div>
      <div class="col-lg-4 pt-0 px-2">
        Sales Order Date
      </div>
      <div class="col-lg-4 pt-0 px-2">
        {{ displayDate(order.salesOrderDate, '')  }}
      </div>
      <div class="col-lg-4 pt-0 px-2">
            Shipment Date
      </div>
      <div class="col-lg-4 pt-0 px-2">
        <input id="shipping-date" type="Date" formControlName="shipmentDate" class="form-control">
      </div>
    </div>
  
    <div class="row g-0">
      <div class="col-lg-8 pt-0 px-2">
        {{ customer?.address }}
      </div>
      <div class="col-lg-4 pt-0 px-2">
        Sales Order No.
      </div>
      <div class="col-lg-4 pt-0 px-2">
        {{ order.salesOrderNo }}
      </div>
      <div class="col-lg-4 pt-0 px-2">
        Delivered Date/Appt
      </div>
      <div class="col-lg-4 pt-0 px-2">
        <input id="delivered-date" type="datetime-local" formControlName="deliveredDateAppt" class="form-control">
      </div>
    </div>
  
    <div class="row g-0">
      <div class="col-lg-8 pt-0 px-2">
        {{ getAddress2('customer') }}
      </div>
      <div class="col-lg-4 pt-0 px-2">
        Purchase Order
      </div>
      <div class="col-lg-4 pt-0 px-2">
        {{ order.purchaseOrder }}
      </div>
      <div class="col-lg-4 pt-0 px-2">
        App Confirmed
      </div>
      <div class="col-lg-4 pt-0 px-2">
        <select id="app-confirmed" formControlName="appConfirmed" class="form-control">
            <option value="Yes">Yes</option>
            <option value="No">No</option>
        </select>
      </div>
    </div>
  
    <div class="row g-0">
      <div class="col-lg-8 pt-0 px-2">
      </div>
      <div class="col-lg-4 pt-0 px-2">
        Payment Terms
      </div>
      <div class="col-lg-4 pt-0 px-2">
        {{ term?.paymentTerms }}
      </div>
      <div class="col-lg-4 pt-0 px-2">
        Delivery Type
      </div>
      <div class="col-lg-4 pt-0 px-2">
        <select id="delivery-type" formControlName="freightTypeId" class="form-control">
            <option *ngFor="let f of freightType" [value]="f.id">{{ f.freightType }}</option>
        </select>
      </div>
    </div>
  
    <div class="row g-0">
      <div class="col-lg-8 pt-0 px-2">
      </div>
      <div class="col-lg-4 pt-0 px-2">
        Broker
      </div>
      <div class="col-lg-4 pt-0 px-2">
        {{ broker?.company }}
      </div>
      <div class="col-lg-4 pt-0 px-2">
          Order Status
        </div>
      <div class="col-lg-4 pt-0 px-2">
          {{ order?.orderStatus }}
        </div>
    </div>
  
    <div class="row g-0">
      <div class="col-lg-16 pt-0 px-2">
      </div>
      <div class="col-lg-4 pt-2 px-2">
        Pallet Weight
      </div>
      <div class="col-lg-4 pt-0 px-2">
        <input id="pallet-weight" type="text" formControlName="palletGrossLbs" class="form-control">
      </div>
    </div>
  
    <div class="row g-0">
      <div class="col-lg-6 pt-0 px-0">
        <label class="text-white w-100 bg-primary">
          SHIP TO
        </label>
      </div>
      <div class="col-lg-4 pt-0 px-0">
        <label class="text-white w-100 bg-primary">
          VENDOR PICK UP
        </label>
      </div>
      <div class="col-lg-6 pt-0 px-0">
        <label class="text-white w-100 bg-primary">
          SHIPPING LANE
        </label>
      </div>
  
      <div class="col-lg-4 pt-0 px-0">
        <label class="text-white w-100 bg-primary">
          TRANSPORT COMPANY
        </label>
      </div>
      <div class="col-lg-4 pt-0 px-0">
        <label class="bg-primary text-white text-center w-100 bg-primary">
          WAREHOUSE
        </label>
      </div>
    </div>
  
    <div class="row g-0">
      <div class="col-lg-6 pt-0 px-2">
          {{ shipTo?.company }}
      </div>
      <div class="col-lg-4 pt-0 px-2">
        {{ pickup?.company }}
      </div>
      <div class="col-lg-6 pt-0 px-2">
        {{ location1?.location }}
      </div>
      <div class="col-lg-4 pt-0 px-2">
        <select formControlName="transportCo1" class="form-control">
            <option [value]=""></option>
            <option *ngFor="let t of transport" [value]="t.id">{{ t.company }}</option>
        </select>
      </div>
      <div class="col-lg-4 pt-0 px-2">
        {{ warehouse?.company }}
      </div>
    </div>
    <div class="row g-0">
      <div class="col-lg-8">
      </div>
      <div class="col-lg-2 text-end pe-2 pt-2">
        Type of Freight
      </div>
      <div class="col-lg-2">
      <select formControlName="freightType1" class="form-control">
        <option [value]=""></option>
        <option value="CND">CND Freight Cost</option>
        <option value="USD">USD Freight Cost</option>
      </select>
    </div>
      <div class="col-lg-2 text-end pe-2 pt-2">
        Cost
      </div>
      <div class="col-lg-2">
        <input type="text" formControlName="freightCost1" class="form-control">
      </div>
      <div class="col-lg-2 text-end pe-2 pt-2">
        FSC %
      </div>
      <div class=" col-lg-2">
        <input type="text" formControlName="freightCostSales1" class="form-control">
      </div>
      <div class="col-lg-2 text-end pe-2 pt-2">
        Total Cost
      </div>
      <div class=" col-lg-2">
        <input type="text" formControlName="freightTotalCost1" class="form-control">
      </div>
    </div> 
    <div class="row g-0 mt-2">
        <div class="col-lg-6 pt-0 px-2">
            {{ shipTo?.address }}
          </div>
    
      <div class="col-lg-4 pt-0 px-2">
        {{ pickup?.address }}
      </div>
      <div class="col-lg-6 pt-0 px-2">
        {{ location2?.location }}
      </div>
      <div class="col-lg-4 pt-0 px-2">
        <select formControlName="transportCo2" class="form-control">
          <option [value]=""></option>
          <option *ngFor="let t of transport" [value]="t.id">{{ t.company }}</option>
      </select>
      </div>
      <div class="col-lg-4 pt-0 px-2">
        {{ warehouse?.address }}
      </div>
    </div>
    
    <div class="row g-0">
      <div class="col-lg-8">
      </div>
      <div class="col-lg-2 text-end pe-2 pt-2">
        Type of Freight
      </div>
      <div class="col-lg-2">
      <select formControlName="freightType2" class="form-control">
        <option [value]=""></option>
        <option value="CND">CND Freight Cost</option>
        <option value="USD">USD Freight Cost</option>
      </select>
      </div>
      <div class="col-lg-2 text-end pe-2 pt-2">
        Cost
      </div>
      <div class=" col-lg-2">
        <input type="text" formControlName="freightCost2" class="form-control">
      </div>
      <div class="col-lg-2 text-end pe-2 pt-2">
        FSC %
      </div>
      <div class=" col-lg-2">
        <input type="text" formControlName="freightCostSales2" class="form-control">
      </div>
      <div class="col-lg-2 text-end pe-2 pt-2">
        Total Cost
      </div>
      <div class=" col-lg-2">
        <input type="text" formControlName="freightTotalCost2" class="form-control">
      </div>
    </div>

    <div class="row g-0 mt-2">
      <div class="col-lg-6 pt-0 px-2">
         {{ getAddress2('shipto') }}
      </div>
      <div class="col-lg-4 pt-0 px-2">
        {{ getAddress2('pickup') }}
      </div>
      <div class="col-lg-6 pt-0 px-2">
        {{ location3?.location }}
      </div>
      <div class="col-lg-4 pt-0 px-2">
        <select formControlName="transportCo3" class="form-control">
          <option [value]=""></option>
          <option *ngFor="let t of transport" [value]="t.id">{{ t.company }}</option>
        </select>
      </div>
      <div class="col-lg-4 pt-0 px-2">
        {{ warehouseLine3 }}
      </div>
    </div>

    <div class="row g-0">
      <div class="col-lg-8">
      </div>
      <div class="col-lg-2 text-end pe-2 pt-2">
        Type of Freight
      </div>
      <div class="col-lg-2">
      <select formControlName="freightType3" class="form-control">
        <option [value]=""></option>
        <option value="CND">CND Freight Cost</option>
        <option value="USD">USD Freight Cost</option>
      </select>
      </div>
      <div class="col-lg-2 text-end pe-2 pt-2">
        Cost
      </div>
      <div class=" col-lg-2">
        <input type="text" formControlName="freightCost3" class="form-control">
      </div>
      <div class="col-lg-2 text-end pe-2 pt-2">
        FSC %
      </div>
      <div class=" col-lg-2">
        <input type="text" formControlName="freightCostSales3" class="form-control">
      </div>
      <div class="col-lg-2 text-end pe-2 pt-2">
        Total Cost
      </div>
      <div class=" col-lg-2">
        <input type="text" formControlName="freightTotalCost3" class="form-control">
      </div>
    </div>
    
    <div *ngIf="warehouseLine4 !=''" class="row g-0 mt-2">
      <div class="col-lg-20 pt-0 px-2">
      </div>
      <div class="col-lg-4 pt-0 px-2">
        {{ warehouseLine4 }}
      </div>
    </div>

    <div class="row g-0">
      <div class="col-lg-20 pt-3 px-2">
        <span class="font-weight-bold">Vendor: </span>
        {{ getVendor() }}
      </div>
      <div class="col-lg-24 pt-3 px-2">
        <span class="font-weight-bold">Notes: </span>
        {{ order.notes }}
      </div>
    </div>
  
    <div class="row g-0 text-white mt-3 bg-primary">
      <div class="col-lg-2 pt-0 px-2">
        <label class="text-white">
          ORDER CODE
        </label>
      </div>
      <div class="col-lg-6 pt-0 px-2">
        <label class="text-white">
          BRAND/CATEGORY
        </label>
      </div>
  
      <div class="col-lg-10 pt-0 px-2">
        <label class="text-white">
          ITEM DESCRIPTION
        </label>
      </div>
  
      <div class="col-lg-2 pt-0 px-2">
        <label class="text-white">
          SIZE
        </label>
      </div>
      <div class="col-lg-2 pt-0 px-2">
        <label class="text-white text-end w-100">
          ORDER
        </label>
      </div>
      <div class="col-lg-2 pt-0 px-2">
        <label class="text-white text-end w-100">
          SHIPPED
        </label>
      </div>
    </div>
      <div class="form-group">
        <div *ngFor="let item of items; let i = index;">
          <div class="row g-0"
            [ngClass]="{'grid-stripe': i % 2 != 0}">
            <div class="col-lg-2 pt-0 px-2 ">
              {{ item.orderCode}}
            </div>
            <div class="col-lg-6 pt-0 px-2 ">
              {{ item.brandCategory }}
            </div>
            <div class="col-lg-10 pt-0 px-2 ">
              {{ item.itemDescription}}
            </div>
            <div class="col-lg-2 pt-0 px-2 ">
              {{ item.size }}
            </div>
            <div class="col-lg-2 pt-0 px-2 text-end">
              <a (click)="openModal(i)" class="text-primary pointer">
                <u class="text-primary">{{ item.cases }}</u>
              </a>
            </div>
            <div class="col-lg-2 pt-0 px-2  text-end">
                {{ item.shipped ? item.shipped : 0 | number : '1.0-0' }}
            </div>
          </div>
          <div class="row g-0" (click)="openModal(i)"
            [ngClass]="{'gray-out': grayRow[i]==='G', 'grid-stripe': grayRow[i]==='S', 'pdf-none': grayRow[i]==='B'}">
            <div class="col-lg-4 pt-0 pb-2 px-2 ">
              <span class="pe-2">CS Gross Lbs</span>
              {{ item.csGrossLbs | number : '1.2-2' }}
            </div>
            <div class="col-lg-4 pt-0 pb-2 px-2 ">
              <span class="pe-2">Product Type</span>
              {{ item.productType }}
            </div>
            <div class="col-lg-6 pt-0 pb-2 px-2 ">
              <span class="pe-2">TI/HI</span>
              {{ item.tiHi }}
              <span class="ps-4 pe-2">CS per Pallet</span>
              {{ item.csPerPallet }}
            </div>
          </div>
        </div>
      </div>
      <div class="row g-0 text-white mt-3 bg-primary">
        <div class="col-lg-6 pt-0 px-2">
          <span class="pe-2">TOTAL CASES</span>
          {{ totalCases | number : '1.0-0' }}
        </div>
        <div class="col-lg-4 pt-0 px-2">
          <span class="pe-2">TOTAL PALLETS</span>
          {{ totalPallets |  number : '1.0-0' }}
        </div>
        <div class="col-lg-2 pt-0 px-2">
          CONFIRM PALLETS
        </div>
  
        <div class="col-lg-1 pt-0 px-2">
          <input id="confirm-pallets" type="text" formControlName="palletCount" class="form-control" >
        </div>
    
        <div class="col-lg-8 pt-0 px-2 ms-auto">
          <span class="pe-2">TOTAL GROSS</span>
          {{ totalGrossLbs | number : '1.2-2'  }}
          <span class="pe-3"> lbs</span>
          {{ totalGrosskg | number : '1.2-2'  }}
          <span class="pe-2"> kg</span>
        </div>
  
      </div>
      <div class="row g-0 mt-3">
        <div class="col-lg-6 pt-0 px-2">
          <img class="logo img-responsive" src="/assets/images/Kalcor Menu.png" height="100%" width="158px">
        </div>
        <div class="col-lg-18 pt-3 px-2">
          ​​CANADA OFFICES: VANCOUVER: 1285 W Broadway , #600, Vancouver, BC, V6H 3X8, Office 866.779.9850 Email:
          Inbox@kalcor.ca Website: www.kalcor.ca ​
        </div>
      </div>
      <br>

      <div class="row">
        <div class="col-4 pe-0">
          <button type="submit" class="btn btn-primary mt-3 w-100">Save Order</button>
        </div>
        <div class="col-4 pe-0">
          <button type="button" class="btn btn-primary mt-3 w-100" [ngClass]="{'disabled': !shipOrderBtn}" [disabled]="!shipOrderBtn"
            (click)="orderEdit()">Sales Order</button>
        </div>
        <div class="col-4 pe-0">
          <button type="button" class="btn btn-primary mt-3 w-100" [ngClass]="{'disabled': !invoiceOrderBtn}" [disabled]="!invoiceOrderBtn"
          (click)="editInvoice()">Invoice Order</button>
        </div>
      </div>
  
      <div class="row">
        <div class="col-4 pe-0">
          <button type="button" class="btn btn-warning mt-3 w-100" [ngClass]="{'disabled': !salesOrderPrintBtn}" [disabled]="!salesOrderPrintBtn"
          (click)="printProcess('Sales Order')">Print Sales Order</button>
        </div>
      
        <div class="col-4 pe-0">
          <button type="button" class="btn btn-warning mt-3 w-100" [ngClass]="{'disabled': !slipPrintBtn}" [disabled]="!slipPrintBtn"
          (click)="printProcess('Packing Slip')" >Print Packing Slip</button>
        </div>
        <div class="col-4 pe-0">
          <button type="button" class="btn btn-warning mt-3 w-100" [ngClass]="{'disabled': !shipPrintBtn}" [disabled]="!shipPrintBtn"
          (click)="printProcess('Shipping')">Print Shipping</button>
        </div>
        <div class="col-4 pe-0">
          <button type="button" class="btn btn-warning mt-3 w-100" [ngClass]="{'disabled': !invoicePrintBtn}" [disabled]="!invoicePrintBtn"
          (click)="printProcess('Commercial Invoice')">Print Commercial Invoice</button>
        </div>
        <div class="col-4 pe-0">
          <button type="button" class="btn btn-warning mt-3 w-100" [ngClass]="{'disabled': !poPrintBtn}" [disabled]="!poPrintBtn"
          (click)="showDialog()">Print Purchase Order</button>
        </div>
        <div class="col-4 pe-0">
          <button type="button" class="btn btn-warning mt-3 w-100" [ngClass]="{'disabled': !invoicePrintBtn}" [disabled]="!invoicePrintBtn"
          (click)="printProcess('Invoice')">Print Invoice</button>
        </div>        
      </div>
      
      <div class="row">
        <div class="col-4 pe-0">
          <button type="button" class="btn btn-secondary mt-3 w-100" (click)="onDelete()" [ngClass]="{'disabled': !deleteOrderBtn}" [disabled]="!deleteOrderBtn"
          >Delete</button>
        </div>
      </div>

      <ngb-alert *ngIf="successMessage" [type]="alertType" (close)="successMessage = null">{{ successMessage }}
      </ngb-alert>
      <div class="backdrop" [ngStyle]="{'display':display}"></div>
      <div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':display}">
        <div class="modal-dialog modal-lg" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title mx-auto">{{ modalTitle }}</h4>
            </div>
            <div class="modal-body">
              <div class="container-fluid">
                <div class="row" *ngIf="rowModalShow[0]">
                  <label for="bb-date1" class="col-md-4 pt-2 px-2 text-end">BB Date:</label>
                  <div class="col-md-6 px-0">
                    <input id="bbdate1" type="Date" formControlName="bbDate1" class="form-control">
                  </div>
                  <label for="lotNo1" class="col-md-2 pt-2 pe-2 text-end">LOT</label>
                  <div class="col-md-4 px-0">
                    <input id="lotNo1" type="text" formControlName="lotNo1" class="form-control">
                  </div>
                  <label for="case1" class="col-md-2 pt-2 pe-2 text-end">CASES</label>
                  <div class="col-md-4 px-0">
                    <input id="case1" type="text" formControlName="case1" class="form-control">
                  </div>
                </div>
                <div class="row" *ngIf="rowModalShow[1]">
                  <label for="bb-date2" class="col-md-4 pt-2 px-2 text-end">BB Date:</label>
                  <div class="col-md-6 px-0">
                    <input id="bbdate2" type="Date" formControlName="bbDate2" class="form-control">
                  </div>
                  <label for="lotNo2" class="col-md-2 pt-2 pe-2 text-end">LOT</label>
                  <div class="col-md-4 px-0">
                    <input id="lotNo2" type="text" formControlName="lotNo2" class="form-control">
                  </div>
                  <label for="case2" class="col-md-2 pt-2 pe-2 text-end">CASES</label>
                  <div class="col-md-4 px-0">
                    <input id="case2" type="text" formControlName="case2" class="form-control">
                  </div>
                </div>
                <div class="row" *ngIf="rowModalShow[2]">
                  <label for="bb-date3" class="col-md-4 pt-2 px-2 text-end">BB Date:</label>
                  <div class="col-md-6 px-0">
                    <input id="bbdate3" type="Date" formControlName="bbDate3" class="form-control">
                  </div>
                  <label for="lotNo3" class="col-md-2 pt-2 pe-2 text-end">LOT</label>
                  <div class="col-md-4 px-0">
                    <input id="lotNo3" type="text" formControlName="lotNo3" class="form-control">
                  </div>
                  <label for="case3" class="col-md-2 pt-2 pe-2 text-end">CASES</label>
                  <div class="col-md-4 px-0">
                    <input id="case3" type="text" formControlName="case3" class="form-control">
                  </div>
                </div>
                <div class="row" *ngIf="rowModalShow[3]">
                  <label for="bb-date4" class="col-md-4 pt-2 px-2 text-end">BB Date:</label>
                  <div class="col-md-6 px-0">
                    <input id="bbdate4" type="Date" formControlName="bbDate4" class="form-control">
                  </div>
                  <label for="lotNo4" class="col-md-2 pt-2 pe-2 text-end">LOT</label>
                  <div class="col-md-4 px-0">
                    <input id="lotNo4" type="text" formControlName="lotNo4" class="form-control">
                  </div>
                  <label for="case4" class="col-md-2 pt-2 pe-2 text-end">CASES</label>
                  <div class="col-md-4 px-0">
                    <input id="case4" type="text" formControlName="case4" class="form-control">
                  </div>
                </div>
                <div class="row" *ngIf="rowModalShow[4]">
                  <label for="bb-date5" class="col-md-4 pt-2 px-2 text-end">BB Date:</label>
                  <div class="col-md-6 px-0">
                    <input id="bbdate5" type="Date" formControlName="bbDate5" class="form-control col-md-6 px-0">
                  </div>
                  <label for="lotNo5" class="col-md-2 pt-2 pe-2 text-end">LOT</label>
                  <div class="col-md-4 px-0">
                    <input id="lotNo5" type="text" formControlName="lotNo5" class="form-control col-md-4 px-0">
                  </div>
                  <label for="case5" class="col-md-2 pt-2 pe-2 text-end">CASES</label>
                  <div class="col-md-4 px-0">
                    <input id="case5" type="text" formControlName="case5" class="form-control col-md-4 px-0">
                  </div>
                </div>
                <div class="row" *ngIf="rowModalShow[5]">
                  <label for="bb-date6" class="col-md-4 pt-2 px-2 text-end">BB Date:</label>
                  <div class="col-md-6 px-0">
                    <input id="bbdate6" type="Date" formControlName="bbDate6" class="form-control col-md-6 px-0">
                  </div>
                  <label for="lotNo6" class="col-md-2 pt-2 pe-2 text-end">LOT</label>
                  <div class="col-md-4 px-0">
                    <input id="lotNo6" type="text" formControlName="lotNo6" class="form-control col-md-4 px-0">
                  </div>
                  <label for="case6" class="col-md-2 pt-2 pe-2 text-end">CASES</label>
                  <div class="col-md-4 px-0">
                    <input id="case6" type="text" formControlName="case6" class="form-control col-md-4 px-0">
                  </div>
                </div>
                <div class="row" *ngIf="rowModalShow[6]">
                  <label for="bb-date7" class="col-md-4 pt-2 px-2 text-end">BB Date:</label>
                  <div class="col-md-6 px-0">
                    <input id="bbdate7" type="Date" formControlName="bbDate7" class="form-control col-md-6 px-0">
                  </div>
                  <label for="lotNo7" class="col-md-2 pt-2 pe-2 text-end">LOT</label>
                  <div class="col-md-4 px-0">
                    <input id="lotNo7" type="text" formControlName="lotNo7" class="form-control col-md-4 px-0">
                  </div>
                  <label for="case7" class="col-md-2 pt-2 pe-2 text-end">CASES</label>
                  <div class="col-md-4 px-0">
                    <input id="case7" type="text" formControlName="case7" class="form-control col-md-4 px-0">
                  </div>
                </div>
                <div class="row" *ngIf="rowModalShow[7]">
                  <label for="bb-date8" class="col-md-4 pt-2 px-2 text-end">BB Date:</label>
                  <div class="col-md-6 px-0">
                    <input id="bbdate8" type="Date" formControlName="bbDate8" class="form-control col-md-6 px-0">
                  </div>
                  <label for="lotNo8" class="col-md-2 pt-2 pe-2 text-end">LOT</label>
                  <div class="col-md-4 px-0">
                    <input id="lotNo8" type="text" formControlName="lotNo8" class="form-control col-md-4 px-0">
                  </div>
                  <label for="case8" class="col-md-2 pt-2 pe-2 text-end">CASES</label>
                  <div class="col-md-4 px-0">
                    <input id="case8" type="text" formControlName="case8" class="form-control col-md-4 px-0">
                  </div>
                </div>
                <div class="row" *ngIf="rowModalShow[8]">
                  <label for="bb-date9" class="col-md-4 pt-2 px-2 text-end">BB Date:</label>
                  <div class="col-md-6 px-0">
                    <input id="bbdate9" type="Date" formControlName="bbDate9" class="form-control col-md-6 px-0">
                  </div>
                  <label for="lotNo9" class="col-md-2 pt-2 pe-2 text-end">LOT</label>
                  <div class="col-md-4 px-0">
                    <input id="lotNo9" type="text" formControlName="lotNo9" class="form-control col-md-4 px-0">
                  </div>
                  <label for="case9" class="col-md-2 pt-2 pe-2 text-end">CASES</label>
                  <div class="col-md-4 px-0">
                    <input id="case9" type="text" formControlName="case9" class="form-control col-md-4 px-0">
                  </div>
                </div>
                <div class="row" *ngIf="rowModalShow[9]">
                  <label for="bb-date10" class="col-md-4 pt-2 px-2 text-end">BB Date:</label>
                  <div class="col-md-6 px-0">
                    <input id="bbdate10" type="Date" formControlName="bbDate10" class="form-control col-md-6 px-0">
                  </div>
                  <label for="lotNo10" class="col-md-2 pt-2 pe-2 text-end">LOT</label>
                  <div class="col-md-4 px-0">
                    <input id="lotNo10" type="text" formControlName="lotNo10" class="form-control col-md-4 px-0">
                  </div>
                  <label for="case10" class="col-md-2 pt-2 pe-2 text-end">CASES</label>
                  <div class="col-md-4 px-0">
                    <input id="case10" type="text" formControlName="case10" class="form-control col-md-4 px-0">
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-link" (click)="onAddLine()">*Additional Line*</button>
                <button type="button" class="btn btn-default" (click)="onCloseHandled()">Close</button>
              </div>
            </div>
            </div><!-- /.modal-content -->
          </div><!-- /.modal-dialog -->
        </div><!-- /.modal !-->
        </form>
      </div> 
      <div id="my-confirm-page" *ngIf="reportName=='Confirm'">
        <app-confirm-dialog 
          (returnDialog) = "returnDialog($event)">
        </app-confirm-dialog>
      </div> 