import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from "@angular/router";
import { Observable } from "rxjs";
import { Projection } from "../models/projection-model";
import { ProjectionsService } from "../services/projections.service";

@Injectable()

export class ProjectionsResolver implements Resolve<Projection> {
    projectionId: number;
    
    constructor(private projectionsService: ProjectionsService) {
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Projection> {
        const idField = 'id';
        this.projectionId = parseInt(route.params[idField], 10);

        if (this.projectionId === 0) {
            return;
        }
        return this.projectionsService.getOne(this.projectionId);
    }
    //     const id = parseInt(route.params[idField], 10);
    //     if (id === 0) {
    //         return;
    //     }
    //     return this.projectionsService.getOne(id);
    // }
}



