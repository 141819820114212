import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { WeekCustomerEnd } from 'src/app/models/week-customer-end.model';
import { WeekCustomerEndService } from 'src/app/services/week-customer-end.service';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { CustomerEndService } from 'src/app/services/customer-end.service';
//import { Message } from '@angular/compiler/src/i18n/i18n_ast';
import { CustomerEnd } from 'src/app/models/customer-end-model';

@Component({
  selector: 'app-week-customer-end-new',
  templateUrl: './week-customer-end-new.component.html',
  styleUrls: ['./week-customer-end-new.component.scss']
})
export class WeekCustomerEndNewComponent implements OnInit {
  customerHeadSelected: string;
  customerHead: CustomerEnd[];
  successMessage: string;
  private success = new Subject<string>();
  staticAlertClosed = false;
  alertType = "success";

  constructor(
      private route: ActivatedRoute,
      private router: Router,
      private weekCustomerEndSvc: WeekCustomerEndService,
      private customerEndSvc: CustomerEndService
      ) { }

  ngOnInit(): void {
    this.customerEndSvc.getCompany().subscribe(data => {
      this.customerHead = data;
      this.displayItems();
    }, error => {
      this.successMessage = 'Unable to process request';
      this.alertType = 'danger';
    })
    setTimeout(() => this.staticAlertClosed= true, 2000);
    this.success.subscribe((message) => this.successMessage = message);
    this.success.pipe(
      debounceTime(5000)
    ).subscribe(() => this.successMessage = null);
  }
displayItems() {
  this.customerHead;
}
onRowClicked(customerHead) {
  this.router.navigate(['./other/week-customer-end-edit', customerHead.id, 0]);
}
}
