<div class="login-form">
  <form [formGroup] ="loginForm">
      <h2 class="text-center">Log in</h2>
      <div class="mb-3 mt-3">
          <input type="text" class="form-control" formControlName="username" placeholder="Username" required="required">
      </div>
      <div class="mb-3">
          <input type="password" class="form-control" formControlName="password" placeholder="Password" required="required">
      </div>
      <div class="mb-3">
          <button type="submit" class="btn btn-primary" (click)="login()">Log in</button>
      </div>
      <!-- <div class="clearfix">
          <label class="pull-left checkbox-inline"><input type="checkbox"> Remember me</label>
          <a href="#" class="pull-right">Forgot Password?</a>
      </div> -->
      <div *ngIf="adminLoggedIn" class="text-center">
        <a [routerLink]="['/register']" class="nav-link">Create an Account</a>  
      </div>
    </form>
  <!-- <p class="text-center"><a href="register" >Create an Account</a></p> -->
</div>
