
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './guard/auth.guard';
import { 
    OrderListComponent,
    OrderEditComponent,
    OrderResolver,
    OrderItemsEditComponent,
    OrderItemsResolver,
    VendorSalesOrderComponent,
    ShippingEditComponent,
    ShippedListComponent,
    InvoicingEditComponent
  } from './sales/index';
  const routes: Routes = [
    {
        path: 'sales',
        canActivate: [AuthGuard],
        data: { section: 'sales' },
        children: [
          {
            path: 'order-list', component: OrderListComponent,
            canActivate: [AuthGuard]
          },
          {
            path: 'order-edit/:id', component: OrderEditComponent,
            canActivate: [AuthGuard],
            resolve: { dataOrder: OrderResolver }
          },
          {
            path: 'order-items-edit/:id', component: OrderItemsEditComponent,
            canActivate: [AuthGuard],
            resolve: { dataOrder: OrderItemsResolver }
          },
          {
            path: 'vendor-sales-order/:id', component: VendorSalesOrderComponent,
            canActivate: [AuthGuard],
            resolve: { dataOrder: OrderItemsResolver }
          },
          {
            path: 'shipping-edit/:id', component: ShippingEditComponent,
            canActivate: [AuthGuard],
            resolve: { dataOrder: OrderItemsResolver }
          },
          {
            path: 'shipped-list', component: ShippedListComponent,
            canActivate: [AuthGuard]
          },
          {
            path: 'invoicing-edit/:id', component: InvoicingEditComponent,
            canActivate: [AuthGuard],
            resolve: { dataOrder: OrderItemsResolver }
          }
        ]
      }
  ];

  @NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
  })
  export class SalesRoutingModule { }
