export class Transportation {
  id: number;
  activeItem: string;
  company: string;
  address: string;
  city: string;
  stateProvince: string;
  countryRegion: string;
  zipPostalCode: string;
  fullName: string;
  jobTitle: string;
  businessPhone: string;
  faxNumber: string;
  mobileNumber: string;
  eMail: string;
  currencyCode: string;
  termsId: number;
  webPage: string;
  comments: string;
}
