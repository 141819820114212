import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../services/authentication.service';
@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private authService: AuthenticationService
    ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      if (sessionStorage.getItem('currentUser')) {
        // const cUser = JSON.parse(sessionStorage.getItem('currentUser'));
        const user1 = this.authService.getUserInfo();
        if (user1 === null) {
          alert("Please login.")
        }
        // const user1 = JSON.parse(sessionStorage.getItem('currentUser'));
        const paths = state.url.split('/');
        let approval = false;
        if (paths.length > 0) {
          switch (paths[1]) {
            case 'sales':
              approval = user1.sales > 0 ? true : approval; 
              break;
            case 'accounts':
              approval = user1.accounting > 0 ? true : approval; 
              break;
            case 'customers':
              approval = user1.customer > 0 ? true : approval;
              if (paths.length == 2) {
                approval = user1.vendorId ? true : approval;
              }
                // if (paths.length > 2 && paths[2] == 'customer-details' ) {
                //   approval = user1.vendorId > 0 ? true : approval;
                // }
              
              break;
            case 'vendors':
              approval = user1.vendor > 0 ? true : approval;
              break;
            case 'other':
              approval = user1.other > 0 ? true : approval;
              break;
            case 'items':
                approval = user1.items > 0 ? true : approval;
                break;
            case 'products':
                approval = user1.products > 0 ? true : approval;
                break;
            case 'productListings':
                approval = user1.productListing > 0 ? true : approval;
                approval = user1.vendorId && paths.indexOf('listing-setup-list') == -1 ? false : approval;
                break;
            case 'portal':
              approval = user1.reports > 0 ? true : approval;
              break;
            }
        }
        approval = user1.administrationRole ? true : approval;
        return approval;
        // if (state.url == '/register') {
         //   if (cUser.firstName == 'Richard Johnson') {
          //  return true;
          // } 
        // } else {
          // logged in so return true
         // return true;
        // } 
      }
      this.router.navigate(['/login'], { queryParams: { returnUrl: state.url }});
      return false;
    }
}
