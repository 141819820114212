import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { TypeOfAccount } from '../../../models/type-of-account-model';
import { TypeOfAccountService } from '../../../services/type-of-account.service';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
@Component({
  selector: 'app-type-of-account-edit',
  templateUrl: './type-of-account-edit.component.html',
  styleUrls: ['./type-of-account-edit.component.css']
})
export class TypeOfAccountEditComponent implements OnInit {
  title = 'Type of Account';
  newOne = true;
  typeOfAccount: TypeOfAccount;
  typeOfAccountForm: UntypedFormGroup;
  successMessage: string;
  private success = new Subject<string>();
  staticAlertClosed = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private fb: UntypedFormBuilder,
    private typeOfAcctSvc: TypeOfAccountService
  ) { }

  ngOnInit(): void {
    this.typeOfAccountForm = this.fb.group({
      active: [''],
      typeOfAccount1: ['']
    })    
    this.route.data.subscribe(data => {
      if (data.dataTypeOfAccount !== undefined) {
        this.title = 'Type Of Account Edit';
        this.newOne = false;
        this.typeOfAccount = data.dataTypeOfAccount;
        this.fillFormData();
      } else {
        this.title = 'Type Of Account New';
        this.newOne = true;
        this.typeOfAccount = new TypeOfAccount();
        this.typeOfAccountForm.reset();
        this.typeOfAccount.active = 'Active';
        this.typeOfAccount.typeOfAccount1 = '';
        this.fillFormData();
      }
    });
    setTimeout(() => this.staticAlertClosed = true, 20000);
    this.success.subscribe((message) => this.successMessage = message);
    this.success.pipe(
      debounceTime(5000)
    ).subscribe(() => this.successMessage = null);     

  }
  fillFormData() {
    this.typeOfAccountForm.setValue({
      active: this.typeOfAccount.active,
      typeOfAccount1: this.typeOfAccount.typeOfAccount1
    });
  }
  onSubmit() {
    this.typeOfAccount.active = this.typeOfAccountForm.controls.active.value;
    this.typeOfAccount.typeOfAccount1 = this.typeOfAccountForm.controls.typeOfAccount1.value;

    if (this.newOne) {
      this.typeOfAcctSvc.create(this.typeOfAccount).subscribe(data => {
        this.typeOfAccount.accountTypeId =  data.accountTypeId;
        this.newOne = false;
        this.title = 'Type Of Account Edit';
        this.success.next(`Successfully added.`);
      }, error => {
      });
    } else {
      this.typeOfAcctSvc.update(this.typeOfAccount).subscribe(data => {
        this.success.next(`Successfully changed.`);
      }, error => {
      });
    }
  }
  onDelete() {
    if (this.typeOfAccount.accountTypeId) {
      this.typeOfAcctSvc.remove(this.typeOfAccount.accountTypeId).subscribe(data => {
        this.router.navigate(['/other/type-of-account-list']);
      });
    }
  }     
}
