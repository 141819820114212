import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { GstHst } from '../models/gst-hst.model';
const url = environment.kalcorApiUrl;

@Injectable({
  providedIn: 'root'
})
export class GstHstService {

  constructor(private http: HttpClient) { }

  getAll(): Observable<any> {
    return this.http.get(url + 'GstHsts', this.jwt());
  }
  getOne(id: number): Observable<any> {
    return this.http.get(url + 'GstHsts/' +id, this.jwt());
  }
  update(gstHst) {
    return this.http.put(url + 'GstHsts/' + gstHst.id, gstHst, this.jwt());
  }
  create(gstHst): Observable<any> {
    return this.http.post(url + 'GstHsts', gstHst, this.jwt());
  }
  remove(id: number) {
    return this.http.delete(url + 'GstHsts/' + id, this.jwt());
  }

  private jwt() {
    // create authorization header with jwt token
    const currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
    if (currentUser && currentUser.token) {
        const headersInfo = new HttpHeaders({ Authorization: 'Bearer ' + currentUser.token });
        return { headers: headersInfo };
    }
  }
}
