<div id="my-sales-order" *ngIf="reportName=='Sales Order'">
  <app-sales-order
    [title] = "title"
    [order] = "orderSelected"
    [orderItems] = "orderItems2"
    [totalCases] = "totalCases"
    [totalPallets] = "totalPallets"
    [totalGrossLbs] = "totalGrossLbs"
    [totalCaseCost] = "totalCaseCost"
    [totalGrosskg] = "totalGrosskg">
  </app-sales-order>
</div> 
<div id="my-packing-slip" *ngIf="reportName=='Packing Slip'">
  <app-packing-slip
  [title] = "title"
  [order] = "orderSelected"
  [orderItems] = "orderItems2">
</app-packing-slip>
</div> 
<div id="my-shipping" *ngIf="reportName=='Shipping'">
  <app-shipping
    [title] = "title"
    [order] = "orderSelected"
    [items] = "orderItems2"
    [totalCases] = "totalCases"
    [totalPallets] = "totalPallets"
    [totalGrosskg] = "totalGrosskg"
    [totalGrossLbs] = "totalGrossLbs">
  </app-shipping>
</div> 
<div id="my-commercial-invoice" *ngIf="reportName=='Commercial Invoice'">
  <app-sales-order
    [title] = "title"
    [order] = "orderSelected"
    [orderItems] = "orderItems2"
    [totalCases] = "totalCases"
    [totalPallets] = "totalPallets"
    [totalGrossLbs] = "totalGrossLbs"
    [totalCaseCost] = "totalCaseCost"
    [totalGrosskg] = "totalGrosskg">
  </app-sales-order>
</div> 
<div id="my-sales-order-vendor" *ngIf="reportName=='Vendor Sales Order'">
  <app-vendor-sales-order
    [title] = "title"
    [order] = "orderSelected"
    [orderItems] = "orderItems2"
    [totalCases] = "totalCases"
    [totalPallets] = "totalPallets"
    [totalGrossLbs] = "totalGrossLbs"
    [totalCaseCost] = "totalCaseCost"
    [totalGrosskg] = "totalGrosskg"
    [vendorPurchaseCaseCost] = "vendorPurchaseCaseCost"
    [fOBNetVendorCost] = "fOBNetVendorCost">
  </app-vendor-sales-order>
</div> 
<div id="my-invoicing-customer" *ngIf="reportName=='Customer Invoice'">
  <app-invoicing-customer
    [title] = "title"
    [order] = "orderSelected"
    [orderItems] = "orderItems"
    [totalCases] = "totalCases"
    [totalPallets] = "totalPallets"
    [totalGrossLbs] = "totalGrossLbs"
    [totalCaseCost] = "totalCaseCost"
    >
  </app-invoicing-customer>
  
</div> 
<div id="my-invoicing" *ngIf="reportName=='Invoice'">
  <app-invoicing
  [title] = "title"
  [order] = "orderSelected"
  [orderItems] = "orderItems"
  [totalCases] = "totalCases"
  [totalPallets] = "totalPallets"
  [totalGrossLbs] = "totalGrossLbs"
  [totalCaseCost] = "totalCaseCost"
  [totalGrosskg] = "totalGrosskg">
  </app-invoicing>
</div> 
<div *ngIf="reportName==''">
  <h5>{{ title }} </h5>
  <form [formGroup]="orderForm" (ngSubmit)="onSubmit()" novalidate>
    <div class="mb-3 mt-3" *ngIf="itemsLoaded">
      <div class="row g-3 align-items-center">
      <div class="col-lg-4">
          <label for="sales-order-date" class="col-form-label">
              Sales Order Date
          </label>
      </div>
      <div class="col-lg-4">
        <input id="sales-order-date" type="date" formControlName="salesOrderDate" class="form-control" >
      </div>
    </div>
     
      <div class="row g-3 align-items-center">
        <div class="col-lg-4 pt-2">
          <label for="sales-order-number" class="col-form-label">
              Sales Order No.
          </label>
        </div>
        <div class="col-lg-4 pt-2">
          <input id="sales-order-number" type="text" formControlName="salesOrderNo" class="form-control">
        </div>
      </div>

      <div class="row g-3 align-items-center modal-10">
        <div class="col-lg-4 pt-2">
          <label for="purchase-order" class="col-form-label">
              Purchase Order
          </label>
        </div>
        <div class="col-lg-4 pt-2">
          <input id="purchase-order" type="text" formControlName="purchaseOrder" class="form-control">
        </div>
      </div>

      <div class="row g-3 align-items-center">
        <div class="col-lg-4 pt-2">
          <label for="bill-to">
              Bill to
          </label>
        </div>
        <div class="col-lg-8 pt-2">
          <select formControlName="billToId" class="form-control">
            <option [value]=""></option>
            <option *ngFor="let c of customer" [value]="c.id">{{ c.company }}</option>
          </select>
        </div>
      </div>

      <div class="row g-3 align-items-center">
        <div class="col-lg-4 pt-2">
        <label for="vendor">
            Vendor
        </label>
        </div>
        <div class="col-lg-8 pt-2">
        <select formControlName="vendorId" class="form-control">
            <option [value]=""></option>
            <option *ngFor="let v of vendor" [value]="v.id">{{ v.company }}</option>
        </select>
      </div>
    </div>

      <div class="row g-3 align-items-center">
        <div class="col-lg-4 pt-2">
          <label for="ship-to">
              Ship To
          </label>
        </div>
        <div class="col-lg-8 pt-2">
          <select formControlName="shipToId" class="form-control">
              <option [value]=""></option>
              <option *ngFor="let s of shipTo" [value]="s.id">{{ s.company }}</option>
          </select>   
        </div>   
      </div>

      <div class="row g-3 align-items-center">
        <div class="col-lg-4 pt-2">
          <label for="ship-to">
              Billing Agent
          </label>
        </div>
        <div class="col-lg-8 pt-2">
          <select formControlName="billingAgentId" class="form-control">
              <option [value]=""></option>
              <option *ngFor="let b of billingAgent" [value]="b.id">{{ b.billingAgentName }}</option>
          </select>   
        </div>     
      </div>

      <div class="col-lg-8 py-4">
        <h5>SHIPPING DATES/TYPES</h5>
      </div>

      <div class="row g-3 align-items-center">
        <div class="col-lg-4 pt-2">
          <label for="shipping-date">
              Shipment Date
          </label>
        </div>
        <div class="col-lg-4 pt-2">
          <input id="shipping-date" type="date" formControlName="shipmentDate" class="form-control">
        </div>    
      </div>

      <div class="row g-3 align-items-center">
        <div class="col-lg-4 pt-2">
        <label for="delivered-date-only">
            Delivered Date
        </label>
      </div>
      <div class="col-lg-4 pt-2">
        <input id="delivered-date-only" type="date" formControlName="deliveredDate" class="form-control">
      </div>
      <div class="col-lg-4 pt-2 text-end">
        <label for="delivered-date-time">
          Delivered Appt
      </label>
      </div>
      <div class="col-lg-4 pt-2">
      <input id="delivered-date-time" type="time" formControlName="deliveredTime" class="form-control">
      </div>
      </div>

      <div class="row g-3 align-items-center">
        <div class="col-lg-4 pt-2">
          <label for="app-confirmed">
              App Confirmed
          </label>
        </div>
        <div class="col-lg-4 pt-2">
          <select id="app-confirmed" formControlName="appConfirmed" class="form-control">
              <option value="Yes">Yes</option>
              <option value="No">No</option>
          </select>
        </div>
      </div>

      <div class="row g-3 align-items-center">
        <div class="col-lg-4 pt-2">
          <label for="delivery-type">
            Delivery Type
        </label>
        </div>
        <div class="col-lg-8 pt-2">
          <select id="delivery-type" formControlName="freightTypeId" class="form-control">
              <option *ngFor="let f of freightType" [value]="f.id">{{ f.freightType }}</option>
          </select>
        </div>
      </div>

      <div class="row g-3 align-items-center">
        <div class="col-lg-4 pt-2">
          <label for="vendor-pickup">
              Vendor Pick Up
          </label>
        </div>
        <div class="col-lg-8 pt-2">
          <select id="vendor-pickup" formControlName="vendorPickupId" class="form-control">
              <option [value]=""></option>
              <option *ngFor="let p of pickup" [value]="p.id">{{ p.company }}</option>
          </select>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-4 pt-2">Shipping Lane</div>
        <div class="col-lg-3 pt-2">Shipment Date</div>
        <div class="col-lg-3 pt-2">Delivery Date</div>
        <div class="col-lg-2 pt-2">Starting</div>
        <div class="col-lg-2 pt-2">Ending</div>
        <div class="col-lg-2 pt-2">Transport Co.</div>
        <div class="col-lg-2 pt-2">Type of Freight</div>
        <div class="col-lg-2 pt-2">Cost</div>
        <div class="col-lg-2 pt-2">FSC %</div>
        <div class="col-lg-2 pt-2">Total Cost</div>
      </div>

      <div class="row">
          <div class="col-lg-4 pt-0"></div>
          <div class="col-lg-3 pt-2">
          <input id="shipping-date1" type="date" formControlName="shipDate1" class="form-control">
          </div>
          <div class="col-lg-3 pt-2">
          <input id="delivery-date1" type="date" formControlName="deliveryDate1" class="form-control">
          </div>
          <div class="col-lg-2 pt-2">
          <select id="shipping-lane1" formControlName="shippingLane1" class="form-control">
              <option [value]=""></option>
              <option *ngFor="let l1 of location" [value]="l1.id">{{ l1.location }}</option>
          </select>
          </div>
          <div class="col-lg-2 pt-2">
          <select id="shipping-laneEnd1" formControlName="shippingLaneEnd1" class="form-control">
            <option [value]=""></option>
            <option *ngFor="let l1 of location" [value]="l1.id">{{ l1.location }}</option>
          </select>
          </div>
          <div class="col-lg-2 pt-2">
          <select formControlName="transportCo1" class="form-control">
              <option [value]=""></option>
              <option *ngFor="let t1 of transport" [value]="t1.id">{{ t1.company }}</option>
          </select>
        </div>
        <div class="col-lg-2 pt-2">
          <select formControlName="freightType1" class="form-control">
            <option [value]=""></option>
            <option value="CND">CND Freight Cost</option>
            <option value="USD">USD Freight Cost</option>
          </select>
        </div>
        <div class="col-lg-2 pt-2">
          <input type="text" formControlName="freightCost1" class="form-control">
        </div>
        <div class="col-lg-2 pt-2">
          <input type="text" formControlName="freightCostSales1" class="form-control">
        </div>
        <div class="col-lg-2 pt-2">
          <input type="text" formControlName="freightTotalCost1" class="form-control">
        </div>
      </div>

      <div class="row">
        <div class="col-lg-4 pt-0"></div>
        <div class="col-lg-3 pt-2">
        <input type="date" formControlName="shipDate2" class="form-control">
        </div>
        <div class="col-lg-3 pt-2">
        <input type="date" formControlName="deliveryDate2" class="form-control">
        </div>
        <div class="col-lg-2 pt-2">
        <select formControlName="shippingLane2" class="form-control">
          <option [value]=""></option>
          <option *ngFor="let l2 of location" [value]="l2.id">{{ l2.location }}</option>
        </select>
        </div>
        <div class="col-lg-2 pt-2">
        <select formControlName="shippingLaneEnd2" class="form-control">
          <option [value]=""></option>
          <option *ngFor="let l2 of location" [value]="l2.id">{{ l2.location }}</option>
        </select>
        </div>
        <div class="col-lg-2 pt-2">
        <select formControlName="transportCo2" class="form-control">
          <option [value]=""></option>
          <option *ngFor="let t2 of transport" [value]="t2.id">{{ t2.company }}</option>
        </select>
        </div>
        <div class="col-lg-2 pt-2">
        <select formControlName="freightType2" class="form-control">
          <option [value]=""></option>
          <option value="CND">CND Freight Cost</option>
          <option value="USD">USD Freight Cost</option>
        </select>
        </div>
        <div class="col-lg-2 pt-2">
        <input type="text" formControlName="freightCost2" class="form-control">
        </div>
        <div class="col-lg-2 pt-2">
        <input type="text" formControlName="freightCostSales2" class="form-control">
        </div>
        <div class="col-lg-2 pt-2">
        <input type="text" formControlName="freightTotalCost2" class="form-control">
        </div>
      </div>

      <div class="row">
        <div class="col-lg-4 pt-0"></div>
        <div class="col-lg-3 pt-2">
        <input type="date" formControlName="shipDate3" class="form-control">
        </div>
        <div class="col-lg-3 pt-2">
        <input type="date" formControlName="deliveryDate3" class="form-control">
        </div>
        <div class="col-lg-2 pt-2">
        <select formControlName="shippingLane3" class="form-control">
          <option [value]=""></option>
          <option *ngFor="let l3 of location" [value]="l3.id">{{ l3.location }}</option>
        </select>
        </div>
        <div class="col-lg-2 pt-2">
        <select formControlName="shippingLaneEnd3" class="form-control">
          <option [value]=""></option>
          <option *ngFor="let l3 of location" [value]="l3.id">{{ l3.location }}</option>
        </select>
        </div>
        <div class="col-lg-2 pt-2">
        <select formControlName="transportCo3" class="form-control">
          <option [value]=""></option>
          <option *ngFor="let t3 of transport" [value]="t3.id">{{ t3.company }}</option>
        </select>
       </div>
       <div class="col-lg-2 pt-2">
        <select formControlName="freightType3" class="form-control">
          <option [value]=""></option>
          <option value="CND">CND Freight Cost</option>
          <option value="USD">USD Freight Cost</option>
        </select>
      </div>
      <div class="col-lg-2 pt-2">
        <input type="text" formControlName="freightCost3" class="form-control">
      </div>
      <div class="col-lg-2 pt-2">
        <input type="text" formControlName="freightCostSales3" class="form-control">
      </div>
      <div class="col-lg-2 pt-2">
        <input type="text" formControlName="freightTotalCost3" class="form-control">
      </div>
      </div>

      <div class="row">
        <div class="col-lg-4 pt-0"></div>
        <div class="col-lg-3 pt-2">
        <input type="date" formControlName="shipDate4" class="form-control">
        </div>
        <div class="col-lg-3 pt-2">
        <input type="date" formControlName="deliveryDate4" class="form-control">
        </div>
        <div class="col-lg-2 pt-2">
        <select formControlName="shippingLane4" class="form-control">
          <option [value]=""></option>
          <option *ngFor="let l4 of location" [value]="l4.id">{{ l4.location }}</option>
        </select>
      </div>
      <div class="col-lg-2 pt-2">
        <select formControlName="shippingLaneEnd4" class="form-control">
          <option [value]=""></option>
          <option *ngFor="let l4 of location" [value]="l4.id">{{ l4.location }}</option>
        </select>
      </div>
      <div class="col-lg-2 pt-2">
        <select formControlName="transportCo4" class="form-control">
          <option [value]=""></option>
          <option *ngFor="let t4 of transport" [value]="t4.id">{{ t4.company }}</option>
        </select>
      </div>
      <div class="col-lg-2 pt-2">
        <select formControlName="freightType4" class="form-control">
          <option [value]=""></option>
          <option value="CND">CND Freight Cost</option>
          <option value="USD">USD Freight Cost</option>
        </select>
      </div>
      <div class="col-lg-2 pt-2">
        <input type="text" formControlName="freightCost4" class="form-control">
      </div>
      <div class="col-lg-2 pt-2">
        <input type="text" formControlName="freightCostSales4" class="form-control">
      </div>
      <div class="col-lg-2 pt-2">
        <input type="text" formControlName="freightTotalCost4" class="form-control">
      </div>
      </div>

      <div class="row">
        <div class="col-lg-4 pt-0"></div>
        <div class="col-lg-3 pt-2">
        <input type="date" formControlName="shipDate5" class="form-control">
      </div>
      <div class="col-lg-3 pt-2">
        <input type="date" formControlName="deliveryDate5" class="form-control">
      </div>
      <div class="col-lg-2 pt-2">
        <select formControlName="shippingLane5" class="form-control">
          <option [value]=""></option>
          <option *ngFor="let l5 of location" [value]="l5.id">{{ l5.location }}</option>
        </select>
      </div>
      <div class="col-lg-2 pt-2">
        <select formControlName="shippingLaneEnd5" class="form-control">
          <option [value]=""></option>
          <option *ngFor="let l5 of location" [value]="l5.id">{{ l5.location }}</option>
        </select>
      </div>
      <div class="col-lg-2 pt-2">
        <select formControlName="transportCo5" class="form-control">
          <option [value]=""></option>
          <option *ngFor="let t5 of transport" [value]="t5.id">{{ t5.company }}</option>
        </select>
      </div>
      <div class="col-lg-2 pt-2">
        <select formControlName="freightType5" class="form-control">
          <option [value]=""></option>
          <option value="CND">CND Freight Cost</option>
          <option value="USD">USD Freight Cost</option>
        </select>
      </div>
      <div class="col-lg-2 pt-2">
        <input type="text" formControlName="freightCost5" class="form-control">
      </div>
      <div class="col-lg-2 pt-2">
        <input type="text" formControlName="freightCostSales5" class="form-control">
      </div>
      <div class="col-lg-2 pt-2">
        <input type="text" formControlName="freightTotalCost5" class="form-control">
      </div>
      </div>

      <div class="row">
        <div class="col-lg-4 pt-2">
          <label for="warehouse">
              Warehouse
          </label>
        </div>
        <div class="col-lg-8 pt-2">
          <select id="warehouse" formControlName="whsid" class="form-control">
              <option *ngFor="let w of wh" [value]="w.id">{{ w.company }}</option>
          </select>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-4 pt-2">
          <label for="order-status">
              Order Status
          </label>
        </div>
        <div class="col-lg-4 pt-2">
          <select id="order-status" formControlName="orderStatus" class="form-control">
              <option value="Sales Order">Sales Order</option>
              <option value="Shipped">Shipped</option>
              <option value="Invoiced">Invoiced</option>
              <option value="Closed">Closed</option>
          </select>
        </div>
      </div>   

      <div class="row">
        <div class="col-lg-4 pt-2">
          <label for="order-type">
              Order Type
          </label>
        </div>
        <div class="col-lg-4 pt-2">
          <select id="order-type" formControlName="orderType" class="form-control">
              <option value="Retail">Retail</option>
              <option value="Food Service">Food Service</option>
              <option value="Stock">Stock</option>
          </select>
        </div>
      </div>  

      <div class="row">
        <div class="col-lg-4 pt-2">
          <label for="pallet-weight">
            Pallet Weight
          </label>
        </div>
        <div class="col-lg-4 pt-2">
          <input id="pallet-weight" type="text" formControlName="palletGrossLbs" class="form-control">
        </div>
      </div>

    <div class="row">
      <div class="col-lg-4 pt-2">
          <label for="notes">
              Notes
          </label>
        </div>
        <div class="col-lg-8 pt-2">
          <textarea id="order-type" formControlName="notes" class="form-control">
          </textarea>
        </div>
      </div>

      <div class="row g-0 bg-primary text-white mt-3">
        <div class="col-lg-2 pt-2 px-2">
          <label class="bg-primary text-white">
            ORDER CODE
          </label>
        </div>
        <div class="col-lg-6 pt-2 px-2">
          <label class="bg-primary text-white">
            BRAND/CATEGORY
          </label>
        </div>
        
        <div class="col-lg-8 pt-2 px-2">
          <label class="bg-primary text-white">
            ITEM DESCRIPTION
          </label>
        </div>
        
        <div class="col-lg-2 pt-2 px-2">
          <label class="bg-primary text-white">
            SIZE
          </label>
        </div>
        <div class="col-lg-2 pt-2 px-2">
          <label class="text-white text-end w-100">
            CASES
          </label>
        </div>
        
        <div class="col-lg-2 pt-2 px-2">
          <label class="bg-primary text-white text-end w-100">
            CASE COST
          </label>
        </div>
        <div class="col-lg-2 pt-2 px-2">
          <label class="text-white text-end w-100">
            EXT CASE COST
          </label>
        </div>
      </div>

      <div *ngFor="let item of selectedListing; let i = index;">
        <div class="row g-0" [ngClass]="{'gray-out': grayRow[i]==='G', 'grid-stripe': grayRow[i]==='S'}">
          <div class="col-lg-2 pt-2 px-2">
            {{ item?.orderCode}}
          </div>
          <div *ngIf="item.brandCategory !== undefined" class="col-lg-6 pt-2 px-2">
            {{ item?.brandCategory }}
          </div>
          <div *ngIf="item.brandCategory === undefined" class="col-lg-6 pt-2 px-2">
            {{ item?.brand }} / {{ item?.category}}
          </div>
          <div class="col-lg-8 pt-2 px-2">
            {{ item?.itemDescription}}
          </div>
          <div class="col-lg-2 pt-2 px-2">
            {{ item?.size }}
          </div>
          <div class="col-lg-2 pt-2 px-2">
            <input class="w-75 float-end text-end" [(ngModel)]="itemsEntered[i].cases"
              [ngModelOptions]="{standalone: true}" (keyup)="caseChange($event, i)">
          </div>
          <div class="col-lg-2 pt-2 px-2 text-end">
            {{ itemsEntered[i]?.caseCost | currency }}
          </div>
          <div class="col-lg-2 pt-2 px-2 text-end">
            {{ itemsEntered[i]?.extCaseCost | currency }}
          </div>
        </div>
        
        <div class="row g-0" [ngClass]="{'gray-out': grayRow[i]==='G', 'grid-stripe': grayRow[i]==='S'}">
          <div class="col-lg-4 pt-0 pb-2 px-2">
            <span class="pr-2">CS Gross Lbs</span>
            {{ item?.csGrossLbs | number : '1.2-2' }}
          </div>
          <div class="col-lg-4 pt-0 pb-2 px-2">
            <span class="pr-2">Product Type</span>
            {{ item?.productType }}
          </div>
          <div class="col-lg-2 pt-0 pb-2 px-2">
            <span class="pr-2">TI/HI</span>
            {{ item?.ti }} x {{ item?.hi }}
          </div>
          <div class="col-lg-4 pt-0 pb-2 px-2">
            <span class="pr-2">CS per Pallet</span>
            {{ item?.csPerPallet }}
          </div>
      
        </div>
      </div>
    <div class="row g-0 text-white mt-3 bg-primary">
      <div class="col-lg-6 pt-0 px-2">
        <span class="pr-2">TOTAL CASES</span>
        {{ totalCases | number : '1.0-0' }}
      </div>
      <div class="col-lg-3 pt-0 px-2">
        <span class="pr-2">TOTAL PALLETS</span>
        {{ totalPallets |  number : '1.0-0' }}
      </div>

      <div class="col-lg-2 pt-0 px-2">
        CONFIRM PALLETS
      </div>

      <div class="col-lg-1 pt-0 px-2">
        <input id="confirm-pallets" type="text" formControlName="palletCount" class="form-control" >
      </div>
      
      <div class="col-lg-4 pt-0 px-2 text-end">
        <span class="pr-2">TOTAL NET</span>
        {{ totalNetLbs | number : '1.2-2'  }}
        <span class="pr-3"> lbs</span>
        {{ totalNetkg | number : '1.2-2'  }}
        <span class="pr-2"> kg</span>
      </div>

      <div class="col-lg-4 pt-0 px-2 text-end">
        <span class="pr-2">TOTAL GROSS</span>
        {{ totalGrossLbs | number : '1.2-2'  }}
        <span class="pr-3"> lbs</span>
        {{ totalGrosskg | number : '1.2-2'  }}
        <span class="pr-2"> kg</span>
      </div>

      <div class="col-lg-4 pt-0 px-2 text-end">
        <span class="pr-2">TOTAL CASE COST</span>
        {{ totalCaseCost | currency }}
      </div>
    </div>

    <div class="row">
      <div class="col-4 pr-0">
        <button type="submit" class="btn btn-primary mt-3 w-100">Save Order</button>
      </div>
      <div class="col-4 pr-0">
        <button type="button" class="btn btn-primary mt-3 w-100" [ngClass]="{'disabled': !shipOrderBtn}" [disabled]="!shipOrderBtn"
          (click)="editShippingItems()">Ship Order</button>
      </div>
      <div class="col-4 pr-0">
        <button type="button" class="btn btn-primary mt-3 w-100" [ngClass]="{'disabled': !invoiceOrderBtn}" [disabled]="!invoiceOrderBtn"
        (click)="editInvoice()">Invoice Order</button>
      </div>
    </div>

    <div class="row">
      <div class="col-4 pr-0">
        <button type="button" class="btn btn-warning mt-3 w-100" [ngClass]="{'disabled': !salesOrderPrintBtn}" [disabled]="!salesOrderPrintBtn"
        (click)="printProcess('Sales Order')">Print Sales Order</button>
      </div>
    
      <div class="col-4 pr-0">
        <button type="button" class="btn btn-warning mt-3 w-100" [ngClass]="{'disabled': !slipPrintBtn}" [disabled]="!slipPrintBtn"
        (click)="printProcess('Packing Slip')" >Print Packing Slip</button>
      </div>
      <div class="col-4 pr-0">
        <button type="button" class="btn btn-warning mt-3 w-100" [ngClass]="{'disabled': !shipPrintBtn}" [disabled]="!shipPrintBtn"
        (click)="printProcess('Shipping')">Print Shipping</button>
      </div>
      <div class="col-4 pr-0">
        <button type="button" class="btn btn-warning mt-3 w-100" [ngClass]="{'disabled': !invoicePrintBtn}" [disabled]="!invoicePrintBtn"
        (click)="printProcess('Commercial Invoice')">Print Commercial Invoice</button>
      </div>
      <div class="col-4 pr-0">
        <button type="button" class="btn btn-warning mt-3 w-100" [ngClass]="{'disabled': !poPrintBtn}" [disabled]="!poPrintBtn"
        (click)="showDialog()">Print Purchase Order</button>
      </div>
      <div class="col-4 pr-0">
        <button type="button" class="btn btn-warning mt-3 w-100" [ngClass]="{'disabled': !invoicePrintBtn}" [disabled]="!invoicePrintBtn"
        (click)="printProcess('Invoice')">Print Invoice</button>
      </div>
      <div class="col-4 pr-0">
        <button type="button" class="btn btn-warning mt-3 w-100" [ngClass]="{'disabled': !invoicePrintBtn}" [disabled]="!invoicePrintBtn"
        (click)="printProcess('Customer Invoice')">Print Customer Invoice</button>
      </div>
    </div>
    
    <div class="row">
      <div class="col-4 pr-0">
        <button type="button" class="btn btn-secondary mt-3 w-100" (click)="onDelete()" [ngClass]="{'disabled': !deleteOrderBtn}" [disabled]="!deleteOrderBtn"
        >Delete</button>
      </div>
    </div>
  </div>
  <ngb-alert *ngIf="successMessage" [type]="alertType" (close)="successMessage = null">{{ successMessage }}</ngb-alert>
  </form>
</div>
<div id="my-confirm-page" *ngIf="reportName=='Confirm'">
  <app-confirm-dialog 
    (returnDialog) = "returnDialog($event)">
  </app-confirm-dialog>
</div> 