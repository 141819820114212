import { Component, OnInit } from '@angular/core';
import { BillingAgentService } from 'src/app/services/billing-agent.service';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { SorterService } from 'src/app/services/sorter.service';

@Component({
  selector: 'app-billing-agent-list',
  templateUrl: './billing-agent-list.component.html',
  styleUrls: ['./billing-agent-list.component.scss']
})
export class BillingAgentListComponent implements OnInit{
  billingAgent: any;
  successMessage: string;
  private success = new Subject<string>();
  alertType = "success";
  staticAlertClosed = false;

  constructor(private router: Router,
    private billingAgentService: BillingAgentService,
    private sortSvc: SorterService
  ) {}

  ngOnInit(): void {
    this.billingAgentService.getAll().subscribe(data => {
      this.billingAgent = data;
      this.billingAgent = this.sortSvc.sorter(this.billingAgent, 'billingAgentName');
    }, error => {
      this.successMessage = 'Unable to process request';
      this.alertType = 'danger';
    });
    setTimeout(() => this.staticAlertClosed = true, 20000);
    this.success.subscribe((message) => this.successMessage = message);
    this.success.pipe(
      debounceTime(5000)
    ).subscribe(() => this.successMessage = null);    
  }
  onRowClicked(rowSelected) {
    this.router.navigate(['/other/billing-agent-edit', rowSelected.id])
  }

}
