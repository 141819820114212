import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Customer } from '../../../../models/customer.model';
import { Term } from '../../../../models/term-model';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { CustomerService } from '../../../../services/customer.service';
import { TermsService } from '../../../../services/terms.service';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { Region } from 'src/app/models/region-model';
import { RegionService } from 'src/app/services/region.service';
import { TypeOfAccount } from 'src/app/models/type-of-account-model';
import { TypeOfAccountService } from 'src/app/services/type-of-account.service';
import { CustomerEnd } from 'src/app/models/customer-end-model';
import { CustomerEndService } from 'src/app/services/customer-end.service';
@Component({
  selector: 'app-customer-create',
  templateUrl: './customer-create.component.html',
  styleUrls: ['./customer-create.component.scss']
})
export class CustomerCreateComponent implements OnInit {
  customer: Customer;
  term: Term[] = [];
  customerForm: UntypedFormGroup;
  typeOfAcct: TypeOfAccount[];
  region: Region[];
  customerEnd: CustomerEnd[];
  successMessage: string;
  retailRegion = " ";
  retailAccountType = " ";
  private success = new Subject<string>();
  staticAlertClosed = false;
  constructor(
    private fb: UntypedFormBuilder,
    private customerSvc: CustomerService,
    private termsSvc: TermsService,
    private typeOfAcctSvc: TypeOfAccountService,
    private regionSvc: RegionService,
    private customerEndSvc: CustomerEndService,
    private router: Router
  ) {
    this.customer = new Customer();
  }

  ngOnInit() {
    this.customerForm = this.fb.group({
      accountNo: [''],
      activeItem:  ['', Validators.required],
      address: [''],
      billing: [''],
      bucketsId: [''],
      businessPhone: [''],
      city: [''],
      clearing: [''],
      comments: [''],
      commission: [''],
      company: ['', Validators.required],
      countryRegion: [''],
      currencyCode: [''],
      eMail: [''],
      exchange: [''],
      faxNumber: [''],
      freightCanada: [''],
      freightUsaCanada: [''],
      fullAddress: [''],
      fullName: [''],
      jobTitle: [''],
      marketing: [''],
      mobileNumber: [''],
      orderType: [''],
      paymentTermsTerms: [''],
      stateProvince: [''],
      storage: [''],
      termsId: [''],
      tollLocalCog: [''],
      vendorId: [''],
      webPage: [''],
      zipPostalCode: [''],
      latitude: [''],
      longitude: [''],
      customerEndId: [''],
      
    });
    this.termsSvc.getAll().subscribe(data2 => {
      this.term = data2;
      this.typeOfAcctSvc.getAll().subscribe(typeOfAcct => {
        this.typeOfAcct = typeOfAcct;
          this.regionSvc.getAll().subscribe(region => {
            this.region = region;
            this.customerEndSvc.getAll().subscribe(customerEnd => {
              this.customerEnd = customerEnd;
            })
          });
      });
    });
    setTimeout(() => this.staticAlertClosed = true, 20000);
    this.success.subscribe((message) => this.successMessage = message);
    this.success.pipe(
      debounceTime(5000)
    ).subscribe(() => this.successMessage = null);
  }
  onSubmit() {
    this.customer.accountNo = this.customerForm.controls.accountNo.value;
    this.customer.activeItem = this.customerForm.controls.activeItem.value;
    this.customer.address = this.customerForm.controls.address.value;
    this.customer.billing = this.customerForm.controls.billing.value;
    this.customer.bucketsId = this.customerForm.controls.bucketsId.value;
    this.customer.businessPhone = this.customerForm.controls.businessPhone.value;
    this.customer.city = this.customerForm.controls.city.value;
    this.customer.clearing = this.customerForm.controls.clearing.value;
    this.customer.comments = this.customerForm.controls.comments.value;
    this.customer.commission = this.customerForm.controls.commission.value;
    this.customer.company = this.customerForm.controls.company.value;
    this.customer.countryRegion = this.customerForm.controls.countryRegion.value;
    this.customer.currencyCode = this.customerForm.controls.currencyCode.value;
    this.customer.eMail = this.customerForm.controls.eMail.value;
    this.customer.exchange = this.customerForm.controls.exchange.value;
    this.customer.faxNumber = this.customerForm.controls.faxNumber.value;
    this.customer.freightCanada = this.customerForm.controls.freightCanada.value;
    this.customer.freightUsaCanada = this.customerForm.controls.freightUsaCanada.value;
    this.customer.fullAddress = this.customerForm.controls.fullAddress.value;
    this.customer.fullName = this.customerForm.controls.fullName.value;
    this.customer.jobTitle = this.customerForm.controls.jobTitle.value;
    this.customer.marketing = this.customerForm.controls.marketing.value;
    this.customer.mobileNumber = this.customerForm.controls.mobileNumber.value;
    this.customer.orderType = this.customerForm.controls.orderType.value;
    this.customer.paymentTermsTerms = this.customerForm.controls.paymentTermsTerms.value;
    this.customer.stateProvince = this.customerForm.controls.stateProvince.value;
    this.customer.storage = this.customerForm.controls.storage.value;
    this.customer.termsId = this.customerForm.controls.termsId.value;
    this.customer.tollLocalCog = this.customerForm.controls.tollLocalCog.value;
    this.customer.vendorId = this.customerForm.controls.vendorId.value;
    this.customer.webPage = this.customerForm.controls.webPage.value;
    this.customer.zipPostalCode = this.customerForm.controls.zipPostalCode.value;
    this.customer.latitude = this.customerForm.controls.latitude.value;
    this.customer.longitude = this.customerForm.controls.longitude.value;
    this.customer.customerEndId = this.customerForm.controls.customerEndId.value;

    
    this.customerSvc.create(this.customer).subscribe(data => {
      const id = data.id;
      this.router.navigate(['/customers/customer-details', id]);
    }, error => {
    });
  }
}
