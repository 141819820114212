<h5>User List </h5>
<table class="table table-striped" style="overflow-x:scroll;">
    <thead>
    <tr>
      <th scope="col">User Name</th>
      <th scope="col">First Name</th>
      <th scope="col">Last Name</th>
      <th scope="col">Department</th>
      <th scope="col">Address</th>
      <th scope="col">City</th>
      <th scope="col">Email</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let u of users | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize" (click)="onRowClicked(u)">
      <td>{{ u.userinitials }}</td>
      <td>{{ u.firstName }}</td>
      <td>{{ u.lastName }}</td>
      <td>{{ u.department }}</td>
      <td>{{ u.address }}</td>
      <td>{{ u.city }}</td>
      <td>{{ u.email }}</td>
    </tr>
    </tbody>
  </table>
  <div *ngIf="users!==undefined">
    <ngb-pagination [(page)]="page" [pageSize]="pageSize" [collectionSize]="users.length">
    </ngb-pagination>
  </div>