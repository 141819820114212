import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { VendorService } from '../../services/vendor.service';
import { BrokerService } from '../../services/broker.service';
import { CustomerService } from '../../services/customer.service';
import { Vendor } from '../../models/vendor-model';
import { Broker } from '../../models/broker.model';
import { Customer } from '../../models/customer.model';
import { BucketService } from '../../services/bucket.service';
import { Bucket } from '../../models/bucket.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-portal',
  templateUrl: './portal.component.html',
  styleUrls: ['./portal.component.scss']
})
export class PortalComponent implements OnInit {
  dashboard = false;
  sales = false;
  customClearing = false;
  accounting = false;
  customer = false;
  vendor = false;
  Other = false;
  items = false;
  products = false;
  productListing = false;
  projections = false;
  reports = false;
  portal = false;
  customerId: number;
  vendorId: number;
  brokerId: number;
  customers: Customer[];
  vendors: Vendor[];
  brokers: Broker[];
  yearSelected: string;
  monthSelected: string[];
  vendorSelected: number;
  customerSelected: number;
  brokerSelected: number;
  bucketSelected: string;
  typeSelected: string;
  OrderTypeSelected: string;
  years = [];
  yearschecked = [];
  buckets: Bucket[];
  lockVendor = false;
  months = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];
  monthsChecked = [];
  quarter1Checked = false;
  quarter2Checked = false;
  quarter3Checked = false;
  quarter4Checked = false;
  combine = false;
  constructor(
    private router: Router,
    private customerService: CustomerService,
    private brokerService: BrokerService,
    private bucketService: BucketService,
    private vendorService: VendorService 
  ) { }

  ngOnInit() {
    // this.bucketService.getAll().subscribe(data => this.buckets = data);
    this.bucketService.getAll().subscribe(data => {
      this.buckets = data;
      this.buckets.sort((a, b) => { 
        if (a.buckets > b.buckets) {
          return 1;
        }

        if (a.buckets < b.buckets) {
          return -1;
        }
        return 0;
      });
    });

    this.getYears();
    this.initMonths();
    this.getUserInfo();
  }
  getYears() {
    const today = new Date();
    const endYear = today.getFullYear() + 1;
    for ( let loopYear = 2020; loopYear < endYear; loopYear++) {
      this.years.push(loopYear);
      this.yearschecked.push(false);
    }
  }
  initMonths() {
    for (let index = 0; index < 12; index++) {
      this.monthsChecked.push(false);
    }
  }
  getUserInfo() {
    const user = JSON.parse(sessionStorage.getItem('currentUser'));
    if (user === null) { return};
    this.dashboard = user.dashboard ? true : false;
    this.sales = user.sales ? true : false;
    this.customClearing = user.customClearing ? true : false;
    this.accounting = user.accounting ? true : false;
    this.customer = user.customer ? true : false;
    this.vendor = user.vendor ? true : false;
    this.Other = user.Other ? true : false;
    this.items = user.items ? true : false;
    this.products = user.products ? true : false;
    this.productListing = user.productListing ? true : false;
    this.projections = user.projections ? true : false;
    this.reports = user.reports ? true : false;
    this.portal = user.portal ? true : false;
    this.customerId = user.customerId ? user.customerId : 0;
    this.vendorId = user.vendorId ? user.vendorId : 0;
    this.brokerId = user.brokerId ? user.brokerId : 0;
    if (this.reports) {
      if (this.vendorId) {
        this.vendorService.getOne(this.vendorId).subscribe(data => {
          this.vendors = [];
          this.vendors.push(data);
          if (this.vendors.length > 0) {
              this.lockVendor = true;
              this.vendorSelected = this.vendors[0].id;
          }
        });
      } else {
        this.vendorService.getCompany().subscribe(data => this.vendors = data);
      }
      if (this.customerId) {
        this.customerService.getOne(this.customerId).subscribe(data => this.customers = data);
      } else {
        this.customerService.getCompany().subscribe(data => this.customers = data);
      }

      if (this.brokerId) {
        this.brokerService.getOne(this.brokerId).subscribe(data => this.brokers = data);
      } else {
        this.brokerService.getCompany().subscribe(data => this.brokers = data);
      }
    }
  }
  run() {
    let yearCount = 0;
    for (let index = 0; index < 5; index++) {
      if (this.yearschecked[index]) {
        yearCount++;
        this.yearSelected = this.years[index].toString();
      }
    }
    if (yearCount == 0) {
      alert('Select Year');
      return;
    }
    if (yearCount > 1) {
      alert('Select only 1 year');
      return;
    }


    if (this.vendorSelected === undefined) {
      if (this.bucketSelected != 'Sales Overview by Vendor') {
        alert('Select Vendor');
        return;
      }
    }

    if (this.typeSelected === undefined || !this.typeSelected) {
      alert('Select Credit Type');
      return;
    }
    
    if (this.OrderTypeSelected === undefined || !this.OrderTypeSelected) {
      alert('Select Order Type');
      return;
    }

    if (this.bucketSelected === undefined) {
      alert('Select Bucket');
      return;
    }

    if (this.quarter1Checked) {
      this.combine = true;
      this.monthsChecked[0] = true;
      this.monthsChecked[1] = true;
      this.monthsChecked[2] = true;
    }
    if (this.quarter2Checked) {
      this.combine = true;
      this.monthsChecked[3] = true;
      this.monthsChecked[4] = true;
      this.monthsChecked[5] = true;
    }
    if (this.quarter3Checked) {
      this.combine = true;
      this.monthsChecked[6] = true;
      this.monthsChecked[7] = true;
      this.monthsChecked[8] = true;
    }

    if (this.quarter4Checked) {
      this.combine = true;
      this.monthsChecked[9] = true;
      this.monthsChecked[10] = true;
      this.monthsChecked[11] = true;
    }

    if (this.monthsChecked.findIndex(mo => mo) == -1) {
      this.combine = true;
      this.monthsChecked[0] = true;
      this.monthsChecked[1] = true;
      this.monthsChecked[2] = true;
      this.monthsChecked[3] = true;
      this.monthsChecked[4] = true;
      this.monthsChecked[5] = true;
      this.monthsChecked[6] = true;
      this.monthsChecked[7] = true;
      this.monthsChecked[8] = true;
      this.monthsChecked[9] = true;
      this.monthsChecked[10] = true;
      this.monthsChecked[11] = true;
    }


    let monthCount = 0;
    this.monthSelected = [];
    for (let index = 0; index < 12; index++) {
      if (this.monthsChecked[index]) {
        monthCount++;
        const mon = index + 1;
        this.monthSelected.push(mon + '');
      }
    }



    let customerName: string;
    if (this.customerSelected) {
      const index = this.customers.findIndex(cust => cust.id == this.customerSelected);
      if (index > -1) {
        customerName = this.customers[index].company;
      }
    }

    let vendorName: string;
    if (this.vendorSelected) {
      const index = this.vendors.findIndex(vend => vend.id == this.vendorSelected);
      if (index > -1) {
        vendorName = this.vendors[index].company;
      }
    }
    
    let brokerName: string;
    if (this.brokerSelected) {
      const index = this.brokers.findIndex(broker => broker.id == this.brokerSelected);
      if (index > -1) {
        brokerName = this.brokers[index].company;
      }
    }
    let navItem = [];

    switch (this.bucketSelected) {
      case 'Commission':
        navItem.push('/portal/commission');
        break;
      case 'Exchange':
        navItem.push('/portal/exchange');
        break;
      case 'Marketing':
        navItem.push('/portal/marketing');
        break;
      case 'Vendor':
        navItem.push('/portal/vendor');
        break;
      case 'Royalty':
        navItem.push('/portal/royalty');
        break;
      case 'Storage':
        navItem.push('/portal/storage');
        break;
      case 'Sales':
        navItem.push('/portal/sales');
        break;
      case 'Sales Detail':
        navItem.push('/portal/sales-detail');
        break;
      case 'Sales Overview by Customer':
        navItem.push('/portal/sales-overview');
        break;
      case 'Sales Overview by Vendor':
        navItem.push('/portal/sales-overview');
        break;
      default:
        navItem.push('/portal/bucket');
        break;
    }
    this.router.navigate(navItem, { state:
      { 
        year: this.yearSelected,
        month: this.monthSelected,
        vendor: this.vendorSelected,
        customer: this.customerSelected,
        broker: this.brokerSelected,
        bucket: this.bucketSelected,
        customerName: customerName,
        brokerName: brokerName,
        vendorName: vendorName,
        combinePDF: this.combine,
        type: this.typeSelected,
        orderType: this.OrderTypeSelected
      }
    });

  }
}