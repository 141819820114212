import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Region } from '../../../models/region-model';
import { RegionService } from '../../../services/region.service';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
@Component({
  selector: 'app-region-edit',
  templateUrl: './region-edit.component.html',
  styleUrls: ['./region-edit.component.css']
})
export class RegionEditComponent implements OnInit {
  title = 'Region';
  newOne = true;
  region: Region;
  regionForm: UntypedFormGroup;
  successMessage: string;
  private success = new Subject<string>();
  staticAlertClosed = false;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private fb: UntypedFormBuilder,
    private regionSvc: RegionService
  ) { }

  ngOnInit(): void {
    this.regionForm = this.fb.group({
      active: [''],
      regionName: [''],
      province: ['']
    })
    
    this.route.data.subscribe(data => {
      if (data.dataRegion !== undefined) {
        this.title = 'Region Edit';
        this.newOne = false;
        this.region = data.dataRegion;
        this.fillFormData();
      } else {
        this.title = 'Region New';
        this.newOne = true;
        this.region = new Region();
        this.region.active = 'Active';
        this.regionForm.reset();
      }
    });
    setTimeout(() => this.staticAlertClosed = true, 20000);
    this.success.subscribe((message) => this.successMessage = message);
    this.success.pipe(
      debounceTime(5000)
    ).subscribe(() => this.successMessage = null);     

  }
  fillFormData() {
    this.regionForm.setValue({
      active: this.region.active,
      regionName: this.region.regionName,
      province: this.region.province
    });
  }
  onSubmit() {
    this.region.active = this.regionForm.controls.active.value;
    this.region.province = this.regionForm.controls.province.value;
    this.region.regionName = this.regionForm.controls.regionName.value;

    if (this.newOne) {
      this.regionSvc.create(this.region).subscribe(data => {
        this.region.regionId =  data.regionId;
        this.newOne = false;
        this.title = 'Region Edit';
        this.success.next(`Successfully added.`);
      }, error => {
      });
    } else {
      this.regionSvc.update(this.region).subscribe(data => {
        this.success.next(`Successfully changed.`);
      }, error => {
      });
    }
  }
  onDelete() {
    if (this.region.regionId) {
      this.regionSvc.remove(this.region.regionId).subscribe(data => {
        this.router.navigate(['/other/region-list']);
      });
    }
  }   
}
