import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
const url = environment.kalcorApiUrl;
@Injectable({
  providedIn: 'root'
})
export class BudgetActualsService {
  constructor(private http: HttpClient) { }
  getAll(): Observable<any> {
    return this.http.get(url + 'BudgetActuals', this.jwt());
  }
  getOne(id: number): Observable<any> {
    return this.http.get(url + 'BudgetActuals/' + id, this.jwt());
  }
  getBySalesNumber(salesNo: string): Observable<any> {
    return this.http.get(url + 'BudgetActuals/' + salesNo.trim() + '/Sales', this.jwt());
  }
  update(items) { 
    
    return this.http.put(url + 'BudgetActuals/' + items.id, items, this.jwt());
  }
  updateCase(items) {
    return this.http.put(url + 'BudgetActuals/' + items.id + '/Case', items, this.jwt());
  }
  updateShip(items) {
    return this.http.put(url + 'BudgetActuals/' + items.itemId + '/Ship', items, this.jwt());
  }
  updateInvoice(items) {
    return this.http.put(url + 'BudgetActuals/' + items.id + '/Invoice', items, this.jwt());
  }
  create(items): Observable<any> {
    return this.http.post(url + 'BudgetActuals', items, this.jwt());
  }
  remove(id) {
    return this.http.delete(url + 'BudgetActuals/' + id, this.jwt());
  }
  private jwt() {
    // create authorization header with jwt token
    const currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
    if (currentUser && currentUser.token) {
        const headersInfo = new HttpHeaders({ Authorization: 'Bearer ' + currentUser.token });
        return { headers: headersInfo };
    }
  }

}
