import { Component, OnInit, Input } from '@angular/core';
import { Big } from 'big.js';

@Component({
  selector: 'app-invoicing-customer',
  templateUrl: './invoicing-customer.component.html',
  styleUrls: ['./invoicing-customer.component.scss']
})
export class InvoicingCustomerComponent implements OnInit{
@Input() title: string;
@Input() order: any;
@Input() orderItems: any;
@Input() totalCases: number;
@Input() totalPallets: number;
@Input() totalGrossLbs: number;
@Input() totalCaseCost: number;
@Input() totalGrossKg: number;

constructor() {}
grayRow = [];
commPerCent = [];
commAmount = [];
kalcorPerCent = [];
kalcorAmount = [];
totalCommAmount = 0;
totalKalcorAmount = 0;
totalInvoice = 0;
newTotal = 0;
gstTax = 5;
gstHst = [];
gstAmount = [];
totalGstHst = 0;
gstTaxAmount = 0;
grandTotalInvoice = 0;

ngOnInit(): void {
  let stripe = 'S';
    this.orderItems.forEach(element => {
      stripe = stripe == 'S' ? '' : 'S';
      this.grayRow.push(stripe);
      
      let commpct = element.commission1 ? element.commission1 : 0;
      commpct += element.commission21 ? element.commission21 : 0;
      this.commPerCent.push(commpct);
      const bigNum: any = Big(element.extCaseCost)
      .times(commpct)
      .times(.01)
      .round(2);
      this.commAmount.push(bigNum * 1);
      this.totalCommAmount += (bigNum * 1);
      
      let kalcorPct = element.kalcor1 ? element.kalcor1 : 0;
      this.kalcorPerCent.push(kalcorPct);
      const bigNum2: any = Big(element.extCaseCost)
      .times(kalcorPct)
      .times(.01)
      .round(2);
      this.kalcorAmount.push(bigNum2 * 1);
      this.totalKalcorAmount += (bigNum2 * 1);

      if (element.gstHst != null) {
        let gstHstPct = element.gstHst ? element.gstHst : 0;
        this.gstHst.push(gstHstPct);
        const bigNum3: any = Big(element.extCaseCost)
        .times(gstHstPct)
        .times(.01)
        .round(2);
        this.gstAmount.push(bigNum3 * 1);
        this.totalGstHst += (bigNum3 * 1);
      }

    });
    // this.totalInvoice = this.totalCommAmount + this.totalKalcorAmount;
    // const bigNum3: any = Big(this.totalInvoice)
    // .times(this.gstTax)
    // .times(.01)
    // .round(2);
    // this.gstTaxAmount = bigNum3 * 1;
    // this.grandTotalInvoice = this.totalInvoice + this.gstTaxAmount;
    // this.grandTotalInvoice = this.totalInvoice + this.totalGstHst;

    // const bigNum4: any = Big(this.totalCaseCost)
    // .times(.01)
    // .round(2);
    // this.newTotal = bigNum4 * 1;
    this.grandTotalInvoice = this.totalCaseCost + this.totalGstHst;
}

// getGstHst(id) {
//   let ret = '';
//   let shipToCount = 0;
//   if (id) {
//     const ids = id.split(',');
//     ids.forEach(ship => {
//       const firstId = +ship;
//       const index = this.shipTo.findIndex(item => item.id === firstId);
//       if (index >  -1) {
//         shipToCount++;
//         ret += ' (' + shipToCount + ')' + this.shipTo[index].company;
//       }
//     });
//   }

//   return ret;
// }

displayDate(dt, t) {
  if (dt) {
    if (t === undefined) {
      t = '';
    }
    if (dt.indexOf('T') == -1) {
      dt += 'T00:00';
    }
    let x = new Date(dt);
    if (t == 'T') {
      return x.toLocaleDateString() + ' @ ' + x.toLocaleTimeString();
    }
    return x.toLocaleDateString();
  } 
  return dt
}

commPrt(i) {
  return this.commPerCent[i].toFixed(2) + '% $' + this.commAmount[i].toFixed(2);
}  
kalcorPrt(i) {
  return this.kalcorPerCent[i].toFixed(2) + '% $' + this.kalcorAmount[i].toFixed(2);
}
gstPrt(i) {
  const gst = this.gstAmount[i];
  if (gst == null || gst == 0) {
    return 0;
  } else {
    return '$' + gst.toFixed(2);
  }
}
}
