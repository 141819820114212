import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { User, UserFull } from '../models/user.model';
const url =  environment.kalcorApiUrl;
@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private http: HttpClient) { }
  getAll(): Observable<any> {
    return this.http.get(url + 'Users', this.jwt());
  }

  getById(id: number) {
      return this.http.get(url + 'Users/' + id, this.jwt());
  }
  getOne(id: number): Observable<any> {
    return this.http.get(url + 'Users/' + id, this.jwt());
  }
create(user: User) {
      return this.http.post(url + 'Users/', user, this.jwt());
}
updatePassword(user: User) {
  return this.http.post(url + 'Users/Password', user, this.jwt());
}
createFull(user: UserFull) {
  return this.http.post<UserFull>(url + 'Users/New', user, this.jwt());
}
update(user: UserFull) {
      return this.http.put(url + 'Users/' + user.userid, user, this.jwt());
}

delete(id: number) {
      return this.http.delete(url + 'Users/' + id, this.jwt());
}

// private helper methods

private jwt() {
    // create authorization header with jwt token
    const currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
    if (currentUser && currentUser.token) {
        const headersInfo = new HttpHeaders({ Authorization: 'Bearer ' + currentUser.token });
        return { headers: headersInfo };
    }
  }
}
