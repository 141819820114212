import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
const url = environment.kalcorApiUrl;
@Injectable({
  providedIn: 'root'
})
export class CategoryService {

  constructor(private http: HttpClient) { }
  getAll(): Observable<any> {
    return this.http.get(url + 'LkupProductsCategories', this.jwt());
  }
  getCompany(): Observable<any> {
    return this.http.get(url + 'LkupProductsCategories/Company', this.jwt());
  }
  getOne(id: number): Observable<any> {
    return this.http.get(url + 'LkupProductsCategories/' + id, this.jwt());
  }
  getByVendor(): Observable<any> {
    return this.http.get(url + 'LkupProductsCategories/vendor', this.jwt());
  }
  getFilteredListing(activeItem: string, vendorId: number, brandId: number, page: number, pageSize: number): Observable<any> {
    page = page ? page : 1;
    pageSize = pageSize ? pageSize : 30;
    return this.http.get(url + 'LkupProductsCategories/Filter?activeItem=' + activeItem + '&vendorId=' + vendorId + '&brandId=' + 
    brandId + '&page=' + page + '&pageSize=' + pageSize, this.jwt());
  }    
  update(category) {
    return this.http.put(url + 'LkupProductsCategories/' + category.id, category, this.jwt());
  }
  create(category): Observable<any> {
    return this.http.post(url + 'LkupProductsCategories', category, this.jwt());
  }
  remove(id) {
    return this.http.delete(url + 'LkupProductsCategories/' + id, this.jwt());
  }
  private jwt() {
    // create authorization header with jwt token
    const currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
    if (currentUser && currentUser.token) {
        const headersInfo = new HttpHeaders({ Authorization: 'Bearer ' + currentUser.token });
        return { headers: headersInfo };
    }
  }

}
