<h5>Product Case Review</h5>
<div class="smallSpacer"><h6>Filter</h6></div>
<div class="col-lg-44">

  <label class="formControlName ms-3">
    Brand:
    <select class="form-control" [(ngModel)]="brand" (change)="resetFilters()">
      <option [value]=""></option>
      <option *ngFor="let b of brandFilter" [value]="b">{{ b }}</option>
    </select>
  </label>

  <label class="formControlName ms-3">
    Category:
    <select class="form-control" [(ngModel)]="category" (change)="resetFilters()">
        <option [value]=""></option>
        <option *ngFor="let c of categoryFilter" [value]="c">{{ c }}</option>
    </select>
  </label>

  <label class="formControlName ms-3">
    Type of Costing:
    <select class="form-control" [(ngModel)]="costing" (change)="resetFilters()">
        <option [value]=""></option>
        <option *ngFor="let t of costingFilter" [value]="t">{{ t }}</option>
    </select>
  </label>

  <label class="formControlName ms-3">
    Cost Type:
    <select class="form-control" [(ngModel)]="costType" (change)="resetFilters()">
      <option [value]=""></option>
      <option *ngFor="let t of costTypeFilter" [value]="t">{{ t }}</option>
    </select>
  </label>

  <label class="formControlName ms-3">
    Group:
    <select class="form-control" [(ngModel)]="group" (change)="resetFilters()">
      <option [value]=""></option>
      <option *ngFor="let t of groupFilter" [value]="t">{{ t }}</option>
    </select>
  </label>
  <div class="smallSpacer"></div>
  <!-- <button type="button" class="btn btn-primary" (click)="initializeExcel()">Export to Excel</button> -->
  <button type="button" class="btn btn-primary mt-0 ms-3" (click)="getDefaultDataScreen()">Reset</button>
</div>
<div class="spacer"></div>
<table class="table table-striped">
  <thead>
  <tr>
    <th scope="col">Order Code</th>
    <th scope="col">Item Description</th>
    <th scope="col">Size</th>
    <th scope="col">CS Net Lbs</th>
    <th scope="col">CS Gross Lbs</th>
    <th scope="col">CS Net Kg</th>
    <th scope="col">CS Gross Kg</th>
    <th scope="col">Cs L Inch</th>
    <th scope="col">Cs W Inch</th>
    <th scope="col">Cs H Inch</th>
    <th scope="col">Cs Cube Imp</th>
    <th scope="col">Cs L Cm</th>
    <th scope="col">Cs W Cm</th>
    <th scope="col">Cs H Cm</th>
    <th scope="col">Cs Cube M</th>
    <th scope="col">Unit Net</th>
    <th scope="col">Unit Gross</th>
    <th scope="col">Unit L Inch</th>
    <th scope="col">Unit W Inch</th>
    <th scope="col">Unit H Inch</th>
    <th scope="col">Unit Cube Imp</th>
    <th scope="col">Unit L Cm</th>
    <th scope="col">Unit W Cm</th>
    <th scope="col">Unit H Cm</th>
    <th scope="col">Unit Cube M</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let p of products | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize">
    <td>
      <a (click)="onRowClicked(p)" class="pointer">
        <u class="text-primary">{{ p.orderCode }}</u>
      </a>
    </td>
    <td>{{ p.itemDescription }}</td>
    <td>{{ p.size }}</td>
    <td>{{ formatValue(p.csNetLbs) }}</td>
    <td>{{ formatValue(p.csGrossLbs) }}</td>
    <td>{{ formatValue(p.csNetKg) }}</td>
    <td>{{ formatValue(p.csGrossKg) }}</td>
    <td>{{ formatValue(p.csLInch) }}</td>
    <td>{{ formatValue(p.csWInch) }}</td>
    <td>{{ formatValue(p.csHInch) }}</td>
    <td>{{ formatValue(p.csCubeImperial) }}</td>
    <td>{{ formatValue(p.csLCm) }}</td>
    <td>{{ formatValue(p.csWCm) }}</td>
    <td>{{ formatValue(p.csHCm) }}</td>
    <td>{{ formatValue(p.csCubeMetric) }}</td>
    <td>{{ formatValue(p.unitNet) }}</td>
    <td>{{ formatValue(p.unitGross) }}</td>
    <td>{{ formatValue(p.unitLInch) }}</td>
    <td>{{ formatValue(p.unitWInch) }}</td>
    <td>{{ formatValue(p.unitHInch) }}</td>
    <td>{{ formatValue(p.unitCubeImperial) }}</td>
    <td>{{ formatValue(p.unitLCm) }}</td>
    <td>{{ formatValue(p.unitWCm) }}</td>
    <td>{{ formatValue(p.unitHCm) }}</td>
    <td>{{ formatValue(p.unitCubeMetric) }}</td>
  </tr>
  <div *ngIf="products!==undefined">
  <ngb-pagination
  [(page)]="page"
  [pageSize]="pageSize"
  [collectionSize]="products?.length"></ngb-pagination>
  </div>
  </tbody>
</table>
