import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Transportation } from '../../../models/transportation.model';
import { TransportationService } from '../../../services/transportation.service';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { TermsService } from '../../../services/terms.service';
import { Term } from '../../../models/term-model';
@Component({
  selector: 'app-transportation-edit',
  templateUrl: './transportation-edit.component.html',
  styleUrls: ['./transportation-edit.component.scss']
})
export class TransportationEditComponent implements OnInit {
  title = 'Transportation Account New';
  newOne = true;
  transportation: Transportation;
  transportationForm: UntypedFormGroup;
  successMessage: string;
  private success = new Subject<string>();
  staticAlertClosed = false;
  term: Term[] = [];
  constructor(private route: ActivatedRoute,
              private router: Router,
              private fb: UntypedFormBuilder,
              private termsSvc: TermsService,
              private transportSvc: TransportationService) {
    this.transportation = new Transportation();
              }

  ngOnInit() {
    this.transportationForm = this.fb.group({
      activeItem: [''],
      company: [''],
      address: [''],
      city: [''],
      stateProvince: [''],
      countryRegion: [''],
      zipPostalCode: [''],
      fullName: [''],
      jobTitle: [''],
      businessPhone: [''],
      faxNumber: [''],
      mobileNumber: [''],
      eMail: [''],
      currencyCode: [''],
      termsId: [''],
      webPage: [''],
      comments: [''],
    });
    this.termsSvc.getAll().subscribe(data => {
      this.term = data;
      this.route.data.subscribe(data2 => {
        if (data2.dataTransport !== undefined) {
          this.title = 'Transportation Accounts Edit';
          this.newOne = false;
          this.transportation = data2.dataTransport;
          this.fillFormData();
        } else {
          this.title = 'Transportation Accounts New';
          this.newOne = true;
          this.transportation = new Transportation();
          this.transportationForm.reset();
        }
      });
    });
    setTimeout(() => this.staticAlertClosed = true, 20000);
    this.success.subscribe((message) => this.successMessage = message);
    this.success.pipe(
      debounceTime(5000)
    ).subscribe(() => this.successMessage = null);
  }
  fillFormData() {
    this.transportationForm.setValue({
      activeItem: this.transportation.activeItem,
      company: this.transportation.company,
      address: this.transportation.address,
      city: this.transportation.city,
      stateProvince: this.transportation.stateProvince,
      countryRegion: this.transportation.countryRegion,
      zipPostalCode: this.transportation.zipPostalCode,
      fullName: this.transportation.fullName,
      jobTitle: this.transportation.jobTitle,
      businessPhone: this.transportation.businessPhone,
      faxNumber: this.transportation.faxNumber,
      mobileNumber: this.transportation.mobileNumber,
      eMail: this.transportation.eMail,
      currencyCode: this.transportation.currencyCode,
      termsId: this.transportation.termsId,
      webPage: this.transportation.webPage,
      comments: this.transportation.comments
    });
  }
  onSubmit() {
    this.transportation.activeItem = this.transportationForm.controls.activeItem.value;
    this.transportation.company = this.transportationForm.controls.company.value;
    this.transportation.address = this.transportationForm.controls.address.value;
    this.transportation.city = this.transportationForm.controls.city.value;
    this.transportation.stateProvince = this.transportationForm.controls.stateProvince.value;
    this.transportation.countryRegion = this.transportationForm.controls.countryRegion.value;
    this.transportation.zipPostalCode = this.transportationForm.controls.zipPostalCode.value;
    this.transportation.fullName = this.transportationForm.controls.fullName.value;
    this.transportation.jobTitle = this.transportationForm.controls.jobTitle.value;
    this.transportation.businessPhone = this.transportationForm.controls.businessPhone.value;
    this.transportation.faxNumber = this.transportationForm.controls.faxNumber.value;
    this.transportation.mobileNumber = this.transportationForm.controls.mobileNumber.value;
    this.transportation.eMail = this.transportationForm.controls.eMail.value;
    this.transportation.currencyCode = this.transportationForm.controls.currencyCode.value;
    this.transportation.termsId = this.transportationForm.controls.termsId.value;
    this.transportation.webPage = this.transportationForm.controls.webPage.value;
    this.transportation.comments = this.transportationForm.controls.comments.value;
    if (this.newOne) {
      this.transportSvc.create(this.transportation).subscribe(data => {
        this.transportation.id = data.id;
        this.newOne = false;
        this.title = 'Clearing Company Edit';
        this.success.next(`Successfully added.`);
      }, error => {
      });
    } else {
      this.transportSvc.update(this.transportation).subscribe(data => {
        this.success.next(`Successfully changed.`);
      }, error => {
      });
    }
  }
  onDelete() {
    if (this.transportation.id) {
      this.transportSvc.remove(this.transportation.id).subscribe(data => {
        this.router.navigate(['/other/transportation-list']);
      });
    }
  }
}
