import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ProductService } from '../../../../services/product.service';
import { UpcGtnService } from '../../../../services/upc-gtn.service';
import { UpcGtn } from '../../../../models/upc-gtn.model';
import { FormsModule } from '@angular/forms';
import { Product, ActiveListings, ProductInit } from 'src/app/models/product.model';
import { BrandService } from '../../../../services/brand.service';
import { Brand } from '../../../../models/brand.model';
import { CategoryService } from '../../../../services/category.service';
import { Category } from '../../../../models/category.model';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { UtilityService } from '../../../../services/utility.service';
import { LkupProductGroupService } from 'src/app/services/lkup-product-group.service';
import { LkupProductGroups } from 'src/app/models/lkup-product-group.model';
import { error } from 'console';
@Component({
  selector: 'app-case-setup-edit',
  templateUrl: './case-setup-edit.component.html',
  styleUrls: ['./case-setup-edit.component.scss']
})
export class CaseSetupEditComponent implements OnInit {
  title = 'Product Setup New';
  newOne = true;
  brands: Brand[];
  categories: Category[];
  product: Product;
  originalProduct: Product;
  gtn: UpcGtn[];
  productForm: UntypedFormGroup;
  successMessage: string;
  private success = new Subject<string>();
  staticAlertClosed = false;
  activeList = [];
  productGroups: LkupProductGroups[];
  groupName = '';
  linkedProduct: any;
  processIndex: number;
  alertType = "success";
  grouped = false;
  listing = false;
  linkedListing: any;
  spinner = '';

  constructor(private route: ActivatedRoute,
              private router: Router,
              private fb: UntypedFormBuilder,
              private gtnService: UpcGtnService,
              private brandService: BrandService,
              private categoryService: CategoryService,
              private productService: ProductService,
              private productGroupService: LkupProductGroupService,
              private utiityService: UtilityService) { }

  ngOnInit() {
    this.activeList = ActiveListings;
    this.productForm = this.fb.group({
      activeListings: [{ value: '', disabled: true }],
      costType: [{ value: '', disabled: true }],
      productOrderCode: [{ value: '', disabled: true }],
      brand: [{ value: '', disabled: true }],
      category: [{ value: '', disabled: true }],
      itemDescription: [{ value: '', disabled: true }],
      size: [{ value: '', disabled: true }],
      csNetLbs: [{ value: '', disabled: true }],
      csGrossLbs: [''],
      csNetKg: [{ value: '', disabled: true }],
      csGrossKg: [{ value: '', disabled: true }],
      csLInch: [''],
      csWInch: [''],
      csHInch: [''],
      csCubeImperial: [{ value: '', disabled: true }],
      csLCm: [{ value: '', disabled: true }],
      csHCm: [{ value: '', disabled: true }],
      csWCm: [{ value: '', disabled: true }],
      csCubeMetric: [{ value: '', disabled: true }],
      unitNet: [{ value: '', disabled: true }],
      unitGross: [{ value: '', disabled: true }],
      unitLInch: [''],
      unitWInch: [''],
      unitHInch: [''],
      unitCubeImp: [{ value: '', disabled: true }],
      unitLCm: [{ value: '', disabled: true }],
      unitWCm: [{ value: '', disabled: true }],
      unitHCm: [{ value: '', disabled: true }],
      unitCubeM: [{ value: '', disabled: true }],
      gstHstId: [{ value: '', disabled: true }]
    });

    this.brandService.getAll().subscribe(brand => {
      this.brands = brand;
      this.categoryService.getAll().subscribe(category => {
        this.categories = category;
        this.gtnService.getAll().subscribe(gtns => {
          this.gtn = gtns;
          this.productGroupService.getAll().subscribe(group => {
            this.productGroups = group;

          this.route.data.subscribe(data => {
            if (data.dataProductSetup !== undefined) {
              this.title = 'Cases Setup Edit';
              this.newOne = false;
              this.product = data.dataProductSetup;
              this.product.activeListings = data.dataProductSetup.activeListings.replace("–","-");
              if (this.product.activeListings.includes("LISTING")) {
                this.listing = true;
                if (this.product.groupListingId > 0) {
                  let x = this.product.groupListingId;
                  this.getListingGroup(x);
                }
              }
              this.fillFormData();
              this.reCalculate('ALL');
              this.onChanges();
            } else {
              this.title = 'Cases Setup New';
              this.newOne = true;
              this.product = ProductInit;
              this.productForm.reset();
              this.onChanges();
            }
          });
        });
        });
      });
    });
    setTimeout(() => this.staticAlertClosed = true, 20000);
    this.success.subscribe((message) => this.successMessage = message);
    this.success.pipe(
      debounceTime(5000)
    ).subscribe(() => this.successMessage = null);

  }
  onChanges(): void {
    const item = ['csGrossLbs', 'csLInch', 'csWInch', 'csHInch', 'unitLInch', 'unitWInch', 'unitHInch'];
    item.forEach(name => {
      this.productForm.get(name).valueChanges.subscribe(val => {
        this.reCalculate(name);
      });
    });
  }
  fillFormData() {
    const productOrderCodeValue = this.getValue(this.product.productOrderCodeId, 'productOrderCodeId');
    const brandValue = this.getValue(this.product.productOrderCodeId, 'brandId');
    const categoryValue = this.getValue(this.product.categoryId, 'categoryId');
    const productGroupValue = this.getValue(this.product.productGroupId, 'productGroupId');
    this.groupName = productGroupValue;

    this.productForm.setValue({
      activeListings: this.product.activeListings,
      costType: this.product.costType,
      productOrderCode: productOrderCodeValue,
      brand: brandValue,
      category: categoryValue,
      itemDescription: this.product.itemDescription,
      size: this.product.size,
      csNetLbs: this.product.csNetLbs,
      csGrossLbs: this.product.csGrossLbs,
      csNetKg: this.product.csNetKg,
      csGrossKg: this.product.csGrossKg,
      csLInch: this.product.csLInch,
      csWInch: this.product.csWInch,
      csHInch: this.product.csHInch,
      csCubeImperial: this.product.csCubeImperial,
      csLCm: this.product.csLCm,
      csHCm: this.product.csHCm,
      csWCm: this.product.csWCm,
      csCubeMetric: this.product.csCubeMetric,
      unitNet: this.product.unitNet,
      unitGross: this.product.unitGross,
      unitLInch: this.product.unitLInch,
      unitWInch: this.product.unitWInch,
      unitHInch: this.product.unitHInch,
      unitCubeImp: this.product.unitCubeImp,
      unitLCm: this.product.unitLCm,
      unitWCm: this.product.unitWCm,
      unitHCm: this.product.unitHCm,
      unitCubeM: this.product.unitCubeM,
      gstHstId: this.product.gstHstId
    });
    if (productGroupValue) {
      this.getProductGroup(this.product.productGroupId);
    }
  }
  getProductGroup(val) {
    this.productService.getProductGroup(val).subscribe(data => {
      this.linkedProduct = data;
    })
  }
  getListingGroup(x){
    this.productService.getProductGroupListing(x).subscribe(data => {
      this.linkedListing = data;
    })
  }
  getValue(id: number, name: string) {
    let ret = '';
    if (id) {
      let index = -1;
      switch (name) {
        case 'productOrderCodeId':
          index = this.gtn.findIndex(item => item.id === id);
          if (index > -1) {
            ret = this.gtn[index].vendorOrderCode;
          }
          break;
          case 'brandId':
            index = this.gtn.findIndex(item => item.id === id);
            if (index > -1) {
              const brandId = this.gtn[index].brandId;
              if (brandId > -1 ) {
                const brandIndex = this.brands.findIndex(item => item.id === brandId);
                if (brandIndex > -1) {
                  ret = this.brands[brandIndex].brand;
                }  
              }
            }
            break;
        case 'categoryId':
          index = this.categories.findIndex(item => item.id === id);
          if (index > -1) {
            ret = this.categories[index].category;
          }
          break;
          case 'productGroupId':
            index = this.productGroups.findIndex(item => item.id === id);
            if (index > -1) {
              ret = this.productGroups[index].productGroupNo;
            }
          break;
        }
    }
    return ret;
  }
  onSubmit() {
    this.updateProduct();
    if (this.newOne) {
      this.productService.create(this.product).subscribe(data => {
        this.product = data;
        this.newOne = false;
        this.title = 'Case Setup Edit';
        this.success.next(`Successfully added.`);
      }, error => {
      });
    } else {
      this.productService.update(this.product).subscribe(data => {
        this.success.next(`Successfully changed.`);
      }, error => {
      });
    }
  }

  onDelete() {
    if (this.product.id) {
      this.productService.remove(this.product.id).subscribe(data => {
        this.router.navigate(['/products/cases-setup-list']);
      });
    }
  }
  reCalculate(key) {
    this.updateProduct();
    const changedItems = this.utiityService.productCalculations(key, this.product);
    for (const item in changedItems) {
      if (changedItems.hasOwnProperty(item)) {
        if (this.productForm.controls.hasOwnProperty(item)) {
          this.productForm.controls[item].setValue(changedItems[item]);
        }
        this.product[item] = changedItems[item];
      }
    }
  }
  updateProduct() {
    for (const item in this.productForm.controls) {
      if (this.productForm.controls.hasOwnProperty(item)) {
       if (this.product[item] !== undefined) {
          this.product[item] = this.productForm.controls[item].value;
       }
      }
    }
  }
  validate() {
    //beginning method for updating products defined by active listing - "pricelist"
    if (this.product.productGroupId == 0 || this.product.productGroupId == null) {
      this.success.next(`This product does not belong to a group.`);
      this.alertType = "danger";
      return;
    }
    if (confirm('Case information will be updated for all products in the group.'))
    this.processIndex = -1;
    this.grouped = true;
    this.originalProduct = this.product;
    this.spinner = '...Updating';
    this.initializeProducts();
  }
  initializeProducts() {
    //update case information for all products in linkedProduct array
    this.processIndex++;
      if (this.processIndex >= this.linkedProduct.length) {
        this.processIndex = -1;
        this.grouped = false;
        this.product = this.originalProduct;
        this.spinner = '';
        alert('All products updated successfully.');
      } else {
        this.insertLinkedProducts();
      }
  }
  insertLinkedProducts() {
    this.updateLinkedProduct();
    if (this.newOne) {
      this.productService.create(this.product).subscribe(data => {
        this.product = data;
        this.newOne = false;
        this.title = 'Case Setup Edit';
        this.initializeProducts();
      }, error => {
      });
    } else {
      this.productService.update(this.product).subscribe(data => {
        this.initializeProducts();
      }, error => {
      });
    }
  }
  updateLinkedProduct() {
    this.product = this.linkedProduct[this.processIndex];
    for (const item in this.productForm.controls) {
      if (this.productForm.controls.hasOwnProperty(item)) {
        if (this.product[item] !== undefined) {
           if (item !== 'productOrderCode') {
            if (item !== 'itemDescription') {
              if (item !== 'category') {
                if (item !== 'size') {
                  if (item !== 'brand') {
                    this.product[item] = this.productForm.controls[item].value;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  validateListing() {
    //beginning method for updating products defined by active listing - "listing"
    if (this.product.groupListingId == 0 || this.product.groupListingId == null) {
      this.success.next(`This product does not belong to a group listing.`);
      this.alertType = "danger";
      return;
    }
    if (confirm('Case information will be updated for all products in the group listing.'))
    this.processIndex = -1;
    this.grouped = true;
    this.originalProduct = this.product;
    this.spinner = '...Updating';
    this.initializeListings();
  }
  initializeListings() {
    //update case information for all products in linkedProduct array
    this.processIndex++;
      if (this.processIndex >= this.linkedListing.length) {
        this.processIndex = -1;
        this.grouped = false;
        this.product = this.originalProduct;
        this.spinner = '';
        alert('All listings updated successfully.');
      } else {
        this.insertLinkedListings();
      }
  }
  insertLinkedListings() {
    this.updateLinkedListings();
    if (this.newOne) {
      this.productService.create(this.product).subscribe(data => {
        this.product = data;
        this.newOne = false;
        this.title = 'Case Setup Edit';
        this.initializeListings();
      }, error => {
      });
    } else {
      this.productService.update(this.product).subscribe(data => {
        this.initializeListings();
      }, error => {
      });
    }
  }
  updateLinkedListings() {
    this.product = this.linkedListing[this.processIndex];
    for (const item in this.productForm.controls) {
      if (this.productForm.controls.hasOwnProperty(item)) {
        if (this.product[item] !== undefined) {
           if (item !== 'productOrderCode') {
            if (item !== 'itemDescription') {
              if (item !== 'category') {
                if (item !== 'size') {
                  if (item !== 'brand') {
                    this.product[item] = this.productForm.controls[item].value;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  editSetup() {
    this.router.navigate(['/products/setup-edit',  this.product.id]);
  }
  editPalletSetup() {
    this.router.navigate(['/products/pallet-setup-edit',  this.product.id]);
  }
  editFreightCostSetup() {
    this.router.navigate(['/products/freight-cost-setup-edit',  this.product.id]);
  }
  editCostingBucketSetup() {
    this.router.navigate(['/products/costing-bucket-setup-edit',  this.product.id]);
  } 
  editListingSetup() {  
    this.router.navigate(['/productListings/listing-setup-edit',  this.product.id]);
  }
}
