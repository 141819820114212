import { Component, OnInit } from '@angular/core';
import { CustomerEndService  } from '../../../../services/customer-end.service';
import { Router } from '@angular/router';
import { debounceTime } from 'rxjs/operators';
import { Subject } from 'rxjs';
@Component({
  selector: 'app-customer-end-list',
  templateUrl: './customer-end-list.component.html',
  styleUrls: ['./customer-end-list.component.scss']
})
export class CustomerEndListComponent implements OnInit {
  customerEnd: any;
  activeItemSelected: string;
  companySelected: number;
  companyOptions:[];
  customerEndOriginal: any;
  // page = 1;
  // pageSize = 30;
  // activeItemSelected = 'Active';
  // companySelected = 0;
  collectionSize = 0;
  // count = 0;
  // pageData = [];
  page: number;
  pageSize: number;
  count: number;
  successMessage: string;
  private success = new Subject<string>();
  alertType = "success";
  staticAlertClosed = false;
  constructor(private router: Router,
              private customerEndSvc: CustomerEndService) { }

  ngOnInit() {
    this.reset();
    setTimeout(() => this.staticAlertClosed = true, 20000);
    this.success.subscribe((message) => this.successMessage = message);
    this.success.pipe(
      debounceTime(5000)
    ).subscribe(() => this.successMessage = null);      
  }
  loadPage() {

    this.customerEndSvc.getFilteredListing(this.activeItemSelected, this.companySelected, this.page, this.pageSize).subscribe(data => {
      this.customerEndOriginal = data.item;
      this.customerEnd = [];
      this.customerEndOriginal.forEach(item => {
        const index = this.customerEnd.findIndex(x => x.id == item.id);
        if (index == -1) {
          this.customerEnd.push(item);
        } else {
          if (item.retailRegion && this.customerEnd[index].retailRegion) {
            if (this.customerEnd[index].retailRegion.indexOf(item.retailRegion) == -1) {
              this.customerEnd[index].retailRegion += " " + item.retailRegion;
              this.customerEnd[index].retailRegion.trim();
            }
          }
          if (item.retailAccountType) {
            if (this.customerEnd[index].retailAccountType.indexOf(item.retailAccountType) == -1) {
              this.customerEnd[index].retailAccountType += " " + item.retailAccountType;
              this.customerEnd[index].retailAccountType.trim();
            }
          }
        }
      });
      this.companyOptions = data.item2;
      // this.collectionSize = data.item2.length;
      this.count = data.count;
    }, error => {
      this.successMessage = 'Unable to process request';
      this.alertType = "danger";
    });
  }
  reset() {
    this.page = 1;
    this.pageSize = 30;
    this.activeItemSelected = 'Active';
    this.companySelected = 0;
    this.loadPage();    
  }
  onRowClicked(customer) {
    this.router.navigate(['/customers/customer-end-edit', customer.id]);
  }
}
