import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { SubCategory } from '../../models/sub-category.model';
import { SubCategoryService } from '../../services/sub-category.service';

@Injectable()
export class SubCategoryResolver implements Resolve<any> {
  constructor(private subCategoryService: SubCategoryService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<SubCategory> {

    if (route.url[0].path === 'sub-category-list') {
      return this.subCategoryService.getFilteredListing('Active', 0, 0, 0, 1, 30);
    }

    const idField = 'id';
    const id =  parseInt(route.params[idField], 10);

    if (id === 0 || isNaN(id)) {
      return;
    }
    return this.subCategoryService.getOne(id);
  }
}
