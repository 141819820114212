import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { Customer } from '../models/customer.model';
import { idText } from 'typescript';
import { ThrottlingUtils } from '@azure/msal-common';
const url = environment.kalcorApiUrl;
@Injectable({
  providedIn: 'root'
})
export class CustomerService {

  constructor(private http: HttpClient) { }
  getAll(): Observable<any> {
    return this.http.get(url + 'AccountsCustomers', this.jwt());
  }
  getCompany(): Observable<any> {
    return this.http.get(url + 'AccountsCustomers/Company', this.jwt());
  }
  getOne(id: number): Observable<any> {
    return this.http.get(url + 'AccountsCustomers/' + id, this.jwt());
  }
  getFilteredList(status: string, company: string, page: number, pageSize: number): Observable<any> {
    status = status ? status : 'Active';
    page = page ? page : 1;
    pageSize = pageSize ? pageSize : 30;
    company = company ? company : '';

    return this.http.get(url + 'AccountsCustomers/Filter?status=' +
      status.trim() + '&company=' + company + '&page=' + page + '&pageSize=' + pageSize, this.jwt());
  }
  getCustomerYears(): Observable<any> {
    return this.http.get(url + 'AccountsCustomers/Years', this.jwt());
  }
  getOneYears(id: number): Observable<any> {
    return this.http.get(url + 'AccountsCustomers/' + id + '/Years', this.jwt());
  }
  getCustomer(id: number): Observable<any> {
    return this.http.get(url + 'AccountsCustomers/' + id + '/Head', this.jwt());
  }
  getRegions(id: number): Observable<any> {
    return this.http.get(url + 'AccountsCustomers/Region/' + id, this.jwt());
  }
  update(customer) {
    return this.http.put(url + 'AccountsCustomers/' + customer.id, customer, this.jwt());
  }
  create(customer): Observable<any> {
    return this.http.post(url + 'AccountsCustomers', customer, this.jwt());
  }
  remove(id) {
    return this.http.delete(url + 'AccountsCustomers/' + id, this.jwt());
  }
  private jwt() {
    // create authorization header with jwt token
    const currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
    if (currentUser && currentUser.token) {
      const headersInfo = new HttpHeaders({ Authorization: 'Bearer ' + currentUser.token });
      return { headers: headersInfo };

    }
  }
}
