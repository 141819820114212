import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-shipping',
  templateUrl: './shipping.component.html',
  styleUrls: ['./shipping.component.scss']
})
export class ShippingComponent implements OnInit {
  @Input() title: string;
  @Input() order: any;
  @Input() items: any;
  @Input() customer: any;
  @Input() term: any;
  @Input() freightType: any;
  @Input() broker: any;
  @Input() shipTo: any;
  @Input() pickup: any;
  @Input() location1: any;
  @Input() transport1: any;
  @Input() warehouse: any;
  @Input() location2: any;
  @Input() transport2: any;
  @Input() location3: any;
  @Input() transport3: any;
  @Input() vendor: any;
  @Input() totalCases: number;
  @Input() totalPallets: number;
  @Input() totalGrossLbs: number;
  @Input() totalGrosskg: number;
  @Input() freightTypeCompany: string;

  constructor() { }
  
  ngOnInit() {
  }

  displayDate(dt, t) {
    
    if (dt) {
      if (t === undefined) {
        t = '';
      }
      if (t == '' ) {
        dt += 'T00:00';
      }

      let x = new Date(dt);
      if (t == 'T') {
        return x.toLocaleDateString() + ' @ ' + x.toLocaleTimeString();
      }
      return x.toLocaleDateString();
    } 
    return dt
  }
}
