import { Component, OnInit } from '@angular/core';
import { CustomerService } from '../../../../services/customer.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, fromEventPattern, Subscription, throwError } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-customer-list',
  templateUrl: './customer-list.component.html',
  styleUrls: ['./customer-list.component.scss']
})
export class CustomerListComponent implements OnInit {
  customers: any;
  companies: any;
  companyOptions: [];
  customerOriginal: any;
  page = 1;
  pageSize = 30;
  status = 'Active';
  collectionSize = 0;
  company = '';
  successMessage: string;
  private success = new Subject<string>();
  alertType = "success";
  staticAlertClosed = false;
  constructor(private customerSvc: CustomerService,
              private router: Router
    ) { }

  ngOnInit() {
    this.customerSvc.getFilteredList(this.status, this.company, this.page, this.pageSize).subscribe(data => {
      this.customerOriginal = data.item;
      this.customers = [];
      this.customerOriginal.forEach(item => {
        const index = this.customers.findIndex(x => x.id == item.id);
        if (index == -1) {
          this.customers.push(item);
        } else {
          if (item.retailRegion && this.customers[index].retailRegion) {
            if (this.customers[index].retailRegion.indexOf(item.retailRegion) == -1) {
              this.customers[index].retailRegion += " " + item.retailRegion;
              this.customers[index].retailRegion.trim();
            }
          }
          if (item.retailAccountType) {
            if (this.customers[index].retailAccountType.indexOf(item.retailAccountType) == -1) {
              this.customers[index].retailAccountType += " " + item.retailAccountType;
              this.customers[index].retailAccountType.trim();
            }
          }
          if (item.shipToRegion) {
            if (this.customers[index].shipToRegion.indexOf(item.shipToRegion) == -1) {
              this.customers[index].shipToRegion += " " + item.shipToRegion;
              this.customers[index].shipToRegion.trim();
            }
          }
          if (item.shipToAccountType) {
            if (this.customers[index].shipToAccountType.indexOf(item.shipToAccountType) == -1) {
              this.customers[index].shipToAccountType += " " + item.shipToAccountType;
              this.customers[index].shipToRegion.trim();
            }
          }
        }        
      });
      this.collectionSize = data.count;
      this.companyOptions = data.item2;
    }, error => {
      this.successMessage = 'Unable to process request';
      this.alertType = "danger";
    });
    setTimeout(() => this.staticAlertClosed = true, 20000);
    this.success.subscribe((message) => this.successMessage = message);
    this.success.pipe(
      debounceTime(5000)
    ).subscribe(() => this.successMessage = null);    
    
  
  }
  onRowClicked(dataCustomers: any) {
    this.router.navigate(['/customers/customer-details', dataCustomers.id]);
  }
  loadPage(e) {
    if (e == 'status') {
      this.company = '';
    }
    this.customerSvc.getFilteredList(this.status, this.company, this.page, this.pageSize).subscribe(data => {
      this.customerOriginal = data.item;
      this.customers = [];
      this.customerOriginal.forEach(item => {
        const index = this.customers.findIndex(x => x.id == item.id);
        if (index == -1) {
          this.customers.push(item);
        } else {
          if (item.retailRegion && this.customers[index].retailRegion) {
            if (this.customers[index].retailRegion.indexOf(item.retailRegion) == -1) {
              this.customers[index].retailRegion += " " + item.retailRegion;
              this.customers[index].retailRegion.trim();
            }
          }
          if (item.retailAccountType) {
            if (this.customers[index].retailAccountType.indexOf(item.retailAccountType) == -1) {
              this.customers[index].retailAccountType += " " + item.retailAccountType;
              this.customers[index].retailAccountType.trim();
            }
          }
        }        
      });
      this.collectionSize = data.count;
      this.companyOptions = data.item2;
    }, error => {
      this.successMessage = 'Unable to process request';
      this.alertType = "danger";
    });
  }
}
