import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../../../services/user.service';
@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss']
})
export class UserListComponent implements OnInit {
  users: any;
  page = 1;
  pageSize = 30;
  constructor(
    private router: Router,
    private userService: UserService
  ) { }

  ngOnInit() {
    this.userService.getAll().subscribe(data => this.users = data);
  }
  onRowClicked(user) {
    this.router.navigate(['/admin/user-edit', user.userid]);
  }
}
