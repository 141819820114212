import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class Sorter2Service {

  constructor() { }
  sorter(tobeSorted, name, name2) {

    tobeSorted.sort((a, b) => {
      // if (a[name] === null && b[name] === null) {
      //   return 0;
      // }

      if (a[name] !== null && b[name] === null) {
        return 1;
      }

      if (a[name] === null && b[name] !== null) {
        return -1;
      }

      if (a[name] < b[name]) {
        return -1;
      }

      if (a[name] > b[name]) {
        return 1;
      }
      if (a[name2] !== null && b[name2] === null) {
        return 1;
      }

      if (a[name2] === null && b[name2] !== null) {
        return -1;
      }

      if (a[name2] < b[name2]) {
        return -1;
      }

      if (a[name2] > b[name2]) {
        return 1;
      }
      return 0;
    });
    return tobeSorted;
  }
}
