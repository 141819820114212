import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ClearingCompany } from '../../../models/clearing-company.model';
import { ClearingCompanyService } from '../../../services/clearing-company.service';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
@Component({
  selector: 'app-clearing-company-edit',
  templateUrl: './clearing-company-edit.component.html',
  styleUrls: ['./clearing-company-edit.component.scss']
})
export class ClearingCompanyEditComponent implements OnInit {
  title = 'Clearing Company New';
  newOne = true;
  clearing: ClearingCompany;
  clearingForm: UntypedFormGroup;
  successMessage: string;
  private success = new Subject<string>();
  staticAlertClosed = false;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private fb: UntypedFormBuilder,
              private clearingSvc: ClearingCompanyService) {
                  this.clearing = new ClearingCompany();
              }

  ngOnInit() {
    this.clearingForm = this.fb.group({
      activeItem: [''],
      company: [''],
      address: [''],
      city: [''],
      stateProvince: [''],
      countryRegion: [''],
      zipPostalCode: [''],
      fullName: [''],
      jobTitle: [''],
      businessPhone: [''],
      faxNumber: [''],
      mobileNumber: [''],
      eMail: [''],
      webPage: [''],
      comments: [''],
      billingCompany: [''],
      customerNumber: [''],
    });
    this.route.data.subscribe(data => {
      if (data.dataClearing !== undefined) {
        this.title = 'Clearing Company Edit';
        this.newOne = false;
        this.clearing = data.dataClearing;
        this.fillFormData();
      } else {
        this.title = 'Clearing Company New';
        this.newOne = true;
        this.clearing = new ClearingCompany();
        this.clearingForm.reset();
      }
    });
    setTimeout(() => this.staticAlertClosed = true, 20000);
    this.success.subscribe((message) => this.successMessage = message);
    this.success.pipe(
      debounceTime(5000)
    ).subscribe(() => this.successMessage = null);
  }
  fillFormData() {
    this.clearingForm.setValue({
      activeItem: this.clearing.activeItem,
      company: this.clearing.company,
      address: this.clearing.address,
      city: this.clearing.city,
      stateProvince: this.clearing.stateProvince,
      countryRegion: this.clearing.countryRegion,
      zipPostalCode: this.clearing.zipPostalCode,
      fullName: this.clearing.fullName,
      jobTitle: this.clearing.jobTitle,
      businessPhone: this.clearing.businessPhone,
      faxNumber: this.clearing.faxNumber,
      mobileNumber: this.clearing.mobileNumber,
      eMail: this.clearing.eMail,
      webPage: this.clearing.webPage,
      comments: this.clearing.comments,
      billingCompany: this.clearing.billingCompany,
      customerNumber: this.clearing.customerNumber
    });
  }
  onSubmit() {
    this.clearing.activeItem = this.clearingForm.controls.activeItem.value;
    this.clearing.company = this.clearingForm.controls.company.value;
    this.clearing.address = this.clearingForm.controls.address.value;
    this.clearing.city = this.clearingForm.controls.city.value;
    this.clearing.stateProvince = this.clearingForm.controls.stateProvince.value;
    this.clearing.countryRegion = this.clearingForm.controls.countryRegion.value;
    this.clearing.zipPostalCode = this.clearingForm.controls.zipPostalCode.value;
    this.clearing.fullName = this.clearingForm.controls.fullName.value;
    this.clearing.jobTitle = this.clearingForm.controls.jobTitle.value;
    this.clearing.businessPhone = this.clearingForm.controls.businessPhone.value;
    this.clearing.faxNumber = this.clearingForm.controls.faxNumber.value;
    this.clearing.mobileNumber = this.clearingForm.controls.mobileNumber.value;
    this.clearing.eMail = this.clearingForm.controls.eMail.value;
    this.clearing.webPage = this.clearingForm.controls.webPage.value;
    this.clearing.comments = this.clearingForm.controls.comments.value;
    this.clearing.billingCompany = this.clearingForm.controls.billingCompany.value;
    this.clearing.customerNumber = this.clearingForm.controls.customerNumber.value;
    if (this.newOne) {
      this.clearingSvc.create(this.clearing).subscribe(data => {
        this.clearing.id = data.id;
        this.newOne = false;
        this.title = 'Clearing Company Edit';
        this.success.next(`Successfully added.`);
      }, error => {
      });
    } else {
      this.clearingSvc.update(this.clearing).subscribe(data => {
        this.success.next(`Successfully changed.`);
      }, error => {
      });
    }
  }
  onDelete() {
    if (this.clearing.id) {
      this.clearingSvc.remove(this.clearing.id).subscribe(data => {
        this.router.navigate(['/other/clearing-co-list']);
      });
    }
  }
}
