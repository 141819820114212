import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OrderService } from 'src/app/services/order.service';
import { Vendor } from 'src/app/models/vendor-model';
import { VendorService } from 'src/app/services/vendor.service';
import { WeekKalcor } from 'src/app/models/week-kalcor.model';
import { WeekKalcorService } from 'src/app/services/week-kalcor.service';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { ExcelService } from 'src/app/services/excel.service';
import { LocationService } from 'src/app/services/location.service';
import { Location } from 'src/app/models/location-model';
import { Sorter2Service } from 'src/app/services/sorter2.service';

@Component({
  selector: 'app-lot-bb',
  templateUrl: './lot-bb.component.html',
  styleUrls: ['./lot-bb.component.scss']
})
export class LotBBComponent implements OnInit {
  title = 'Period/Week Report';
  orders: any;
  page = 1;
  pageSize = 25;
  collectionSize = 0;
  vendorSelected = 0;
  vendors: Vendor[];
  locations: Location[];
  venOptions = [];
  orderStatusSelected = '';
  orderStatusOptions = ['Sales Order', 'Shipped', 'Invoiced', 'Closed'];
  orderTypeSelected = 'Stock';
  orderTypeOptions = ['Stock', 'Retail', 'Food Service', 'Both Retail/Food Service'];
  custSelected = 0;
  custOptions = [];
  shipSelected = 0;
  shipOptions = [];
  salesNoSelected = '';
  salesNoOptions = [];
  poNoSelected = '';
  poNoOptions = [];
  year: number;
  years: WeekKalcor[];
  today: any;
  periods = [];
  periodStartDate: any;
  periodEndDate: any;
  periodOptions: any;
  periodSelected: any;
  month: any;
  successMessage: string;
  private success = new Subject<string>();
  staticAlertClosed = false;
  alertType = "success";
  resetForm = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private orderService: OrderService,
    private vendorService: VendorService,
    private weekKalcorService: WeekKalcorService,
    private excelService: ExcelService,
    private locationService: LocationService,
    private sort2Service: Sorter2Service
  ) { }

  ngOnInit(): void {
    this.periods = [];
    this.periodOptions = [];
    this.periodSelected = 0;    
    const d = new Date();
    let y = d.getFullYear();
    this.year = y;
    this.today = d;
    this.resetForm = true;
    this.locationService.getAll().subscribe(data => {
      this.locations = data;
      this.weekKalcorService.getYears().subscribe(y => {
        this.years = y;
        this.getDate();
      })
    })
    setTimeout(() => this.staticAlertClosed = true, 20000);
    this.success.subscribe((message) => this.successMessage = message);
    this.success.pipe(
      debounceTime(5000)
    ).subscribe(() => this.successMessage = null);
  }
  getDate() {
    this.periodOptions = [];
    this.weekKalcorService.getYear(this.year).subscribe(data => {
      let p = 1;
      let startDate = data[0].weekStartDate; 
      let endDate = data[0].weekEndDate;
      
      data.forEach(e => {
        if (e.period == p) {
          endDate = e.weekEndDate;
        } else {
          const periodOpt = {
            period: p,
            weekStart: startDate,
            weekEnd: endDate
          }; 
          this.periodOptions.push(periodOpt);
          startDate = e.weekStartDate;
          p = e.period;         
        }
      })
      const totalOptions = this.periodOptions.length;
      const lastIndex = totalOptions -1;
      if (this.periodOptions[lastIndex].period != p) {
        if (p != 0) {
          const periodOpt = {
            period: p,
            weekStart: startDate,
            weekEnd: endDate
          }; 
          this.periodOptions.push(periodOpt);
        }       
      }
      const yearStart = this.periodOptions[0].weekStart;
      const yearEnd = this.periodOptions[this.periodOptions.length -1].weekEnd;
        const periodOpt = {
          period: 'All',
          weekStart: yearStart,
          weekEnd: yearEnd
        };
        this.periodOptions.push(periodOpt);
        if (this.resetForm == true) {
          this.periodSelected = this.periodOptions.length;
          this.periodChange('All');
          this.resetFilters();  
        }
    })
  }
  yearChange() {
    if (this.year * 1 > 999) {
      this.resetForm = false;
      this.getDate();
    } 
      return;
  }
  periodChange(e) {
    if (e == 'All') {
      this.periodStartDate = this.periodOptions[this.periodOptions.length -1].weekStart;
      this.periodEndDate = this.periodOptions[this.periodOptions.length -1].weekEnd;
    } else {
    e--;
    if (e < 0) {
      return;
    }
    this.periodStartDate = this.periodOptions[e].weekStart;
    this.periodEndDate = this.periodOptions[e].weekEnd;
    }
  }
  getDefaultDataScreen() {
    this.periods = [];
    this.periodOptions = [];
    this.periodSelected = 0;
    this.vendorSelected = 0;
    this.orderStatusSelected = '';
    this.orderTypeSelected = 'Stock';
    this.custSelected = 0;
    this.shipSelected = 0;
    this.salesNoSelected = '';
    this.poNoSelected = '';    
    this.page = 1;
    const d = new Date();
    let y = d.getFullYear();
    this.year = y;
    this.resetForm = true;
    this.getDate();
  }
  loadPage($event){
    this.resetFilters();
  }
  resetFilters() {
    if (this.periodSelected == 0) {
      this.success.next('Please select a period');
      this.alertType = "danger";
      return;
    }
    this.orderService.getLotBB(this.periodStartDate, this.periodEndDate, this.orderStatusSelected, 
      this.orderTypeSelected, this.custSelected, this.shipSelected, this.vendorSelected, this.salesNoSelected,
      this.poNoSelected, this.page, this.pageSize).subscribe(data => {
        this.orders = data.order;
        this.orders = this.sort2Service.sorter(this.orders, 'salesOrderNo', 'bbDate1');
        this.collectionSize = data.count;
        this.page = data.page;
        this.custOptions = data.item2;
        this.shipOptions = data.item3;
        this.venOptions = data.item4;
        this.salesNoOptions = data.item5;
        this.poNoOptions = data.item6;
      })
  }
  refreshOrders() {
    this.orderService.getLotBB(this.periodStartDate, this.periodEndDate, this.orderStatusSelected, 
      this.orderTypeSelected, this.custSelected, this.shipSelected, this.vendorSelected, this.salesNoSelected,
      this.poNoSelected, this.page, 999).subscribe(data => {
        this.orders = data.order;
        this.orders = this.sort2Service.sorter(this.orders, 'salesOrderNo', 'bbDate1');
        this.exportToExcel();
      })
  }
  exportToExcel() {
    const excelData = [];
    this.orders.forEach(e => {
      const temp = {
        orderStatus: e.orderStatus,
        orderType: e.orderType,
        vendor: e.vendor,
        location: this.getLocation(e.locationId),
        customer: e.billTo,
        shipTo: e.shipTo,
        orderCode: e.orderCode,
        brandCategory: e.brandCategory,
        itemDescription: e.itemDescription,
        size: e.size,
        cases: e.cases,
        shipped: e.shipped,
        invoiced: e.invoiced,
        salesOrderNo: e.salesOrderNo,
        purchaseOrder: e.purchaseOrder,
        salesOrderDate: this.displayDate(e.salesOrderDate, ''),
        shipmentDate: this.displayDate(e.shipmentDate, ''),
        deliveredDate: this.displayDate(e.deliveredDateAppt, ''),
        lotNo: e.lotNo1,
        bbDate: this.displayDate(e.bbDate1, '')
      }
      excelData.push(temp);
    });
    this.excelService.generateExcel(excelData, 'Lot-BB');
  }
  getLocation(id) {
    let ret = '';
    if (id) {
      const index = this.locations.findIndex(item => item.id == id);
      if (index > -1) {
        ret = this.locations[index].location;
      }
    }
    return ret;
  }
  displayDate(dt, t) {
    
    if (dt) {
      if (t === undefined) {
        t = '';
      }
      let x = new Date(dt);
      if (t == 'T') {
        return x.toLocaleDateString() + ' @ ' + x.toLocaleTimeString();
      }
      return x.toLocaleDateString();
    } 
    return dt

  }
}
