import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Roles } from '../../../models/roles.model';
import { RolesService } from '../../../services/roles.service';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
@Component({
  selector: 'app-roles-edit',
  templateUrl: './roles-edit.component.html',
  styleUrls: ['./roles-edit.component.css']
})
export class RolesEditComponent implements OnInit {
  title = 'Role New';
  newOne = true;
  role: Roles;
  roleForm: UntypedFormGroup;
  successMessage: string;
  alertType = "success";
  staticAlertClosed = false;

  private success = new Subject<string>();

  constructor(private route: ActivatedRoute,
              private router: Router,
              private fb: UntypedFormBuilder,
              private roleService: RolesService
              ) { }

  ngOnInit(): void {
    this.roleForm = this.fb.group({
      roleName: [''],
      dashboard: [''],
      sales: [''],
      customClearing: [''],
      accounting: [''],
      customer: [''],
      vendor: [''],
      other: [''],
      items: [''],
      products: [''],
      productListing: [''],
      projections: [''],
      reports: ['']
    })
    this.route.data.subscribe(data => {
      if (data.dataRoles !== undefined) {
        this.title = 'Role Edit';
        this.newOne = false;
        this.role = data.dataRoles;
        this.fillFormData();
      } else {
        this.title = 'Role New';
        this.newOne = true;
        this.role = new Roles();
        this.roleForm.reset();
      }

    })
    setTimeout(() => this.staticAlertClosed = true, 20000);
    this.success.subscribe((message) => this.successMessage = message);
    this.success.pipe(
      debounceTime(5000)
    ).subscribe(() => this.successMessage = null);    
  }
  fillFormData() {
    this.roleForm.setValue({
      roleName: this.role.roleName,
      dashboard: this.role.dashboard ? true : false,
      sales: this.role.sales ? true : false,
      customClearing: this.role.customClearing ? true : false,
      accounting: this.role.accounting ? true : false,
      customer: this.role.customer ? true : false,
      vendor: this.role.vendor ? true : false,
      other: this.role.other ? true : false,
      items: this.role.items ? true : false,
      products: this.role.products ? true : false,
      productListing: this.role.productListing ? true : false,
      projections: this.role.projections ? true : false,
      reports: this.role.reports ? true : false
    });
  }
  onSubmit() {
    this.role.roleName = this.roleForm.controls.roleName.value;
    this.role.dashboard = this.roleForm.controls.dashboard.value ? 1 : 0;
    this.role.sales = this.roleForm.controls.sales.value ? 1 : 0;
    this.role.customClearing = this.roleForm.controls.customClearing.value ? 1 : 0;
    this.role.accounting = this.roleForm.controls.accounting.value ? 1 : 0;
    this.role.customer = this.roleForm.controls.customer.value ? 1 : 0;
    this.role.vendor = this.roleForm.controls.vendor.value ? 1 : 0;
    this.role.other = this.roleForm.controls.other.value ? 1 : 0;
    this.role.items = this.roleForm.controls.items.value ? 1 : 0;
    this.role.products = this.roleForm.controls.products.value ? 1 : 0;
    this.role.productListing = this.roleForm.controls.productListing.value ? 1 : 0;
    this.role.projections = this.roleForm.controls.projections.value ? 1 : 0;
    this.role.reports = this.roleForm.controls.reports.value ? 1 : 0;


    if (this.newOne) {
      this.roleService.create(this.role).subscribe(data => {
        this.role.roleid = data.roleid;
        this.newOne = false;
        this.title = 'Role Edit';
        this.success.next(`Successfully added.`);
      }, error => {
      });
    } else {
      this.roleService.update(this.role).subscribe(data => {
        this.success.next(`Successfully changed.`);
      }, error => {
      });
    }
  }  
  onDelete() {
    if (this.role.roleid) {
      this.roleService.remove(this.role.roleid).subscribe(data => {
        this.router.navigate(['/admin/roles-list']);
      });
    }
  }
}
