import { Component, OnInit } from '@angular/core';
import { TypeOfAccountService } from '../../../services/type-of-account.service';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
@Component({
  selector: 'app-type-of-account-list',
  templateUrl: './type-of-account-list.component.html',
  styleUrls: ['./type-of-account-list.component.css']
})
export class TypeOfAccountListComponent implements OnInit {
  typeOfAccount: any;
  page = 1;
  pageSize = 30;
  successMessage: string;
  private success = new Subject<string>();
  alertType = "success";
  staticAlertClosed = false;
  constructor(
    private router: Router,
    private typeOfAcctSvc: TypeOfAccountService
  ) { }

  ngOnInit(): void {
    this.typeOfAcctSvc.getAll().subscribe(data => {
      this.typeOfAccount = data
    },error => {
      this.successMessage = 'Unable to process request';
      this.alertType = "danger";
    })
    setTimeout(() => this.staticAlertClosed = true, 20000);
    this.success.subscribe((message) => this.successMessage = message);
    this.success.pipe(
      debounceTime(5000)
    ).subscribe(() => this.successMessage = null); 
  }
  onRowClicked(typeOfAccount) {
    this.router.navigate(['/other/type-of-account-edit', typeOfAccount.accountTypeId]);
  }
}
