import { Component, OnInit } from '@angular/core';
import { PickupLocationService } from '../../../../services/pickup-location.service';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
@Component({
  selector: 'app-pickup-location-list',
  templateUrl: './pickup-location-list.component.html',
  styleUrls: ['./pickup-location-list.component.scss']
})
export class PickupLocationListComponent implements OnInit {
  pickupLocation: any;
  activeItemSelected: string;
  vendorSelected: number;
  vendorOptions:[];
  companySelected: number;
  companyOptions:[];
  page: number;
  pageSize: number;
  count: number;
  successMessage: string;
  private success = new Subject<string>();
  alertType = "success";
  staticAlertClosed = false;
  constructor(private router: Router,
              private pickupLocationService: PickupLocationService) { }

  ngOnInit() {
    this.reset();
    setTimeout(() => this.staticAlertClosed = true, 20000);
    this.success.subscribe((message) => this.successMessage = message);
    this.success.pipe(
      debounceTime(5000)
    ).subscribe(() => this.successMessage = null); 
  }
  loadPage() {
    this.pickupLocationService.getFilteredListing(this.activeItemSelected, this.companySelected, this.vendorSelected, this.page, this.pageSize).subscribe(data => {
      this.pickupLocation = data.item;
      this.vendorOptions  = data.item2;
      this.companyOptions = data.item3;
      this.count = data.count;
    },error => {
      this.successMessage = 'Unable to process request';
      this.alertType = "danger";
    });
  }
  reset() {
    this.page = 1;
    this.pageSize = 30;
    this.activeItemSelected = 'Active';
    this.companySelected = 0;
    this.vendorSelected = 0;
    this.loadPage();    
  }
  onRowClicked(clear) {
    this.router.navigate(['/vendors/pickup-location-edit', clear.id]);
  }
}
