import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { CustomerRetail } from '../../../models/customer-retail.model';
import { CustomerRetailService } from '../../../services/customer-retail.service';

@Injectable()
export class CustomerRetailResolver implements Resolve<CustomerRetail> {
  constructor(private customerRetailService: CustomerRetailService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<CustomerRetail> {
    const idField = 'id';
    const id =  parseInt(route.params[idField], 10);

    if (id === 0) {
      return;
    }
    return this.customerRetailService.getOne(id);
  }
}
