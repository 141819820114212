<h5>{{ title }} </h5>
<form [formGroup]="warehouseForm" (ngSubmit)="onSubmit()" novalidate>
  <div class="form-group">
    <div class="col">
      <label>
        Active Item:
        <select formControlName="activeItem" class="form-control">
          <option value="Active">Active</option>
          <option value="Not Active">Not Active</option>
        </select>
      </label>
    </div>

    <div class="col-lg-24">
      <label>
        company:
        <input type="text" formControlName="company" class="form-control"  size="60">
      </label>
    </div>

    <div class="col-lg-24">
      <label>
        Address:
        <input type="text" formControlName="address" class="form-control" size="60">
      </label>
    </div>

    <div class="col-lg-24">
      <label>
        City:
        <input type="text" formControlName="city" class="form-control" size="60">
      </label>
    </div>

    <div class="col">
      <label>
        State/Province:
        <input type="text" formControlName="stateProvince" class="form-control">
      </label>

      <label class="ms-3">
        Country/Region:
        <input type="text" formControlName="countryRegion" class="form-control">
      </label>

      <label class="ms-3">
        Zip/Postal Code:
        <input type="text" formControlName="zipPostalCode" class="form-control">
      </label>
    </div>

    <div class="col-lg-24">
      <label>
        Full Name:
        <input type="text" formControlName="fullName" class="form-control" size="60">
      </label>
    </div>

    <div class="col-lg-24">
      <label>
        Job Title:
        <input type="text" formControlName="jobTitle" class="form-control" size="60">
      </label>
    </div>
    <div class="col">
      <label>
        Business Phone:
        <input type="text" formControlName="businessPhone" class="form-control">
      </label>

      <label class="ms-3">
        Fax:
        <input type="text" formControlName="faxNumber" class="form-control">
      </label>

      <label class="ms-3">
        Mobile:
        <input type="text" formControlName="mobileNumber" class="form-control">
      </label>
    </div>

    <div class="col-lg-24">
      <label>
        e-Mail:
        <input type="text" formControlName="eMail" class="form-control" size="60">
      </label>
    </div>

    <div class="col-lg-24">
      <label>
        Web Page:
        <input type="text" formControlName="webPage" class="form-control" size="60">
      </label>
    </div>

    <div class="col-lg-24">
      <label>
        Comments:
        <input type="text" formControlName="comments" class="form-control" size="60">
      </label>
    </div>

    <div class="col-lg-24">
      <label>
        Warehouse Number:
        <input type="text" formControlName="whsNo" class="form-control" size="60">
      </label>
    </div>

    <div class="col-lg-24">
      <label>
        Federal Establishment:
        <input type="text" formControlName="federalEstablishment" class="form-control" size="60">
      </label>
    </div>

    <div class="col">
      <label>
       Terms:
        <select formControlName="termsId" class="form-control">
          <option *ngFor="let t of term" [value]="t.id">{{t.paymentTerms}}</option>
        </select>
      </label>
    </div>

    <div class="col">
      <label>
        Currency Code:
        <select formControlName="currencyCode" class="form-control">
          <option value="USD">USD</option>
          <option value="CAN">CAN</option>
        </select>
      </label>
    </div>

    <button type="submit" class="btn btn-primary mt-3 w-25">Save</button>
    <button *ngIf="!newOne" type="button" class="btn btn-secondary ms-3 mt-3 w-25" (click)="onDelete()">Delete</button>
    </div>
     <ngb-alert *ngIf="successMessage" type="success" (close)="successMessage = null">{{ successMessage }}</ngb-alert>
</form>

