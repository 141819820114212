<h5>{{ title }} </h5>
<form [formGroup]="userForm" (ngSubmit)="onSubmit()" novalidate>
  <div class="form-group">
    <div class="col-lg-24">
      <label>
        User Name:
        <input type="text" formControlName="userinitials" class="form-control"  size="60">
      </label>
    </div>
    <div class="col-lg-24">
        <label>
          First Name:
          <input type="text" formControlName="firstName" class="form-control"  size="60">
        </label>
    </div>
  
    <div class="col-lg-24">
        <label>
          Last Name:
          <input type="text" formControlName="lastName" class="form-control"  size="60">
        </label>
    </div>

    <div class="col-lg-24">
        <label>
            Department:
          <input type="text" formControlName="department" class="form-control"  size="60">
        </label>
    </div>

    <div class="col-lg-24">
        <label>
            Address
            <input type="text" formControlName="address" class="form-control"  size="60">
        </label>
    </div>

    <div class="col-lg-24">
        <label>
            City:
          <input type="text" formControlName="city" class="form-control"  size="60">
        </label>
    </div>

    <div class="col-lg-24">
        <label>
            State/Province:
          <input type="text" formControlName="stateProvince" class="form-control"  size="60">
        </label>
    </div>

    <div class="col-lg-24">
        <label>
            Postal Code:
          <input type="text" formControlName="postalCode" class="form-control"  size="60">
        </label>
    </div>

    <div class="col-lg-24">
        <label>
            Country/Region:
          <input type="text" formControlName="countryRegion" class="form-control"  size="60">
        </label>
    </div>

    <div class="col-lg-24">
        <label>
            E-mail:
          <input type="text" formControlName="email" class="form-control"  size="60">
        </label>
    </div>

    <div class="col-lg-24">
        <label>
            Mobile:
          <input type="text" formControlName="mobile" class="form-control"  size="60">
        </label>
    </div>

    <div class="col-lg-24">
        <label>
            Phone:
          <input type="text" formControlName="phone" class="form-control"  size="60">
        </label>
    </div>
    <div class="col-lg-24">
      <label>
        Roles:
        <select multiple formControlName="roleIds" class="form-control">
          <option *ngFor="let r of roles" [value]="r.roleid">{{r.roleName}}</option>
        </select>
      </label>
    </div>    
    <div class="col-lg-24">
      <label for="vendorId">
          Vendor:
      </label>
      <div class="col-lg-4">
        <select formControlName="vendorId" class="form-control">
          <option [value]=""></option>
          <option *ngFor="let v of vendors" [value]="v.id">{{ v.company }}</option>
      </select>
      </div>
  </div>

    <div class="row">
        <div class="col-4">
            <button type="submit" class="btn btn-primary mt-3 w-100">Save</button>
        </div>
        <div class="col-4">
            <button type="button" class="btn btn-secondary ms-3 mt-3 w-100" (click)="openModal()">Set Password</button>
        </div>
        <div class="col-4">
            <button type="button" class="btn btn-secondary ms-3 mt-3 w-100" (click)="onDelete()">Delete</button>
        </div>
    </div>
    <div class="backdrop" [ngStyle]="{'display':display}"></div>

    <div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':display}">
        <div class="modal-dialog modal-lg" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title mx-auto">{{ modalTitle }}</h4>
            </div>
            <div class="modal-body">
              <div class="container-fluid">
                <div class="row">
                    <label for="my-password" class="col-md-4 pt-2 px-2">Password:</label>
                    <input id="my-password" type="password" formControlName="userpassword" class="form-control col-md-6 px-0">
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-link" (click)="onUpdatePassword()">Save</button>
                    <button type="button" class="btn btn-default" (click)="onCloseHandled()">Cancel</button>
                </div>
                <ngb-alert *ngIf="successMessage2" type="success" (close)="successMessage2 = null">{{ successMessage2 }}</ngb-alert>
              </div>
            </div>
          </div><!-- /.modal-content -->
        </div><!-- /.modal-dialog -->
    </div><!-- /.modal !-->
  </div>
  <ngb-alert *ngIf="successMessage" type="success" (close)="successMessage = null">{{ successMessage }}</ngb-alert>
</form>