<h4>Brand List </h4>
<h5>Filter</h5>
<div class="col-lg-44">
  <label>
    Active:
    <select [(ngModel)]="activeItemSelected" (change)="loadPage()">
      <option value="Active">Active</option>
      <option value="Not Active">Not Active</option>
    </select>
  </label>
  <label class="ml-3">
    Vendor:
    <select [(ngModel)]="vendorSelected" (change)="loadPage()">
      <option [value]=""></option>
      <option *ngFor="let v of vendorOptions" [value]="v.vendorId">{{ v.company }}</option>
    </select>
  </label>
  <label class="ml-3">
      Brand:
     <select [(ngModel)]="brandSelected" (change)="loadPage()">
      <option [value]=""></option>
      <option *ngFor="let c of brandOptions" [value]="c.id">{{ c.brand }}</option>
    </select>
  </label>
  <button type="button" class="btn btn-primary mt-0 ml-3" (click)="reset()">Reset</button>
</div>

<table class="table table-striped" style="overflow-x:scroll;">
    <thead>
    <tr>
      <th scope="col">Active</th>
      <th scope="col">Vendor</th>
      <th scope="col">Brand</th>
      </tr>
    </thead>
    <tbody>
    <tr *ngFor="let b of brand; index as i" (click)="onRowClicked(b)">
      <td>{{ b.activeItem }}</td>
      <td>{{ b.company }}</td>
      <td>{{ b.brand }}</td>
    </tr>
    </tbody>
  </table>
  <div *ngIf="brand!==undefined">
    <ngb-pagination [(page)]="page" [pageSize]="pageSize" [collectionSize]="count" (pageChange)="loadPage()">
    </ngb-pagination>
  </div>
