import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { Term } from '../../models/term-model';
import { TermsService } from '../../services/terms.service';

@Injectable()
export class TermsResolver implements Resolve<Term> {
    constructor(private termsService: TermsService) {
    }
  
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Term> {
      const idField = 'id';
      const id =  parseInt(route.params[idField], 10);
  
      if (id === 0) {
        return;
      }
      return this.termsService.getOne(id);
    }
  }
  