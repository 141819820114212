import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { BorderCrossing } from '../../../models/border-crossing.model';
import { BorderCrossingService } from '../../../services/border-crossing.service';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-border-crossing-edit',
  templateUrl: './border-crossing-edit.component.html',
  styleUrls: ['./border-crossing-edit.component.scss']
})
export class BorderCrossingEditComponent implements OnInit {
  title = 'Border Crossing';
  newOne = true;
  borderCrossing: BorderCrossing;
  borderCrossingForm: UntypedFormGroup;
  successMessage: string;
  private success = new Subject<string>();
  staticAlertClosed = false;
  constructor(    
    private route: ActivatedRoute,
    private router: Router,
    private fb: UntypedFormBuilder,
    private borderCrossingService: BorderCrossingService
    ) { }

  ngOnInit() {
    this.borderCrossingForm = this.fb.group({
      area: [''],
      canadaPortOfEntryName: [''],
      canadaRoadHighway: [''],
      unitedStatesPortOfEntryName: [''],
      unitedStatesRoadHighwayCommunity: [''],
      notes: [''],
      structureOrNotableFeature: [''],
      coordinates: ['']
    })    
    this.route.data.subscribe(data => {
      if (data.dataBorderCrossing !== undefined) {
        this.title = 'Border Crossing Edit';
        this.newOne = false;
        this.borderCrossing = data.dataBorderCrossing;
        this.fillFormData();
      } else {
        this.title = 'Border Crossing New';
        this.newOne = true;
        this.borderCrossing = new BorderCrossing();
        this.borderCrossingForm.reset();
      }
    });
    setTimeout(() => this.staticAlertClosed = true, 20000);
    this.success.subscribe((message) => this.successMessage = message);
    this.success.pipe(
      debounceTime(5000)
    ).subscribe(() => this.successMessage = null);     
  }
  fillFormData() {
    this.borderCrossingForm.setValue({
      area: this.borderCrossing.area,
      canadaPortOfEntryName: this.borderCrossing.canadaPortOfEntryName,
      canadaRoadHighway: this.borderCrossing.canadaRoadHighway,
      unitedStatesPortOfEntryName: this.borderCrossing.unitedStatesPortOfEntryName,
      unitedStatesRoadHighwayCommunity: this.borderCrossing.unitedStatesRoadHighwayCommunity,
      notes:  this.borderCrossing.notes,
      structureOrNotableFeature: this.borderCrossing.structureOrNotableFeature,
      coordinates: this.borderCrossing.coordinates
    });
  }
  onSubmit() {
    this.borderCrossing.area = this.borderCrossingForm.controls.area.value;
    this.borderCrossing.canadaPortOfEntryName = this.borderCrossingForm.controls.canadaPortOfEntryName.value;
    this.borderCrossing.canadaRoadHighway = this.borderCrossingForm.controls.canadaRoadHighway.value;
    this.borderCrossing.unitedStatesPortOfEntryName = this.borderCrossingForm.controls.unitedStatesPortOfEntryName.value;
    this.borderCrossing.unitedStatesRoadHighwayCommunity = this.borderCrossingForm.controls.unitedStatesRoadHighwayCommunity.value;
    this.borderCrossing.notes = this.borderCrossingForm.controls.notes.value;
    this.borderCrossing.structureOrNotableFeature = this.borderCrossingForm.controls.structureOrNotableFeature.value;
    this.borderCrossing.coordinates = this.borderCrossingForm.controls.coordinates.value;
    if (this.newOne) {
        this.borderCrossingService.create(this.borderCrossing).subscribe(data => {
          this.borderCrossing.id =  data.id;
          this.newOne = false;
          this.title = 'Border Crossing Edit';
          this.success.next(`Successfully added.`);
        }, error => {
        });
      } else {
        this.borderCrossingService.update(this.borderCrossing).subscribe(data => {
          this.success.next(`Successfully changed.`);
        }, error => {
        });
      }
    }
  onDelete() {
    if (this.borderCrossing.id) {
      this.borderCrossingService.remove(this.borderCrossing.id).subscribe(data => {
        this.router.navigate(['/other/border-crossing-list']);
      });
    }
  }      
}
