import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { VendorData } from '../models-azure/vendor-data.model';

const url = environment.kalcorApiUrl;

@Injectable({
  providedIn: 'root'
})
export class AzureService {

  constructor(private http: HttpClient) { }

  getAll(): Observable<any> {
    return this.http.get(url + 'VendorDatas', this.jwt());
  }
  getOne(id: number): Observable<any> {
    return this.http.get(url + 'VendorDatas/' + id, this.jwt());
  }
  getFilter(year: number, vendorId: number, page: number, pageSize: number): Observable<any> {
    page = page ? page : 1;
    pageSize = pageSize ? pageSize : 200;
    return this.http.get(url + 'VendorDatas/Filter?year=' + year + '&vendorId=' + vendorId + '&page='
    + page + '&pageSize=' + pageSize, this.jwt());
  }
  update(vendorData) {
    return this.http.put(url + 'VendorDatas/' + vendorData.Id, vendorData, this.jwt());
  }
  create(vendorData): Observable<any> {
    return this.http.post(url + 'VendorDatas', vendorData, this.jwt());
  }
  remove(id) {
    return this.http.delete(url + 'VendorDatas/' + id, this.jwt());
  }


  private jwt() {
    // create authorization header with jwt token
    const currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
    if (currentUser && currentUser.token) {
        const headersInfo = new HttpHeaders({ Authorization: 'Bearer ' + currentUser.token });
        return { headers: headersInfo };
    }
  }
}
