import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { GstHst } from 'src/app/models/gst-hst.model';
import { GstHstService } from 'src/app/services/gst-hst.service';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-gst-hst-edit',
  templateUrl: './gst-hst-edit.component.html',
  styleUrls: ['./gst-hst-edit.component.scss']
})
export class GstHstEditComponent implements OnInit {
  title = 'GST/HST';
  newOne = true;
  gstHst: GstHst;
  gstHstForm: UntypedFormGroup;
  successMessage: string;
  private success = new Subject<string>();
  staticAlertClosed = false;
  alertType = 'success';

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private fb: UntypedFormBuilder,
    private gstHstService: GstHstService
  ) { }

  ngOnInit(): void {
    this.gstHstForm = this.fb.group({
      taxName: ['', Validators.required],
      rate: ['']
    })
    this.route.data.subscribe(data => {
      if (data.dataGstHst !== undefined) {
        this.title = 'GST/HST Edit';
        this.newOne = false;
        this.gstHst = data.dataGstHst;
        this.fillFormData();
      } else {
        this.title = 'GST/HST New';
        this.newOne = true;
        this.gstHst = new GstHst();
        this.gstHstForm.reset();
      }
    });
    setTimeout(() => this.staticAlertClosed = true, 20000);
    this.success.subscribe((message) => this.successMessage = message);
    this.success.pipe(
      debounceTime(5000)
    ).subscribe(() => this.successMessage = null);

  }

  fillFormData() {
    this.gstHstForm.setValue({
      taxName: this.gstHst.taxName,
      rate: this.gstHst.rate
    });
  }
  onSubmit() {
    if (this.gstHstForm.invalid) {
      this.success.next('Please enter required fields.');
      this.alertType = "danger";
    }
    this.gstHst.taxName = this.gstHstForm.controls.taxName.value;
    this.gstHst.rate = this.gstHstForm.controls.rate.value;
    if (this.newOne) {
      this.gstHstService.create(this.gstHst).subscribe(data => {
        this.gstHst.id = data.id;
        this.newOne = false;
        this.title = 'GST/HST Edit';
        this.success.next('Successfully added.');
      }, error => {
      });
    } else {
      this.gstHstService.update(this.gstHst).subscribe(data => {
        this.success.next('Successfully changed.');
      }, error => {
      });
    }
  }
  onDelete() {
    if (this.gstHst.id) {
      this.gstHstService.remove(this.gstHst.id).subscribe(data => {
        this.router.navigate(['/other/gst-hst-list']);
      })
    }
  }
}
