import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { Projection } from '../models/projection-model';
import { Url } from 'url';
import { error } from 'console';
const url = environment.kalcorApiUrl;


@Injectable({
  providedIn: 'root'
})
export class ProjectionsService {

  constructor(private http: HttpClient) { }
  getAll(): Observable<any> {
    return this.http.get(url + 'Projections', this.jwt());
  }
  getOne(id: number): Observable<any> {
    return this.http.get(url + 'Projections/' + id, this.jwt());
  }
  getConnectedPromotions(id: number): Observable<any> {
    return this.http.get(url + 'Projections/ConnectedPromotions/' + id, this.jwt());
  }
  getConnectedBudgetPromotions(id: number): Observable<any> {
    return this.http.get(url + 'Projections/ConnectedBudgetPromotions/' + id, this.jwt());
  }
  getPromotion(customerId: number, vendorId: number, headId: number, bannerId: number, year: number, promotion: string): Observable<any> {
    return this.http.get(url + 'Projections/Promotion' + '?customerId=' + customerId + '&vendorId=' + vendorId +
    '&headId=' + headId + '&bannerId=' + bannerId + '&year=' + year + '&promo=' + promotion, this.jwt());
  }
  getYearBudget(vendorId: number, year: number): Observable<any> {
    return this.http.get(url + 'Projections/YearBudget?year=' + year + '&vendorId=' + vendorId, this.jwt());
  }
  getFilteredList(id: number, vendorId: number, customerId: number, headOfficeId: number, bannerId: number, year: number, promotionType: string, page: number, pageSize: number): Observable<any> {
    page = page ? page : 1;
    pageSize = pageSize ? pageSize : 30;
    return this.http.get(url + 'Projections/Filter?id=' + id + '&vendorId=' + vendorId + '&customerId=' + customerId + '&headOfficeId=' + headOfficeId + '&bannerId=' + bannerId + '&year=' + year + '&promotionType=' + 
    promotionType.trim() + '&page=' + page + '&pageSize=' + pageSize, this.jwt());
  }
  getOrder(customerId: number, vendorId: number, headId: number, bannerId: number, year: number, promotion: string): Observable<any> {
    return this.http.get(url + 'Projections/Order' + '?customerId=' + customerId + '&vendorId=' + vendorId +
      '&headId=' + headId + '&bannerId=' + bannerId + '&year=' + year + '&promo=' + promotion, this.jwt());
  }
  getOrderCode(customerId: number, vendorId: number, headId: number, bannerId: number, year: number, promotion: string, orderCode: string ): Observable<any> {
    return this.http.get(url + 'Projections/OrderCode' + '?customerId=' + customerId + '&vendorId=' + vendorId +
      '&headId=' + headId + '&bannerId=' + bannerId + '&year=' + year + '&promo=' + promotion + '&orderCode=' + orderCode, this.jwt());
  }
  getOrderCustomer(vendorId: number, customerId: number, bannerId: number, year: number, promotion: string): Observable<any> {
    return this.http.get(url + 'Projections/OrderCustomer' + '?vendorId=' + vendorId + '&customerId=' + customerId + '&bannerId=' + bannerId + '&year=' + year + '&promo=' + promotion, this.jwt());
  }

  update(projection) {
    return this.http.put(url + 'Projections/' + projection.id, projection, this.jwt());
  }
  create(projection): Observable<any> {
    return this.http.post(url + 'Projections', projection, this.jwt());
  }
  remove(id: number) {
    return this.http.delete(url + 'Projections/' + id, this.jwt());
  }

  private jwt() {
    // create authorization header with jwt token
    const currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
    if (currentUser && currentUser.token) {
        const headersInfo = new HttpHeaders({ Authorization: 'Bearer ' + currentUser.token });
        return { headers: headersInfo };
    }
  }

}
