<h5>Listing Setup List</h5>
<div class="col-lg-44">
<div class="smallSpacer"><h6>Filter</h6></div>
<div class="col-lg-44">
<label class="formControlName ms-3">
  Vendor:
  <select class="form-control" [(ngModel)]="vendorSelected" (change)="resetFilters()">
    <option [value]="0"></option>
    <option *ngFor="let o of vendors" [value]="o.vendorId">{{ o.vendor }}</option>
  </select>
</label>
  <label class="formControlName ms-3">
  Type of Costing:
  <select class="form-control" [(ngModel)]="activeListingsSelected" (change)="resetFilters()">
    <option [value]=""></option>
    <option *ngFor="let o of activeListingsOptions" [value]="o">{{ o }}</option>
  </select>
  </label>  
  <label class="formControlName ms-3">
  Order Code:
  <select class="form-control" [(ngModel)]="orderCodeSelected" (change)="resetFilters()">
    <option [value]="0"></option>
    <option *ngFor="let o of orderCodes" [value]="o.productOrderCodeId">{{ o.orderCode }}</option>
  </select>
</label>
<label class="formControlName ms-3">
  Brand:
  <select class="form-control" [(ngModel)]="brandSelected" (change)="resetFilters()">
    <option [value]="0"></option>
    <option *ngFor="let o of brands" [value]="o.brandId">{{ o.brand }}</option>
  </select>
</label>
<label class="formControlName ms-3">
  Group:
  <select class="form-control" [(ngModel)]="groupSelected" (change)="resetFilters()">
    <option [value]="0"></option>
    <option *ngFor="let o of groups" [value]="o.productGroupId">{{ o.productGroupNo }}</option>
  </select>
</label>
<div class="smallSpacer"></div>
  <button type="button" class="btn btn-primary mt-0 ms-3" (click)="getDefaultDataScreen()">Reset</button>
</div>
</div>
<div class="spacer"></div>
<table class="table table-striped">
  <thead>
  <tr>
    <th scope="col">Active Price List</th>
    <th scope="col">Product Order Code</th>
    <th scope="col">Cost Type</th>
    <th scope="col">Group</th>
    <th scope="col">Item Description</th>
    <th scope="col">Category</th>
    <th scope="col">Size</th>
    </tr>
  </thead>
  <tbody>
  <!-- <tr *ngFor="let p of products | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize"> -->
    <tr *ngFor="let p of products">
    <td>{{ p.activeListings }}</td>
    <td><a class="pointer" (click)="onRowClicked(p)">
      <u class="text-primary">{{ p.orderCode }}</u>
      </a>
      </td>
    <td>{{ p.costType }}</td>
    <td>{{ p.productGroupNo }}</td>
    <td>{{ p.itemDescription }}</td>
    <td>{{ p.category }}</td>
    <td>{{ p.size }}</td>
  </tr>
  </tbody>
</table>
<div *ngIf="products!==undefined">
  <ngb-pagination
  [(page)]="page"
  [pageSize]="pageSize"
  [collectionSize]="collectionSize"
  [maxSize]="5"
  (pageChange)="loadPage($event)"
  ></ngb-pagination>

  <div class="btn-group" role="group" aria-label="Basic radio toggle button group">
      
    <input class="btn-check" type="radio" name="radio1" id="choice1" [(ngModel)]="pageSize"  (change)="loadPage($event)" autocomplete="off" checked [value]="25">
    <label class="btn btn-outline-primary" for="choice1">
      25
    </label>
  
    <input class="btn-check" type="radio" name="radio1" id="choice2" [(ngModel)]="pageSize"  (change)="loadPage($event)" [value]="50" autocomplete="off">
    <label class="btn btn-outline-primary" for="choice2">
      50
    </label>
  
    <input class="btn-check" type="radio" name="radio1" id="choice3" [(ngModel)]="pageSize"  (change)="loadPage($event)" [value]="100" autocomplete="off">
    <label class="btn btn-outline-primary" for="choice3">
      100
    </label>
  
  </div>

  