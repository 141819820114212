<h4>Projection List </h4>
<h5>Filter</h5>
<div class="col-lg-44">

  <label>
    Vendor:
    <select [(ngModel)]="vendorSelected" (change)="loadPage()">
        <option [value]=""></option>
        <option *ngFor="let v of vendorOptions" [value]="v.vendorId">{{ v.vendor }}</option>
    </select>
  </label>
  <label class="ms-3">
    Customer:
    <select [(ngModel)]="customerSelected" (change)="loadPage()">
        <option [value]=""></option>
        <option *ngFor="let c of customerOptions" [value]="c.customerId">{{ c.customer }}</option>
    </select>
  </label>
  <label class="ms-3">
    Customer Head Office:
    <select [(ngModel)]="headOfficeSelected" (change)="loadPage()">
        <option [value]=""></option>
        <option *ngFor="let h of headOfficeOptions" [value]="h.customerEndId">{{ h.customerEnd }}</option>
    </select>
  </label>
  <label class="ms-3">
    Banner:
    <select [(ngModel)]="bannerSelected" (change)="loadPage()">
        <option [value]=""></option>
        <option *ngFor="let b of bannerOptions" [value]="b.bannerId">{{ b.banner }}</option>
    </select>
  </label>
  <label class="ms-3">
    Year:
    <select [(ngModel)]="yearSelected" (change)="loadPage()">
        <option *ngFor="let y of yearOptions" [value]="y">{{ y }}</option>
    </select>
  </label>
  <label class="ms-3">
    Promotion Type:
    <select [(ngModel)]="typeSelected" (change)="loadPage()">
        <option value="Budget">Budget</option>
        <option value="Budget Contingency">Budget Contingency</option>
        <option value="Budget Promotion">Budget Promotion</option>
    </select>
  </label>
 
<button type="button" class="btn btn-primary mt-0 ms-3" (click)="reset()">Reset</button>

</div>

<table class="table table-striped" style="overflow-x:scroll;">
    <thead>
        <tr>
            <th scope="col">Projection No.</th>
            <th scope="col">Vendor</th>
            <th scope="col">Customer</th>
            <th scope="col">Customer Head Office</th>
            <th scope="col">Banner</th>
            <th scope="col">Order Code</th>
            <th scope="col">Item Description</th>
            <th scope="col">Size</th>
            <th scope="col">Cases</th>
            <th scope="col">Week Start</th>
            <th scope="col">Week End</th>
            <th scope="col">No. of Stores</th>
            <th scope="col">Promotion Type</th>
            <th scope="col">Promotion No.</th>
        </tr>
    </thead>
    <tbody>
    <tr *ngFor="let p of projection; index as i" >
        <td>
            <a class="pointer" (click)="onRowClicked(p)">
              <u class="text-primary">
                {{ p.projectionNo }}
              </u>
            </a>
          </td>
      <td>{{ p.vendor }}</td>
      <td>{{ p.customer }}</td>
      <td>{{ p.customerEnd }}</td>
      <td>{{ p.banner }}</td>
      <td>{{ p.productOrderCode }}</td>
      <td>{{ p.itemDescription }}</td>
      <td>{{ p.size }}</td>
      <td>{{ p.casesPerWeek }}</td>
      <td>{{ p.week1 }}</td>
      <td>{{ p.week2 }}</td>
      <td>{{ p.noStores }}</td>
      <td>{{ p.typeOfPromotion }}</td>
      <td>{{ p.promotionalNumber }}</td>
    </tr>
    </tbody>
  </table>
  <div *ngIf="projection!==undefined">
    <ngb-pagination [(page)]="page" [pageSize]="pageSize" [collectionSize]="count" (pageChange)="loadPage()">
    </ngb-pagination>
  </div>
  <div class="btn-group" role="group" aria-label="Basic radio toggle button group">
      
    <input class="btn-check" type="radio" name="radio1" id="choice1" [(ngModel)]="pageSize"  (change)="loadPage()" autocomplete="off" checked [value]="25">
    <label class="btn btn-outline-primary" for="choice1">
      25
    </label>
  
    <input class="btn-check" type="radio" name="radio1" id="choice2" [(ngModel)]="pageSize"  (change)="loadPage()" [value]="50" autocomplete="off">
    <label class="btn btn-outline-primary" for="choice2">
      50
    </label>
  
    <input class="btn-check" type="radio" name="radio1" id="choice3" [(ngModel)]="pageSize"  (change)="loadPage()" [value]="100" autocomplete="off">
    <label class="btn btn-outline-primary" for="choice3">
      100
    </label>
  
  </div>

  <ngb-alert *ngIf="successMessage" [type]="alertType" (close)="successMessage = null">{{ successMessage }}</ngb-alert>
  
