<div>
    <h4>Payment List</h4>
    <div class="col-lg-44">
        Filter
      <label class="ms-3">
        Payment Status:
        <select [(ngModel)]="brokerPayStatusSelected" (change)="resetFilters()">
          <option [value]=""></option>
          <option *ngFor="let b of brokerPayStatusOptions" [value]="b">{{ b }}</option>
        </select>
      </label>
  
      <label class="ms-3">
        Payment Number:
        <select [(ngModel)]="brokerPayNumberSelected" (change)="resetFilters()">
          <option [value]=""></option>
          <option *ngFor="let b of brokerPayNumberOptions" [value]="b">{{ b }}</option>
        </select>
      </label>
  
      <label class="ms-3">
        Broker
        <select [(ngModel)]="brokerSelected" (change)="resetFilters()">
          <option [value]=""></option>
          <option *ngFor="let b of brokerOptions" [value]="b.brokerId">{{ b.company }}</option>
        </select>
      </label>

      <label class="ms-3">
        Vendor:
        <select [(ngModel)]="vendorSelected" (change)="resetFilters()">
          <option [value]=""></option>
          <option *ngFor="let v of vendorOptions" [value]="v.vendorId">{{ v.vendorName }}</option>
        </select>
      </label>
      <button type="button" class="btn btn-primary mt-0 ms-3" (click)="getDefaultDataScreen()">Reset</button>
    </div>
    <table class="table table-striped">
        <thead>
            <tr>
              <th scope="col">Payment Status</th>
              <th scope="col">Payment Number</th>
              <th scope="col">Payment Date</th>
              <th scope="col">Broker</th>
              <th scope="col">Vendor</th>
              <th scope="col">Reference</th>
              <th scope="col">Notes</th>
              <th scope="col">Broker Pay</th>
              <th scope="col">Payment</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let b of brokerPay; index as i">
              <td>{{ b?.status }}</td>
              <td>{{ b?.brokerPayNumber }}</td>
              <td>{{ displayDate(b.paymentDate, '') }}</td>
              <td>{{ b?.company }}</td>
              <td>{{ b?.vendorName }}</td>
              <td>{{ b?.reference }}</td>
              <td>{{ b?.notes }}</td>
              <td>{{ b?.paymentAmount | currency }}</td>
              <td>
                <a (click)="goToEdit(i)" class="pointer">
                  <u class="text-primary">Payment</u>
                </a>
              </td>
            </tr>
        </tbody>
    </table>
</div>
