<h5>Listing Freight Review </h5>
<div class="smallSpacer"><h6>Filter</h6></div>
<div class="col-lg-44">
  <label class="formControlName ms-3">
    Type of Costing:
    <select class="form-control" [(ngModel)]="activeListingsSelected" (change)="resetFilters()">
      <option [value]=""></option>
      <option *ngFor="let o of activeListingsOptions" [value]="o">{{ o }}</option>
    </select>
  </label>
    <label class="formControlName ms-3">
    Cost Type:
    <select class="form-control" [(ngModel)]="costTypeSelected" (change)="resetFilters()">
      <option [value]=""></option>
      <option *ngFor="let o of costTypeOptions" [value]="o">{{ o }}</option>
    </select>
  </label>
    <label class="formControlName ms-3">
    Customer:
    <select class="form-control" [(ngModel)]="customerSelected" (change)="resetFilters()">
      <option [value]=""></option>
      <option *ngFor="let o of customerOptions" [value]="o.customerId">{{ o.customer }}</option>
    </select>
  </label>

  <label class="formControlName ms-3">
    Ship To:
    <select class="form-control" [(ngModel)]="shipToSelected" (change)="resetFilters()">
      <option [value]=""></option>
      <option *ngFor="let o of shipToOptions" [value]="o.id">{{ o.company }}</option>
    </select>
  </label>
    <label class="formControlName ms-3">
    Broker:
    <select class="form-control" [(ngModel)]="brokerSelected" (change)="resetFilters()">
      <option [value]=""></option>
      <option *ngFor="let o of brokerOptions" [value]="o.id">{{ o.company }}</option>
    </select>
  </label>
  <label class="formControlName ms-3">
    Vendor
    <select class="form-control" [(ngModel)]="vendorSelected" (change)="resetFilters()">
      <option [value]="0"></option>
      <option *ngFor="let o of vendorOptions" [value]="o.vendorId">{{ o.vendor }}</option>
    </select> 
  </label>
    <label class="formControlName ms-3">
    Brand:
    <select class="form-control" [(ngModel)]="brandSelected" (change)="resetFilters()">
      <option [value]=""></option>
      <option *ngFor="let o of brandOptions" [value]="o.brandId">{{ o.brand }}</option>
    </select>
  </label>
    <label class="formControlName ms-3">
    Category:
    <select class="form-control" [(ngModel)]="categorySelected" (change)="resetFilters()">
      <option [value]=""></option>
      <option *ngFor="let o of categoryOptions" [value]="o.categoryId">{{ o.category }}</option>
    </select>
  </label>
  <div class="smallSpacer"></div>
  <button type="button" class="btn btn-primary mt-0 ms-3" (click)="getDefaultDataScreen()">Reset</button>
</div>
<div class="spacer"></div>
<table class="table table-striped">
  <thead>
  <tr>
    <th scope="col">Customer</th>
    <th scope="col">Ship To</th>
    <th scope="col">Broker</th>
    <th scope="col">Product Order Code</th> 
    <th scope="col">Order Type</th> 
    <th scope="col">Item Description</th>
    <th scope="col">Size</th>
    <th scope="col">Blended Freight Cost</th>
    <th scope="col">Blended%</th>  

    <th scope="col">Location 1 Starting</th>
    <th scope="col">Location 1 Ending</th>
    <th scope="col">Total Cost 1</th>
    <th scope="col">Location 1%</th>
    <th scope="col">Transport Co. 1</th>
    <th scope="col">Freight Type</th>
    <th scope="col">Cost</th>
    <th scope="col">FSC%</th>
    <th scope="col">Notes</th>

    <th scope="col">Location 2 Starting</th>
    <th scope="col">Location 2 Ending</th>
    <th scope="col">Total Cost 2</th>
    <th scope="col">Location 2%</th>
    <th scope="col">Transport Co. 2</th>
    <th scope="col">Freight Type</th>
    <th scope="col">Cost</th>
    <th scope="col">FSC%</th>
    <th scope="col">Notes</th>

    <th scope="col">Location 3 Starting</th>
    <th scope="col">Location 3 Ending</th>
    <th scope="col">Total Cost 3</th>
    <th scope="col">Location 3%</th>
    <th scope="col">Transport Co. 3</th>
    <th scope="col">Freight Type</th>
    <th scope="col">Cost</th>
    <th scope="col">FSC%</th>
    <th scope="col">Notes</th>

    <th scope="col">Location 4 Starting</th>
    <th scope="col">Location 4 Ending</th>
    <th scope="col">Total Cost 4</th>
    <th scope="col">Location 4%</th>
    <th scope="col">Transport Co. 4</th>
    <th scope="col">Freight Type</th>
    <th scope="col">Cost</th>
    <th scope="col">FSC%</th>
    <th scope="col">Notes</th>

    <th scope="col">Location 5 Starting</th>
    <th scope="col">Location 5 Ending</th>
    <th scope="col">Total Cost 5</th>
    <th scope="col">Location 5%</th>
    <th scope="col">Transport Co. 5</th>
    <th scope="col">Freight Type</th>
    <th scope="col">Cost</th>
    <th scope="col">FSC%</th>
    <th scope="col">Notes</th>

    <th scope="col">Location 6 Starting</th>
    <th scope="col">Location 6 Ending</th>
    <th scope="col">Total Cost 6</th>
    <th scope="col">Location 6%</th>
    <th scope="col">Transport Co. 6</th>
    <th scope="col">Freight Type</th>
    <th scope="col">Cost</th>
    <th scope="col">FSC%</th>
    <th scope="col">Notes</th>

    <th scope="col">Location 7 Starting</th>
    <th scope="col">Location 7 Ending</th>
    <th scope="col">Total Cost 7</th>
    <th scope="col">Location 7%</th>
    <th scope="col">Transport Co. 7</th>
    <th scope="col">Freight Type</th>
    <th scope="col">Cost</th>
    <th scope="col">FSC%</th>
    <th scope="col">Notes</th>

    <th scope="col">Location 8 Starting</th>
    <th scope="col">Location 8 Ending</th>
    <th scope="col">Total Cost 8</th>
    <th scope="col">Location 8%</th>
    <th scope="col">Transport Co. 8</th>
    <th scope="col">Freight Type</th>
    <th scope="col">Cost</th>
    <th scope="col">FSC%</th>
    <th scope="col">Notes</th>
    
    </tr>
  </thead>
  <tbody>
  <tr *ngFor="let p of products; index as i">
    <td>{{ p.customer }}</td>
    <td>{{ getShipTo(p.shipToIds) }}</td>
    <td>{{ p.broker }}</td>
    <td>
      <a class="pointer" (click)="onRowClicked(p)">
      <u class="text-primary">{{ p.orderCode }}</u>
      </a>
    </td>
    <td>{{ p.orderType }}</td>
    <td>{{ p.itemDescription }}</td>
    <td>{{ p.size }}</td>

    <td>{{ p.blendedFreightCost | currency   }}</td>
    <td>{{ p.blended }}%</td>

    <td>{{ getLocation(p.location1Id) }}</td>
    <td>{{ getLocation(p.shippingLaneEnd1) }}</td>
    <td>{{ p.truckCost1 | currency  }}</td>
    <td>{{ p.location1 }}%</td>
    <td>{{ getTransport(p.transportCo1) }}</td>
    <td>{{ p.freightType1 }}</td>
    <td>{{ p.freightCost1 | currency }}</td>
    <td>{{ p.freightCostSales1}}%</td>
    <td>{{ p.transNotesCo1 }}</td>

    <td>{{ getLocation(p.location2Id) }}</td>
    <td>{{ getLocation(p.shippingLaneEnd2) }}</td>
    <td>{{ p.truckCost2 | currency  }}</td>
    <td>{{ p.location2 }}%</td>
    <td>{{ getTransport(p.transportCo2) }}</td>
    <td>{{ p.freightType2 }}</td>
    <td>{{ p.freightCost2 | currency }}</td>
    <td>{{ p.freightCostSales2}}%</td>
    <td>{{ p.transNotesCo2 }}</td>

    <td>{{ getLocation(p.location3Id) }}</td>
    <td>{{ getLocation(p.shippingLaneEnd3) }}</td>
    <td>{{ p.truckCost3 | currency  }}</td>
    <td>{{ p.location3 }}%</td>
    <td>{{ getTransport(p.transportCo3) }}</td>
    <td>{{ p.freightType3 }}</td>
    <td>{{ p.freightCost3 | currency }}</td>
    <td>{{ p.freightCostSales3}}%</td>
    <td>{{ p.transNotesCo3 }}</td>

    <td>{{ getLocation(p.location4Id) }}</td>
    <td>{{ getLocation(p.shippingLaneEnd4) }}</td>
    <td>{{ p.truckCost4 | currency  }}</td>
    <td>{{ p.location4 }}%</td>
    <td>{{ getTransport(p.transportCo4) }}</td>
    <td>{{ p.freightType4 }}</td>
    <td>{{ p.freightCost4 | currency }}</td>
    <td>{{ p.freightCostSales4}}%</td>
    <td>{{ p.transNotesCo4 }}</td>

    <td>{{ getLocation(p.location5Id) }}</td>
    <td>{{ getLocation(p.shippingLaneEnd5) }}</td>
    <td>{{ p.truckCost5 | currency  }}</td>
    <td>{{ p.location5 }}%</td>
    <td>{{ getTransport(p.transportCo5) }}</td>
    <td>{{ p.freightType5 }}</td>
    <td>{{ p.freightCost5 | currency }}</td>
    <td>{{ p.freightCostSales5}}%</td>
    <td>{{ p.transNotesCo5 }}</td>

    <td>{{ getLocation(p.location6Id) }}</td>
    <td>{{ getLocation(p.shippingLaneEnd6) }}</td>
    <td>{{ p.truckCost6 | currency  }}</td>
    <td>{{ p.location6 }}%</td>
    <td>{{ getTransport(p.transportCo6) }}</td>
    <td>{{ p.freightType6 }}</td>
    <td>{{ p.freightCost6 | currency }}</td>
    <td>{{ p.freightCostSales6}}%</td>
    <td>{{ p.transNotesCo6 }}</td>

    <td>{{ getLocation(p.location7Id) }}</td>
    <td>{{ getLocation(p.shippingLaneEnd7) }}</td>
    <td>{{ p.truckCost7 | currency  }}</td>
    <td>{{ p.location7 }}%</td>
    <td>{{ getTransport(p.transportCo7) }}</td>
    <td>{{ p.freightType7 }}</td>
    <td>{{ p.freightCost7 | currency }}</td>
    <td>{{ p.freightCostSales7}}%</td>
    <td>{{ p.transNotesCo7 }}</td>

    <td>{{ getLocation(p.location8Id) }}</td>
    <td>{{ getLocation(p.shippingLaneEnd8) }}</td>
    <td>{{ p.truckCost8 | currency  }}</td>
    <td>{{ p.location8 }}%</td>
    <td>{{ getTransport(p.transportCo8) }}</td>
    <td>{{ p.freightType8 }}</td>
    <td>{{ p.freightCost8 | currency }}</td>
    <td>{{ p.freightCostSales8}}%</td>
    <td>{{ p.transNotesCo8 }}</td>
  </tr>
  </tbody>
</table>

<div *ngIf="products!==undefined">
  <ngb-pagination
  [(page)]="page"
  [pageSize]="pageSize"
  [collectionSize]="collectionSize"
  [maxSize]="5"
  (pageChange)="loadPage($event)">
 </ngb-pagination>
</div>

<div class="btn-group" role="group" aria-label="Basic radio toggle button group">
      
  <input class="btn-check" type="radio" name="radio1" id="choice1" [(ngModel)]="pageSize"  (change)="loadPage($event)" autocomplete="off" checked [value]="25">
  <label class="btn btn-outline-primary" for="choice1">
    25
  </label>

  <input class="btn-check" type="radio" name="radio1" id="choice2" [(ngModel)]="pageSize"  (change)="loadPage($event)" [value]="50" autocomplete="off">
  <label class="btn btn-outline-primary" for="choice2">
    50
  </label>

  <input class="btn-check" type="radio" name="radio1" id="choice3" [(ngModel)]="pageSize"  (change)="loadPage($event)" [value]="100" autocomplete="off">
  <label class="btn btn-outline-primary" for="choice3">
    100
  </label>

</div>

<!-- <div class="btn-group btn-group-toggle" data-toggle="buttons" ngbRadioGroup name="radioBasic" [(ngModel)]="pageSize"  (change)="loadPage($event)">
  <label class="pt-2 pr-2" >Page Size</label>
  <label class="btn-outline-primary" ngbButtonLabel>
    <input type="radio" ngbButton [value]="25"> 25
  </label>
  <label class="btn-outline-primary" ngbButtonLabel>
    <input type="radio" ngbButton [value]="50"> 50
  </label>
  <label class="btn-outline-primary" ngbButtonLabel>
    <input type="radio" ngbButton [value]="100"> 100
  </label>
</div> -->