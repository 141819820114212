import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Term } from '../../../models/term-model';
import { TermsService } from '../../../services/terms.service';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
@Component({
  selector: 'app-terms-edit',
  templateUrl: './terms-edit.component.html',
  styleUrls: ['./terms-edit.component.scss']
})
export class TermsEditComponent implements OnInit {
  title = 'Terms';
  newOne = true;
  terms: Term;
  termsForm: UntypedFormGroup;
  successMessage: string;
  private success = new Subject<string>();
  staticAlertClosed = false;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private fb: UntypedFormBuilder,
              private termsSvc: TermsService) {}

  ngOnInit() {
    this.termsForm = this.fb.group({
      paymentTerms: ['']
    })
    this.route.data.subscribe(data => {
      if (data.dataTerms !== undefined) {
        this.title = 'Terms Edit';
        this.newOne = false;
        this.terms = data.dataTerms;
        this.fillFormData();
      } else {
        this.title = 'Terms New';
        this.newOne = true;
        this.terms = new Term();
        this.termsForm.reset();
      }
    });
    setTimeout(() => this.staticAlertClosed = true, 20000);
    this.success.subscribe((message) => this.successMessage = message);
    this.success.pipe(
      debounceTime(5000)
    ).subscribe(() => this.successMessage = null); 
  }
  fillFormData() {
    this.termsForm.setValue({
      paymentTerms: this.terms.paymentTerms
    });
  }
  onSubmit() {
    this.terms.paymentTerms = this.termsForm.controls.paymentTerms.value;
    if (this.newOne) {
      this.termsSvc.create(this.terms).subscribe(data => {
        this.terms.id =  data.id;
        this.newOne = false;
        this.title = 'Terms Edit';
        this.success.next(`Successfully added.`);
      }, error => {
      });
    } else {
      this.termsSvc.update(this.terms).subscribe(data => {
        this.success.next(`Successfully changed.`);
      }, error => {
      });
    }
  }
  onDelete() {
    if (this.terms.id) {
      this.termsSvc.remove(this.terms.id).subscribe(data => {
        this.router.navigate(['/other/terms-list']);
      });
    }
  }      

}
