import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
const url = environment.kalcorApiUrl;

@Injectable({
  providedIn: 'root'
})
export class WarehouseService {

  constructor(private http: HttpClient) { }
  getAll(): Observable<any> {
    return this.http.get(url + 'AccountsWarehouses', this.jwt());
  }
  getOne(id: number): Observable<any> {
    return this.http.get(url + 'AccountsWarehouses/' + id, this.jwt());
  }
  getFilteredListing(activeItem: string, id: number, page: number, pageSize: number): Observable<any> {
    page = page ? page : 1;
    pageSize = pageSize ? pageSize : 30;
    return this.http.get(url + 'AccountsWarehouses/Filter?activeItem=' + activeItem + '&id=' + id
    + '&page=' + page + '&pageSize=' + pageSize, this.jwt());
  }  
  update(pickup) {
    return this.http.put(url + 'AccountsWarehouses/' + pickup.id, pickup, this.jwt());
  }
  create(pickup): Observable<any> {
    return this.http.post(url + 'AccountsWarehouses', pickup, this.jwt());
  }
  remove(id) {
    return this.http.delete(url + 'AccountsWarehouses/' + id, this.jwt());
  }
  private jwt() {
    // create authorization header with jwt token
    const currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
    if (currentUser && currentUser.token) {
        const headersInfo = new HttpHeaders({ Authorization: 'Bearer ' + currentUser.token });
        return { headers: headersInfo };
    }
  }

}
