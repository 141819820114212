import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
const url = environment.kalcorApiUrl;
@Injectable({
  providedIn: 'root'
})
export class 
OrderService {

  constructor(private http: HttpClient) { }
  getAll(): Observable<any> {
    return this.http.get(url + 'DataOrders', this.jwt());
  }
  getPurchaseOrderOnly(): Observable<any>  {
    return this.http.get(url + 'DataOrders/POnly', this.jwt());
  }
  getPurchaseInfo(id: number): Observable<any>  {
    return this.http.get(url + 'DataOrders/' + id + '/CreditInfo', this.jwt());
  }
  getList(page, pageSize): Observable<any> {
    page = page ? page : 1;
    pageSize = pageSize ? pageSize : 30;
    return this.http.get(url + 'DataOrders/List?page=' + page + '&pageSize=' + pageSize, this.jwt());
  }
  getBucketList(year: number, month:[], vendorId: number, customerId: number, brokerId: number, bucket: string, type: string, orderType: string): Observable<any> {
    return this.http.get(url + 'DataOrders/Bucket?year=' + year + '&month=' + 
            month + '&vendorId=' + vendorId + '&customerId=' + customerId + '&brokerId=' + brokerId + '&bucket=' + bucket + '&type=' + type + '&orderType=' + orderType, this.jwt());
  }
  getBucketExchange(year: number, month:[], vendorId: number, customerId: number, brokerId: number, bucket: string, orderType: string): Observable<any> {
    return this.http.get(url + 'DataOrders/Bucket/Exchange?year=' + year + '&month=' + 
            month + '&vendorId=' + vendorId + '&customerId=' + customerId + '&brokerId=' + brokerId + '&bucket=' + bucket + '&orderType=' + orderType, this.jwt());
  }
  getBucketSales(year: number, month:[], vendorId: number, customerId: number, brokerId: number, bucket: string, orderType: string): Observable<any> {
    return this.http.get(url + 'DataOrders/Sales?year=' + year + '&month=' + 
            month + '&vendorId=' + vendorId + '&customerId=' + customerId + '&brokerId=' + brokerId + '&bucket=' + bucket + '&orderType=' + orderType, this.jwt());
  }
  getBucketSalesDetail(year: number, month:[], vendorId: number, customerId: number, brokerId: number, bucket: string, orderType: string): Observable<any> {
    return this.http.get(url + 'DataOrders/Sales-detail?year=' + year + '&month=' + 
            month + '&vendorId=' + vendorId + '&customerId=' + customerId + '&brokerId=' + brokerId + '&bucket=' + bucket + '&orderType=' + orderType, this.jwt());
  }
  getFilteredList(id: number, status: string, orderType: string, bill: number, ship: number, vendor: number, page: number, pageSize: number): Observable<any> {
    page = page ? page : 1;
    pageSize = pageSize ? pageSize : 30;
    return this.http.get(url + 'DataOrders/Filter?id=' + id + '&status=' + 
            status.trim() +  '&orderType=' + orderType.trim() + '&bill=' + bill + '&ship=' + ship + '&vend=' + vendor + '&page=' + page + '&pageSize=' + pageSize, this.jwt());
  }
  getOneList(id): Observable<any> {
    return this.http.get(url + 'DataOrders/' + id + '/List', this.jwt());
  }
  getOneSum(id): Observable<any> {
    return this.http.get(url + 'DataOrders/' + id + '/Sum', this.jwt());
  }
  getShipped(): Observable<any> {
    return this.http.get(url + 'DataOrders/Shipped', this.jwt());
  }
  getOne(id: number): Observable<any> {
    return this.http.get(url + 'DataOrders/' + id, this.jwt());
  }
  getStatusOptions(status: string, bill: number, ship: number, vendor: number): Observable<any> {
    return this.http.get(url + 'DataOrders/Status?status=' + 
    status.trim() + '&bill=' + bill + '&ship=' + ship + '&vend=' + vendor, this.jwt());
  }
  
  getOrderNumberOptions(status: string, bill: number, ship: number, vendor: number): Observable<any> {
    return this.http.get(url + 'DataOrders/SalesOrderNumber?status=' + 
    status.trim() + '&bill=' + bill + '&ship=' + ship + '&vend=' + vendor, this.jwt());
  }

  getPurchaseOrderOptions(status: string, bill: number, ship: number, vendor: number): Observable<any> {
    return this.http.get(url + 'DataOrders/PurchaseOrder?status=' + 
    status.trim() + '&bill=' + bill + '&ship=' + ship + '&vend=' + vendor, this.jwt());
  }

  getBillToOptions(status: string, bill: number, ship: number, vendor: number): Observable<any> {
    return this.http.get(url + 'DataOrders/BillTo?status=' + 
    status.trim() + '&bill=' + bill + '&ship=' + ship + '&vend=' + vendor, this.jwt());
  }

  getShipToOptions(status: string, bill: number, ship: number, vendor: number): Observable<any> {
    return this.http.get(url + 'DataOrders/ShipTo?status=' + 
    status.trim() + '&bill=' + bill + '&ship=' + ship + '&vend=' + vendor, this.jwt());
  }
 
  getVendorOptions(status: string, bill: number, ship: number, vendor: number): Observable<any> {
    return this.http.get(url + 'DataOrders/Vendor?status=' + 
    status.trim() + '&bill=' + bill + '&ship=' + ship + '&vend=' + vendor, this.jwt());
  }
  getStatus(type: string): Observable<any> {
    return this.http.get(url + 'DataOrders/Status/' + type, this.jwt());
  }

  getDetails(id: number): Observable<any> {
    return this.http.get(url + 'DataOrders/' + id + '/details', this.jwt());
  }
  getItems(id: number): Observable<any> {
    return this.http.get(url + 'DataOrders/' + id + '/Items', this.jwt());
  }  

  getDetailsSP(year: number, vendorId: number): Observable<any> {
    return this.http.get(url + 'DataOrders/Sales-SP?year=' + year + 
            '&vendorId=' + vendorId, this.jwt());
  }
  getBucketPeriod(dateStart: string, dateEnd: string, vendorId: number, orderType: string, billingAgentId: number): Observable<any> {
    return this.http.get(url + 'DataOrders/Period?dateStart=' + dateStart + '&dateEnd=' + 
    dateEnd + '&vendorId=' + vendorId + '&bucket=' + '&orderType=' + orderType + '&billingAgentId=' + billingAgentId, this.jwt());
  }
  getBucketPeriodClearing(dateStart: string, dateEnd: string, vendorId: number, orderType: string, billingAgentId: number): Observable<any> {
    return this.http.get(url + 'DataOrders/Period/Clearing?dateStart=' + dateStart + '&dateEnd=' + 
    dateEnd + '&vendorId=' + vendorId + '&bucket=' + '&orderType=' + orderType + '&billingAgentId=' + billingAgentId, this.jwt());
  }
  getBucketPeriodUSDFreight(dateStart: string, dateEnd: string, vendorId: number, orderType: string, billingAgentId: number): Observable<any> {
    return this.http.get(url + 'DataOrders/Period/USDFreight?dateStart=' + dateStart + '&dateEnd=' + 
    dateEnd + '&vendorId=' + vendorId + '&bucket=' + '&orderType=' + orderType + '&billingAgentId=' + billingAgentId, this.jwt());
  }
  getLotBB(dateStart: string, dateEnd: string, status: string, orderType: string, bill: number, ship: number, vendor: number, salesNo: string, 
    poNo: string, page: number, pageSize: number): Observable<any> {
    page = page ? page : 1;
    pageSize = pageSize ? pageSize : 30;
    return this.http.get(url + 'DataOrders/LotBB?dateStart=' + dateStart + '&dateEnd=' + dateEnd + '&status=' + 
      status.trim() + '&orderType=' + orderType.trim() + '&bill=' + bill + '&ship=' + ship + '&vend=' + vendor + 
    '&salesNo=' + salesNo.trim() + '&poNo=' + poNo.trim() + '&page=' + page + '&pageSize=' + pageSize, this.jwt());
  }
  update(order) {
    return this.http.put(url + 'DataOrders/' + order.id, order, this.jwt());
  }
  updateInvoice(order): Observable<any> {
    return this.http.put(url + 'DataOrders/' + order.id + '/Invoice', order, this.jwt());
  }
  updateStatus(order): Observable<any> {
    return this.http.put(url + 'DataOrders/' + order.id + '/Status', order, this.jwt());
  }
  create(order): Observable<any> {
    return this.http.post(url + 'DataOrders', order, this.jwt());
  }
  remove(id) {
    return this.http.delete(url + 'DataOrders/' + id, this.jwt());
  }
  private jwt() {
    // create authorization header with jwt token
    const currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
    if (currentUser && currentUser.token) {
        const headersInfo = new HttpHeaders({ Authorization: 'Bearer ' + currentUser.token });
        return { headers: headersInfo };
    }
  }
 
}
