<h5>{{ title }} </h5>
<form [formGroup]="gstHstForm" (ngSubmit)="onSubmit()" novalidate>
<div class="smallSpacer"></div>
  <div class="form-group">
    <div class="col-lg-44">
      <label class="formControlName ms-3">
        *Tax Name:
        <input type="text" formControlName="taxName" class="form-control">
      </label>
    </div>
    <div class="col-lg-44">
        <label class="formControlName ms-3">
          Rate %:
          <input type="text" formControlName="rate" class="form-control">
        </label>
    </div>
    <div class="spacer"></div>
    <button type="submit" class="btn btn-primary mt-3 w-25">Save</button>
    <button type="button" class="btn btn-secondary ml-3 mt-3 w-25" (click)="onDelete()">Delete</button>
  </div>
    <ngb-alert *ngIf="successMessage" type="success" (close)="successMessage = null">{{ successMessage }}</ngb-alert>
</form>