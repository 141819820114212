import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { RestService } from '../../../services/rest.service';

@Component({
  selector: 'app-link',
  templateUrl: './link.component.html',
  styleUrls: ['./link.component.css']
})
export class LinkComponent implements OnInit {
  year = [];
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private restSvc: RestService

  ) { }

  ngOnInit(): void {
    this.displayItems();
  }

  displayItems() {
    const theDate = new Date();
    const middle = theDate.getFullYear();
    // this.year.push(middle - 2);
    // this.year.push(middle - 1);
    // this.year.push(middle);
    // this.year.push(middle + 1);
    this.year.push(2020);
    this.year.push(2021);
    this.year.push(2022);
    this.year.push(2023);
    this.year.push(2024);
  }
  clickedItem(i: number) {
    this.router.navigate(['/portal/connect-link',  this.year[i]]);
  }
  clickedItemBudget(i: number) {
    this.router.navigate(['/portal/connect-budget',  this.year[i]]);
  }  
}
