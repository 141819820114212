import { Component, OnInit } from '@angular/core';
import { UpcGtnService } from '../../../services/upc-gtn.service';
import { Router } from '@angular/router';
import { Vendor } from '../../../models/vendor-model';
import { VendorService } from '../../../services/vendor.service';
import { Brand } from '../../../models/brand.model';
import { BrandService } from '../../../services/brand.service';
@Component({
  selector: 'app-upc-gtn-list',
  templateUrl: './upc-gtn-list.component.html',
  styleUrls: ['./upc-gtn-list.component.scss']
})
export class UpcGtnListComponent implements OnInit {
  upcGtn: any;
  vendor: Vendor[] = [];
  brand: Brand[] = [];
  activeItemSelected: string;
  vendorSelected: number;
  vendorOptions:[];
  brandSelected: number;
  brandOptions:[];
  page: number;
  pageSize: number;
  count: number;

  constructor(private router: Router,
              private brandService: BrandService,
              private vendorService: VendorService,
              private upcGtnSvc: UpcGtnService) { }

  ngOnInit() {
    // this.brandService.getAll().subscribe(brand => {
    //   this.brand = brand;
    //   this.vendorService.getAll().subscribe(data => {
    //     this.vendor = data;
    //     this.upcGtnSvc.getVendor().subscribe(data => this.upcGtn = data);
    //   });
    // });
    this.reset();
  }
  
  getBrandVendor(id, type) {
    let ret = '';
    if (id) {
      const index = this.brand.findIndex(item => item.id === id);
      if (index >  -1) {
        if (type === "Brand") {
          ret = this.brand[index].brand;
        } else {
          if (this.brand[index].vendorId) {
            const index2 = this.vendor.findIndex(item => item.id === this.brand[index].vendorId);
            ret = this.vendor[index2].company;
          }
        } 
      }
    }
    return ret;
  }

  getVendor(id) {
    let ret = '';
    if (id) {
      const index = this.brand.findIndex(item => item.id === id);
      if (index >  -1) {
        ret = this.brand[index].brand;
      }
    }
    return ret;
  }
  loadPage() {
    this.upcGtnSvc.getFilteredListing(this.activeItemSelected, this.brandSelected, this.vendorSelected, this.page, this.pageSize).subscribe(data => {
      this.upcGtn = data.item;
      this.vendorOptions  = data.item3;
      this.brandOptions = data.item2;
      this.count = data.count;
    });
  }
  reset() {
    this.page = 1;
    this.pageSize = 30;
    this.activeItemSelected = 'Active';
    this.brandSelected = 0;
    this.vendorSelected = 0;
    this.loadPage();    
  }    
  onRowClicked(upcGtn) {
    this.router.navigate(['/items/upc-gtn-edit',  upcGtn.id]);
  }
}
