export class BorderCrossing {
    id: number;
    area: string;
    canadaPortOfEntryName: string;
    canadaRoadHighway: string;
    unitedStatesPortOfEntryName: string;
    unitedStatesRoadHighwayCommunity: string;
    notes: string;
    structureOrNotableFeature: string;
    coordinates: string;
}
  