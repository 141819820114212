
<!-- <div id="my-sales-order" *ngIf="reportName=='Sales Order'"> -->
  <!-- <app-sales-order
    [title] = "title"
    [order] = "orderFilled"
    [customer] = "customer"
    [term] = "term"
    [freightType] = "freightType"
    [broker] = "broker"
    [shipTo] = "shipTo"
    [pickup] = "pickup"
    [location1] = "location1"
    [transport1] = "transport1"
    [warehouse] = "warehouse"
    [location2] = "location2"
    [transport2] = "transport2"
    [location3] = "location3"
    [transport3] = "transport3"
    [vendor] = "vendor"
    [totalCases] = "totalCases"
    [totalPallets] = "totalPallets"
    [totalGrossLbs] = "totalGrossLbs"
    [totalCaseCost] = "totalCaseCost"
    [totalGrosskg] = "totalGrosskg">
  </app-sales-order>
</div>  -->
<!-- <div id="my-packing-slip" *ngIf="reportName=='Packing Slip'">
  <app-packing-slip
    [title] = "title"
    [order] = "orderFilled"
    [customer] = "customer"
    [term] = "term"
    [freightType] = "freightType"
    [broker] = "broker"
    [shipTo] = "shipTo"
    [pickup] = "pickup"
    [location1] = "location1"
    [transport1] = "transport1"
    [warehouse] = "warehouse"
    [location2] = "location2"
    [transport2] = "transport2"
    [location3] = "location3"
    [transport3] = "transport3"
    [vendor] = "vendor">
  </app-packing-slip>
</div>  -->
<div *ngIf="reportName==''">

  <div class="row g-0 text-white mt-2 px-2 bg-primary">
    <div class="col-lg-44 pt-2 px-2 text-center">
      {{ title }}
    </div>
  </div>

  <div class="row g-0 mt-2">
    <div class="col-lg-8 pt-2 px-2">
      <label class="text-center text-white w-100 bg-primary">
        BILL TO
      </label>
    </div>
    <div class="col-lg-8 pt-2 px-2">
      <label class="text-white text-center w-100 bg-primary">
        SALES ORDER INFORMATION
      </label>
    </div>
    <div class="col-lg-8 pt-2 px-2">
      <label class="text-white text-center w-100 bg-primary">
        SHIPPING DATES/TYPES
      </label>
    </div>
  </div>

  <div class="row g-0">
    <div class="col-lg-8 pt-0 px-2">
      {{ customer?.company }}
    </div>
    <div class="col-lg-4 pt-0 px-2">
      Sales Order Date
    </div>
    <div class="col-lg-4 pt-0 px-2">
      {{ displayDate(order[0].salesOrderDate, '')  }}
    </div>
    <div class="col-lg-4 pt-0 px-2">
      Shipment Date
    </div>
    <div class="col-lg-4 pt-0 px-2">
      {{ displayDate(order[0].shipmentDate, '')  }}
    </div>
  </div>

  <div class="row g-0">
    <div class="col-lg-8 pt-0 px-2">
      {{ customer?.address }}
    </div>
    <div class="col-lg-4 pt-0 px-2">
      Sales Order No.
    </div>
    <div class="col-lg-4 pt-0 px-2">
      {{ order[0].salesOrderNo }}
    </div>
    <div class="col-lg-4 pt-0 px-2">
      Delivered Date/Appt
    </div>
    <div class="col-lg-4 pt-0 px-2">
      {{ displayDate(order[0].deliveredDateAppt, 'T') }}
    </div>
  </div>

  <div class="row g-0">
    <div class="col-lg-8 pt-0 px-2">
      {{ getAddress2('customer') }}
    </div>
    <div class="col-lg-4 pt-0 px-2">
      Purchase Order
    </div>
    <div class="col-lg-4 pt-0 px-2">
      {{ order[0].purchaseOrder }}
    </div>
    <div class="col-lg-4 pt-0 px-2">
      App Confirmed
    </div>
    <div class="col-lg-4 pt-0 px-2">
      {{ order[0].appConfirmed }}
    </div>
  </div>

  <div class="row g-0">
    <div class="col-lg-8 pt-0 px-2">
    </div>
    <div class="col-lg-4 pt-0 px-2">
      Payment Terms
    </div>
    <div class="col-lg-4 pt-0 px-2">
      {{ term?.paymentTerms }}
    </div>
    <div class="col-lg-4 pt-0 px-2">
      Delivery Type
    </div>
    <div class="col-lg-4 pt-0 px-2">
      {{ freightType?.freightType }}
    </div>
  </div>

  <div class="row g-0">
    <div class="col-lg-8 pt-0 px-2">
      <label class="text-white text-center w-100 bg-primary">
        SHIP TO
      </label>
    </div>
    <div class="col-lg-4 pt-0 px-2">
      Broker
    </div>
    <div class="col-lg-4 pt-0 px-2">
      {{ broker?.company }}
    </div>
    <div class="col-lg-4 pt-0 px-2">
    </div>
    <div class="col-lg-4 pt-0 px-2">
    </div>
  </div>

  <div class="row g-0">
    <div class="col-lg-20 pt-0 px-2">
      {{ shipTo?.company }}
    </div>
    <div class="col-lg-6">
    </div>
    <div class="col-lg-4 pt-0 px-2">
      Order Status
    </div>
    <div class="col-lg-4 pt-0 px-2">
      {{ order[0]?.orderStatus }}
    </div>
  </div>

  <div class="row g-0">
    <div class="col-lg-20 pt-0 px-2">
      {{ shipTo?.address }}
    </div>
    <div class="col-lg-24"></div>
  </div>

  <div class="row g-0">
    <div class="col-lg-20 pt-0 px-2">
      {{ getAddress2('shipto') }}
    </div>
    <div class="col-lg-24"></div>
  </div>

  <div class="row g-0">
    <div class="col-lg-6 pt-0 px-2">
      <label class="text-white text-center w-100 bg-primary">
        VENDOR PICK UP
      </label>
    </div>
    <div class="col-lg-20 pt-0 px-2">
      <label class="text-white text-center w-100 bg-primary">
        SHIPPING LANE
      </label>
    </div>

    <div class="col-lg-4 pt-0 px-2">
      <label class="text-white text-center w-100 bg-primary">
        TRANSPORT COMPANY
      </label>
    </div>
    <div class="col-lg-4 pt-0 px-2">
      <label class="bg-primary text-white text-center w-100 bg-primary">
        WAREHOUSE
      </label>
    </div>
  </div>

  <div class="row g-0">
    <div class="col-lg-6 pt-0 px-2">
      {{ pickup?.company }}
    </div>
    <div class="col-lg-20 pt-0 px-2">
      {{ location1?.location }}
    </div>
    <div class="col-lg-4 pt-0 px-2">
      {{ transport1?.company }}
    </div>
    <div class="col-lg-4 pt-0 px-2">
      {{ warehouse?.company }}
    </div>
  </div>

  <div class="row g-0">
    <div class="col-lg-6 pt-0 px-2">
      {{ pickup?.address }}
    </div>
    <div class="col-lg-20 pt-0 px-2">
      {{ location2?.location }}
    </div>
    <div class="col-lg-4 pt-0 px-2">
      {{ transport2?.company }}
    </div>
    <div class="col-lg-4 pt-0 px-2">
      {{ warehouse?.address }}
    </div>
  </div>

  <div class="row g-0">
    <div class="col-lg-6 pt-0 px-2">
      {{ getAddress2('pickup') }}
    </div>
    <div class="col-lg-20 pt-0 px-2">
      {{ location3?.location }}
    </div>
    <div class="col-lg-4 pt-0 px-2">
      {{ transport3?.company }}
    </div>
    <div class="col-lg-4 pt-0 px-2">
      {{ getAddress2('warehouse') }}
    </div>
  </div>

  <div class="row g-0">
    <div class="col-lg-20 pt-3 px-2">
      <span class="fw-bold">Vendor: </span>
      {{ getVendor() }}
    </div>
    <div class="col-lg-24 pt-3 px-2">
      <span class="fw-bold">Notes: </span>
      {{ order[0].notes }}
    </div>
  </div>

  <div class="row g-0 text-white mt-3 bg-primary">
    <div class="col-lg-2 pt-0 px-2">
      <label class="text-white">
        ORDER CODE
      </label>
    </div>
    <div class="col-lg-6 pt-0 px-2">
      <label class="text-white">
        BRAND/CATEGORY
      </label>
    </div>

    <div class="col-lg-8 pt-0 px-2">
      <label class="text-white">
        ITEM DESCRIPTION
      </label>
    </div>

    <div class="col-lg-2 pt-0 px-2">
      <label class="text-white">
        SIZE
      </label>
    </div>
    <div class="col-lg-2 pt-0 px-2">
      <label class="text-white text-end w-100">
        CASES
      </label>
    </div>
    <div class="col-lg-2 pt-0 px-2">
      <label class="text-white text-end w-100">
        CASE COST
      </label>
    </div>
    <div class="col-lg-2 pt-0 px-2">
      <label class="text-white text-end w-100">
        EXT CASE COST
      </label>
    </div>
  </div>
  <form [formGroup]="orderForm" (ngSubmit)="onSubmit()" novalidate>
    <div class="form-group">
      <label>
        <input type="checkbox" formControlName="showAll"> Show all items
      </label>
      <div formArrayName="items" *ngFor="let item of orderForm.get('items')['controls']; let i = index;">
        <div [formGroupName]="i">
          <div class="row g-0"
            [ngClass]="{'gray-out': grayRow[i]==='G', 'grid-stripe': grayRow[i]==='S', 'pdf-none': grayRow[i]==='B'}">
            <div class="col-lg-2 pt-0 px-2 w-100">
              {{ order[i].orderCode}}
            </div>
            <div class="col-lg-6 pt-0 px-2 w-100">
              {{ order[i].brandCategory }}
            </div>
            <div class="col-lg-8 pt-0 px-2 w-100">
              {{ order[i].itemDescription}}
            </div>
            <div class="col-lg-2 pt-0 px-2 w-100">
              {{ order[i].size }}
            </div>
            <div class="col-lg-2 pt-0 px-2 text-end">
              <input formControlName="cases" class="w-100 text-end">
            </div>
            <div class="col-lg-2 pt-0 px-2 w-100 text-end">
              {{ order[i].caseCost | currency }}
            </div>
            <div class="col-lg-2 pt-0 px-2 w-100 text-end">
              {{ order[i].extCaseCost | currency }}
            </div>
          </div>
          <div class="row g-0"
            [ngClass]="{'gray-out': grayRow[i]==='G', 'grid-stripe': grayRow[i]==='S', 'pdf-none': grayRow[i]==='B'}">
            <div class="col-lg-4 pt-0 pb-2 px-2 w-100">
              <span class="pe-2">CS Gross Lbs</span>
              {{ order[i].csGrossLbs | number : '1.2-2' }}
            </div>
            <div class="col-lg-4 pt-0 pb-2 px-2 w-100">
              <span class="pe-2">Product Type</span>
              {{ order[i].productType }}
            </div>
            <div class="col-lg-6 pt-0 pb-2 px-2 w-100">
              <span class="pe-2">TI/HI</span>
              {{ order[i].tiHi }}
              <span class="ps-4 pe-2">CS per Pallet</span>
              {{ order[i].csPerPallet }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row g-0 text-white mt-3 bg-primary">
      <div class="col-lg-6 pt-0 px-2">
        <span class="pe-2">TOTAL CASES</span>
        {{ totalCases | number : '1.0-0' }}
      </div>
      <div class="col-lg-4 pt-0 px-2">
        <span class="pe-2">TOTAL PALLETS</span>
        {{ totalPallets |  number : '1.0-0' }}
      </div>

      <div class="col-lg-8 pt-0 px-2">
        <span class="pe-2">TOTAL GROSS</span>
        {{ totalGrossLbs | number : '1.2-2'  }}
        <span class="pe-3"> lbs</span>
        {{ totalGrosskg | number : '1.2-2'  }}
        <span class="pe-2"> kg</span>
      </div>

      <div class="col-lg-6 pt-0 px-2">
        <span class="pe-2">TOTAL CASE COST</span>
        {{ totalCaseCost | currency }}
      </div>
    </div>
    <div class="row g-0 mt-3">
      <div class="col-lg-6 pt-0 px-2">
        <img class="logo img-responsive" src="/assets/images/Kalcor Menu.png" height="100%" width="158px">
      </div>
      <div class="col-lg-28 pt-3 px-2">
        ​​CANADA OFFICES: VANCOUVER: 1285 W Broadway , #600, Vancouver, BC, V6H 3X8, Office 866.779.9850 Email:
        Inbox@kalcor.ca Website: www.kalcor.ca ​
      </div>
    </div>
    <br>
    <button type="submit" class="btn btn-primary mt-3 w-25">Save</button>
    <button type="button" class="btn btn-secondary ms-3 mt-3 w-25" (click)="onPrint()">Print</button>
    <button type="button" class="btn btn-secondary ms-3 mt-3 w-25" (click)="onDelete()">Delete</button>
    <ngb-alert *ngIf="successMessage" [type]="alertType" (close)="successMessage = null">{{ successMessage }}
    </ngb-alert>
  </form>
</div>