<h4>Product Group List</h4>
<h5>Filter</h5>
<div class="col-lg-44">

  <label>
    Vendor:
    <select [(ngModel)]="vendor" (change)="resetFilters()">
      <option [ngValue]=""></option>
      <option *ngFor="let v of vendorFilter" [ngValue]="v.vendorId">{{ v.vendor }}</option>
    </select>
  </label>
  <button type="button" class="btn btn-primary mt-0 ms-3" (click)="getDefaultDataScreen()">Reset</button>

</div>

<table class="table table-striped">
  <thead>
  <tr>
    <th scope="col">Product Group</th>
    <th scope="col">Vendor</th>
    </tr>
  </thead>
  <tbody>
  <tr *ngFor="let p of productGroup">
    <td>
      <a (click)="onRowClicked(p)" class="pointer">
        <u class="text-primary">{{ p.productGroupNo }}</u>
      </a>
    </td>
    <td>{{ p.vendor }}</td>
  </tr>
  <div *ngIf="productGroup!==undefined">
      <ngb-pagination [(page)]="page" [pageSize]="pageSize" [collectionSize]="collectionSize" [maxSize]="5"
        (pageChange)="loadPage($event)">
      </ngb-pagination>
  </div>
  <div class="btn-group" role="group" aria-label="Basic radio toggle button group">
      
    <input class="btn-check" type="radio" name="radio1" id="choice1" [(ngModel)]="pageSize"  (change)="loadPage($event)" autocomplete="off" checked [value]="25">
    <label class="btn btn-outline-primary" for="choice1">
      25
    </label>
  
    <input class="btn-check" type="radio" name="radio1" id="choice2" [(ngModel)]="pageSize"  (change)="loadPage($event)" [value]="50" autocomplete="off">
    <label class="btn btn-outline-primary" for="choice2">
      50
    </label>
  
    <input class="btn-check" type="radio" name="radio1" id="choice3" [(ngModel)]="pageSize"  (change)="loadPage($event)" [value]="100" autocomplete="off">
    <label class="btn btn-outline-primary" for="choice3">
      100
    </label>
  
  </div>
  </tbody>
</table>
