import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CommonModule } from '@angular/common';
import { FormsModule} from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { AdminRoutingModule } from './admin-routing.module';
import { 
  UserListComponent,
  UserEditComponent,
  UserResolver
} from './user/index';
import { 
  RolesListComponent, 
  RolesEditComponent, 
  RolesResolver
} from './roles/index';


@NgModule({
  declarations: [UserListComponent, UserEditComponent, RolesListComponent, RolesEditComponent],
  imports: [
    CommonModule,
    NgbModule,
    AdminRoutingModule,
    ReactiveFormsModule,
    FormsModule
  ],
  providers: [
    UserResolver,
    RolesResolver
  ]  
})
export class AdminModule { }
