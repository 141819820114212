import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { PickupLocation } from '../../../models/pickup-location.model';
import { PickupLocationService } from '../../../services/pickup-location.service';

@Injectable()
export class PickupLocationResolver implements Resolve<PickupLocation> {
  constructor(private pickupLocationService: PickupLocationService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<PickupLocation> {
    const idField = 'id';
    const id =  parseInt(route.params[idField], 10);

    if (id === 0) {
      return;
    }
    return this.pickupLocationService.getOne(id);
  }
}
