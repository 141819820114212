import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from "@angular/router";
import { Observable } from "rxjs";
import { CreditReasons } from "src/app/models/credit-reasons.model";
import { CreditReasonsService } from "src/app/services/credit-reasons.service";

@Injectable()
export class CreditReasonsResolver implements Resolve<CreditReasons> {
    constructor(private creditReasonsService: CreditReasonsService) {
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<CreditReasons> {
        const idField = 'id';
        const id = parseInt(route.params[idField], 10);

        if (id === 0) {
            return;
        }
        return this.creditReasonsService.getOne(id);
    }
}