<div class="pdf">
  <div class="row g-0 text-white mt-2 px-2">
      <div class="col-lg-20 pt-2 px-2 pdf, pdf-bg, pdf-size20">
          {{ order?.invoiceNumber }}
      </div>        
      <div class="col-lg-4 pt-2 px-2 text-end pdf, pdf-bg, pdf-size20">
          {{ title }}
      </div>
  </div>

  <div class="row g-0 mt-2">
      <div class="col-lg-8 pt-2 px-2">
          <label class="text-center text-white w-100 pdf-bg pdf-size11">
              BILL TO
          </label>
      </div>
      <div class="col-lg-8 pt-2 px-2">
          <label class="text-white text-center w-100 pdf-bg pdf-size11">
              SALES ORDER INFORMATION
          </label>
      </div>
      <div class="col-lg-8 pt-2 px-2">
          <label class="text-white text-center w-100 pdf-bg pdf-size11">
              SHIPPING DATES/TYPES
          </label>
      </div>
  </div>

  <div class="row g-0">
      <div class="col-lg-8 pt-0 px-2">
          {{ order?.billTo }}
      </div>
      <div class="col-lg-4 pt-0 px-2">
          Sales Order Date
      </div>
      <div class="col-lg-4 pt-0 px-2">
          {{ displayDate(order.salesOrderDate, '')  }}
      </div>
      <div class="col-lg-4 pt-0 px-2">
          Shipment Date
      </div>
      <div class="col-lg-4 pt-0 px-2">
          {{ displayDate(order.shipmentDate, '')  }}
      </div>
  </div>

  <div class="row g-0">
      <div class="col-lg-8 pt-0 px-2">
          {{ order?.billAddress }}
      </div>
      <div class="col-lg-4 pt-0 px-2">
          Sales Order No.
      </div>
      <div class="col-lg-4 pt-0 px-2">
          {{ order?.salesOrderNo }}
      </div>
      <div class="col-lg-4 pt-0 px-2">
          Delivered Date/Appt
      </div>
      <div class="col-lg-4 pt-0 px-2">
          {{ displayDate(order.deliveredDateAppt, 'T') }}
      </div>
  </div>

  <div class="row g-0">
      <div class="col-lg-8 pt-0 px-2">
          {{ order?.billCSZ }}
      </div>
      <div class="col-lg-4 pt-0 px-2">
          Purchase Order
      </div>
      <div class="col-lg-4 pt-0 px-2">
          {{ order?.purchaseOrder }}
      </div>
      <div class="col-lg-4 pt-0 px-2">
          App Confirmed
      </div>
      <div class="col-lg-4 pt-0 px-2">
          {{ order?.appConfirmed }}
      </div>
  </div>

  <div class="row g-0">
      <div class="col-lg-8 pt-0 px-2">
      </div>
      <div class="col-lg-4 pt-0 px-2">
          Payment Terms
      </div>
      <div class="col-lg-4 pt-0 px-2">
          {{ order?.paymentTerms }}
      </div>
      <div class="col-lg-4 pt-0 px-2">
          Delivery Type
      </div>
      <div class="col-lg-4 pt-0 px-2">
          {{ order?.freightType }}
      </div>
  </div>

<div class="row g-0">
  <div class="col-lg-8 pt-0 px-2">
      <label class="text-white text-center w-100 pdf-bg pdf-size11">
          SHIP TO
      </label>
  </div>
  <div class="col-lg-4 pt-0 px-2">
      Broker
  </div>
  <div class="col-lg-4 pt-0 px-2">
      {{ order?.broker }}
  </div>
  <div class="col-lg-4 pt-0 px-2">
  </div>
  <div class="col-lg-4 pt-0 px-2">
  </div>
</div>

<div class="row g-0">
  <div class="col-lg-10 pt-0 px-2">
      {{ order?.shipTo }}
  </div>
  <div class="col-lg-6">
  </div>
  <div class="col-lg-4 pt-0 px-2">
      Order Status
  </div>
  <div class="col-lg-4 pt-0 px-2">
      {{ order?.orderStatus }}
  </div>
</div>

<div class="row g-0">
  <div class="col-lg-10 pt-0 px-2">
      {{ order?.shipToAddress }}
  </div>
  <div class="col-lg-14"></div>
</div>

<div class="row g-0">
  <div class="col-lg-10 pt-0 px-2">
      {{ order?.shipToCSZ }}
  </div>
  <div class="col-lg-14"></div>
</div>

<div class="row g-0">
  <div class="col-lg-6 pt-0 px-2">
      <label class="text-white text-center w-100 pdf-bg pdf-size11">
          VENDOR PICK UP
      </label>
  </div>
  <div class="col-lg-10 pt-0 px-2">
      <label class="text-white text-center w-100 pdf-bg pdf-size11">
          SHIPPING LANE
      </label>
  </div>

  <div class="col-lg-4 pt-0 px-2">
      <label class="text-white text-center w-100 pdf-bg pdf-size11">
          TRANSPORT COMPANY
      </label>
  </div>
  <div class="col-lg-4 pt-0 px-2">
      <label class="text-white text-center w-100 pdf-bg pdf-size11">
          WAREHOUSE
      </label>
  </div>
</div>

<div class="row g-0">
  <div class="col-lg-6 pt-0 px-2">
      {{ order?.pickCompany }}
  </div>
  <div class="col-lg-10 pt-0 px-2">
      {{ order?.location1 }}
  </div>
  <div class="col-lg-4 pt-0 px-2">
      {{ order?.transCompany1 }}
  </div>
  <div class="col-lg-4 pt-0 px-2">
      {{ order?.whseCompany }}
  </div>
</div>
<div class="row g-0">
  <div class="col-lg-6 pt-0 px-2">
      {{ order?.pickAddress }}
  </div>
  <div class="col-lg-10 pt-0 px-2">
      {{ order?.location2 }}
  </div>
  <div class="col-lg-4 pt-0 px-2">
      {{ order?.transCompany2 }}
  </div>
  <div class="col-lg-4 pt-0 px-2">
      {{ order?.whseAddress }}
  </div>
</div>

<div class="row g-0">
  <div class="col-lg-6 pt-0 px-2">
      {{ order?.pickCSZ }}
  </div>
  <div class="col-lg-10 pt-0 px-2">
      {{ order?.location3 }}
  </div>
  <div class="col-lg-4 pt-0 px-2">
      {{ order?.transCompany3 }}
  </div>
  <div class="col-lg-4 pt-0 px-2">
      {{ order?.whseCSZ }}
  </div>
</div>

<div *ngIf="order.whseEst" class="row g-0">
  <div class="col-lg-20 pt-0 px-2">
  </div>
  <div class="col-lg-4 pt-0 px-2">
    {{ order?.whseEst }}
  </div>
</div>
  
  <div class="row g-0">
  <div class="col-lg-10 pt-3 px-2">
      <span class="fw-bold">Vendor: </span>
      {{ order?.vendorInfo }}
  </div>
  <div class="col-lg-14 pt-3 px-2">
      <span class="fw-bold">Notes: </span>
      {{ order?.notes }}
  </div>
</div>

<div class="row g-0 text-white mt-3 pdf-bg pdf-size10">
  <div class="col-lg-2 pt-0 px-2">
      <label class="text-white">
          ORDER CODE
      </label>
  </div>
  <div class="col-lg-4 pt-0 px-2">
      <label class="text-white">
          BRAND/CATEGORY
      </label>
  </div>

  <div class="col-lg-6 pt-0 px-2">
      <label class="text-white">
          ITEM DESCRIPTION
      </label>
  </div>

  <div class="col-lg-2 pt-0 px-2">
      <label class="text-white">
          SIZE
      </label>
  </div>
  <div class="col-lg-2 pt-0 px-2">
      <label class="text-white text-end w-100">
          CASES
      </label>
  </div>
  <div class="col-lg-2 pt-0 px-2">
      <label class="text-white text-end w-100">
          CASE COST
      </label>
  </div>
  <div class="col-lg-2 pt-0 px-2">
      <label class="text-white text-end w-100">
          EXT CASE COST
      </label>
  </div>
    <div class="col-lg-2 pt-0 px-2">
        <label class="text-white text-end w-100">
            COMMISSION
        </label>
    </div>
    <div class="col-lg-2 pt-0 px-2">
        <label class="text-white text-end w-100">
            KALCOR
        </label>
    </div>
</div>
  
<div *ngFor="let item of orderItems; let i = index;">
  <div class="row g-0"
      [ngClass]="{'grid-stripe': grayRow[i]==='S'}">
      <div class="col-lg-2 pt-0 px-2 ">
          {{ item.orderCode}}
      </div>
      <div class="col-lg-4 pt-0 px-2 ">
          {{ item.brandCategory }}
      </div>
      <div class="col-lg-6 pt-0 px-2 ">
          {{ item.itemDescription}}
      </div>
      <div class="col-lg-2 pt-0 px-2 ">
          {{ item.size }}
      </div>
      <div class="col-lg-2 pt-0 px-2 text-end">
          {{ item.shipped  |  number : '1.0-0' }}
      </div>
      <div class="col-lg-2 pt-0 px-2  text-end">
          {{ item.caseCost | currency }}
      </div>
      <div class="col-lg-2 pt-0 px-2  text-end">
          {{ item.extCaseCost | currency }}
      </div>
      <div class="col-lg-2 pt-0 px-2  text-end">
            {{ commPrt(i) }}
        </div>
        <div class="col-lg-2 pt-0 px-2  text-end">
            {{ kalcorPrt(i) }}
        </div>
    </div>
</div>

<div class="row g-0 text-white mt-3 pdf-bg pdf-size12">
  <div class="col-lg-4 pt-0 px-2">
      <span class="pe-2">TOTAL CASES</span>
      {{ totalCases | number : '1.0-0' }}
  </div>
  <div class="col-lg-4 pt-0 px-2">
      <span class="pe-2">TOTAL PALLETS</span>
      {{ totalPallets |  number : '1.0-0' }}
  </div>

  <div class="col-lg-6 pt-0 px-2">
      <span class="pe-2">TOTAL GROSS</span>
      {{ totalGrossLbs | number : '1.2-2'  }}
  </div>

  <div class="col-lg-6 pt-0 px-2">
      <span class="pe-2">TOTAL CASE COST</span>
      {{ totalCaseCost | currency }}
  </div>
  <div class="col-lg-2 pt-0 px-2 text-end">
      {{ totalCommAmount | currency }}
  </div>
  <div class="col-lg-2 pt-0 px-2 text-end">
        {{ totalKalcorAmount | currency }}
    </div>
</div>

 <div class="container">
         <div class="row">
             <div class="col-md-4 ms-0 ps-0 mt-3">
                <img class="logo img-responsive" src="/assets/images/Kalcor_Canada.png"  width="158px">
             </div>
             <div class="col-md-20 mt-3">
                 <div class="row h-100">
                     <div class="col-md-6">CANADA OFFICES:</div>
                     <div class="col-md-6">CND Funds payments by cheque:</div>
                     <div class="col-md-6">CND Funds Payments by EFT</div>
                     <div class="col-md-4 text-end">Total Invoice</div>
                     <div class="col-md-2 text-end">{{ totalInvoice | currency }}</div>

                     <div class="col-md-6">2275 Upper Middle Road East, Suite 101</div>
                     <div class="col-md-6">Kalcor CANADA, Inc.</div>
                     <div class="col-md-6">Kalcor CANADA, Inc.</div>
                     <div class="col-md-4 text-end border-bottom border-dark">GST Tax (5%)</div>
                     <div class="col-md-2 text-end border-bottom border-dark">{{ gstTaxAmount | currency }}</div>

                     <div class="col-md-6">Oakville, ON, L6H 0C3</div>
                     <div class="col-md-6">2275 Upper Middle Road East, Suite 101</div>
                     <div class="col-md-6">Transit No. 20852</div>
                     <div class="col-md-4 text-end border-bottom border-dark border-2">Grand Total Invoice</div>
                     <div class="col-md-2 text-end border-bottom border-dark border-2">{{ grandTotalInvoice | currency }}</div>

                     <div class="col-md-6">Office 866.779.9850</div>
                     <div class="col-md-6">Oakville, ON, L6H 0C3</div>
                     <div class="col-md-6">Inst. No. 004</div>
                     <div class="col-md-6"></div>

                     <div class="col-md-6">Email: Inbox@kalcor.ca</div>
                     <div class="col-md-6"></div>
                     <div class="col-md-6">Account No. 5025386</div>
                     <div class="col-md-6"></div>

                     <div class="col-md-6">Website: www.kalcor.ca</div>
                     <div class="col-md-6"></div>
                     <div class="col-md-6"></div>
                     <div class="col-md-6 text-center">GST/HST 74437 1519 RT0001</div>


                 </div>
             </div>
         </div>
     </div>
</div>