<h5>Product Freight Review</h5>
<div class="smallSpacer"><h6>Filter</h6></div>
<div class="col-lg-44">

  <label class="formControlName ms-3">
    Brand:
    <select class="form-control" [(ngModel)]="brand" (change)="resetFilters()">
      <option [value]=""></option>
      <option *ngFor="let b of brandFilter" [value]="b">{{ b }}</option>
    </select>
  </label>

  <label class="formControlName ms-3">
    Category:
    <select class="form-control" [(ngModel)]="category" (change)="resetFilters()">
        <option [value]=""></option>
        <option *ngFor="let c of categoryFilter" [value]="c">{{ c }}</option>
    </select>
  </label>

  <label class="formControlName ms-3">
    Type of Costing:
    <select class="form-control" [(ngModel)]="costing" (change)="resetFilters()">
        <option [value]=""></option>
        <option *ngFor="let t of costingFilter" [value]="t">{{ t }}</option>
    </select>
  </label>

  <label class="formControlName ms-3">
    Cost Type:
    <select class="form-control" [(ngModel)]="costType" (change)="resetFilters()">
      <option [value]=""></option>
      <option *ngFor="let t of costTypeFilter" [value]="t">{{ t }}</option>
    </select>
  </label>

  <label class="formControlName ms-3">
    Group:
    <select class="form-control" [(ngModel)]="group" (change)="resetFilters()">
      <option [value]=""></option>
      <option *ngFor="let t of groupFilter" [value]="t">{{ t }}</option>
    </select>
  </label>
  <div class="smallSpacer"></div>
  <button type="button" class="btn btn-primary mt-0 ms-3" (click)="getDefaultDataScreen()">Reset</button>
</div>
<div class="spacer"></div>

<table class="table table-striped">
  <thead>
  <tr>
    <th scope="col">Order Code</th>
    <th scope="col">Item Description</th>
    <th scope="col">Size</th>
    <th scope="col">Blended Freight Cost</th>
    <th scope="col">Blended%</th>    
    
    <th scope="col">Location 1 Starting</th>
    <th scope="col">Location 1 Ending</th>
    <th scope="col">Total Cost 1</th>
    <th scope="col">Location 1%</th>
    <th scope="col">Transport Co. 1</th>
    <th scope="col">Freight Type</th>
    <th scope="col">Cost</th>
    <th scope="col">FSC%</th>
    <th scope="col">Notes</th>

    <th scope="col">Location 2 Starting</th>
    <th scope="col">Location 2 Ending</th>
    <th scope="col">Total Cost 2</th>
    <th scope="col">Location 2%</th>
    <th scope="col">Transport Co. 2</th>
    <th scope="col">Freight Type</th>
    <th scope="col">Cost</th>
    <th scope="col">FSC%</th>
    <th scope="col">Notes</th>

    <th scope="col">Location 3 Starting</th>
    <th scope="col">Location 3 Ending</th>
    <th scope="col">Total Cost 3</th>
    <th scope="col">Location 3%</th>
    <th scope="col">Transport Co. 3</th>
    <th scope="col">Freight Type</th>
    <th scope="col">Cost</th>
    <th scope="col">FSC%</th>
    <th scope="col">Notes</th>

    <th scope="col">Location 4 Starting</th>
    <th scope="col">Location 4 Ending</th>
    <th scope="col">Total Cost 4</th>
    <th scope="col">Location 4%</th>
    <th scope="col">Transport Co. 4</th>
    <th scope="col">Freight Type</th>
    <th scope="col">Cost</th>
    <th scope="col">FSC%</th>
    <th scope="col">Notes</th>

    <th scope="col">Location 5 Starting</th>
    <th scope="col">Location 5 Ending</th>
    <th scope="col">Total Cost 5</th>
    <th scope="col">Location 5%</th>
    <th scope="col">Transport Co. 5</th>
    <th scope="col">Freight Type</th>
    <th scope="col">Cost</th>
    <th scope="col">FSC%</th>
    <th scope="col">Notes</th>

    <th scope="col">Location 6 Starting</th>
    <th scope="col">Location 6 Ending</th>
    <th scope="col">Total Cost 6</th>
    <th scope="col">Location 6%</th>
    <th scope="col">Transport Co. 6</th>
    <th scope="col">Freight Type</th>
    <th scope="col">Cost</th>
    <th scope="col">FSC%</th>
    <th scope="col">Notes</th>

    <th scope="col">Location 7 Starting</th>
    <th scope="col">Location 7 Ending</th>
    <th scope="col">Total Cost 7</th>
    <th scope="col">Location 7%</th>
    <th scope="col">Transport Co. 7</th>
    <th scope="col">Freight Type</th>
    <th scope="col">Cost</th>
    <th scope="col">FSC%</th>
    <th scope="col">Notes</th>

    <th scope="col">Location 8 Starting</th>
    <th scope="col">Location 8 Ending</th>
    <th scope="col">Total Cost 8</th>
    <th scope="col">Location 8%</th>
    <th scope="col">Transport Co. 8</th>
    <th scope="col">Freight Type</th>
    <th scope="col">Cost</th>
    <th scope="col">FSC%</th>
    <th scope="col">Notes</th>

    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let p of products | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize">
    <td>
      <a (click)="onRowClicked(p)" class="pointer">
        <u class="text-primary">{{ p.orderCode }}</u>
      </a>
    </td>
    <td>{{ p.itemDescription }}</td>
    <td>{{ p.size }}</td>
    <td>{{ p.blendedFreightCost | currency   }}</td>
    <td>{{ p.blended }}%</td>

    <td>{{ getLocation(p.location1Id) }}</td>
    <td>{{ getLocation(p.shippingLaneEnd1) }}</td>
    <td>{{ p.truckCost1 | currency  }}</td>
    <td>{{ p.location1 }}%</td>
    <td>{{ getTransport(p.transportCo1) }}</td>
    <td>{{ p.freightType1 }}</td>
    <td>{{ p.freightCost1 | currency }}</td>
    <td>{{ p.freightCostSales1}}%</td>
    <td>{{ p.transNotesCo1 }}</td>

    <td>{{ getLocation(p.location2Id) }}</td>
    <td>{{ getLocation(p.shippingLaneEnd2) }}</td>
    <td>{{ p.truckCost2 | currency  }}</td>
    <td>{{ p.location2 }}%</td>
    <td>{{ getTransport(p.transportCo2) }}</td>
    <td>{{ p.freightType2 }}</td>
    <td>{{ p.freightCost2 | currency }}</td>
    <td>{{ p.freightCostSales2}}%</td>
    <td>{{ p.transNotesCo2 }}</td>

    <td>{{ getLocation(p.location3Id) }}</td>
    <td>{{ getLocation(p.shippingLaneEnd3) }}</td>
    <td>{{ p.truckCost3 | currency  }}</td>
    <td>{{ p.location3 }}%</td>
    <td>{{ getTransport(p.transportCo3) }}</td>
    <td>{{ p.freightType3 }}</td>
    <td>{{ p.freightCost3 | currency }}</td>
    <td>{{ p.freightCostSales3}}%</td>
    <td>{{ p.transNotesCo3 }}</td>

    <td>{{ getLocation(p.location4Id) }}</td>
    <td>{{ getLocation(p.shippingLaneEnd4) }}</td>
    <td>{{ p.truckCost4 | currency  }}</td>
    <td>{{ p.location4 }}%</td>
    <td>{{ getTransport(p.transportCo4) }}</td>
    <td>{{ p.freightType4 }}</td>
    <td>{{ p.freightCost4 | currency }}</td>
    <td>{{ p.freightCostSales4}}%</td>
    <td>{{ p.transNotesCo4 }}</td>

    <td>{{ getLocation(p.location5Id) }}</td>
    <td>{{ getLocation(p.shippingLaneEnd5) }}</td>
    <td>{{ p.truckCost5 | currency  }}</td>
    <td>{{ p.location5 }}%</td>
    <td>{{ getTransport(p.transportCo5) }}</td>
    <td>{{ p.freightType5 }}</td>
    <td>{{ p.freightCost5 | currency }}</td>
    <td>{{ p.freightCostSales5}}%</td>
    <td>{{ p.transNotesCo5 }}</td>

    <td>{{ getLocation(p.location6Id) }}</td>
    <td>{{ getLocation(p.shippingLaneEnd6) }}</td>
    <td>{{ p.truckCost6 | currency  }}</td>
    <td>{{ p.location6 }}%</td>
    <td>{{ getTransport(p.transportCo6) }}</td>
    <td>{{ p.freightType6 }}</td>
    <td>{{ p.freightCost6 | currency }}</td>
    <td>{{ p.freightCostSales6}}%</td>
    <td>{{ p.transNotesCo6 }}</td>

    <td>{{ getLocation(p.location7Id) }}</td>
    <td>{{ getLocation(p.shippingLaneEnd7) }}</td>
    <td>{{ p.truckCost7 | currency  }}</td>
    <td>{{ p.location7 }}%</td>
    <td>{{ getTransport(p.transportCo7) }}</td>
    <td>{{ p.freightType7 }}</td>
    <td>{{ p.freightCost7 | currency }}</td>
    <td>{{ p.freightCostSales7}}%</td>
    <td>{{ p.transNotesCo7 }}</td>

    <td>{{ getLocation(p.location8Id) }}</td>
    <td>{{ getLocation(p.shippingLaneEnd8) }}</td>
    <td>{{ p.truckCost8 | currency  }}</td>
    <td>{{ p.location8 }}%</td>
    <td>{{ getTransport(p.transportCo8) }}</td>
    <td>{{ p.freightType8 }}</td>
    <td>{{ p.freightCost8 | currency }}</td>
    <td>{{ p.freightCostSales8}}%</td>
    <td>{{ p.transNotesCo8 }}</td>

  </tr>
  <div *ngIf="products!==undefined">
  <ngb-pagination
  [(page)]="page"
  [pageSize]="pageSize"
  [collectionSize]="products?.length"></ngb-pagination>
  </div>
  </tbody>
</table>
