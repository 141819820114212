<h5>Product Pallet Review</h5>
<div class="smallSpacer"><h6>Filter</h6></div>
<div class="col-lg-44">

  <label class="formControlName ms-3">
    Brand:
    <select class="form-control" [(ngModel)]="brand" (change)="resetFilters()">
      <option [value]=""></option>
      <option *ngFor="let b of brandFilter" [value]="b">{{ b }}</option>
    </select>
  </label>

  <label class="formControlName ms-3">
    Category:
    <select class="form-control" [(ngModel)]="category" (change)="resetFilters()">
        <option [value]=""></option>
        <option *ngFor="let c of categoryFilter" [value]="c">{{ c }}</option>
    </select>
  </label>

  <label class="formControlName ms-3">
    Type of Costing:
    <select class="form-control" [(ngModel)]="costing" (change)="resetFilters()">
        <option [value]=""></option>
        <option *ngFor="let t of costingFilter" [value]="t">{{ t }}</option>
    </select>
  </label>

  <label class="formControlName ms-3">
    Cost Type:
    <select class="form-control" [(ngModel)]="costType" (change)="resetFilters()">
      <option [value]=""></option>
      <option *ngFor="let t of costTypeFilter" [value]="t">{{ t }}</option>
    </select>
  </label>

  <label class="formControlName ms-3">
    Group:
    <select class="form-control" [(ngModel)]="group" (change)="resetFilters()">
      <option [value]=""></option>
      <option *ngFor="let t of groupFilter" [value]="t">{{ t }}</option>
    </select>
  </label>
  <div class="smallSpacer"></div>
  <button type="button" class="btn btn-primary mt-0 ms-3" (click)="getDefaultDataScreen()">Reset</button>
</div>
<div class="spacer"></div>

<table class="table table-striped">
  <thead>
  <tr>
    <!-- <th scope="col">Type of Costing</th>
    <th scope="col">Cost Type</th> -->
    <th scope="col">Order Code</th>
    <!-- <th scope="col">Brand</th>
    <th scope="col">Category</th> -->
    <th scope="col">Item Description</th>
    <th scope="col">Size</th>

    <th scope="col">ti</th>
    <th scope="col">hi</th>
    <th scope="col">csPerPallet</th>
    <th scope="col">palletPerTruck</th>
    <th scope="col">csPerTruck</th>
    <th scope="col">palletType</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let p of products | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize">
  <!-- <tr *ngFor="let p of products | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize" (click)="onRowClicked(p)"> -->
    <!-- <td>{{ p.activeListings }}</td>
    <td>{{ p.costType }}</td> -->
    <td>
      <a (click)="onRowClicked(p)" class="pointer">
        <u class="text-primary">{{ p.orderCode }}</u>
      </a>
    </td>
    <!-- <td>{{ p.brand }}</td>
    <td>{{ p.category }}</td> -->
    <td>{{ p.itemDescription }}</td>
    <td>{{ p.size }}</td>

    <td>{{ p.ti }}</td>
    <td>{{ p.hi }}</td>
    <td>{{ p.csPerPallet }}</td>
    <td>{{ p.palletPerTruck }}</td>
    <td>{{ p.csPerTruck }}</td>
    <td>{{ p.palletType1 }}</td>
    

  </tr>
  <div *ngIf="products!==undefined">
  <ngb-pagination
  [(page)]="page"
  [pageSize]="pageSize"
  [collectionSize]="products?.length"></ngb-pagination>
  </div>
  </tbody>
</table>
