import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { BorderCrossing } from '../../models/border-crossing.model';
import { BorderCrossingService } from '../../services/border-crossing.service';

@Injectable()
export class BorderCrossingResolver implements Resolve<BorderCrossing> {
  constructor(private borderCrossingService: BorderCrossingService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<BorderCrossing> {
    const idField = 'id';
    const id =  parseInt(route.params[idField], 10);

    if (id === 0) {
      return;
    }
    return this.borderCrossingService.getOne(id);
  }
}
