<h5>Exchange Rage List </h5>
<table class="table table-striped" style="overflow-x:scroll;">
    <thead>
    <tr>
      <th scope="col">Exchange Rate</th>
      <th scope="col">Default</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let e of exchangeRate | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize" (click)="onRowClicked(e)">
      <td>{{ e.exchangeRateAmount }}</td>
      <td>{{ e.exchangeDefault }}</td>
    </tr>
    </tbody>
  </table>
  <div *ngIf="exchangeRate!==undefined">
    <ngb-pagination [(page)]="page" [pageSize]="pageSize" [collectionSize]="exchangeRate.length">
    </ngb-pagination>
  </div>
  <ngb-alert *ngIf="successMessage" [type]="alertType" (close)="successMessage = null">{{ successMessage }}</ngb-alert>  
  