<h5>{{ title }} </h5>
<form [formGroup]="productGroupForm" (ngSubmit)="onSubmit()" novalidate>
  <div class="form-group">

    <div class="col-lg-24">
      <label>
        Product Group:
        <input type="text" formControlName="productGroupNo" class="form-control" size="60">
      </label>
    </div>

    <div class="col-lg-24">
        <label>
            Vendor:
            <select class="form-control" formControlName="vendorId">
              <option [value]=""></option>
              <option *ngFor="let v of vendors" [value]="v.id">{{ v.company }}</option>
            </select>
          </label>
    </div>
   


    <button type="submit" class="btn btn-primary mt-3 w-25">Save</button>
    <button type="button" class="btn btn-secondary ms-3 mt-3 w-25" (click)="onDelete()">Delete</button>
    </div>
     <ngb-alert *ngIf="successMessage" type="success" (close)="successMessage = null">{{ successMessage }}</ngb-alert>
</form>

