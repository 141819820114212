import { Component, OnInit } from '@angular/core';
import { ClearingCompanyService } from '../../../services/clearing-company.service';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
@Component({
  selector: 'app-clearing-company-list',
  templateUrl: './clearing-company-list.component.html',
  styleUrls: ['./clearing-company-list.component.scss']
})
export class ClearingCompanyListComponent implements OnInit {
  clearing: any;
  successMessage: string;
  private success = new Subject<string>();
  alertType = "success";
  staticAlertClosed = false;
  constructor(private router: Router,
              private clearingSvc: ClearingCompanyService) { }

  ngOnInit() {
    this.clearingSvc.getAll().subscribe(data => {
      this.clearing = data
    },error => {
      this.successMessage = 'Unable to process request';
      this.alertType = "danger";
    });
    setTimeout(() => this.staticAlertClosed = true, 20000);
    this.success.subscribe((message) => this.successMessage = message);
    this.success.pipe(
      debounceTime(5000)
    ).subscribe(() => this.successMessage = null);       
  }
  onRowClicked(clear) {
    this.router.navigate(['/other/clearing-co-edit', clear.id]);
  }
}
