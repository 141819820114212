<h5>Region</h5>
<table class="table table-striped" style="overflow-x:scroll;">
    <thead>
    <tr>
      <th scope="col">Region</th>
      <th scope="col">Province</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let r of region | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize" (click)="onRowClicked(r)">
      <td>{{ r.regionName }}</td>
      <td>{{ r.province }}</td>
    </tr>
    </tbody>
  </table>
  <div *ngIf="region!==undefined">
    <ngb-pagination [(page)]="page" [pageSize]="pageSize" [collectionSize]="region.length">
    </ngb-pagination>
  </div>
  <ngb-alert *ngIf="successMessage" [type]="alertType" (close)="successMessage = null">{{ successMessage }}</ngb-alert>  
  
