import { Component, OnInit } from '@angular/core';
import { VendorService } from '../../../../services/vendor.service';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
@Component({
  selector: 'app-vendor-list',
  templateUrl: './vendor-list.component.html',
  styleUrls: ['./vendor-list.component.scss']
})
export class VendorListComponent implements OnInit {
  vendor: any;
  activeItemSelected: string;
  companySelected: number;
  companyOptions:[];
  page: number;
  pageSize: number;
  count: number;
  successMessage: string;
  private success = new Subject<string>();
  alertType = "success";
  staticAlertClosed = false;

  constructor(private router: Router,
              private vendorService: VendorService) { }

  ngOnInit() {
    this.reset();
    setTimeout(() => this.staticAlertClosed = true, 20000);
    this.success.subscribe((message) => this.successMessage = message);
    this.success.pipe(
      debounceTime(5000)
    ).subscribe(() => this.successMessage = null);    
  }
  loadPage() {
    this.vendorService.getFilteredListing(this.activeItemSelected, this.companySelected, this.page, this.pageSize).subscribe(data => {
      this.vendor = data.item;
      this.companyOptions = data.item2;
      this.count = data.count;
    },error => {
      this.successMessage = 'Unable to process request';
      this.alertType = "danger";
    });
  }
  reset() {
    this.page = 1;
    this.pageSize = 30;
    this.activeItemSelected = 'Active';
    this.companySelected = 0;
    this.loadPage();    
  }
  onRowClicked(clear) {
    this.router.navigate(['/vendors/vendor-edit', clear.id]);
  }
}
