import { Component, EventEmitter, OnInit, Output, Input} from '@angular/core';
import { AuthenticationService } from './../services/authentication.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { User } from '../models/user.model';
// import { analyzeAndValidateNgModules } from '@angular/compiler';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  @Output() logged = new EventEmitter<boolean>();
  @Input() sales: boolean;
  @Output() salesChange = new EventEmitter<boolean>();

  @Input() dashboard: boolean;
  @Input() customClearing: boolean;
  @Input() accounting: boolean;
  @Input() customer: boolean;
  @Input() vendor: boolean;
  @Input() other: boolean;
  @Input() items: boolean;
  @Input() products: boolean;
  @Input() productListing: boolean;
  @Input() projections: boolean;
  @Input() reports: boolean;
  @Input() portal: boolean;
  @Input() customerId: number;
  @Input() vendorId: number;
  @Input() brokerId: number;

  @Input() administrationRole: boolean;
  @Input() trafficRole: boolean;
  @Input() accountingRole: boolean;
  @Input() marketingRole: boolean;
  @Input() vendorRole: boolean;
  @Input() projectionsRole: boolean;
  
  @Output() dashboardChange = new EventEmitter<boolean>();
  @Output() customClearingChange = new EventEmitter<boolean>();
  @Output() accountingChange = new EventEmitter<boolean>();
  @Output() customerChange = new EventEmitter<boolean>();
  @Output() vendorChange = new EventEmitter<boolean>();
  @Output() otherChange = new EventEmitter<boolean>();
  @Output() itemsChange = new EventEmitter<boolean>();
  @Output() productsChange = new EventEmitter<boolean>();
  @Output() productListingChange = new EventEmitter<boolean>();
  @Output() projectionsChange = new EventEmitter<boolean>();
  @Output() reportsChange = new EventEmitter<boolean>();
  @Output() portalChange = new EventEmitter<boolean>();
  @Output() customerIdChange = new EventEmitter<number>();
  @Output() vendorIdChange = new EventEmitter<number>();
  @Output() brokerIdChange = new EventEmitter<number>();

  @Output() administrationRoleChange = new EventEmitter<boolean>();
  @Output() trafficRoleChange = new EventEmitter<boolean>();
  @Output() accountingRoleChange = new EventEmitter<boolean>();
  @Output() marketingRoleChange = new EventEmitter<boolean>();
  @Output() vendorRoleChange = new EventEmitter<boolean>();
  @Output() projectionsRoleChange = new EventEmitter<boolean>();

  user: User;
  model: any = {};
  loading = false;
  returnUrl: string;
  loginForm: UntypedFormGroup;
  error: string;
  adminLoggedIn = false;
  constructor(
    private route: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private formBuilder: UntypedFormBuilder,
    private router: Router
  ) {}

  ngOnInit() {
    const returnUrl = 'returnUrl';
    this.returnUrl = this.route.snapshot.queryParams[returnUrl] || '/';
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });
    this.adminLoggedIn = this.checkLogin();
  }
  checkLogin() {
    let retValue = false;
    if (sessionStorage.getItem('currentUser')) {
      const cUser = JSON.parse(sessionStorage.getItem('currentUser'));
      if (cUser.firstName == 'Richard Johnson') {
        return true;
      } 
    }
    return retValue;
  }
  get f() {
    return this.loginForm.controls;
  }
  login() {
    this.loading = true;
    this.authenticationService.login(this.f.username.value, this.f.password.value).subscribe(
      data => {
        this.user = data;
        if (this.user && this.user.token) {
          sessionStorage.setItem('currentUser', JSON.stringify(this.user));
          this.sales = this.user.sales === 1 ? true : false; 
          this.salesChange.emit(this.sales);
          this.dashboard = this.user.dashboard === 1 ? true : false;
          this.dashboardChange.emit(this.dashboard);
          this.customClearing = this.user.customClearing ? true : false;
          this.customClearingChange.emit(this.customClearing);
          this.accounting = this.user.accounting ? true : false;
          this.accountingChange.emit(this.accounting);
          this.customer = this.user.customer ? true : false;
          this.customerChange.emit(this.customer);
          this.vendor = this.user.vendor ? true : false;
          this.vendorChange.emit(this.vendor);
          this.other = this.user.other ? true : false;
          this.otherChange.emit(this.other);
          this.items = this.user.items ? true : false;
          this.itemsChange.emit(this.items);
          this.products = this.user.products ? true : false;
          this.productsChange.emit(this.products);
          this.productListing = this.user.productListing ? true : false;
          this.productListingChange.emit(this.productListing);
          this.projections = this.user.projections ? true : false;
          this.projectionsChange.emit(this.projections);
          this.reports = this.user.reports ? true : false;
          this.reportsChange.emit(this.reports);
          this.portal = this.user.portal ? true : false;
          this.portalChange.emit(this.portal);
          this.customerId = this.user.customerId ? this.customerId : 0;
          this.customerIdChange.emit(this.customerId);
          this.vendorId = this.user.vendorId ? this.vendorId : 0;
          this.vendorIdChange.emit(this.vendorId);
          this.brokerId = this.user.brokerId ? this.brokerId : 0;
          this.brokerIdChange.emit(this.brokerId); 
          this.trafficRole = this.user.trafficRole ? true : false;
          this.trafficRoleChange.emit(this.trafficRole);
          this.administrationRole = this.user.administrationRole ? true : false;
          this.administrationRoleChange.emit(this.administrationRole);
          this.accountingRole = this.user.accountingRole ? true : false;
          this.accountingChange.emit(this.accountingRole);
          this.marketingRole = this.user.marketingRole ? true : false;
          this.marketingRoleChange.emit(this.marketingRole);
          this.vendorRole = this.user.vendorRole ? true : false;
          this.vendorRoleChange.emit(this.vendorRole);
          this.projectionsRole = this.user.projectionsRole ? true : false;
          this.projectionsRoleChange.emit(this.vendorRole);
          this.logged.emit(true);
        } else {
          alert('Please re-enter username or password');
          this.logout();
        }
        this.loading = false;
        this.router.navigate(['dashboard']);
      },
      error => {
        // this.alertService.error(error);
        console.log(error);
        alert('Please re-enter username or password');
        this.error = error;
        this.loading = false;
      }
    );
  }
  turnLoginOn() {

  }
  // logged() {
  //   return true;
  // }
  logout() {
    // remove user from local storage to log user out
    sessionStorage.removeItem('currentUser');
  }
}
