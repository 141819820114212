<h5>KST Data Link To SharePoint</h5>

<div class="col-lg-44" style="border: 30px;">
    <!-- <label class="ms-3" style="font-size: large; padding-bottom:3px">
        SALES
        <div *ngFor="let y of year; index as i" (click)="clickedItem(i)">
            <a (click)="clickedItem(i)" class="pointer">
                <u class="text-primary">{{ y }}</u>
            </a>        
        </div>
    </label> -->
    <label class="ms-3" style="font-size: large; padding-bottom:3px">
        BUDGET & SALES
        <div *ngFor="let y of year; index as i" (click)="clickedItemBudget(i)">
            <a (click)="clickedItemBudget(i)" class="pointer">
                <u class="text-primary">{{ y }}</u>
            </a>        
        </div>
    </label>
</div>
<!-- <div class="col-lg-44">
    <label class="ms-3" style="font-size: large; padding-bottom:3px">
        SALES ONLY
        <div *ngFor="let y of year; index as i" (click)="clickedItem(i)">
            <a (click)="clickedItem(i)" class="pointer">
                <u class="text-primary">{{ y }}</u>
            </a>        
        </div>
    </label>
</div> -->