import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { BillingAgent } from 'src/app/models/billing-agent.model';
import { BillingAgentService } from 'src/app/services/billing-agent.service';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';


@Component({
  selector: 'app-billing-agent-edit',
  templateUrl: './billing-agent-edit.component.html',
  styleUrls: ['./billing-agent-edit.component.scss']
})
export class BillingAgentEditComponent implements OnInit{
  title = 'Billing Agent New';
  newOne = true;
  billingAgent: BillingAgent;
  billingAgentForm: UntypedFormGroup;
  successMessage: string;
  private success = new Subject<string>();
  staticAlertClosed = false;
  alertType = "success";

  constructor(private route: ActivatedRoute,
    private router: Router,
    private fb: UntypedFormBuilder,
    private billingAgentService: BillingAgentService
  ) {
    this.billingAgent = new BillingAgent();
  }

  ngOnInit(): void {
    this.billingAgentForm = this.fb.group({
      billingAgentName: ['', Validators.required]
    });
    this.route.data.subscribe(data => {
      if (data.dataBillingAgent !== undefined) {
        this.title = 'Billing Agent Edit';
        this.newOne = false;
        this.billingAgent = data.dataBillingAgent;
        this.fillFormData();
      } else { 
        this.title = 'Billing Agent New';
        this.newOne = true;
        this.billingAgent = new BillingAgent();
        this.billingAgentForm.reset();
      }
    });
    setTimeout(() => this.staticAlertClosed = true, 20000);
    this.success.subscribe((message) => this.successMessage = message);
    this.success.pipe(
      debounceTime(5000)
    ).subscribe(() => this.successMessage = null);
  }
  fillFormData() {
    this.billingAgentForm.setValue({
      billingAgentName: this.billingAgent.billingAgentName
    });
  }
  onSubmit() {
    if (this.billingAgentForm.invalid) {
      this.success.next('Please enter required field.');
      this.alertType = "danger";
      return;
    }
    this.billingAgent.billingAgentName = this.billingAgentForm.controls.billingAgentName.value;
    if (this.newOne) {
      this.billingAgentService.create(this.billingAgent).subscribe(data => {
        this.billingAgent.id = data.id;
        this.newOne = false;
        this.title = 'Billing Agent Edit';
        this.success.next('Successfully added.');
      }, error => {
      });
    } else {
      this.billingAgentService.update(this.billingAgent).subscribe(data => {
        this.success.next('Successfully changed.');
      }, error => {
      });
    }
  }
  onDelete() {
    if (this.billingAgent.id) {
      this.billingAgentService.remove(this.billingAgent.id).subscribe(data => {
        this.router.navigate(['/other/billing-agent-list']);
      })
    }
  }
}
