<div class="pdf">
  <div class="row g-0 text-white mt-2 px-2">
    <div class="col-lg-24 pt-3 ">
      <label class="text-center text-white w-100 pdf-size15">
        {{ title }}
      </label>
    </div>
  </div>
  <div class="row g-0 text-white mt-2 px-2">
      <div class="col-lg-4 pt-2 px-2 pdf-bg">
        <label class="text-white w-100">
          BILL TO
        </label>
      </div>
      <div class="col-lg-4 pt-2 px-2 pdf-bg">
        <label class="text-white w-100">
          SHIP TO
        </label>
      </div>
      <div class="col-lg-4 pt-2 px-2 pdf-bg">
        <label class="text-center text-white w-100">
          SALES ORDER INFORMATION
        </label>
      </div>
      <div class="col-lg-4 pt-2 px-2 pdf-bg">
        <label class="text-center text-white w-100">
          SHIPPING DATES/TYPES
        </label>
      </div>
      <div class="col-lg-4 pt-2 px-2 pdf-bg">
        <label class="text-white w-100">
          VENDOR PICK UP
        </label>
      </div>
      <div class="col-lg-2 pt-2 px-2">
      </div>
  
    <div class="col-lg-2 pt-2 ps-2 pdf-bg">
      <label class="text-center text-white w-100">
        BUDGET EX
      </label>
    </div>
  </div>

  <div class="row g-0 px-2">
    <div class="col-lg-4 pt-0 px-2">
      {{ order?.billTo }}
    </div>
    <div class="col-lg-4 pt-0 px-2">
      {{ order?.shipTo }}
    </div>
    <div class="col-lg-2 pt-0 px-2">
      Sales Order Date
    </div>
    <div class="col-lg-2 pt-0 px-2">
      {{ displayDate(order.salesOrderDate, '')  }}
    </div>
    <div class="col-lg-2 pt-0 px-2">
      Shipment Date
    </div>
    <div class="col-lg-2 pt-0 px-2">
      {{ displayDate(order.shipmentDate, '')  }}
    </div>
    <div class="col-lg-4 pt-0 px-2">
      {{ order?.pickCompany }}
    </div>
    <div class="col-lg-2 pt-0 px-2">
    </div>

    <div class="col-lg-2 pt-1 border text-center">
        {{ order?.exchangeBudget | number :'1.6' }}
    </div>
  </div>
  <div class="row g-0 px-2">
    <div class="col-lg-4 pt-0 px-2">
      {{ order?.billAddress }}
    </div>
    <div class="col-lg-4 pt-0 px-2">
      {{ order?.shipToAddress }}
    </div>
    <div class="col-lg-2 pt-0 px-2">
      Sales Order No.
    </div>
    <div class="col-lg-2 pt-0 px-2">
      {{ order?.salesOrderNo }}
    </div>
    <div class="col-lg-2 pt-0 px-2">
      Delivered Date/Appt
    </div>
    <div class="col-lg-2 pt-0 px-2">
      {{ displayDate(order.deliveredDateAppt, 'T')  }}
    </div>
    <div class="col-lg-4 pt-0 px-2">
      {{ order?.pickAddress }}
    </div>
    <div class="col-lg-2 pt-0 px-2">
    </div>

    <div class="col-lg-2 pt-1 border text-white text-center pdf-bg">
      ACTUAL EX
    </div>
  </div>
  <div class="row g-0 px-2">
    <div class="col-lg-4 pt-0 px-2">
      {{ order?.billCSZ }}
    </div>
    <div class="col-lg-4 pt-0 px-2">
      {{ order?.shipToCSZ }}
    </div>
    <div class="col-lg-2 pt-0 px-2">
      Purchase Order
    </div>
    <div class="col-lg-2 pt-0 px-2">
      {{ order?.purchaseOrder }}
    </div>
    <div class="col-lg-2 pt-0 px-2">
      App Confirmed
    </div>
    <div class="col-lg-2 pt-0 px-2">
      {{ order?.appConfirmed }}
    </div>
    <div class="col-lg-4 pt-0 px-2">
      {{ order?.pickCSZ }}
    </div>
    <div class="col-lg-2 pt-0 px-2">
    </div>

    <div class="col-lg-2 pt-1 border text-center bg-orange">
        {{ order?.exchangeActual | number :'1.7' }}
    </div>
  </div>
  
  <div class="row g-0 px-2">
    <div class="col-lg-2 offset-lg-4 pt-0 px-2">
      Payment Terms
    </div>
    <div class="col-lg-2 pt-0 px-2">
      {{ order?.paymentTerms }}
    </div>
    <div class="col-lg-2 pt-0 px-2">
      Delivery Type
    </div>
    <div class="col-lg-2 pt-0 px-2">
      {{ order?.freightType }}
    </div>
    <div class="col-lg-6 pt-0 px-2">
    </div>
    <div class="col-lg-2 pt-1 border text-white text-center pdf-bg">
      FOB Difference Exchange
    </div>
  </div>
  <div class="row g-0 px-2">
    <div class="col-lg-2 offset-lg-4 pt-0 px-2">
      Broker
    </div>
    <div class="col-lg-2 pt-0 px-2">
      {{ order?.broker }}
    </div>
    <div class="col-lg-2 pt-0 px-2">
      Order Status
    </div>
    <div class="col-lg-2 pt-0 px-2">
      {{ order?.orderStatus }}
    </div>
    <div class="col-lg-6 pt-0 px-2">
    </div>
    <div class="col-lg-2 pt-1 border text-center bg-orange">
      {{ budgetActual[indexFobDiff].actualAmount | currency }}
  </div>
</div>

  <div class="row g-0 mt-2 px-2">
    <div class="col-lg-8 pt-2 px-2 pdf-bg">
      <label class="text-white w-100">
        SHIPPING LANE
      </label>
    </div>
    <div class="col-lg-4 pt-2 px-2 pdf-bg">
      <label class="text-white w-100">
        TRANSPORT COMPANY
      </label>
    </div>
    <div class="col-lg-2 pt-2 px-2 pdf-bg">
      <label class="text-center text-white w-100">
        WAREHOUSE
      </label>
    </div>
    <div class="col-lg-6 pt-2 px-2">
      Vendor: {{ order?.vendor }} {{ order?.vendorAddress }}
    </div>
  </div>  
  <div class="row g-0 px-2">
    <div class="col-lg-8 pt-0 px-2">
      {{ order?.location1 }}
    </div>
    <div class="col-lg-4 pt-0 px-2">
      {{ order?.transCompany1 }}
    </div>
    <div class="col-lg-2 pt-0 px-2">
      {{ order?.whseCompany }}
    </div>
    <div class="col-lg-4 pt-0 px-2">
      {{ order?.vendorCSZ }}
    </div>
  </div>  
  <div class="row g-0 px-2">
    <div class="col-lg-8 pt-0 px-2">
      {{ order?.location2 }}
    </div>
    <div class="col-lg-4 pt-0 px-2">
      {{ order?.transCompany2 }}
    </div>
    <div class="col-lg-2 pt-0 px-2">
      {{ order?.whseCompany }}
    </div>
    <div class="col-lg-4 pt-0 px-2">
      Notes: {{ order?.notes }}
    </div>
  </div>    
  <div class="row g-0 text-white mt-2 px-2 break-CND">
    <div class="col-lg-24 pt-3 ">
      <label class="text-white w-100 pdf-size12">
        CND FUNDS
      </label>
    </div>
  </div>
  <div class="row g-0 text-white mt-2 px-2">
    <table class="w-100">
    <thead class="text-white mt-3 pe-2 pdf-bg-100">
      <th>ORDER CODE</th>
      <th>BRAND/CATEGORY</th>
      <th>ITEM DESCRIPTION</th>
      <th>SIZE</th>
      <th class="text-end">CASES</th>
      <th class="text-end">SHIPPED</th>
      <th class="text-end">INVOICED</th>
      <th class="text-end">CASE COST</th>
      <th class="text-end">EXT CASE COST</th>
      <th>MARKETING %</th>
      <th>MARKETING</th>
      <th>BILLING %</th>
      <th>BILLING</th>
      <th class="text-end">TERMS %</th>
      <th class="text-end">TERMS</th>
    </thead>
      <tbody class="text-dark">
        <tr *ngFor="let item of items; let i = index;">
          <td>{{ item.orderCode }}</td>
          <td>{{ item.brandCategory }}</td>
          <td>{{ item.itemDescription }}</td>
          <td>{{ item.size }}</td>
          <td class="text-end">{{ item.cases }}</td>
          <td class="text-end">{{ item.shipped }}</td>
          <td class="text-end">{{ item.invoiced }}</td>
          <td class="text-end">{{ item.caseCost | currency  }}</td>
          <td class="text-end">{{ item.extCaseCost | currency  }}</td>
          <td class="text-end">{{ item.marketing1 | number : '1.2-2' }}%</td>
          <td class="text-end">{{ item.marketing | currency }}</td>
          <td class="text-end">{{ item.billing1 | number : '1.2-2' }}%</td>
          <td class="text-end">{{ item.billing | currency  }}</td>
          <td class="text-end">{{ item.terms1 | number : '1.2-2' }}%</td>
          <td class="text-end">{{ item.terms | currency  }}</td>
        </tr>
        <tr>
          <td colspan="4" class="border border-left-0 border-right-0 text-end text-bold fw-bold border-dark">
            BUDGET TOTALS
          </td>
          <td class="border border-left-0 border-right-0 text-end border-dark fw-bold">
            {{ totalCases | number }}
          </td>
          <td class="border border-left-0 border-right-0 text-end border-dark fw-bold">
            {{ totalShipped | number }}
          </td>
          <td class="border border-left-0 border-right-0 text-end border-dark fw-bold">
            {{ totalInvoiced | number }}
          </td>
          <td class="border border-left-0 border-right-0 text-end border-dark">
          </td>
          <td class="border border-left-0 border-right-0 text-end border-dark fw-bold">
            {{ totalExtCost | currency  }}
          </td>
          <td class="border border-left-0 border-right-0 text-end border-dark">
          </td>
          <td class="border border-left-0 border-right-0 text-end border-dark fw-bold">
            {{ totalMarketing | currency  }}
          </td>
          <td class="border border-left-0 border-right-0 text-end border-dark">
          </td>
          <td class="border border-left-0 border-right-0 text-end border-dark fw-bold">
            {{ totalBilling | currency  }}
          </td>
          <td class="border border-left-0 border-right-0 text-end border-dark">
          </td>
          <td class="border border-left-0 border-right-0 text-end border-dark fw-bold">
            {{ totalTerms | currency  }}
          </td>
        </tr>
        <tr>
          <td colspan="4" class="border border-left-0 border-right-0 text-end text-bold fw-bold border-dark">
            ACTUAL TOTALS
          </td>
          <td class="border border-left-0 border-right-0 text-end border-dark fw-bold bg-orange">
            {{ totalCases | number }}
          </td>
          <td class="border border-left-0 border-right-0 text-end border-dark fw-bold bg-orange">
            {{ totalShipped | number }}
          </td>
          <td class="border border-left-0 border-right-0 text-end border-dark fw-bold bg-orange">
            {{ totalInvoiced | number }}
          </td>
          <td class="border border-left-0 border-right-0 text-end border-dark bg-orange">
          </td>
          <td class="border border-left-0 border-right-0 text-end border-dark fw-bold bg-orange">
            {{ totalExtCost | currency  }}
          </td>
          <td class="border border-left-0 border-right-0 text-end border-dark bg-orange">
          </td>
          <td class="border border-left-0 border-right-0 text-end border-dark fw-bold bg-orange">
            {{ totalMarketing | currency  }}
          </td>
          <td class="border border-left-0 border-right-0 text-end border-dark bg-orange">
          </td>
          <td class="border border-left-0 border-right-0 text-end border-dark fw-bold bg-orange">
            {{ budgetActual[indexBilling].actualAmount | currency  }}
          </td>
          <td class="border border-left-0 border-right-0 text-end border-dark bg-orange">
          </td>
          <td class="border border-left-0 border-right-0 text-end border-dark fw-bold bg-orange">
            {{ budgetActual[indexTerms].actualAmount | currency  }}
          </td>
        </tr>
        <tr>
          <td colspan="4" class="border border-left-0 border-right-0 text-end text-bold fw-bold border-dark">
            DIFFERENCE
          </td>
          <td class="border border-left-0 border-right-0 text-end border-dark fw-bold">
            {{ 0 }}
          </td>
          <td class="border border-left-0 border-right-0 text-end border-dark fw-bold">
            {{ 0 }}
          </td>
          <td class="border border-left-0 border-right-0 text-end border-dark fw-bold">
            {{ 0 }}
          </td>
          <td class="border border-left-0 border-right-0 text-end border-dark">
          </td>
          <td class="border border-left-0 border-right-0 text-end border-dark fw-bold">
            {{ 0 | currency  }}
          </td>
          <td class="border border-left-0 border-right-0 text-end border-dark">
          </td>
          <td class="border border-left-0 border-right-0 text-end border-dark fw-bold">
            {{ 0 | currency  }}
          </td>
          <td class="border border-left-0 border-right-0 text-end border-dark">
          </td>
          <td class="border border-left-0 border-right-0 text-end border-dark fw-bold">
            {{ budgetActual[indexBilling].difference  | currency  }}
          </td>
          <td class="border border-left-0 border-right-0 text-end border-dark">
          </td>
          <td class="border border-left-0 border-right-0 text-end border-dark fw-bold">
            {{ this.budgetActual[indexTerms].difference | currency  }}
          </td>
        </tr>
        <tr>
          <td colspan="11">
          </td>
          <td colspan="2" class="text-end">
            {{ budgetActual[indexBilling].company + ' ' +  budgetActual[indexBilling].reference }}
          </td>
          <td colspan="2" class="text-end">
            {{ budgetActual[indexTerms].company + ' ' +  budgetActual[indexTerms].reference }}
          </td>
        </tr>

      </tbody>
    </table>

    <table class="w-100 mt-3">
      <thead class="text-white mt-3 pe-2 pdf-bg-100">
        <th>ORDER CODE</th>
        <th>BRAND/CATEGORY</th>
        <th>ITEM DESCRIPTION</th>
        <th>SIZE</th>
        <th class="text-center">COMMISSION %</th>
        <th class="text-end">COMMISSION</th>
        <th class="text-center">KALCOR %</th>
        <th class="text-end">KALCOR</th>
        <th class="text-end">CND FRT Cost/Case</th>
        <th class="text-end">Storage Cost/Case</th>
      </thead>
      <tbody class="text-dark">
        <tr *ngFor="let item of items; let i = index;">
          <td>{{ item.orderCode }}</td>
          <td>{{ item.brandCategory }}</td>
          <td>{{ item.itemDescription }}</td>
          <td>{{ item.size }}</td>
          <td class="text-center">{{ item.commission1 + item.commission2 | number : '1.2-2' }}%</td>
          <td class="text-end">{{ item.commission11 + item.commission21 | currency }}</td>
          <td class="text-center">{{ item.kalcor1 | number : '1.2-2' }}%</td>
          <td class="text-end">{{ item.kalcor | currency  }}</td>
          <td class="text-end">{{ cndFrtCostCase[i] | currency  }}</td>
          <td class="text-end">{{ storageCostCase[i] | currency }}%</td>
        </tr>
        <tr>
          <td colspan="4" class="border border-left-0 border-right-0 text-end text-bold fw-bold border-dark">
            TOTALS
          </td>
          <td class="border border-left-0 border-right-0 text-end border-dark fw-bold">
          </td>
          <td class="border border-left-0 border-right-0 text-end border-dark fw-bold">
            {{ budgetActual[indexCommission].budgetAmount | currency }}
          </td>
          <td class="border border-left-0 border-right-0 text-end border-dark fw-bold">
          </td>
          <td class="border border-left-0 border-right-0 text-end border-dark fw-bold">
            {{ budgetActual[indexKalcor].budgetAmount | currency }}
          </td>
          <td class="border border-left-0 border-right-0 text-end border-dark fw-bold">
            {{ this.budgetActual[indexFreightCnd].budgetAmount | currency  }}
          </td>
          <td class="border border-left-0 border-right-0 text-end border-dark fw-bold">
            {{ budgetActual[indexStorage].budgetAmount | currency  }}
          </td>
        </tr>
        <tr>
          <td colspan="4" class="border border-left-0 border-right-0 text-end text-bold fw-bold border-dark">
            ACTUAL TOTALS
          </td>
          <td class="border border-left-0 border-right-0 text-end border-dark fw-bold bg-orange">
          </td>
          <td class="border border-left-0 border-right-0 text-end border-dark fw-bold bg-orange">
            {{ budgetActual[indexCommission].actualAmount | currency }}
          </td>
          <td class="border border-left-0 border-right-0 text-end border-dark fw-bold bg-orange">
          </td>
          <td class="border border-left-0 border-right-0 text-end border-dark fw-bold bg-orange">
            {{ budgetActual[indexKalcor].actualAmount | currency }}
          </td>
          <td class="border border-left-0 border-right-0 text-end border-dark fw-bold bg-orange">
            {{ budgetActual[indexFreightCnd].actualAmount | currency }}
          </td>
          <td class="border border-left-0 border-right-0 text-end border-dark fw-bold bg-orange">
            {{ budgetActual[indexStorage].actualAmount | currency }}
          </td>
        </tr>
    
        <tr>
          <td colspan="4" class="border border-left-0 border-right-0 text-end text-bold fw-bold border-dark">
            DIFFERENCE
          </td>
          <td class="border border-left-0 border-right-0 text-end border-dark fw-bold">
          </td>
          <td class="border border-left-0 border-right-0 text-end border-dark fw-bold">
            {{ 0 | currency }}
          </td>
          <td class="border border-left-0 border-right-0 text-end border-dark fw-bold">
          </td>
          <td class="border border-left-0 border-right-0 text-end border-dark fw-bold">
            {{ 0 | currency }}
          </td>
          <td class="border border-left-0 border-right-0 text-end border-dark fw-bold">
            {{ budgetActual[indexFreightCnd].difference | currency }}
          </td>
          <td class="border border-left-0 border-right-0 text-end border-dark fw-bold">
            {{ budgetActual[indexStorage].difference | currency }}
          </td>
        </tr>
        <tr>
          <td colspan="4">
          </td>
          <td colspan="2" class="text-end">
            {{ budgetActual[indexCommission].company + ' ' +  budgetActual[indexCommission].reference }}
          </td>
          <td colspan="2" class="text-end">
            {{ budgetActual[indexKalcor].company + ' ' +  budgetActual[indexKalcor].reference }}
          </td>
          <td class="text-end">
            {{ budgetActual[indexFreightCnd].company + ' ' +  budgetActual[indexFreightCnd].reference }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="row g-0 text-white mt-2 px-2 break-USD">
    <div class="col-lg-24 pt-3 ">
      <label class="text-white w-100 pdf-size12">
        USD FUNDS
      </label>
    </div>
  </div>
  <div class="row g-0 text-white mt-2 px-2">
    <table class="w-100">
      <thead class="text-white mt-3 pe-2 pdf-bg-100">
        <th>ORDER CODE</th>
        <th>BRAND/CATEGORY</th>
        <th>ITEM DESCRIPTION</th>
        <th>SIZE</th>
        <th class="text-end">VENDOR</th>
        <th class="text-end">EXT CASE COST</th>
        <th class="text-end">ROYALTY</th>
        <th class="text-end">CLEARING</th>
        <th class="text-end">DUTY</th>
        <th class="text-end">FREIGHT USD</th>
      </thead>
      <tbody class="text-dark">
        <tr *ngFor="let item of itemsUSD; let i = index;">
          <td>{{ item.orderCode }}</td>
          <td>{{ item.brandCategory }}</td>
          <td>{{ item.itemDescription }}</td>
          <td>{{ item.size }}</td>
          <td class="text-end">{{ usdVendorCost[i] | currency }}</td>
          <td class="text-end">{{ item.extCaseCost | currency }}</td>
          <td class="text-end">{{ usdRoyalty[i] | currency }}</td>
          <td class="text-end">{{ usdClearing[i] | currency }}</td>
          <td class="text-end">{{ usdDuty[i] | currency  }}</td>
          <td class="text-end">{{ usdFrtCost[i] | currency  }}</td>
        </tr>
        <tr>
          <td colspan="4" class="border border-left-0 border-right-0 text-end text-bold fw-bold border-dark">
            TOTALS
          </td>
          <td class="border border-left-0 border-right-0 text-end border-dark fw-bold">
          </td>
          <td class="border border-left-0 border-right-0 text-end border-dark fw-bold">
            {{ budgetActual[indexVendor].budgetAmount | currency }}
          </td>
          <td class="border border-left-0 border-right-0 text-end border-dark fw-bold">
            {{ budgetActual[indexRoyalty].budgetAmount | currency }}
          </td>
          <td class="border border-left-0 border-right-0 text-end border-dark fw-bold">
            {{ budgetActual[indexClearing].budgetAmount | currency }}
          </td>
          <td class="border border-left-0 border-right-0 text-end border-dark fw-bold">
            {{ this.budgetActual[indexDuty].budgetAmount | currency  }}
          </td>
          <td class="border border-left-0 border-right-0 text-end border-dark fw-bold">
            {{ budgetActual[indexFreightUSD].budgetAmount | currency  }}
          </td>
        </tr>
        <tr>
          <td colspan="4" class="border border-left-0 border-right-0 text-end text-bold fw-bold border-dark">
            ACTUAL TOTALS
          </td>
          <td class="border border-left-0 border-right-0 text-end border-dark fw-bold bg-orange">
          </td>
          <td class="border border-left-0 border-right-0 text-end border-dark fw-bold bg-orange">
            {{ budgetActual[indexVendor].actualAmount | currency }}
          </td>
          <td class="border border-left-0 border-right-0 text-end border-dark fw-bold bg-orange">
            {{ budgetActual[indexRoyalty].actualAmount | currency }}
          </td>
          <td class="border border-left-0 border-right-0 text-end border-dark fw-bold bg-orange">
            {{ budgetActual[indexClearing].actualAmount | currency }}
          </td>
          <td class="border border-left-0 border-right-0 text-end border-dark fw-bold bg-orange">
            {{ this.budgetActual[indexDuty].actualAmount | currency  }}
          </td>
          <td class="border border-left-0 border-right-0 text-end border-dark fw-bold bg-orange">
            {{ budgetActual[indexFreightUSD].actualAmount | currency  }}
          </td>
        </tr>
    
        <tr>
          <td colspan="4" class="border border-left-0 border-right-0 text-end text-bold fw-bold border-dark">
            DIFFERENCE
          </td>
          <td class="border border-left-0 border-right-0 text-end border-dark fw-bold">
          </td>
          <td class="border border-left-0 border-right-0 text-end border-dark fw-bold fw-bold">
            {{ budgetActual[indexVendor].difference | currency }}
          </td>
          <td class="border border-left-0 border-right-0 text-end border-dark fw-bold">
            {{ budgetActual[indexRoyalty].difference | currency }}
          </td>
          <td class="border border-left-0 border-right-0 text-end border-dark fw-bold">
            {{ budgetActual[indexClearing].difference | currency }}
          </td>
          <td class="border border-left-0 border-right-0 text-end border-dark fw-bold">
            {{ this.budgetActual[indexDuty].difference | currency  }}
          </td>
          <td class="border border-left-0 border-right-0 text-end border-dark fw-bold">
            {{ budgetActual[indexFreightUSD].difference | currency  }}
          </td>
        </tr>

        <tr>
          <td colspan="4">
          </td>
          <td colspan="2" class="text-end">
            {{ budgetActual[indexVendor].company + ' ' +  budgetActual[indexVendor].reference }}
          </td>
          <td class="text-end">
            {{ budgetActual[indexClearing].company + ' ' +  budgetActual[indexClearing].reference }}
          </td>
          <td class="text-end">
            {{ budgetActual[indexDuty].company + ' ' +  budgetActual[indexDuty].reference }}
          </td>
          <td class="text-end">
            {{ budgetActual[indexFreightUSD].company + ' ' +  budgetActual[indexFreightUSD].reference }}
          </td>
        </tr>
      </tbody>
  </table>
</div>
</div>