export class Roles {
    roleid: number;
    roleName: string;
    dashboard: number;
    sales: number;
    customClearing: number;
    accounting: number;
    customer: number;
    vendor: number;
    other: number;
    items: number;
    products: number;
    productListing: number;
    projections: number;
    reports: number;
    portal: number;
}
    