import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProductListing } from 'src/app/models/product.model';
import { ProductService } from 'src/app/services/product.service';
import { ShipToAddressService } from 'src/app/services/ship-to-address.service';
import { ShipToAddress } from 'src/app/models/shipto.model';
import { BrokerService } from 'src/app/services/broker.service';
import { Vendor } from 'src/app/models/vendor-model';
import { Broker } from 'src/app/models/broker.model';

@Component({
  selector: 'app-listing-list-costing',
  templateUrl: './listing-list-costing.component.html',
  styleUrls: ['./listing-list-costing.component.scss']
})
export class ListingListCostingComponent implements OnInit {
  products: ProductListing[];
  productsOriginal: ProductListing[];
  productsSelected: ProductListing[]=[];
  shipTo: ShipToAddress[];
  brokers: Broker[];
  commissions: [];
  activeListingsSelected: string;
  activeListingsOptions:[]=[];
  costTypeSelected: string;
  costTypeOptions:[]=[];
  customerSelected: number;
  customerOptions:[]=[];
  shipToSelected: number;
  shipToOptions: ShipToAddress[];
  vendorSelected: number;
  vendors: Vendor[];
  vendorOptions = [];
  brokerSelected: number;
  brokerOptions: Broker[];
  brandSelected: number;
  brandOptions:[]=[];
  categorySelected: number;
  categoryOptions:[]=[];
  page = 1;
  pageSize = 30;
  searchText = '';
  itemFound = [];
  collectionSize = 0;
  constructor(private route: ActivatedRoute,
    private router: Router,
    private productService: ProductService,
    private shipToService: ShipToAddressService,
    private brokerService: BrokerService
  ) {}

  ngOnInit(): void {
    this.shipToOptions = [];
    this.brokerOptions = [];
    this.activeListingsSelected = '';
    this.costTypeSelected = '';
    this.customerSelected = 0;
    this.shipToSelected = 0;
    this.brokerSelected = 0;
    this.brandSelected = 0;
    this.vendorSelected = 0;
    this.categorySelected = 0;
    this.shipToService.getAll().subscribe( shipTo => {
      this.shipTo = shipTo;
      this.brokerService.getAll().subscribe(broker => {
        this.brokers = broker;
        this.route.data.subscribe(data => {
          if (data.dataProductSetup !== undefined) {
            this.products = data.dataProductSetup.item;
            this.productsOriginal = data.dataProductSetup.item;
            this.activeListingsOptions = data.dataProductSetup.item2;
            this.costTypeOptions = data.dataProductSetup.item3;
            this.customerOptions = data.dataProductSetup.item4;
            this.brandOptions = data.dataProductSetup.item7;
            this.categoryOptions = data.dataProductSetup.item8;
            this.vendorOptions = data.dataProductSetup.item9;
            this.collectionSize =data.dataProductSetup.count;
            data.dataProductSetup.item5.forEach(e => {
              if (e !== null) {
                const ret = e.split(',');
                ret.forEach(element => {
                  this.buildShipOptions(element);
                });
              }
            });
            this.shipToOptions.sort((a, b) => a.company < b.company ? -1 : a.company > b.company ? 1 : 0);
            data.dataProductSetup.item.forEach(broker => {
              broker.broker = '';
              broker.broker2 = '';
              broker.items.forEach(b => {
                if (b !== null) {
                  const name1 = this.getBrokerNames(b.brokerId);
                  const name2 = this.getBrokerNames(b.broker2Id);
                  if (broker.broker.indexOf(name1) == -1 ) {
                    broker.broker += broker.broker && name1 ? ' / ' + name1 : name1;
                    this.buildBrokerOptions(b.brokerId, name1);
                  }  
                  if (broker.broker.indexOf(name2) == -1 ) {
                    broker.broker += broker.broker && name2 ? ' / ' + name2 : name2;
                    this.buildBrokerOptions(b.broker2Id, name2);
                  }  
                }
              })
            })
            this.brokerOptions.sort((a, b) => a.company < b.company ? -1 : a.company > b.company ? 1 : 0);
          }
        })
      })
    })
  }
  buildBrokerOptions(brokerId, company) {
    if (brokerId === null) {
      return;
    }
    const id = +brokerId;
    if (id) {
      const idx = this.brokerOptions.findIndex(broke => broke.id === id);
      if (idx == -1) {
        let broker = new Broker();
        broker.id = brokerId;
        broker.company = company;
        this.brokerOptions.push(broker);
      }
    }

  }
  buildShipOptions(e) {
    if (e === null) {
      return;
    }
    const id = +e;
    if (id) {
      const idx = this.shipToOptions.findIndex(ship => ship.id === id);
      if (idx > -1) {
        return;
      }
      const index = this.shipTo.findIndex(item => item.id === id);
      if (index >  -1) {
        let ship = new ShipToAddress();
        ship.id = this.shipTo[index].id;
        ship.company = this.shipTo[index].company;
        this.shipToOptions.push(ship);
      }
    }
  }
  getShipTo(id) {
    let ret = '';
    let shipToCount = 0;
    if (id) {
      const ids = id.split(',');
      ids.forEach(ship => {
        const firstId = +ship;
        const index = this.shipTo.findIndex(item => item.id === firstId);
        if (index >  -1) {
          shipToCount++;
          ret += ' (' + shipToCount + ')' + this.shipTo[index].company;
        }
        
      });
    }

    return ret;
  }
  getCommission1(row) {
    let ret = '';
    let commCount = 0;
    for (let i = 0; i < this.products[row].items.length; i ++) {
    if (this.products[row].items[i].commission1 > 0) {
      commCount++;
      ret += ' (' + commCount + ')' + this.products[row].items[i].commission1.toString() + '%';
    }
  }
    return ret;
  }
  getCommission2(row) {
    let ret = '';
    let commCount = 0;
    for (let i = 0; i < this.products[row].items.length; i ++) {
    if (this.products[row].items[i].commission2 > 0) {
      commCount++;
      ret += ' (' + commCount + ')' + this.products[row].items[i].commission2.toString() + '%';
    }
  }
    return ret;
  }
  getBroker(id, id2) {
    let ret = '';
    let brokerCount = 0;
    if (id) {
      const index = this.brokers.findIndex(item => item.id === id);
      if (index >  -1) {
        brokerCount++;
        ret = '(1)' + this.brokers[index].company;
      }
    }
    if (id2) {
      const index = this.brokers.findIndex(item => item.id === id2);
      if (index >  -1) {
        if (brokerCount > 0) {
          ret += " ";
        }
        ret += '(2)' + this.brokers[index].company;
      }
    }
    return ret;
  }

  getBrokerNames(id) {
    id = id === null ? 0 : id * 1;
    let name = '';
    if (id > -1) {
        const index = this.brokers.findIndex(item => item.id == id);
        if (index > -1) {
          name = this.brokers[index].company.trim();
        }
    } 
    return name;
  }
  getDefaultDataScreen() {
    this.activeListingsSelected = '';
    this.costTypeSelected = '';
    this.customerSelected = 0;
    this.shipToSelected = 0;
    this.vendorSelected = 0;
    this.brokerSelected = 0;
    this.brandSelected = 0;
    this.categorySelected = 0;
    this.page = 1;
    this.resetFilters();
  } 
  loadPage($event) {
    this.resetFilters();
  }
  resetFilters() {
    this.shipToOptions = [];
    this.brokerOptions = [];
    this.products = [];
    this.vendorOptions = [];
    this.productService.getFilteredListing(this.activeListingsSelected,
      this.costTypeSelected, 
      this.customerSelected,
      this.shipToSelected,
      this.brokerSelected,
      this.brandSelected,
      this.categorySelected,
      this.vendorSelected, 
      this.page,
      this.pageSize).subscribe( data => {
        if (this.brokerSelected) {
          data.item.forEach(b => {
            let found = false;
            b.items.forEach(e => {
              if (e.brokerId == this.brokerSelected || e.broker2Id == this.brokerSelected) {
                found = true;
              }
            });
            if (found) {
              this.products.push(b);
            }
          })
        } else {
        this.products = data.item;
        }
        this.activeListingsOptions = data.item2;
        this.costTypeOptions = data.item3;
        this.customerOptions = data.item4;
        this.brandOptions = data.item7;
        this.categoryOptions = data.item8;
        this.vendorOptions = data.item9;
        this.collectionSize = data.count;
        data.item5.forEach(e => {
          if (e !== null) {
            const ret = e.split(',');
            ret.forEach(element => {
              this.buildShipOptions(element);
            });
          }
        });
        this.shipToOptions.sort((a, b) => a.company < b.company ? -1 : a.company > b.company ? 1 : 0);
        data.item.forEach(broker => {
          broker.broker = '';
          broker.broker2 = '';
          broker.items.forEach(b => {
            if (b !== null) {
              const name1 = this.getBrokerNames(b.brokerId);
              const name2 = this.getBrokerNames(b.broker2Id);
              if (broker.broker.indexOf(name1) == -1 ) {
                broker.broker += broker.broker && name1 ? ' / ' + name1 : name1;
                this.buildBrokerOptions(b.brokerId, name1);
              }  
              if (broker.broker.indexOf(name2) == -1 ) {
                broker.broker += broker.broker && name2 ? ' / ' + name2 : name2;
                this.buildBrokerOptions(b.broker2Id, name2);
              }  
            }
          })
        })
        this.brokerOptions.sort((a, b) => a.company < b.company ? -1 : a.company > b.company ? 1 : 0);
  })
  }
  onRowClicked(product) {
    this.router.navigate(['/products/costing-bucket-setup-edit',  product.id]);
  }
}
