import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { ShipToBroker } from '../models/shipto-broker';
const url = environment.kalcorApiUrl;
@Injectable({
  providedIn: 'root'
})
export class ShipToBrokerService {

  constructor(private http: HttpClient) { }

  getAll(): Observable<any> {
    return this.http.get(url + 'ShipToBrokers', this.jwt());
  }
  getOne(id: number): Observable<any> {
    return this.http.get(url + 'ShipToBrokers/' + id, this.jwt());
  }
  
  getList(id: number): Observable<any> {
    return this.http.get(url + 'ShipToBrokers/' + id + '/Listing', this.jwt());
  }

  getCommission(id: number): Observable<any> {
    return this.http.get(url + 'ShipToBrokers/' + id + '/Commission', this.jwt());
  }

  update(shipToBroker: ShipToBroker) {
    return this.http.put(url + 'ShipToBrokers/' + shipToBroker.shipToBrokerId, shipToBroker, this.jwt());
  }

  updateBroker(shipToBroker: ShipToBroker) {
    return this.http.put(url + 'ShipToBrokers/' + shipToBroker.shipToBrokerId + '/Broker', shipToBroker, this.jwt());
  }

  updateCommission(shipToBroker: ShipToBroker) {
    return this.http.put(url + 'ShipToBrokers/' + shipToBroker.shipToBrokerId + '/Commission', shipToBroker, this.jwt());
  }
  create(shipToBroker: ShipToBroker): Observable<any> {
    return this.http.post(url + 'ShipToBrokers', shipToBroker, this.jwt());
  }
  remove(id: number) {
    return this.http.delete(url + 'ShipToBrokers/' + id, this.jwt());
  }

  private jwt() {
    // create authorization header with jwt token
    const currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
    if (currentUser && currentUser.token) {
        const headersInfo = new HttpHeaders({ Authorization: 'Bearer ' + currentUser.token });
        return { headers: headersInfo };
    }
  }

}
