<h5>Product Freight Review</h5>
<div class="smallSpacer"><h6>Filter</h6></div>
<div class="col-lg-44">

  <label class="formControlName ms-3">
    Brand:
    <select class="form-control" [(ngModel)]="brand" (change)="resetFilters()">
      <option [value]=""></option>
      <option *ngFor="let b of brandFilter" [value]="b">{{ b }}</option>
    </select>
  </label>

  <label class="formControlName ms-3">
    Category:
    <select class="form-control" [(ngModel)]="category" (change)="resetFilters()">
        <option [value]=""></option>
        <option *ngFor="let c of categoryFilter" [value]="c">{{ c }}</option>
    </select>
  </label>

  <label class="formControlName ms-3">
    Type of Costing:
    <select class="form-control" [(ngModel)]="costing" (change)="resetFilters()">
        <option [value]=""></option>
        <option *ngFor="let t of costingFilter" [value]="t">{{ t }}</option>
    </select>
  </label>

  <label class="formControlName ms-3">
    Cost Type:
    <select class="form-control" [(ngModel)]="costType" (change)="resetFilters()">
      <option [value]=""></option>
      <option *ngFor="let t of costTypeFilter" [value]="t">{{ t }}</option>
    </select>
  </label>

  <label class="formControlName ms-3">
    Group:
    <select class="form-control" [(ngModel)]="group" (change)="resetFilters()">
      <option [value]=""></option>
      <option *ngFor="let t of groupFilter" [value]="t">{{ t }}</option>
    </select>
  </label>
  <div class="smallSpacer"></div>
  <button type="button" class="btn btn-primary mt-0 ms-3" (click)="getDefaultDataScreen()">Reset</button>
</div>
<div class="spacer"></div>

<table class="table table-striped">
  <thead>
  <tr>
    <!-- <th scope="col">Type of Costing</th>
    <th scope="col">Cost Type</th> -->
    <th scope="col">Order Code</th>
    <!-- <th scope="col">Brand</th>
    <th scope="col">Category</th> -->
    <th scope="col">Item Description</th>
    <th scope="col">Size</th>

    <th scope="col">location1</th>
    <th scope="col">truckCost1</th>
    <th scope="col">location2</th>
    <th scope="col">truckCost2</th>
    <th scope="col">location3</th>
    <th scope="col">truckCost3</th>
    <th scope="col">location4</th>
    <th scope="col">truckCost4</th>
    <th scope="col">location5</th>
    <th scope="col">truckCost5</th>
    <th scope="col">location6</th>
    <th scope="col">truckCost6</th>
    <th scope="col">location7</th>
    <th scope="col">truckCost7</th>
    <th scope="col">location8</th>
    <th scope="col">truckCost8</th>
    <th scope="col">transportCo1</th>
    <th scope="col">transportCo2</th>
    <th scope="col">transportCo3</th>
    <th scope="col">transportCo4</th>
    <th scope="col">transportCo5</th>
    <th scope="col">transportCo6</th>
    <th scope="col">transportCo7</th>
    <th scope="col">transportCo8</th>
    <th scope="col">transNotesCo1</th>
    <th scope="col">transNotesCo2</th>
    <th scope="col">transNotesCo3</th>
    <th scope="col">transNotesCo4</th>
    <th scope="col">transNotesCo5</th>
    <th scope="col">transNotesCo6</th>
    <th scope="col">transNotesCo7</th>
    <th scope="col">transNotesCo8</th>
    <th scope="col">blended</th>
    <th scope="col">blendedFreightCost</th>
    <th scope="col">shippingLaneEnd1</th>
    <th scope="col">shippingLaneEnd2</th>
    <th scope="col">shippingLaneEnd3</th>
    <th scope="col">shippingLaneEnd4</th>
    <th scope="col">shippingLaneEnd5</th>
    <th scope="col">shippingLaneEnd6</th>
    <th scope="col">shippingLaneEnd7</th>
    <th scope="col">shippingLaneEnd8</th>
    <th scope="col">freightType1</th>
    <th scope="col">freightType2</th>
    <th scope="col">freightType3</th>
    <th scope="col">freightType4</th>
    <th scope="col">freightType5</th>
    <th scope="col">freightType6</th>
    <th scope="col">freightType7</th>
    <th scope="col">freightType8</th>
    <th scope="col">freightCost1</th>
    <th scope="col">freightCost2</th>
    <th scope="col">freightCost3</th>
    <th scope="col">freightCost4</th>
    <th scope="col">freightCost5</th>
    <th scope="col">freightCost6</th>
    <th scope="col">freightCost7</th>
    <th scope="col">freightCost8</th>
    <th scope="col">freightCostSales1</th>
    <th scope="col">freightCostSales2</th>
    <th scope="col">freightCostSales3</th>
    <th scope="col">freightCostSales4</th>
    <th scope="col">freightCostSales5</th>
    <th scope="col">freightCostSales6</th>
    <th scope="col">freightCostSales7</th>
    <th scope="col">freightCostSales8</th>

    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let p of products | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize">
  <!-- <tr *ngFor="let p of products | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize" (click)="onRowClicked(p)"> -->
    <!-- <td>{{ p.activeListings }}</td>
    <td>{{ p.costType }}</td> -->
    <td>
      <a (click)="onRowClicked(p)" class="pointer">
        <u class="text-primary">{{ p.orderCode }}</u>
      </a>
    </td>
    <!-- <td>{{ p.brand }}</td>
    <td>{{ p.category }}</td> -->
    <td>{{ p.itemDescription }}</td>
    <td>{{ p.size }}</td>

    <td>{{ p.location1 }}</td>
    <td>{{ p.truckCost1 }}</td>
    <td>{{ p.location2 }}</td>
    <td>{{ p.truckCost2 }}</td>
    <td>{{ p.location3 }}</td>
    <td>{{ p.truckCost3 }}</td>
    <td>{{ p.location4 }}</td>
    <td>{{ p.truckCost4 }}</td>
    <td>{{ p.location5 }}</td>
    <td>{{ p.truckCost5 }}</td>
    <td>{{ p.location6 }}</td>
    <td>{{ p.truckCost6 }}</td>
    <td>{{ p.location7 }}</td>
    <td>{{ p.truckCost7 }}</td>
    <td>{{ p.location8 }}</td>
    <td>{{ p.truckCost8 }}</td>
    <td>{{ p.transportCo1 }}</td>
    <td>{{ p.transportCo2 }}</td>
    <td>{{ p.transportCo3 }}</td>
    <td>{{ p.transportCo4 }}</td>
    <td>{{ p.transportCo5 }}</td>
    <td>{{ p.transportCo6 }}</td>
    <td>{{ p.transportCo7 }}</td>
    <td>{{ p.transportCo8 }}</td>
    <td>{{ p.transNotesCo1 }}</td>
    <td>{{ p.transNotesCo2 }}</td>
    <td>{{ p.transNotesCo3 }}</td>
    <td>{{ p.transNotesCo4 }}</td>
    <td>{{ p.transNotesCo5 }}</td>
    <td>{{ p.transNotesCo6 }}</td>
    <td>{{ p.transNotesCo7 }}</td>
    <td>{{ p.transNotesCo8 }}</td>
    <td>{{ p.blended }}</td>
    <td>{{ p.blendedFreightCost }}</td>
    <td>{{ p.shippingLaneEnd1 }}</td>
    <td>{{ p.shippingLaneEnd2 }}</td>
    <td>{{ p.shippingLaneEnd3 }}</td>
    <td>{{ p.shippingLaneEnd4 }}</td>
    <td>{{ p.shippingLaneEnd5 }}</td>
    <td>{{ p.shippingLaneEnd6 }}</td>
    <td>{{ p.shippingLaneEnd7 }}</td>
    <td>{{ p.shippingLaneEnd8 }}</td>
    <td>{{ p.freightType1 }}</td>
    <td>{{ p.freightType2 }}</td>
    <td>{{ p.freightType3 }}</td>
    <td>{{ p.freightType4 }}</td>
    <td>{{ p.freightType5 }}</td>
    <td>{{ p.freightType6 }}</td>
    <td>{{ p.freightType7 }}</td>
    <td>{{ p.freightType8 }}</td>
    <td>{{ p.freightCost1 }}</td>
    <td>{{ p.freightCost2 }}</td>
    <td>{{ p.freightCost3 }}</td>
    <td>{{ p.freightCost4 }}</td>
    <td>{{ p.freightCost5 }}</td>
    <td>{{ p.freightCost6 }}</td>
    <td>{{ p.freightCost7 }}</td>
    <td>{{ p.freightCost8 }}</td>
    <td>{{ p.freightCostSales1 }}</td>
    <td>{{ p.freightCostSales2 }}</td>
    <td>{{ p.freightCostSales3 }}</td>
    <td>{{ p.freightCostSales4 }}</td>
    <td>{{ p.freightCostSales5 }}</td>
    <td>{{ p.freightCostSales6 }}</td>
    <td>{{ p.freightCostSales7 }}</td>
    <td>{{ p.freightCostSales8 }}</td>


  </tr>
  <div *ngIf="products!==undefined">
  <ngb-pagination
  [(page)]="page"
  [pageSize]="pageSize"
  [collectionSize]="products?.length"></ngb-pagination>
  </div>
  </tbody>
</table>
