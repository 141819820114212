import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Brand } from '../../../models/brand.model';
import { BrandService } from '../../../services/brand.service';
import { Vendor } from '../../../models/vendor-model';
import { VendorService } from '../../../services/vendor.service';
import { SorterService } from '../../../services/sorter.service';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
@Component({
  selector: 'app-brand-detail',
  templateUrl: './brand-detail.component.html',
  styleUrls: ['./brand-detail.component.scss']
})
export class BrandDetailComponent implements OnInit {
  title = 'Brand New';
  newOne = true;
  brand: Brand;
  brandForm: UntypedFormGroup;
  successMessage: string;
  private success = new Subject<string>();
  staticAlertClosed = false;
  vendor: Vendor[] = [];
  constructor(private route: ActivatedRoute,
              private router: Router,
              private fb: UntypedFormBuilder,
              private brandService: BrandService,
              private sortSvc: SorterService,
              private vendorService: VendorService) { }

  ngOnInit() {
    this.brandForm = this.fb.group({
      activeItem:  [''],
      brand:  [''],
      vendorId:  [''],
    });
    this.vendorService.getAll().subscribe(data => {
      this.vendor = this.sortSvc.sorter(data, 'company');
    // this.vendorService.getFilteredListing('Active', 0, 1, 30).subscribe(data => {
    //   this.vendor = data.item;
      // this.vendor = this.sortSvc.sorter(this.vendor, 'company');
      this.route.data.subscribe(data2 => {
        if (data2.dataBrand !== undefined) {
          this.title = 'Brand Edit';
          this.newOne = false;
          this.brand = data2.dataBrand;
          this.fillFormData();
        } else {
          this.title = 'Brand New';
          this.newOne = true;
          this.brand = new Brand();
          this.brandForm.reset();
        }
      });
    });
    setTimeout(() => this.staticAlertClosed = true, 20000);
    this.success.subscribe((message) => this.successMessage = message);
    this.success.pipe(
      debounceTime(5000)
    ).subscribe(() => this.successMessage = null);
  }
  fillFormData() {
    this.brandForm.setValue({
      activeItem:  this.brand.activeItem,
      brand: this.brand.brand,
      vendorId:  this.brand.vendorId
    });
  }
  onSubmit() {
    this.brand.activeItem = this.brandForm.controls.activeItem.value;
    this.brand.brand = this.brandForm.controls.brand.value;
    this.brand.vendorId = this.brandForm.controls.vendorId.value;
    if (this.newOne) {
      this.brandService.create(this.brand).subscribe(data => {
        this.brand.id = data.id;
        this.newOne = false;
        this.title = 'Brand Edit';
        this.success.next(`Successfully added.`);
      }, error => {
      });
    } else {
      this.brandService.update(this.brand).subscribe(data => {
        this.success.next(`Successfully changed.`);
      }, error => {
      });
    }
  }
  onDelete() {
    if (this.brand.id) {
      this.brandService.remove(this.brand.id).subscribe(data => {
        this.router.navigate(['/items/brand-list']);
      });
    }
  }

}
