<div *ngIf="reports" class="bg-light text-dark fw-bold">
    REPORT PORTAL
    <div class="row">
        <div class="col-md-2 text-right">
            Select
        </div>
    </div>
    <div class="row">
        <div class="col-md-2">
            <div class="col-md-16 mt-2 text-right">
                Year
            </div>
            <div *ngFor="let y of years; let i = index">
                    <label class="col-md-16 text-right">{{ years[i] }}</label>
                    <input type="checkbox" [(ngModel)]="yearschecked[i]">
            </div>
        </div>
        <div class="col-md-2">
            <div class="col-md-16 mt-2 text-right">
                Month
            </div>
            <div *ngFor="let m of months; let i = index">
               <div>
                  <label class="col-md-16 text-right">{{ m }}</label>
                  <input type="checkbox" [(ngModel)]="monthsChecked[i]">
               </div>
            </div>
        </div>

        <div class="col-md-2">
            <div class="col-md-21 mt-2 text-right">
                Quarter
            </div>
            <div>
                <label class="col-md-16 text-right">Q1</label>
                <input type="checkbox" [(ngModel)]="quarter1Checked">
            </div>
            <div>
                <label class="col-md-16 text-right">Q2</label>
                <input type="checkbox" [(ngModel)]="quarter2Checked">
            </div>
            <div>
                <label class="col-md-16 text-right">Q3</label>
                <input type="checkbox" [(ngModel)]="quarter3Checked">
            </div>
            <div>
                <label class="col-md-16 text-right">Q4</label>
                <input type="checkbox" [(ngModel)]="quarter4Checked">
            </div>
        </div>
        <div class="col-md-4">
            <div class="col-md-24 mt-2">Credit Type</div>
            <div class="col-md-24 mt-2">
                <select [(ngModel)]="typeSelected">
                    <option [value]=""></option>
                    <option value="Actual">Actual</option>
                    <option value="Budget">Budget</option>
                    <option value="Both">Both</option>
                </select>
            </div>            
            <div class="col-md-24 mt-2">Order Type</div>
            <div class="col-md-24 mt-2">
                <select [(ngModel)]="OrderTypeSelected">
                    <option [value]=""></option>
                    <option value="Stock">Stock</option>
                    <option value="Retail">Retail</option>
                    <option value="Food Service">Food Service</option>
                    <option value="Both Retail/Food Service">Both Retail/Food Service</option>
                </select>
            </div>            
        </div>

        <div class="col-md-12">
            <div class="col-md-2 mt-2 text-right">
                Vendor
            </div>
            <div class="col-md-4 mt-2">
                <select [(ngModel)]="vendorSelected">
                    <option *ngIf="!lockVendor" [value]="0"></option>
                    <option *ngFor="let v of vendors" [value]="v.id">{{ v.company }}</option>
                </select>
            </div>
                <div class="col-md-2 text-right">
                    Customer
                </div>
                <div class="col-md-4">
                    <select [(ngModel)]="customerSelected" >
                        <option [value]="0"></option>
                        <option *ngFor="let c of customers" [value]="c.id">{{ c.company }}</option>
                    </select>
                </div>
                <div class="col-md-2 text-right">
                    Broker
                </div>
                <div class="col-md-4">
                    <select [(ngModel)]="brokerSelected" >
                        <option [value]=""></option>
                        <option *ngFor="let b of brokers" [value]="b.id">{{ b.company }}</option>
                    </select>
                </div>
                <div class="col-md-2 text-right mt-2">
                    Bucket
                </div>
                <div class="col-md-4 mt-2" >
                    <select [(ngModel)]="bucketSelected" >
                        <option [value]=""></option>
                        <option *ngFor="let b of buckets" [value]="b.buckets">{{ b.buckets }}</option>
                    </select>
                </div>
    
    
        </div>


    </div>

    <div class="row">
        <div class="col-md-2 mt-2 mb-3">
        </div>
        <div class="col-md-4 mt-2" >
            <button type="button" class="btn btn-primary mt-3 mb-3 w-75" (click)="run()">Run</button>
        </div>
    </div>
</div>

