import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class Sorter3Service {

  constructor() { }
  sorter(tobeSorted, name, name2, name3) {

    tobeSorted.sort((a, b) => {

      if (a[name] !== null && b[name] === null) {
        return 1;
      }
      if (a[name] === null && b[name] != null) {
        return -1;
      }
      if (a[name] < b[name]) {
        return -1;
      }
      if (a[name] > b[name]) {
        return 1;
      }

      if (a[name2] !== null && b[name2] === null) {
        return 1;
      }
      if (a[name2] === null && b[name2] !== null) {
        return -1;
      }
      if (a[name2] < b[name2]) {
        return -1;
      }
      if (a[name2] > b[name2]) {
        return 1;
      }
      
      if (a[name3] !== null && b[name3] === null) {
        return 1;
      }
      if (a[name3] === null && b[name3] !== null) {
        return -1;
      }
      if (a[name3] < b[name3]) {
        return -1;
      }
      if (a[name3] > b [name3]) {
        return 1;
      }
      return 0;
    });
    return tobeSorted;
  }
}
