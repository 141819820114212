<h5>PAYMENT</h5>
<form [formGroup]="brokerPayForm"  novalidate>
    <div class="form-group">

        <div class="row">
            <label for="paymentDate" class="col-lg-4 pt-2">
                Payment Date (*)
            </label>
            <div class="col-lg-4 pt-2">
            <input id="paymentDate" type="date" formControlName="paymentDate" class="form-control" >
        </div>
        </div>

        <div class="row">
            <label for="brokerPayNumber" class="col-lg-4 pt-2">
                Payment Number (*)
            </label>
            <div class="col-lg-4 pt-2">
            <input id="brokerPayNumber" type="text" formControlName="brokerPayNumber" class="form-control" >
            </div>
        </div>
        
        <div class="row">
            <label for="brokerId" class="col-lg-4 pt-2">
                BROKER
            </label>
            <div class="col-lg-4 pt-2">
            <select formControlName="brokerId" class="form-control">
                <option [value]=""></option>
                <option *ngFor="let b of brokers" [value]="b.id">{{ b.company }}</option>
            </select>
            </div>
        </div>

        <div class="row">
            <label for="vendorId" class="col-lg-4 pt-2">
                VENDOR (*)
            </label>
            <div class="col-lg-4 pt-2">
            <select formControlName="vendorId" class="form-control">
                <option [value]=""></option>
                <option *ngFor="let v of vendors" [value]="v.id">{{ v.company }}</option>
            </select>
            </div>
        </div>

        <div class="row">
            <label for="status" class="col-lg-4 pt-2">
                Status
            </label>
            <div class="col-lg-4 pt-2">
            <input id="status" type="text" formControlName="status" class="form-control" >
            </div>
        </div>

        <div class="row">
            <label for="reference" class="col-lg-4 pt-2">
                Reference
            </label>
            <div class="col-lg-6 pt-2">
            <textarea id="reference" formControlName="reference" class="form-control">
            </textarea>
        </div>
        </div>

        <div class="row">
            <label for="notes" class="col-lg-4 pt-2">
                Notes
            </label>
            <div class="col-lg-6 pt-2">
            <textarea id="notes" formControlName="notes" class="form-control">
            </textarea>
            </div>
        </div>

        <div class="row">
            <label for="paymentAmount" class="col-lg-4 pt-2">
                Payment Amount (*)
            </label>
            <div class="col-lg-4 pt-2">
            <input id="paymentAmount" type="text" formControlName="paymentAmount" class="form-control" >
            </div>
        </div>

        <div class="row">
            <label for="month" class="col-lg-4 pt-2">
                Month (*)
            </label>
            <div class="col-lg-4 pt-2">
            <select formControlName="month" class="form-control">
                <option [value]=""></option>
                <option *ngFor="let m of monthName" [value]="m">{{ m }}</option>
            </select>
            </div>
        </div>

        <div class="row">
            <label for="year" class="col-lg-4 pt-2">
                Year (*)
            </label>
            <div class="col-lg-4 pt-2">
            <input id="year" type="number" [min]="minYear" [max]="maxYear" step="1" formControlName="year" class="form-control" >
            </div>
        </div>

        <div class="row">
            <label for="bucket" class="col-lg-4 pt-2">
                Bucket (*)
            </label>
            <div class="col-lg-4 pt-2">
            <select id="bucket" formControlName="bucket" class="form-control">
                <option [value]=""></option>
                <option *ngFor="let b of buckets" [value]="b">{{ b }}</option>
            </select>
            </div>
        </div>
        <div class="row">
            <div class="col-4">
            </div>
            <div class="col-4 px-0">
              <button type="button" (click)="onSubmit('Open')" class="btn btn-primary mt-3 w-100">SAVE BROKER PAY - OPEN</button>
            </div>
        </div>
        <div class="row">
            <div class="col-4">
            </div>
            <div class="col-4 px-0">
              <button type="button" (click)="onSubmit('Closed')" class="btn btn-primary mt-3 w-100">SAVE BROKER PAY - CLOSED</button>
            </div>
        </div>
        <div class="row">
            <div class="col-4">
            </div>
            <div class="col-4 px-0">
              <button type="button" (click)="onDelete()" class="btn btn-secondary mt-3 w-100">Delete</button>
            </div>
        </div>

    </div>
</form>
<p>(*) Denotes Mandatory</p>
<ngb-alert *ngIf="successMessage" [type]="alertType" (close)="successMessage = null">{{ successMessage }}</ngb-alert>
