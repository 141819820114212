import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from "@angular/router";
import { Observable } from "rxjs";
import { KalcorData } from "src/app/models-azure/kalcor-data.model";
import { KalcorService } from "src/app/services-azure/kalcor.service";

@Injectable()
export class KalcorResolver implements Resolve<KalcorData> {
    kalcorData: KalcorData;
    constructor(private kalcorService: KalcorService) {

    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<KalcorData> {
        const idField = 'id';
        const id = parseInt(route.params[idField], 10);

        if (id === 0) {
            return;
        }
        return this.kalcorService.getOne(id);
    }
}