<div class="col-md-22 col-md-offset-3">
  <h2>Register</h2>
  <form [formGroup] ="registerForm" (ngSubmit)="f.form.valid && register()" #f="ngForm" novalidate>
      <div class="form-group">
          <label for="firstName">First Name</label>
          <input type="text" class="form-control" formControlName="firstName" required />
      </div>
      <div class="form-group">
          <label for="lastName">Last Name</label>
          <input type="text" class="form-control" formControlName="lastName"  required />
      </div>
      <div class="form-group">
          <label for="userinitials">Username</label>
          <input type="text" class="form-control" formControlName="userinitials"  required />
      </div>
      <div class="form-group">
          <label for="userpassword">Password</label>
          <input type="password" class="form-control" formControlName="userpassword"   required />
      </div>
      <div class="form-group">
          <button [disabled]="loading" class="btn btn-primary">Register</button>
          <a [routerLink]="['/login']" class="btn btn-link">Cancel</a>
      </div>
  </form>
</div>

