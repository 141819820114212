<h5>KST Kalcor Data Link To Azure</h5>
<div class="col-lg-14 text-danger">
    <i>* Running a year update may take several minutes. A pop up will appear indicating update complete.</i>
  </div>
<table class="table table-striped" style="width: 50%;">
    <thead>
    <tr>
      <th scope="col">Year</th>
      <th scope="col">Run Update</th>
      <!-- <th scope="col">Modified By</th>
      <th scope="col">Last Update</th> -->
      <th scope="col">Status</th>
      <!-- <th scope="col">Counter</th> -->
      <th scope="col">Edit Vendors</th>
      </tr>
    </thead>
    <tbody>
    <tr *ngFor="let y of year; index as i">

      <td>{{ y }}</td>
      <td>
          <button type="button" [disabled]="buttonClicker[i]" class="btn btn-link" (click)="run(i)">Run Update</button>
      </td>
      <!-- <td></td> -->
      <!-- <td></td> -->
      <!-- <td>{{ y.name }}</td>
      <td>{{ y.lastUpdate | date:'short' }}</td> -->
      <!-- <td>{{ y.status }}</td> -->
      <td><button *ngIf="this.spinner[i]=='...Updating'" class="btn btn-primary" type="button[i]" disabled>
        <span class="spinner-border spinner-border-sm"></span>{{ spinner[i] }}</button></td>
      <!-- <td>{{ y.counter }}</td> -->
      <td>
        <button type="button" class="btn btn-secondary" (click)="clickedItem(i)">Edit Vendors</button>            
    </td>
    </tr>
    </tbody>
  </table>

<!-- <div class="col-lg-44" style="border: 30px;">
    <label class="ms-3" style="font-size: large; padding-bottom:3px">
        Kalcor
        <div *ngFor="let y of year; index as i" (click)="clickedItem(i)">
            <a (click)="clickedItem(i)" class="pointer">
                <u class="text-primary">{{ y }}</u>
            </a>        
        </div>
    </label>
</div> -->