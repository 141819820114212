<div id="my-sales-order" *ngIf="reportName=='Sales Order'">
  <app-sales-order
    [title] = "title"
    [order] = "orderSelected"
    [orderItems] = "orderItems"
    [totalCases] = "totalCases"
    [totalPallets] = "totalPallets"
    [totalGrossLbs] = "totalGrossLbs"
    [totalCaseCost] = "totalCaseCost"
    [totalGrosskg] = "totalGrosskg">
  </app-sales-order>
</div> 
<div id="my-packing-slip" *ngIf="reportName=='Packing Slip'">
  <app-packing-slip
  [title] = "title"
  [order] = "orderSelected"
  [orderItems] = "orderItems">
</app-packing-slip>
</div> 
<div id="my-shipping" *ngIf="reportName=='Shipping'">
  <app-shipping
    [title] = "title"
    [order] = "orderSelected"
    [items] = "orderItems"
    [totalCases] = "totalCases"
    [totalPallets] = "totalPallets"
    [totalGrosskg] = "totalGrosskg"
    [totalGrossLbs] = "totalGrossLbs">
  </app-shipping>
</div> 
<div id="my-commercial-invoice" *ngIf="reportName=='Commercial Invoice'">
  <app-sales-order
    [title] = "title"
    [order] = "orderSelected"
    [orderItems] = "orderItems"
    [totalCases] = "totalCases"
    [totalPallets] = "totalPallets"
    [totalGrossLbs] = "totalGrossLbs"
    [totalCaseCost] = "totalCaseCost"
    [totalGrosskg] = "totalGrosskg">
  </app-sales-order>
</div> 
<div id="my-sales-order-vendor" *ngIf="reportName=='Vendor Sales Order'">
  <app-vendor-sales-order
    [title] = "title"
    [order] = "orderSelected"
    [orderItems] = "orderItems"
    [totalCases] = "totalCases"
    [totalPallets] = "totalPallets"
    [totalGrossLbs] = "totalGrossLbs"
    [totalCaseCost] = "totalCaseCost"
    [totalGrosskg] = "totalGrosskg"
    [vendorPurchaseCaseCost] = "vendorPurchaseCaseCost"
    [fOBNetVendorCost] = "fOBNetVendorCost">
  </app-vendor-sales-order>
</div> 
<div id="my-invoicing" *ngIf="reportName=='Invoice'">
    <app-invoicing
    [title] = "title"
    [order] = "orderSelected"
    [orderItems] = "orderItems"
    [totalCases] = "totalCases"
    [totalPallets] = "totalPallets"
    [totalGrossLbs] = "totalGrossLbs"
    [totalCaseCost] = "totalCaseCost"
    [totalGrosskg] = "totalGrosskg">
    </app-invoicing>
  </div> 
  <div id="my-invoicing-customer" *ngIf="reportName=='Customer Invoice'">
    <app-invoicing-customer
      [title] = "title"
      [order] = "orderSelected"
      [orderItems] = "orderItems"
      [totalCases] = "totalCases"
      [totalPallets] = "totalPallets"
      [totalGrossLbs] = "totalGrossLbs"
      [totalCaseCost] = "totalCaseCost"
      >
    </app-invoicing-customer>
    
  </div> 
<div *ngIf="reportName==''">  
  <form [formGroup]="orderForm" *ngIf="loaded" (ngSubmit)="onSubmit()" novalidate>
    <div class="row g-0 text-white mt-2 px-2 bg-primary">
      <div class="col-lg-24 pt-2 px-2 text-center">
        INVOICING
      </div>
    </div>
    
    <div class="row g-0 mt-2">
      <div class="col-lg-8 pt-2 px-0">
        <label class="text-white w-100 bg-primary">
          BILL TO
        </label>
      </div>
      <div class="col-lg-8 pt-2 px-0">
        <label class="text-white w-100 bg-primary">
          SALES ORDER INFORMATION
        </label>
      </div>
      <div class="col-lg-8 pt-2 px-0">
        <label class="text-white w-100 bg-primary">
          SHIPPING DATES/TYPES
        </label>
      </div>
    </div>
    
    <div class="row g-0">
      <div class="col-lg-8 pt-0 px-2">
        {{ customer?.company }}
      </div>
      <div class="col-lg-4 pt-0 px-2">
        Sales Order Date
      </div>
      <div class="col-lg-4 pt-0 px-2">
        {{ displayDate(order?.salesOrderDate, '')  }}
      </div>
      <div class="col-lg-4 pt-0 px-2">
        Shipment Date
      </div>
      <div class="col-lg-4 pt-0 px-2">
        {{ displayDate(order?.shipmentDate, '')  }}
      </div>
    </div>
    
    <div class="row g-0">
      <div class="col-lg-8 pt-0 px-2">
        {{ customer?.address }}
      </div>
      <div class="col-lg-4 pt-0 px-2">
        Sales Order No.
      </div>
      <div class="col-lg-4 pt-0 px-2">
        {{ order?.salesOrderNo }}
      </div>
      <div class="col-lg-4 pt-0 px-2">
        Delivered Date/Appt
      </div>
      <div class="col-lg-4 pt-0 px-2">
        {{ displayDate(order?.deliveredDateAppt, 'T')  }}
      </div>
    </div>
    
    <div class="row g-0">
      <div class="col-lg-8 pt-0 px-2">
        {{ getAddress2('customer') }}
      </div>
      <div class="col-lg-4 pt-0 px-2">
        Purchase Order
      </div>
      <div class="col-lg-4 pt-0 px-2">
        {{ order?.purchaseOrder }}
      </div>
      <div class="col-lg-4 pt-0 px-2">
        App Confirmed
      </div>
      <div class="col-lg-4 pt-0 px-2">
        {{ order?.appConfirmed }}
      </div>
    </div>
    
    <div class="row g-0">
      <div class="col-lg-8 pt-0 px-2">
      </div>
      <div class="col-lg-4 pt-0 px-2">
        Payment Terms
      </div>
      <div class="col-lg-4 pt-0 px-2">
        {{ term?.paymentTerms }}
      </div>
      <div class="col-lg-4 pt-0 px-2">
        Delivery Type
      </div>
      <div class="col-lg-4 pt-0 px-2">
        {{ freightType?.freightType }}
      </div>
    </div>
    
    <div class="row g-0">
      <div class="col-lg-8 pt-0 px-2">
      </div>
      <div class="col-lg-4 pt-0 px-2">
        Broker
      </div>
      <div class="col-lg-4 pt-0 px-2">
        {{ broker?.company }}
      </div>
      <div class="col-lg-4 pt-0 px-2">
        Order Status
      </div>
      <div class="col-lg-4 pt-0 px-2">
        {{ order?.orderStatus }}
      </div>
    </div>
    
    
    <div class="row g-0">
      <div class="col-lg-4 pt-0 px-0">
        <label class="text-white w-100 bg-primary">
          SHIP TO
        </label>
      </div>
      <div class="col-lg-4 pt-0 px-0">
        <label class="text-white w-100 bg-primary">
           VENDOR PICK UP
        </label>
      </div>
      <div class="col-lg-6 pt-0 px-0">
        <label class="text-white w-100 bg-primary">
          SHIPPING LANE
        </label>
      </div>
    
      <div class="col-lg-4 pt-0 px-0">
        <label class="text-white w-100 bg-primary">
          TRANSPORT COMPANY
        </label>
      </div>
      <div class="col-lg-4 pt-0 px-0">
        <label class="bg-primary text-white w-100">
          WAREHOUSE
        </label>
      </div>
      <div class="col-lg-2 pt-0 px-0">
        <label class="bg-primary text-white w-100">
          Invoice #
        </label>
      </div>
    </div>
    
    <div class="row g-0">
      <div class="col-lg-4 pt-0 px-2">
        {{ shipTo?.company }}
      </div>
      <div class="col-lg-4 pt-0 px-2">
        {{ pickup?.company }}
      </div>
      <div class="col-lg-6 pt-0 px-2">
        {{ location1?.location }}
      </div>
      <div class="col-lg-4 pt-0 px-2">
        {{ transport1?.company }}
      </div>
      <div class="col-lg-4 pt-0 px-2">
        {{ warehouse?.company }}
      </div>
      <div class="col-lg-2 pt-0 px-2">
      </div>
    </div>
    
    <div class="row g-0">
      <div class="col-lg-4 pt-0 px-2">
        {{ shipTo?.address }}
      </div>
      
      <div class="col-lg-4 pt-0 px-2">
        {{ pickup?.address }}
      </div>
      <div class="col-lg-6 pt-0 px-2">
        {{ location2?.location }}
      </div>
      <div class="col-lg-4 pt-0 px-2">
        {{ transport2?.company }}
      </div>
      <div class="col-lg-4 pt-0 px-2">
        {{ warehouse?.address }}
      </div>
      <div class="col-lg-2 pt-0 px-2">
        {{ order?.invoiceNumber }}
      </div>
    </div>
    
    <div class="row g-0">
      <div class="col-lg-4 pt-0 px-2">
        {{ getAddress2('shipto') }}
      </div>
      <div class="col-lg-4 pt-0 px-2">
        {{ getAddress2('pickup') }}
      </div>
      <div class="col-lg-6 pt-0 px-2">
        {{ location3?.location }}
      </div>
      <div class="col-lg-4 pt-0 px-2">
        {{ transport3?.company }}
      </div>
      <div class="col-lg-4 pt-0 px-2">
        {{  warehouseLine3 }}
      </div>
      <div class="col-lg-2 pt-0 px-2">
      </div>
    </div>
    
    <div *ngIf="warehouseLine4 !=''" class="row g-0 mt-2">
      <div class="col-lg-18 pt-0 px-2">
      </div>
      <div class="col-lg-4 pt-0 px-2">
        {{ warehouseLine4 }}
      </div>
      <div class="col-lg-2 pt-0 px-2">
      </div>
    </div>

    <div class="row g-0">
      <div class="col-lg-10 pt-3 px-2">
        <span class="fw-bold">Vendor: </span>
        {{ getVendor() }}
      </div>
      <div class="col-lg-14 pt-3 px-2">
        <span class="fw-bold">Notes: </span>
        {{ order?.notes }}
      </div>
    </div>
    
    <div class="row g-0 text-white mt-3 bg-primary">
      <div class="col-lg-2 pt-0 px-2">
        <label class="text-white">
          ORDER CODE
        </label>
      </div>
      <div class="col-lg-4 pt-0 px-2">
        <label class="text-white">
          BRAND/CATEGORY
        </label>
      </div>
    
      <div class="col-lg-8 pt-0 px-2">
        <label class="text-white">
          ITEM DESCRIPTION
        </label>
      </div>
    
      <div class="col-lg-2 pt-0 px-2">
        <label class="text-white">
          SIZE
        </label>
      </div>
      <div class="col-lg-2 pt-0 px-2">
        <label class="text-white text-end w-100">
          ORDER
        </label>
      </div>
      <div class="col-lg-2 pt-0 px-2">
        <label class="text-white text-end w-100">
          SHIPPED
        </label>
      </div>
      <div class="col-lg-2 pt-0 px-2">
        <label class="text-white text-end w-100">
          INVOICED
        </label>
      </div>
      <div class="col-lg-2 pt-0 px-2">
        <label class="text-white text-end w-100">
          PURCHASED
        </label>
      </div>
    </div>
    <div formArrayName="items" *ngFor="let item of orderForm.get('items')['controls']; let i = index;">
      <div [formGroupName]="i">
        <div class="row g-0" [ngClass]="{'grid-stripe': i % 2 != 0}">
          <div class="col-lg-2 pt-0 px-2">
            {{ items[i].orderCode}}
          </div>
          <div class="col-lg-4 pt-0 px-2">
            {{ items[i].brandCategory }}
          </div>
          <div class="col-lg-8 pt-0 px-2">
            {{ items[i].itemDescription}}
          </div>
          <div class="col-lg-2 pt-0 px-2">
            {{ items[i].size }}
          </div>
          <div class="col-lg-2 pt-0 px-2 text-end">
            {{ items[i].cases }}
          </div>
          <div class="col-lg-2 pt-0 px-2 text-end">
            {{ items[i].shipped }}
          </div>
          <div class="col-lg-2 pt-0 px-2 text-end">
            <input formControlName="invoiced"  class="w-75 text-end"> 
          </div>
          <div class="col-lg-2 pt-0 px-2 text-end">
            <input formControlName="purchased"  class="w-75 text-end"> 
          </div>
        </div>
        <div class="row g-0"
              [ngClass]="{'gray-out': grayRow[i]==='G', 'grid-stripe': grayRow[i]==='S', 'pdf-none': grayRow[i]==='B'}">
          <div class="col-lg-4 pt-0 pb-2 px-2">
            <span class="pe-2">CS Gross Lbs</span>
            {{ items[i].csGrossLbs | number : '1.2-2' }}
          </div>
          <div class="col-lg-4 pt-0 pb-2 px-2">
            <span class="pe-2">Product Type</span>
            {{ items[i].productType }}
          </div>
          <div class="col-lg-6 pt-0 pb-2 px-2">
            <span class="pe-2">TI/HI</span>
            {{ items[i].tiHi }}
            <span class="ps-4 pe-2">CS per Pallet</span>
            {{ items[i].csPerPallet }}
          </div>
        </div>
      </div>
    </div>
  
    <div class="row g-0 text-white mt-3 bg-primary">
      <div class="col-lg-6 pt-0 px-2">
        <span class="pe-2">TOTAL CASES INVOICED</span>
        {{ totalCases | number : '1.0-0' }}
      </div>
      <div class="col-lg-4 pt-0 px-2">
        <span class="pe-2">TOTAL PALLETS</span>
          {{ totalPallets |  number : '1.0-0' }}
      </div>
    
      <div class="col-lg-8 pt-0 px-2">
        <span class="pe-2">TOTAL GROSS</span>
          {{ totalGrossLbs | number : '1.2-2'  }}
        <span class="pe-3"> lbs</span>
          {{ totalGrosskg | number : '1.2-2'  }}
        <span class="pe-2"> kg</span>
      </div>

      <div class="col-lg-6 pt-0 px-2">
        <span class="pe-2">SALES</span>
        {{ totalSales | currency }}
        <span class="ps-5 pe-3">PURCHASE</span>
        {{ totalPurchased | currency }}
      </div>

    </div>

  
    <div class="row g-0 mt-3">
      <div class="col-lg-6 pt-0 px-2">
        <img class="logo img-responsive" src="/assets/images/Kalcor Menu.png" height="100%" width="158px">
      </div>
      <div class="col-lg-18 pt-3 px-2">
          ​​CANADA OFFICES: VANCOUVER: 1285 W Broadway , #600, Vancouver, BC, V6H 3X8, Office 866.779.9850 Email:
          Inbox@kalcor.ca Website: www.kalcor.ca ​
      </div>
    </div>
    <br>
  
    <div class="row">
      <div class="col-4 pe-0">
        <button type="submit" class="btn btn-primary mt-3 w-100">Save Order</button>
      </div>
      <div class="col-4 pe-0">
        <button type="button" class="btn btn-primary mt-3 w-100" [ngClass]="{'disabled': !shipOrderBtn}" [disabled]="!shipOrderBtn"
          (click)="orderEdit()">Sales Order</button>
      </div>
      <div class="col-4 pe-0">
            <button type="button" class="btn btn-primary mt-3 w-100" [ngClass]="{'disabled': !invoiceOrderBtn}" [disabled]="!invoiceOrderBtn"
            (click)="editShippingItems()">Ship Order</button>
          </div>
        </div>
    
        <div class="row">
          <div class="col-4 pe-0">
            <button type="button" class="btn btn-warning mt-3 w-100" [ngClass]="{'disabled': !salesOrderPrintBtn}" [disabled]="!salesOrderPrintBtn"
            (click)="printProcess('Sales Order')">Print Sales Order</button>
          </div>
        
          <div class="col-4 pe-0">
            <button type="button" class="btn btn-warning mt-3 w-100" [ngClass]="{'disabled': !slipPrintBtn}" [disabled]="!slipPrintBtn"
            (click)="printProcess('Packing Slip')" >Print Packing Slip</button>
          </div>
          <div class="col-4 pe-0">
            <button type="button" class="btn btn-warning mt-3 w-100" [ngClass]="{'disabled': !shipPrintBtn}" [disabled]="!shipPrintBtn"
            (click)="printProcess('Shipping')">Print Shipping</button>
          </div>
          <div class="col-4 pe-0">
            <button type="button" class="btn btn-warning mt-3 w-100" [ngClass]="{'disabled': !invoicePrintBtn}" [disabled]="!invoicePrintBtn"
            (click)="printProcess('Commercial Invoice')">Print Commercial Invoice</button>
          </div>
          <div class="col-4 pe-0">
            <button type="button" class="btn btn-warning mt-3 w-100" [ngClass]="{'disabled': !poPrintBtn}" [disabled]="!poPrintBtn"
            (click)="showDialog()">Print Purchase Order</button>
          </div>
          <div class="col-4 pe-0">
            <button type="button" class="btn btn-warning mt-3 w-100" [ngClass]="{'disabled': !invoicePrintBtn}" [disabled]="!invoicePrintBtn"
            (click)="printProcess('Invoice')">Print Invoice</button>
          </div>
          <div class="col-4 pr-0">
            <button type="button" class="btn btn-warning mt-3 w-100" [ngClass]="{'disabled': !invoicePrintBtn}" [disabled]="!invoicePrintBtn"
            (click)="printProcess('Customer Invoice')">Print Customer Invoice</button>
          </div>
        </div>
        
        <div class="row">
          <div class="col-4 pe-0">
            <button type="button" class="btn btn-secondary mt-3 w-100" (click)="onDelete()" [ngClass]="{'disabled': !deleteOrderBtn}" [disabled]="!deleteOrderBtn"
            >Delete</button>
          </div>
        </div>
  
        <!-- <button type="submit" class="btn btn-primary mt-3 w-25">Save</button>
        <button type="button" class="btn btn-secondary ml-3 mt-3 w-25" (click)="onPrint()">Print</button> -->
        <ngb-alert *ngIf="successMessage" [type]="alertType" (close)="successMessage = null">{{ successMessage }}
        </ngb-alert>
  </form>
</div>
<div id="my-confirm-page" *ngIf="reportName=='Confirm'">
  <app-confirm-dialog 
    (returnDialog) = "returnDialog($event)">
  </app-confirm-dialog>
</div> 