<h5>{{ title }} </h5>
<div class="row">
  <div class="column">
    <form [formGroup]="productForm" (ngSubmit)="onSubmit()" novalidate>
      <div class="form-group">
        <div class="col">
          <label>
            Active Listing:
            <input type="text" formControlName="activeListings" class="form-control">
          </label>

          <label class="ms-3">
            Product Order Code:
            <input type="text" formControlName="productOrderCode" class="form-control">
          </label>
        </div>

        <div class="col">
          <label>
            Brand:
            <input type="text" formControlName="brand" class="form-control">
          </label>

          <label class="ms-3">
            Category:
            <input type="text" formControlName="category" class="form-control">
          </label>
        </div>

        <div class="col">
          <label>
            Item Desciption:
            <input type="text" formControlName="itemDescription" class="form-control">
          </label>

          <label class="ms-3">
            Size:
            <input type="text" formControlName="size" class="form-control">
          </label>
        </div>
        <div class="col">
          <label class="col-lg-3">
            TI:
            <input type="text" formControlName="ti" class="form-control">
          </label>

          <label class="col-lg-3 ms-3">
            HI:
            <input type="text" formControlName="hi" class="form-control">
          </label>

          <label class="ms-3">
            Type of Pallet:
            <select formControlName="palletTypeId" class="form-control">
              <option *ngFor="let p of palletType" [value]="p.palletTypeId">{{p.palletType1}}</option>
            </select>
          </label>
        </div>

        <div class="col">
          <label class="col-lg-3">
            CS Per Pallet:
            <input type="text" formControlName="csPerPallet" class="form-control">
          </label>

          <label class="col-lg-4 ms-3">
            Pallet Per Truck:
            <input type="text" formControlName="palletPerTruck" class="form-control">
          </label>

          <label class="col-lg-3 ms-3">
            Case Per Truck:
            <input type="text" formControlName="csPerTruck" class="form-control">
          </label>
        </div>
        <div *ngIf="this.spinner=='...Updating'"> <button type='button' class="btn btn-primary mt-3 w-25" disabled>
            <span class="spinner-border spinner-border-sm"></span>{{ spinner }}</button>
        </div>
        <div *ngIf="this.spinner==''">
          <button type="submit" class="btn btn-primary mt-3 w-25">Save</button>
          <button *ngIf="!listing" type="button" class="btn btn-warning ms-3 mt-3 w-25" (click)="validate()">Save to
            Group</button>
          <button *ngIf="listing" type="button" class="btn btn-warning ms-3 mt-3 w-25" (click)="validateListing()">Save
            to Listing Group</button>
          <br>
          <button type="button" class="btn btn-secondary mt-3 w-25" (click)="editSetup()">Product Setup</button>
          <button type="button" class="btn btn-secondary ms-3 mt-3 w-25" (click)="editCaseSetup()">Case Setup</button>
          <button type="button" class="btn btn-secondary ms-3 mt-3 w-25" (click)="editFreightCostSetup()">Freight Cost
            Setup</button>
          <br>
          <button type="button" class="btn btn-secondary mt-3 w-25" (click)="editCostingBucketSetup()">Costing Bucket
            Setup</button>
          <button *ngIf="listing" type="button" class="btn btn-secondary ms-3 mt-3 w-25"
            (click)="editListingSetup()">Listing Setup</button>
        </div>
      </div>
      <ngb-alert *ngIf="successMessage" type="success" (close)="successMessage = null">{{ successMessage }}</ngb-alert>
    </form>
  </div>
  <div *ngIf="!listing" class="column">
    <h5>Group Products: {{ this.groupName }}</h5>
    <table class="table table-striped">
      <thead>
        <tr>
          <th scope="col">Product Order Code</th>
          <th scope="col">Order Type</th>
          <th scope="col">Brand</th>
          <th scope="col">Category</th>
          <th scope="col">Item Description</th>
          <th scope="col">Size</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let p of linkedProduct">
          <td>{{ p.orderCode }}</td>
          <td>{{ p.orderType }}</td>
          <td>{{ p.brand }}</td>
          <td>{{ p.category }}</td>
          <td>{{ p.itemDescription }}</td>
          <td>{{ p.size }}</td>
        </tr>
      </tbody>
    </table>
  </div>
  <div *ngIf="listing" class="column">
    <h5>Listing Products</h5>
    <table class="table table-striped">
      <thead>
        <tr>
          <th scope="col">Product Order Code</th>
          <th scope="col">Order Type</th>
          <th scope="col">Brand</th>
          <th scope="col">Category</th>
          <th scope="col">Item Description</th>
          <th scope="col">Size</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let p of linkedListing">
          <td>{{ p.orderCode }}</td>
          <td>{{ p.orderType }}</td>
          <td>{{ p.brand }}</td>
          <td>{{ p.category }}</td>
          <td>{{ p.itemDescription }}</td>
          <td>{{ p.size }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>