<h5>Product Case Review</h5>
<div class="smallSpacer"><h6>Filter</h6></div>
<div class="col-lg-44">

  <label class="formControlName ms-3">
    Brand:
    <select class="form-control" [(ngModel)]="brand" (change)="resetFilters()">
      <option [value]=""></option>
      <option *ngFor="let b of brandFilter" [value]="b">{{ b }}</option>
    </select>
  </label>

  <label class="formControlName ms-3">
    Category:
    <select class="form-control" [(ngModel)]="category" (change)="resetFilters()">
        <option [value]=""></option>
        <option *ngFor="let c of categoryFilter" [value]="c">{{ c }}</option>
    </select>
  </label>

  <label class="formControlName ms-3">
    Type of Costing:
    <select class="form-control" [(ngModel)]="costing" (change)="resetFilters()">
        <option [value]=""></option>
        <option *ngFor="let t of costingFilter" [value]="t">{{ t }}</option>
    </select>
  </label>

  <label class="formControlName ms-3">
    Cost Type:
    <select class="form-control" [(ngModel)]="costType" (change)="resetFilters()">
      <option [value]=""></option>
      <option *ngFor="let t of costTypeFilter" [value]="t">{{ t }}</option>
    </select>
  </label>

  <label class="formControlName ms-3">
    Group:
    <select class="form-control" [(ngModel)]="group" (change)="resetFilters()">
      <option [value]=""></option>
      <option *ngFor="let t of groupFilter" [value]="t">{{ t }}</option>
    </select>
  </label>
  <div class="smallSpacer"></div>
  <button type="button" class="btn btn-primary mt-0 ms-3" (click)="getDefaultDataScreen()">Reset</button>
</div>
<div class="spacer"></div>
<table class="table table-striped">
  <thead>
  <tr>
    <!-- <th scope="col">Type of Costing</th>
    <th scope="col">Cost Type</th> -->
    <th scope="col">Order Code</th>
    <!-- <th scope="col">Brand</th> -->
    <!-- <th scope="col">Category</th> -->
    <th scope="col">Item Description</th>
    <th scope="col">Size</th>
    <th scope="col">csNetLbs</th>
    <th scope="col">csGrossLbs</th>
    <th scope="col">csNetKg</th>
    <th scope="col">csGrossKg</th>
    <th scope="col">csLInch</th>
    <th scope="col">csWInch</th>
    <th scope="col">csHInch</th>
    <th scope="col">csCubeImperial</th>
    <th scope="col">csLCm</th>
    <th scope="col">csWCm</th>
    <th scope="col">csHCm</th>
    <th scope="col">csCubeMetric</th>
    <th scope="col">unitNet</th>
    <th scope="col">unitGross</th>
    <th scope="col">unitLInch</th>
    <th scope="col">unitWInch</th>
    <th scope="col">unitHInch</th>
    <th scope="col">unitCubeImperial</th>
    <th scope="col">unitLCm</th>
    <th scope="col">unitWCm</th>
    <th scope="col">unitHCm</th>
    <th scope="col">unitCubeMetric</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let p of products | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize">
  <!-- <tr *ngFor="let p of products | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize" (click)="onRowClicked(p)"> -->
    <!-- <td>{{ p.activeListings }}</td> -->
    <!-- <td>{{ p.costType }}</td> -->
    <td>
      <a (click)="onRowClicked(p)" class="pointer">
        <u class="text-primary">{{ p.orderCode }}</u>
      </a>
    </td>
    <!-- <td>{{ p.brand }}</td> -->
    <!-- <td>{{ p.category }}</td> -->
    <td>{{ p.itemDescription }}</td>
    <td>{{ p.size }}</td>

    <td>{{ p.csNetLbs }}</td>
    <td>{{ p.csGrossLbs }}</td>
    <td>{{ p.csNetKg }}</td>
    <td>{{ p.csGrossKg }}</td>
    <td>{{ p.csLInch }}</td>
    <td>{{ p.csWInch }}</td>
    <td>{{ p.csHInch }}</td>
    <td>{{ p.csCubeImperial }}</td>
    <td>{{ p.csLCm }}</td>
    <td>{{ p.csWCm }}</td>
    <td>{{ p.csHCm }}</td>
    <td>{{ p.csCubeMetric }}</td>
    <td>{{ p.unitNet }}</td>
    <td>{{ p.unitGross }}</td>
    <td>{{ p.unitLInch }}</td>
    <td>{{ p.unitWInch }}</td>
    <td>{{ p.unitHInch }}</td>
    <td>{{ p.unitCubeImperial }}</td>
    <td>{{ p.unitLCm }}</td>
    <td>{{ p.unitWCm }}</td>
    <td>{{ p.unitHCm }}</td>
    <td>{{ p.unitCubeMetric }}</td>
  </tr>
  <div *ngIf="products!==undefined">
  <ngb-pagination
  [(page)]="page"
  [pageSize]="pageSize"
  [collectionSize]="products?.length"></ngb-pagination>
  </div>
  </tbody>
</table>
