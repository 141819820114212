<h5>{{ title }} </h5>
<form [formGroup]="brandForm" (ngSubmit)="onSubmit()" novalidate>
  <div class="form-group">
    <div class="col">
      <label>
        Active Item:
        <select formControlName="activeItem" class="form-control">
          <option value="Active">Active</option>
          <option value="Not Active">Not Active</option>
        </select>
      </label>
    </div>

    <div class="col-lg-24">
      <label>
        brand:
        <input type="text" formControlName="brand" class="form-control"  size="60">
      </label>
    </div>


    <div class="col">
      <label>
       Vendor:
        <select formControlName="vendorId" class="form-control">
          <option *ngFor="let v of vendor" [value]="v.id">{{v.company}}</option>
        </select>
      </label>
    </div>
    <button type="submit" class="btn btn-primary mt-3 w-25">Save</button>
    <button type="button" class="btn btn-secondary ml-3 mt-3 w-25" (click)="onDelete()">Delete</button>
    </div>
     <ngb-alert *ngIf="successMessage" type="success" (close)="successMessage = null">{{ successMessage }}</ngb-alert>
</form>

