import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ExchangeRate } from '../../../models/exchange-rate.model';
import { ExchangeRateService } from '../../../services/exchange-rate.service';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
//import { ThrowStmt } from '@angular/compiler';

@Component({
  selector: 'app-exchange-rate-edit',
  templateUrl: './exchange-rate-edit.component.html',
  styleUrls: ['./exchange-rate-edit.component.css']
})
export class ExchangeRateEditComponent implements OnInit {
  title = 'Exchange Rates';
  newOne = true;
  exchangeRate: ExchangeRate;
  exchangeForm: UntypedFormGroup;
  successMessage: string;
  exchangeDefault: ExchangeRate[];
  private success = new Subject<string>();
  staticAlertClosed = false;
  constructor(private route: ActivatedRoute,
    private router: Router,
    private fb: UntypedFormBuilder,
    private exchangeSvc: ExchangeRateService) { }

  ngOnInit(): void {
    this.exchangeForm = this.fb.group({
      exchangeRate: [''],
      exchangeDefault:  ['']
    })
    this.exchangeSvc.getDefault().subscribe(data => {
      this.exchangeDefault = data;
    })
    this.route.data.subscribe(data => {
      if (data.dataExchangeRate !== undefined) {
        this.title = 'Exchange Rate Edit';
        this.newOne = false;
        this.exchangeRate = data.dataExchangeRate;
        this.fillFormData();
      } else {
        this.title = 'Exchange Rate New';
        this.newOne = true;
        this.exchangeRate = new ExchangeRate();
        this.exchangeForm.reset();
      }
    });
    setTimeout(() => this.staticAlertClosed = true, 20000);
    this.success.subscribe((message) => this.successMessage = message);
    this.success.pipe(
      debounceTime(5000)
    ).subscribe(() => this.successMessage = null);     
  }
  fillFormData() {
    this.exchangeForm.setValue({
      exchangeRate: this.exchangeRate.exchangeRateAmount,
      exchangeDefault: this.exchangeRate.exchangeDefault
    });
  }

  onSubmit() {
    this.exchangeRate.exchangeRateAmount = this.exchangeForm.controls.exchangeRate.value;
    this.exchangeRate.exchangeDefault = this.exchangeForm.controls.exchangeDefault.value;
    if (this.exchangeRate.exchangeDefault == "Yes") {
      this.exchangeDefault.forEach(data => {
        if (data.exchangeId === this.exchangeRate.exchangeId) {
        } else {
          data.exchangeDefault = "No";
          this.exchangeSvc.update(data).subscribe(e => {

          }) 
        }
      })
    }

    if (this.newOne) {
      this.exchangeSvc.create(this.exchangeRate).subscribe(data => {
        this.exchangeRate.exchangeId =  data.exchangeId;
        this.newOne = false;
        this.title = 'Exchange Rate Edit';
        this.success.next(`Successfully added.`);
      }, error => {
      });
    } else {
      this.exchangeSvc.update(this.exchangeRate).subscribe(data => {
        this.success.next(`Successfully changed.`);
      }, error => {
      });
    }
  }  
  onDelete() {
    if (this.exchangeRate.exchangeId) {
      this.exchangeSvc.remove(this.exchangeRate.exchangeId).subscribe(data => {
        this.router.navigate(['/other/exchange-rate-list']);
      });
    }
  }     
}
