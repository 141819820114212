import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FundsTransferService } from '../../../services/funds-transfer.service';
import { FundsTransfer } from '../../../models/transfer-funds';
import { UntypedFormBuilder, UntypedFormGroup, Validators, FormsModule } from '@angular/forms';
import { Subject, fromEventPattern, Subscription, throwError } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { CustomerService } from '../../../services/customer.service';
import { VendorService } from '../../../services/vendor.service';
import { BucketService } from '../../../services/bucket.service';
import { ProductService } from 'src/app/services/product.service';
import { SorterService } from 'src/app/services/sorter.service';
import { Sorter2Service } from 'src/app/services/sorter2.service';
import { Product } from 'src/app/models/product.model';
import { Customer } from 'src/app/models/customer.model';
import { Vendor } from 'src/app/models/vendor-model';
import { Brand } from 'src/app/models/brand.model';
import { BrandService } from 'src/app/services/brand.service';
import { ThisReceiver } from '@angular/compiler';




@Component({
  selector: 'app-transfer-edit',
  templateUrl: './transfer-edit.component.html',
  styleUrls: ['./transfer-edit.component.scss']
})
export class TransferEditComponent implements OnInit {
  transfer: FundsTransfer;
  successMessage: string;
  private success = new Subject<string>();
  alertType = "success";
  staticAlertClosed = false;
  newOne = true;
  transferForm: UntypedFormGroup;
  customers: any;
  vendors: Vendor[];
  vendorOriginal: Vendor[];
  bucket: any;
  listing: Product[];
  brand: Brand[];
  brandId: number;
  customerId: number;
  orderType1: any;
  orderType2: any;
  orderType3: any;




  constructor(
    private router: Router,
    private transferService: FundsTransferService,
    private route: ActivatedRoute,
    private fb: UntypedFormBuilder,
    private custService: CustomerService,
    private vendService: VendorService,
    private bucketService: BucketService,
    private productService: ProductService,
    private sortSvc: SorterService,
    private sort2Svc: Sorter2Service,
    private brandService: BrandService
  ) { }
  


  ngOnInit() {
    this.transferForm = this.fb.group({
      transferDate: ['', Validators.required],
      transferNo: [{ value: '', disabled: true }],
      billToId: ['', Validators.required],
      vendorId: ['', Validators.required],
      transferStatus: [{ value: '', disabled: true }],
      notes: [''],
      fromBucket: ['', Validators.required],
      toBucket: ['', Validators.required],
      transferAmount: ['', Validators.required],
    });
    this.custService.getCompany().subscribe(data => {
      this.customers = data;
      this.vendService.getCompany().subscribe(data => {
        this.vendors = [];
        this.vendorOriginal = data;
        this.bucketService.getAll().subscribe(data => {
          this.bucket = data;
          this.productService.getListingCustomerOrder().subscribe(listing => {
            this.listing = listing;
            this.brandService.getAll().subscribe(brand => {
              this.brand = brand;
              this.route.data.subscribe(transfer => {
                if (transfer.dataTransfer !== undefined) {
                  this.transfer = transfer.dataTransfer;
                  this.newOne = false;
                } else {
                  this.newOne = true;
                  this.initTransfer();
                }
                this.fillFormData();
                this.onChanges();
                this.filterVendor(this.transfer.billToId);
                // this.filterOrder();
              })
            })
          })
        })
      })
    })
    setTimeout(() => this.staticAlertClosed = true, 20000);
    this.success.subscribe((message) => this.successMessage = message);
    this.success.pipe(
      debounceTime(5000)
      ).subscribe(() => this.successMessage = null);    
  
  }

  fillFormData() {
    this.transferForm.setValue({
      transferDate: this.transfer.transferDate == null ? this.transfer.transferDate : this.transfer.transferDate.toString().substr(0,10),
      transferNo: this.transfer.transferNo,
      billToId: this.transfer.billToId,
      vendorId: this.transfer.vendorId,
      transferStatus: this.transfer.transferStatus,
      notes: this.transfer.notes,
      fromBucket: this.transfer.fromBucket,
      toBucket: this.transfer.toBucket,
      transferAmount: this.transfer.transferAmount,
    });
  }
  initTransfer(): void {
    this.transfer = new FundsTransfer();
    this.transfer.transferDate = null;
    this.transfer.transferNo = null;
    this.transfer.billToId = null;
    this.transfer.vendorId = null;
    this.transfer.transferStatus = null;
    this.transfer.notes = null;
    this.transfer.fromBucket = null;
    this.transfer.toBucket = null;
    this.transfer.transferAmount = null;
    this.transfer.orderType1 = '';
    this.transfer.orderType2 = '';
    this.transfer.orderType3 = '';
    this.transfer.id = 0;
  }
  onChanges(): void {
    this.transferForm.get('billToId').valueChanges.subscribe(val => {
      this.filterVendor(val);
    });
  }
  filterVendor(id) {
    this.vendors = [];
    let vendorId = null;
    if (id) {
      id = id * 1;
      this.listing.forEach(list => {
        if (list.customerId == id) {
          const brand = this.brand.find(b => b.id === list.brandId);
          this.brandId = list.brandId;
          if (brand && brand.vendorId) {
            const vend = this.vendorOriginal.find(v => v.id === brand.vendorId);
            if (vend) {
              const ven = this.vendors.find(v => v.id === vend.id);
              if (!ven) {
                this.vendors.push(vend);
              }
            }
          }
        }
      })
      this.vendors = this.sortSvc.sorter(this.vendors, 'company');
    }
  }
  filterOrder() {
    let id = this.transferForm.controls.vendorId.value * 1;
    let orderTypes = [];
    if (id) {
      id = id * 1;
      this.brand.find(b => {
        if (b.vendorId == id) {
          this.brandId = b.id;
          const customerId = this.transferForm.controls.billToId.value * 1;
          const order = this.listing.forEach(list => {
            if (list.brandId === this.brandId && list.customerId === customerId) {
              if (list.orderType) {
                const type = orderTypes.indexOf(list.orderType);
                if (type == -1) {
                  orderTypes.push(list.orderType)
                }
              }
            }
          })
        }
      })
      this.transfer.orderType1 = orderTypes.length > 0 ? orderTypes[0] : this.transfer.orderType1;
      this.transfer.orderType2 = orderTypes.length > 1 ? orderTypes[1] : this.transfer.orderType2;
      this.transfer.orderType3 = orderTypes.length > 2 ? orderTypes[2] : this.transfer.orderType3;
    }
  }
  onSubmit(status) {
    if (this.transferForm.invalid) {
      this.success.next(`Please enter required fields.`);
      this.alertType = "danger";
      return;
    }
    this.alertType = "success";
    this.transferForm.controls.transferStatus.setValue(status);
    this.filterOrder();
    this.updateOrder();
    if (this.newOne) {
      this.transfer.id = 0;
      this.transferService.create(this.transfer).subscribe(data => {
        this.success.next(`Successfully created.`);
        this.router.navigate(['/accounts/transfer-edit',  data.id]);
      })
    } else {
      this.transferService.update(this.transfer).subscribe(data => {
        this.success.next(`Successfully updated.`);
      })
    }
  }
  updateOrder() {
    for (const item in this.transferForm.controls) {
      if (this.transferForm.controls.hasOwnProperty(item)) {
          this.transfer[item] = this.transferForm.controls[item].value;
      }
    }
  }
  onDelete() {
    if (this.transfer.id) {
      if (confirm('Delete this item?'))
      this.transferService.remove(this.transfer.id).subscribe(data => {
        this.router.navigate(['/accounts/transfer-list']);
      });
    }
  }

}
